import { checkODINHTTPStatus } from '~/code/services/http-response-handler'
import { getWithAuth } from '~/code/services/authorised-requests'
import { ApplicationDetails } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/models/ApplicationDetails'
import { VersionHistory } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/models/VersionHistory'

export const fetchApplicationDetailsById = (applicationId: number) =>
    checkODINHTTPStatus(getWithAuth<ApplicationDetails>(`/odin-api/api/Packages/${applicationId}/GetLastVersionDetails`))

export const fetchApplicationVersionHistoryById = (applicationId: number, filter) =>
    checkODINHTTPStatus(getWithAuth<VersionHistory[]>(`/odin-api/api/Packages/${applicationId}/PackageVersionHistory`, filter))
