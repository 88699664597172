import React from 'react'
import { CopyText } from '~/code/common/components'
import { InformationFormData } from '~/code/POS/pages/NewOrder/components/iso/InformationForm/models'
import { SummaryBlockData } from '~/code/common/components/SummaryBlock/models/SummaryBlockData'
import { DeliveryAddressFormData } from '~/code/POS/pages/NewOrder/components/iso/DeliveryAddressForm/models'
import informationTranslations from '../components/iso/InformationForm/translations'
import orderTypeTranslations from '../components/iso/OrderTypeForm/translations'
import addressTranslations from '../components/iso/DeliveryAddressForm/translations'
import processingTranslations from '../components/iso/ProcessingDetailsForm/translations'
import productsTranslations from '../components/iso/ProductsForm/translations'
import translations from '~/code/POS/pages/NewOrder/components/common/OrderSummary/translations'
import { ProcessingDetailsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/models'
import { SelectItem } from '~/code/common/components/Select'
import { ProductsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/models'
import { Bundle } from '~/code/POS/pages/NewOrder/models/Order/Bundle'
import { isEmpty } from 'dna-common'
import { OrderTypeFormData } from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/models/OrderTypeFormData'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'


function nonEmpty(value: any) {
    return isEmpty(value) ? ' ' : value
}

function formatSortCode(value: string) {
    if (isEmpty(value) || value.length < 6) return ''
    return `${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 6)}`
}

export function orderDetailsToSummaryBlock (orderReference: string, employeeCompany: string): SummaryBlockData {
    return {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: 'Order reference',
                        renderValue: () => {
                            return orderReference ? <CopyText text={ orderReference } /> : translations().noReference
                        },
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: 'Ordering on behalf of',
                        value: employeeCompany,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function informationFormDataToSummaryBlock (data: InformationFormData, countries: SelectItem[]): SummaryBlockData {
    const country = countries ? countries.find(item => item.value === data.country) : null
    return {
        header: translations().information,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().companyName,
                        value: data.companyName,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().vatNumber,
                        value: data.vatNumber,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().tradingAs,
                        value: nonEmpty(data.tradingAs),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().companyNumber,
                        value: data.companyNumber,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: informationTranslations().merchantAddress
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().country,
                        value: nonEmpty(country ? country.label : ''),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().contactName,
                        value: nonEmpty(data.contactName),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().postcode,
                        value: nonEmpty(data.postcode),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().contactSurname,
                        value: nonEmpty(data.contactSurname),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().addressLine1,
                        value: nonEmpty(data.addressLine1),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().jobTitle,
                        value: nonEmpty(data.jobTitle),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().addressLine2,
                        value: nonEmpty(data.addressLine2),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().email,
                        value: nonEmpty(data.email),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().addressLine3,
                        value: nonEmpty(data.addressLine3),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().contactPhoneNumber,
                        value: nonEmpty(data.contactPhoneNumber),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().town,
                        value: nonEmpty(data.town),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().contactMobilePhone,
                        value: nonEmpty(data.contactMobilePhone),
                        span: {xs: 12, sm: 12}
                    }
                ]

            },
            {
                fields: [
                    {
                        type: 'field',
                        label: informationTranslations().county,
                        value: nonEmpty(data.county)
                    }
                ]
            }
        ]
    }
}

export function orderTypeFormDataToSummaryBlock (data: OrderTypeFormData, stores: SelectItem[], departments: SelectItem[], selectedCompanyName: string): SummaryBlockData {
    const store = stores ? stores.find(item => item.value as number === data.storeId) : null
    const department = departments ? departments.find(item => item.value as number === data.departmentId) : null

    const result: SummaryBlockData = {
        header: orderTypeTranslations().orderType,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: orderTypeTranslations().orderType,
                        value: nonEmpty(orderTypeTranslations()[data.orderType]),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: informationTranslations().companyName,
                        value: nonEmpty(selectedCompanyName),
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }

    if (!isEmpty(store?.label) || !isEmpty(department?.label)) {
        result.rows.push(
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: orderTypeTranslations().storeAndDepartmentInfo
                    }
                ]
            }
        )
        result.rows.push(
            {
                fields: [
                    {
                        type: 'field',
                        label: orderTypeTranslations().store,
                        value: store?.label || '',
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: orderTypeTranslations().department,
                        value: department?.label || '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            })
    }

    return result
}

export function deliveryAddressFormDataToSummaryBlock (data: DeliveryAddressFormData, countries: SelectItem[]): SummaryBlockData {
    const country = countries ? countries.find(item => item.value === data.country) : null
    const rows = data.useMerchantAddress ?
        [
            {
                fields: [
                    {
                        type: 'field',
                        value: translations().sameAsMerchantAddress
                    }
                ]
            }
        ] :
        [
            {
                fields: [
                    {
                        type: 'field',
                        label: addressTranslations().country,
                        value: nonEmpty(country ? country.label : ''),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addressTranslations().recipientName,
                        value: nonEmpty(data.recipientName),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addressTranslations().postcode,
                        value: nonEmpty(data.postcode),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addressTranslations().deliveryInstructions,
                        value: nonEmpty(data.deliveryInstructions),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addressTranslations().addressLine1,
                        value: nonEmpty(data.addressLine1)
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addressTranslations().addressLine2,
                        value: nonEmpty(data.addressLine2)
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addressTranslations().town,
                        value: nonEmpty(data.town)
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addressTranslations().county,
                        value: nonEmpty(data.county)
                    }
                ]
            }
        ]

    return {
        header: translations().deliveryAddress,
        rows
    } as SummaryBlockData
}

export function processingDetailsFormDataToSummaryBlock (data: ProcessingDetailsFormData, acquirers: SelectItem[], categoryCodes: SelectItem[], currencies: SelectItem[], submissionTypes: SelectItem[], brandingOrganisations: SelectItem[]): SummaryBlockData {
    const acquirer = acquirers ? acquirers.find(item => item.value as string === data?.acquirer) : null
    const amexAcquirer = acquirers && data.amexAcquirer ? acquirers.find(item => item.value as string === data.amexAcquirer) : null
    const categoryCode = categoryCodes ? categoryCodes.find(item => item.value as string === data.merchantCategoryCode) : null
    const currency = currencies ? currencies.find(item => item.value === data.currency) : null
    const preferredSubmission = submissionTypes ? submissionTypes.find(item => item.value as string === data.preferredSubmission) : null
    const brandingOrganisation = brandingOrganisations ? brandingOrganisations.find(item => item.value === data.brandingOrganisationId) : null

    const amexMerchantId = data.amexMerchantId ? data.amexMerchantId : ''

    return {
        header: translations().processingDetails,
        rows: [
            {
                fields: [
                    {
                        fields: [
                            {
                                type: 'field',
                                label: processingTranslations().acquirer,
                                value: acquirer ? acquirer.label : '',
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: processingTranslations().streamlineCompanyId,
                                value: data.streamlineCompanyId,
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: processingTranslations().preferredSubmission,
                        value: preferredSubmission ? preferredSubmission.label : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: processingTranslations().merchantId,
                        value: data.merchantId,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: processingTranslations().accountName,
                        value: data.accountName,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: processingTranslations().merchantCategoryCode,
                        value: categoryCode ? categoryCode.label : '',
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: processingTranslations().brandingOrganisation,
                        value: brandingOrganisation ? brandingOrganisation.label : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: processingTranslations().currency,
                        value: currency ? currency.label : '',
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'subHeader',
                        value: data.amexMerchantId ? processingTranslations().americanExpress : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: processingTranslations().accountNumber,
                        value: data.bankAccountNumber,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: processingTranslations().merchantId,
                        value: data.amexMerchantId ? data.amexMerchantId : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: processingTranslations().sortCode,
                        value: formatSortCode(data.sortCode),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: processingTranslations().acquirer,
                        value: amexAcquirer ? amexAcquirer.label : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        fields: [
                            {
                                type: 'field',
                                label: processingTranslations().cashBack,
                                value: data.cashBack,
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: processingTranslations().gratuity,
                                value: data.gratuity,
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    },
                    {
                        fields: [
                            {
                                type: 'field',
                                label: processingTranslations().accountNumber,
                                value: data.amexBankAccountNumber ? data.amexBankAccountNumber : '',
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: processingTranslations().sortCode,
                                value: formatSortCode(data.amexSortCode),
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    } as SummaryBlockData
}

export function productsFormDataToSummaryBlock (
    data: ProductsFormData, bundles: Bundle[], acquirerAssignedTids: AcquirerAssignedTid[] = []
): SummaryBlockData {
    const bundle = bundles ? bundles.find(item => item.bundleCode as string === data.bundleCode) : null
    return {
        header: translations().products,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: productsTranslations().bundle,
                        value: nonEmpty(data.bundleCode),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: productsTranslations().quantity,
                        value: nonEmpty(data.quantity),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: productsTranslations().bundleDescription,
                        value: nonEmpty(bundle ? bundle.description : ''),
                        span: {xs: 12, sm: 12}
                    },
                    acquirerAssignedTids.length && {
                        type: 'field',
                        label: productsTranslations().acquirerAssignedTids,
                        value: nonEmpty(acquirerAssignedTids.map(({tid}) => `${tid} `)),
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}
