import React from "react";
const SvgConfiguration = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" {...props}>
    <defs>
      <path
        d="M10 0H2C.9 0 0 .9 0 2v16c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zm2 10H4v2h8v-2zm0 4H4v2h8v-2zM2 18h12V7H9V2H2v16z"
        id="configuration_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="configuration_svg__b" fill="#fff">
        <use xlinkHref="#configuration_svg__a" />
      </mask>
      <path
        fillOpacity={0.54}
        fill="#000"
        fillRule="nonzero"
        mask="url(#configuration_svg__b)"
        d="M-4-2h23v24H-4z"
      />
    </g>
  </svg>
);
export default SvgConfiguration;
