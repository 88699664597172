// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3pTdumtzJBAr8q4WqRY5PQ {\n  display: flex;\n  align-items: center; }\n\n.ed7hZfKbxRPduFW4SRuae {\n  display: flex;\n  flex-direction: column; }\n\n.aJE9yU3Ddq4dAN5rQQT4w .ant-pagination {\n  margin-bottom: 8px; }\n\n.U7XCt_BNSk3iF5PIePG2Q {\n  margin-top: 20px;\n  margin-bottom: 20px; }\n", ""]);
// Exports
exports.locals = {
	"flexAlignCenter": "_3pTdumtzJBAr8q4WqRY5PQ",
	"lastCommunication": "ed7hZfKbxRPduFW4SRuae",
	"list": "aJE9yU3Ddq4dAN5rQQT4w",
	"listActions": "U7XCt_BNSk3iF5PIePG2Q"
};
module.exports = exports;
