import { CommunicationMediaTypes } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/CommunicationMediaTypes'
import translations from '../translations'

export const getCommunicationMethodLabelByType = (type: CommunicationMediaTypes) => {
    switch (type) {
        case CommunicationMediaTypes.bluetooth:
            return translations().bluetooth
        case CommunicationMediaTypes.cellular:
            return translations().cellular
        case CommunicationMediaTypes.ethernet:
            return translations().ethernet
        case CommunicationMediaTypes.notSet:
            return translations().notSet
        case CommunicationMediaTypes.wifi:
            return translations().wifi
    }
}
