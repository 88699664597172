import { createDeliveryDetails, DeliveryDetails } from '~/code/POS/pages/NewOrder/models/partner/DeliveryDetails'
import { PartnerStore } from '~/code/POS/pages/NewOrder/models/partner/PartnerStore'
import { createMerchant, Merchant } from './Merchant'

export interface OTTSendMerchant {
    existingMerchantId?: number
    newMerchant?: Merchant
    deliveryDetails: DeliveryDetails
    stores: PartnerStore[]
    hardwareOnlyProductDetails?: {
        priceOverride?: number
        commission?: number
        bundleId: string
        quantity: number
    }[]
}

export function createOTTSendMerchant(): OTTSendMerchant {
    return {
        existingMerchantId: 0,
        newMerchant: createMerchant(),
        deliveryDetails: createDeliveryDetails(),
        stores: []
    }
}
