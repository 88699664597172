import translations from './translations'

translations.add('en', {
    acquirer: 'Acquirer',
    acquirerRequired: 'Acquirer is required',
    acquirerTooltip: 'An acquirer is the financial institution that processes credit or debit card payment processing services on behalf of the merchant. The acquirer will have issued a Merchant ID for card processing.',
    streamlineCompanyId: 'Streamline company ID',
    streamlineCompanyIdRequired: 'Streamline company ID is required',
    streamlineCompanyIdInvalid: 'Streamline company ID must be 6 or 8 digits',
    streamlineCompanyIdTooltip: 'Mandatory if using Worldpay as the acquirer.',
    merchantId: 'Merchant ID',
    merchantIdRequired: 'Merchant ID is required',
    accountNumber: 'Bank account number',
    accountNumberRequired: 'Bank account number is required',
    accountNumberInvalid: 'Bank account number must be 8 digits',
    sortCode: 'Sort code',
    sortCodeRequired: 'Sort code is required',
    sortCodeInvalid: 'Sort code must be 6 digits',
    cashBack: 'Max. cashback',
    enableCashBack: 'Enable cashback',
    cashBackRequired: 'Max. cashback is required',
    cashBackInvalid: 'Invalid number',
    cashBackMaxExceeded: 'Max. cashback value is 100.00',
    gratuity: 'Max. gratuity',
    enableGratuity: 'Enable gratuity',
    gratuityRequired: 'Max. gratuity is required',
    preferredSubmission: 'Preferred submission',
    preferredSubmissionRequired: 'Preferred submission is required',
    preferredSubmissionTooltip: '“Main” Submission sees funds submitted at midnight, “Extended” submits later in the morning. The precise times may vary dependent upon the acquirer. For more details contact Optomany.',
    brandingOrganisation: 'Custom branding selection',
    brandingOrganisationTooltip: 'If customer branding is required it will have been configured with Optomany prior to ordering, if your branding does not appear and you believe it should please contact Optomany.',
    merchantCategoryCode: 'Merchant category code',
    merchantCategoryCodeRequired: 'Merchant category code is required',
    merchantCategoryCodeTooltip: 'A Merchant Category Code (MCC) is a four-digit number used to classify a business by the types of goods or services it provides. Optomany require this information to ensure the correct settings are applied to every merchant for their category.\n' +
        '\n' +
        'The MCC should be provided by your acquirer.',
    accountName: 'Account name',
    accountNameRequired: 'Account name is required',
    accountNameTooltip: 'Used as the “Merchant Department” name in OCC. This is also displayed to the user when logging into axept GO and more than one Merchant Department is available.',
    currency: 'Currency',
    currencyRequired: 'Currency is required',
    currencyTooltip: 'This must be the currency which has been agreed with the Acquirer when setting up the merchant agreement. An invalid entry will affect the processing of authorisations and/or settlements.',
    americanExpress: 'American Express',
    amexMerchantIdTooltip: 'Merchant ID issued by American Express - different from the main acquirer MID.',
    back: 'Back',
    nextStep: 'Next',
    preathorization: 'Preathorization'
})
