// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gMqP48DXMZSzltzKg0fdJ {\n  display: inline; }\n", ""]);
// Exports
exports.locals = {
	"InlineSelect": "gMqP48DXMZSzltzKg0fdJ"
};
module.exports = exports;
