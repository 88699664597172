// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qnQSYgd1-CbOpap3MyuG7 {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 24px;\n  color: #00a0dc;\n  padding-top: 8px;\n  padding-bottom: 16px; }\n  ._3qnQSYgd1-CbOpap3MyuG7._10UiJInvxXDWIST4p56jhn {\n    font-weight: 600; }\n  ._3qnQSYgd1-CbOpap3MyuG7._2iKX5Bf8mtv8TFUmphlkUz {\n    font-size: 14px;\n    line-height: 21px; }\n", ""]);
// Exports
exports.locals = {
	"SubHeader": "_3qnQSYgd1-CbOpap3MyuG7",
	"bold": "_10UiJInvxXDWIST4p56jhn",
	"small": "_2iKX5Bf8mtv8TFUmphlkUz"
};
module.exports = exports;
