import React from 'react'
import { observer } from 'mobx-react'
import { Button } from '~/code/common/components'
import translations from './translations'
import { NoCompanySelectedProps } from './props'
import styles from './NoCompanySelected.scss'

export const NoCompanySelected = observer((props: NoCompanySelectedProps) => {
    return <div className={styles.NoCompanySelected} key={'content'}>
        <div className={styles.noCompanySelectedText}>
            { props.noCompanySelectedText || translations().noCompanySelected }
        </div>
        <div className={styles.buttonWrapper}>
            <Button onClick={props.onSelectCompanyClick}>
                {translations().selectEmployeeCompany}
            </Button>
        </div>
    </div>
})
