import { createMerchantDetailsFormData, MerchantDetailsFormData } from '~/code/POS/pages'

export interface OTTSendMerchantDetailsFormData extends MerchantDetailsFormData {
    accountManager: number
    financeContactUseMain: boolean
    financeContactName?: string
    financeContactSurname?: string
    financeContactJobTitle?: string
    financeContactEmail?: string
    financeContactPhoneNumber?: string
}

export function createOTTSendMerchantDetailsFormData(): OTTSendMerchantDetailsFormData {
    return {
        ...createMerchantDetailsFormData(),
        accountManager: 0,
        financeContactUseMain: true,
        financeContactName: '',
        financeContactSurname: '',
        financeContactJobTitle: '',
        financeContactEmail: '',
        financeContactPhoneNumber: ''
    }
}
