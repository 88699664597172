import React from 'react'
import translations from './translations'

translations.add('en', {
    orderingAs: 'ordering on behalf of',
    onHold: 'company is on hold',
    onHoldDescription: 'The selected company is on hold',
    selectCompany: 'Select Company',
    searchBy: 'Select your company from the list',
    companyPurposeExplanation: '* This is the company you will act on behalf of',
    confirm: 'Confirm',
    areYouSureToChangeCompany: <span>Are you sure you want to change the company?<br/>All of your entered data and search results will be reset.</span>,
    yes: 'Yes',
    cancel: 'Cancel',
    directMerchant: 'Direct Merchant',
    resellerCustomer: 'Reseller Customer',
    agent: 'Agent',
    partner: 'Partner',
    dealerEposSupplier: 'Dealer / EPOS Supplier',
    referrer: 'Referrer',
    parentCompany: 'Parent Company',
    iso: 'ISO',
    acquirer: 'Acquirer'
})