import React from 'react'
import classNames from 'classnames'
import { LabelProps } from './props'
import styles from './Label.scss'

export const Label = (props: LabelProps) => {
    const labelClass = classNames(styles.Label, {[styles.Label__info]: props.type === 'info', [styles.Label__error]: props.type === 'error'}, props.className)
    return <span className={labelClass}>
        { props.children }
    </span>
}
