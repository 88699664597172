import React from "react";
const SvgLock = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <mask
      id="lock_svg__a"
      maskUnits="userSpaceOnUse"
      x={4}
      y={1}
      width={16}
      height={22}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.5h1c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-10c0-1.1.9-2 2-2h1v-2c0-2.76 2.24-5 5-5s5 2.24 5 5v2zm-5-5c-1.66 0-3 1.34-3 3v2h6v-2c0-1.66-1.34-3-3-3zm-6 17v-10h12v10H6zm8-5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#lock_svg__a)">
      <path fill="#00A0DC" d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default SvgLock;
