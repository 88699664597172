import React from 'react'
import translations from './translations'

translations.add('en', {
    merchantSchedules: 'Merchant Schedules',
    maintenanceSchedule: 'Maintenance Schedule',
    deviceStatusSchedule: 'Device Status Schedule',
    inThisPeriodsDevicesAllowedToInstall: 'This is the period in which devices are allowed to install apps or app/OS updates',
    settingsApplyToAllDevices: 'These settings apply to all devices under the merchant',
    daysWhenMaintenancePerformed: 'Days When Maintenance Can Be Performed:',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    timeWhenMaintenancePerformed: 'Time When Maintenance Can Be Performed:',
    start: 'Start',
    end: 'End',
    everyDayOfWeek: 'Every day of week',
    save: 'Save',
    configSaved: 'Configurations saved successfully',
    errorOnSavingConfig: 'Unfortunately this request cannot be actioned. If this continues to happen, please seek support.',
    thisIsHowRegularlyYourDeviceProvide: 'This is how regularly your device provides metrics to the central host',
    ethernet: 'Ethernet',
    whenConnectedViaEthernetConnection: (value) => `When connected via an Ethernet connection, the device will contact the central host ${value}`,
    whenConnectedViaWiFiConnection: (value) => `When connected via a Wi-Fi connection, the device will contact the central host ${value}`,
    whenConnectedViaBluetoothConnection: (value) => `When connected via a Bluetooth connection, the device will contact the central host ${value}`,
    whenConnectedViaCellularConnection: (value) => `When connected via a Cellular connection, the device will contact the central host ${value}`,
    whenConnectedViaConnectionTypeEvery: (type, value, time) => `When connected via a ${type} connection, the device will contact the central host every ${value} ${time}`,
    wiFi: 'Wi-fi',
    bluetooth: 'Bluetooth',
    cellular: 'Cellular',
    minutes: 'Minutes',
    hours: 'Hours',
    loading: 'Loading',
    days: 'Days'
})
