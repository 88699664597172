import moment, { Moment } from 'moment'

export const getDisabledMinutes = (selectedHour: number, startTime: Moment) => {
    const minutes = []
    if (selectedHour === moment(startTime).hours()){
        for (let i = 0; i < moment(startTime).minutes(); i++){
            minutes.push(i)
        }
    }
    return minutes
}

export const getDisabledHours = (startTime: Moment) => {
    const hours = []
    for (let i = 0; i < moment(startTime).hours(); i++){
        hours.push(i)
    }
    return hours
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const formatBytes = (x) => {

    let length = 0
    let num = parseInt(x, 10) || 0

    while (num >= 1024 && ++length){
        num = num / 1024
    }

    return(num.toFixed(num < 10 && length > 0 ? 1 : 0) + ' ' + units[length])
}

export const download = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}
