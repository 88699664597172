import React from "react";
const SvgReport = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" {...props}>
    <defs>
      <path
        d="M10.507 2h3.715C15.2 2 16 2.9 16 4v14c0 1.1-.8 2-1.778 2H1.778c-.125 0-.24-.01-.356-.03a1.738 1.738 0 01-.898-.55A2.153 2.153 0 010 18V4c0-.28.053-.54.142-.77.09-.24.222-.46.382-.64.24-.27.552-.47.898-.55.116-.03.231-.04.356-.04h3.715C5.867.84 6.844 0 8 0c1.156 0 2.133.84 2.507 2zM3.556 8V6h8.888v2H3.556zm8.888 4v-2H3.556v2h8.888zm-2.666 2H3.556v2h6.222v-2zM8 1.75c.364 0 .667.34.667.75s-.303.75-.667.75c-.364 0-.667-.34-.667-.75s.303-.75.667-.75zM1.778 18h12.444V4H1.778v14z"
        id="report_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="report_svg__b" fill="#fff">
        <use xlinkHref="#report_svg__a" />
      </mask>
      <path
        fillOpacity={0.54}
        fill="#000"
        fillRule="nonzero"
        mask="url(#report_svg__b)"
        d="M-2-2h21v24H-2z"
      />
    </g>
  </svg>
);
export default SvgReport;
