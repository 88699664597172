import { action, computed, makeObservable, observable } from 'mobx'
import { IMerchantDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/IMerchantDetailsFormStore'
import { createMerchantDetailsFormData, MerchantDetailsFormData } from '~/code/POS/pages'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { SelectItem } from '~/code/common/components'
import { AppStore } from '~/code/AppStore'
import { ConfigCountry } from '~/code/POS/pages/NewOrder/models/constants'

export class MerchantDetailsFormStore implements IMerchantDetailsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler, merchantDetailsFormData?: MerchantDetailsFormData) {
        makeObservable(this, {
            merchantDetailsFormData: observable,
            canGoBack: observable,
            countries: computed,
            configCountry: computed,
            isNewOrderForSoleTrader: computed,
            setMerchantDetailsFormData: action,
            goBack: action
        })

        this.merchantDetailsFormData = merchantDetailsFormData || createMerchantDetailsFormData()
    }

    static stepName: string = 'merchantDetails'

    merchantDetailsFormData: MerchantDetailsFormData = null

    canGoBack: boolean = false

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get configCountry(): ConfigCountry {
        return AppStore.newOrderStore?.placeOrderConfigStore.country
    }

    get isNewOrderForSoleTrader(): boolean {
        return AppStore.newOrderStore?.isNewOrderForSoleTrader
    }

    setMerchantDetailsFormData = (data: MerchantDetailsFormData) => {
        this.merchantDetailsFormData = {...this.merchantDetailsFormData, ...data, isNew: true}
        this.formSubmitHandler.handleFormSubmit(data, MerchantDetailsFormStore.stepName)
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(MerchantDetailsFormStore.stepName)
    }

}
