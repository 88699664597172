import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    orderReference: string
    noReference: string
    orderingOnBehalfOf: string
    orderSummary: string
    placeOrder: string
    information: string
    deliveryAddress: string
    processingDetails: string
    products: string
    merchantDetails: string
    orderDetails: string
    deliveryDetails: string
    sameAsMerchantAddress: string
    loadingTermsAndConditions: string
    termsAndConditionsFileUrl: string
    agreeWithTerms: (company: string) => string
    confirmationRequired: string
    back: string
    cancelOrder: string
    cancelOrderConfirmText: (orderReference: string) => JSX.Element
    confirmCancelOrder:  string
    cancelCancelOrder: string
}>()

