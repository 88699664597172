enum MATRIX_MESSAGE {
    NOT_PROVIDED = `"Not Provided"`,
    NOT_CHECKED = `"Not Checked"`,
    MATCHED = `"Matched"`,
    NOT_MATCHED = `"Not Matched"`,
    PARTIAL_MATCH = `"Partial Match"`
}

export enum AVS_OR_CSC_MATRIX_REJECT_CODE_COMBINED {
    NOT_CHECKED_AND_NOT_PROVIDED = 6,
    NOT_CHECKED_AND_MATCHED = 12,
    MATCHED_AND_NOT_MATCHED = 24
}

export enum AVS_OR_CSC_MATRIX_REJECT_CODE {
    NONE = 0,
    NOT_PROVIDED = 2,
    NOT_CHECKED = 4,
    MATCHED = 8,
    NOT_MATCHED = 16,
    PARTIAL_MATCH = 32
}

export const AVS_OR_CSC_MATRIX_MESSAGE = {
    [AVS_OR_CSC_MATRIX_REJECT_CODE.NONE]: 'Reject None',
    [AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_PROVIDED]: `Reject ${MATRIX_MESSAGE.NOT_PROVIDED} Results`,
    [AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_CHECKED]: `Reject ${MATRIX_MESSAGE.NOT_CHECKED} Results`,
    [AVS_OR_CSC_MATRIX_REJECT_CODE.MATCHED]: `Reject ${MATRIX_MESSAGE.MATCHED} Results`,
    [AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_MATCHED]: `Reject ${MATRIX_MESSAGE.NOT_MATCHED} Results`,
    [AVS_OR_CSC_MATRIX_REJECT_CODE.PARTIAL_MATCH]: `Reject ${MATRIX_MESSAGE.PARTIAL_MATCH} Results`,
    // Combined messages
    [AVS_OR_CSC_MATRIX_REJECT_CODE_COMBINED.NOT_CHECKED_AND_NOT_PROVIDED]: `Rejecting ${MATRIX_MESSAGE.NOT_PROVIDED} (${AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_PROVIDED}) and ${MATRIX_MESSAGE.NOT_CHECKED} (${AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_CHECKED}) results.`,
    [AVS_OR_CSC_MATRIX_REJECT_CODE_COMBINED.NOT_CHECKED_AND_MATCHED]: `Rejecting ${MATRIX_MESSAGE.NOT_CHECKED} (${AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_CHECKED}) and ${MATRIX_MESSAGE.MATCHED} (${AVS_OR_CSC_MATRIX_REJECT_CODE.MATCHED}) results.`,
    [AVS_OR_CSC_MATRIX_REJECT_CODE_COMBINED.MATCHED_AND_NOT_MATCHED]: `Rejecting ${MATRIX_MESSAGE.MATCHED} (${AVS_OR_CSC_MATRIX_REJECT_CODE.MATCHED}) and ${MATRIX_MESSAGE.NOT_MATCHED} (${AVS_OR_CSC_MATRIX_REJECT_CODE.NOT_MATCHED}) results.`
}

export enum PA_MATRIX_REJECT_CODE_COMBINED {
    U_BOTH_ENROLMENT_AND_AUTHENTICATION = 3
}

export enum PA_MATRIX_REJECT_CODE {
    NONE = 0,
    U_ON_ENROLMENT = 1,
    U_ON_AUTHENTICATION = 2
}

export const PA_MATRIX_MESSAGE = {
    [PA_MATRIX_REJECT_CODE.NONE]: 'Reject None',
    [PA_MATRIX_REJECT_CODE.U_ON_ENROLMENT]: `Reject "U" on Enrolment`,
    [PA_MATRIX_REJECT_CODE.U_ON_AUTHENTICATION]: `Reject "U" on Authentication`,
    // Combined messages
    [PA_MATRIX_REJECT_CODE_COMBINED.U_BOTH_ENROLMENT_AND_AUTHENTICATION]: `Rejecting "U" responses on both Enrolments and Authentications.`
}