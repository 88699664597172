import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    requestSummary: string

    back: string
    cancelRequest: string
    cancelRequestConfirmText: JSX.Element | string
    confirmCancelRequest:  string
    cancelCancelRequest: string

    submitRequest: string
}>()

