// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Xu0JUfN4N4uszFRkaRsYY .ant-upload {\n  width: 256px;\n  height: 256px; }\n", ""]);
// Exports
exports.locals = {
	"uploadWithPreview": "_1Xu0JUfN4N4uszFRkaRsYY"
};
module.exports = exports;
