import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    noProductAdded: string
    products: string
    addProduct: string
    nextStep: string
    back: string
}>()

