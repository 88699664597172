// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3H89ghF-s0VW5l5IwEv3be {\n  width: 100%; }\n  ._1sfRC3JCwGBVMG-O1UdHPt {\n    background-color: transparent; }\n  ._3bEbaNIYoef0H2QxPhjsHU {\n    text-transform: uppercase; }\n  ._3H89ghF-s0VW5l5IwEv3be ::-webkit-input-placeholder {\n    /* WebKit browsers */\n    text-transform: none; }\n  ._3H89ghF-s0VW5l5IwEv3be :-moz-placeholder {\n    /* Mozilla Firefox 4 to 18 */\n    text-transform: none; }\n  ._3H89ghF-s0VW5l5IwEv3be ::-moz-placeholder {\n    /* Mozilla Firefox 19+ */\n    text-transform: none; }\n  ._3H89ghF-s0VW5l5IwEv3be :-ms-input-placeholder {\n    /* Internet Explorer 10+ */\n    text-transform: none; }\n  ._3H89ghF-s0VW5l5IwEv3be ::placeholder {\n    /* Recent browsers */\n    text-transform: none; }\n  ._3En5iQ0kGspYxFMGpSHGa4 {\n    text-transform: lowercase; }\n    ._3En5iQ0kGspYxFMGpSHGa4::-webkit-input-placeholder {\n      text-transform: initial; }\n    ._3En5iQ0kGspYxFMGpSHGa4:-moz-placeholder {\n      text-transform: initial; }\n    ._3En5iQ0kGspYxFMGpSHGa4::-moz-placeholder {\n      text-transform: initial; }\n    ._3En5iQ0kGspYxFMGpSHGa4:-ms-input-placeholder {\n      text-transform: initial; }\n  ._21FZSOUd-b19-2MoG7WWin {\n    padding-top: 16px;\n    display: flex;\n    flex-flow: row-reverse; }\n  ._3OUZe4m-5tXHAdKAlqEo9l {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #000000;\n    padding-bottom: 13px; }\n  ._1jYonCX505zXHcdjWLV1zP {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 22px;\n    color: #000000; }\n", ""]);
// Exports
exports.locals = {
	"OrderTypeForm": "_3H89ghF-s0VW5l5IwEv3be",
	"OrderTypeForm_inputContainer": "_1sfRC3JCwGBVMG-O1UdHPt",
	"OrderTypeForm_inputUppercase": "_3bEbaNIYoef0H2QxPhjsHU",
	"OrderTypeForm_email": "_3En5iQ0kGspYxFMGpSHGa4",
	"OrderTypeForm_submitButtonContainer": "_21FZSOUd-b19-2MoG7WWin",
	"OrderTypeForm_subgroupTitle": "_3OUZe4m-5tXHAdKAlqEo9l",
	"OrderTypeForm_label": "_1jYonCX505zXHcdjWLV1zP"
};
module.exports = exports;
