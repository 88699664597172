import React from 'react'
import { IHeaderStore } from '~/code/POS/layouts/MainLayout/components/Header/IHeaderStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { OwnerGroup } from '~/code/common/layouts/MainLayout/components/Header/components'
import { AppStore } from '~/code/AppStore'
import dnaPaymentsLightLogo from '~/assets/img/dna_payments_logo_light.svg'
import dnaPaymentsDarkLogo from '~/assets/img/dna_payments_logo_dark.svg'
import optomanyLightLogo from '~/assets/img/logo_light.png'
import optomanyDarkLogo from '~/assets/img/logo_dark.png'
import { isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'

export class HeaderStore implements IHeaderStore {

    private parentStore: IAppStore

    constructor (parentStore) {
        makeObservable(this, {
            ownerGroups: observable,
            selectedOwnerGroup: observable,
            isSideMenuVisible: computed,
            userInfo: computed,
            determineAndSelectOwnerGroup: action,
            selectOwnerGroup: action,
            setSelectedOwnerGroupById: action,
            logOut: action,
            toggleSideMenu: action,
            clear: action
        })

        this.parentStore = parentStore
    }

    ownerGroups: OwnerGroup[] = [
        {
            id: 10000,
            domain: 'optomany.com',
            lightLogo: <img src={optomanyLightLogo}/>,
            darkLogo: <img src={optomanyDarkLogo}/>
        },
        {
            id: 10001,
            domain: '123send.com',
            lightLogo: <img src={dnaPaymentsLightLogo} alt={'dna-payments-logo-light'}/>,
            darkLogo: <img src={dnaPaymentsDarkLogo} alt={'dna-payments-logo-dark'}/>
        }
    ]

    selectedOwnerGroup: OwnerGroup = AppStore?.userInfo?.ownerGroupId ? this.ownerGroups.find(item => item.id === AppStore.userInfo.ownerGroupId) : null // this.ownerGroups[1]

    get isSideMenuVisible() {
        return AppStore.isSideMenuVisible
    }

    get userInfo() {
        return AppStore.userInfo
    }

    determineAndSelectOwnerGroup = (email: string) => {
        const emailParts = email?.split('@')
        if (emailParts?.length > 1 && !isEmpty(emailParts[1])) {
            const domain = emailParts[1]
            const ownerGroup = this.ownerGroups.find(item => item.domain.toLowerCase() === domain.toLowerCase())
            this.selectOwnerGroup(ownerGroup || null)
        }
    }

    selectOwnerGroup = (ownerGroup: OwnerGroup) => {
        this.selectedOwnerGroup = ownerGroup
        AppStore.userInfo = {...AppStore.userInfo, ownerGroupId: this.selectedOwnerGroup?.id}
    }

    setSelectedOwnerGroupById = (ownerGroupId: number) => {
        const ownerGroup = this.ownerGroups.find(item => item.id === ownerGroupId)
        if (!isEmpty(ownerGroup)) {
            this.selectOwnerGroup(ownerGroup)
        }
    }

    logOut  = () => {
        AppStore.logOut()
    }

    toggleSideMenu = () => {
        AppStore.toggleSideMenu()
    }

    clear = () => {
        this.selectedOwnerGroup = null
    }

}
