import React from 'react'
import page from 'page'
import { Button } from '~/code/common/components'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models/Constants'
import translations from '~/code/ODIN/components/SelectMerchant/translations'

export const SelectMerchant = () => {
    return (
        <Button
            onClick={() => {
                page(LOCATION_ODIN_PATH_NAMES.MERCHANT_SELECTION)
            }}
        >
            {translations().selectMerchant}
        </Button>
    )
}
