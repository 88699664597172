import React from 'react'
import { CopyText } from '~/code/common/components'
import { SummaryBlockData } from '~/code/common/components/SummaryBlock/models/SummaryBlockData'
import translations from '~/code/POS/pages/NewOrder/components/common/OrderSummary/translations'
import merchantDetailsTranslations from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/translations'
import addProductFormTranslations from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/translations'
import orderDetailsTranslations from '~/code/POS/pages/NewOrder/components/partner/OrderDetailsForm/translations'
import deliveryDetailsTranslations from '~/code/POS/pages/NewOrder/components/partner/DeliveryDetailsForm/translations'
import { SelectItem } from '~/code/common/components/Select'
import { isEmpty } from 'dna-common'
import { MerchantDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/models'
import { OrderDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/OrderDetailsForm/models'
import { DeliveryDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/DeliveryDetailsForm/models'
import { ProductListFormData } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/ProductListFormData'
import { Product } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/Product'
import { RowData } from '~/code/common/components/SummaryBlock/models/RowData'
import { FieldData } from '~/code/common/components/SummaryBlock/models/FieldData'
import { SDStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDStore'
import { SDDepartment } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDDepartment'
import { OTTSendBundle } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/models/OTTSendBundle'
import { formatPriceWithCurrency } from '~/code/services/ui-helpers'


function nonEmpty(value: any) {
    return isEmpty(value) ? ' ' : value
}

function formatSortCode(value: string) {
    if (isEmpty(value) || value.length < 6) return ''
    return `${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 6)}`
}

export function orderDetailsToSummaryBlock (orderReference: string, employeeCompany: string): SummaryBlockData {
    return {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: 'Order reference',
                        renderValue: () => {
                            return orderReference ? <CopyText text={ orderReference } /> : translations().noReference
                        },
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: 'Ordering on behalf of',
                        value: employeeCompany,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function merchantDetailsFormDataToSummaryBlock (data: MerchantDetailsFormData, countries: SelectItem[]): SummaryBlockData {
    const country = countries ? countries.find(item => item.value === data.country) : null
    return {
        header: translations().merchantDetails,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().companyName,
                        value: data.companyName,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().vatNumber,
                        value: data.vatNumber,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().tradingAs,
                        value: nonEmpty(data.tradingAs),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().companyNumber,
                        value: data.companyNumber,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: merchantDetailsTranslations().address
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().country,
                        value: nonEmpty(country ? country.label : ''),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().town,
                        value: nonEmpty(data.town),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().postcode,
                        value: nonEmpty(data.postcode),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().county,
                        value: nonEmpty(data.county),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().addressLine1,
                        value: nonEmpty(data.addressLine1),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().addressLine2,
                        value: nonEmpty(data.addressLine2),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: merchantDetailsTranslations().contact
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().contactName,
                        value: nonEmpty(data.contactName),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().email,
                        value: nonEmpty(data.contactEmail),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().contactSurname,
                        value: nonEmpty(data.contactSurname),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().contactPhoneNumber,
                        value: nonEmpty(data.contactPhoneNumber),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().jobTitle,
                        value: nonEmpty(data.contactJobTitle),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsTranslations().contactMobilePhone,
                        value: nonEmpty(data.contactMobilePhone),
                        span: {xs: 12, sm: 12}
                    }
                ]

            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: merchantDetailsTranslations().communicationPreferences
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        value: data.receiveSalesAndMarketing ?
                            merchantDetailsTranslations().receiveSalesAndMarketing.charAt(0).toUpperCase() + merchantDetailsTranslations().receiveSalesAndMarketing.slice(1) :
                            merchantDetailsTranslations().dontReceiveSalesAndMarketing
                    }
                ]
            }
        ]
    }
}

export function productListFormDataToSummaryBlock (data: ProductListFormData, partnerStores: SDStore[]): SummaryBlockData {
    const generateCardSchemes = (department: SDDepartment) => {
        const schemes = []
        const pushToArray = (array, item) => {
            if (isEmpty(array) || array[0].length === 2) {
                array.push([item])
            } else {
                array[array.length - 1].push(item)
            }
        }

        if (department.amexMerchantID) {
            const item = {
                header: addProductFormTranslations().americanExpress,
                merchantId: department.amexMerchantID,
                acquirer: department.amexAcquirerName,
                bankAccountNumber: department.amexBankAccountNumber,
                sortCode: department.amexSortCode
            }
            pushToArray(schemes, item)
        }
        if (department.flexecashMerchantID) {
            const item = {
                header: addProductFormTranslations().flexecash,
                merchantId: department.flexecashMerchantID,
                acquirer: department.flexecashAcquirerName,
                bankAccountNumber: department.flexecashBankAccountNumber,
                sortCode: department.flexecashSortCode
            }
            pushToArray(schemes, item)
        }
        if (department.dinersDiscoverMerchantID) {
            const item = {
                header: addProductFormTranslations().dinersDiscover,
                merchantId: department.dinersDiscoverMerchantID,
                acquirer: department.dinersDiscoverAcquirerName,
                bankAccountNumber: department.dinersDiscoverBankAccountNumber,
                sortCode: department.dinersDiscoverSortCode
            }
            pushToArray(schemes, item)
        }

        return schemes
    }

    const schemesToRowData = (array): RowData[] => {
        return array.map(innerArray => {
            if (innerArray.length === 2) {
                return [{
                    fields: [
                        {
                            type: 'subHeaderSmall',
                            value: innerArray[0].header,
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'subHeaderSmall',
                            value: innerArray[1].header,
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: addProductFormTranslations().merchantId,
                            value: innerArray[0].merchantId,
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: addProductFormTranslations().merchantId,
                            value: innerArray[1].merchantId,
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: addProductFormTranslations().acquirer,
                            value: innerArray[0]?.acquirer,
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: addProductFormTranslations().acquirer,
                            value: innerArray[1]?.acquirer,
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            fields: [
                                {
                                    type: 'field',
                                    label: addProductFormTranslations().accountNumber,
                                    value: innerArray[0].bankAccountNumber,
                                    span: {xs: 12, sm: 12}
                                },
                                {
                                    type: 'field',
                                    label: addProductFormTranslations().sortCode,
                                    value: innerArray[0].sortCode,
                                    span: {xs: 12, sm: 12}
                                }
                            ],
                            span: {xs: 12, sm: 12}
                        },
                        {
                            fields: [
                                {
                                    type: 'field',
                                    label: addProductFormTranslations().accountNumber,
                                    value: innerArray[1].bankAccountNumber,
                                    span: {xs: 12, sm: 12}
                                },
                                {
                                    type: 'field',
                                    label: addProductFormTranslations().sortCode,
                                    value: innerArray[1].sortCode,
                                    span: {xs: 12, sm: 12}
                                }
                            ],
                            span: {xs: 12, sm: 12}
                        }
                    ]
                }]
            }

            return [{
                fields: [
                    {
                        type: 'subHeaderSmall',
                        value: innerArray[0].header,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().merchantId,
                        value: innerArray[0].merchantId,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().acquirer,
                        value: innerArray[0]?.acquirer,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        fields: [
                            {
                                type: 'field',
                                label: addProductFormTranslations().accountNumber,
                                value: innerArray[0].bankAccountNumber,
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: addProductFormTranslations().sortCode,
                                value: innerArray[0].sortCode,
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    }
                ]
            }]
        }).reduce((resultArray, currentArray) => {
            return [...resultArray, ...currentArray]
        }, [])
    }

    const productToRowData = (product: Product, store: SDStore): RowData[] => {
        const department: SDDepartment = store.departments.find(item => item.id === product.departmentId)
        const cardSchemes = generateCardSchemes(department)
        const cardSchemeSummary = schemesToRowData(cardSchemes)

        const bundlesSummary: RowData[] = product.bundles.map(item => {
            return {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().bundle,
                        value: item.bundleCode,
                        span: {xs: 12, sm: 6}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().bundleDescription,
                        value: item.bundleDescription,
                        span: {xs: 12, sm: 10}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().quantity,
                        value: item.bundleQuantity,
                        span: {xs: 12, sm: 4}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().customPrice,
                        value: (item as OTTSendBundle).bundleCustomPrice !== undefined && (item as OTTSendBundle).bundleCustomPrice !== null
                            ? formatPriceWithCurrency((item as OTTSendBundle).bundleCustomPrice) : '',
                        span: {xs: 12, sm: 4}
                    },
                    !isEmpty((item as OTTSendBundle).acquirerAssignedTids) && {
                        type: 'field',
                        label: addProductFormTranslations().acquirerAssignedTid,
                        value: (item as OTTSendBundle).acquirerAssignedTids ? (item as OTTSendBundle).acquirerAssignedTids.map(tid => tid.tid).join(', ') : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        })

        const resultRowDataArray: RowData[] = [
            {
                fields: [
                    {
                        type: 'subHeaderBold',
                        value: addProductFormTranslations().store
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().storeName,
                        value: store.name,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().telephoneNumber,
                        value: store.telephoneNumber,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().companyNumber,
                        value: nonEmpty(store.companyRegistrationNumber),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().email,
                        value: store.email,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().vatNumber,
                        value: nonEmpty(store.vatNumber),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeaderSmall',
                        value: addProductFormTranslations().address
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().country,
                        value: nonEmpty(store.countryName),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().town,
                        value: store.town,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().postcode,
                        value: nonEmpty(store.postcode),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().county,
                        value: store.county,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().addressLine1,
                        value: nonEmpty(store.addressLine1),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().axeptConnectCloud,
                        value: store.useAxeptConnectCloud ? addProductFormTranslations().yes : addProductFormTranslations().no,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().addressLine2,
                        value: nonEmpty(store.addressLine2),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().addressLine3,
                        value: nonEmpty(store.addressLine3),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'divider'
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeaderBold',
                        value: addProductFormTranslations().department
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().departmentName,
                        value: department.name,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().merchantCategoryCode,
                        value: department.merchantCategoryCodeLabel,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().currency,
                        value: department.currencyName,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeaderSmall',
                        value: addProductFormTranslations().transactionTypes
                    }
                ]
            },
            {
                fields: [
                    {
                        fields: [
                            {
                                type: 'field',
                                label: addProductFormTranslations().purchase,
                                value: department.purchase,
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: addProductFormTranslations().refund,
                                value: department.refund,
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    },
                    {
                        fields: [
                            {
                                type: 'field',
                                label: addProductFormTranslations().cashBack,
                                value: department.cashBack,
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: addProductFormTranslations().gratuity,
                                value: department.gratuity,
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeaderSmall',
                        value: addProductFormTranslations().cardSchemeDetails
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().visaMID,
                        value: department.visaMID,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().acquirer,
                        value: department.acquirerName,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().visaDebitMID,
                        value: department.visaDebitMID,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: addProductFormTranslations().streamlineCompanyId,
                        value: department.streamlineCompanyId,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().mastercardMID,
                        value: department.mastercardMID,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        fields: [
                            {
                                type: 'field',
                                label: addProductFormTranslations().accountNumber,
                                value: department.bankAccountNumber,
                                span: {xs: 12, sm: 12}
                            },
                            {
                                type: 'field',
                                label: addProductFormTranslations().sortCode,
                                value: department.sortCode,
                                span: {xs: 12, sm: 12}
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().mastercardDebitMID,
                        value: department.mastercardDebitMID,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().maestroDebitMID,
                        value: department.maestroDebitMID,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            ...cardSchemeSummary,
            {
                fields: [
                    {
                        type: 'divider'
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeaderBold',
                        value: addProductFormTranslations().bundles
                    }
                ]
            },
            ...bundlesSummary
        ]

        if (department.acquirerAssignedTIDs && department.acquirerAssignedTIDs.length > 0) {
            resultRowDataArray.splice( 17, 1, {
                fields: [
                    {
                        type: 'field',
                        label: addProductFormTranslations().visaMID,
                        value: department.visaMID,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        fields: [
                            {
                                fields: [
                                    {
                                        type: 'field',
                                        label: addProductFormTranslations().acquirer,
                                        value: department.acquirerName,
                                        span: {xs: 12, sm: 12}
                                    },
                                    {
                                        type: 'field',
                                        label: addProductFormTranslations().acquirerIssuedTid,
                                        value: department.acquirerAssignedTIDs[0],
                                        span: {xs: 12, sm: 12}
                                    }
                                ]
                            }
                        ],
                        span: {xs: 12, sm: 12}
                    }
                ]
            })
        }

        return resultRowDataArray
    }

    const generateCollapsibleRows = (): FieldData[] => {
        return data.products.map(product => {
            const store = partnerStores.find(item => item.id === product.storeId)
            const department = store.departments.find(item => item.id === product.departmentId)
            return {
                type: 'collapsiblePanel',
                value: {
                    header: `${store.name} / ${department.name} / ${product.bundles.map(bundle => `${bundle.bundleCode} (x${bundle.bundleQuantity})`).join(', ')}`,
                    content: productToRowData(product, store)
                }
            }
        })
    }

    const result: SummaryBlockData = {
        header: translations().products,
        rows: [
            {
                type: 'collapse',
                fields: generateCollapsibleRows()
            }
        ]
    }

    return result
}

export function orderDetailsFormDataToSummaryBlock (data: OrderDetailsFormData, brandingOrganisations?: SelectItem[]): SummaryBlockData {
    const brandingOrganisation = brandingOrganisations ? brandingOrganisations.find(item => item.value === data.brandingOrganisationId) : null

    const result: SummaryBlockData = {
        header: translations().orderDetails,
        rows: [
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: orderDetailsTranslations().orderInformation
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: orderDetailsTranslations().poNumber,
                        value: data.poNumber,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: orderDetailsTranslations().activationDate,
                        value: data.requestedDeliveryAndOrActivationDate,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: orderDetailsTranslations().brandingOrganisation,
                        value: brandingOrganisation ? brandingOrganisation.label : '',
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: orderDetailsTranslations().contactsForActivation
                    }
                ]
            }
        ]
    }

    if (data.useMainContact) {
        result.rows.push(
            {
                fields: [
                    {
                        type: 'field',
                        value: orderDetailsTranslations().useMainContact.charAt(0).toUpperCase() + orderDetailsTranslations().useMainContact.slice(1)
                    }
                ]
            }
        )
    } else {
        result.rows.push(
            {
                fields: [
                    {
                        type: 'field',
                        label: orderDetailsTranslations().companyName,
                        value: data.companyName,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: orderDetailsTranslations().firstName,
                        value: data.firstName,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: orderDetailsTranslations().email,
                        value: data.email,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: orderDetailsTranslations().surname,
                        value: data.surname,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        )
    }

    return result
}

export function deliveryDetailsFormDataToSummaryBlock (data: DeliveryDetailsFormData, countries: SelectItem[]): SummaryBlockData {
    const result: SummaryBlockData = {
        header: translations().deliveryDetails,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        value: deliveryDetailsTranslations()[data.deliverTo]
                    }
                ]
            }
        ]
    }

    switch (data.deliverTo) {
        case 'deliverToMerchant': {
            result.rows.push(
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().deliveryInstructions,
                            value: data.deliveryInstructions,
                            span: {xs: 24, sm: 24}
                        }
                    ]
                }
            )
            break
        }
        case 'deliverToStore': {
            result.rows.push(
                {
                    fields: [
                        {
                            type: 'subHeader',
                            value: deliveryDetailsTranslations().storeDeliveryInstructions
                        }
                    ]
                }
            )

            data.storeDeliveryInstructions.forEach(item => {
                result.rows.push(
                    {
                        fields: [
                            {
                                type: 'field',
                                label: item.storeName,
                                value: item.deliveryInstructions || data.deliveryInstructions,
                                span: {xs: 24, sm: 24}
                            }
                        ]
                    }
                )
            })

            break
        }
        case 'deliverToNewAddress': {
            const country = countries ? countries.find(item => item.value === data.country) : null
            result.rows.push(
                {
                    fields: [
                        {
                            type: 'subHeader',
                            value: deliveryDetailsTranslations().address
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().country,
                            value: nonEmpty(country ? country.label : ''),
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().tradingAs,
                            value: nonEmpty(data.tradingAs),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().postcode,
                            value: nonEmpty(data.postcode),
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().deliveryName,
                            value: nonEmpty(data.deliveryName),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().addressLine1,
                            value: nonEmpty(data.addressLine1),
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().deliveryInstructions,
                            value: data.deliveryInstructions,
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().addressLine2,
                            value: nonEmpty(data.addressLine2),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().town,
                            value: nonEmpty(data.town),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().county,
                            value: nonEmpty(data.county),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'subHeader',
                            value: deliveryDetailsTranslations().contact
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().contactName,
                            value: nonEmpty(data.contactName),
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().email,
                            value: nonEmpty(data.email),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().contactSurname,
                            value: nonEmpty(data.contactSurname),
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().contactPhoneNumber,
                            value: nonEmpty(data.contactPhoneNumber),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().jobTitle,
                            value: nonEmpty(data.jobTitle),
                            span: {xs: 12, sm: 12}
                        },
                        {
                            type: 'field',
                            label: deliveryDetailsTranslations().contactMobilePhone,
                            value: nonEmpty(data.contactMobilePhone),
                            span: {xs: 12, sm: 12}
                        }
                    ]

                }
            )
            break
        }
    }

    return result
}
