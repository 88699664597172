// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3SL_P9osGuUmnryLYYTXjQ .ant-form-item-explain {\n  text-align: right; }\n", ""]);
// Exports
exports.locals = {
	"DeliveryInstructions": "_3SL_P9osGuUmnryLYYTXjQ"
};
module.exports = exports;
