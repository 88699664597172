import { AppstoreOutlined, FieldTimeOutlined, MobileOutlined } from '@ant-design/icons'
import React from 'react'
import { MenuItemStatus } from '~/code/common/layouts/MainLayout/components/SideMenu/models'
import translations from '~/code/common/layouts/MainLayout/components/SideMenu/translations'
import { SideMenuItemTitle } from '../components/SideMenuItemTitle'
import { MerchantSearchItem } from '../stores/MerchantSelectionStore/models/MerchantSearchItem'
import { LOCATION_ODIN_PATH_NAMES } from './Constants'

export const getOdinEmployeeSideMenuItems = (merchant: MerchantSearchItem, cancelMerchant: () => void, permissions: string[] = []) => {
    return [
        {
            icon: <AppstoreOutlined />,
            title: merchant ? <SideMenuItemTitle text={merchant.merchantName} onCancelClick={cancelMerchant} /> : translations().noMerchantSelected,
            status: 'active' as MenuItemStatus,
            route: LOCATION_ODIN_PATH_NAMES.MERCHANT_SELECTION
        },
        {
            icon: <FieldTimeOutlined />,
            title: translations().merchantSchedule,
            status: permissions.includes('odin.merchantSchedules.view') ? 'active' as MenuItemStatus : 'soon' as MenuItemStatus,
            route: LOCATION_ODIN_PATH_NAMES.MERCHANT_SCHEDULE
        },
        {
            icon: <MobileOutlined />,
            title: translations().deviceManagement,
            status: permissions.includes('odin.deviceManagement.view') ? 'active' as MenuItemStatus : 'soon' as MenuItemStatus,
            route: LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT
        },
        {
            icon: <MobileOutlined />,
            title: translations().applicationManagement,
            status: permissions.includes('odin.packageManagement.view') ? 'active' as MenuItemStatus : 'soon' as MenuItemStatus,
            route: LOCATION_ODIN_PATH_NAMES.APPLICATION_MANAGEMENT
        },
        {
            icon: <MobileOutlined />,
            title: translations().firmwareManagement,
            status: permissions.includes('odin.firmwareManagement.view') ? 'active' as MenuItemStatus : 'soon' as MenuItemStatus,
            route: LOCATION_ODIN_PATH_NAMES.FIRMWARE_MANAGEMENT
        }
    ]
}
