import { DEFAULT_COLOUR } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/models/constants'

export interface CheckoutSettingsFormData {
    usePartnerSettings: boolean
    integrationTypes: string[]
    mandatoryFields: {
        accountCity: boolean
        accountEmail: boolean
        accountCountry: boolean
        accountStreet1: boolean
        accountLastName: boolean
        accountFirstName: boolean
        accountPostalCode: boolean
    },
    customStyle: {
        primaryColor: string
        headerLogoUrl: string
        headerLogoText: string
        headerStartColor: string
        actionButtonColor: string
        actionButtonBackground: string
        headerFinishColor: string
        closeButtonOpacity: number
        closeButtonColor: string
        showPoweredByBlock: boolean
        headerLogoTextColor: string
        showHeaderBottomLine: boolean
        showOrderNumberBlock: boolean
        headerLogoMobileWidth: number
        headerLogoDesktopWidth: number
        headerLogoMobileHeight: number
        headerSecondaryLogoUrl: string
        headerLogoDesktopHeight: number
        headerLogoTextSecondary: string
        actionButtonBorderRadius: number
        headerLogoTextMarginLeft: number
        showOrderDescriptionBlock: boolean
        showPaymentSecuredByBlock: boolean
        headerSecondaryLogoPosition: string
    }
}

export function createCheckoutSettingsFormData() {
    return {
        usePartnerSettings: true,
        integrationTypes: ['seamless'],
        customStyle: {
            primaryColor: DEFAULT_COLOUR,
            headerStartColor: DEFAULT_COLOUR,
            actionButtonColor: DEFAULT_COLOUR,
            actionButtonBackground: DEFAULT_COLOUR,
            headerFinishColor: DEFAULT_COLOUR,
            closeButtonColor: DEFAULT_COLOUR,
            headerLogoTextColor: DEFAULT_COLOUR,
            actionButtonBorderRadius: 0,
            closeButtonOpacity: 1,
            headerLogoUrl: '',
            headerLogoText: 'Header logo text',
            headerSecondaryLogoUrl: '',
            headerLogoTextSecondary: 'Secondary logo text',
            headerSecondaryLogoPosition: 'right',
            showPoweredByBlock: true,
            showHeaderBottomLine: true,
            showOrderNumberBlock: true,
            headerLogoMobileWidth: 72,
            headerLogoDesktopWidth: 72,
            headerLogoMobileHeight: 72,
            headerLogoDesktopHeight: 72,
            headerLogoTextMarginLeft: 14,
            showOrderDescriptionBlock: true,
            showPaymentSecuredByBlock: true
        },
        mandatoryFields: {
            accountCity: false,
            accountEmail: false,
            accountCountry: false,
            accountStreet1: false,
            accountLastName: false,
            accountFirstName: false,
            accountPostalCode: false
        }
    }
}
