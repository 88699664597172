import React, { forwardRef } from 'react'
import { Select as AntSelect } from 'antd'
import { SelectProps, Select } from '~/code/common/components'
import styles from './BundleSelect.scss'

export const BundleSelect = forwardRef<any, SelectProps<string>>((props, forwardedRef) => {
  return <div className={styles.BundleSelect}>
    <Select ref={ forwardedRef } { ...props }>
      {
        props.selectItems.map(({ value, label, params }) => (
          <AntSelect.Option
              value={ value }
              title={ label }
              key={ value }
              className={styles.BundleOption}
          >
            { label }
            <div className={styles.subLabel}>{params.description}</div>
          </AntSelect.Option>
        ))
      }
    </Select>
  </div>
})
