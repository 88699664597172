import React, { useContext, useEffect, useState } from 'react'
import { Col, Empty, Popconfirm, Row, Tabs } from 'antd'
import { PlusOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { Button, Modal, SearchBar, Tab } from '~/code/common/components'
import { OrderSummary } from '~/code/POS/pages/NewOrder/components/common/OrderSummary/OrderSummary'
import { Progress } from '~/code/POS/pages/NewOrder/components/common/Progress/Progress'
import { Page } from '~/code/common/pages/Page'
import { ButtonWithConfirmation } from '~/code/common/components/ButtonWithConfirmation'
import { PlaceOrderConfiguration } from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration'
import { AppStore, AppStoreContext } from '~/code/AppStore'
import translations from './translations'
import styles from './NewOrder.scss'

const { TabPane } = Tabs

export const NewOrder = observer(() => {
    const store  = useContext(AppStoreContext).newOrderStore
    const [wrapperId, setWrapperId] = useState(store.constructor.name)

    // TODO it's invoked only once, is it even needed?
    useEffect(() => {
        if (store?.steps && store?.steps[store.currentStep] === 'placingOrder' && store?.progressStore?.status !== 'loading') {
            store.clearAndSetUp()
        }
        setWrapperId(store.constructor.name)
    }, [store])

    const setShowSearchBar = (value: boolean) => {
        store.setShouldShowSearchBar(value)
    }

    const setShowForm = (value: boolean) => {
        store.setShouldShowForm(value)
    }

    const cancelOrder = () => {
        store.setShouldShowForm(false)
        store.setShouldShowSearchBar(true)
        store.clearAndSetUp()
        store.placeOrderConfigStore.clearAndSetup()
    }

    const onAddManuallyButtonClick = () => {
        (document.activeElement as HTMLElement).blur()
        store.clearAndSetUp()
        setShowForm(true)
    }

    const wrappedTabPane = ({order, title, key, children} : {order: number, title: string, key: string, children: any}) => {
        return <TabPane
            tab = {
                <Tab
                    order={order}
                    title={title}
                    key={key}
                    isActive={ store.createOrderSteps[store.currentCreateOrderStep].key === key }
                />
            }
            key={key}
            disabled={ store.createOrderSteps[store.currentCreateOrderStep].key !== key }
        >
            { children }
        </TabPane>
    }

    if (store.shouldResetForm) {
       setTimeout( () => {store.setShouldResetForm(false)}, 100)
    }

    // TODO: move these forms to the TabPane component
    const headerClassName = `${styles.NewOrder_header}`
    const isOrderingPrevented = store.isOrderingPrevented

    const renderCompanyName  = () => {
        if (store.manuallyEnteredCompanyName) return store.manuallyEnteredCompanyName
        if (store.selectedCompanyName) return store.selectedCompanyName
        switch (store.placeOrderConfigStore.organisationType) {
            case 'company': {
                return translations().company
            }
            default: {
                return translations().soleTrader
            }
        }
    }

    const renderSearchInputPlaceholder = () => {
        if (store.placeOrderConfigStore.merchantType === 'existing') {
            if (AppStore.userInfo?.companyType === 'partner') {
                return translations().existingPartnerMerchantSearchText
            }
            return translations().existingMerchantSearchText
        }
        if (store.placeOrderConfigStore.country === 'roi') {
            return translations().newROIMerchantSearchText
        }
        return translations().newUKMerchantSearchText
    }

    const renderSearchInputNoDataContent = () => {
        const notFoundInSystem = store.placeOrderConfigStore.merchantType === 'existing' ? translations().dataNotFoundInExisting :
            store.placeOrderConfigStore.country === 'roi' ? translations().dataNotFoundInROI : translations().dataNotFoundInCH
        return (
            <div className={styles.searchInputNoDataContent}>
                { notFoundInSystem }
                {
                    store.placeOrderConfigStore.merchantType !== 'existing' &&
                    <div className={styles.addManuallyButtonContainer}>
                        {
                            store.shouldShowForm ?
                            <Popconfirm
                                placement={'topRight'}
                                title={translations().areYouSureToEnterManually}
                                onConfirm={onAddManuallyButtonClick}
                                onCancel={() => (document.activeElement as HTMLElement).blur()} // remove focus from the search bar
                                okText={translations().yesAddManually}
                                cancelText={translations().noDontAddManually}
                            >
                                <span className={styles.addManuallyButton}>
                                    <PlusOutlined /> {translations().enterManually}
                                </span>
                            </Popconfirm> :
                            <span className={styles.addManuallyButton} onClick={onAddManuallyButtonClick}>
                                <PlusOutlined /> {translations().enterManually}
                            </span>
                        }
                    </div>
                }
            </div>
        )
    }

    const renderContent = () => {
        if (isOrderingPrevented) {
            return (
                <div className={styles.orderingPrevented}>
                    <div className={styles.iconContainer}>
                        <WarningOutlined className={styles.icon}/>
                    </div>
                    {translations().orderingPrevented}
                </div>
            )
        } else {
            return <>
                <div className={styles.NewOrder_companySelection}>
                    <Row>
                        <Col xs={24} sm={ (store.selectedCompanyName || store.shouldShowForm) ? 18 : 24}>
                            <PlaceOrderConfiguration store={store.placeOrderConfigStore}/>
                        </Col>
                        { (store.selectedCompanyName || store.shouldShowForm) && <Col xs={24} sm={6}>
                            <div className={styles.NewOrder_clearButtonWrapper}>
                                <ButtonWithConfirmation
                                    type={'link'}
                                    statusType={'danger'}
                                    popConfirmTitle={ translations().cancelOrderConfirmText }
                                    confirmButtonTitle={ translations().confirmCancelOrder }
                                    cancelButtonTitle={ translations().cancelCancelOrder }
                                    onConfirm={ cancelOrder }
                                >
                                    <><DeleteOutlined /> {translations().cancelOrder} </>
                                </ButtonWithConfirmation>
                            </div>
                        </Col> }
                    </Row>
                </div>
                {
                    store.shouldShowSearchBar &&
                    <div className={styles.NewOrder_companySelection}>
                        <SearchBar
                            store={store}
                            disabled={store.isNoCompanySelected}
                            // hideSearchType={true}
                            searchInputPlaceholder={renderSearchInputPlaceholder()}
                            searchInputNoDataContent={renderSearchInputNoDataContent()}
                            searchInputValue={null}
                            searchInputAllowClear={false}/>
                    </div>
                }
                {
                    store.selectedItem &&
                    <Modal
                        key={'existingMerchantModal'}
                        visible={store.isExistingMerchantModalOpen}
                        title={translations().merchantMatch}
                        onOk={store.closeExistingMerchantModal}
                        onCancel={store.closeExistingMerchantModal}
                        footer={[
                            <Button
                                className={styles.NewOrder_existingMerchantModal_outlinedButton}
                                key={'ok'}
                                outline={'outlined'}
                                onClick={store.closeExistingMerchantModal}
                            >
                                {translations().continueAsNewMerchant}
                            </Button>,
                            <Button key={'cancel'} type={'primary'} onClick={store.changeToExistingMerchantFlow}>
                                {translations().orderForThisMerchant}
                            </Button>
                        ]}
                        type={'info'}
                        smallerWidth
                    >
                        <div>{translations().matchFound} - {store.selectedItem.title}</div>
                        <div>{translations().companyNumber}: {store.selectedItem.identifier}</div>
                    </Modal>
                }
                {
                    !store.shouldResetForm && store.shouldShowForm &&
                    <div className={styles.NewOrder_companyInfo}>
                        <div className={styles.NewOrder_header}>{renderCompanyName()}</div>
                        <div className={styles.NewOrder_tabsContainer}>
                            {
                                store.steps && <Tabs activeKey={store.createOrderSteps[store.currentCreateOrderStep].key}>
                                    { store.createOrderSteps.map(item => wrappedTabPane(item)) }
                                </Tabs>
                            }
                        </div>
                    </div>
                }
                {
                    !store.shouldShowForm &&
                    <Empty className={styles.empty} description={translations().noMerchantSelectedDescription}/>
                }
            </>
        }
    }

    return <Page>
        <div className={styles.NewOrder} id={wrapperId}>
            { store.steps &&
                <>
                    {
                        store.steps[store.currentStep] === 'creatingOrder' && <div>
                            <div className={headerClassName} key={'header'}>{translations().placeOrder}</div>
                            <div className={styles.NewOrder_content} key={'content'}>
                                {
                                    renderContent()
                                }
                            </div>
                        </div>
                    }
                    {
                        store.steps && store.steps[store.currentStep] === 'summary' && <div>
                            <OrderSummary store={store}/>
                        </div>
                    }
                    {
                        store.steps && store.steps[store.currentStep] === 'placingOrder' && <div>
                            <Progress store={store.progressStore}/>
                        </div>
                    }
                </>
            }
        </div>
    </Page>
})
