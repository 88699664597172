import { action, makeObservable, observable, reaction } from 'mobx'
import { LoginFormData } from '~/code/common/pages/Login/models/LoginFormData'
import { ILoginFormStore } from '~/code/common/pages/Login/components/LoginForm/ILoginFormStore'
import { post } from 'back-connector'
import { LoginResponse } from '~/code/common/pages/Login/models'
import { message } from 'antd'
import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { ILoginParentStore } from '~/code/common/pages/Login/models/ILoginParentStore'
import translations from '~/code/common/pages/Login/translations'
import { PortalType } from '~/code/common/layouts/MainLayout/models/PortalType'
import { set } from 'store-connector'

export class LoginStore implements ILoginFormStore {

    constructor (parentStore: ILoginParentStore) {
        this.parentStore = parentStore
        this.isLoading = false

        makeObservable(this, {
            isLoading: observable,
            isPortalSelectable: observable,
            login: observable,
            portalType: observable,
            onSubmit: action,
            setLogin: action,
            setPortalType: action,
            setIsPortalSelectable: action
        })

        reaction(() => this.login, () => {
            const hasOdinPermission = ['@optomany.com', '@123send.net', '@dnapayments.com', '@dnapaymentsgroup.com',
                '@dnapaymentsgroup.co.uk', '@activepayments.co.uk', '@ukprgroup.com', '@ofimedia.eu']
            const isPortalSelectable = hasOdinPermission
                .map(domain => this.login.endsWith(domain))
                .find(isSelectable => isSelectable)

            if (isPortalSelectable) {
                this.setIsPortalSelectable(true)
            } else {
                this.setIsPortalSelectable(false)
                this.setPortalType('POS')
            }
        })
    }

    private parentStore: ILoginParentStore

    isLoading: boolean
    isPortalSelectable: boolean
    login: string
    portalType: PortalType = 'POS'

    public onSubmit(loginFormData: LoginFormData) {
        this.isLoading = true
        if (this.portalType === 'POS') {
            this.authPortal(loginFormData, '/auth/authenticate/authenticate-with-client')
        }

        if (this.portalType === 'ODIN') {
            this.authPortal(loginFormData, '/odin-auth/api/Authentication/authenticate-with-client')
        }
    }

    setIsPortalSelectable = (isPortalSelectable: boolean) => {
        this.isPortalSelectable = isPortalSelectable
    }

    setLogin = (login: string) => {
        this.login = login
    }

    setPortalType = (portalType: PortalType) => {
        this.portalType = portalType
    }

    authPortal(loginFormData: LoginFormData, portalUrl: string) {
        checkHTTPStatus(post<LoginResponse>(portalUrl, loginFormData))
            .then(response => {
                if (this.portalType === 'POS') {
                    set('portalType', 'POS')
                    this.parentStore.onPosLogin(response as LoginResponse)
                }

                if (this.portalType === 'ODIN') {
                    set('portalType', 'ODIN')
                    this.parentStore.onOdinLogin(response as LoginResponse)
                }

                this.isLoading = false
            })
                .catch((error: Error) => {
                message.error(translations().loginError)
                this.isLoading = false
            })
    }
}
