import React, { forwardRef } from 'react'
import { Select as AntSelect } from 'antd'
import { isEmpty } from 'dna-common'
import { SelectItem, OptionGroup } from './models'
import { SelectProps } from './props'
import styles from './Select.scss'

const { Option, OptGroup } = AntSelect

export const Select = forwardRef<any, SelectProps<any>>((props, forwardedRef) => {
    const {selectItems, optionGroups, size, ...rest} = props
    const renderOptions = (options: SelectItem[]) => {
        return isEmpty(options) ? null :
            options.map((option) => (
            <Option
                value={ option.value }
                title={ option.label }
                key={ option.value }
            >{ option.label }</Option>
        ))
    }

    const renderOptionGroups = (_optionGroups: OptionGroup[]) => {
        return isEmpty(_optionGroups) ? null :
            _optionGroups.map((optionGroup) => (
            <OptGroup
                label={ optionGroup.label }
            >
                { renderOptions(optionGroup.options) }
            </OptGroup>
        ))
    }

    return <div className={ styles.Select }>
        <AntSelect ref={ forwardedRef } { ...rest } size={size ? size : 'large'} value={rest?.currentValue ? rest?.currentValue : rest?.value}>
            { props.children ||
            (props.optionGroups ?
                renderOptionGroups(props.optionGroups) :
                renderOptions(props.selectItems))
            }
        </AntSelect>
    </div>
})
