import { StepModel } from '~/code/common/components/Steps/models/StepModel'
import translations from '~/code/POS/pages/OrderHistory/pages/OrderDetails/translations'
import { isEmpty } from 'dna-common'

export function generateOrderStatusHistory(orderStatusId: number): StepModel[] {
    const orderStatusHistory: StepModel[] = []
    switch (orderStatusId) {
        case 8: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'passed'})
            orderStatusHistory.push({label: translations().dispatching, status: 'passed'})
            orderStatusHistory.push({label: translations().completed, status: 'passed'})
            break
        }
        case 7: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'passed'})
            orderStatusHistory.push({label: translations().dispatching, status: 'active'})
            orderStatusHistory.push({label: translations().completed, status: 'waiting'})
            break
        }
        case 3: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'active'})
            orderStatusHistory.push({label: translations().dispatching, status: 'waiting'})
            orderStatusHistory.push({label: translations().completed, status: 'waiting'})
            break
        }
        case 2:
        case 6: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'active'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'waiting'})
            orderStatusHistory.push({label: translations().dispatching, status: 'waiting'})
            orderStatusHistory.push({label: translations().completed, status: 'waiting'})
            break
        }
        case 5:
        case 10:
        case 12: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'passed'})
            orderStatusHistory.push({label: translations().dispatching, status: 'passed'})
            orderStatusHistory.push({label: translations().orderFailed, status: 'failed'})
            break
        }
        case 4: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationFailed, status: 'failed'})
            orderStatusHistory.push({label: translations().dispatching, status: 'waiting'})
            orderStatusHistory.push({label: translations().completed, status: 'waiting'})
            break
        }
        case 1: {
            orderStatusHistory.push({label: translations().orderSubmissionFailed, status: 'failed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'waiting'})
            orderStatusHistory.push({label: translations().dispatching, status: 'waiting'})
            orderStatusHistory.push({label: translations().completed, status: 'waiting'})
            break
        }
        case 13: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'passed'})
            orderStatusHistory.push({label: translations().reversed, status: 'waiting'})
            break
        }
        case 14: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'passed'})
            orderStatusHistory.push({label: translations().cancellationRequested, status: 'active'})
            break
        }
        case 15: {
            orderStatusHistory.push({label: translations().orderSubmitted, status: 'passed'})
            orderStatusHistory.push({label: translations().validationCompleted, status: 'passed'})
            orderStatusHistory.push({label: translations().cancelled, status: 'cancelled'})
            break
        }
    }

    return orderStatusHistory
}

export function mapOrderStatus (orderStatus: string) {
    if (isEmpty(orderStatus)) return 'failed'
    const stringToTest = orderStatus.toLowerCase()
    if (stringToTest.indexOf('fail') >= 0) {
        return 'failed'
    } else if (stringToTest.indexOf('complete') >= 0 || stringToTest.indexOf('success') >= 0) {
        return 'success'
    } else if (stringToTest.indexOf('reversed') >= 0) {
        return 'reversed'
    } else if (stringToTest.indexOf('cancellation requested') >= 0) {
        return 'cancellationRequested'
    } else if (stringToTest.indexOf('cancelled') >= 0) {
        return 'cancelled'
    } else {
        return 'other'
    }
}
