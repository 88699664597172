import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    address: string
    contact: string
    deliverToStore: string
    deliverToMerchant: string
    deliverToNewAddress: string
    addressLine1: string
    addressLine1Required: string
    addressLine2: string
    addressLine2Required: string
    addressLine3: string
    addressLine3Required: string
    town: string
    townRequired: string
    county: string
    countyRequired: string
    country: string
    countryRequired: string
    postcode: string
    postcodeRequired: string
    postcodeInvalid: string
    tradingAs: string
    tradingAsRequired: string
    deliveryName: string
    deliveryNameRequired: string
    useMerchantAddress: string
    contactName: string
    contactNameRequired: string
    contactSurname: string
    contactSurnameRequired: string
    jobTitle: string
    jobTitleRequired: string
    email: string
    emailInvalid: string
    emailRequired: string
    contactPhoneNumber: string
    contactPhoneNumberInvalid: string
    contactPhoneNumberRequired: string
    contactMobilePhone: string
    contactMobilePhoneInvalid: string
    contactMobilePhoneRequired: string
    deliveryInstructions: string
    setCustomDeliveryInstructions: string
    storeDeliveryInstructions: string
    back: string
    nextStep: string
}>()

