import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    dispatchReport: string
    refereeName: string
    merchantStoreName: string
    serialNumber: string
    tamperBagNo: string
    simNo: string
    mid: string
    tid: string
    dispatchedDateTime: string
    consignmentNo: string
    productCode: string
    name: string
    orderReference: string
    noReference: string
}>()
