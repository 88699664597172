import moment from 'moment'

export function stringToDate(value: string) {
    let result = null
    try {
        result = value && moment(value)
    } catch (exception) {
        return null
    }

    return result
}
