// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Hg3ajf3ozs2LmbQE22xD8 {\n  width: 100%;\n  min-height: 100%;\n  background-color: #F5F5F5; }\n  ._33dCSXkB6qmVYwbElpDUE8 {\n    background-color: #00a0dc;\n    width: 100%;\n    margin: 0px auto;\n    padding: 0;\n    position: fixed;\n    z-index: 12; }\n  ._3BccLkDfPegifK3u3epOL3 {\n    max-width: 1220px;\n    width: 100%;\n    margin: 0px auto; }\n  .iXgJ8yxcHp9PGbFBiNf8P {\n    width: 100%;\n    max-width: 1220px;\n    margin: 0 auto;\n    background: #F5F5F5; }\n    .iXgJ8yxcHp9PGbFBiNf8P .ant-affix {\n      z-index: 1; }\n  ._2Hg3ajf3ozs2LmbQE22xD8 ._3LBye0S-fD-xFkqmcmXp-h {\n    width: 276px;\n    height: 100%; }\n    ._2Hg3ajf3ozs2LmbQE22xD8 ._1f_lUD0scPJz1lZrFLMtyr {\n      display: none; }\n  ._3aMvpaz7wa8P-we1G7MnxA {\n    position: fixed;\n    height: 100%;\n    background: #F5F5F5;\n    width: 276px !important;\n    min-width: 276px !important;\n    max-width: 276px !important;\n    padding-top: calc(64px + 8px);\n    z-index: 2; }\n    ._3Lzuro9Pqnx3HG7iCegXPa {\n      display: none; }\n  ._3hq1u0Uj_HI8iZ_AgsDTUk {\n    background: #FFFFFF;\n    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.085);\n    padding-top: 64px;\n    z-index: 3; }\n  ._2omZiPFUnbom-dpIMJo9IX {\n    background: #FFFFFF; }\n  .LRAVdk5jxT7juEN6hU66i {\n    background: #FFFFFF;\n    padding: 0;\n    text-align: center;\n    color: var(--antd-wave-shadow-color); }\n  @media (max-width: 1090px) {\n    ._3aMvpaz7wa8P-we1G7MnxA {\n      z-index: 4; }\n      ._1wRgq6WcGfOM-dp2D51jN_ {\n        display: block;\n        box-shadow: 3px 0 3px 0px rgba(0, 0, 0, 0.085); }\n    ._2Hg3ajf3ozs2LmbQE22xD8 ._3LBye0S-fD-xFkqmcmXp-h {\n      display: none; }\n      ._2Hg3ajf3ozs2LmbQE22xD8 .J5wDk0M548RPPT-MiAAmo {\n        display: block;\n        position: absolute; } }\n", ""]);
// Exports
exports.locals = {
	"MainLayout": "_2Hg3ajf3ozs2LmbQE22xD8",
	"MainLayout_headerWrapper": "_33dCSXkB6qmVYwbElpDUE8",
	"MainLayout_header": "_3BccLkDfPegifK3u3epOL3",
	"MainLayout_body": "iXgJ8yxcHp9PGbFBiNf8P",
	"siderWrapper": "_3LBye0S-fD-xFkqmcmXp-h",
	"siderWrapper__hidden": "_1f_lUD0scPJz1lZrFLMtyr",
	"MainLayout_sider": "_3aMvpaz7wa8P-we1G7MnxA",
	"MainLayout_sider__hidden": "_3Lzuro9Pqnx3HG7iCegXPa",
	"MainLayout_contentWrapper": "_3hq1u0Uj_HI8iZ_AgsDTUk",
	"MainLayout_content": "_2omZiPFUnbom-dpIMJo9IX",
	"MainLayout_footer": "LRAVdk5jxT7juEN6hU66i",
	"MainLayout_sider__visible": "_1wRgq6WcGfOM-dp2D51jN_",
	"siderWrapper__visible": "J5wDk0M548RPPT-MiAAmo"
};
module.exports = exports;
