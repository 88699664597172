import React from 'react'
import { Col, Form, Row } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons/lib'
import { Button } from '~/code/common/components'
import translations from './translations'
import { FormNavigationProps } from './props'
import styles from './FormNavigation.scss'

export const FormNavigation = ({ canGoBack, isNextStepButtonDisabled, goBack }: FormNavigationProps) => {
    return <Row gutter={16} className={styles.FormNavigation}>
        <Col xs={12}>
            {
                canGoBack &&
                <div className={styles.goBackButtonContainer}>
                    <Form.Item>
                        <Button outline={'outlined'} onClick={() => goBack()}>
                            <ArrowLeftOutlined />{translations().back}
                        </Button>
                    </Form.Item>
                </div>
            }
        </Col>
        <Col xs={12}>
            <div className={styles.submitButtonContainer}>
                <Form.Item>
                    <Button className={null} htmlType={'submit'} disabled={isNextStepButtonDisabled}>
                        {translations().nextStep}<ArrowRightOutlined />
                    </Button>
                </Form.Item>
            </div>
        </Col>
    </Row>
}
