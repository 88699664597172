import { action, makeObservable, observable, runInAction } from 'mobx'
import { error } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { fetchSystemInfo } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { SystemInfo } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/SystemInfo'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class SystemInfoStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            systemInfo: observable,
            loadSystemInfo: action,
            setSystemInfo: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    isLoading: boolean = false
    systemInfo: SystemInfo = {} as SystemInfo

    setSystemInfo = (systemInfo: SystemInfo) => {
        this.systemInfo = systemInfo
    }

    public loadSystemInfo = (deviceId: number) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        fetchSystemInfo(deviceId)
            .then(response => {
                this.setSystemInfo(response.result)
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
