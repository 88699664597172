import { OrderType } from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/models/OrderType'

export interface OrderTypeFormData {
    orderType: OrderType
    storeId?: number
    departmentId?: number
}

export function createOrderTypeFormData(orderType?: OrderType): OrderTypeFormData {
    return {
        orderType: orderType || 'newBundle',
        storeId: 0,
        departmentId: 0
    }
}
