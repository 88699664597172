import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    dailyTransactionLimitByValue: string
    dailyTransactionLimitByVolume: string
    monthlyTransactionLimitByValue: string
    monthlyTransactionLimitByVolume: string
    maximumSingleTransactionByValue: string
    maximumRefundByValue: string
    unlimited: string
    fieldRequired: string
    max13Chars: string
    max2DecimalPlaces: string
}>()

