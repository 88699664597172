// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1fov3EMK4MjP8nzJOyVFQo .ant-card-body {\n  padding-top: 12px;\n  padding-bottom: 12px; }\n\n._1fov3EMK4MjP8nzJOyVFQo .ant-card-actions > li > span {\n  display: inline; }\n\n._1fov3EMK4MjP8nzJOyVFQo ._19dXTwXAf8O9qsgeKWoh5p {\n  border: 1px solid #fa8c16; }\n  ._1fov3EMK4MjP8nzJOyVFQo ._19dXTwXAf8O9qsgeKWoh5p .pGq03zUDoK58-MLUGsJzI {\n    color: #fa8c16;\n    border: none;\n    margin-right: 10px;\n    cursor: pointer; }\n\n._1fov3EMK4MjP8nzJOyVFQo .YFhWHmfIMqKXoEAN_ztpr:hover {\n  color: #D23153; }\n\n._1fov3EMK4MjP8nzJOyVFQo ._5kjydFlXzh5i7gT8oFJj1 {\n  cursor: default; }\n  ._1fov3EMK4MjP8nzJOyVFQo ._5kjydFlXzh5i7gT8oFJj1:hover {\n    color: rgba(0, 0, 0, 0.45); }\n\n._1fov3EMK4MjP8nzJOyVFQo ._1otYw1ak8quHoMGKfcdtvK {\n  background-color: white; }\n  ._1fov3EMK4MjP8nzJOyVFQo ._1otYw1ak8quHoMGKfcdtvK ._1JIjYaQS08yEWea0C_GHV3 {\n    background-color: white; }\n    ._1fov3EMK4MjP8nzJOyVFQo ._1otYw1ak8quHoMGKfcdtvK ._1JIjYaQS08yEWea0C_GHV3 ._2hxmqr8Xiit_wBiF1r2KCL {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      padding: 5px 0px; }\n      ._1fov3EMK4MjP8nzJOyVFQo ._1otYw1ak8quHoMGKfcdtvK ._1JIjYaQS08yEWea0C_GHV3 ._2hxmqr8Xiit_wBiF1r2KCL ._2PktDDasJcxER7ZiWPohE4 {\n        font-size: 50px;\n        color: #00a0dc; }\n      ._1fov3EMK4MjP8nzJOyVFQo ._1otYw1ak8quHoMGKfcdtvK ._1JIjYaQS08yEWea0C_GHV3 ._2hxmqr8Xiit_wBiF1r2KCL .T2mB_GTzHqa71UpibSmeE {\n        font-family: Open Sans;\n        font-style: normal;\n        font-weight: normal;\n        font-size: 14px;\n        line-height: 19px;\n        color: #00a0dc;\n        text-align: center;\n        padding-top: 10px;\n        margin-bottom: 0px; }\n", ""]);
// Exports
exports.locals = {
	"StoreCard": "_1fov3EMK4MjP8nzJOyVFQo",
	"warning": "_19dXTwXAf8O9qsgeKWoh5p",
	"warningIcon": "pGq03zUDoK58-MLUGsJzI",
	"deleteButton": "YFhWHmfIMqKXoEAN_ztpr",
	"noAction": "_5kjydFlXzh5i7gT8oFJj1",
	"content": "_1otYw1ak8quHoMGKfcdtvK",
	"block": "_1JIjYaQS08yEWea0C_GHV3",
	"addContainer": "_2hxmqr8Xiit_wBiF1r2KCL",
	"addIcon": "_2PktDDasJcxER7ZiWPohE4",
	"addText": "T2mB_GTzHqa71UpibSmeE"
};
module.exports = exports;
