export interface OnboardingRequest {
    onboardingType: string
    merchant: {
        companyNameShort: string
        companyNameFull: string
        descriptor: string
        website: string
        addressLine1: string
        addressLine2: string
        country: string
        city: string
        postCode: string
        mcc: string
        currency: string[]
        acquisitionChannel: string
        createMerchantPortalAccount: boolean
        credentials: {
            email: string
            password?: string
        },
        initiatorEmail: string
    },
    terminalSettings: {
        defaultTransactionType: string
        optomanyPlatform: {
            acquirerId: number
            terminalId?: string
            paymentChannelId: number
            cardAcceptorNumber: string
            merchantDepartmentId: number
            terminalDeviceInfo: {
                attributes: string
                capabilities: string
                terminalType: string
                attributesUsed: string
                poiCapabilities: string
                capabilitiesUsed: string
            },
            merchantName: string
            merchantUrl: string
            merchantStoreId: number
            visaMid: string
            mastercardMid: string
            amexMid?: string
            unionPayMid?: string
            avsHouseMatrix: number
            avsPostCodeMatrix: number
            cscMatrix: number
            paMatrix: number
            enableAvsForNonUKCustomers: boolean
            countryId: number
        },
        riskLimits: {
            dailyValue: number
            dailyVolume: number
            monthlyValue: number
            monthlyVolume: number
            individualTransactionValue: number
            maxRefundValue: number
        }
    },
    checkoutSettings: {
        integrationTypes: string[]
        mandatoryFields: {
            accountCity: boolean
            accountEmail: boolean
            accountCountry: boolean
            accountStreet1: boolean
            accountLastName: boolean
            accountFirstName: boolean
            accountPostalCode: boolean
        },
        donation: {
            logo: string
            donationMode: string
            adjustmentBands: {
                bandCeilValue: number
                bandFloorValue: number
                adjustmentValue: number
            }[],
            logoImageMimeType: string
            penniesDonationActiveText: string
            penniesDonationInactiveText: string
        },
        customStyle: {
            primaryColor: string
            headerLogoUrl: string
            headerLogoText: string
            headerStartColor: string
            actionButtonColor: string
            actionButtonBackground: string
            headerFinishColor: string
            closeButtonOpacity: number
            closeButtonColor: string
            showPoweredByBlock: boolean
            headerLogoTextColor: string
            showHeaderBottomLine: boolean
            showOrderNumberBlock: boolean
            headerLogoMobileWidth: number
            headerLogoDesktopWidth: number
            headerLogoMobileHeight: number
            headerSecondaryLogoUrl: string
            headerLogoDesktopHeight: number
            headerLogoTextSecondary: string
            actionButtonBorderRadius: number
            headerLogoTextMarginLeft: number
            showOrderDescriptionBlock: boolean
            showPaymentSecuredByBlock: boolean
            headerSecondaryLogoPosition: string
        }
    }
}

export function createOnboardingRequest(): OnboardingRequest {
    return {
        onboardingType: 'checkoutv3',
        merchant: {
            companyNameShort: '',
            companyNameFull: '',
            descriptor: '',
            website: '',
            addressLine1: '',
            addressLine2: '',
            country: '',
            city: '',
            postCode: '',
            mcc: '',
            currency: [],
            acquisitionChannel: '',
            createMerchantPortalAccount: false,
            credentials: {
                email: '',
                password: ''
            },
            initiatorEmail: ''
        },
        terminalSettings: {
            defaultTransactionType: '',
            optomanyPlatform: {
                acquirerId: 0,
                terminalId: '',
                paymentChannelId: 0,
                cardAcceptorNumber: '',
                merchantDepartmentId: 0,
                terminalDeviceInfo: {
                    attributes: '',
                    capabilities: '',
                    terminalType: '',
                    attributesUsed: '',
                    poiCapabilities: '',
                    capabilitiesUsed: ''
                },
                merchantName: '',
                merchantUrl: '',
                merchantStoreId: 0,
                visaMid: '',
                mastercardMid: '',
                amexMid: '',
                unionPayMid: '',
                avsHouseMatrix: 0,
                avsPostCodeMatrix: 0,
                cscMatrix: 0,
                paMatrix: 0,
                enableAvsForNonUKCustomers: false,
                countryId: 0
            },
            riskLimits: {
                dailyValue: 0,
                dailyVolume: 0,
                monthlyValue: 0,
                monthlyVolume: 0,
                individualTransactionValue: 0,
                maxRefundValue: 0
            }
        },
        checkoutSettings: {
            integrationTypes: [],
            mandatoryFields: {
                accountCity: false,
                accountEmail: false,
                accountCountry: false,
                accountStreet1: false,
                accountLastName: false,
                accountFirstName: false,
                accountPostalCode: false
            },
            donation: {
                logo: '',
                donationMode: '',
                adjustmentBands: [],
                logoImageMimeType: '',
                penniesDonationActiveText: '',
                penniesDonationInactiveText: ''
            },
            customStyle: {
                primaryColor: '',
                headerLogoUrl: '',
                headerLogoText: '',
                headerStartColor: '',
                actionButtonColor: '',
                actionButtonBackground: '',
                headerFinishColor: '',
                closeButtonOpacity: 0,
                closeButtonColor: '',
                showPoweredByBlock: false,
                headerLogoTextColor: '',
                showHeaderBottomLine: false,
                showOrderNumberBlock: false,
                headerLogoMobileWidth: 0,
                headerLogoDesktopWidth: 0,
                headerLogoMobileHeight: 0,
                headerSecondaryLogoUrl: '',
                headerLogoDesktopHeight: 0,
                headerLogoTextSecondary: '',
                actionButtonBorderRadius: 0,
                headerLogoTextMarginLeft: 0,
                showOrderDescriptionBlock: false,
                showPaymentSecuredByBlock: false,
                headerSecondaryLogoPosition: ''
            }
        }
    }
}
