// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3MLl2vwi3oYmJUVizBz0EC {\n  background: #FFFFFF;\n  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 4px;\n  margin-bottom: 16px;\n  padding: 16px;\n  cursor: pointer; }\n  ._1zv4P95AGY52qjveHJ7F1e {\n    background: #FFEFF2;\n    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1); }\n  ._3OFLEnbmme0jicp21ODSpT {\n    background: #d9d9d9;\n    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1); }\n  ._3MLl2vwi3oYmJUVizBz0EC ._2PD2nyVLtqXYNWUvXLy_HW {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 22px;\n    margin-bottom: 5px;\n    color: #000000; }\n  ._3MLl2vwi3oYmJUVizBz0EC .uOKv9I13W-FNBeAagv-F4 {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 12px;\n    line-height: 22px;\n    margin-bottom: 5px;\n    color: #8C8C8C; }\n  ._3MLl2vwi3oYmJUVizBz0EC ._3KrBVSVqTfN_hMg-0YC5aD {\n    font-size: 13px; }\n  ._3MLl2vwi3oYmJUVizBz0EC ._2jUPGK0Ew7FBVd_SuMycZx {\n    word-break: break-word;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 16px;\n    color: #8C8C8C; }\n  ._3MLl2vwi3oYmJUVizBz0EC ._3Kvh7HNfIKMdOXaaqUkD4q {\n    margin-bottom: 5px; }\n  ._3MLl2vwi3oYmJUVizBz0EC ._2l2ZPuPQ3KzxXmBv8JPabU {\n    text-transform: uppercase;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 12px;\n    line-height: 16px;\n    color: #fa8c16;\n    word-break: break-word; }\n    ._3MLl2vwi3oYmJUVizBz0EC .O5z0glF1cD8y2yjXSuGgP {\n      color: #12B138; }\n    ._3MLl2vwi3oYmJUVizBz0EC ._3q6nDIl9q6mZ6KoBp7wGwh {\n      color: #D23153; }\n    ._3MLl2vwi3oYmJUVizBz0EC ._15SUb0pBYd1D86iP9fgOK2 {\n      color: #8C8C8C; }\n    ._3MLl2vwi3oYmJUVizBz0EC ._1q9LUC5loZEhJJjg0Knd8M {\n      color: #c9c9c9; }\n", ""]);
// Exports
exports.locals = {
	"OrderListItem": "_3MLl2vwi3oYmJUVizBz0EC",
	"OrderListItem_failed": "_1zv4P95AGY52qjveHJ7F1e",
	"OrderListItem_cancelled": "_3OFLEnbmme0jicp21ODSpT",
	"header": "_2PD2nyVLtqXYNWUvXLy_HW",
	"title": "uOKv9I13W-FNBeAagv-F4",
	"icon": "_3KrBVSVqTfN_hMg-0YC5aD",
	"subtitle": "_2jUPGK0Ew7FBVd_SuMycZx",
	"groupWrapper": "_3Kvh7HNfIKMdOXaaqUkD4q",
	"status": "_2l2ZPuPQ3KzxXmBv8JPabU",
	"status_success": "O5z0glF1cD8y2yjXSuGgP",
	"status_failed": "_3q6nDIl9q6mZ6KoBp7wGwh",
	"status_reversed": "_15SUb0pBYd1D86iP9fgOK2",
	"status_cancelled": "_1q9LUC5loZEhJJjg0Knd8M"
};
module.exports = exports;
