// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".JSvDlKRTk0IueKbT3pFdM ._1mRfQt6lkF-hlwVTQV__6G {\n  padding-top: 16px; }\n\n.JSvDlKRTk0IueKbT3pFdM ._3PPg-_KcqFb8e2GtOpzs9P {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n\n.JSvDlKRTk0IueKbT3pFdM ._2sqsU3VaUcALl48-Xclt4E {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 36px;\n  color: #000000;\n  padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"DeliveryDetailsForm": "JSvDlKRTk0IueKbT3pFdM",
	"goBackButtonContainer": "_1mRfQt6lkF-hlwVTQV__6G",
	"submitButtonContainer": "_3PPg-_KcqFb8e2GtOpzs9P",
	"subgroupTitle": "_2sqsU3VaUcALl48-Xclt4E"
};
module.exports = exports;
