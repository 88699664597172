export interface Order {
    existingMerchantId: number // only for existing merchants
    brandingOrganizationId: number
    existingStoreId: number
    existingDepartmentId: number
    merchant: {
        companyRegistrationNumber: string
        name: string
        storeName: string
        contact: {
            firstName: string
            surname: string
            telephoneNumber: string
            mobileNumber: string
            email: string
        }
    }
    address: {
        line1: string
        line2: string
        line3: string
        townOrCity: string
        county: string
        postcode: string
        countryCode: number
    }
    departmentDetails: {
        name: string
        merchantCategoryCode: string
        streamlineCompanyId: string
        currencyId: number
        maxCashback: number
        maxGratuity: number
        preAuthorisation?: boolean
        visaMasterCard: {
            mid: string
            acquirerId: number
            accountNumber: string
            sortCode: string
        }
        amex: {
            mid: string
            acquirerId: string
            accountNumber: string
            sortCode: string
        }
        submissionId: number
    }
    productDetails: {
        bundleId: string
        quantity: number
    }
    deliveryDetails: {
        deliverToStore: boolean
        instructions: string
    }
    acquirerAssignedTids: string[]
}

export function createOrder (): Order {
    return {
        existingMerchantId: -1,
        brandingOrganizationId: -1,
        existingStoreId: -1,
        existingDepartmentId: -1,
        merchant: {
            companyRegistrationNumber: '',
            name: '',
            storeName: '', // trading as
            contact: {
                firstName: '',
                surname: '',
                telephoneNumber: '',
                mobileNumber: '',
                email: ''
            }
        },
        address: {
            line1: '',
            line2: '',
            line3: '',
            townOrCity: '',
            county: '',
            postcode: '',
            countryCode: -1
        },
        departmentDetails: {
            name: '',
            merchantCategoryCode: '-1',
            streamlineCompanyId: '',
            currencyId: null,
            maxCashback: -1,
            maxGratuity: -1,
            visaMasterCard: {
                mid: '',
                acquirerId: -1,
                accountNumber: '',
                sortCode: ''
            },
            amex: {
                mid: '',
                acquirerId: '',
                accountNumber: '',
                sortCode: ''
            },
            submissionId: -1
        },
        productDetails: {
            bundleId: '',
            quantity: -1
        },
        deliveryDetails: {
            deliverToStore: false,
            instructions: ''
        },
        acquirerAssignedTids: []
    }
}
