import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{

    // GENERAL SETTINGS
    generalSettings: string

    defaultTransactionType: string
    defaultTransactionTypeRequired: string
    sale: string
    auth: string

    acquirer: string
    acquirerRequired: string
    acquirerTooltip: string

    paymentChannelId: string
    paymentChannelIdRequired: string

    cardAcceptorNumber: string
    cardAcceptorNumberRequired: string

    merchantName: string
    merchantNameRequired: string

    merchantUrl: string
    merchantUrlRequired: string

    visaMid: string
    visaMidRequired: string

    mastercardMid: string
    mastercardMidRequired: string

    amexMid: string
    unionPayMid: string

    avsHouseMatrix: string
    avsHouseMatrixRequired: string

    avsPostcodeMatrix: string
    avsPostcodeMatrixRequired: string

    cscMatrix: string
    cscMatrixRequired: string

    paMatrix: string
    paMatrixRequired: string

    enableAvsForNonUKCustomers: string

    country: string

    max10digits: string
    max2digits: string
    max25digits: string
    max25Chars: string
    max20Chars: string
    invalidUrl: string

    // TERMINAL DEVICE INFO
    terminalDeviceInfo: string

    attributes: string
    capabilities: string
    terminalType: string
    attributesUsed: string
    poiCapabilities: string
    capabilitiesUsed: string

}>()

