import translations from './translations'

translations.add('en', {
    companyNameFull: 'Company full name',
    companyNameFullRequired: 'Company full name is required',
    companyNameShort: 'Company short name',
    companyNameShortRequired: 'Company short name is required',
    descriptor: 'Merchant Descriptor',
    descriptorRequired: 'Merchant Descriptor is required',
    descriptorTooltip: 'Merchant name as will appear on the consumers statement. Limited to max. 21 characters.',
    companyNumber: 'Company number',
    companyNumberRequired: 'Company number is required',
    companyNumberInvalid: 'Company number must consist of 8 digits or 2 letters followed by 6 digits or 2 letters followed by 5 digits followed by 1 letter',
    companyNumberTooltip: 'Company registration number (CRN) as registered with Companies House.',
    mcc: 'Merchant category code',
    mccRequired: 'Merchant category code is required',
    currency: 'Currency',
    currencyRequired: 'Currency is required',
    acquisitionChannel: 'Acquisition channel',
    acquisitionChannelRequired: 'Acquisition channel is required',
    acquirer: 'Acquirer',
    acquirerRequired: 'Acquirer is required',

    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',
    addressLine3Required: 'Address line 3 is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    county: 'County',
    countyRequired: 'County is required',
    country: 'Country',
    countryRequired: 'Country is required',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',

    contactName: 'Contact name',
    contactNameRequired: 'Contact name is required',
    contactSurname: 'Contact surname',
    contactSurnameRequired: 'Contact surname is required',

    email: 'Email',
    emailInvalid: 'Invalid email',
    emailRequired: 'Email is required',
    website: 'Website',
    websiteRequired: 'Website is required',
    credentials: 'Credentials',
    credentialsGuidanceText: 'Enter a unique email address and create a password that will be used by the user to login to the Merchant Portal to view transaction reports, manage additional payment methods, etc.',
    password: 'Password',

    nextStep: 'Next',
    back: 'Back',
    initiatorEmail: 'Initiator\'s email',
    initiatorInfo: 'Initiator\'s Information',
    invalidUrl: 'Invalid URL'
})
