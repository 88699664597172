export interface MerchantDetailsFormData {
    companyName: string
    tradingAs: string
    companyNumber: string
    vatNumber: string
    addressLine1: string
    addressLine2: string
    town: string
    county: string
    country: number
    postcode: string
    contactName: string
    contactSurname: string
    contactJobTitle: string
    contactEmail: string
    contactPhoneNumber: string
    contactMobilePhone: string
    financeContactName?: string
    financeContactSurname?: string
    financeContactJobTitle?: string
    financeContactPhoneNumber?: string
    financeContactEmail?: string
    receiveSalesAndMarketing: boolean
    isNew: boolean
}

export function createMerchantDetailsFormData(): MerchantDetailsFormData {
    return {
        companyName: '',
        tradingAs: '',
        companyNumber: '',
        vatNumber: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        country: 0,
        postcode: '',
        contactName: '',
        contactSurname: '',
        contactJobTitle: '',
        contactEmail: '',
        contactPhoneNumber: '',
        contactMobilePhone: '',
        financeContactName: '',
        financeContactSurname: '',
        financeContactJobTitle: '',
        financeContactPhoneNumber: '',
        financeContactEmail: '',
        receiveSalesAndMarketing: true,
        isNew: false
    }
}
