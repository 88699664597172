import React, { useContext } from 'react'
import { Alert, Col, Empty, Row, Spin, Tabs } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { SearchBar, Tab } from '~/code/common/components'
import { Page } from '~/code/common/pages/Page'
import { ButtonWithConfirmation } from '~/code/common/components/ButtonWithConfirmation'
import { AppStoreContext } from '~/code/AppStore'
import translations from './translations'
import styles from './CheckoutV3Onboarding.scss'
import { CheckoutV3OnboardingProps } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/props'
import mainStyles from '~/assets/styles/main.scss'
import { Progress } from '~/code/POS/pages'
import { RequestSummary } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/RequestSummary'

const { TabPane } = Tabs

export const CheckoutV3Onboarding = observer(({ children }: CheckoutV3OnboardingProps) => {
    const store  = useContext(AppStoreContext).checkoutV3OnboardingStore

    const cancelOnboarding = () => {
        store.clear()
    }

    const wrappedTabPane = ({order, title, key, children: tabPaneChildren} : {order: number, title: string, key: string, children: any}) => {
        return <TabPane
            tab = {
                <Tab
                    order={order}
                    title={title}
                    key={key}
                    isActive={ store.onboardingDetailsSteps[store.currentOnboardingDetailsStep].key === key }
                />
            }
            key={key}
            disabled={ store.onboardingDetailsSteps[store.currentOnboardingDetailsStep].key !== key }
        >
            { tabPaneChildren }
        </TabPane>
    }

    const renderSearchInputNoDataContent = () => {
        return (
            <div className={styles.searchInputNoDataContent}>
                { translations().noMerchantFound }
            </div>
        )
    }

    const onMerchantRequirementsAlertClose = () => {
        store.setHideMerchantRequirementWarning(true)
    }

    const renderContent = () => {
        return <>
            <Row>
                { (store.selectedMerchantName || store.shouldShowForm) && <Col xs={24}>
                    <div className={styles.clearButtonWrapper}>
                        <ButtonWithConfirmation
                            type={'link'}
                            statusType={'danger'}
                            popConfirmTitle={ translations().cancelOnboardingConfirmText }
                            confirmButtonTitle={ translations().confirmCancelOnboarding }
                            cancelButtonTitle={ translations().cancelCancelOnboarding }
                            onConfirm={ cancelOnboarding }
                        >
                            <><DeleteOutlined /> {translations().cancelOnboarding} </>
                        </ButtonWithConfirmation>
                    </div>
                </Col> }
            </Row>
            <div className={styles.companySelection}>
                <SearchBar
                    store={store}
                    // hideSearchType={true}
                    searchInputPlaceholder={translations().searchMerchantPlaceholder}
                    searchInputNoDataContent={renderSearchInputNoDataContent()}
                    searchInputAllowClear={false}/>
            </div>
            {
                store.shouldShowForm &&
                <div className={styles.companyInfo}>
                    <div className={styles.header}>{store.selectedMerchantName}</div>
                    <div className={styles.tabsContainer}>
                        {
                            store.onboardingSteps && <Tabs activeKey={store.onboardingDetailsSteps[store.currentOnboardingDetailsStep].key}>
                                { store.onboardingDetailsSteps.map(item => wrappedTabPane(item)) }
                            </Tabs>
                        }
                    </div>
                </div>
            }
            {
                !store.shouldShowForm &&
                <Empty className={styles.empty} description={translations().noMerchantSelectedDescription}/>
            }
        </>
    }

    return (
        <Spin size={'large'} spinning={store.placingOrder} tip={'Submitting request...'} className={`${mainStyles.spin__large} ${mainStyles.spin}`}>
            <Page>
                <div className={styles.CheckoutV3Onboarding}>
                    { store.onboardingSteps &&
                        <>
                            {
                                store.onboardingSteps[store.currentStep].key === 'onboardingDetails' && <div>
                                    <div className={styles.header} key={'header'}>{translations().checkoutV3Onboarding}</div>
                                    {
                                        !store.hideMerchantRequirementWarning &&
                                        <Alert message={translations().merchantOnboardingRequirements} type={'warning'} className={styles.merchantOnboardingRequirements} showIcon closable afterClose={onMerchantRequirementsAlertClose}/>
                                    }
                                    <div className={styles.content} key={'content'}>
                                        {
                                            renderContent()
                                        }
                                    </div>
                                </div>
                            }
                            {
                                store.onboardingSteps[store.currentStep].key !== 'onboardingDetails' && store.onboardingSteps[store.currentStep].children
                            }
                        </>
                    }
                </div>
            </Page>
        </Spin>
    )
})

