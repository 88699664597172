import { ICompanySelectStore } from '~/code/common/components/CompanySelect/ICompanySelectStore'
import { SearchItem } from '~/code/common/components/SearchBar/models/SearchItem'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { getWithAuth } from '~/code/services/authorised-requests'
import { EmployeeMerchant } from '~/code/POS/pages/NewOrder/models/EmployeeMerchant'
import { error, isEmpty } from 'dna-common'
import { persist } from 'mobx-persist'
import { AppStore } from '~/code/AppStore'
import { companyTypes } from '~/code/common/components/CompanySelect/services'
import { IAppStore } from '~/code/IAppStore'
import { OWNER_GROUPS } from '~/code/models'
import { COMPANY_TYPE } from '~/code/common/layouts/MainLayout/models/CompanyType'

const PAGE_SIZE = 20

export class CompanySelectStore implements ICompanySelectStore {

    private parentStore: IAppStore

    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isSearching: observable,
            items: observable,
            selectedItem: observable,
            isModalVisible: observable,
            selectedEmployeeCompany: computed,
            loadCompanies: action,
            selectItem: action,
            setItems: action,
            setModalVisible: action,
            setSelectedItem: action,
            clear: action
        })

        this.parentStore = parentStore

        reaction(() => this.parentStore?.headerStore?.selectedOwnerGroup, () => {
            if (this.parentStore?.headerStore?.selectedOwnerGroup?.id === OWNER_GROUPS['123send']) {
                this.setSelectedItem(null)
            }
        })
    }

    currentPage = 0

    lastResponseSize = -1

    lastSearchValue: string = undefined

    isSearching: boolean = false

    items: SearchItem[] = []

    @persist('object', SearchItem)
    selectedItem: SearchItem

    isModalVisible: boolean

    get selectedEmployeeCompany(): SearchItem {
        return this.selectedItem
    }

    loadCompanies = (value: string = undefined) => {
        if (this.lastSearchValue !== value) {
            this.currentPage = 0
            this.lastResponseSize = 0
            this.isSearching = false
            this.lastSearchValue = value || undefined
            this.items = []
        }

        if ((this.lastResponseSize < PAGE_SIZE && this.lastResponseSize > 0) || this.isSearching) {
            return
        }

        if (isEmpty(this.items)) {
            this.currentPage = 0
        }

        this.currentPage++
        this.isSearching = true

        checkHTTPStatus(getWithAuth<EmployeeMerchant[]>('/api/employees/companies-for-orders',
            {
                pageNumber: this.currentPage,
                pageSize: PAGE_SIZE,
                searchValue: this.lastSearchValue,
                ownerGroupId: AppStore.selectedOwnerGroup?.id || undefined
            }
        ))
        .then( response => {
            if (response && response.length > 0) {
                if (isEmpty(this.items)) {
                    this.items = []
                }
                this.items.push(...response.map((merchant, index) => {
                    const companyRawName = merchant.companyName.replace(`(${merchant.accountNumber}) `, '')
                    return {
                        identifier: merchant.internalIdentifier, 
                        title: merchant.companyName, 
                        description: `ACC: ${merchant.accountNumber}<br/>${companyTypes[merchant.companyTypeId]}`, 
                        additionalInfo: { ...merchant, companyRawName}
                    }
                }))
            } else {
                this.items = []
            }
            this.lastResponseSize = response.length
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
        .finally(() => {
            this.isSearching = false
        })
    }

    search (value?: string): void {
        this.loadCompanies(value)
    }

    selectItem (value: string) {
        if (!value) {
            this.selectedItem = null
            this.parentStore.userInfo = {
                ...this.parentStore.userInfo,
                companyType: null,
                ownerGroupId: null
            }
            return
        }

        const selectedTempItem = this.items.find(item => item.identifier === value) || null
        this.selectedItem = {...selectedTempItem}

        this.parentStore.userInfo = {
            ...this.parentStore.userInfo,
            companyType: COMPANY_TYPE[selectedTempItem.additionalInfo?.companyTypeId],
            ownerGroupId: selectedTempItem.additionalInfo?.ownerGroupId
        }
    }

    setItems (items: SearchItem[]) {
        this.items = items
    }

    setSelectedItem(selectedItem: SearchItem) {
        this.selectedItem = selectedItem
    }

    setModalVisible = (isModalVisible) => {
        this.isModalVisible = isModalVisible
    }

    clear = () => {
        this.currentPage = 0
        this.lastResponseSize = -1
        this.isSearching = false
        this.items = []
        this.selectedItem = undefined
        this.isModalVisible = false
    }
}
