import SvgArrowRight from '~/assets/icons/ArrowRight'
import SvgUser from './User'
import SvgLock from './Lock'
import SvgLogOut from './LogOut'
import SvgCart from './Cart'
import SvgDropDownArrow from './DropDownArrow'
import SvgSteps from './Steps'
import SvgHelp from './Help'
import SvgSearch from './Search'
import SvgCall from './Call'
import SvgSadFace from './SadFace'
import SvgConfiguration from './Configuration'
import SvgReport from './Report'
import SvgRedo from './Redo'
import SvgCompany from './Company'
import SvgReload from './Reload'
import SvgParams from './Params'
import SvgFirstPage from './FirstPage'
import SvgPrevPage from './PrevPage'
import SvgNextPage from './NextPage'
import SvgLastPage from './LastPage'
import SvgCopy from './Copy'
import SvgTrusted from './Trusted'

export { SvgArrowRight, SvgUser, SvgLock, SvgLogOut, SvgCart,
    SvgDropDownArrow, SvgSteps, SvgHelp, SvgSearch, SvgCall,
    SvgSadFace, SvgConfiguration, SvgReport, SvgRedo, SvgCompany,
    SvgReload, SvgParams, SvgCopy, SvgTrusted
}

