import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    configReport: string
    orderReference: string
    noReference: string
    orderId: string
    opportunityNo: string
    salesSource: string
    referee: string
    merchantName: string
    merchantSystemId: string
    noOfStores: string
    noOfDepartments: string
    storeConfiguration: string
    merchantStoreId: string
    storeName: string
    storeKey: string
    licenceKey: string
    s300PEDCount: string
    yomaniPEDCount: string
    yomaniXRPEDCount: string
    xenteoPEDCount: string
    xenteoECOPEDCount: string
    s800Count: string
    s900Count: string
    s300Count: string
    a920GoCount: string
    a920ProCount: string
    departmentConfiguration: string
    merchantDepartmentId: string
    departmentName: string
    tids: string
    cnpChannelCount: string
    signatureKey: string
    a920PositiveCount: string
    wlPaymentsAppCount: string
    v200CCount: string
    v240MCount: string
    deviceDetail: string
    productId: string
    deviceName: string
    quantity: string
}>()
