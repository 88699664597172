import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    companyName: string
    companyNameRequired: string
    companyNameWrong: string
    tradingAs: string
    tradingAsRequired: string
    accountManager: string
    accountManagerRequired: string
    companyNumber: string
    companyNumberRequired: string
    companyNumberUKInvalid: string
    companyNumberROIInvalid: string
    companyNumberUKTooltip: string
    companyNumberROITooltip: string
    vatNumber: string
    vatNumberRequired: string
    addressLine1: string
    addressLine1Required: string
    addressLine2: string
    addressLine2Required: string
    addressLine3: string
    addressLine3Required: string
    town: string
    townRequired: string
    county: string
    countyRequired: string
    country: string
    countryRequired: string
    postcode: string
    postcodeRequired: string
    postcodeInvalid: string
    contactName: string
    contactNameRequired: string
    contactSurname: string
    contactSurnameRequired: string
    jobTitle: string
    jobTitleRequired: string
    email: string
    emailInvalid: string
    emailRequired: string
    contactPhoneNumber: string
    contactPhoneNumberInvalid: string
    contactPhoneNumberRequired: string
    contactMobilePhone: string
    contactMobilePhoneInvalid: string
    contactMobilePhoneRequired: string
    address: string
    contact: string
    financeContact: string
    financeContactUseMain: string
    financeContactSameAsMain: string
    communicationPreferences: string
    receiveSalesAndMarketing: string
    dontReceiveSalesAndMarketing: string
    receiveSalesAndMarketingTooltip: string
    nextStep: string
    back: string
}>()

