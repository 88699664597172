export interface DeliveryAddressFormData {
    useMerchantAddress: boolean
    addressLine1: string
    addressLine2: string
    town: string
    county: string
    country: number
    postcode: string
    recipientName: string
    deliveryInstructions: string
}

export function createDeliveryAddressFormData(): DeliveryAddressFormData {
    return {
        useMerchantAddress : false,
        addressLine1 : '',
        addressLine2 : '',
        town : '',
        county : '',
        country : 0,
        postcode : '',
        recipientName: '',
        deliveryInstructions : ''
    }
}
