// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ZFUpGtFzPaXNKRV2dfBfH ._2v37u5aXX_2TMwkRGnVDMJ {\n  font-family: Poppins;\n  font-weight: 600;\n  color: #00A0DC;\n  cursor: pointer; }\n\n.ijzgquGex5KpPwdC5G2Pt {\n  background-color: white; }\n  .ijzgquGex5KpPwdC5G2Pt .anticon {\n    color: #D23153; }\n  .ijzgquGex5KpPwdC5G2Pt .ant-btn.ant-btn-primary {\n    background: #D23153;\n    border-color: #D23153;\n    box-shadow: 0px 3px 5px rgba(210, 49, 83, 0.3); }\n    .ijzgquGex5KpPwdC5G2Pt .ant-btn.ant-btn-primary:hover, .ijzgquGex5KpPwdC5G2Pt .ant-btn.ant-btn-primary:focus, .ijzgquGex5KpPwdC5G2Pt .ant-btn.ant-btn-primary:active {\n      background: #D23153;\n      border-color: #D23153; }\n", ""]);
// Exports
exports.locals = {
	"ButtonWithConfirmation": "_1ZFUpGtFzPaXNKRV2dfBfH",
	"confirmLink": "_2v37u5aXX_2TMwkRGnVDMJ",
	"PopConfirmDanger": "ijzgquGex5KpPwdC5G2Pt"
};
module.exports = exports;
