import { removeEmptyFields } from '~/code/services'
import { MerchantDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/models'
import { OrderDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/OrderDetailsForm/models'
import { createPlaceOrderRequest, PartnerPlaceOrderRequest } from '~/code/stores/NewOrderStore/models/PartnerPlaceOrderRequest'
import { DeliveryDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/DeliveryDetailsForm/models'
import { ProductListFormData } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/ProductListFormData'
import { Product } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/Product'
import { ProductBundle } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/ProductBundle'
import { isEmpty } from 'dna-common'
import { PartnerDepartment } from '~/code/POS/pages/NewOrder/models/partner/PartnerDepartment'
import { SDStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDStore'
import { PartnerStore } from '~/code/POS/pages/NewOrder/models/partner/PartnerStore'
import { SDDepartment } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDDepartment'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'

export function createPlaceOrderRequestFromData(merchantDetails: MerchantDetailsFormData,
                                        partnerStores: SDStore[],
                                        productListFormData: ProductListFormData,
                                        orderDetailsFormData: OrderDetailsFormData,
                                        deliveryDetailsFormData: DeliveryDetailsFormData,
                                        internalIdentifier: string,
                                        orderReference: string,
                                        accountNumber: string,
                                        merchantId: string,
                                        steps: any[],
                                        acquirerAssignedTids: AcquirerAssignedTid[]
) { // TODO specify the type
    const request = createPlaceOrderRequest()

    steps.forEach(step => {
        switch (step.key) {
            case 'merchantDetails': {
                merchantDetailsToRequest(request, merchantDetails)
                break
            }
            case 'productList': {
                productsListFormDataToRequest(request, partnerStores, productListFormData, acquirerAssignedTids)
                break
            }
            case 'orderDetails': {
                orderDetailsFormDataToRequest(request, orderDetailsFormData)
                break
            }
            case 'deliveryDetails': {
                deliveryDetailsFormDataToRequest(request, deliveryDetailsFormData)
                break
            }
        }
    })

    if (!isEmpty(merchantId)) {
        request.merchant.existingMerchantId = Number(merchantId)
        delete request.merchant.newMerchant
    }

    request.internalIdentifier = internalIdentifier?.trim()
    request.orderReference = orderReference?.trim()
    request.partnerId = accountNumber

    removeEmptyFields(request)
    removeStoreIds(request)
    return request
}

export function merchantDetailsToRequest (request: PartnerPlaceOrderRequest, merchantDetails: MerchantDetailsFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }

    if (!merchantDetails) {
        return request
    }

    delete request.merchant.existingMerchantId
    merchantDetails.companyName && (request.merchant.newMerchant.name = merchantDetails.companyName.trim())
    merchantDetails.companyNumber && (request.merchant.newMerchant.companyRegistrationNumber = merchantDetails.companyNumber.trim())
    request.merchant.newMerchant.tradingAs = merchantDetails.tradingAs?.trim()
    request.merchant.newMerchant.contact.firstName = merchantDetails.contactName?.trim()
    request.merchant.newMerchant.contact.surname = merchantDetails.contactSurname?.trim()
    request.merchant.newMerchant.contact.telephoneNumber = merchantDetails.contactPhoneNumber?.trim()
    request.merchant.newMerchant.contact.mobileNumber = merchantDetails.contactMobilePhone?.trim()
    request.merchant.newMerchant.contact.email = merchantDetails.contactEmail?.trim()
    request.merchant.newMerchant.contact.jobTitle = merchantDetails.contactJobTitle?.trim()
    request.merchant.newMerchant.address.line1 = merchantDetails.addressLine1?.trim()
    request.merchant.newMerchant.address.line2 = merchantDetails.addressLine2?.trim()
    request.merchant.newMerchant.address.townOrCity = merchantDetails.town?.trim()
    request.merchant.newMerchant.address.county = merchantDetails.county?.trim()
    request.merchant.newMerchant.address.countryCode = Number(merchantDetails.country)
    request.merchant.newMerchant.address.postcode = merchantDetails.postcode?.trim()

    request.merchant.newMerchant.communicationDetails = {
        receiveSalesAndMarketing: merchantDetails.receiveSalesAndMarketing,
        paperBasedInvoices: false
    }

    // DEFAULT STATIC VALUES
    request.merchant.newMerchant.roles = [{
        name: 'Supervisor',
        purchase: true,
        refund: true,
        cashback: false,
        users: [{
            userId: '1234',
            userPin: '1234'
        }]
    }]

    return request
}

export function orderDetailsFormDataToRequest (request: PartnerPlaceOrderRequest, orderDetailsFormData: OrderDetailsFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!orderDetailsFormData) {
        return request
    }

    if (orderDetailsFormData.useMainContact) {
        request.merchant.activationDetails = {
            useMainContact: orderDetailsFormData.useMainContact
        }
    } else {
        request.merchant.activationDetails = {
            useMainContact: orderDetailsFormData.useMainContact,
            companyName: orderDetailsFormData.companyName,
            firstName: orderDetailsFormData.firstName,
            surname: orderDetailsFormData.surname,
            email: orderDetailsFormData.email
        }
    }

    request.poNumber = orderDetailsFormData.poNumber
    request.requestedDeliveryAndOrActivationDate = orderDetailsFormData.requestedDeliveryAndOrActivationDate
    request.brandingOrganizationId = orderDetailsFormData.brandingOrganisationId || null

    return request
}

export function deliveryDetailsFormDataToRequest (request: PartnerPlaceOrderRequest, deliveryDetailsFormData: DeliveryDetailsFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!deliveryDetailsFormData) {
        return request
    }

    request.merchant.deliveryDetails = {}
    request.merchant.deliveryDetails.deliverToMerchant = deliveryDetailsFormData.deliverTo === 'deliverToMerchant'
    request.merchant.deliveryDetails.deliverToStore = deliveryDetailsFormData.deliverTo === 'deliverToStore'
    if (request.merchant.deliveryDetails.deliverToStore) {
        deliveryDetailsFormData.storeDeliveryInstructions.map(item => {
            const store = request.merchant.stores.find(storeItem => storeItem.id === item.storeId)
            store.deliveryInstructions = item.isCustom ? item.deliveryInstructions : deliveryDetailsFormData.deliveryInstructions
        })
    } else {
        request.merchant.deliveryDetails.deliveryInstructions = deliveryDetailsFormData.deliveryInstructions
    }

    if (deliveryDetailsFormData.deliverTo === 'deliverToNewAddress') {
        request.merchant.deliveryDetails.newHardwareAddress = {
            address: {
                deliveryName: deliveryDetailsFormData.deliveryName,
                tradingAs: deliveryDetailsFormData.tradingAs,
                countryCode: deliveryDetailsFormData.country,
                postcode: deliveryDetailsFormData.postcode,
                line1: deliveryDetailsFormData.addressLine1,
                line2: deliveryDetailsFormData.addressLine2,
                townOrCity: deliveryDetailsFormData.town,
                county: deliveryDetailsFormData.county
            },
            contact: {
                firstName: deliveryDetailsFormData.contactName,
                surname: deliveryDetailsFormData.contactSurname,
                jobTitle: deliveryDetailsFormData.jobTitle,
                email: deliveryDetailsFormData.email,
                telephoneNumber: deliveryDetailsFormData.contactPhoneNumber,
                mobileNumber: deliveryDetailsFormData.contactMobilePhone
            }
        }
    }

    return request
}

export function productsListFormDataToRequest(request: PartnerPlaceOrderRequest, partnerStores: SDStore[], productListFormData: ProductListFormData, acquirerAssignedTids: AcquirerAssignedTid[]) {
    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!productListFormData) {
        return request
    }

    productListFormData.products.forEach( (product: Product) => {
        let requestStore: PartnerStore  = request.merchant.stores.find(item => item.id === product.storeId)
        const partnerStore = partnerStores.find(item => item.id === product.storeId)
        const partnerDepartment = partnerStore.departments.find(item => item.id === product.departmentId)

        const TIDs = product.bundles.reduce((acc, val) => {
            return acc.concat(val.acquirerAssignedTids.map(tid => tid.tid))
        }, [])

        if (requestStore) {
            // find department
            let department = requestStore.departments.find(item => item.id === product.departmentId)
            if (department) {
                department.productDetails.push(...product.bundles.map(bundle => { return {bundleId: bundle.bundleCode, quantity: bundle.bundleQuantity} }))
            } else {
                department = createDepartment(partnerDepartment, product.bundles, TIDs)
                requestStore.departments.push(department)
            }
        } else {
            if (partnerStore.isNew) {
                requestStore = {
                    id: partnerStore.id,
                    newStore: {
                        name: partnerStore.name,
                        address: {
                            line1: partnerStore.addressLine1,
                            line2: partnerStore.addressLine2,
                            line3: partnerStore.addressLine3,
                            townOrCity: partnerStore.town,
                            county: partnerStore.county,
                            postcode: partnerStore.postcode,
                            countryCode: partnerStore.country
                        },
                        useAxeptConnectCloud: partnerStore.useAxeptConnectCloud,
                        telephoneNumber: partnerStore.telephoneNumber,
                        email: partnerStore.email
                        // companyRegistrationNumber: product.store.companyRegistrationNumber,
                        // vatNumber: product.store.vatNumber
                    },
                    departments: [
                        createDepartment(partnerDepartment, product.bundles, TIDs)
                    ]
                }
            } else {
                requestStore = {
                    id: partnerStore.id,
                    existingStoreId: Number(partnerStore.id),
                    departments: [
                        createDepartment(partnerDepartment, product.bundles, TIDs)
                    ]
                }
            }

            request.merchant.stores.push(requestStore)
        }
    })

    return request
}

function createDepartment(partnerDepartment: SDDepartment, bundles: ProductBundle[], acquirerAssignedTids: string[]): PartnerDepartment {
    const productDetails = bundles.map(bundle => { return {bundleId: bundle.bundleCode, quantity: bundle.bundleQuantity} }  )

    let result: PartnerDepartment

    if (partnerDepartment.isNew) {
        const transactionTypes = {
            cashbackAccepted: partnerDepartment.cashBackAccepted,
            cashbackMaxValue: partnerDepartment.cashBack,
            gratuityAccepted: partnerDepartment.gratuityAccepted,
            gratuityMaxValue: partnerDepartment.gratuity,
            purchaseAccepted: partnerDepartment.purchaseAccepted,
            purchaseMaxValue: partnerDepartment.purchase,
            refundAccepted: partnerDepartment.refundAccepted,
            refundMaxValue: partnerDepartment.refund,
            preAuthorisation: partnerDepartment.preAuthorisation
        }
        !partnerDepartment.cashBackAccepted && delete transactionTypes.cashbackMaxValue
        !partnerDepartment.gratuityAccepted && delete transactionTypes.gratuityMaxValue
        !partnerDepartment.purchaseAccepted && delete transactionTypes.purchaseMaxValue
        !partnerDepartment.refundAccepted && delete transactionTypes.refundMaxValue

        result = {
            newDepartment: {
                name: partnerDepartment.name,
                currencyId: partnerDepartment.currencyId,
                merchantCategoryCode: partnerDepartment.merchantCategoryCode,
                streamlineCompanyId: partnerDepartment.streamlineCompanyId,
                transactionTypes,
                cardSchemeDetails: {
                    visaMasterCard: {
                        visaMid: partnerDepartment.visaMID,
                            visaDebitMid: partnerDepartment.visaDebitMID,
                            mastercardMid: partnerDepartment.mastercardMID,
                            mastercardDebitMid: partnerDepartment.mastercardDebitMID,
                            maestroDebitMid: partnerDepartment.maestroDebitMID,
                            acquirerId: partnerDepartment?.acquirer,
                            accountNumber: partnerDepartment.bankAccountNumber,
                            sortCode: partnerDepartment.sortCode
                    },
                    americanExpress: {
                        mid: partnerDepartment.amexMerchantID,
                            acquirerId: partnerDepartment.amexAcquirer,
                            accountNumber: partnerDepartment.amexBankAccountNumber,
                            sortCode: partnerDepartment.amexSortCode
                    },
                    dinersDiscover: {
                        mid: partnerDepartment.dinersDiscoverMerchantID,
                            acquirerId: partnerDepartment.dinersDiscoverAcquirer,
                            accountNumber: partnerDepartment.dinersDiscoverBankAccountNumber,
                            sortCode: partnerDepartment.dinersDiscoverSortCode
                    },
                    flexecash: {
                        mid: partnerDepartment.flexecashMerchantID,
                            acquirerId: partnerDepartment.flexecashAcquirer,
                            accountNumber: partnerDepartment.flexecashBankAccountNumber,
                            sortCode: partnerDepartment.flexecashSortCode
                    }
                    // ,
                    // unionPay: {
                    //     mid: partnerDepartment.unionPayMerchantID,
                    //     acquirerId: partnerDepartment.unionPayAcquirer,
                    //     accountNumber: partnerDepartment.unionPayBankAccountNumber,
                    //     sortCode: partnerDepartment.unionPaySortCode
                    // }
                }
            },
            payerAuthenticationDetails: {
                visaMid: partnerDepartment.visaMID,
                    mastercardMid: partnerDepartment.mastercardMID
            },
            productDetails
        }

        if (result.newDepartment && !result.newDepartment.streamlineCompanyId) {
            delete result.newDepartment.streamlineCompanyId
        }

        if (partnerDepartment.departmentType === 'general') {
            delete result.payerAuthenticationDetails
        }
    } else {
        result = {
            existingDepartmentId: Number(partnerDepartment.id),
            productDetails
        }
    }

    if (!isEmpty(acquirerAssignedTids)) {
        result.acquirerAssignedTids = acquirerAssignedTids
    }

    return result
}

function removeStoreIds(request: PartnerPlaceOrderRequest) {
    const stores = request?.merchant?.stores
    if (!isEmpty(stores)) {
        stores.forEach(store => {
            delete store.id
        })
    }
}
