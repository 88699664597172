export interface DeliveryDetails {
    deliverToStore?: boolean
    deliverToMerchant?: boolean
    deliveryInstructions?: string
    newHardwareAddress?: {
        contact: {
            jobTitle: string
            firstName: string
            surname: string
            telephoneNumber: string
            mobileNumber: string
            email: string
        }
        address: {
            deliveryName: string
            tradingAs: string
            line1: string
            line2?: string
            line3?: string
            townOrCity: string
            county: string
            postcode: string
            countryCode: number
        }
    }
}

export function createDeliveryDetails(): DeliveryDetails {
    return {
        deliverToStore: false,
        deliverToMerchant: false,
        deliveryInstructions: '',
        newHardwareAddress: {
            contact: {
                jobTitle: '',
                firstName: '',
                surname: '',
                telephoneNumber: '',
                mobileNumber: '',
                email: ''
            },
            address: {
                deliveryName: '',
                tradingAs: '',
                line1: '',
                line2: '',
                line3: '',
                townOrCity: '',
                county: '',
                postcode: '',
                countryCode: 0
            }
        }
    }
}
