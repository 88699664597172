import { persist } from 'mobx-persist'
import { UserType } from '~/code/common/layouts/MainLayout/models/UserType'
import { CompanyType } from '~/code/common/layouts/MainLayout/models/CompanyType'

export class UserInfo {
    @persist userId?: string
    @persist firstName: string
    @persist surname: string
    @persist userType: UserType
    @persist companyType?: CompanyType
    @persist email: string
    @persist companyName?: string
    @persist accountNumber?: string
    @persist timezone: string
    @persist preventOrdering?: boolean
    @persist merchantsAtStoreLevel?: boolean
    @persist ownerGroupId?: number
    @persist('list') permissions?: string[]
}
