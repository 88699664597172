import React from 'react'
import { observer } from 'mobx-react'
import page from 'page'
import { Page } from '~/code/common/pages/Page'
import { isEmpty } from 'dna-common'
import { ListActions, SearchBar } from '~/code/common/components'
import { OrderHistoryProps } from '~/code/POS/pages/OrderHistory/props'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { LoadingView, OrdersListView, FiltersSelect, EmptyView } from './components'
import translations from './translations'
import { RangePicker } from './components/RangePicker'
import { InlineSelect } from '../index'
import { orderStatuses } from './models'
import styles from './OrderHistory.scss'

export const OrderHistory = observer((props: OrderHistoryProps) => {
    const { store } = props

    const refresh = () => {
        store.loadOrders()
    }

    const loadNextPage = () => {
        store.loadNextPage()
    }

    const loadPrevPage = () => {
        store.loadPrevPage()
    }

    const renderContent = () => {
        if (store.isLoading) {
            return <div>
                <LoadingView status={'loading'} />
                <div id={'loadingViewBottom'}/>
            </div>
        }

        if (isEmpty(store.orders)) {
            if (!store.isLoading){
                return (
                    <>
                        <div className={styles.refreshButtonWrapper}>
                            <ListActions
                                currentTotalAmount={store.orders?.length}
                                onReloadClick={refresh}
                                onGoNextPageClick={loadNextPage}
                                onGoPrevPageClick={loadPrevPage}
                                isNextPageDisabled={store.isNextPageDisabled}
                                isPrevPageDisabled={store.isPrevPageDisabled}
                            />
                        </div>
                        <EmptyView onNewOrderClick={() => {page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)}} />
                    </>
                )
            } else {
                return <div/>
            }
        }

        return <div>
            <div className={styles.refreshButtonWrapper}>
                <ListActions
                    currentTotalAmount={store.orders?.length}
                    onReloadClick={refresh}
                    onGoNextPageClick={loadNextPage}
                    onGoPrevPageClick={loadPrevPage}
                    isNextPageDisabled={store.isNextPageDisabled}
                    isPrevPageDisabled={store.isPrevPageDisabled}
                />
            </div>
            {!store.isLoading && (
                <OrdersListView orders={store.orders} onOrderItemClick={store.onOrderItemClick}/>
            )}
        </div>
    }

    const statusesItems = orderStatuses.map(status => ({
        label: status.status,
        value: status.orderStatusId
    }))

    return <Page>
        <div className={styles.OrderHistory}>
            <div className={styles.header} key={'header'}>{translations().orderHistory}</div>
            <div className={styles.content} id={'orderHistoryContent'}>
                <div className={styles.rangePickerAndStatusContainer}>
                    {translations().for}
                    <RangePicker store={store.rangePickerStore} />
                    {translations().status}
                    <InlineSelect
                        selectItems={statusesItems}
                        value={store.chosenOrderStatusId}
                        onSelect={store.setOrderStatusId}
                    />
                </div>
                <div className={styles.searchBarContainer}>
                    <SearchBar>
                        <FiltersSelect store={store.filtersSelectStore} />
                    </SearchBar>
                </div>
                {renderContent()}
            </div>
        </div>
    </Page>
})
