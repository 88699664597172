import { action, makeObservable, observable } from 'mobx'
import { SummaryBlockData } from '~/code/common/components'
import { IRequestSummaryStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/RequestSummary'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { OnboardingStepKey } from '~/code/stores/CheckoutV3Onboarding/models'

export class RequestSummaryStore implements IRequestSummaryStore{

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            requestSummaryData: observable,
            clearAndSetUp: action,
            goBack: action,
            placeOrder: action
        })
    }

    static stepName: OnboardingStepKey = 'summary'

    requestSummaryData: SummaryBlockData[]

    clearAndSetUp = () => {
        this.formSubmitHandler.clear()
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack()
    }

    placeOrder = () => {
        this.formSubmitHandler.handleFormSubmit(null, RequestSummaryStore.stepName)
    }

}
