import React from 'react'
import classNames from 'classnames'
import { PropProps } from './props'
import styles from './Prop.scss'

export const Prop = (props: PropProps) => {
    return <div className={classNames(styles.Prop, props.className)}>
        <div className={classNames(styles.propLabel, props.labelClassName)}>{props.label}</div>
        <div className={classNames(styles.propValue, props.valueClassName)}>{props.value}</div>
    </div>
}
