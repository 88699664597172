import FastMutex from 'fast-mutex/dist/fast-mutex'
import { v4 as uuidv4 } from 'uuid/dist'
import {
    StorageOptions,
    StorageType
} from "../models";

const THREAD_ID = uuidv4()
const mutex = new FastMutex({
    clientId: THREAD_ID,
    localStorage: window.localStorage
});

export function getStorage(storageOptions: StorageOptions) {
    if (!storageOptions || storageOptions.storageType !== StorageType.sessionStorage) {
        return localStorage
    }
    return sessionStorage
}

export function get<T = any>(key: string, storageOptions: StorageOptions = { storageType: StorageType.localStorage, useUserHash: true, userHash: '' }): T {
    try {
        const storage = getStorage(storageOptions)
        const prefix = storageOptions && storageOptions.useUserHash ? storageOptions.userHash + '_' : ''
        const value = storage.getItem(prefix + key)
        if (value === null || value === 'undefined') return undefined
        return JSON.parse(value)
    } catch (e) {
        return undefined
    }
}

export function set(key: string, value: any, storageOptions: StorageOptions = { storageType: StorageType.localStorage, useUserHash: true, userHash: '' }) {
    try {
        const storage = getStorage(storageOptions)
        const prefix = storageOptions && storageOptions.useUserHash ? storageOptions.userHash + '_' : ''
        storage.setItem(prefix + key, JSON.stringify(value))
    } catch (e) {
        // do nothing
    }
}

export async function setWithMutex(key: string, value: any, storageOptions: StorageOptions = { storageType: StorageType.localStorage, useUserHash: true }): Promise<void> {

    await mutex.lock(key)
    set(key, value, storageOptions)
    mutex.release(key)
}
