import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    brandingOrganisation: string
    brandingOrganisationTooltip: string
    orderInformation: string
    poNumber: string
    poNumberRequired: string
    activationDate: string
    contactsForActivation: string
    contactsForActivationTooltip: string
    useMainContact: string
    companyName: string
    companyNameRequired: string
    firstName: string
    firstNameRequired: string
    surname: string
    surnameRequired: string
    email: string
    emailRequired: string
    emailInvalid: string
    back: string
    nextStep: string

    dealId: string
    dealIdRequired: string
    invalidDealId: string
    uniqueOrderReference: string
    uniqueOrderReferenceRequired: string
    orderType: string
    orderTypeRequired: string
    upgradeDetails: string
    addUpgradeRow: string
    serialNumber: string
    serialNumberRequired: string
    tid: string
    tidRequired: string
    partnerDetails: string
    partnerCompany: string
    agentName: string
    containsAcquirerRevenue: string
    doesntContainAcquirerRevenue: string
    revenueDetails: string
    maximumLength: string
}>()

