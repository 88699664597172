import React, { forwardRef } from 'react'
import { Select, SelectProps } from '~/code/common/components'
import { getOTTCustomBundlePriceValue } from '~/code/POS/pages/NewOrder/services/utils'

interface BundlePriceSelectProps extends SelectProps<string> {
    bundle?: {
        containsVerifoneProduct: boolean,
        bundleCode: string,
        description: string
    }
}

export const BundlePriceSelect = forwardRef<any, BundlePriceSelectProps>((props: BundlePriceSelectProps) => {
    const { bundle, ...rest } = props
    const value = props.value !== undefined && props.value !== null ? props.value : getOTTCustomBundlePriceValue(bundle)

    return <Select {...rest} value={value}/>
})
