import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Dropdown, Popconfirm, Tooltip } from 'antd'
import { SvgDropDownArrow } from '~/assets/icons'
import { ListItem } from '../ListItem'
import translations from './translations'
import { OwnerGroup } from './models'
import { OwnerGroupSelectProps } from './props'
import mainStyles from '~/assets/styles/main.scss'
import styles from './OwnerGroupSelect.scss'
import { isEmpty } from 'dna-common'

/**
 * A component that allows employees to choose what owner group to work under
 * @param selectedOwnerGroup - currently selected owner group
 * @param ownerGroups - a list of available owner groups
 * @param onOwnerGroupSelect - a callback function that is triggered when an item from the list is selected and confirmed
 * @constructor
 */
export const OwnerGroupSelect = ({selectedOwnerGroup, ownerGroups, onOwnerGroupSelect}: OwnerGroupSelectProps) => {
    const [isDropdownVisible, setDropdownVisible] = useState(isEmpty(selectedOwnerGroup))

    const handleOverlayVisibleChange = (visible) => {
        setDropdownVisible( visible )
    }

    const handleOwnerGroupSelect = (ownerGroup) => {
        handleOverlayVisibleChange(false)
        onOwnerGroupSelect(ownerGroup)
    }

    return (<>
            <Dropdown overlay={<OwnerGroupsContent ownerGroups={ownerGroups} selectedOwnerGroup={selectedOwnerGroup} onOwnerGroupSelect={handleOwnerGroupSelect}/>} placement={'bottomRight'} visible={isDropdownVisible} onVisibleChange={handleOverlayVisibleChange}>
                <Tooltip title={translations().selectOwnerGroup} overlayClassName={styles.tooltip} color={'white'} placement={'rightTop'} visible={Boolean(!selectedOwnerGroup?.id)}>
                    <div className={styles.OwnerGroupSelect}>
                        <div>
                            <div className={styles.logoWrapper}>
                                { selectedOwnerGroup?.lightLogo }
                            </div>
                        </div>
                        <SvgDropDownArrow className={mainStyles.icon}/>
                    </div>
                </Tooltip>
            </Dropdown>
            {isEmpty(selectedOwnerGroup) && <div className={styles.contentOverlay}/>}
        </>
    )
}

/**
 * A component that holds the content of the owner group select dropdown
 * @param ownerGroups - a list of available owner groups
 * @param selectedOwnerGroup - currently selected owner group
 * @param onOwnerGroupSelect - a callback function that is triggered when an item from the list is selected and confirmed
 */
const OwnerGroupsContent = ({ownerGroups, selectedOwnerGroup, onOwnerGroupSelect}: {ownerGroups: OwnerGroup[], selectedOwnerGroup: OwnerGroup, onOwnerGroupSelect: (ownerGroup: OwnerGroup) => void}) => {
    const items: {id: string | number, darkLogo?: JSX.Element}[] = ownerGroups

    const [isPopConfirmVisible, setPopConfirmVisible] = useState(false)
    const [_selectedOwnerGroup, _setSelectedOwnerGroup] = useState(null)

    const popConfirmRef = useRef(null)

    useEffect(() => {
        const documentClick = (e: MouseEvent) => {
            const target = e.target as Element
            const popConfirmElement = ReactDOM.findDOMNode(popConfirmRef.current) as Element

            // here we use ref as useState value does not get updated inside useEffect
            if (!popConfirmElement.contains(target)) {
                setPopConfirmVisible(false)
            }
        }
        document.addEventListener('click', documentClick)
        return () => document.removeEventListener('click', documentClick)
    }, [popConfirmRef])

    const handleSelectOwnerGroup = (ownerGroup) => {
        if (!isEmpty(selectedOwnerGroup) && selectedOwnerGroup.id !== ownerGroup.id) {
            _setSelectedOwnerGroup(ownerGroup)
            setPopConfirmVisible(true)
        } else {
            onOwnerGroupSelect(ownerGroup)
            setPopConfirmVisible(false)
        }
    }

    const handleConfirm = () => {
        onOwnerGroupSelect(_selectedOwnerGroup)
        setPopConfirmVisible(false)
    }

    const handleCancel = () => {
        setPopConfirmVisible(false)
    }

    return (
        <Popconfirm
            placement={'rightTop'}
            ref={popConfirmRef}
            title={translations().areYouSureToChange}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            visible={isPopConfirmVisible}
            okText={translations().yesChange}
            cancelText={translations().noKeep}
        >
            <div className={styles.OwnerGroupsContent}>
                {items.map(item => {
                    return <ListItem key={`${item.id}`} record={item} onClick={handleSelectOwnerGroup}>
                        <div className={styles.logoWrapper}>
                            {item.darkLogo}
                        </div>
                    </ListItem>
                })}
            </div>
        </Popconfirm>
    )
}
