import { SvgCall, SvgCart, SvgHelp, SvgSteps } from '~/assets/icons'
import translations from '~/code/common/layouts/MainLayout/components/SideMenu/translations'
import React from 'react'
import { FileTextOutlined, UserOutlined } from '@ant-design/icons'

export enum LOCATION_POS_PATH_NAMES {
    ORDER_HISTORY = '/pos/order-history',
    LOGIN = '/login',
    PLACE_ORDER = '/pos/place-order',
    ORDER_HISTORY_DETAILS = '/pos/order-history/*',
    USER_GUIDE = '/pos/user-guide',
    EMPLOYEE_FUNCTIONS = '/pos/employee-functions',
    CHECKOUT_V3_ONBOARDING = '/pos/employee-functions/checkout-v3-onboarding',
    FAQ = '/pos/faq',
    CONTACT_US = '/pos/contact-us'
}

export const POS_SIDE_MENU_ITEMS = [
    {
        icon: <SvgCart />,
        title: translations().newOrder,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.PLACE_ORDER
    },
    {
        icon: <SvgSteps />,
        title: translations().orderHistory,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.ORDER_HISTORY
    },
    {
        icon: <FileTextOutlined />,
        title: translations().userGuide,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.USER_GUIDE
    },
    {
        icon: <SvgHelp />,
        title: translations().faq,
        status: 'soon',
        route: LOCATION_POS_PATH_NAMES.FAQ
    },
    {
        icon: <SvgCall />,
        title: translations().contactUs,
        status: 'soon',
        route: LOCATION_POS_PATH_NAMES.CONTACT_US
    }
]

export const POS_EMPLOYEE_SIDE_MENU_ITEMS = [
    {
        icon: <SvgCart />,
        title: translations().newOrder,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.PLACE_ORDER
    },
    {
        icon: <SvgSteps />,
        title: translations().orderHistory,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.ORDER_HISTORY
    },
    {
        icon: <FileTextOutlined />,
        title: translations().userGuide,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.USER_GUIDE
    },
    {
        icon: <UserOutlined />,
        title: translations().employeeFunctions,
        status: 'active',
        route: LOCATION_POS_PATH_NAMES.EMPLOYEE_FUNCTIONS,
        items: [
            {
                title: translations().createCheckoutV3Account,
                status: 'active',
                route: LOCATION_POS_PATH_NAMES.CHECKOUT_V3_ONBOARDING
            }
        ]
    },
    {
        icon: <SvgHelp />,
        title: translations().faq,
        status: 'soon',
        route: LOCATION_POS_PATH_NAMES.FAQ
    },
    {
        icon: <SvgCall />,
        title: translations().contactUs,
        status: 'soon',
        route: LOCATION_POS_PATH_NAMES.CONTACT_US
    }
]

export const WARNING_COLOR = '#faad14'
export const GREEN_COLOR = '#12B138'
