// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._22_RqQfdTh-UsCPhBjuTms {\n  padding-bottom: 20px; }\n  ._22_RqQfdTh-UsCPhBjuTms ._1kcJO7uUntcEXPMfeJHz-9 {\n    color: red; }\n  ._22_RqQfdTh-UsCPhBjuTms ._2LCgVYQ-VnlDP3beKUsYqp {\n    text-align: right; }\n", ""]);
// Exports
exports.locals = {
	"AddStoreForm": "_22_RqQfdTh-UsCPhBjuTms",
	"storeNameNotification": "_1kcJO7uUntcEXPMfeJHz-9",
	"useMerchantDetailsButtonWrapper": "_2LCgVYQ-VnlDP3beKUsYqp"
};
module.exports = exports;
