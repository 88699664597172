import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    serialNumber: string
    hardwarePtid: string
    name: string
    merchantNumber: string
    accountNumber: string
    nameSavedSuccessfully: string
}>()

