import { IOrderDetailsStore } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/IOrderDetailsStore'
import { createOrderDetailsModel, OrderDetailsModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/models/OrderDetailsModel'
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { getWithAuth, postWithAuth } from '~/code/services/authorised-requests'
import { isOrderDetailsPage, isOrderHistoryPage } from '~/code/services'
import { FailedOrdersResponse } from '~/code/stores/OrderDetailsStore/models/FailedOrdersResponse'
import { persist } from 'mobx-persist'
import { ConfigurationReportModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/components/ConfigurationReport/models/ConfigurationReportModel'
import { ConfigurationReportResponse } from '~/code/stores/OrderDetailsStore/models/ConfigurationReportResponse'
import { configurationReportResponseToConfigurationReportModel } from '~/code/stores/OrderDetailsStore/services/configuration-report-mapper'
import { message } from 'antd'
import page from 'page'
import { DispatchReportModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/components/DispatchReport/models/DispatchReportModel'
import { dispatchReportResponseToConfigurationReportModel } from '~/code/stores/OrderDetailsStore/services/dispatch-report-mapper'
import { DispatchReportResponse } from '~/code/stores/OrderDetailsStore/models/DispatchReportResponse'
import { NO_CONTENT } from '~/code/models/ErrorNames'
import translations from '~/code/POS/pages/OrderHistory/pages/OrderDetails/translations'
import localTranslations from './translations'
import { SearchItem } from '~/code/common/components/SearchBar/models/SearchItem'
import { OrderStatusResponse } from './models/OrderStatusResponse'
import { OrderStatusErrorModel } from './models/OrderStatusErrorModel'
import { AppStore as appStore } from '~/code/AppStore'
import { INewOrderStore } from '~/code/POS/pages/NewOrder/INewOrderStore'
import { OWNER_GROUPS, PAGE_SIZE } from '~/code/models'
import { cancelOrder } from '~/code/stores/OrderDetailsStore/services/fetchers'
import { EmployeeMerchant } from '~/code/POS/pages/NewOrder/models/EmployeeMerchant'
import { OrderFlowType } from './models/OrderFlowType'
import { IOrderHistoryStore } from '~/code/POS/pages/OrderHistory/IOrderHistoryStore'
import { error, isEmpty } from 'dna-common'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'

export class OrderDetailsStore implements IOrderDetailsStore {

    constructor (placeOrderStore: INewOrderStore, orderHistoryStore: IOrderHistoryStore) {
        makeObservable(this, {
            prevIdentifier: observable,
            prevOwnerGroupId: observable,
            isErrorInfoVisible: observable,
            errorInfo: observable,
            isNoCompanySelectedVisible: observable,
            noCompanySelectedDescription: observable,
            isConfReportVisible: observable,
            isDispatchReportVisible: observable,
            configurationReport: observable,
            dispatchReport: observable,
            isCancelOrderLoading: observable,
            isLoading: observable,
            order: observable,
            userInfo: computed,
            isOrderingPrevented: computed,
            selectedEmployeeCompany: computed,
            internalIdentifier: computed,
            internalIdentifierEncoded: computed,
            hasInternalIdentifier: computed,
            shouldSelectCompany: computed,
            orderFlowType: computed,
            reorder: action,
            loadOrderRequest: action,
            loadConfigurationReport: action,
            loadDispatchReport: action,
            getInternalIdentifierAsBody: action,
            getInternalIdentifierAsQuery: action,
            loadErrorInfo: action,
            withCompanySelectionCheck: action,
            handleConfReportShow: action,
            handleConfReportClose: action,
            clear: action,
            handleDispatchReportShow: action,
            handleDispatchReportClose: action,
            handleErrorInfoShow: action,
            handleErrorInfoClose: action,
            handleNoCompanySelectedClose: action,
            cancelOrder: action,
            setPrevOwnerGroupId: action,
            setPrevIdentifier: action
        })

        this.placeOrderStore = placeOrderStore
        this.orderHistoryStore = orderHistoryStore

        autorun(() => {
            if (this.prevIdentifier !== this.internalIdentifier) {
                if (isOrderHistoryPage()) {
                    page(LOCATION_POS_PATH_NAMES.ORDER_HISTORY)
                }
            }
            this.setPrevIdentifier(this.internalIdentifier)
        }, {delay: 300})

        autorun(() => {
            if (this.prevOwnerGroupId !== this.userInfo?.ownerGroupId && isOrderDetailsPage()) {
                page(LOCATION_POS_PATH_NAMES.ORDER_HISTORY)
            }

            this.setPrevOwnerGroupId(this.userInfo?.ownerGroupId)
        }, {delay: 300})
    }

    @persist
    public prevIdentifier = null

    @persist
    public prevOwnerGroupId = null

    public placeOrderStore: INewOrderStore
    public orderHistoryStore: IOrderHistoryStore

    isErrorInfoVisible: boolean = false

    errorInfo: OrderStatusErrorModel[]

    isNoCompanySelectedVisible: boolean = false

    noCompanySelectedDescription: string = ''

    isConfReportVisible: boolean = false

    isDispatchReportVisible: boolean = false

    configurationReport: ConfigurationReportModel

    dispatchReport: DispatchReportModel[]

    isCancelOrderLoading: boolean = false

    @persist
    isLoading: boolean = false

    @persist('object', OrderDetailsModel)
    order: OrderDetailsModel

    get userInfo(): UserInfo {
        return appStore?.userInfo
    }

    get isOrderingPrevented(): boolean {
        return appStore?.userInfo?.preventOrdering || appStore?.companySelectStore?.selectedEmployeeCompany?.additionalInfo?.preventOrdering
    }

    get selectedEmployeeCompany(): SearchItem {
        return appStore.companySelectStore.selectedEmployeeCompany
    }

    get internalIdentifier(): string {
        return appStore.internalIdentifier
    }

    get internalIdentifierEncoded(): string {
        return appStore.internalIdentifierEncoded
    }

    get hasInternalIdentifier(): boolean {
        return appStore.userInfo?.userType === 'employee' && !isEmpty(this.internalIdentifier)
    }

    get shouldSelectCompany(): boolean {
        return appStore.userInfo?.userType === 'employee' && appStore.userInfo?.ownerGroupId === OWNER_GROUPS.optomany && isEmpty(this.internalIdentifier)
    }

    get orderFlowType(): OrderFlowType {
        if (appStore?.headerStore?.selectedOwnerGroup?.id === OWNER_GROUPS['123send']) return '123send'
        return this.userInfo.companyType
    }

    setPrevIdentifier = prevIdentifier => {
        this.prevIdentifier = prevIdentifier
    }

    setPrevOwnerGroupId = prevOwnerGroupId => {
        this.prevOwnerGroupId = prevOwnerGroupId
    }

    reorder = () => {
        this.withCompanySelectionCheck(translations().selectCompanyToViewTryAgain, () => {
            if (this.order.status === 'failed') {
                this.loadOrderRequest()
            } else {
                this.placeOrderStore.createOrderFromOrderInfo(this.order.additionalInfo)
            }
        })
    }

    loadOrderRequest = async () => {
        this.isLoading = true
        const {internalIdentifier, employeeMerchant} = await this.getInternalIdentifierAsQuery(true)

        const _internalIdentifier = internalIdentifier ? `&internalIdentifier=${internalIdentifier}` : ''

        checkHTTPStatus(getWithAuth<FailedOrdersResponse>(`/api/failed-orders?orderReference=${this.order.orderReference}${_internalIdentifier}`)).then(response => {
            if (response) {
                this.placeOrderStore.createOrderFromStringAndOrder(response.orderRequest, this.order, employeeMerchant)
            }
        }).catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        }).finally(() => {
            this.isLoading = false
        })
    }

    loadConfigurationReport = async () => {
        this.isLoading = true
        let data = {
            orderReferences: [
                this.order.orderReference
            ],
            page: 0
        }
        data = await this.getInternalIdentifierAsBody(data)

        checkHTTPStatus(postWithAuth<ConfigurationReportResponse>(`/api/system-configuration/create-configuration-report`, data)).then(response => {
            if (response) {
                this.configurationReport = configurationReportResponseToConfigurationReportModel(response)
                this.isConfReportVisible = true
            }
        }).catch((err: Error) => {
            if (err.name === NO_CONTENT) {
                message.error(translations().noConfigurationReport)
                return
            }
            message.error(err.message)
        }).finally(() => {
            this.isLoading = false
        })
    }

    loadDispatchReport = async () => {
        this.isLoading = true
        let data = {
            orderReferences: [
                this.order.orderReference
            ]
        }

        data = await this.getInternalIdentifierAsBody(data)

        postWithAuth<DispatchReportResponse[]>(`/api/dispatch-detail/create-dispatch-report`, data).then(response => {
            if (!isEmpty(response)) {
                if (response.status !== 200) {
                    throw {name: NO_CONTENT}
                } else {
                    this.dispatchReport = dispatchReportResponseToConfigurationReportModel(response.result)
                    this.isDispatchReportVisible = true
                }
            }
        }).catch((err: Error) => {
            if (err.name === NO_CONTENT) {
                message.error(translations().noDispatchReport)
                return
            }
            message.error(err.message)
        }).finally(() => {
            this.isLoading = false
        })
    }

    getInternalIdentifierAsBody = async (data) => {
        if (this.hasInternalIdentifier) {
            data['internalIdentifier'] = this.internalIdentifier
        }

        if (appStore?.headerStore?.selectedOwnerGroup?.id === OWNER_GROUPS['123send']) {
            try {
                const [employeeMerchant] = await checkHTTPStatus(getWithAuth<EmployeeMerchant[]>('/api/employees/companies-for-orders',
                    {
                        pageNumber: 1,
                        pageSize: PAGE_SIZE,
                        searchValue: this.order.accountNumber,
                        ownerGroupId: OWNER_GROUPS['123send']
                    }
                ))
                data['internalIdentifier'] = employeeMerchant?.internalIdentifier
            } catch (e) {
                error(e)
            }
        }

        return data
    }

    getInternalIdentifierAsQuery = async (encoded?: boolean) => {
        let internalIdentifier = ''
        if (this.hasInternalIdentifier) {
            internalIdentifier = encoded ? this.internalIdentifierEncoded : this.internalIdentifier
        }
        if (appStore?.headerStore?.selectedOwnerGroup?.id === OWNER_GROUPS['123send']) {
            try {
                const [employeeMerchant] = await checkHTTPStatus(getWithAuth<EmployeeMerchant[]>('/api/employees/companies-for-orders',
                    {
                        pageNumber: 1,
                        pageSize: PAGE_SIZE,
                        searchValue: this.order.accountNumber,
                        ownerGroupId: OWNER_GROUPS['123send']
                    }
                ))
                internalIdentifier = encoded ? encodeURIComponent(employeeMerchant?.internalIdentifier) : employeeMerchant?.internalIdentifier

                return {
                    internalIdentifier,
                    employeeMerchant
                }
            } catch (e) {
                error(e)
            }
        }

        if (appStore?.headerStore?.selectedOwnerGroup?.id === OWNER_GROUPS['optomany']) {
            try {
                const [employeeMerchant] = await checkHTTPStatus(getWithAuth<EmployeeMerchant[]>('/api/employees/companies-for-orders',
                    {
                        pageNumber: 1,
                        pageSize: PAGE_SIZE,
                        searchValue: this.order.accountNumber,
                        ownerGroupId: OWNER_GROUPS['optomany']
                    }
                ))
                internalIdentifier = encoded ? encodeURIComponent(employeeMerchant?.internalIdentifier) : employeeMerchant?.internalIdentifier

                return {
                    internalIdentifier,
                    employeeMerchant
                }
            } catch (e) {
                error(e)
            }
        }

        return {
            internalIdentifier
        }
    }

    getInternalIdByUserType = (internalIdentifier) => {
        switch (this.userInfo.userType) {
            case 'employee':
                return `&internalIdentifier=${internalIdentifier}`
            case 'none':
                return ''
        }
    }

    loadErrorInfo = async () => {
        const { internalIdentifier } = await this.getInternalIdentifierAsQuery(true)

        if (!internalIdentifier && this.userInfo.userType !== 'none') {
            this.isErrorInfoVisible = true
            this.errorInfo = [{ errorText: `Can't get access to failed order details`, errorCode: '401', properties: [] }]
            return
        }

        this.isLoading = true

        checkHTTPStatus(getWithAuth<OrderStatusResponse>(`/api/order-details/order-status?orderReference=${this.order.orderReference}${this.getInternalIdByUserType(internalIdentifier)}`)).then(response => {
            if (response.errorsWithinOrder && response.errorsWithinOrder.length > 0) {
                const errorInfo = new Map<string, OrderStatusErrorModel>()
                response.errorsWithinOrder.forEach(({errorCode, property}) => {
                    if (!errorInfo.has(errorCode)) {
                        errorInfo.set(errorCode, {errorCode, errorText: localTranslations().orderStatusErrorCodes[errorCode], properties: []})
                    }
                    errorInfo.get(errorCode).properties.push(property)
                })
                this.errorInfo = Array.from(errorInfo.values())
                this.isErrorInfoVisible = true
            }
        }).catch((err: Error) => {
            message.error(localTranslations().fetchError)
            error(`FAILED: ${err.message}`)
        }).finally(() => {
            this.isLoading = false
        })
    }

    withCompanySelectionCheck = (noCompanySelectedDescription, callBack) => {
        if (this.shouldSelectCompany) {
            this.noCompanySelectedDescription = noCompanySelectedDescription
            this.isNoCompanySelectedVisible = true
            return
        }
        callBack()
    }

    handleConfReportShow = () => {
        this.withCompanySelectionCheck(translations().selectCompanyToViewConfigurationReport, () => {
            if (isEmpty(this.configurationReport)) {
                this.loadConfigurationReport()
            } else {
                this.isConfReportVisible = true
            }
        })
    }

    handleConfReportClose = () => {
        this.isConfReportVisible = false
    }

    clear = () => {
        this.isLoading = false
        this.order = createOrderDetailsModel()
        this.prevIdentifier = null
    }

    handleDispatchReportShow = () => {
        this.withCompanySelectionCheck(translations().selectCompanyToViewDispatchReport, () => {
            if (isEmpty(this.dispatchReport)) {
                this.loadDispatchReport()
            } else {
                this.isDispatchReportVisible = true
            }
        })
    }

    handleDispatchReportClose =  () => {
        this.isDispatchReportVisible = false
    }

    handleErrorInfoShow = () => {
        this.withCompanySelectionCheck(translations().selectCompanyToViewErrorDetails, () => {
                if (isEmpty(this.errorInfo)) {
                    this.loadErrorInfo()
                } else {
                    this.isErrorInfoVisible = true
                }
            }
        )
    }

    handleErrorInfoClose = () => {
        this.isErrorInfoVisible = false
    }

    handleNoCompanySelectedClose = () => {
        this.isNoCompanySelectedVisible = false
    }

    cancelOrder = async () => {
        this.isCancelOrderLoading = true
        const { internalIdentifier } = await this.getInternalIdentifierAsQuery()

        let orderStatus: 'cancelled' | 'cancellationRequested'
        let orderStatusLabel: string

        cancelOrder(this.orderFlowType, this.order.orderReference, internalIdentifier)
            .then(response => {
                const {status} = response

                if (status === 200) {
                    message.success(localTranslations().cancelOrderSuccessMsg)
                    runInAction(() => {
                        this.order.canBeCancelled = false
                        orderStatus = 'cancelled'
                        orderStatusLabel = translations().cancelled
                    })
                } else if (status === 202) {
                    message.info(localTranslations().cancelling)
                    runInAction(() => {
                        this.order.isCancelBtnDisabled = true
                        orderStatus = 'cancellationRequested'
                        orderStatusLabel = translations().cancelling
                    })
                } else {
                    message.error(localTranslations().cancelOrderErrMsg)
                }
            })
            .catch(err => {
                error(`FAILED: ${err.message}`)
            })
            .finally(() => {
                runInAction(() => {
                    this.isCancelOrderLoading = false
                    this.orderHistoryStore.refreshCurrentPage()
                    this.order.status = orderStatus
                    this.order.statusLabel = orderStatusLabel
                })
            })
    }
}
