import React, { useState } from 'react'
import { Modal, Popconfirm } from 'antd'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Button, SearchBar } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { SvgCompany } from '~/assets/icons'
import translations from './translations'
import { CompanySelectProps } from './props'
import styles from './CompanySelect.scss'

export const CompanySelect = observer((props: CompanySelectProps) => {
    const { store } = props
    const selectedCompany = store.selectedItem?.additionalInfo
    const [tempSelectedCompany, setTempSelectedCompany] = useState(store.selectedItem?.identifier || undefined)
    const [tempSelectedCompanyInfo, setTempSelectedCompanyInfo] = useState(undefined)
    const [isCompanySelected, setIsCompanySelected] = useState(false)

    const toggleModal = () => {
        store.setModalVisible(!store.isModalVisible)
    }

    const handleOk = () => {
        if (tempSelectedCompany === undefined || tempSelectedCompany === store.selectedItem?.identifier){
            store.setModalVisible(false)
            return
        }
        store.selectItem(tempSelectedCompany)
        store.setModalVisible(false)
    }

    const handleCancel = () => {
        store.setModalVisible(false)
    }

    const onSearchItemSelect = (value) => {
        setIsCompanySelected(true)
        setTempSelectedCompany(value || null)
        setTempSelectedCompanyInfo(store.items.find(item => item.identifier === value)?.additionalInfo || null)
    }

    const handleScroll = (e) => {
        const isEndOfList = e.target.scrollTop + e.target.clientHeight

        if (isEndOfList === e.target.scrollHeight ) {
            store.loadCompanies()
        }
    }

    const isConfirmButtonDisabled = isEmpty(store.items)
    const modalConfirmButton = (store.selectedItem?.identifier !== undefined && tempSelectedCompany !== store.selectedItem?.identifier) ?
        <Popconfirm
            placement={'topRight'}
            title={translations().areYouSureToChangeCompany}
            onConfirm={handleOk}
            okText={translations().yes}
            cancelText={translations().cancel}
        >
            <Button key={'submit'} type={'primary'} disabled={ isConfirmButtonDisabled }>
                { translations().confirm }
            </Button>
        </Popconfirm> :
        <Button key={'submit'} type={'primary'} onClick={handleOk} disabled={ isConfirmButtonDisabled }>
            { translations().confirm }
        </Button>

    const renderCompanyStatus = () => {
        if (selectedCompany) {
            if (selectedCompany?.preventOrdering) {
                return <div className={classNames(styles.label, styles.label__warning)}>
                    <span>{translations().onHold}</span>
                </div>
            }
            return <div className={styles.label}>
                <span>{translations().orderingAs}</span>
            </div>
        }
        return null
    }

    const onClear = () => {
        props.store.selectItem(null)
        setTempSelectedCompany(null)
    }

    const onBlur = () => {
        if (!isCompanySelected) {
            onClear()
        }
    }

    const onSearch = (value) => {
        setIsCompanySelected(false)
    }

    return <div className={styles.CompanySelect}>
        <div className={styles.iconTextWrapper}>
            <div className={styles.icon}>
                <SvgCompany />
            </div>
            <div>
                <div className={styles.title} onClick={toggleModal}>
                    { selectedCompany ? selectedCompany.companyName : translations().selectCompany }
                </div>
            </div>
        </div>
        { renderCompanyStatus() }

        { <Modal
            visible={store.isModalVisible}
            title={translations().selectCompany}
            onCancel={handleCancel}
            footer={modalConfirmButton}
            className={styles.CompanySelect_modal}
        >
            <div>
                <SearchBar
                    store={props.store}
                    searchInputPlaceholder={translations().searchBy}
                    searchInputValue={tempSelectedCompany}
                    searchInputAllowClear={true}
                    searchShowArrow={true}
                    searchOnPopupScroll={handleScroll}
                    searchShouldPreserveSelectedItemWhenNoData={true}
                    onSearchItemSelect={onSearchItemSelect}
                    searchOnFocus={() => store.loadCompanies()}
                    onClear={onClear}
                    onBlur={onBlur}
                    onSearch={onSearch}
                />
            </div>
            <div className={styles.CompanySelect_hint}>{translations().companyPurposeExplanation}</div>
            { tempSelectedCompanyInfo && tempSelectedCompanyInfo.preventOrdering &&
                <div className={classNames(styles.CompanySelect_hint, styles.CompanySelect_hint__warning)}>{translations().onHoldDescription}</div>
            }
        </Modal> }
    </div>
})
