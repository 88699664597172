import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PenniesFormProps } from './props'
import { Col, Form, Row } from 'antd'
import { Checkbox, GroupTitle, Input, Select, TextArea } from '~/code/common/components'
import translations from './translations'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { ImageUploadWithPreview } from '~/code/common/components'
import { TWO_DECIMAL_NUMBER_TYPING_PATTERN } from '~/code/models'

const validateAdjustmentField = (e: React.FormEvent<HTMLInputElement>, form, fieldName: string) => {
    const twoDigitsRegexp = TWO_DECIMAL_NUMBER_TYPING_PATTERN
    const value = e.currentTarget.value
    const test = twoDigitsRegexp.test(value)
    if (!test) {
        return form.getFieldValue(['adjustmentBands', fieldName])
    }

    return +value
}

export const PenniesForm = observer( ({ store }: PenniesFormProps) => {
    const {penniesFormData: data, setPenniesFormData, donationModes, canGoBack, goBack, uploadImage} = store
    const [isPenniesUsed, setIsPenniesUsed] = useState<boolean>(true)
    const [charityName, setCharityName] = useState<string>()
    const [charityNumber, setCharityNumber] = useState<number>(null)
    const [donationText, setDonationText] = useState<string>(`Every penny you give goes to charity: 90% to _ (charity no. _) and 10% to Pennies (charity no. 1122489).`)

    useEffect(() => {
        const charityNameValue = charityName ? charityName : '_'
        const charityNumberValue = charityNumber ? charityNumber : '_'

        setDonationText(`Every penny you give goes to charity: 90% to ${charityNameValue} (charity no. ${charityNumberValue}) and 10% to Pennies (charity no. 1122489).`)
    }, [charityName, charityNumber])

    const formInitialValues = {
        logo: data.logo,
        charityImage: data.logo,
        donationMode: data.donationMode,
        adjustmentBands: data.adjustmentBands,
        logoImageMimeType: data.logoImageMimeType,
        penniesDonationActiveText: donationText,
        penniesDonationInactiveText: data.penniesDonationInactiveText
    }

    const onFinish = (values) => {
        values.isPenniesUsed = isPenniesUsed
        values.penniesDonationActiveText = donationText
        setPenniesFormData(values)
    }

    const [form] = Form.useForm()

    const onCharityNameChange: ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.value

        setCharityName(value)
    }

    const onCharityNumberChange: ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.value

        if (!Number(+value)) {
            return
        }

        setCharityNumber(+value)
    }

    const onDonationTextChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
        const value = e.target.value

        setDonationText(value)
    }

    return (
        <div>
            <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={formInitialValues}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            name={'isPenniesUsed'}
                        >
                            <Checkbox onChange={(e) => setIsPenniesUsed(e.target.checked)} checked={isPenniesUsed}>
                                {translations().isPenniesUsed}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                { isPenniesUsed && (
                    <>
                        <Row justify={'space-between'}>
                            <Col xs={24} md={10}>
                                <Form.Item label={translations().charity}>
                                    <Input
                                        value={charityName}
                                        onChange={onCharityNameChange}
                                        placeholder={translations().charity}
                                        maxLength={30}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={10}>
                                <Form.Item label={translations().charityNumber}>
                                    <Input
                                        value={charityNumber}
                                        onChange={onCharityNumberChange}
                                        placeholder={translations().charityNumber}
                                        maxLength={15}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'space-between'}>
                            <Col xs={24} md={10}>
                                <Form.Item label={translations().donationPromptText}>
                                    <TextArea
                                        rows={5}
                                        autoSize={false}
                                        value={donationText}
                                        placeholder={translations().donationPromptText}
                                        maxLength={1000}
                                        onChange={onDonationTextChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={translations().donationMode}
                                    name={'donationMode'}
                                >
                                    <Select placeholder={translations().donationMode}
                                            selectItems={donationModes}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={10}>
                                <Form.Item
                                    label={translations().donationSelectedText}
                                    name={'penniesDonationInactiveText'}
                                >
                                    <TextArea
                                        rows={5}
                                        autoSize={false}
                                        maxLength={30}
                                        placeholder={translations().donationSelectedText}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <GroupTitle size={'small'} content={translations().adjustmentBanding} />
                        <Row justify={'space-between'}>
                            <Col xs={24} md={10}>
                                <Form.Item
                                    label={translations().ceilingAmount}
                                    name={['adjustmentBands', 0, 'bandCeilValue']}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => validateAdjustmentField(e, form, 'bandCeilValue')}
                                    validateTrigger="onBlur"
                                >
                                    <Input type={'number'} placeholder={translations().ceilingAmount}/>
                                </Form.Item>
                                <Form.Item
                                    label={translations().floorLimit}
                                    name={['adjustmentBands', 0, 'bandFloorValue']}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => validateAdjustmentField(e, form, 'bandFloorValue')}
                                >
                                    <Input type={'number'} placeholder={translations().floorLimit}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={10}>
                                <Form.Item
                                    label={translations().adjustmentValue}
                                    name={['adjustmentBands', 0, 'adjustmentValue']}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => validateAdjustmentField(e, form, 'adjustmentValue')}
                                >
                                    <Input type={'number'} placeholder={translations().adjustmentValue}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <GroupTitle size={'small'} content={translations().charityImage} />
                        <Row gutter={24}>
                            <Col xs={24}>
                                <Form.Item name={'charityImage'}>
                                    <ImageUploadWithPreview name="charityImage" value={data.charityImage} uploadFile={uploadImage}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                ) }
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
