import translations from './translations'

translations.add('en', {
    bundle: 'Bundle',
    bundleRequired: 'Bundle is required',
    bundleTooltip: 'Select from agreed product bundles, if a bundle is missing or incorrect please contact Optomany.',
    bundleDescription: 'Bundle description',
    acquirerAssignedTids: 'Acquirer Assigned TIDs',
    noDescription: 'No description',
    quantity: 'Quantity',
    quantityRequired: 'Quantity is required',
    back: 'Back',
    nextStep: 'Next',
    submit: 'Place Order'
})
