import translations from './translations'

translations.add('en', {
    loginPageTitle: 'Login',
    placeOrderPageTitle: 'Place Order',
    orderHistoryPageTitle: 'Order History',
    orderDetailsPageTitle: 'Order Details',
    userGuidePageTitle: 'User Guide',
    checkoutV3OnboardingPageTitle: 'Checkout V3 Onboarding',
    deviceManagementDetailsPageTitle: 'Device Management Details',
    applicationManagementPageTitle: 'Application Management',
    applicationManagementDetailsPageTitle: 'Application Management Details',
    firmwareManagementPageTitle: 'Firmware Management',
    firmwareManagementDetailsPageTitle: 'Firmware Management Details',
    deviceManagementPageTitle: 'Device Management',
    merchantSchedulePageTitle: 'Merchant Schedule',
    merchantSelectionPageTitle: 'Merchant Selection',
    notFoundPageTitle: 'Not Found',

    invalidWorldPayMIDLength: 'Worldpay MID is usually 8 digits, please check and ensure the MID is correct',
    invalidBarclaycardSterlingMIDLength: 'Barclaycard Sterling MID is usually 7 digits, please check and ensure the MID is correct',
    invalidGlobalPayMIDLength: 'GlobalPay MID is usually 8 digits, please check and ensure the MID is correct',
    invalidAMEXMIDLength: 'AMEX MID is usually 10 digits, please check and ensure the MID is correct',
    invalidElavonMIDLength: 'Elavon MID is usually 10 digits, please check and ensure the MID is correct',
    invalidBarclaycardMCMIDLength: 'Barclaycard MC MID is usually 7 digits, please check and ensure the MID is correct',
    invalidOptomanyMIDLength: 'Optomany MID is usually more than 5 digits, please check and ensure the MID is correct',
    invalidLloydsCardNetMIDLength: 'Lloyds CardNet MID is usually 15 digits, please check and ensure the MID is correct',
    invalidFDMSMIDLength: 'FDMS MID is usually 15 digits, please check and ensure the MID is correct',
    invalidAIBMIDLength: 'AIB MID is usually 9 digits, please check and ensure the MID is correct',
    invalidSwedbankMIDLength: 'Swedbank MID is usually more than 5 digits, please check and ensure the MID is correct',
    invalidBorgunMIDLength: 'Borgun MID is usually 7 digits, please check and ensure the MID is correct',
    invalidDNAPaymentsMIDLength: 'DNA Payments MID is usually 11 digits, please check and ensure the MID is correct',

    invalidDNAPaymentsTIDLength: 'DNA Payments TID must be 8 digits',
    invalidDNAPaymentsEComTIDLength: 'DNA Payments eCom TID must be 32 characters (GUID)',
    invalidDNAPaymentsEComTIDFormat: 'DNA Payments eCom TID must be a valid GUID string',
    errors: {
        unfortunatelyWeCantProcessYourRequest: (traceId) => `Unable to retrieve requested data - please try again. If the problem persists, please contact the helpdesk. ${traceId ? traceId : ''}`,
        unableToRetrieveRequestedDate: (traceId) => `Unfortunately, we are unable to process your request at this time. ${traceId ? traceId : ''}`,
        yourSessionHasExpired: 'Your session has expired. Please log in again.'
    }
})
