import React from 'react'
import { Col, Popconfirm, Row } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { PlainButton } from '../AddProductForm/components/PlainButton/PlainButton'
import translations from './translations'
import { ProductListItemProps } from './props'
import styles from './ProductListItem.scss'
import { formatPriceWithCurrency } from '~/code/services/ui-helpers'

export const ProductListItem = (props: ProductListItemProps) => {
    const {
        onEditClick,
        onDeleteClick
    } = props

    const {
        storeName,
        storeAddressLine1,
        storeAddressLine2,
        storeTown,
        storePostcode,
        departmentName,
        departmentAcquirer,
        departmentMerchantCategory,
        departmentCurrency,
        departmentVisaMID,
        bundles
    } = props.productListItem

    return (
        <div className={styles.ProductListItem}>
            <Row gutter={16}>
                <Col xs={24} sm={8}>
                    <div className={styles.header}>{translations().store}</div>
                    <div className={styles.title}>{storeName}</div>
                    <div className={styles.subtitle}>{storeAddressLine1}</div>
                    <div className={styles.subtitle}>{storeAddressLine2}</div>
                    <div className={styles.subtitle}>{storeTown}</div>
                    <div className={styles.subtitle}>{storePostcode}</div>
                </Col>
                <Col xs={24} sm={8}>
                    <div className={styles.header}>{translations().department}</div>
                    <div className={styles.title}>{departmentName}</div>
                    <div className={styles.subtitle}>{departmentAcquirer}</div>
                    <div className={styles.subtitle}>{departmentMerchantCategory}</div>
                    <div className={styles.subtitle}>{departmentCurrency}</div>
                    <div className={styles.subtitle}>{departmentVisaMID}</div>
                </Col>
                <Col xs={24} sm={8}>
                    <div className={styles.header}>{translations().bundles}</div>
                    {
                        bundles.map(bundle => <div className={styles.bundleWrapper} key={bundle.bundleCode}>
                            <div className={styles.title}>{`${bundle.bundleCode} (x${bundle.bundleQuantity})${bundle['bundleCustomPrice'] ? ' (' + formatPriceWithCurrency(bundle['bundleCustomPrice']) + ')' : ''}`}</div>
                            <div className={styles.subtitle}>{bundle.bundleDescription}</div>
                        </div>)
                    }
                </Col>
            </Row>
            <div className={styles.buttonsWrapper}>
                <PlainButton
                    icon={<EditOutlined/>}
                    text={translations().edit}
                    className={styles.editButton}
                    onClick={onEditClick}
                />
                <Popconfirm
                    placement={'topRight'}
                    title={translations().areYouSureToDelete}
                    onConfirm={onDeleteClick}
                    okText={translations().yesDelete}
                    cancelText={translations().noDontDelete}
                >
                    <PlainButton
                        icon={<DeleteOutlined/>}
                        text={translations().delete}
                        className={styles.deleteButton}
                    />
                </Popconfirm>
            </div>
        </div>
    )
}
