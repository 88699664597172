import { LabeledValue } from 'antd/lib/select'
import { action, computed, makeObservable, observable } from 'mobx'
import { AppStore } from '~/code/AppStore'
import { IFiltersSelectStore } from '~/code/common/components/FiltersSelect/IFiltersSelectStore'
import { FiltersSelectItem } from '~/code/common/components/FiltersSelect/models'
import translations from './translations'

export class FiltersSelectStore implements IFiltersSelectStore {
    constructor(
        public loadOrders: (pageNumber?) => void,
        options?: LabeledValue[],
        public onDeselectFilter?: (value, context) => void
    ) {
        makeObservable(this, {
            values: observable,
            query: observable,
            _options: observable,
            ownerGroupId: observable,
            orderReference: observable,
            placedBy: observable,
            bundleCode: observable,
            oneTwoThreeSendDealId: observable,
            serialNumber: observable,
            hardwarePtid: observable,
            name: observable,
            merchantNumber: observable,
            accountNumber: observable,
            options: computed,
            setValues: action.bound,
            setQuery: action.bound,
            onSelect: action.bound,
            onDeselect: action.bound
        })

        if (options) {
            this._options = options
        }
    }

    // Select values
    values: FiltersSelectItem[]

    // User input
    query: string

    // Select options
    _options: LabeledValue[] = [
        {
            key: 'orderReference',
            value: 'orderReference',
            label: translations().orderReference
        },
        {
            key: 'placedBy',
            value: 'placedBy',
            label: translations().placedBy
        },
        {
            key: 'bundleCode',
            value: 'bundleCode',
            label: translations().bundleCode
        }
    ]

    // Query params
    ownerGroupId: string
    orderReference: string
    placedBy: string
    bundleCode: string
    oneTwoThreeSendDealId: string
    serialNumber: string
    hardwarePtid: string
    name: string
    merchantNumber: string
    accountNumber: string

    get options(): LabeledValue[] {
        if (AppStore.userInfo.ownerGroupId === 10001) {
            return [
                ...this._options,
                {
                    key: 'oneTwoThreeSendDealId',
                    value: 'oneTwoThreeSendDealId',
                    label: translations().oneTwoThreeSendDealId
                }
            ]
        }

        return this._options
    }

    setValues(values: FiltersSelectItem[]) {
        this.values = values
    }

    setQuery(query: string) {
        this.query = query
    }

    onSelect(val: LabeledValue) {
        this[val.value] = this.query

        this.loadOrders(1)
        this.query = ''
    }

    onDeselect(val: LabeledValue) {
        if (this.onDeselectFilter) {
            this.onDeselectFilter(val, this)
            return
        }

        this[val.value] = undefined

        this.loadOrders(1)
        this.query = ''
    }
}
