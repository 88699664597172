// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Ul0SWKG9RoTueZ_P38M1H {\n  height: 100%;\n  width: 100%;\n  padding: 128px 8px;\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  .Ul0SWKG9RoTueZ_P38M1H ._2VrFgWil0G2jcApWoWSh22 {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 32px;\n    line-height: 48px;\n    color: #000000; }\n  .Ul0SWKG9RoTueZ_P38M1H .AjGpuYJit0XubOJOzzdOO {\n    width: 48px;\n    height: 48px;\n    font-size: 48px;\n    line-height: 48px;\n    margin-top: 24px;\n    margin-bottom: 24px; }\n    .Ul0SWKG9RoTueZ_P38M1H ._3GhHO1eDJfue8a68bnn6Jc {\n      color: #00A0DC; }\n    .Ul0SWKG9RoTueZ_P38M1H .iA26BYwsAVKBdpUsqenMC {\n      color: #00C81A; }\n    .Ul0SWKG9RoTueZ_P38M1H .F5dqZuJEOA17FJcvJgLdl {\n      color: #F53777; }\n  .Ul0SWKG9RoTueZ_P38M1H ._2sxLXgKXo3YqJLyKRK9vWt {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #8C8C8C;\n    text-align: center; }\n  .Ul0SWKG9RoTueZ_P38M1H ._1_fZHFDUPwUaib_czPlkei {\n    width: 100%;\n    padding-top: 96px;\n    text-align: center; }\n", ""]);
// Exports
exports.locals = {
	"Progress": "Ul0SWKG9RoTueZ_P38M1H",
	"title": "_2VrFgWil0G2jcApWoWSh22",
	"icon": "AjGpuYJit0XubOJOzzdOO",
	"icon_loading": "_3GhHO1eDJfue8a68bnn6Jc",
	"icon_success": "iA26BYwsAVKBdpUsqenMC",
	"icon_failure": "F5dqZuJEOA17FJcvJgLdl",
	"message": "_2sxLXgKXo3YqJLyKRK9vWt",
	"buttonWrapper": "_1_fZHFDUPwUaib_czPlkei"
};
module.exports = exports;
