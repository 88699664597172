import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    merchantId: string
    merchantIdRequired: string
    acquirer: string
    acquirerRequired: string
    accountNumber: string
    accountNumberRequired: string
    accountNumberInvalid: string
    sortCode: string
    sortCodeRequired: string
    sortCodeInvalid: string
}>()

