import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    updateApp: string
    cancel: string
    pushUpdate: string
    installPriority: string
    pleaseSelectPriority: string
    appVersion: string
    pleaseTypePackageVersionId: string
}>()
