import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    placeOrder: string
    merchantName: string
    visaMID: string
    accountNumber: string
    newOrderForSoleTrader: string
    newOrderForSoleTraderTooltip: string
    company: string
    soleTrader: string
    information: string
    orderType: string
    deliveryAddress: string
    processingDetails: string
    placingOrder: string
    products: string
    merchantDetails: string,
    storesAndDepartments: string,
    productList: string
    orderDetails: string,
    deliveryDetails: string,
    pleaseWait: string
    success: string
    orderHasBeenPlaced: string
    trackOrderInOrderHistory: string
    sorry: string
    unableToPlaceOrder: string
    error: string
    orderReference: string
    pleaseContactUs: string
    orderingAs: string
    companyName: string
    noCompanySelected: string
    existingMerchant: string
    newMerchant: string
    existingMerchantSearchText: string
    existingPartnerMerchantSearchText: string
    newROIMerchantSearchText: string
    newUKMerchantSearchText: string
    cancelOrder: string,
    cancelOrderConfirmText: JSX.Element
    confirmCancelOrder: string
    cancelCancelOrder: string
    orderingPrevented: JSX.Element
    enterManually: string
    dataNotFoundInCH: string
    dataNotFoundInROI: string
    dataNotFoundInExisting: string
    areYouSureToEnterManually: JSX.Element
    yesAddManually: string
    noDontAddManually: string
    error19Text: JSX.Element
    error20Text: JSX.Element
    noMerchantSelectedDescription: JSX.Element
    merchantMatch: string
    continueAsNewMerchant: string
    orderForThisMerchant: string
    matchFound: string
    companyNumber: string

    // newly added
    nextStep: string
    back: string

    // currencies
    australianDollar: string
    canadianDollar: string
    czechKoruna: string
    danishKrone: string
    honkKongDollar: string
    hungarianForint: string
    icelandicKrona: string
    indianRupee: string
    japaneseYen: string
    omaniRial: string
    newZealandDollar: string
    norwegianKrone: string
    saudiRiyal: string
    singaporeDollar: string
    swedishKrona: string
    swissFranc: string
    thaiBaht: string
    uaeDirham: string
    poundSterling: string
    usaDollar: string
    euro: string
    newRelationship: string
    newRelationshipDetails: string
    existingRelationship: string
    existingRelationshipDetails: string
    selectCompanyMerchantType: (company: string) => string
    selectMerchantType: string
    confirmTheMerchantType: string
    agreeWithTermsDna: string
    confirmationDNARequired: string
}>()
