import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { NewOrder, Page } from '~/code/POS/pages'
import { AppStoreContext } from '~/code/AppStore'
import { NoCompanySelected } from '~/code/common/components'
import translations from '~/code/POS/pages/NewOrder/translations/translations'
import styles from '~/code/POS/pages/NewOrder/NewOrder.scss'

export const NewOrderEmployee = observer(() => {
    const store = useContext(AppStoreContext).newOrderStore

    const onSelectCompanyClick = () => {
        store.selectEmployeeCompany() // TODO should be handled in a different store
    }

    const renderContent = () => {
        if (store.isNoCompanySelected) { // TODO should be handled in a different store
            return <Page>
                <div className={styles.NewOrder}>
                    <NoCompanySelected onSelectCompanyClick={onSelectCompanyClick} noCompanySelectedText={translations().noCompanySelected} />
                </div>
            </Page>
        } else {
            return (
                <NewOrder/>
            )
        }
    }

    return renderContent()
})
