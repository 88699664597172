import React from 'react'
import { observer } from 'mobx-react'
import { Form } from 'antd'
import { Input } from '~/code/common/components'
import { MERCHANT_ID_TYPING_PATTERN } from '~/code/models/Patterns'
import { MIDInputProps } from './props'

export const MIDInput = observer((props: MIDInputProps) => {
    const { form, label, fieldName, required = false, requiredMessage, midValidateStatus, midHelp, setMIDStatus, onBlur } = props

    return  <Form.Item label={label}
                       validateStatus={midValidateStatus}
                       help={midHelp}
                       name={fieldName}
                       getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                           const regExp = MERCHANT_ID_TYPING_PATTERN
                           const value = e.currentTarget.value
                           const test = regExp.test(value)
                           if (!test) {
                               return form.getFieldValue(fieldName)
                           }
                           setMIDStatus && setMIDStatus(value)
                           return value
                       }}
                       rules={[
                           {
                               required,
                               message: requiredMessage
                           }
                       ]}
    >
        <Input placeholder={label} onBlur={onBlur ? onBlur : undefined}/>
    </Form.Item>
})
