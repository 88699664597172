import { PeriodType } from '~/code/models'
import translations from '../translations'

export function getPeriods () {
    return [
        {
            label: translations().today,
            value: PeriodType.Day
        },
        {
            label: translations().yesterday,
            value: PeriodType.Yesterday
        },
        {
            label: translations().thisWeek,
            value: PeriodType.Week
        },
        {
            label: translations().thisMonth,
            value: PeriodType.Month
        },
        {
            label: translations().thisYear,
            value: PeriodType.Year
        }
    ]
}
