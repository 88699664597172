import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { fetchBrandingOrganisations } from '~/code/stores/NewOrderStore/services/fetchers'
import { AppStore } from '~/code/AppStore'

export class BrandingOrganisationsStore {

    constructor () {
        makeObservable(this, {
            _items: observable,
            _isLoading: observable,
            brandingOrganisations: computed,
            load: action
        })
    }


    _items: SelectItem[] = []
    _isLoading: boolean = false

    get brandingOrganisations() {
        this.load()
        return this._items
    }

    async load() {
        if (this._isLoading || !isEmpty(this._items)) { return }
        this._isLoading = true
        const internalIdentifier = AppStore?.userInfo?.userType === 'employee' && AppStore.internalIdentifier ? `?internalIdentifier=${AppStore.internalIdentifierEncoded}` : ''
        this._items = await fetchBrandingOrganisations(internalIdentifier)
        this._isLoading = false
    }

}
