// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1hiKmhFUie4WG2nKSGXQr2 {\n  display: flex;\n  flex-direction: row;\n  cursor: pointer;\n  margin-right: 16px; }\n  ._1bRJe5zpDxPuT-g8haX4wI {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end; }\n  ._1eFSG0RS6Dtd6szeIXrR37 {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 24px;\n    color: #FFFFFF; }\n  ._12JGxUsdYqAfj63uTmgNbm {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 10px;\n    line-height: 10px;\n    color: #00A0DC;\n    background: #FFFFFF;\n    border-radius: 2px;\n    padding: 5px; }\n", ""]);
// Exports
exports.locals = {
	"UserShortInfo": "_1hiKmhFUie4WG2nKSGXQr2",
	"UserShortInfo_userInfoWrapper": "_1bRJe5zpDxPuT-g8haX4wI",
	"UserShortInfo_userFullName": "_1eFSG0RS6Dtd6szeIXrR37",
	"UserShortInfo_userType": "_12JGxUsdYqAfj63uTmgNbm"
};
module.exports = exports;
