import {
    createPenniesFormData,
    IPenniesFormStore,
    PenniesFormData
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/PenniesForm/models'
import { SelectItem } from '~/code/common/components'
import { action, computed, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { OnboardingDetailsStepKey } from '~/code/stores/CheckoutV3Onboarding/models'
import translations from './translations'
import { ICDNUploadResponse } from '~/code/stores/CheckoutV3Onboarding/models/backend/ICDNUploadResponse'
import { ImageUploadStore } from '~/code/stores/CheckoutV3Onboarding/stores/ImageUploadStore/ImageUploadStore'
import { AppStore } from '~/code/AppStore'

export class PenniesFormStore implements IPenniesFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            penniesFormData: observable,
            donationModes: computed,
            merchantCompanyName: computed,
            setPenniesFormData: action,
            goBack: action,
            setImage: action,
            uploadImage: action
        })

        this.penniesFormData = createPenniesFormData()
        this.imageUploadStore = new ImageUploadStore()
    }

    static stepName: OnboardingDetailsStepKey = 'processingDetails'

    penniesFormData: PenniesFormData

    public imageUploadStore: ImageUploadStore

    get donationModes(): SelectItem[] {
        return [
            { label: translations().topUp, value: 'TopUp'},
            { label: translations().roundUp, value: 'RoundUp'}
        ]
    }

    get merchantCompanyName() {
        return AppStore.checkoutV3OnboardingStore?.merchantDetailsFormStore?.merchantDetailsFormData?.companyNameFull
    }

    setPenniesFormData = (data: PenniesFormData) => {
        this.penniesFormData = {...this.penniesFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.penniesFormData, PenniesFormStore.stepName)
    }

    canGoBack: boolean = true

    goBack = () => {
        this.formSubmitHandler.handleGoBack(PenniesFormStore.stepName)
    }

    setImage = (cdnImageData: ICDNUploadResponse) => {
        const { url } = cdnImageData
        this.penniesFormData = { ...this.penniesFormData, logo: url }
    }

    uploadImage = (image: File) => {
        const companyName = this.merchantCompanyName?.replace(' ', '_').toLocaleLowerCase()

        return this.imageUploadStore?.uploadImage(image, companyName).then(res => {
            this.setImage(res)
            return res
        })
    }
}
