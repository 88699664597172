import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { COUNTRIES, MERCHANT_TYPES, ORGANISATION_TYPES } from './index'
import { InlineSelect } from './components'
import { MerchantType } from '../../../models/constants'
import translations from './translations'
import { PlaceOrderConfigurationProps } from './props'
import styles from './PlaceOrderConfiguration.scss'

export const PlaceOrderConfiguration = observer((props: PlaceOrderConfigurationProps) => {
    const { store, merchantTypes, organisationTypes, countries  } = props

    const merchantTypesLocal = merchantTypes || MERCHANT_TYPES
    const organisationTypesLocal = organisationTypes || ORGANISATION_TYPES
    const countriesLocal = countries || COUNTRIES

    return <div className={styles.PlaceOrderConfiguration}>
        {
            store.customContent &&  store.customContent
        }
        {
            !store.customContent &&
            <>
                {translations().orderingFor}
                <InlineSelect
                    selectItems={merchantTypesLocal}
                    value={store.merchantType}
                    shouldAskSelectConfirmation={store.parentStore.shouldShowForm}
                    selectConfirmationTitle={translations().areYouSureToChangeMerchantType}
                    selectConfirmationYesButtonText={translations().yesSureToChangeMerchantType}
                    selectConfirmationNoButtonText={translations().noKeepMerchantType}
                    onSelect={store.onMerchantTypeChange}/>
                {translations().merchant}

                {
                    store.shouldShowOrganisationTypeSelection &&
                    <>
                        {store.organisationType === 'soleTrader' ? translations().organisationTypeSoleTrader : translations().organisationTypeCompany}
                        <InlineSelect
                            selectItems={organisationTypesLocal}
                            value={store.organisationType}
                            shouldAskSelectConfirmation={store.parentStore.shouldShowForm}
                            selectConfirmationTitle={translations().areYouSureToChangeOrganisationType}
                            selectConfirmationYesButtonText={translations().yesSureToChangeOrganisationType}
                            selectConfirmationNoButtonText={translations().noKeepOrganisationType}
                            onSelect={store.onOrganisationTypeChange}/>
                    </>
                }
                {
                    store.shouldShowCountrySelection &&
                    <>
                        {translations().andLocatedIn}
                        <InlineSelect
                            selectItems={countriesLocal}
                            value={store.country}
                            shouldAskSelectConfirmation={store.parentStore.shouldShowForm}
                            selectConfirmationTitle={translations().areYouSureToChangeCountry}
                            selectConfirmationYesButtonText={translations().yesSureToChangeCountry}
                            selectConfirmationNoButtonText={translations().noKeepCountry}
                            onSelect={store.onCountryChange}/>
                    </>
                }
            </>
        }
    </div>
})
