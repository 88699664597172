import React from 'react'
import translations from './translations'

translations.add('en', {
    cancel: 'Cancel',
    ok: 'Ok',
    uploadAppPackage: 'Upload App Package',
    dragAndDropOrClickToUpload: (megabytes) => `Drag and drop package below, or click icon to open file explorer. Max size is ${megabytes}MB`,
    fileSizeRequirements: (megabytes) => `File must smaller than ${megabytes}MB!`
})
