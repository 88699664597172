import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    addDepartment: string
    editDepartment: string

    department: string,
    departmentName: string
    departmentNameRequired: string
    departmentNameAlreadyInUse: string
    streamlineCompanyId: string
    streamlineCompanyIdRequired: string
    streamlineCompanyIdInvalid: string
    streamlineCompanyIdTooltip: string
    merchantCategoryCode: string
    merchantCategoryCodeRequired: string
    merchantCategoryCodeTooltip: string
    departmentType: string
    general: string
    eCommerce: string

    transactionTypes: string
    cashBack: string
    enableCashBack: string
    cashBackRequired: string
    cashBackInvalid: string
    cashBackMaxExceeded: string
    gratuity: string
    enableGratuity: string
    gratuityRequired: string
    refund: string
    enableRefund: string
    refundRequired: string
    purchase: string
    enablePurchase: string
    purchaseRequired: string
    decimalPlacesError: string

    cardSchemeDetails: string
    noCardSchemeSpecified: string
    visaMID: string
    visaMIDRequired: string
    visaDebitMID: string
    visaDebitMIDRequired: string
    accountNumber: string
    accountNumberRequired: string
    accountNumberInvalid: string
    sortCode: string
    sortCodeRequired: string
    sortCodeInvalid: string
    mastercardMID: string
    mastercardMIDRequired: string
    mastercardDebitMID: string
    mastercardDebitMIDRequired: string
    maestroDebitMID: string
    maestroDebitMIDRequired: string
    midNamesWithoutMID: {
        visa: string
        visaDebit: string
        mastercard: string
        mastercardDebit: string
        maestroDebit: string
    }

    acquirer: string
    acquirerRequired: string
    acquirerTooltip: string

    acquirerAssignedTid: string
    acquirerAssignedTidRequired: string

    preferredSubmission: string
    preferredSubmissionRequired: string
    preferredSubmissionTooltip: string
    brandingOrganisation: string
    brandingOrganisationTooltip: string
    accountName: string
    accountNameRequired: string
    accountNameTooltip: string
    currency: string
    currencyRequired: string
    currencyTooltip: string

    merchantId: string
    merchantIdRequired: string
    amexMerchantIdTooltip: string

    americanExpress: string
    dinersDiscover: string
    unionPay: string
    flexecash: string

    invalidWorldPayMIDLength: string
    invalidBarclaycardSterlingMIDLength: string
    invalidGlobalPayMIDLength: string
    invalidAMEXMIDLength: string
    invalidElavonMIDLength: string
    invalidBarclaycardMCMIDLength: string
    invalidOptomanyMIDLength: string
    invalidLloydsCardNetMIDLength: string
    invalidFDMSMIDLength: string
    invalidAIBMIDLength: string
    invalidSwedbankMIDLength: string
    invalidBorgunMIDLength: string

    // verifone
    departmentVerifoneTransactionTypes: string,
    departmentAccountVerification: string
    departmentCashAdvance: string
    departmentMoto: string
    departmentPreAuth: string

    save: string
    add: string
    cancel: string

    preathorization: string
}>()

