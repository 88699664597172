import React, { useEffect, useRef } from 'react'
import { Drawer as AntDrawer } from 'antd'
import { DrawerProps } from './props'
import styles from './Drawer.scss'

export const Drawer = (props: DrawerProps) => {
    const { children, visible, shouldScrollToTopOnVisible, ...rest } = props
    const topElementRef = useRef(null)
    const scrollToTop = () => {
        topElementRef.current.scrollIntoView(true)
    }

    useEffect(
        () => {
            if (topElementRef && topElementRef.current && shouldScrollToTopOnVisible && visible) {
                scrollToTop()
            }
        },
        [visible]
    )

    return <AntDrawer {...rest} visible={visible} className={styles.Drawer}>
        <div ref={topElementRef} className={styles.aboveTop}/>
        {children}
    </AntDrawer>
}
