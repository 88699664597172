import { RowData, FieldData } from '~/code/common/components'
import { isEmpty } from 'dna-common'

export function removeEmptyFieldsFromRowData(rows: RowData[]) {
    const resultRows: RowData[] = []
    let fields = []

    for (const row of rows) {
        for (const field of row.fields) {
            if (!isEmpty((field as FieldData).value)) {
                fields.push(field)
                if (fields.length === 2) {
                    resultRows.push({fields})
                    fields = []
                }
            }
        }
    }
    // push the last row id there is only 1 item
    if (fields.length === 1) {
        resultRows.push({fields})
    }

    return resultRows
}
