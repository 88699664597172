import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import { Dropdown, Menu, Popconfirm } from 'antd'
import { LinkLabel } from '~/code/common/components'
import translations from './translations'
import { InlineSelectProps } from './props'
import styles from './InlineSelect.scss'

export const InlineSelect = observer((props: InlineSelectProps) => {
    const { selectItems, defaultValue, value,
        shouldAskSelectConfirmation,
        selectConfirmationTitle,
        selectConfirmationYesButtonText,
        selectConfirmationNoButtonText, onSelect
    } = props
    const [isDropDownVisible, setDropdownVisible] = useState(false)
    const [isPopConfirmVisible, setPopConfirmVisible] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultValue ? selectItems.find(item => item.value === defaultValue) : null)
    const tempSelectedValue = useRef(null) // to store synchronously
    const tempIsPopConfirmVisible = useRef(false) // to store synchronously
    const popConfirmRef = useRef(null)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const documentClick = (e: MouseEvent) => {
            const target = e.target as Element
            const popConfirmElement = ReactDOM.findDOMNode(popConfirmRef.current) as Element
            const dropdownRefElement = ReactDOM.findDOMNode(dropdownRef.current) as Element

            // here we use ref as useState value does not get updated inside useEffect
            if (!popConfirmElement.contains(target) && !dropdownRefElement?.contains(target) && tempIsPopConfirmVisible.current) {
                setPopConfirmVisible(false)
                tempIsPopConfirmVisible.current = false
            }
        }
        document.addEventListener('click', documentClick)
        return () => document.removeEventListener('click', documentClick)
    }, [popConfirmRef, dropdownRef])

    const renderOptions = () => {
        const selectedKeys = value ? [value] : (selectedOption ? [selectedOption.value] : null)
        return (
            <Menu ref={dropdownRef}
                  selectedKeys={selectedKeys}
                  onClick={param => handleMenuClick(param)}>
                {selectItems.map(item => <Menu.Item key={item.value}>{item.label}</Menu.Item>)}
            </Menu>
        )
    }

    const handleMenuClick = (param) => {
        if (param.key === value) {
            setDropdownVisible(false)
            return
        }
        tempSelectedValue.current = param.key
        if (shouldAskSelectConfirmation) {
            setPopConfirmVisible(true)
            tempIsPopConfirmVisible.current = true
            return
        }
        handleConfirmSelect()
    }

    const handleConfirmSelect = () => {
        const val = tempSelectedValue.current
        setSelectedOption(selectItems.find(item => item.value === val))
        onSelect && onSelect(val)
        setDropdownVisible(false)
        setPopConfirmVisible(false)
        tempIsPopConfirmVisible.current = false
    }

    const handleCancelSelect = () => {
        setPopConfirmVisible(false)
        setDropdownVisible(false)
        tempIsPopConfirmVisible.current = false
    }

    const renderValue = () => {
        if (value) {
            return selectItems.find(item => item.value === value)?.label
        }

        return selectedOption ? selectedOption.label : null
    }

    return <div className={styles.InlineSelect}>
        <Dropdown
            placement={'bottomLeft'}
            trigger={['click']}
            onVisibleChange={setDropdownVisible}
            visible={isDropDownVisible}
            overlay={() => renderOptions()}>
            <Popconfirm
                ref={popConfirmRef}
                placement={'topRight'}
                title={selectConfirmationTitle || translations().popConfirmTitle}
                onConfirm={handleConfirmSelect}
                onCancel={handleCancelSelect}
                visible={isPopConfirmVisible}
                okText={selectConfirmationYesButtonText || translations().popConfirmYesButton}
                cancelText={selectConfirmationNoButtonText || translations().popConfirmNoButton}
            >
                <LinkLabel onClick={() => setDropdownVisible(true)}>
                    { renderValue() }
                </LinkLabel>
            </Popconfirm>
        </Dropdown>
    </div>
})
