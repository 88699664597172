import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Spin, Form } from 'antd'
import { LabelWithTooltip } from '~/code/common/components/LabelWithTooltip/LabelWithTooltip'
import { Select, Checkbox, GroupTitle, Input, MIDInput, CountrySelect } from '~/code/common/components'
import styles from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/AddDepartmentForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import translations from './translations'
import { TerminalSettingsFormProps } from './props'
import { AVS_OR_CSC_MATRIX_MESSAGE, PA_MATRIX_MESSAGE } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/models/constants'
import { ValidateStatus } from 'antd/es/form/FormItem'
import { VALID_URL_PATTERN } from '~/code/models'

const getFormInitialValues = data => {
    return {
        defaultTransactionType: data.defaultTransactionType,
        optomanyPlatform: {
            acquirerId: data.optomanyPlatform?.acquirerId,
            paymentChannelId: data.optomanyPlatform?.paymentChannelId,
            cardAcceptorNumber: data.optomanyPlatform?.cardAcceptorNumber,
            merchantName: data.optomanyPlatform?.merchantName,
            merchantUrl: data.optomanyPlatform?.merchantUrl,
            visaMid: data.optomanyPlatform?.visaMid,
            mastercardMid: data.optomanyPlatform?.mastercardMid,
            amexMid: data.optomanyPlatform?.amexMid,
            unionPayMid: data.optomanyPlatform?.unionPayMid,
            avsHouseMatrix: data.optomanyPlatform?.avsHouseMatrix,
            avsPostCodeMatrix: data.optomanyPlatform?.avsPostCodeMatrix,
            cscMatrix: data.optomanyPlatform?.cscMatrix,
            paMatrix: data.optomanyPlatform?.paMatrix,
            enableAvsForNonUKCustomers: data.optomanyPlatform?.enableAvsForNonUKCustomers,
            countryId: data.optomanyPlatform?.countryId,
            terminalDeviceInfo: {
                attributes: data.optomanyPlatform?.terminalDeviceInfo?.attributes,
                capabilities: data.optomanyPlatform?.terminalDeviceInfo?.capabilities,
                terminalType: data.optomanyPlatform?.terminalDeviceInfo?.terminalType,
                attributesUsed: data.optomanyPlatform?.terminalDeviceInfo?.attributesUsed,
                poiCapabilities: data.optomanyPlatform?.terminalDeviceInfo?.poiCapabilities,
                capabilitiesUsed: data.optomanyPlatform?.terminalDeviceInfo?. capabilities
            }
        }

    }
}

export const TerminalSettingsForm = observer( ({ store }: TerminalSettingsFormProps) => {
    const {
        terminalSettingsFormData: data,
        setTerminalSettingsFormData,
        acquirers,
        defaultTransactionTypes,
        countries,
        canGoBack,
        goBack
    } = store

    const [avsHouseMatrix, setAvsHouseMatrix] = useState<number>(data.optomanyPlatform?.avsHouseMatrix)
    const [avsPostcodeMatrix, setAvsPostcodeMatrix] = useState<number>(data.optomanyPlatform?.avsPostCodeMatrix)
    const [cscMatrix, setCscMatrix] = useState<number>(data.optomanyPlatform?.cscMatrix)
    const [paMatrix, setPaMatrix] = useState<number>(data.optomanyPlatform?.paMatrix)

    const [form] = Form.useForm()

    const onFinish = (values) => {
        values.acquirerName = acquirers.find(item => item.value === values.acquirer)?.label
        setTerminalSettingsFormData(values)
    }

    useEffect(() => {
        form.setFieldsValue(getFormInitialValues(data))
    }, [form, data])

    const formInitialValues = getFormInitialValues(data)

    const onMatrixValueChange: (e, callBack) => void = (e, callBack) => {
        const value = e.target.value
        callBack(value)
    }

    const avsHouseMatrixHelpMessage: string = AVS_OR_CSC_MATRIX_MESSAGE[avsHouseMatrix]
    const avsPostcodeMatrixHelpMessage: string = AVS_OR_CSC_MATRIX_MESSAGE[avsPostcodeMatrix]
    const cscMatrixHelpMessage: string = AVS_OR_CSC_MATRIX_MESSAGE[cscMatrix]
    const paMatrixHelpMessage: string = PA_MATRIX_MESSAGE[paMatrix]

    const avsHouseMatrixStatus: ValidateStatus = avsHouseMatrix ? avsHouseMatrixHelpMessage ? 'warning' : '' : undefined
    const avsPostcodeMatrixStatus: ValidateStatus = avsPostcodeMatrix ? avsPostcodeMatrixHelpMessage ? 'warning' : '' : undefined
    const cscMatrixStatus: ValidateStatus = cscMatrix ? cscMatrixHelpMessage ? 'warning' : '' : undefined
    const paMatrixStatus: ValidateStatus = paMatrix ? paMatrixHelpMessage ? 'warning' : '' : undefined

    return (
        <div>
            <Form layout={'vertical'} form={form} onFinish={onFinish} className={styles.AddDepartmentForm}
                  scrollToFirstError={{ behavior: actions => {
                          actions.forEach(({ el, top }) => {
                              el.scrollTop = top - 50
                          })
                      }}}
                  initialValues={formInitialValues}
            >
                {/* GENERAL SETTINGS BLOCK */}
                <GroupTitle size={'small'} content={translations().generalSettings} />
                <Row justify={'space-between'}>
                    <Col xs={24} sm={10}>
                        <Form.Item
                            label={ translations().defaultTransactionType}
                            name={'defaultTransactionType'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().defaultTransactionTypeRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().defaultTransactionType}
                                    showSearch={false}
                                    selectItems={
                                        defaultTransactionTypes?.slice()
                                    }/>
                        </Form.Item>
                        <Form.Item
                            label={ <LabelWithTooltip label={translations().acquirer} tooltipText={translations().acquirerTooltip} tooltipPosition={'right'} />}
                            name={['optomanyPlatform', 'acquirerId']}
                            rules={[
                                {
                                    required: true,
                                    message: translations().acquirerRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().acquirer}
                                    notFoundContent={<Spin size={'small'}/>}
                                    selectItems={acquirers?.slice()}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().paymentChannelId}
                            name={['optomanyPlatform', 'paymentChannelId']}
                            rules={[
                                {
                                    max: 10,
                                    message: translations().max10digits
                                }
                            ]}
                        >
                            <Input type={'number'} placeholder={translations().paymentChannelId}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().cardAcceptorNumber}
                            name={['optomanyPlatform', 'cardAcceptorNumber']}
                            rules={[
                                {
                                    max: 25,
                                    message: translations().max25digits
                                }
                            ]}
                        >
                            <Input type={'number'} placeholder={translations().cardAcceptorNumber}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().merchantName}
                            name={['optomanyPlatform', 'merchantName']}
                            rules={[
                                {
                                    required: true,
                                    message: translations().merchantNameRequired
                                },
                                {
                                    max: 25,
                                    message: translations().max25Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().merchantName}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().merchantUrl}
                            name={['optomanyPlatform', 'merchantUrl']}
                            rules={[
                                {
                                    required: true,
                                    message: translations().merchantUrlRequired
                                },
                                {
                                    pattern: VALID_URL_PATTERN,
                                    message: translations().invalidUrl
                                }
                            ]}
                        >
                            <Input placeholder={translations().merchantUrl}/>
                        </Form.Item>
                        <CountrySelect
                            form={form}
                            fieldName={['optomanyPlatform', 'countryId']}
                            userCountry={'uk'}
                            countries={countries.slice()}
                            required={true}
                        />
                        <Form.Item
                            name={['optomanyPlatform', 'enableAvsForNonUKCustomers']}
                            valuePropName={'checked'}
                            rules={[
                                {
                                    transform: checked => (checked || undefined),
                                    type: 'boolean'
                                }
                            ]}
                        >
                            <Checkbox>
                                {translations().enableAvsForNonUKCustomers}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={10}>
                        <MIDInput label={translations().visaMid}
                                  required={true}
                                  requiredMessage={translations().visaMidRequired}
                                  form={form}
                                  fieldName={['optomanyPlatform', 'visaMid']}/>
                        <MIDInput label={translations().mastercardMid}
                                  required={true}
                                  requiredMessage={translations().mastercardMidRequired}
                                  form={form}
                                  fieldName={['optomanyPlatform', 'mastercardMid']}/>
                        <MIDInput label={translations().amexMid}
                                  required={false}
                                  form={form}
                                  fieldName={['optomanyPlatform', 'amexMid']}/>
                        <MIDInput label={translations().unionPayMid}
                                  required={false}
                                  form={form}
                                  fieldName={['optomanyPlatform', 'unionPayMid']}/>
                        <Form.Item
                            label={translations().avsHouseMatrix}
                            help={avsHouseMatrixHelpMessage}
                            name={['optomanyPlatform', 'avsHouseMatrix']}
                            validateStatus={avsHouseMatrixStatus}
                            rules={[
                                {
                                    required: true,
                                    message: translations().avsHouseMatrixRequired
                                },
                                {
                                    max: 2,
                                    message: translations().max2digits
                                }
                            ]}
                        >
                            <Input
                                onChange={(e) => onMatrixValueChange(e, setAvsHouseMatrix)}
                                placeholder={translations().avsHouseMatrix}
                            />
                        </Form.Item>
                        <Form.Item
                            label={translations().avsPostcodeMatrix}
                            help={avsPostcodeMatrixHelpMessage}
                            validateStatus={avsPostcodeMatrixStatus}
                            name={['optomanyPlatform', 'avsPostCodeMatrix']}
                            rules={[
                                {
                                    required: true,
                                    message: translations().avsPostcodeMatrixRequired
                                },
                                {
                                    max: 2,
                                    message: translations().max2digits
                                }
                            ]}
                        >
                            <Input
                                onChange={(e) => onMatrixValueChange(e, setAvsPostcodeMatrix)}
                                placeholder={translations().avsPostcodeMatrix}
                            />
                        </Form.Item>
                        <Form.Item
                            label={translations().cscMatrix}
                            help={cscMatrixHelpMessage}
                            validateStatus={cscMatrixStatus}
                            name={['optomanyPlatform', 'cscMatrix']}
                            rules={[
                                {
                                    required: true,
                                    message: translations().cscMatrixRequired
                                },
                                {
                                    max: 2,
                                    message: translations().max2digits
                                }
                            ]}
                        >
                            <Input
                                onChange={(e) => onMatrixValueChange(e, setCscMatrix)}
                                placeholder={translations().cscMatrix}
                            />
                        </Form.Item>
                        <Form.Item
                            label={translations().paMatrix}
                            name={['optomanyPlatform', 'paMatrix']}
                            help={paMatrixHelpMessage}
                            validateStatus={paMatrixStatus}
                            rules={[
                                {
                                    required: true,
                                    message: translations().paMatrixRequired
                                },
                                {
                                    max: 2,
                                    message: translations().max2digits
                                }
                            ]}
                        >
                            <Input
                                onChange={(e) => onMatrixValueChange(e, setPaMatrix)}
                                placeholder={translations().paMatrix}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* TERMINAL DEVICE INFO BLOCK */}
                <GroupTitle size={'small'} content={translations().terminalDeviceInfo} />
                <Row justify={'space-between'}>
                    <Col xs={24} sm={10}>
                        <Form.Item
                            label={translations().attributes}
                            name={['optomanyPlatform', 'terminalDeviceInfo', 'attributes']}
                            rules={[
                                {
                                    max: 20,
                                    message: translations().max20Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().attributes}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().capabilities}
                            name={['optomanyPlatform', 'terminalDeviceInfo', 'capabilities']}
                            rules={[
                                {
                                    max: 20,
                                    message: translations().max20Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().capabilities}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().terminalType}
                            name={['optomanyPlatform', 'terminalDeviceInfo', 'terminalType']}
                            rules={[
                                {
                                    max: 20,
                                    message: translations().max20Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().terminalType}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={10}>
                        <Form.Item
                            label={translations().attributesUsed}
                            name={['optomanyPlatform', 'terminalDeviceInfo', 'attributesUsed']}
                            rules={[
                                {
                                    max: 20,
                                    message: translations().max20Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().attributesUsed}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().poiCapabilities}
                            name={['optomanyPlatform', 'terminalDeviceInfo', 'poiCapabilities']}
                            rules={[
                                {
                                    max: 20,
                                    message: translations().max20Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().poiCapabilities}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().capabilitiesUsed}
                            name={['optomanyPlatform', 'terminalDeviceInfo', 'capabilitiesUsed']}
                            rules={[
                                {
                                    max: 20,
                                    message: translations().max20Chars
                                }
                            ]}
                        >
                            <Input placeholder={translations().capabilitiesUsed}/>
                        </Form.Item>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )

})
