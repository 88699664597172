import React, { useEffect, useState } from 'react'
import { Col, Popover, Row } from 'antd'
import { SketchPicker } from 'react-color'
import { DEFAULT_COLOUR } from '../../models'
import { Input } from '~/code/common/components'
import translations from './translations'
import { ColourPickerProps } from './props'
import styles from './ColourPicker.scss'

export const ColourPicker = ({value, onChange, inputPlaceholder}: ColourPickerProps) => {
    const [colour, setColour] = useState(value || DEFAULT_COLOUR)

    useEffect(() => {
       setColour(value)
    }, [value])

    const handleChangeComplete = (val, event) => {
        handleChange(val.hex)
    }

    const handleChange = (hex) => {
        setColour(hex)
        onChange && onChange(hex)
    }

    const handleInputChange = (event) => {
        handleChange(event.target.value)
    }

    const renderSketchPicker = () => {
        return <SketchPicker
            color={ colour }
            onChangeComplete={ handleChangeComplete }
            onChange={ handleChangeComplete }
        />
    }

    return (
        <div className={styles.ColourPicker}>
            <Popover
                overlayClassName={styles.popover}
                content={renderSketchPicker()}
                trigger={'click'}
            >
                <div className={styles.trigger} style={{backgroundColor: colour}}/>
            </Popover>
            <div className={styles.input}>
                <Input value={colour} onChange={handleInputChange} placeholder={inputPlaceholder || translations().hexValue}/>
            </div>
        </div>
    )
}
