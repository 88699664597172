import React from 'react'
import translations from './translations'

translations.add('en', {
    newPackageDetails: 'New Package Details',
    displayName: 'Name',
    packageNameRequired: 'Name Required',
    packageDeveloper: 'Developer',
    packageDeveloperRequired: 'New Developer Name Required',
    packageVersionNumber: 'Version Number',
    packageVersionNumberRequired: 'Version Number required',
    packageVersionNumberInvalid: 'Version Number is invalid',
    cancel: 'Cancel',
    uploadPackage: 'Upload Package',
    packageVersionRegex: 'Package Version Regex',
    packageVersionRegexRequired: 'Package Version Regex required',
    pleaseSelectDeveloper: 'Please Select Developer',
    packageModels: 'Package Models',
    packageModelsRequired: 'Package Models Required',
    documentationUrl: 'Documentation URL',
    packageName: 'Package Name',
    appNameRequired: 'Package Name Required',
    max500Length: 'Max 500 character length',
    invalidUrl: 'Documentation URL invalid',
    whichDeviceManufacturerSignedAPK: 'Which device manufacturer signed the APK?',
    deviceManufacturer: 'Device Manufacturer',
    deviceManufacturerRequired: 'Device Manufacturer Is Required',
    addNewDeveloper: 'Add New Developer?',
    packageCreatedSuccessfully: 'Package Created Successfully'
})
