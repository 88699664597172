import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { error, isEmpty } from 'dna-common'
import { notification } from 'antd'
import { v4 as uuid } from 'uuid'
import { IAppStore } from '~/code/IAppStore'
import { IAllApplicationsStore } from '~/code/ODIN/components/AllApplications/IAllApplicationsStore'
import { Application } from '~/code/ODIN/stores/ApplicationManagementStore/models/Application'
import { fetchAllApps } from '~/code/ODIN/stores/ApplicationManagementStore/services/fetchers'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { PAGE_SIZE } from '~/code/models'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import { IAppDeveloperStore } from '~/code/ODIN/pages/ApplicationManagement/IAppDeveloperStore'
import { AppDeveloperStore } from '~/code/ODIN/stores/ApplicationManagementStore/AppDeveloperStore'
import commonTranslations from '~/code/translations/translations'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class AllApplicationsStore implements IAllApplicationsStore {
    constructor (parentStore: IAppStore, type: PackageType) {
        makeObservable(this, {
            isLoading: observable,
            _allApps: observable,
            pageNumber: observable,
            pageSize: observable,
            totalCount: observable,
            totalPageItems: observable,
            packageTypeId: observable,
            options: observable,
            queryParams: computed,
            allApps: computed,
            loadAllApps: action
        })

        this.parentStore = parentStore
        this.appDeveloperStore = new AppDeveloperStore()
        this.packageTypeId = type
    }

    appDeveloperStore: IAppDeveloperStore
    parentStore: IAppStore
    _allApps: Application[] = []
    isLoading: boolean = false
    pageNumber: number = 0
    pageSize: number = PAGE_SIZE
    totalCount: number = 0
    totalPageItems: number = 0
    packageTypeId: number = null
    public options = {
        name: '',
        developer: ''
    }

    public get allApps(): Application[] {
        return this._allApps
    }

    public get queryParams(): { [key: string]: string | number } {
        return {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            packageTypeId: this.packageTypeId,
            ...this.options
        }
    }

    public loadAllApps = (pageNumber?) => {
        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber

        this.isLoading = true

        fetchAllApps(this.queryParams)
            .then(response => {
                    runInAction(() => {
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this._allApps = response?.result?.length > 0 ? response?.result?.map(item => ({...item, key: uuid()})) : []
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    public setOptions = (options) => {
        this.options = options

        this.loadAllApps(1)
    }
}
