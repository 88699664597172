import React from 'react'
import { Form } from 'antd'
import styles from './EntityDetails.scss'

interface EntityDetailsItem {
    label: string
    value: any
}

interface EntityDetailsData {
    [key: string]: EntityDetailsItem
}

interface EntityDetailsProps {
    data: EntityDetailsData
}

export const EntityDetails = ({data}: EntityDetailsProps) => {
    const renderContent = () => {
        return Object.keys(data).map((key, index) => {
            return <Form.Item
                key={`${key}-${index}`}
                label={data[key].label}
                name={'storePostcode'}
            >
                <div className={styles.label}>{data[key].value}</div>
            </Form.Item>
        })
    }

    return (
        <div>
            { renderContent() }
        </div>
    )
}
