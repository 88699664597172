export class DispatchReportModel {
    refereeName: string
    merchantStoreName: string
    serialNumber: string
    tamperBagNo: string
    simNo: string
    mid: string
    tid: string
    dispatchedDateTime: string
    consignmentNo: string
    productCode: string
    name: string
    orderReference: string
}
