import translations from './translations'

translations.add('en', {
    dispatchReport: 'Dispatch Report',
    refereeName: 'Referee name',
    merchantStoreName: 'Merchant store name',
    serialNumber: 'Serial number',
    tamperBagNo: 'Tamper bag number',
    simNo: 'Sim number',
    mid: 'MID',
    tid: 'TID',
    dispatchedDateTime: 'Dispatched date',
    consignmentNo: 'Consignment number',
    productCode: 'Product code',
    name: 'Product name',
    orderReference: 'Order reference',
    noReference: 'No reference'
})
