import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    loginPageTitle: string
    placeOrderPageTitle: string
    orderHistoryPageTitle: string
    orderDetailsPageTitle: string
    userGuidePageTitle: string
    checkoutV3OnboardingPageTitle: string
    deviceManagementPageTitle: string
    deviceManagementDetailsPageTitle: string
    applicationManagementPageTitle: string
    applicationManagementDetailsPageTitle: string
    firmwareManagementPageTitle: string
    firmwareManagementDetailsPageTitle: string
    merchantSchedulePageTitle: string
    merchantSelectionPageTitle: string
    notFoundPageTitle: string

    invalidWorldPayMIDLength: string
    invalidBarclaycardSterlingMIDLength: string
    invalidGlobalPayMIDLength: string
    invalidAMEXMIDLength: string
    invalidElavonMIDLength: string
    invalidBarclaycardMCMIDLength: string
    invalidOptomanyMIDLength: string
    invalidLloydsCardNetMIDLength: string
    invalidFDMSMIDLength: string
    invalidAIBMIDLength: string
    invalidSwedbankMIDLength: string
    invalidBorgunMIDLength: string
    invalidDNAPaymentsMIDLength: string

    invalidDNAPaymentsTIDLength: string
    invalidDNAPaymentsEComTIDLength: string
    invalidDNAPaymentsEComTIDFormat: string
    errors: {
        unfortunatelyWeCantProcessYourRequest: (traceId) => string
        unableToRetrieveRequestedDate: (traceId) => string
        yourSessionHasExpired: string
    }
}>()

