export const OrderStatus = {
    1: 'orderSubmissionFailed',
    2: 'orderSubmitted',
    3: 'validationCompleted',
    4: 'validationFailed',
    5: 'orderFailed',
    6: 'orderSubmitted',
    7: 'dispatching',
    8: 'completed',
    10: 'orderFailed',
    12: 'orderFailed',
    13: 'reversed',
    14: 'cancellationRequested',
    15: 'cancelled'
}
