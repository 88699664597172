import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { PartnerStore } from '~/code/POS/pages/NewOrder/models/partner/PartnerStore'
import { PartnerDepartment } from '~/code/POS/pages/NewOrder/models/partner/PartnerDepartment'
import { isEmpty } from 'dna-common'

export const ROI_BUSINESS_PREFIX = 'BU'
export const ROI_COMPANY_PREFIX = 'CO'

export function createValidCompanyNumber (configCountry, companyInfo, userEnteredCompanyNumber) {
    if (configCountry === 'roi') {
        let companyNumber = ''
        if (companyInfo?.company_bus_ind?.toUpperCase() === 'B') {
            companyNumber = ROI_BUSINESS_PREFIX
        } else if (companyInfo?.company_bus_ind?.toUpperCase() === 'C') {
            companyNumber = ROI_COMPANY_PREFIX
        } else {
            companyNumber = '00'
        }

        const companyNum = (companyInfo?.company_num || userEnteredCompanyNumber || '') + ''
        let zeroCount = 0
        while (zeroCount < 6 - companyNum.length) {
            companyNumber = companyNumber + '0'
            zeroCount++
        }
        companyNumber = companyNumber + companyNum

        return companyNumber.length > 2 ? companyNumber : ''
    }

    return companyInfo?.company_number || null
}

export function removeRedundantCharsFromCompanyNum(companyNumber: string, configCountry) {
    let resultCompanyNum = companyNumber
    if (configCountry === 'roi') {
        if (companyNumber?.startsWith(ROI_BUSINESS_PREFIX) || companyNumber?.startsWith(ROI_COMPANY_PREFIX)) {
            resultCompanyNum = resultCompanyNum.slice(2)
        }

        while (resultCompanyNum.startsWith('0')) {
            resultCompanyNum = resultCompanyNum.slice(1)
        }
    }

    return resultCompanyNum
}

export function setStoreIdsToOrderRequest<T>(order: T): T {
    const formattedOrder = _.cloneDeep(order)
    const { merchant: { stores = [] } = {} } = formattedOrder

    stores.forEach(store => {
        store.id = store?.existingStoreId || uuid()
    })

    return {
        ...formattedOrder
    }
}

export function setDepartmentIdsToOrderRequest<T>(order: T): T {
    const formattedOrder = _.cloneDeep(order)
    const { merchant: { stores = [] } = {} } = formattedOrder

    stores.forEach(store => {
        store.departments.forEach(department => {
            department.id = department?.existingDepartmentId || uuid()
            department.storeId = store?.id
        })
    })

    return {
        ...formattedOrder
    }
}

export function getExistingStoreIds(stores: PartnerStore[]): number[] {
    return stores
        ?.map(store => store.existingStoreId)
        .filter(id => !isEmpty(id))
}

export function getExistingDepartmentIds(stores: PartnerStore[]): number[] {
    return stores
        ?.reduce((curr, acc) => [...acc.departments], [])
        .map(department => department.existingDepartmentId)
        .filter(id => !isEmpty(id))
}

export function getAllStoreDepartments(stores: PartnerStore[]): PartnerDepartment[] {
    return stores?.reduce((curr, acc) => [...acc.departments], [])
}

export function removeCompanyNumberFromMerchantName(merchantName: string) {
    // merchantName contains company number and name
    // example: (11233005) 123SEND DNA TEST
    // below we are removing company number
    const arr = merchantName.split(' ')
    arr.shift()
    return arr.join(' ')
}
