export interface ProductsFormData {
    bundleCode: string
    quantity: number
}

export function createProductsFormData(): ProductsFormData {
    return {
        bundleCode : '',
        quantity : 0
    }
}
