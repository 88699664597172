// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3aDho2YQpTz9I7AjhYcQxU {\n  background: #FFFFFF;\n  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 4px;\n  margin-bottom: 16px;\n  padding: 16px; }\n  ._3aDho2YQpTz9I7AjhYcQxU ._29BnYSx6lAgBWP4CRV3Rlb {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 12px;\n    line-height: 16px;\n    color: #8C8C8C; }\n  ._3aDho2YQpTz9I7AjhYcQxU ._375MHcNt_t8UXZQ0Pgl7O8 {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 16px;\n    color: #000000; }\n  ._3aDho2YQpTz9I7AjhYcQxU ._2Xn1NVOLqOYK2DCnFtK918 {\n    word-break: break-word;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 16px;\n    color: #8C8C8C; }\n    ._3aDho2YQpTz9I7AjhYcQxU ._16wI0EjgJ42eNFIgZLtGih {\n      margin-top: 10px; }\n  ._3aDho2YQpTz9I7AjhYcQxU ._1kfPga6NGL_hOc7_9ndK0W {\n    padding-bottom: 16px; }\n  ._3aDho2YQpTz9I7AjhYcQxU ._1K9_iWqTLVmZg9Fp8wOTBn {\n    padding: 16px 0px 0px 0px;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end; }\n    ._3aDho2YQpTz9I7AjhYcQxU ._1K9_iWqTLVmZg9Fp8wOTBn .SnnuUsfjBaPMm_pUfoXxr {\n      font-family: Poppins;\n      font-style: normal;\n      font-weight: bold;\n      font-size: 14px;\n      line-height: 21px; }\n      ._3aDho2YQpTz9I7AjhYcQxU ._1K9_iWqTLVmZg9Fp8wOTBn .SnnuUsfjBaPMm_pUfoXxr:hover {\n        cursor: pointer; }\n    ._3aDho2YQpTz9I7AjhYcQxU ._1K9_iWqTLVmZg9Fp8wOTBn .M0QNKBAD-w2CCltx9F3Pe {\n      padding-right: 10px; }\n    ._3aDho2YQpTz9I7AjhYcQxU ._1K9_iWqTLVmZg9Fp8wOTBn ._3A5UmS3RUp8ESUnIK-3M3h {\n      color: #D23153; }\n", ""]);
// Exports
exports.locals = {
	"ProductListItem": "_3aDho2YQpTz9I7AjhYcQxU",
	"header": "_29BnYSx6lAgBWP4CRV3Rlb",
	"title": "_375MHcNt_t8UXZQ0Pgl7O8",
	"subtitle": "_2Xn1NVOLqOYK2DCnFtK918",
	"subtitle_secondary": "_16wI0EjgJ42eNFIgZLtGih",
	"bundleWrapper": "_1kfPga6NGL_hOc7_9ndK0W",
	"buttonsWrapper": "_1K9_iWqTLVmZg9Fp8wOTBn",
	"button": "SnnuUsfjBaPMm_pUfoXxr",
	"editButton": "M0QNKBAD-w2CCltx9F3Pe",
	"deleteButton": "_3A5UmS3RUp8ESUnIK-3M3h"
};
module.exports = exports;
