// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zeiwSsOnVvCj8K0we4bG6 {\n  margin-bottom: 8px; }\n  .zeiwSsOnVvCj8K0we4bG6 .ant-card-body {\n    padding: 8px; }\n\n._17353_NtbHEfZJ6chYoTx4 {\n  margin-right: 8px; }\n\n._1KYG1yWgHnLZo3n6hjSnI6 {\n  display: flex;\n  justify-content: center; }\n\n._1Bv0u-4ekPvrsYgu3i3K1f {\n  margin: 16px 0; }\n", ""]);
// Exports
exports.locals = {
	"body": "zeiwSsOnVvCj8K0we4bG6",
	"backButton": "_17353_NtbHEfZJ6chYoTx4",
	"noContent": "_1KYG1yWgHnLZo3n6hjSnI6",
	"listActions": "_1Bv0u-4ekPvrsYgu3i3K1f"
};
module.exports = exports;
