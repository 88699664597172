import React, { useEffect, useState } from 'react'
import { message, Modal, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import translations from './translations'
import { ImageUploadWithPreviewProps } from './props'
import styles from './ImageUploadWithPreview.scss'
import { removeImageFromCDN } from '~/code/stores/CheckoutV3Onboarding/services/fetchers'
import { error, log } from 'dna-common'
import classNames from 'classnames'

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
        message.error(translations().imageFormatRequirements)
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error(translations().imageSizeRequirements)
    }
    return isJpgOrPng && isLt2M
}

function getBase64(img, callback?) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

export const ImageUploadWithPreview = ({value, uploadFile, onChange, action, className, withModalImagePreview, name = 'image-upload'}: ImageUploadWithPreviewProps) => {
    const [loading, setLoading] = useState(false)
    const [previewVisible, setPreviewVisible] = useState(false)
    const [imageURL, setImageURL] = useState(value || '')
    const [imagePath, setImagePath] = useState(value || '')

    const handlePreview = file => {
        if (!file.url && !file.preview) {
            getBase64(file.originFileObj, (result) => {
                setImageURL(result)
                file.preview = result
            })
        }

        setPreviewVisible(true)
    }

    const handleCancel = () => {
        setPreviewVisible(false)
    }

    const handleChange = async (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return
        }
        if (info.file.status === 'done') {
            setLoading(false)
        }
        if (info.file.status === 'error') {
            message.error(translations().failedToUpload)
            setLoading(false)
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{translations().upload}</div>
        </div>
    )

    const removePreviousImage = async () => {
        try {
            if (imagePath) {
                await removeImageFromCDN(imagePath)
            }
        } catch (e) {
            error(e)
        }
    }

    const uploadImage = async options => {
        const { onSuccess, onError, file } = options

        try {
            await removePreviousImage()
            const res = await uploadFile(file, name)

            setImagePath(res.path)
            onChange && onChange(res.url)
            fetchImage(res.path)
            onSuccess('Ok')
        } catch (err) {
            onError({ err })
        }
    }

    const fetchImage = async (imageUrl) => {
        return new Promise(async (resolve, reject) => {
            const xhr = new XMLHttpRequest()

            xhr.open('get', `/dna-cdn${imageUrl}`)
            xhr.responseType = 'blob'
            xhr.onload = async () => {
                log(xhr.response)
                getBase64(xhr.response, (result) => {
                    setImageURL(result)
                })
                resolve(xhr.response)
            }
            xhr.onerror = () => {
                reject(xhr.response)
            }
            xhr.send()
        })
    }

    return (
        <>
            <Upload
                name={name}
                listType={'picture-card'}
                className={classNames(styles.uploadWithPreview, className)}
                showUploadList={false}
                action={action}
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={uploadImage}
            >
                {!withModalImagePreview && imageURL ? <img src={imageURL} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
            {withModalImagePreview && (
                <Modal
                    visible={previewVisible}
                    title={'previewTitle'}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={imageURL} />
                </Modal>
            )}
        </>
    )
}
