import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Typography, Row, Col, Checkbox, TimePicker } from 'antd'
import { Button } from '~/code/common/components'
import translations from '~/code/ODIN/pages/MerchantSchedule/translations'
import { getDisabledHours, getDisabledMinutes } from '~/code/ODIN/services/utils'
import { MaintenanceScheduleProps } from './props'
import { LoadingView } from '~/code/POS/pages'

const { Title, Text } = Typography

const format = 'HH:mm'

enum Weekdays {
    monday = 'MON',
    tuesday = 'TUE',
    wednesday = 'WED',
    thursday = 'THU',
    friday = 'FRI',
    saturday = 'SAT',
    sunday = 'SUN'
}

export const MaintenanceSchedule = observer((props: MaintenanceScheduleProps) => {
    const { store } = props

    useEffect(() => {
        store.loadConfiguration()
    }, [])

    function handleMaintenanceDaysChange(checkedValues) {
        store.setDays(checkedValues)
    }

    function renderWeeksCheckBox() {
        return(
            <Checkbox.Group value={store.days} onChange={handleMaintenanceDaysChange}>
                <Row>
                    {(Object.keys(Weekdays)).map((day, index) => (
                        <Col span={24} key={index}>
                            <Checkbox
                                value={Weekdays[day]}
                                disabled={!store.permissions?.includes('odin.merchantSchedules.maintenanceSchedule.update')}
                            >
                                {translations()[day]}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
        )
    }

    if (store.isLoading) {
        return <div>
            <LoadingView status={'loading'} message={translations().loading}/>
            <div id={'loadingViewBottom'}/>
        </div>
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Text>{translations().inThisPeriodsDevicesAllowedToInstall}</Text>
            </Col>
            <Col span={24}>
                <Text type={'danger'}>{translations().settingsApplyToAllDevices}</Text>
            </Col>
            <Col span={24}>
                <Title level={5}>{translations().daysWhenMaintenancePerformed}</Title>
            </Col>
            <Col span={24}>
                {renderWeeksCheckBox()}
            </Col>
            <Col span={24}>
                <Title level={5}>{translations().timeWhenMaintenancePerformed}</Title>
            </Col>
            <Col span={12}>
                <TimePicker
                    disabled={!store.permissions?.includes('odin.merchantSchedules.maintenanceSchedule.update')}
                    defaultValue={store.start}
                    value={store.start}
                    format={format}
                    onChange={store.setStart}
                />
            </Col>
            <Col span={12}>
                <TimePicker
                    disabled={!store.permissions?.includes('odin.merchantSchedules.maintenanceSchedule.update')}
                    disabledHours={() => getDisabledHours(store.start)}
                    disabledMinutes={(hours) => getDisabledMinutes(hours, store.start)}
                    defaultValue={store.end}
                    value={store.end}
                    format={format}
                    onChange={store.setEnd}
                />
            </Col>
            <Col span={24}>
                { store.permissions?.includes('odin.merchantSchedules.maintenanceSchedule.update') && (
                    <Button
                        disabled={!store.permissions?.includes('odin.merchantSchedules.maintenanceSchedule.update')}
                        loading={store.isLoading}
                        onClick={() => store.saveSchedule()}
                    >
                        {translations().save}
                    </Button>
                )}

            </Col>
        </Row>
    )
})
