import React from 'react'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { MerchantSearchItem } from '../MerchantSelectionStore/models/MerchantSearchItem'
import { IDeviceManagementDetailsStore } from '~/code/ODIN/pages/DeviceManagementDetails/IDeviceManagementDetailsStore'
import { fetchDeviceDetailsById, fetchPatchFriendlyName, forceReSyncDeviceById } from './services/fetchers'
import { DeviceDetails } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DeviceDetails'
import { notification } from 'antd'
import { DeviceCommunicationsMethodHistoryStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/DeviceCommunicationsMethodHistoryStore'
import { TDeviceCommunicationsMethodHistoryStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/TDeviceCommunicationsMethodHistoryStore'
import { DeviceBatteryHistoryStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/DeviceBatteryHistoryStore'
import { TDeviceBatteryHistoryStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/TDeviceBatteryHistoryStore'
import { DevicePackagesStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/DevicePackagesStore'
import { PAGE_SIZE } from '~/code/models'
import { ManagementActionsStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/ManagementActionsStore'
import { IManagementActionsStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/ManagementActions/IManagementActionsStore'
import { ApplicationFirmwareManagementStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/ApplicationFirmwareManagementStore'
import { IApplicationFirmwareManagementStore } from '~/code/ODIN/pages/DeviceManagementDetails/IApplicationFirmwareManagementStore'
import { NotificationPriorityStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/NotificationPriorityStore'
import { IAuditStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/Audit/IAuditStore'
import { AuditStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/AuditStore'
import { PushTasksStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/PushTasksStore'
import { IPushTasksStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/PushTasksModal/IPushTasksStore'
import { DeviceTypeStore } from '~/code/ODIN/stores/DeviceManagementStore/DeviceTypeStore'
import { IDeviceTypeStore } from '~/code/ODIN/pages/DeviceManagement/IDeviceTypeStore'
import translations from '~/code/ODIN/stores/DeviceManagementDetailsStore/translations'
import { SystemInfoModalStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/SystemInfoModalStore'
import { ISystemInfoModalStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/SystemInfoModal/ISystemInfoModalStore'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import { AppDeveloperStore } from '~/code/ODIN/stores/ApplicationManagementStore/AppDeveloperStore'
import { IAppDeveloperStore } from '~/code/ODIN/pages/ApplicationManagement/IAppDeveloperStore'
import { PushAppsStore } from './PushAppsStore'
import { IPushAppsStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/PushNewAppsModal/IPushAppsStore'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import commonTranslations from '~/code/translations'

export class DeviceManagementDetailsStore implements IDeviceManagementDetailsStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isDetailsLoading: observable,
            isForceReSyncLoading: observable,
            deviceDetails: observable,
            pageNumber: observable,
            pageSize: observable,
            totalPageItems: observable,
            priorityId: observable,
            deviceId: observable,
            selectedMerchant: computed,
            permissions: computed,
            loadDeviceDetailsById: action,
            forceReSync: action,
            setPageNumber: action,
            setPageSize: action,
            setPriorityId: action,
            patchFriendlyName: action,
            setDeviceId: action
        })

        this.parentStore = parentStore
        this.deviceCommunicationsMethodHistoryStore = new DeviceCommunicationsMethodHistoryStore(parentStore)
        this.deviceBatteryHistoryStore = new DeviceBatteryHistoryStore(parentStore)
        this.devicePackagesStore = new DevicePackagesStore(parentStore, PackageType.Application)
        this.deviceFirmwareStore = new DevicePackagesStore(parentStore, PackageType.Firmware)
        this.managementActionsStore = new ManagementActionsStore(parentStore)
        this.applicationFirmwareManagementStore = new ApplicationFirmwareManagementStore(this)
        this.notificationPriorityStore = new NotificationPriorityStore(parentStore)
        this.auditStore = new AuditStore(parentStore)
        this.pushTasksStore = new PushTasksStore(parentStore)
        this.pushAppsStore = new PushAppsStore(parentStore, this.applicationFirmwareManagementStore)
        this.deviceTypesStore = new DeviceTypeStore()
        this.systemInfoModalStore = new SystemInfoModalStore(parentStore)
        this.appDeveloperStore = new AppDeveloperStore()
    }

    private parentStore: IAppStore
    public deviceCommunicationsMethodHistoryStore: TDeviceCommunicationsMethodHistoryStore
    public deviceBatteryHistoryStore: TDeviceBatteryHistoryStore
    public devicePackagesStore: DevicePackagesStore
    public deviceFirmwareStore: DevicePackagesStore
    public managementActionsStore: IManagementActionsStore
    public applicationFirmwareManagementStore: IApplicationFirmwareManagementStore
    public notificationPriorityStore: NotificationPriorityStore
    public auditStore: IAuditStore
    public pushTasksStore: IPushTasksStore
    public pushAppsStore: IPushAppsStore
    public deviceTypesStore: IDeviceTypeStore
    public systemInfoModalStore: ISystemInfoModalStore
    public appDeveloperStore: IAppDeveloperStore

    public deviceDetails: DeviceDetails = {} as DeviceDetails
    public isDetailsLoading: boolean = false
    public isForceReSyncLoading: boolean = false
    public pageNumber: number = 0
    public pageSize: number = PAGE_SIZE
    public totalPageItems: number
    public priorityId: number = 1
    public deviceId: number

    public get selectedMerchant(): MerchantSearchItem {
        return this.parentStore?.odinSelectedMerchant
    }

    public get permissions(): string[] {
        return this.parentStore?.userInfo?.permissions
    }

    public loadDeviceDetailsById = (deviceId: number) => {
        if (this.isDetailsLoading) {
            return
        }

        this.isDetailsLoading = true

        fetchDeviceDetailsById(deviceId)
            .then(response => {
                runInAction(() => {
                    this.deviceDetails = response.result
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isDetailsLoading = false
                })
            })
    }

    public forceReSync = (deviceId: number) => {
        if (this.isForceReSyncLoading) {
            return
        }

        this.isForceReSyncLoading = true

        forceReSyncDeviceById(deviceId)
            .then(_ => {
                document.location.reload()
            })
            .catch((err: Error) => {
                notification.error({
                    message: <span>{err.name}</span>,
                    description: <span>{err.message}</span>,
                    duration: 10
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isForceReSyncLoading = false
                })
            })
    }

    public patchFriendlyName = (id: number, name: string) => {
        fetchPatchFriendlyName(id, name)
            .then((_) => {
                notification.success({
                    message: translations().nameSavedSuccessfully,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })

                this.loadDeviceDetailsById(id)
            })
            .catch((err: Error) => {
                const errorObject = JSON.parse(err.message)

                const errors = errorObject?.error?.errors?.map(item => <div key={item.errorMessage}>{`${item.propertyName} - ${item.errorMessage}`}</div>)
                notification.error({
                    message: <>{errors}</>,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
    }

    public setPriorityId = (priorityId: number) => {
        this.priorityId = priorityId
    }

    public setPageNumber = (page: number) => {
        this.pageNumber = page
    }

    public setPageSize = (size: number) => {
        this.pageSize = size
    }

    public setDeviceId = (deviceId: number) => {
        this.deviceId = deviceId
    }
}
