import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Popover } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { IconButton } from '~/code/common/components/IconButton/IconButton'
import { SvgParams, SvgReload } from '~/assets/icons'
import SvgFirstPage from '~/assets/icons/FirstPage'
import SvgPrevPage from '~/assets/icons/PrevPage'
import SvgNextPage from '~/assets/icons/NextPage'
import SvgLastPage from '~/assets/icons/LastPage'
import translations from '~/code/common/components/ListActions/translations'
import { ListActionsProps } from './props'
import styles from './ListActions.scss'

export const ListActions = observer((
    {
        className, isFilterDisabled = true, onFilterClick, onGoFirstPageClick, onGoLastPageClick, currentTotalAmount,
        onGoNextPageClick, onGoPrevPageClick, onReloadClick, isPrevPageDisabled, isNextPageDisabled, pageInfo, isFilterVisible,
        filterContent, onDownloadClick, isGoToFirstPageDisabled = true, isGoToLastPageDisabled = true
    }: ListActionsProps
) => {
    return <div className={classNames(styles.ListActions, className)}>
        <div className={classNames(styles.container)}>
            {onDownloadClick && <IconButton icon={<DownloadOutlined />} className={classNames(styles.button, styles.download)} onClick={onDownloadClick} />}
            {onReloadClick && <IconButton icon={<SvgReload/>} className={styles.button} onClick={onReloadClick} />}
            {!isFilterDisabled && (
                <Popover
                    content={filterContent}
                    title={translations().filter}
                    trigger="click"
                    visible={isFilterVisible}
                    zIndex={1000}
                    placement={'bottom'}
                >
                    <IconButton icon={<SvgParams/>} className={styles.button} onClick={onFilterClick} disabled={isFilterDisabled}/>
                </Popover>
            )}
            <div className={styles.paging}>
                <IconButton icon={<SvgFirstPage/>} className={styles.button} onClick={onGoFirstPageClick} disabled={isGoToFirstPageDisabled}/>
                <IconButton icon={<SvgPrevPage/>} className={styles.button} onClick={onGoPrevPageClick} disabled={isPrevPageDisabled}/>
                {pageInfo && <div className={styles.pageInfo}>{translations().pageInfo(pageInfo)}</div>}
                {!pageInfo && <div className={styles.pageInfo}>{translations().currentTotalAmount(currentTotalAmount)}</div>}
                <IconButton icon={<SvgNextPage/>} className={styles.button} onClick={onGoNextPageClick} disabled={isNextPageDisabled}/>
                <IconButton icon={<SvgLastPage/>} className={styles.button} onClick={onGoLastPageClick} disabled={isGoToLastPageDisabled}/>
            </div>
        </div>
    </div>
})
