// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_XXiWVdcaBKI_FeTt_USj .ant-select-selection-item.ant-select-selection-item.ant-select-selection-item {\n  height: 38px; }\n\n._1cBrhLisHvcyMRCq9ykRHb {\n  display: flex; }\n  ._1cBrhLisHvcyMRCq9ykRHb .ant-avatar {\n    height: 42px;\n    width: 42px; }\n  ._1cBrhLisHvcyMRCq9ykRHb .ant-select-selection-overflow-item {\n    width: 100%; }\n\n.a1epnk7e3_kW5ZGBgbNBl {\n  width: 50px;\n  margin-right: 8px;\n  border-radius: 16px; }\n\n._1S9LEcBzo25eQmU1DyjSSn {\n  display: flex;\n  justify-content: end; }\n\n._2fRElpfLx75WwR_K6GoSY- .ant-upload {\n  height: 128px;\n  width: 128px; }\n\n._1oN8hLcng-Z1Tl1g9Mqj7P {\n  display: flex;\n  align-items: center; }\n  ._1oN8hLcng-Z1Tl1g9Mqj7P .ant-list-item-meta-content {\n    min-width: 120px;\n    display: flex; }\n  ._1oN8hLcng-Z1Tl1g9Mqj7P .ant-list-item-meta-title {\n    margin-bottom: 0;\n    margin-right: 4px; }\n\n.HNvj7_l_zU-OQUe1fxsFv {\n  margin: 8px; }\n", ""]);
// Exports
exports.locals = {
	"deviceTypeSelect": "_1_XXiWVdcaBKI_FeTt_USj",
	"deviceTypes": "_1cBrhLisHvcyMRCq9ykRHb",
	"deviceTypeImage": "a1epnk7e3_kW5ZGBgbNBl",
	"upload": "_1S9LEcBzo25eQmU1DyjSSn",
	"iconUpload": "_2fRElpfLx75WwR_K6GoSY-",
	"model": "_1oN8hLcng-Z1Tl1g9Mqj7P",
	"addNewDev": "HNvj7_l_zU-OQUe1fxsFv"
};
module.exports = exports;
