// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2AT__3e1nZbZd094iXSfMv {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 22px;\n  color: #000000; }\n", ""]);
// Exports
exports.locals = {
	"label": "_2AT__3e1nZbZd094iXSfMv"
};
module.exports = exports;
