import React from 'react'
import { SummaryBlockData } from '~/code/common/components/SummaryBlock/models/SummaryBlockData'
import translations from '../translations/translations'
import { removeEmptyFieldsFromRowData } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/services/order-detail-utils'
import { DispatchReportModel } from '~/code/POS/pages'

export function dispatchReportModelToSummaryBlockData (dispatchReport: DispatchReportModel = new DispatchReportModel()): SummaryBlockData {
    const {
        refereeName, merchantStoreName, serialNumber, tamperBagNo, simNo, mid, tid, dispatchedDateTime, consignmentNo, productCode, name, orderReference
    } = dispatchReport


    const reportSummaryData: SummaryBlockData = {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().orderReference,
                        value: `${orderReference ? orderReference : translations().noReference }`,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().refereeName,
                        value: refereeName,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().merchantStoreName,
                        value: merchantStoreName,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().serialNumber,
                        value: serialNumber,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().tamperBagNo,
                        value: tamperBagNo,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().simNo,
                        value: simNo,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().mid,
                        value: mid,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().tid,
                        value: tid,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().dispatchedDateTime,
                        value: dispatchedDateTime,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().consignmentNo,
                        renderValue: () => consignmentNo.match(/[a-zA-Z]+/g)
                            ?  (
                                    <a
                                        href={`http://www.parcelforce.com/track-trace?trackNumber=${consignmentNo}`}
                                        target={'_blank'}
                                    >{consignmentNo}</a>
                                )
                            : consignmentNo
                        ,
                        value: consignmentNo,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().productCode,
                        value: productCode,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().name,
                        value: name,
                        span: {xs: 24, sm: 12}
                    }
                ]
            }
        ]
    }

    reportSummaryData.rows = removeEmptyFieldsFromRowData(reportSummaryData.rows)
    return reportSummaryData
}
