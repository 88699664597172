import React from 'react'
import translations from './translations'

translations.add('en', {
    storeInfo: 'Store Info',
    address: 'Address',
    postcode: 'Postcode',
    town: 'Town',
    country: 'Country',
    departments: 'Departments',
    addNewDepartment: 'Add New Department',
    noActionAvailable: <>Existing store</>,
    hasNoDepartments: 'The store has no departments. You will not be able to add bundles to this store without departments. Please add at least one.',
    areYouSureToDeleteStore: <>Are you sure you want to delete the selected store?<br/>Once deleted, all of the nested departments and bundles will be deleted, too.</>,
    yesDelete: 'Yes, delete',
    cancelDelete: 'No, keep it',
    new: 'new'
})
