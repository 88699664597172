import React from 'react'
import classNames from 'classnames'
import { SubHeaderProps } from './props'
import styles from './SubHeader.scss'

export const SubHeader = (props: SubHeaderProps) => {
    return <div className={classNames(styles.SubHeader, {[styles.small]: props.style === 'small', [styles.bold]: props.style === 'bold'})}>
        {props.children}
    </div>
}
