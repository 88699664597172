import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { runInAction } from 'mobx'
import { Card, Col, Row, Tooltip } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { AppStoreContext } from '~/code/AppStore'
import { INewOrder123SendStore } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/components/123send/INewOrder123SendStore'
import { NewOrder, Page } from '~/code/POS/pages'
import { Button } from '~/code/common/components'
import dnaPaymentsDarkLogo from '~/assets/img/dna_payments_logo_dark.svg'
import translations from './translations'
import styles from './NewOrderOTTEmployee.scss'

const {Meta} = Card

export const NewOrderOTTEmployee = observer(() => {
    const store = useContext(AppStoreContext).newOrderStore as INewOrder123SendStore

    useEffect(() => {
        runInAction(() => {
            if (!store.businessEntityId) {
                store.currentStep = 0
            }
        })
    }, [])

    const newMerchantDetails = {
        title: translations().newRelationship,
        description: translations().newRelationshipDetails,
        companies: [
            {
                businessEntityId: 1,
                logo: dnaPaymentsDarkLogo,
                className: styles.newMerchantLogo,
                name: 'DNA Payments'
            }
        ]
    }

    const existingMerchantDetails = {
        title: translations().existingRelationship,
        description: translations().existingRelationshipDetails,
        companies: store.businessEntities
    }

    const renderMerchantCard = (details) => {
        return (
            <Card
                bodyStyle={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}
                className={styles.companiesContainer}
            >
                <Card loading={store.isBusinessEntitiesLoading} bordered={false} className={styles.companyContainer}>
                    {details.companies?.map(company => (
                        <Tooltip
                            className={styles.tooltip}
                            placement="top"
                            title={translations().selectCompanyMerchantType(company.name)}
                        >
                            <Card.Grid
                                className={classNames(styles.company, company.className)}
                                key={company.key}
                                // @ts-ignore
                                onClick={() => store.setBusinessEntityId(company.businessEntityId)}
                            >
                                <img
                                    className={styles.companyLogo}
                                    src={company.logo}
                                    alt={company.businessEntityId}
                                />
                            </Card.Grid>
                        </Tooltip>
                    ))}
                </Card>
                <br/>
                <Meta
                    className={styles.companyDescription}
                    title={details.title}
                    description={details.description}
                />
            </Card>
        )
    }

    const renderMerchantGroupCompanySelection = () => {
        return (
            <Row gutter={16}>
                <Col xs={24} md={12}>{renderMerchantCard(newMerchantDetails)}</Col>
                <Col xs={24} md={12}>{renderMerchantCard(existingMerchantDetails)}</Col>
            </Row>
        )
    }

    const renderContent = () => {
        const isBackBtnVisible = (store.createOrderSteps[store.currentCreateOrderStep].key === 'merchantDetails' ||
            (store.createOrderSteps[store.currentCreateOrderStep].key === 'storesAndDepartments') && store.createOrderSteps.length === 4)
        return <>
            {
                store.steps[store.currentStep] === 'confirmMerchantType' && (
                    <Page>
                        <div>
                            <div className={styles.header} key={'header'}>{translations().placeOrder}</div>
                            <div className={styles.headerDescription} key={'header'}>{translations().confirmTheMerchantType}</div>
                            <div key={'content'}>{renderMerchantGroupCompanySelection()}</div>
                        </div>
                    </Page>
                )
            }
            {
                store.steps[store.currentStep] !== 'confirmMerchantType' && <div>
                    <NewOrder/>
                </div>
            }
            {
                store.steps[store.currentStep] === 'creatingOrder' && isBackBtnVisible &&
                <div>
                    <Button
                        className={styles.back}
                        outline={'outlined'}
                        onClick={() => store.goBack()}
                    >
                        <ArrowLeftOutlined />{translations().back}
                    </Button>
                </div>
            }
        </>
    }

    return renderContent()
})
