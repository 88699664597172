// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".yPI8reyxhFOYsQufDpKhj :hover {\n  cursor: pointer; }\n\n.yPI8reyxhFOYsQufDpKhj .ant-select, .yPI8reyxhFOYsQufDpKhj .ant-select-multiple {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 19px;\n  color: #000000; }\n\n.yPI8reyxhFOYsQufDpKhj .ant-select-selection-search-input, .yPI8reyxhFOYsQufDpKhj .ant-select-selection-item {\n  min-height: 38px;\n  display: flex;\n  align-items: center; }\n\n.yPI8reyxhFOYsQufDpKhj .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {\n  min-height: 50px;\n  border: 1px solid #BABABA;\n  border-radius: 4px; }\n  .yPI8reyxhFOYsQufDpKhj .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder, .yPI8reyxhFOYsQufDpKhj .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {\n    line-height: 48px; }\n\n.yPI8reyxhFOYsQufDpKhj .ant-select-multiple .ant-select-selector {\n  min-height: 50px;\n  border: 1px solid #BABABA;\n  border-radius: 4px; }\n", ""]);
// Exports
exports.locals = {
	"TreeSelect": "yPI8reyxhFOYsQufDpKhj"
};
module.exports = exports;
