import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import page from 'page'
import { Card, Col, Form, List, Row, Space, Typography, Tag, Avatar } from 'antd'
import classNames from 'classnames'
import { useForm } from 'antd/es/form/Form'
import { FormInstance } from 'antd/es/form'
import { Page } from '~/code/common/pages/Page'
import { MerchantDevice } from '~/code/ODIN/stores/DeviceManagementStore/models/MerchantDevice'
import { Button, Input, ListActions, Modal, Select } from '~/code/common/components'
import { SelectMerchant } from '~/code/ODIN/components/SelectMerchant/SelectMerchant'
import { FilterActions } from '~/code/common/components/FilterActions/FilterActions'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models/Constants'
import translations from '~/code/ODIN/pages/DeviceManagement/translations'
import { IDeviceManagementProps } from './props'
import styles from './DeviceManagement.scss'

const {Title} = Typography

export const DeviceManagement = observer((props: IDeviceManagementProps) => {
    const { store } = props
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
    const [isBottomFilterVisible, setIsBottomFilterVisible] = useState<boolean>(false)
    const [form] = useForm()
    const formRef = React.createRef<FormInstance>()

    const handleReset = () => {
        store.setOptions({})
        formRef.current!.resetFields()
        setIsFilterVisible(false)
    }

    const onAddBundleFinish = (values: any) => {
        store.addDeviceType(values)
    }

    const onFilterFinish = (values: any) => {
        const deviceType = store.deviceTypeStore?.deviceTypes?.find(type => type.params.id === values.manufacturer)

        store.setOptions({
            model: deviceType?.params?.model,
            manufacturer: deviceType?.params?.manufacturer,
            serialNumber: values.serialNumber,
            friendlyName: values.friendlyName,
            isRegistered: values.isRegistered,
            online: values.online
        })

        store.loadDevices(1)
        setIsFilterVisible(false)
        setIsBottomFilterVisible(false)
    }

    const listItem = (item: MerchantDevice) => {
        const status = item.online ? <Tag color="success">{translations().online}</Tag> : <Tag color="error">{translations().offline}</Tag>
        const registrationStatus = item.isRegistered ? '' : <Tag color="blue">{translations().odinRegistrationRequired}</Tag>
        const manufacturer = store.deviceTypeStore.deviceTypes.find(deviceType => deviceType.params.manufacturer === item.manufacturer && deviceType.params.model === item.model)

        return (
            <List.Item onClick={() => page(`${LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT_DETAILS}/${item.deviceId}`)}>
                <Card hoverable>
                    <Row gutter={16}>
                        <Col xs={24} sm={3} md={3}>
                            <Avatar className={styles.image} src={manufacturer?.params?.imageUrl}/>
                        </Col>
                        <Col xs={24} sm={10} md={12}>
                            <Row gutter={16}>
                                <Col xs={12} sm={14}>
                                    {item.serialNumber && <div className={styles.header}>
                                        <span>{translations().briefSerialNumber}</span>: {item.serialNumber}
                                    </div>
                                    }
                                    {item.friendlyName && (
                                        <div className={styles.subtitle}>{translations().name}: {item.friendlyName}</div>
                                    )}
                                    {(item.manufacturer || item.model) && (
                                        <div className={ classNames(styles.subtitle) }>
                                            { translations().type }: { `${item.manufacturer} - ${item.model}` }
                                        </div>
                                    )}
                                    {item.merchantStoreName && (
                                        <div className={styles.subtitle}>{translations().store}: {item.merchantStoreName}</div>
                                    )}
                                    {item.createdDateTime && (
                                        <div className={ classNames(styles.subtitle) }>
                                            { translations().created }: { moment(item.createdDateTime).format('DD/MM/YY HH:mm:ss') }
                                        </div>
                                    )}
                                    {item.lastCommunication && (
                                        <div className={ classNames(styles.subtitle) }>
                                            { translations().briefLastCommunication }: { moment(item.lastCommunication).format('DD/MM/YY HH:mm:ss') }
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={10} md={8}>
                            <Row gutter={16}>
                                <Col xs={12} sm={16}>
                                    <div className={styles.title}>{translations().status}</div>
                                    <div className={styles.subtitle}>
                                        {registrationStatus ? registrationStatus : status}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </List.Item>
        )
    }

    const renderPagination = (isBottom: boolean = false) => {
        return (
            <>
                <ListActions
                    className={styles.actionList}
                    pageInfo={{
                        from: store.merchantDevices.length ? store.pageNumber * store.pageSize - 9 : 0,
                        to: store.pageNumber * store.pageSize > store.totalCount ? store.totalCount : store.pageNumber * store.pageSize,
                        total: store.totalCount
                    }}
                    currentTotalAmount={store.merchantDevices.length}
                    onReloadClick={() => store.loadDevices()}
                    onGoNextPageClick={() => store.loadDevices(store.pageNumber + 1)}
                    onGoPrevPageClick={() => store.loadDevices(store.pageNumber - 1)}
                    isNextPageDisabled={(store.pageNumber >= store.totalPageItems) || store.totalPageItems === 1}
                    isPrevPageDisabled={store.pageNumber <= 1}
                    onDownloadClick={() => store.downloadDevicesCSV()}
                    onGoFirstPageClick={() => store.loadDevices(1)}
                    onGoLastPageClick={() => store.loadDevices(store.totalPageItems)}
                    isGoToFirstPageDisabled={store.pageNumber <= 1}
                    isGoToLastPageDisabled={store.pageNumber >= store.totalPageItems}
                    isFilterDisabled={false}
                    isFilterVisible={isBottom ? isBottomFilterVisible : isFilterVisible}
                    onFilterClick={() => isBottom ? setIsBottomFilterVisible(true) : setIsFilterVisible(true)}
                    filterContent={() => {
                        return <div style={{ padding: 8 }}>
                            <Form
                                ref={formRef}
                                form={form}
                                onFinish={onFilterFinish}
                            >
                                <Row className={styles.filterPanel} gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label={translations().type} name={'manufacturer'}>
                                            <Select
                                                showSearch
                                                loading={store.deviceTypeStore.isLoading}
                                                selectItems={store.deviceTypeStore.deviceTypes}
                                                placeholder={translations().selectDeviceType}
                                                optionFilterProp="children"
                                                className={styles.filterItem}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={translations().serialNumber} name={'serialNumber'}>
                                            <Input className={styles.filterItem} placeholder={translations().searchSerialNumber}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={translations().name} name={'friendlyName'}>
                                            <Input className={styles.filterItem} placeholder={translations().searchFriendlyName}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={translations().registrationStatus} name={'isRegistered'}>
                                            <Select
                                                selectItems={[
                                                    {
                                                        value: true,
                                                        label: translations().yes
                                                    },
                                                    {
                                                        value: false,
                                                        label: translations().no
                                                    }
                                                ]}
                                                placeholder={translations().isRegistered}
                                                optionFilterProp="children"
                                                className={styles.filterItem}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={translations().onlineStatus} name={'online'}>
                                            <Select
                                                selectItems={[
                                                    {
                                                        value: true,
                                                        label: translations().yes
                                                    },
                                                    {
                                                        value: false,
                                                        label: translations().no
                                                    }
                                                ]}
                                                placeholder={translations().isOnline}
                                                optionFilterProp="children"
                                                className={styles.filterItem}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item key={'placeRequest'}>
                                            <FilterActions
                                                onCancel={() => isBottom ? setIsBottomFilterVisible(false) : setIsFilterVisible(false)}
                                                onReset={() => handleReset()}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    }}
                />
        </>
        )
    }

    return (
        <Page>
            <Title level={3}>{translations().terminalManagement}</Title>

            {
                !store.selectedMerchant && (
                    <SelectMerchant/>
                )
            }

            {
                store.selectedMerchant && (
                    <>
                        {store.permissions?.includes('odin.merchant.addDevice') && (
                            <Space style={{ marginBottom: 16 }}>
                                <Button onClick={() => store.setIsAddBundleModalOpen(true)}>{translations().addBundle}</Button>
                            </Space>
                        )}
                        {store.permissions?.includes('odin.merchant.devices.view') && (
                            <>
                                {renderPagination()}
                                <List
                                    grid={{
                                        gutter: 16,
                                        column: 1
                                    }}
                                    loading={store.isLoading}
                                    dataSource={store.merchantDevices}
                                    renderItem={item => listItem(item)}
                                />
                                <br/>
                                {store.merchantDevices.length ? renderPagination(true) : null}
                            </>
                        )}

                        <Modal
                            key={'addBundle'}
                            visible={store.isAddBundleModalOpen}
                            title={translations().deviceManagementAddBundle}
                            onOk={store.closeAddBundleModal}
                            onCancel={store.closeAddBundleModal}
                            footer={[
                                <Button key={'cancel'} outline={'outlined'} onClick={store.closeAddBundleModal} className={styles.cancel}>
                                    {translations().cancel}
                                </Button>,
                                <Button key={'ok'} type={'primary'} loading={store.isAddDeviceTypeLoading} form="addDevice" htmlType="submit">
                                    {translations().addBundle}
                                </Button>
                            ]}
                            type={'info'}
                            smallerWidth
                        >
                            <Form
                                id="addDevice"
                                name="addDevice"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={onAddBundleFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={translations().deviceType}
                                    name="deviceTypeId"
                                    rules={[{ required: true, message: translations().pleaseSelectDeviceType }]}
                                >
                                    <Select
                                        showSearch
                                        loading={store.deviceTypeStore.isLoading}
                                        selectItems={store.deviceTypeStore.deviceTypes}
                                        placeholder={translations().pleaseSelect}
                                        className={styles.deviceTypes}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={translations().serialNumber}
                                    name="serialNumber"
                                    rules={[{ required: true, message: translations().pleaseInputSerialNumber }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={translations().deviceName}
                                    name="deviceName"
                                    rules={[{ required: true, message: translations().pleaseInputDeviceName }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>
                )
            }
        </Page>
    )
})
