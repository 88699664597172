import React from 'react'
import translations from './translations'

translations.add('en', {
    terminalManagement: 'Device Management',
    serialNumber: 'Serial Number',
    briefSerialNumber: 'S/N',
    ptid: 'PTID',
    friendlyName: 'Friendly Name',
    name: 'Name',
    model: 'Model',
    merchant: 'Merchant',
    merchantStore: 'Merchant Store',
    store: 'Store',
    created: 'Created',
    status: 'Status',
    registrationStatus: 'Registration Status',
    deviceId: 'Device ID',
    lastCommunication: 'Last Communication',
    briefLastCommunication: 'Last Comm',
    locked: 'Locked',
    unlocked: 'Unlocked',
    manufacturer: 'Manufacturer',
    merchantId: 'Merchant ID',
    merchantStoreId: 'Merchant Store ID',
    merchantStoreName: 'Merchant Store Name',
    online: 'Online',
    action: 'Action',
    search: 'Search',
    reset: 'Reset',
    filter: 'Filter',
    yes: 'Yes',
    no: 'No',
    createdDateTime: 'Created Date Time',
    addBundle: 'Add Device',
    deviceManagementAddBundle: 'Device Management - Add Device',
    cancel: 'Cancel',
    pleaseSelect: 'Please Select',
    deviceType: 'Device Type',
    pleaseInputSerialNumber: 'Please input serial number!',
    deviceName: 'Device Name',
    pleaseInputDeviceName: 'Please input device name!',
    pleaseSelectDeviceType: 'Please select device type!',
    deviceTypeAddedSuccessfully: 'Device type added successfully',
    offline: 'Offline',
    searchManufacturer: 'Search Manufacturer',
    searchModel: 'Search Model',
    searchSerialNumber: 'Search Serial Number',
    searchFriendlyName: 'Search Friendly Name',
    searchCreatedDateTime: 'Search Created Date Time',
    odinRegistrationRequired: `ODIN Registration
        Required`,
    type: 'Type',
    selectDeviceType: 'Select Device Type',
    selectStatus: 'Select Status',
    selectIsRegistered: 'Select registration status',
    isOnline: 'Is Online?',
    onlineStatus: 'Online Status',
    isRegistered: 'Is Registered?'
})
