import React from 'react'
import translations from './translations'

translations.add('en', {
    new: 'a new',
    existing: 'an existing',
    soleTrader: 'sole trader',
    company: 'limited company',
    uk: 'United Kingdom',
    roi: 'Republic of Ireland',
    other: 'other country',
    orderingFor: 'For ',
    merchant: ' merchant',
    organisationTypeCompany: ', who is a ',
    organisationTypeSoleTrader: ' who is a ',
    andLocatedIn: ' and located in ',
    areYouSureToChangeMerchantType: <><p>Are you sure you want to change the merchant type?<br/>All entered data will be lost</p></>,
    yesSureToChangeMerchantType: 'Yes, I want to change',
    noKeepMerchantType: 'No, keep the merchant type',
    areYouSureToChangeOrganisationType: <><p>Are you sure you want to change the organisation type?<br/>All entered data will be lost</p></>,
    yesSureToChangeOrganisationType: 'Yes, I want to change',
    noKeepOrganisationType: 'No, keep the organisation type',
    areYouSureToChangeCountry: <><p>Are you sure you want to change the country?<br/>All entered data will be lost</p></>,
    yesSureToChangeCountry: 'Yes, I want to change',
    noKeepCountry: 'No, keep the country',
    areYouSureToChangeOrderType: <><p>Are you sure you want to change the order type?<br/>All entered data will be lost</p></>,
    yesSureToChangeOrderType: 'Yes, I want to change',
    noKeepOrderType: 'No, keep the order type',
    standard: 'standard',
    hardwareOnly: 'hardware only',
    ofType: ' of type '
})
