import React from 'react'
import translations from './translations'

translations.add('en', {
    deviceInfo: 'Device Info',
    deviceManagement: 'Device Details',
    applicationManagement: 'Application Details',
    serialNumber: 'Serial Number',
    friendlyName: 'Friendly Name',
    merchantName: 'Merchant Name',
    merchantStore: 'Merchant Store',
    status: 'Status',
    online: 'Online',
    offline: 'Offline',
    lastCommunication: 'Last Communication',
    forceReSync: 'Force Resync',
    thisPageWillRefresh: 'This page will refresh once resync is complete',
    noData: 'No Data',
    lastCommsMethodUsed: 'Last communications method used: ',
    seeHistory: 'See history',
    dateTime: 'Date/Time',
    communicationsMethod: 'Communications Method',
    batteryData: 'Battery Data',
    uptime: 'Uptime: ',
    batteryPercentage: 'Battery percentage',
    appAndFirmwareManagement: 'Application & Firmware Management',
    managementActions: 'Management Actions',
    audit: 'Audit',
    size: 'Size',
    changeLauncher: 'Change Launcher',
    deleteApp: 'Delete App',
    updateApp: 'Update App',
    updateFirmware: 'Update Firmware',
    upToDate: 'Up To Date',
    changeLauncherRequested: 'Change of Launcher Requested. View Push Tasks to monitor progress',
    available: 'Available',
    disabled: 'Disabled',
    enabled: 'Enabled',
    locked: 'Locked',
    installPriority: 'Install Priority',
    cancel: 'Cancel',
    pushUpdate: 'Push Update',
    selectPriorityType: 'Select Priority Type',
    selectPackageVersion: 'Select Package Version',
    appVersion: 'App Version',
    pushApp: 'Push App',
    ok: 'Ok',
    install: 'Install',
    installRequested: 'Install Requested',
    packageVersionRequired: 'Package Version Required',
    areYouSureYouWantToDelete: (name: string) => `Are you sure you want to delete ${name} from this device?`,
    areYouSureYouWantToSetLauncher: (name: string) => `Are you sure you want to set ${name} as the launcher for this device?`,
    enterPackageName: 'Enter Package Name',
    searchPackageNameOrDisplayName: 'Search Package Name or Display Name',
    pushTaskProgress: 'Push Task Progress',
    pushTasks: 'Push Tasks',
    lastMetricReceivedDateTime: 'Last Metric Received Date Time',
    systemInfo: 'System Info',
    lastTimeDeviceWasOnline: 'Last time device was online the start-up time was given as',
    odinRegistrationRequired: 'ODIN Registration Required',
    notConnected: 'Not Connected',
    connected: 'Connected',
    type: 'Type',
    application: 'Application',
    firmware: 'Firmware',
    upgradeFirmware: 'Upgrade',
    pleaseSelectPriority: 'Please Select Priority',
    uninstall: 'Uninstall',
    setAsLauncher: 'Set as launcher',
    updateAppRequested: (message) => `Update ${message} Requested. View Push Tasks to monitor progress`,
    installAppRequested: (message) => `Install ${message} Requested. View Push Tasks to monitor progress`,
    appDisplayName: 'App Display Name',
    appDeveloper: 'App Developer',
    uninstallAppRequested: 'Uninstall App Requested',
    pleaseTypePackageVersionId: 'App Version is Required',
    bluetooth: 'Bluetooth',
    cellular: 'Cellular',
    ethernet: 'Ethernet',
    notSet: 'Not Set',
    wifi: 'Wi-Fi'
})
