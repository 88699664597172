import { AppCategory } from '~/code/ODIN/stores/ApplicationManagementStore/models/AppCategory'

export const mapAppCategoryToTreeDataSource = (category: AppCategory) => ({
    title: category.name,
    value: category.categoryId,
    key: category.categoryId,
    children: category.childCategories.map(mapAppCategoryToTreeDataSource)
})

export const prepareFormData = (values) => {
    const formData = new FormData()

    Object.entries(values).map((item, index) => {
        const [key, value] = item

        if (!value) {
            return
        }

        if (Array.isArray(value)) {
            value.forEach(element => formData.append(key, String(element)))
        } else {
            formData.append(key, value as string)
        }
    })

    return formData
}
