// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1fKXc59dIZcH8veM3PQ1iA {\n  font-size: 20px; }\n\n._2bzwIqfzmW0b6xov3_ICUD {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row; }\n  ._2bzwIqfzmW0b6xov3_ICUD ._11R60KtKLvMo64M-Bd-3Yl {\n    justify-content: center; }\n\n._5G7-lvfDpPprZcIxSbkq2 {\n  word-break: break-all; }\n", ""]);
// Exports
exports.locals = {
	"orderReferenceCopyTextIcon": "_1fKXc59dIZcH8veM3PQ1iA",
	"failureButtonsWrapper": "_2bzwIqfzmW0b6xov3_ICUD",
	"centered": "_11R60KtKLvMo64M-Bd-3Yl",
	"wordBreakAll": "_5G7-lvfDpPprZcIxSbkq2"
};
module.exports = exports;
