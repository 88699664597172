// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XjPrquiZEn9wLqtOQAcjQ {\n  background: #FFFFFF;\n  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);\n  border-radius: 2px;\n  min-height: 100px;\n  min-width: 100px; }\n", ""]);
// Exports
exports.locals = {
	"UserDetails": "_3XjPrquiZEn9wLqtOQAcjQ"
};
module.exports = exports;
