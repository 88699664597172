import page from 'page'
import { IRouterStore } from './IRouterStore'
import qs from 'qs'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models/Constants'

// initialize routes
export function Router(store: IRouterStore) {
    let path: string
    page('*', (ctx, next) => {
        path = ctx.path
        ctx.query = qs.parse(ctx.querystring)
        next()
    })

    page('/', () => store.showLoginPage())
    page(LOCATION_POS_PATH_NAMES.LOGIN, () => store.showLoginPage())
    page(LOCATION_POS_PATH_NAMES.PLACE_ORDER, () => store.showPlaceOrderPage())
    page(LOCATION_POS_PATH_NAMES.ORDER_HISTORY, () => store.showOrderHistoryPage())
    page(LOCATION_POS_PATH_NAMES.ORDER_HISTORY_DETAILS, (props) => { store.showOrderDetailsPage(props.params[0]) } )
    page(LOCATION_POS_PATH_NAMES.USER_GUIDE, () => store.showUserGuidePage())
    page(LOCATION_POS_PATH_NAMES.CHECKOUT_V3_ONBOARDING, () => store.showCheckoutV3Onboarding())

    page(LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT, () => store.showDeviceManagement())
    page(`${LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT_DETAILS}/:id`, (props) => store.showDeviceManagementDetails(props.params.id))
    page(LOCATION_ODIN_PATH_NAMES.APPLICATION_MANAGEMENT, () => store.showApplicationManagement())
    page(`${LOCATION_ODIN_PATH_NAMES.APPLICATION_MANAGEMENT}/:id`, (props) => store.showApplicationManagementDetails(props.params.id))
    page(LOCATION_ODIN_PATH_NAMES.FIRMWARE_MANAGEMENT, () => store.showFirmwareManagement())
    page(`${LOCATION_ODIN_PATH_NAMES.FIRMWARE_MANAGEMENT}/:id`, (props) => store.showFirmwareManagementDetails(props.params.id))
    page(LOCATION_ODIN_PATH_NAMES.MERCHANT_SELECTION, () => store.showMerchantSelection())
    page(LOCATION_ODIN_PATH_NAMES.MERCHANT_SCHEDULE, () => store.showMerchantSchedule())

    page('*', () => store.showNotFoundPage())

    // initialize the router
    page()
}
