import React from 'react'
import { Button } from '~/code/common/components'
import { SearchOutlined } from '@ant-design/icons'
import translations from '~/code/ODIN/components/AllApplications/translations/translations'
import styles from './FilterActions.scss'

type FilterActionsProps = {
    onCancel: () => void
    onReset: () => void
}

export const FilterActions = ({onCancel, onReset}: FilterActionsProps) => {
    return (
        <>
            <Button htmlType={'submit'} className={styles.search} icon={<SearchOutlined />}>
                {translations().search}
            </Button>
            <Button className={styles.cancel} onClick={onReset}>
                {translations().reset}
            </Button>
            <Button onClick={onCancel}>
                {translations().cancel}
            </Button>
        </>
    )
}
