import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    id: string
    createdDateTime: string
    appUserId: string
    operation: string
    userFirstName: string
    userSurname: string
    userEmailAddress: string
    noDate: string
}>()
