import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { HelpIconProps } from './props'
import styles from './HelpIcon.scss'

export const HelpIcon = (props: HelpIconProps) => {
    return (
        <Tooltip title={props.text} className={styles.HelpIcon}>
            <QuestionCircleOutlined />
        </Tooltip>
    )
}
