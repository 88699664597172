// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2rxHk_eadVcWW1TvsWGeuq {\n  background-color: transparent;\n  margin: 20px 0px; }\n  ._2rxHk_eadVcWW1TvsWGeuq ._1SxeChXtFtD7Uo7f2BAQiL {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    color: #000000; }\n  ._2rxHk_eadVcWW1TvsWGeuq ._2tuvSNPYfjiojuPYfPyexK {\n    width: 100%;\n    padding-top: 50px; }\n", ""]);
// Exports
exports.locals = {
	"EmptyView": "_2rxHk_eadVcWW1TvsWGeuq",
	"message": "_1SxeChXtFtD7Uo7f2BAQiL",
	"buttonWrapper": "_2tuvSNPYfjiojuPYfPyexK"
};
module.exports = exports;
