import React from 'react'
import { observer } from 'mobx-react'
import { SummaryBlock } from '~/code/common/components'
import { configurationReportModelToSummaryBlockData } from './services'
import { ConfigurationReportProps } from './props'

export const ConfigurationReport = observer((props: ConfigurationReportProps) => {
    return <div>
        {
            configurationReportModelToSummaryBlockData(props.report).map(blockData => {
                return <SummaryBlock data={blockData}/>
            })
        }
    </div>
})
