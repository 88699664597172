import { SearchItem } from '~/code/common/components/SearchBar/models/SearchItem'
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx'
import { INewOrderStore } from '~/code/POS/pages/NewOrder/INewOrderStore'
import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { postWithAuth } from '~/code/services/authorised-requests'
import { OrderStep } from '~/code/POS/pages/NewOrder/models/OrderStep'
import { SelectItem } from '~/code/common/components/Select'
import moment from 'moment'
import { IProgressStore } from '~/code/POS/pages/NewOrder/components/common/Progress/IProgressStore'
import { ProgressStore } from '~/code/stores/NewOrderStore/ProgressStore/ProgressStore'
import { MORE_THAN_2_WHITESPACE, VALID_TEXT_REPLACE_PATTERN } from '~/code/models/Patterns'
import { clean } from '~/code/POS/pages/NewOrder/services/text-processor'
import page from 'page'
import { OrderInfo } from '~/code/stores/OrderHistoryStore/models/OrderInfo'
import { AppStore, AppStore as appStore } from '~/code/AppStore'
import { OrderDetailsModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/models/OrderDetailsModel'
import { MerchantType, OrganisationType } from '~/code/POS/pages/NewOrder/models/constants'
import { PlaceOrderConfigurationStore } from '~/code/stores/PlaceOrderConfigurationStore/PlaceOrderConfigurationStore'
import { IPlaceOrderConfigurationParentStore } from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration/models'
import { IPlaceOrderConfigurationStore } from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration/IPlaceOrderConfigurationStore'
import { CROCompany } from '~/code/POS/pages/NewOrder/models/CROCompany'
import { ROI_ID, UK_ID } from '~/code/models/Constants'
import { fetchCHCompanies, fetchCROCompanies, fetchISOMerchants, fetchPartnerMerchants } from '~/code/stores/NewOrderStore/services/fetchers'
import { notification } from 'antd'
import { isEmpty, error } from 'dna-common'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'
import { COMPANY_TYPE } from '~/code/common/layouts/MainLayout/models/CompanyType'
import translations from './translations'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'

export abstract class NewOrderStore implements INewOrderStore, IPlaceOrderConfigurationParentStore {

    protected constructor () {
        makeObservable(this, {
            generateCreateOrderSteps: action,
            generatePlaceOrderRequestAndURL: action,
            createOrderFromStringAndOrder: action,
            onMerchantTypeChange: action,
            clearAndSetUp: action,
            orderSummaryData: computed,
            internalIdentifierEncoded: computed,
            selectItem: action,
            isLoading: observable,
            internalIdentifier: computed,
            placeOrder: action,
            search: action,
            searchInCRO: action,
            setItems: action,
            searchInCH: action,
            searchInPartnerMerchants: action,
            searchInISOMerchants: action,
            submitOrder: action,
            onOrganisationTypeChange: action,
            setManuallyEnteredCompanyName: action,
            setShouldResetForm: action,
            setShouldShowSearchBar: action,
            setShouldShowForm: action,
            goToContactUs: action,
            goToOrderHistory: action,
            setCurrentStep: action,
            createOrderFromOrderInfo: action,
            selectEmployeeCompany: action,
            userInfo: computed,
            isOrderingPrevented: computed,
            shouldShowForm: computed,
            isNoCompanySelected: computed,
            selectedEmployeeCompany: computed,
            isNewOrderForSoleTrader: computed,
            selectedCompany: computed,
            selectedCompanyName: computed,
            orderSummaryCheckText: computed,
            orderSummaryCheckError: computed,
            companyName: computed,
            items: observable,
            selectedItem: observable,
            currentStep: observable,
            currentCreateOrderStep: observable,
            steps: observable,
            createOrderSteps: observable,
            manuallyEnteredCompanyName: observable,
            _countries: observable,
            _bundles: observable,
            orderReference: observable,
            placingOrder: observable,
            shouldResetForm: observable,
            shouldShowSearchBar: observable,
            _shouldShowForm: observable,
            isSearching: observable,
            existingMerchants: observable,
            selectedExistingMerchant: observable,
            isExistingMerchantModalOpen: observable,
            closeExistingMerchantModal: action.bound,
            changeToExistingMerchantFlow: action.bound
        })

        this.clearAndSetUp()

        autorun(() => {
            if (this.prevIdentifier !== this.selectedEmployeeCompany?.identifier) {
                this.generateCreateOrderSteps()
            }
            this.prevIdentifier = this.selectedEmployeeCompany?.identifier
        }, {delay: 300})

        this.progressStore = new ProgressStore(this)
        this.placeOrderConfigStore = new PlaceOrderConfigurationStore(this)

        reaction(() => this.placeOrderConfigStore.merchantType, (merchantType) => {
            if (merchantType === 'new') {
                this.setShouldShowSearchBar(true)
                this.setShouldShowForm(false)
            } else {
                this.setShouldShowSearchBar(true)
                this.setShouldShowForm(false)
            }
        })

        reaction(() => this.placeOrderConfigStore.organisationType, (organisationType) => {
            this.generateCreateOrderSteps()
            if (organisationType === 'company') {
                this.setShouldShowSearchBar(true)
                this.setShouldShowForm(false)
            } else {
                this.setShouldShowSearchBar(false)
                this.setShouldShowForm(true)
            }
        })

        reaction(() => this.placeOrderConfigStore.country, (country) => {
            switch (country) {
                case 'uk': {
                    this.setShouldShowSearchBar(true)
                    this.setShouldShowForm(false)
                    break
                }
                case 'roi': {
                    this.setShouldShowSearchBar(true)
                    this.setShouldShowForm(false)
                    break
                }
                default: { // other
                    this.setShouldShowSearchBar(false)
                    this.setShouldShowForm(true)
                }
            }
        })

    }

    generateInformationFormDataFromCHResponse = (company) => {
        if (!company) {
            return {}
        }

        const address = company.address
        const containsDigit = /\d/g
        let premises = address.premises.trim()
        if (!containsDigit.test(premises) || premises.split(' ').length > 2) {
            premises = `${clean(premises, VALID_TEXT_REPLACE_PATTERN)},`
        }
        const addressLine1 = clean(address.address_line_1, VALID_TEXT_REPLACE_PATTERN)?.trim()

        return {
            companyName: clean(company.title?.trim(), VALID_TEXT_REPLACE_PATTERN).replace(MORE_THAN_2_WHITESPACE, ' '),
            tradingAs: clean(company.title?.trim(), VALID_TEXT_REPLACE_PATTERN).replace(MORE_THAN_2_WHITESPACE, ' '),
            companyNumber: company.company_number?.trim(),
            addressLine1: clean(`${premises} ${addressLine1}`.trim(), VALID_TEXT_REPLACE_PATTERN),
            addressLine2: clean(address.address_line_2 ? address.address_line_2?.trim() : '', VALID_TEXT_REPLACE_PATTERN),
            addressLine3: clean(address.address_line_3 ? address.address_line_3?.trim() : '', VALID_TEXT_REPLACE_PATTERN),
            town: clean(address.locality?.trim(), VALID_TEXT_REPLACE_PATTERN),
            county: clean(address.region ? address.region?.trim() : '', VALID_TEXT_REPLACE_PATTERN),
            country: UK_ID,
            postcode: address.postal_code?.trim()
        }
    }

    generateInformationFormDataFromCROResponse = (company: CROCompany) => {
        if (!company) {
            return {}
        }

        return {
            companyName: clean(company.company_name?.trim(), VALID_TEXT_REPLACE_PATTERN),
            tradingAs: clean(company.company_name?.trim(), VALID_TEXT_REPLACE_PATTERN),
            companyNumber: `${company.company_num || ''}`?.trim(),
            addressLine1: clean(company?.company_addr_1?.trim() || '', VALID_TEXT_REPLACE_PATTERN),
            addressLine2: clean(company?.company_addr_2?.trim() || '', VALID_TEXT_REPLACE_PATTERN),
            addressLine3: clean(company?.company_addr_3?.trim() || '', VALID_TEXT_REPLACE_PATTERN),
            town: '',
            county: '',
            country: ROI_ID,
            postcode: company.eircode?.trim()
        }
    }

    // TODO remove this check from here, it should be implemented in specific stores
    companyType = () => {
        return COMPANY_TYPE[this.selectedEmployeeCompany?.additionalInfo?.companyTypeId] || AppStore?.userInfo?.companyType
    }

    accountNumber = () => {
        return this.userInfo.userType === 'employee' ?
            appStore?.companySelectStore?.selectedEmployeeCompany?.additionalInfo?.accountNumber :
            this.userInfo?.accountNumber
    }

    abstract generateCreateOrderSteps()

    isLoading: boolean = false // general loading
    public goBackToInformationReviewStep = 0

    private prevIdentifier = null

    placeOrder() {
        this.currentStep++ // = 'placingOrder'
        this.submitOrder()
    }

    readonly progressStore: IProgressStore
    public placeOrderConfigStore: IPlaceOrderConfigurationStore

    get companyName() {
        return this.userInfo.userType === 'employee' ?
            this.selectedEmployeeCompany?.additionalInfo?.companyRawName || '123Send' : this.userInfo?.companyName
    }

    get orderSummaryCheckText() {
        return translations().agreeWithTerms(this.companyName)
    }

    get orderSummaryCheckError() {
        return translations().confirmationRequired
    }

    get userInfo(): UserInfo {
        return appStore?.userInfo
    }

    get isOrderingPrevented(): boolean {
        return appStore?.userInfo?.preventOrdering || appStore?.companySelectStore?.selectedEmployeeCompany?.additionalInfo?.preventOrdering
    }

    get isNoCompanySelected(): boolean {
        return (appStore?.userInfo?.userType === 'employee' &&
            appStore?.shouldSelectCompany &&
            !this.selectedEmployeeCompany)
    }

    abstract get orderSummaryData()

    get selectedEmployeeCompany(): SearchItem {
        return appStore?.companySelectStore?.selectedEmployeeCompany
    }

    items: SearchItem[]

    selectedItem: SearchItem

    existingMerchants: { id: number, name: string }[]

    selectedExistingMerchant: { id: number, name: string }

    isExistingMerchantModalOpen: boolean = false

    get isNewOrderForSoleTrader(): boolean {
        return this.placeOrderConfigStore.organisationType === 'soleTrader'
    }

    get selectedCompany(): any {
        return this.selectedItem?.additionalInfo
    }

    get selectedCompanyName(): string {
        if (this.selectedItem) {
            return clean(this.selectedItem.title, VALID_TEXT_REPLACE_PATTERN).replace(MORE_THAN_2_WHITESPACE, ' ')
        }

        return ''
    }

    get internalIdentifier(): string {
        return appStore?.internalIdentifier
    }

    get internalIdentifierEncoded(): string {
        return appStore?.internalIdentifierEncoded
    }

    search (value): void {
        if (value && value.length >= 3 ) {
            if (this.placeOrderConfigStore.merchantType === 'new') {
                if (this.placeOrderConfigStore.country === 'uk') {
                    this.searchInCH(value)
                } else {
                    this.searchInCRO(value)
                }
            } else {
                this.existingMerchants = []
                if (this.companyType() === 'partner') {
                    this.searchInPartnerMerchants(value)
                } else {
                    this.searchInISOMerchants(value)
                }
            }
        }

        if (!value || value.length === 0) {
            this.setItems([])
        }
    }

    public searchInISOMerchants(value) {
        const data = {
            pageNumber: 1,
            pageSize: 10,
            searchValue: value
        }
        if (this.internalIdentifier) {
           data['internalIdentifier'] = this.internalIdentifier // this gets encoded when the data object is converted into query params
        }

        fetchISOMerchants(data).
        then( response => {
            if (response && response.length > 0) {
                this.items = response.map(merchant => {
                    const description = `MID: ${merchant.visaMid}<br/>${translations().createdDate}: ${moment(merchant.createdDateTime).format('DD.MM.YYYY')}`
                    return {identifier: merchant.merchantId.toString(), title: merchant.merchantName, description, additionalInfo: merchant }
                }).filter((item, index, array) =>
                    array.findIndex(findItem => findItem.identifier === item.identifier) === index)
            } else {
                this.items = []
            }
        }).
        catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
        .finally(() => {
            this.isSearching = false
        })
    }

    public searchInPartnerMerchants(value) {
        const data = {
            pageNumber: 1,
            pageSize: 10,
            searchValue: value
        }
        if (this.internalIdentifier) {
            data['internalIdentifier'] = this.internalIdentifier // this gets encoded when the data object is converted into query params
        }

        fetchPartnerMerchants(data).
        then( response => {
            if (response && response.length > 0) {
                this.items = response.map(merchant => {
                    const description = `${translations().accountNumber}: ${merchant.accountNo}`
                    return {identifier: merchant.merchantId.toString(), title: merchant.merchantName, description, hiddenDescription: `${merchant.merchantId}${merchant.accountNo}`, additionalInfo: merchant } // MID: ${merchant.visaMid}
                }).filter((item, index, array) =>
                    array.findIndex(findItem => findItem.identifier === item.identifier) === index)
            } else {
                this.items = []
            }
        }).
        catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
            .finally(() => {
                this.isSearching = false
            })
    }

    public searchInCH (value) { // for UK
        this.isSearching = true

        fetchCHCompanies(value)
        .then( response => {
            const { result, status } = response

            if (status === 401 || status === 502) {
                notification['error']({ message: '', description: translations().errMsgCH })
            }

            if (result && result.items && result.items.length > 0) {
                this.items = result.items.map(merchant => {
                    return {
                        identifier: merchant.company_number,
                        title: merchant.title,
                        description: ``, hiddenDescription: merchant.company_number, additionalInfo: merchant
                    }
                })
            } else {
                this.items = []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
        .finally(() => {
            this.isSearching = false
        })
    }

    public searchInCRO (value) { // for ROI
        this.isSearching = true

        fetchCROCompanies(value)
        .then( response => {
            const { result, status } = response

            if (status === 401 || status === 502) {
                notification['error']({ message: '', description: translations().errMsgCRO })
            }

            if (result && result.length > 0) {
                this.items = result.map(merchant => {
                    return {identifier: `${merchant.company_num}`, title: clean(merchant.company_name, VALID_TEXT_REPLACE_PATTERN), description: ``,
                        additionalInfo: merchant }
                })
            } else {
                this.items = []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
        .finally(() => {
            this.isSearching = false
        })
    }

    setItems (items: SearchItem[]) {
        this.items = items
    }

    closeExistingMerchantModal() {
        this.isExistingMerchantModalOpen = false
    }

    changeToExistingMerchantFlow() {
        const selectedTempItem = this.selectedItem
        this.placeOrderConfigStore.setSelectedMerchantType('existing')
        this.selectedItem = selectedTempItem
        this.isExistingMerchantModalOpen = false
        this.selectedItem.additionalInfo.merchantId = this.selectedExistingMerchant.id
        this.loadStores()
    }

    async selectItem (value: string) {
        const selectedTempItem = this.items.find(item => item.identifier === value)
        this.clearAndSetUp()

        this.selectedItem = selectedTempItem

        if (this.placeOrderConfigStore.merchantType === 'new') {
            await this.searchExistingMerchants(selectedTempItem.title)
            if (!this.existingMerchants) return

            this.selectedExistingMerchant = this.existingMerchants.find(m => m.name === this.selectedItem.title)
            this.isExistingMerchantModalOpen = !!this.selectedExistingMerchant
        }
    }

    currentStep: number = 0

    currentCreateOrderStep: number = 0

    steps: OrderStep[] = ['creatingOrder', 'summary', 'placingOrder']

    createOrderSteps: {order, title, key, children}[]

    // TODO this should decrease only the top level step count
    goBack() {
        this.currentStep--
    }
    goToNextStep() {
        // do smth
    }

    manuallyEnteredCompanyName: string

    setManuallyEnteredCompanyName = (value: string) => {
        this.manuallyEnteredCompanyName = value
    }

    _countries: SelectItem[] = []

    _bundles: SelectItem[] = []

    orderReference: string = null

    abstract generatePlaceOrderRequestAndURL(): {requestURL, request}

    submitOrder() {
        this.placingOrder = true
        const _progressStore = this.progressStore as ProgressStore
        _progressStore.setStatus('loading')

        const {requestURL, request} = this.generatePlaceOrderRequestAndURL()

        checkHTTPStatus(postWithAuth<any>(requestURL, request)).
        then( response => {
            this.placingOrder = false
            _progressStore.setStatus('success')
        }).
        catch((err: Error) => {
            error('FAILED:', err)
            let orderStatus = '-1'
            let errorMessage = err.message
            try {
                const parsedMessage = JSON.parse(err.message)
                const errorObject = parsedMessage.error
                orderStatus = String(errorObject.currentOrderStatus)
                errorMessage = errorObject.errorsWithinOrder
            } catch (e) {
                error(e)
            }

            _progressStore.setStatus('failure')
            _progressStore.setErrorCodeAndMessage(orderStatus, errorMessage)
            this.placingOrder = false
        })
    }

    placingOrder: boolean = false

    abstract onMerchantTypeChange(merchantType: MerchantType)

    onOrganisationTypeChange = (organisationType: OrganisationType) => {
        // DO Nothing
    }

    clearAndSetUp () {
        this.shouldResetForm = true
        this.currentStep = 0
        this.currentCreateOrderStep = 0
        this.placingOrder = false
        this.items = []
        this.selectedItem = null
        this.orderReference = ''
        this.manuallyEnteredCompanyName = ''
    }

    shouldResetForm: boolean = true

    shouldShowSearchBar: boolean = true

    _shouldShowForm: boolean

    get shouldShowForm(): boolean{
        return this._shouldShowForm || !isEmpty(this.selectedCompanyName)
    }

    setShouldResetForm (value: boolean) {
        this.shouldResetForm = value
    }

    setShouldShowSearchBar (value: boolean) {
        this.shouldShowSearchBar = value
    }

    setShouldShowForm (value: boolean) {
        this._shouldShowForm = value
    }

    goToContactUs () {
        this.clearAndSetUp()
        if (this.userInfo?.userType !== 'employee'){
            page(LOCATION_POS_PATH_NAMES.ORDER_HISTORY)
        }
    }

    goToOrderHistory () {
        this.clearAndSetUp()
        page(LOCATION_POS_PATH_NAMES.ORDER_HISTORY)
    }

    setCurrentStep (step: number) {
        this.currentStep = step
    }

    isSearching: boolean = false

    abstract createOrderFromStringAndOrder (previousOrder: string, orderDetailsModel: OrderDetailsModel)

    abstract createOrderFromOrderInfo (orderInfo: OrderInfo)

    abstract loadStores()

    abstract searchExistingMerchants?(value: string): Promise<void>

    selectEmployeeCompany = () => {
        appStore?.companySelectStore?.setModalVisible(true)
    }
}
