import { action, makeObservable } from 'mobx'
import { ICDNUploadResponse } from '~/code/stores/CheckoutV3Onboarding/models/backend/ICDNUploadResponse'
import { prepareCheckoutImageFormData } from '~/code/stores/CheckoutV3Onboarding/services/utils'
import { uploadImageToCDN } from '~/code/stores/CheckoutV3Onboarding/services/fetchers'
import { IImageUploadStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/models/IImageUploadStore'

export class ImageUploadStore implements IImageUploadStore {
    constructor () {
        makeObservable(this, {
            uploadImage: action
        })
    }

    uploadImage = async (image: File, directory: string): Promise<ICDNUploadResponse> => {
        const formData = prepareCheckoutImageFormData(image, directory)

        return await uploadImageToCDN(formData)
            .then((res) => res)
            .catch((err) => {
                throw new Error(err)
            })
    }
}
