import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form, Space, Radio } from 'antd'
import { Input, Button, FakeLoginForm } from '~/code/common/components'
import { SvgUser, SvgLock } from '~/assets/icons'
import translations from './translations'
import { LoginFormProps } from './props'
import mainStyles from '~/assets/styles/main.scss'
import styles from './LoginForm.scss'

export const LoginForm = observer((props: LoginFormProps) => {
    const { store } = props
    const onFinish = (values) => {
        store.onSubmit(values)
    }
    const [form] = Form.useForm()

    useEffect(() => {
        store.setLogin(form.getFieldValue('username'))
    }, [form.getFieldValue('username')])

    return <div className={styles.LoginForm}>
        <div className={styles.LoginForm_title}>
            {translations().title}
        </div>
        <Form autoComplete={'off'} layout={'vertical'} form={form} onFinish={onFinish}>
            <div className={styles.LoginForm_inputContainer}>
                <FakeLoginForm/>
                <Form.Item
                    name={'username'}
                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value
                        store.setLogin(value)
                        return value
                    }}
                    rules={
                        [
                            {
                                required: true,
                                message: translations().emailRequired
                            },
                            {
                                type: 'email',
                                message: translations().invalidEmail
                            }
                        ]
                    }
                >
                    <Input
                        autoComplete={'false'}
                        textTransform={'lowercase'}
                        prefix={<SvgUser className={mainStyles.icon}/>}
                        placeholder={ translations().email }
                    />
                </Form.Item>
            </div>
            <div className={styles.LoginForm_inputContainer}>
                <Form.Item
                    name={'password'}
                    rules={
                        [
                            {
                                required: true,
                                message: translations().passwordRequired
                            }
                        ]
                    }
                >
                    <Input
                        autoComplete={'new-password'}
                        type={'password'}
                        name={'new-password'}
                        prefix={<SvgLock className={mainStyles.icon}/>}
                        placeholder={ translations().password }
                    />
                </Form.Item>
            </div>
            {
                store.isPortalSelectable && (
                    <Row className={styles.LoginForm_footerContainer}>
                        <Col xs={24}>
                            <Radio.Group onChange={(e) => store.setPortalType(e.target.value)} value={store.portalType}>
                                <Space direction="horizontal">
                                    <Radio value={'POS'}>{ translations().orderPortal }</Radio>
                                    <Radio value={'ODIN'}>{ translations().odin }</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                    </Row>
                )
            }
            <div>
                <Row className={styles.LoginForm_footerContainer}>
                    <Col xs={12}>
                        <a className={styles.LoginForm_forgotPassword} href={ `${ _RESET_PASS_URL_ }` } target={'blank'}>
                            { translations().forgotPassword }
                        </a>
                    </Col>
                    <Col xs={12} className={styles.LoginForm_submitButtonContainer}>
                        <Form.Item>
                            <Button className={null} htmlType={'submit'}>
                                { translations().signIn }
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Form>
    </div>
})
