import { LOCAL_STORAGE_TOKEN_KEYS } from '~/code/models'
import { authoriseInDNAApi } from '~/code/stores/CheckoutV3Onboarding/services/fetchers'
import { ScopeType } from '~/code/stores/CheckoutV3Onboarding/models/ScopeType'

export async function getDnaApiTokenByScope(scope: ScopeType) {
    const localStorageToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEYS.DNA_API + scope)

    if (!localStorageToken) {
        const token = await authoriseInDNAApi(scope)

        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEYS.DNA_API + scope, token)

        return token
    } else {
        return localStorageToken
    }
}

export async function refreshDnaApiTokenByScope(scope: ScopeType) {
    try {
        const token = await authoriseInDNAApi(scope)
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEYS.DNA_API + scope, token)
    } catch (e) {
        throw new Error(e)
    }
}

export function prepareCheckoutImageFormData(image: File, directory: string) {
    const IMAGE_FORM_DATA_FIELDS = {
        FILE: 'file',
        DIRECTORY: 'dir'
    }
    const formData = new FormData()

    formData.append(IMAGE_FORM_DATA_FIELDS.FILE, image)
    formData.append(IMAGE_FORM_DATA_FIELDS.DIRECTORY, directory)

    return formData
}