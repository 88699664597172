import React from 'react'
import { Col, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { PlainButton } from '~/code/POS/pages'
import { BundleListItemProps } from './props'
import translations from './translations'
import styles from '../../../../common/ProductListForm/components/AddProductForm/components/BundleListItem/BundleListItem.scss'
import { formatPriceWithCurrency } from '~/code/services/ui-helpers'
import { isEmpty } from 'dna-common'

export const BundleListItem = (props: BundleListItemProps) => {
    const {
        onDeleteClick
    } = props

    const {
        bundleCode,
        bundleDescription,
        bundleQuantity,
        bundleCustomPrice,
        acquirerAssignedTids
    } = props.bundleListItem

    return (
        <div className={classNames(styles.BundleListItem, {[styles.BundleListItem__highlighted]: props.isHighlighted})}>
            <Row gutter={16}>
                <Col xs={24} sm={6}>
                    <div className={styles.header}>{translations().bundle}</div>
                    <div className={styles.title}>{bundleCode}</div>
                </Col>
                <Col xs={24} sm={10}>
                    <div className={styles.header}>{translations().description}</div>
                    <div className={styles.title}>{bundleDescription}</div>
                </Col>
                <Col xs={24} sm={4}>
                    <div className={styles.header}>{translations().quantity}</div>
                    <div className={styles.title}>{bundleQuantity}</div>
                </Col>
                { bundleCustomPrice !== undefined && bundleCustomPrice !== null &&
                    <Col xs={24} sm={4}>
                        <div className={styles.header}>{translations().customPrice}</div>
                        <div className={styles.title}>{ formatPriceWithCurrency(bundleCustomPrice) }</div>
                    </Col>
                }
                { !isEmpty(acquirerAssignedTids) &&
                    <Col xs={24} sm={24}>
                        <div className={styles.header}>{translations().acquirerAssignedTids}</div>
                        { acquirerAssignedTids.map((tid) => <div className={styles.title}>{tid.tid}</div>) }
                    </Col>
                }
            </Row>
            <div className={styles.buttonsWrapper}>
                <PlainButton
                    icon={<DeleteOutlined/>}
                    text={translations().delete}
                    className={styles.deleteButton}
                    onClick={onDeleteClick}
                />
            </div>
        </div>
    )
}
