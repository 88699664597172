import React from 'react'
import translations from './translations'

translations.add('en', {
    generalSettings: 'General Settings',

    defaultTransactionType: 'Default transaction type',
    defaultTransactionTypeRequired: 'Default transaction type is required',
    sale: 'Sale',
    auth: 'Authorisation',

    acquirer: 'Acquirer',
    acquirerRequired: 'Acquirer is required',
    acquirerTooltip: 'An acquirer is the financial institution that processes credit or debit card payment processing services on behalf of the merchant. The acquirer will have issued a Merchant ID for card processing.',

    paymentChannelId: 'Payment channel id',
    paymentChannelIdRequired: 'Payment channel id is required',

    cardAcceptorNumber: 'Card acceptor number',
    cardAcceptorNumberRequired: 'Card acceptor number is required',

    merchantName: 'Merchant name',
    merchantNameRequired: 'Merchant name is required',

    merchantUrl: 'Merchant URL',
    merchantUrlRequired: 'Merchant URL is required',

    visaMid: 'Visa MID',
    visaMidRequired: 'Visa MID is required',

    mastercardMid: 'Mastercard MID',
    mastercardMidRequired: 'Mastercard MID is required',

    amexMid: 'Amex MID',
    unionPayMid: 'Union Pay MID',

    avsHouseMatrix: 'AVS house matrix',
    avsHouseMatrixRequired: 'AVS house matrix is required',

    avsPostcodeMatrix: 'AVS postcode matrix',
    avsPostcodeMatrixRequired: 'AVS postcode matrix is required',

    cscMatrix: 'CSC matrix',
    cscMatrixRequired: 'CSC matrix is required',

    paMatrix: 'PA matrix',
    paMatrixRequired: 'PA matrix is required',

    enableAvsForNonUKCustomers: 'Enable AVS for non UK customers',

    terminalDeviceInfo: 'Terminal Device Info',

    country: 'Country',

    max10digits: 'Max 10 digits',
    max2digits: 'Max 2 digits',
    max25digits: 'Max 25 digits',
    max25Chars: 'Max 25 characters',
    max20Chars: 'Max 20 characters',
    invalidUrl: 'Invalid URL',

    attributes: 'Attributes',
    capabilities: 'Capabilities',
    terminalType: 'Terminal type',
    attributesUsed: 'Attributes used',
    poiCapabilities: 'POI capabilities',
    capabilitiesUsed: 'Capabilities used'
})
