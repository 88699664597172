import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    bundle: string
    bundleRequired: string
    bundleTooltip: string
    bundleDescription: string
    acquirerAssignedTids: string
    noDescription: string
    quantity: string
    quantityRequired: string
    back: string
    nextStep: string
    submit: string
}>()

