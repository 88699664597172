import React from 'react'
import translations from './translations'

translations.add('en', {
    storeId: 'Store Id',
    store: 'Store',
    storeRequired: 'Store is required',
    departmentId: 'Department Id',
    department: 'Department',
    departmentRequired: 'Department is required',
    addressLine1: 'Address Line 1',
    postcode: 'Postcode'
})
