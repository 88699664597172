import { IDeviceTypeStore } from '~/code/ODIN/pages/DeviceManagement/IDeviceTypeStore'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { DeviceType } from '~/code/ODIN/stores/DeviceManagementStore/models/DeviceType'
import { fetchDeviceTypes } from '~/code/ODIN/stores/DeviceManagementStore/services/fetchers'
import { error } from 'dna-common'
import { SelectItem } from '~/code/common/components'
import { LARGE_PAGE_SIZE } from '~/code/models'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class DeviceTypeStore implements IDeviceTypeStore {
    constructor () {
        makeObservable(this, {
            isLoading: observable,
            _deviceTypes: observable,
            totalPageItems: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,
            manufacturer: observable,
            deviceTypes: computed,
            filteredByManufacturerDeviceTypes: computed,
            setDeviceTypes: action,
            loadDeviceTypes: action
        })
    }

    isLoading: boolean = false
    totalPageItems: number = 0
    totalCount: number = 0
    pageSize: number = LARGE_PAGE_SIZE
    pageNumber: number = 0
    manufacturer = ''
    _deviceTypes: DeviceType[] = []

    public get filteredByManufacturerDeviceTypes(): SelectItem[] {
        if (!this._deviceTypes.length && !this.isLoading) {
            this.pageNumber = 0
            this.loadDeviceTypes()
        }

        return this._deviceTypes
            .map(device => ({value: device.id, label: `${device.manufacturer} - ${device.model}`, key: device.id, params: device}))
            .filter(type => type.params.manufacturer === this.manufacturer)
    }

    public get deviceTypes(): SelectItem[] {
        if (!this._deviceTypes.length && !this.isLoading) {
            this.pageNumber = 0
            this.loadDeviceTypes()
        }

        return this._deviceTypes.map(device => ({value: device.id, label: `${device.manufacturer} - ${device.model}`, key: device.id, params: device}))
    }

    setDeviceTypes = (_deviceTypes: DeviceType[]) => {
        this._deviceTypes = _deviceTypes
    }

    loadDeviceTypes = (param?) => {
        if (this.isLoading) {
            return
        }

        this.pageNumber++

        this.isLoading = true

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            ...param
        }

        fetchDeviceTypes(queryParams)
            .then(response => {
                runInAction(() => {
                    const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                    const _deviceTypes = [...response?.result]
                    this.setDeviceTypes(_deviceTypes)
                    this.totalCount = pagination.totalCount
                    this.totalPageItems = pagination.totalPages
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
