import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import inputStyles from '~/code/common/components/Input/Input.scss'
import postalCodeStyles from '~/assets/styles/postal-code.scss'
import { PostalCode, PostalCodeProps } from 'dna-common'

export const PostCode = observer((props: PostalCodeProps) => {
    return <PostalCode {...props}
        inputClassName={classNames(inputStyles.Input, {[inputStyles.Input__disabled]: props.disabled})}
        dropDownClassName={postalCodeStyles.PostalCodeDropDown}
    />
})
