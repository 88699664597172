import React from 'react'
import translations from './translations'

translations.add('en', {
    departmentName: 'Department Name',
    departmentId: 'Department Id',
    visaMID: 'Visa MID',
    departmentType: 'Department Type',
    general: 'General',
    eCommerce: 'eCommerce',
    noValue: 'no value',
    noActionAvailable: <>Existing department</>,
    areYouSureToDeleteDepartment: <>Are you sure you want to delete the selected department?<br/>Once deleted, all of the attached bundles to the selected department will be deleted, too.</>,
    yesDelete: 'Yes, delete',
    cancelDelete: 'No, keep it',
    dnaEPayments: 'DNA Payments (eCommerce)',
    dnaPayments: 'DNA Paymnts',
    acquirer: 'Acquirer',
    new: 'new'
})
