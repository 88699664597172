export interface ActivationDetails {
    companyName?: string
    firstName?: string
    surname?: string
    email?: string
    useMainContact: boolean
}

export function createActivationDetails(): ActivationDetails {
    return {
        companyName: '',
        firstName: '',
        surname: '',
        email: '',
        useMainContact: false
    }
}
