import React from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { SvgTrusted } from '~/assets/icons'
import { ExistingIconProps } from './props'
import styles from './ExistingIcon.scss'

export const ExistingIcon: React.FC<ExistingIconProps> = ({ text, className }) => {
  return <Tooltip title={text}>
    <SvgTrusted className={classNames(styles.ExistingIcon, className)} />
  </Tooltip>
}