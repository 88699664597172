import React from 'react'
import classNames from 'classnames'
import styles from './GroupTitle.scss'
import { GroupTitleProps } from './props'

export const GroupTitle = (props: GroupTitleProps) => {
    return <div className={classNames(styles.GroupTitle, {[styles.GroupTitle__small]: props.size === 'small'})}>
        { props.content }
        {props.description && (
            <div className={styles.GroupTitle__description}>{ props.description }</div>
        )}
    </div>
}
