import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    merchantSchedules: string
    maintenanceSchedule: string
    deviceStatusSchedule: string
    inThisPeriodsDevicesAllowedToInstall: string
    settingsApplyToAllDevices: string
    daysWhenMaintenancePerformed: string
    monday: string
    tuesday: string
    wednesday: string
    thursday: string
    friday: string
    saturday: string
    sunday: string
    timeWhenMaintenancePerformed: string
    start: string
    end: string
    everyDayOfWeek: string
    save: string
    configSaved: string
    errorOnSavingConfig: string
    thisIsHowRegularlyYourDeviceProvide: string
    ethernet: string
    whenConnectedViaEthernetConnection: (value) => string
    whenConnectedViaWiFiConnection: (value) => string
    whenConnectedViaBluetoothConnection: (value) => string
    whenConnectedViaCellularConnection: (value) => string
    whenConnectedViaConnectionTypeEvery: (type, value, time) => string
    wiFi: string
    bluetooth: string
    cellular: string
    minutes: string
    hours: string
    days: string
    loading: string
}>()
