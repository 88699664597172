import React from 'react'
import * as localForage from 'localforage'
import moment from 'moment'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { create, persist } from 'mobx-persist'
import page from 'page'
import { get, get as getFromStore } from 'store-connector'
import { finishedLoading } from '~/code/index'
import { LoginStore } from '~/code/stores/LoginStore/LoginStore'
import { LoginResponse } from '~/code/common/pages/Login/models'
import { MainLayout as PosMainLayout } from '~/code/POS/layouts/MainLayout/MainLayout'
import { MainLayout as OdinMainLayout } from '~/code/ODIN/layouts/MainLayout/MainLayout'
import { Login, NewOrder, NotFound, OrderDetails, OrderHistory, UserGuide } from '~/code/POS/pages'
import { IHeaderStore } from '~/code/POS/layouts/MainLayout/components/Header/IHeaderStore'
import { decodeToken } from '~/code/services/jwt-parser'
import { NewOrderStore } from '~/code/stores/NewOrderStore/NewOrderStore'
import { AuthStore } from '~/code/stores/AuthStore/AuthStore'
import { OrderHistoryStore } from '~/code/stores/OrderHistoryStore/OrderHistoryStore'
import { OrderDetailsModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/models/OrderDetailsModel'
import { OrderListItemModel } from '~/code/POS/pages/OrderHistory/components/OrdersListView/components/OrderListItem/models/OrderListItemModel'
import { OrderInfo } from '~/code/stores/OrderHistoryStore/models/OrderInfo'
import { OrderDetailsStore } from '~/code/stores/OrderDetailsStore/OrderDetailsStore'
import { SideMenuStore } from '~/code/stores/SideMenuStore/SideMenuStore'
import { CompanySelectStore } from '~/code/stores/CompanySelectStore/CompanySelectStore'
import { OWNER_GROUPS, TIMEZONES } from '~/code/models/Constants'
import { COMPANY_TYPE } from '~/code/common/layouts/MainLayout/models/CompanyType'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'
import translations from './translations'
import { HeaderStore } from '~/code/stores/HeaderStore/HeaderStore'
import { INewOrderStore } from '~/code/POS/pages/NewOrder/INewOrderStore'
import { NewOrderISOStore } from '~/code/stores/NewOrderStore/iso/NewOrderISOStore'
import { NewOrderPartnerStore } from '~/code/stores/NewOrderStore/partner/NewOrderPartnerStore'
import { NewOrderEmployee } from '~/code/POS/pages/NewOrder/NewOrderEmployee'
import { NewOrder123SendStore } from '~/code/stores/NewOrderStore/123send/NewOrder123SendStore'
import { CurrenciesStore } from '~/code/stores/NewOrderStore/common/CurrenciesStore'
import { CountriesStore } from '~/code/stores/NewOrderStore/common/CountriesStore'
import { AcquirersStore } from '~/code/stores/NewOrderStore/common/AcquirersStore'
import { MerchantCategoryCodesStore } from '~/code/stores/NewOrderStore/common/MerchantCategoryCodesStore'
import { fetch123SendAcquirers } from '~/code/stores/NewOrderStore/services/fetchers'
import { CheckoutV3Onboarding } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/CheckoutV3Onboarding'
import { ICheckoutV3OnboardingStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/models/ICheckoutV3OnboardingStore'
import { CheckoutV3OnboardingStore } from '~/code/stores/CheckoutV3Onboarding/CheckoutV3OnboardingStore'
import { authorizeApolloClient } from '~/code/stores/CheckoutV3Onboarding/services/apollo-client'
import { IAppStore } from '~/code/IAppStore'
import { DeviceManagement } from '~/code/ODIN/pages/DeviceManagement'
import { IDeviceManagementStore } from '~/code/ODIN/pages/DeviceManagement/IDeviceManagementStore'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models/Constants'
import { LOCATION_POS_PATH_NAMES, POS_EMPLOYEE_SIDE_MENU_ITEMS, POS_SIDE_MENU_ITEMS } from '~/code/POS/constants'
import { MerchantSelection } from '~/code/ODIN/pages/MerchantSelection'
import { IMerchantSelectionStore } from '~/code/ODIN/pages/MerchantSelection/IMerchantSelectionStore'
import { MerchantSchedule } from '~/code/ODIN/pages/MerchantSchedule'
import { IMerchantScheduleStore } from '~/code/ODIN/pages/MerchantSchedule/IMerchantScheduleStore'
import { MerchantScheduleStore, MerchantSelectionStore, DeviceManagementStore } from '~/code/ODIN/stores'
import { getOdinEmployeeSideMenuItems } from '~/code/ODIN/models'
import { DeviceManagementDetails } from '~/code/ODIN/pages/DeviceManagementDetails'
import { DeviceManagementDetailsStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/DeviceManagementDetailsStore'
import { IDeviceManagementDetailsStore } from '~/code/ODIN/pages/DeviceManagementDetails/IDeviceManagementDetailsStore'
import { ApplicationManagement } from '~/code/ODIN/pages/ApplicationManagement'
import { IApplicationManagementStore } from '~/code/ODIN/pages/ApplicationManagement/IApplicationManagementStore'
import { ApplicationManagementStore } from '~/code/ODIN/stores/ApplicationManagementStore/ApplicationManagementStore'
import { ApplicationManagementDetails } from '~/code/ODIN/pages/ApplicationManagementDetails'
import { IApplicationManagementDetailsStore } from '~/code/ODIN/pages/ApplicationManagementDetails/IApplicationManagementDetailsStore'
import { ApplicationManagementDetailsStore } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/ApplicationManagementDetailsStore'
import { FirmwareManagement } from '~/code/ODIN/pages/FirmwareManagement'
import { IFirmwareManagementStore } from '~/code/ODIN/pages/FirmwareManagement/IFirmwareManagementStore'
import { FirmwareManagementStore } from '~/code/ODIN/stores/FirmwareManagementStore/FirmwareManagementStore'
import { FirmwareManagementDetails } from '~/code/ODIN/pages/FirmwareManagementDetails'
import { IFirmwareManagementDetailsStore } from '~/code/ODIN/pages/FirmwareManagementDetails/IFirmwareManagementDetailsStore'
import { FirmwareManagementDetailsStore } from '~/code/ODIN/stores/FirmwareManagementDetailsStore/FirmwareManagementDetailsStore'
import { NewOrderOTTEmployee } from '~/code/POS/pages/NewOrder/NewOrderOTTEmployee'

export const context = observable({
    pathname: '',
    previousPath: ''
})

page((ctx, next) => {
    runInAction(() => {
        context.previousPath = context.pathname
        context.pathname = ctx.pathname
    })
    next()
})

const hydrate = create({
    storage: localForage
})

class AppStore implements IAppStore {

    constructor () {
        makeObservable(this, {
            userInfo: observable,
            currentPageTitle: observable,
            isSideMenuVisible: observable,
            currentLayout: observable,
            currentPage: observable,
            newOrderPage: observable,
            headerStore: observable,
            internalIdentifier: computed,
            internalIdentifierEncoded: computed,
            shouldSelectCompany: computed,
            selectedOwnerGroup: computed,
            odinSelectedMerchant: computed,
            version: computed,
            reInitNewOrderStore: action,
            clear: action,
            setCurrentPage: action,
            wrapWithMainLayout: action,
            wrapAuthLayout: action,
            logOut: action,
            openLoginPage: action,
            showLoginPage: action,
            showPlaceOrderPage: action,
            showUserGuidePage: action,
            showOrderHistoryPage: action,
            showCheckoutV3Onboarding: action,
            showOrderDetailsPage: action,
            showNotFoundPage: action,
            showMerchantSelection: action,
            showDeviceManagement: action,
            showApplicationManagement: action,
            showDeviceManagementDetails: action,
            showApplicationManagementDetails: action,
            showFirmwareManagement: action,
            showFirmwareManagementDetails: action,
            showMerchantSchedule: action,
            onPosLogin: action,
            onOdinLogin: action,
            toggleSideMenu: action
        })

        this.loginStore = new LoginStore(this)
        this.orderHistoryStore = new OrderHistoryStore(this)
        this.authStore = new AuthStore(this, `/auth/authenticate/refresh`)
        this.odinAuthStore = new AuthStore(this, `/odin-auth/api/Authentication/refresh`)
        this.sideMenuStore = new SideMenuStore(
            this,
            get('portalType') === 'POS'
                ? POS_SIDE_MENU_ITEMS
                : getOdinEmployeeSideMenuItems(
                    this.odinSelectedMerchant,
                    this.odinCancelMerchant.bind(this),
                    this.userInfo?.permissions
                )
        )
        this.orderDetailsStore = new OrderDetailsStore(this.newOrderStore, this.orderHistoryStore)
        this.companySelectStore = new CompanySelectStore(this)
        this.headerStore = new HeaderStore(this)
        this.checkoutV3OnboardingStore = new CheckoutV3OnboardingStore()
        this.deviceManagementStore = new DeviceManagementStore(this)
        this.deviceManagementDetailsStore = new DeviceManagementDetailsStore(this)
        this.applicationManagementStore = new ApplicationManagementStore(this)
        this.applicationManagementDetailsStore = new ApplicationManagementDetailsStore(this)
        this.merchantSelectionStore = new MerchantSelectionStore(this)
        this.merchantScheduleStore = new MerchantScheduleStore(this)
        this.firmwareManagementStore = new FirmwareManagementStore(this)
        this.firmwareManagementDetailsStore = new FirmwareManagementDetailsStore(this)

        this.currenciesStore = new CurrenciesStore()
        this.countriesStore = new CountriesStore()
        this.acquirersStore = new AcquirersStore()
        this.merchantCategoryCodesStore = new MerchantCategoryCodesStore()

        const shouldHideSider = window.matchMedia('(max-width: 1090px)').matches
        if (shouldHideSider && this.isSideMenuVisible) {
            this.toggleSideMenu()
        }

        reaction( () => this.companyType(), () => {
            this.reInitNewOrderStore()
        })

        reaction(() => this.headerStore.selectedOwnerGroup, () => {
            this.reInitNewOrderStore()
        })

        Promise.all([
            hydrate('appStore', this),
            hydrate('detailsStore', this.orderDetailsStore),
            hydrate('companySelectStore', this.companySelectStore),
            hydrate('checkoutV3OnboardingStore', this.checkoutV3OnboardingStore),
            hydrate('merchantSelectionStore', this.merchantSelectionStore)
        ]).then(() => {
            // TODO redo it in a more elegant way
            if (this.userInfo?.ownerGroupId) {
                this.headerStore.setSelectedOwnerGroupById(this.userInfo?.ownerGroupId)
            }
            this.reInitNewOrderStore()
            finishedLoading()
        })

        reaction(() => this.odinSelectedMerchant, odinSelectedMerchant => {
            this.sideMenuStore.setItems(getOdinEmployeeSideMenuItems(odinSelectedMerchant, this.odinCancelMerchant.bind(this), this.userInfo?.permissions))
        })
    }

    public loginStore: LoginStore
    public newOrderStore: INewOrderStore
    public orderHistoryStore: OrderHistoryStore
    public orderDetailsStore: OrderDetailsStore
    public authStore: AuthStore
    public odinAuthStore: AuthStore
    public sideMenuStore: SideMenuStore
    public companySelectStore: CompanySelectStore
    public headerStore: IHeaderStore
    public checkoutV3OnboardingStore: ICheckoutV3OnboardingStore
    public deviceManagementStore: IDeviceManagementStore
    public deviceManagementDetailsStore: IDeviceManagementDetailsStore
    public applicationManagementStore: IApplicationManagementStore
    public firmwareManagementStore: IFirmwareManagementStore
    public firmwareManagementDetailsStore: IFirmwareManagementDetailsStore
    public applicationManagementDetailsStore: IApplicationManagementDetailsStore
    public merchantSelectionStore: IMerchantSelectionStore
    public merchantScheduleStore: IMerchantScheduleStore

    // helper stores
    public currenciesStore: CurrenciesStore
    public countriesStore: CountriesStore
    public acquirersStore: AcquirersStore
    public merchantCategoryCodesStore: MerchantCategoryCodesStore


    @persist('object', UserInfo)
    public userInfo: UserInfo

    @persist
    public currentPageTitle: string

    @persist
    public isSideMenuVisible: boolean = true

    get version() {
        return _IS_PROD_ ? '' : _LAST_COMMIT_HASH_
    }

    public currentLayout: ( {children}: {children?: any} ) => JSX.Element = () => null

    public currentPage: () => JSX.Element = () => null

    get internalIdentifier(): string {
        if (this.companySelectStore.selectedEmployeeCompany?.identifier) {
            return this.companySelectStore.selectedEmployeeCompany?.identifier
        }

        return ''
    }

    get internalIdentifierEncoded(): string {
        if (this.internalIdentifier) {
            return encodeURIComponent(this.companySelectStore.selectedEmployeeCompany?.identifier || '')
        }

        return ''
    }

    get shouldSelectCompany(): boolean {
        return this.userInfo?.userType === 'employee' && this.headerStore?.selectedOwnerGroup?.id === OWNER_GROUPS.optomany
    }

    get selectedOwnerGroup() {
        return this.headerStore?.selectedOwnerGroup
    }

    get odinSelectedMerchant() {
        return this.merchantSelectionStore?.selectedMerchant
    }

    private authLayout = ({ children }: { children?: any }) => {
        return <> {children} </>
    }

    private mainLayout = ({ children }: { children?: any }) => {
        if (get('portalType') === 'POS') {
            return <PosMainLayout store={this} authStore={this.authStore} sideMenuStore={this.sideMenuStore} companySelectStore={this.companySelectStore}> {children} </PosMainLayout>
        } else {
            return <OdinMainLayout store={this} authStore={this.odinAuthStore} sideMenuStore={this.sideMenuStore}> {children} </OdinMainLayout>
        }
    }

    private authorize(route: () => void) {
        if (this.isAuthenticated()) return route()
        this.openLoginPage()
    }

    companyType = () => {
        return COMPANY_TYPE[this.companySelectStore.selectedItem?.additionalInfo?.companyTypeId] || this?.userInfo?.companyType
    }

    newOrderPage = <NewOrder/>

    reInitNewOrderStore = () => {
        if (this.userInfo?.ownerGroupId === OWNER_GROUPS.optomany) {
            switch (this.companyType()) {
                case 'iso': {
                    this.newOrderStore = new NewOrderISOStore()
                    break
                }
                default: {
                    this.newOrderStore = new NewOrderPartnerStore()
                }
            }
            this.acquirersStore = new AcquirersStore()
            this.newOrderPage = this.userInfo?.userType === 'employee' ? <NewOrderEmployee/> : <NewOrder/>
        } else if (this.userInfo?.ownerGroupId === OWNER_GROUPS['123send']) {
            this.newOrderStore = new NewOrder123SendStore()
            this.acquirersStore = new AcquirersStore(fetch123SendAcquirers)
            this.newOrderPage = <NewOrderOTTEmployee/>
        } else {
            this.newOrderStore = null
            this.newOrderPage = null
        }

        if (this.newOrderStore !== null && this.newOrderStore !== undefined) {
            (this.newOrderStore as NewOrderStore).generateCreateOrderSteps()
            this.orderDetailsStore.placeOrderStore = this.newOrderStore
        }

        this.reInitSideMenu()
    }

    reInitSideMenu = () => {
        if (get('portalType') === 'ODIN') {
            this.sideMenuStore = new SideMenuStore(this, getOdinEmployeeSideMenuItems(this.odinSelectedMerchant, this.odinCancelMerchant.bind(this), this.userInfo?.permissions))

            return
        }

        if (this.userInfo?.userType === 'employee') {
            this.sideMenuStore = new SideMenuStore(this, POS_EMPLOYEE_SIDE_MENU_ITEMS)
        } else {
            this.sideMenuStore = new SideMenuStore(this, POS_SIDE_MENU_ITEMS)
        }
    }

    clear = () => {
        localStorage.clear()
        this.loginStore = new LoginStore(this)
        this.orderHistoryStore = new OrderHistoryStore(this)
        this.isSideMenuVisible = !window.matchMedia('(max-width: 1090px)').matches
        this.userInfo = {
            accountNumber: null,
            companyName: null,
            email: null,
            firstName: null,
            surname: null,
            userType: null,
            companyType: null,
            timezone: 'uk',
            ownerGroupId: null,
            preventOrdering: false
        }
        this.merchantSelectionStore.clear()
        this.applicationManagementStore = new ApplicationManagementStore(this)
        this.firmwareManagementStore = new FirmwareManagementStore(this)
        this.deviceManagementDetailsStore = new DeviceManagementDetailsStore(this)
        this.orderDetailsStore.clear()
        this.orderDetailsStore.placeOrderStore = this.newOrderStore
        this.companySelectStore.clear()
        {(this.headerStore as HeaderStore).clear()}
        this.checkoutV3OnboardingStore.clear()
    }

    public setCurrentPage(currentPage: () => JSX.Element, currentLayout: ({children}: {children?: any}) => JSX.Element, pageTitle: string) {
        this.currentLayout = currentLayout
        this.currentPage = currentPage
        this.currentPageTitle = pageTitle
    }

    wrapWithMainLayout(component: () => JSX.Element, pageTitle: string) {
        this.authorize(() => {
            this.setCurrentPage(component, this.mainLayout, pageTitle)
        })
    }

    wrapAuthLayout(component: () => JSX.Element, pageTitle: string) {
        this.setCurrentPage(component, this.authLayout, pageTitle)
    }

    logOut = () => {
        this.clear()
        this.openLoginPage()
    }

    openLoginPage = () => page(LOCATION_POS_PATH_NAMES.LOGIN)

    showLoginPage () {
        if (this.isAuthenticated() && get('portalType') === 'POS') {
            page.redirect(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
            return
        }

        if (this.isAuthenticated() && get('portalType') === 'ODIN') {
            page.redirect(LOCATION_ODIN_PATH_NAMES.MERCHANT_SELECTION)
            return
        }

        this.wrapAuthLayout(() => <Login store={this.loginStore}/>, translations().loginPageTitle)
    }

    showPlaceOrderPage () {
        // TODO depending on the authorized user type the new order page should be rendered differently
        this.wrapWithMainLayout(() => this.newOrderPage, translations().placeOrderPageTitle)
    }

    showUserGuidePage () {
        this.wrapWithMainLayout(() => <UserGuide />, translations().userGuidePageTitle)
    }

    showOrderHistoryPage () {
        if (context.pathname !== context.previousPath) {
            this.orderHistoryStore.loadOrdersIfEmpty()
            this.wrapWithMainLayout(() => <OrderHistory store={this.orderHistoryStore}/>, translations().orderHistoryPageTitle)
        }
    }

    showCheckoutV3Onboarding () {
        this.wrapWithMainLayout(() => <CheckoutV3Onboarding />, translations().checkoutV3OnboardingPageTitle)
    }

    showOrderDetailsPage (orderReference: string) {
        if (context.pathname !== context.previousPath) {
            const order: OrderListItemModel = this.orderHistoryStore?.orders?.find(item => item.orderReference === orderReference)
            if (order) {
                const addInfo: OrderInfo = order.additionalInfo
                const lastModifiedDateTime = new Date(addInfo.lastModifiedDateTime)
                const orderDetails: OrderDetailsModel = {
                    ...order,
                    isExistingMerchant: order.isExistingMerchant,
                    mid: addInfo.details[0]?.mid,
                    acquirerName: addInfo.details[0]?.acquirerName,
                    lastModifiedDateTime: moment(lastModifiedDateTime).format('DD.MM.YYYY HH:mm:ss'),
                    merchantDetails: addInfo.details[0]?.merchantName,
                    merchantAddress: `${addInfo.details[0].deliveryTown} ${addInfo.details[0].deliveryPostcode}`
                }
                this.orderDetailsStore.order = orderDetails
                this.orderDetailsStore.configurationReport = null
                this.orderDetailsStore.dispatchReport = null
                this.orderDetailsStore.errorInfo = null
                this.wrapWithMainLayout(() => <OrderDetails store={this.orderDetailsStore}/>, translations().orderDetailsPageTitle)
            } else if (this.orderDetailsStore.order) {
                this.wrapWithMainLayout(() => <OrderDetails store={this.orderDetailsStore}/>, translations().orderDetailsPageTitle)
            } else {
                this.showNotFoundPage()
            }
        }
    }

    showNotFoundPage () {
        if (this.isAuthenticated()) {
            return this.wrapWithMainLayout(() => <NotFound />, translations().notFoundPageTitle)
        }
        this.wrapAuthLayout(() => <NotFound />, translations().notFoundPageTitle)
    }

    showMerchantSelection () {
        this.wrapWithMainLayout(() => <MerchantSelection store={this.merchantSelectionStore} />, translations().merchantSelectionPageTitle)
    }

    showDeviceManagement () {
        this.wrapWithMainLayout(() => <DeviceManagement store={this.deviceManagementStore} />, translations().deviceManagementPageTitle)
    }

    showDeviceManagementDetails (id: number) {
        this.wrapWithMainLayout(() => <DeviceManagementDetails id={id} store={this.deviceManagementDetailsStore} />, translations().deviceManagementDetailsPageTitle)
    }

    showApplicationManagement () {
        this.wrapWithMainLayout(() => <ApplicationManagement store={this.applicationManagementStore} />, translations().applicationManagementPageTitle)
    }

    showApplicationManagementDetails (id: number) {
        this.wrapWithMainLayout(() => <ApplicationManagementDetails id={id} store={this.applicationManagementDetailsStore} />, translations().applicationManagementDetailsPageTitle)
    }

    showFirmwareManagement () {
        this.wrapWithMainLayout(() => <FirmwareManagement store={this.firmwareManagementStore} />, translations().firmwareManagementPageTitle)
    }

    showFirmwareManagementDetails (id: number) {
        this.wrapWithMainLayout(() => <FirmwareManagementDetails id={id} store={this.firmwareManagementDetailsStore} />, translations().firmwareManagementPageTitle)
    }

    showMerchantSchedule () {
        this.wrapWithMainLayout(() => <MerchantSchedule store={this.merchantScheduleStore} />, translations().merchantSchedulePageTitle)
    }

    onPosLogin (loginResponse: LoginResponse) {
        const tokenData = decodeToken(loginResponse.accessToken)
        const currentUser = JSON.parse(tokenData['Optomany.CurrentUser'])
        const preventOrdering = JSON.parse(tokenData['Optomany.PreventOrdering'])
        const merchantsAtStoreLevel = JSON.parse(tokenData['Optomany.MerchantsAtStoreLevel'])
        const userInfo: UserInfo = {
            userId: currentUser.userId,
            firstName: currentUser.firstName,
            surname: currentUser.surname,
            userType: currentUser.isEmployee ? 'employee' : 'none',
            companyType: COMPANY_TYPE[currentUser.companyTypeId],
            email: currentUser.emailAddress,
            companyName: currentUser.companyName,
            accountNumber: currentUser.accountNumber,
            timezone: TIMEZONES[currentUser.timeZone] || 'uk',
            ownerGroupId: currentUser.ownerGroupId,
            preventOrdering,
            merchantsAtStoreLevel
        }
        this.userInfo = userInfo
        const { accessToken, refreshToken, expiresIn } = loginResponse
        this.authStore.saveAndPlanRefreshAuthCredentials(accessToken, refreshToken, expiresIn)

        if (this.userInfo.userType === 'employee') {
            this.headerStore.determineAndSelectOwnerGroup(currentUser.emailAddress)
            authorizeApolloClient()
        }

        // TODO this needs to be somewhere else a function that initializes appropriate stores when company type & owner group change
        this.reInitNewOrderStore()
        page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
    }

    onOdinLogin (loginResponse: LoginResponse) {
        const { accessToken, refreshToken, expiresIn } = loginResponse
        const tokenData = decodeToken(accessToken)
        const userInfo: UserInfo = {
            userId: tokenData['currentUser.userId'],
            firstName: tokenData['currentUser.firstName'],
            surname: tokenData['currentUser.surname'],
            userType: tokenData['currentUser.isEmployee'] ? 'employee' : 'none',
            email: tokenData['currentUser.emailAddress'][0],
            timezone: 'uk',
            ownerGroupId: tokenData['currentUser.ownerGroupId'],
            permissions: tokenData['permissions']
        }

        this.userInfo = userInfo
        this.odinCancelMerchant()

        this.odinAuthStore.saveAndPlanRefreshAuthCredentials(accessToken, refreshToken, expiresIn)

        this.reInitSideMenu()
        page(LOCATION_ODIN_PATH_NAMES.MERCHANT_SELECTION)
    }

    toggleSideMenu () {
        this.isSideMenuVisible = !this.isSideMenuVisible
    }

    isAuthenticated = () => {
        const accessToken = getFromStore('accessToken')
        const expiresAt = getFromStore('expiresAt')
        return accessToken && expiresAt && moment() < moment(expiresAt)
    }

    odinCancelMerchant() {
        this.merchantSelectionStore.setSelectedMerchant(null)
        this.deviceManagementDetailsStore = new DeviceManagementDetailsStore(this)
    }
}

const AppStoreInstance = new AppStore()

export { AppStoreInstance as AppStore }

export const AppStoreContext = React.createContext(AppStoreInstance)
