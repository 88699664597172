import React from 'react'
import { action, computed, makeObservable, observable } from 'mobx'
import { ICheckoutV3OnboardingStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/models/ICheckoutV3OnboardingStore'
import { SearchItem, SelectItem } from '~/code/common/components'
import { OnboardingDetailsStepKey, OnboardingStep } from './models'
import { OnboardingDetailsStep } from '~/code/stores/CheckoutV3Onboarding/models/OnboardingDetailsStep'
import formTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/translations'
import { StoreAndDepartmentSelectionForm } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/StoreAndDepartmentSelectionForm/StoreAndDepartmentSelectionForm'
import { persist } from 'mobx-persist'
import { log } from 'dna-common'
import { createApolloClient } from '~/code/stores/CheckoutV3Onboarding/services/apollo-client'
import { IStoreAndDepartmentSelectionFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/StoreAndDepartmentSelectionForm/models'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { StoreAndDepartmentSelectionFormStore } from '~/code/stores/CheckoutV3Onboarding/stores/StoreAndDepartmentSelectionFormStore'
import { MerchantDetailsForm } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/MerchantDetailsForm/MerchantDetailsForm'
import { createMerchantDetailsFormData, IMerchantDetailsFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/MerchantDetailsForm/models'
import { MerchantDetailsFormStore } from '~/code/stores/CheckoutV3Onboarding/stores/MerchantDetailsFormStore/MerchantDetailsFormStore'
import { createPenniesFormData, IPenniesFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/PenniesForm/models'
import { PenniesFormStore } from '~/code/stores/CheckoutV3Onboarding/stores/PenniesFormStore/PenniesFormStore'
import { PenniesForm } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/PenniesForm'
import { TransactionLimitsForm } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm'
import { TransactionLimitsFormStore } from '~/code/stores/CheckoutV3Onboarding/stores/TransactionLimitsFormStore'
import { createTransactionLimitsFormData, ITransactionLimitsFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm/models'
import { createCheckoutSettingsFormData, ICheckoutSettingsFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/models'
import { CheckoutSettingsFormStore } from '~/code/stores/CheckoutV3Onboarding/stores/CheckoutSettingsFormStore'
import { CheckoutSettingsForm } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm'
import {
    fetchMerchants,
    fetchMerchantStores,
    submitOnboardingRequest
} from '~/code/stores/CheckoutV3Onboarding/services/fetchers'
import {
    createOnboardingRequestFromData,
    storeAndDepartmentDataToMerchantDetailsFormData
} from '~/code/stores/CheckoutV3Onboarding/services/data-mappers'
import { createFakeTerminalSettingsFormData, ITerminalSettingsFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm/models'
import { TerminalSettingsFormStore } from '~/code/stores/CheckoutV3Onboarding/stores/TerminalSettingsFormStore'
import { TerminalSettingsForm } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm'
import { error } from 'dna-common'
import { RequestSummaryStore } from '~/code/stores/CheckoutV3Onboarding/stores/RequestSummaryStore'
import {
    IRequestSummaryStore,
    RequestSummary
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/RequestSummary'
import { createRequestSummary } from '~/code/stores/CheckoutV3Onboarding/services/summary-data-mappers'
import { AppStore } from '~/code/AppStore'
import { IProgressStore } from '~/code/POS/pages/NewOrder/components/common/Progress/IProgressStore'
import { ProgressStore } from '~/code/stores/CheckoutV3Onboarding/stores/ProgressStore/ProgressStore'
import { Progress } from '~/code/POS/pages'

export class CheckoutV3OnboardingStore implements ICheckoutV3OnboardingStore, IFormSubmitHandler {

    constructor () {
        makeObservable(this, {
            hideMerchantRequirementWarning: observable,
            currentStep: observable,
            currentOnboardingDetailsStep: observable,
            _manuallyEnteredMerchantName: observable,
            isLoading: observable,
            placingOrder: observable,
            shouldShowForm: observable,
            selectedMerchant: observable,
            isSearching: observable,
            items: observable,
            selectedItem: observable,
            selectedMerchantName: computed,
            initiatorEmail: computed,
            clear: action,
            setHideMerchantRequirementWarning: action,
            setManuallyEnteredCompanyName: action,
            generateOnboardingSteps: action,
            generateOnboardingDetailsSteps: action,
            search: action,
            selectItem: action,
            setItems: action,
            clearAndSetUp: action,
            loadMerchantStores: action,
            handleFormSubmit: action,
            handleGoBack: action,
            submitOrder: action
        })

        this.clear()
        this.onboardingSteps = this.generateOnboardingSteps()
        this.onboardingDetailsSteps = this.generateOnboardingDetailsSteps()
    }

    // apollo client
    client = createApolloClient()

    // stores
    storeAndDepartmentSelectionFormStore: IStoreAndDepartmentSelectionFormStore = new StoreAndDepartmentSelectionFormStore(this)
    merchantDetailsFormStore: IMerchantDetailsFormStore = new MerchantDetailsFormStore(this)
    terminalSettingsFormStore: ITerminalSettingsFormStore = new TerminalSettingsFormStore(this)
    penniesFormStore: IPenniesFormStore = new PenniesFormStore(this)
    transactionLimitsFormStore: ITransactionLimitsFormStore = new TransactionLimitsFormStore(this)
    checkoutSettingsFormStore: ICheckoutSettingsFormStore = new CheckoutSettingsFormStore(this)
    requestSummaryStore: IRequestSummaryStore = new RequestSummaryStore(this)
    progressStore: IProgressStore = new ProgressStore(this)

    // forms
    storeAndDepartmentSelectionForm = <StoreAndDepartmentSelectionForm store={this.storeAndDepartmentSelectionFormStore}/>
    merchantDetailsForm = <MerchantDetailsForm store={this.merchantDetailsFormStore}/>
    terminalSettingsForm = <TerminalSettingsForm store={this.terminalSettingsFormStore}/>
    penniesForm = <PenniesForm store={this.penniesFormStore}/>
    transactionLimitsForm = <TransactionLimitsForm store={this.transactionLimitsFormStore}/>
    checkoutSettingsForm = <CheckoutSettingsForm store={this.checkoutSettingsFormStore}/>

    // child components
    requestSummary = <RequestSummary store={this.requestSummaryStore}/>
    progress = <Progress store={this.progressStore}/>

    @persist
    hideMerchantRequirementWarning: boolean = false

    currentStep: number = 0

    currentOnboardingDetailsStep: number = 0

    _manuallyEnteredMerchantName: string = ''

    onboardingSteps: OnboardingStep[]

    onboardingDetailsSteps: OnboardingDetailsStep[]

    isLoading: boolean = false // general loading

    placingOrder: boolean = false

    shouldShowForm: boolean = false

    // Search and Search Bar stores
    isSearching: boolean

    items: SearchItem[] = []

    selectedItem: SearchItem

    selectedMerchant: SelectItem

    get selectedMerchantName() {
        return this._manuallyEnteredMerchantName || this.selectedItem?.additionalInfo?.name
    }

    get initiatorEmail(): string {
        return AppStore?.userInfo?.email
    }

    @action
    clear = () => {
        this.currentStep = 0
        this.currentOnboardingDetailsStep = 0
        this.shouldShowForm = false
        this.hideMerchantRequirementWarning = false
        this._manuallyEnteredMerchantName = ''
        this.selectedItem = null
        this.items = []
    }

    setHideMerchantRequirementWarning = (value: boolean) => {
        this.hideMerchantRequirementWarning = value
    }

    setManuallyEnteredCompanyName(value: string) {
        this._manuallyEnteredMerchantName = value
    }

    setShouldShowForm = (value: boolean) => {
        //  TODO
    }

    setCurrentStep = (step: number) => {
        // TODO
    }

    generateOnboardingSteps(): OnboardingStep[] {
        return  [
            {title: formTranslations().onboardingDetails, key: 'onboardingDetails', children: null},
            {title: formTranslations().requestSummary, key: RequestSummaryStore.stepName, children: this.requestSummary},
            {title: formTranslations().progress, key: ProgressStore.stepName, children: this.progress}
        ]
    }

    generateOnboardingDetailsSteps(): OnboardingDetailsStep[] {
        return [
            {order: 1, title: formTranslations().storeAndDepartmentSelection, key: StoreAndDepartmentSelectionFormStore.stepName, children: this.storeAndDepartmentSelectionForm},
            {order: 2, title: formTranslations().merchantDetails, key: MerchantDetailsFormStore.stepName, children: this.merchantDetailsForm},
            {order: 3, title: formTranslations().terminalSettings, key: TerminalSettingsFormStore.stepName, children: this.terminalSettingsForm},
            {order: 4, title: formTranslations().transactionLimits, key: TransactionLimitsFormStore.stepName, children: this.transactionLimitsForm},
            {order: 5, title: formTranslations().pennies, key: PenniesFormStore.stepName, children: this.penniesForm},
            {order: 6, title: formTranslations().checkoutSettings, key: CheckoutSettingsFormStore.stepName, children: this.checkoutSettingsForm}
        ]
    }

    search (value?): void {
        if (value && value.length >= 3 ) {
            this.isSearching = true
            fetchMerchants(value, this.client)
                .then( result => {
                    this.items = result.data?.merchants?.nodes?.map(merchant => {
                        const description = `${formTranslations().cn}: ${merchant.merchantId}`
                        return {identifier: merchant.merchantId, title: merchant.name, description, hiddenDescription: merchant.company_number, additionalInfo: merchant}
                    })
                })
                .finally(() => {
                    this.isSearching = false
                })
        }
    }

    selectItem (value: string) {
        const tempSelectedItem = this.items.find(item => item.identifier === value)
        this.clearAndSetUp()
        this.shouldShowForm = true
        this.items = []
        this.selectedItem = tempSelectedItem
        {(this.storeAndDepartmentSelectionFormStore as StoreAndDepartmentSelectionFormStore).setStores([])}

        // set stores of the selected merchant
        this.loadMerchantStores()
            .then(result =>
                { (this.storeAndDepartmentSelectionFormStore as StoreAndDepartmentSelectionFormStore).setStores(result) })
            .then(() => {
                (this.terminalSettingsFormStore as TerminalSettingsFormStore).terminalSettingsFormData.optomanyPlatform.acquirerId = (this.storeAndDepartmentSelectionFormStore as StoreAndDepartmentSelectionFormStore).stores[0].params.departments[0].params.allowedCardSchemes[0].acquirerId
            })
    }

    setItems (items: SearchItem[]) {
        this.items = items
    }

    clearAndSetUp() {
        this.clear()
        this.merchantDetailsFormStore.merchantDetailsFormData = {...createMerchantDetailsFormData(), initiatorEmail: this.initiatorEmail}
        this.terminalSettingsFormStore.terminalSettingsFormData = createFakeTerminalSettingsFormData()
        this.penniesFormStore.penniesFormData = createPenniesFormData()
        this.transactionLimitsFormStore.transactionLimitsFormData = createTransactionLimitsFormData()
        this.checkoutSettingsFormStore.checkoutSettingsFormData = createCheckoutSettingsFormData()
        this.onboardingSteps = this.generateOnboardingSteps()
        this.onboardingDetailsSteps = this.generateOnboardingDetailsSteps()
        this.currentOnboardingDetailsStep = 0
        this.currentStep = 0
    }

    loadMerchantStores = async () => {
        this.storeAndDepartmentSelectionFormStore.isLoadingStores = true
        const result = await fetchMerchantStores(Number(this.selectedItem.identifier), this.client)
        if (result?.error) {
            return []
        }
        this.storeAndDepartmentSelectionFormStore.isLoadingStores = false
        return  result?.data?.merchants?.nodes[0]?.merchantStores?.nodes?.map(merchantStore => {
            return { label: merchantStore.name, value: merchantStore.merchantStoreId, params: merchantStore }
        }) || []
    }

    handleFormSubmit = (data?: any, stepName?: OnboardingDetailsStepKey | OnboardingStep) => {
        switch (stepName) {
            case StoreAndDepartmentSelectionFormStore.stepName: {
                const storeData = this.storeAndDepartmentSelectionFormStore.stores.find(store => store.value === data.storeId).params
                const departmentData = storeData.departments.find(department => department.value === data.departmentId).params
                this.merchantDetailsFormStore.merchantDetailsFormData = {
                    ...this.merchantDetailsFormStore.merchantDetailsFormData,
                    ...storeAndDepartmentDataToMerchantDetailsFormData(this.selectedItem.title, storeData, departmentData)
                }
                break
            }
            case CheckoutSettingsFormStore.stepName: {
                this.requestSummaryStore.requestSummaryData = createRequestSummary(
                    this.storeAndDepartmentSelectionFormStore.storeAndDepartmentSelectionData,
                    this.merchantDetailsFormStore.merchantDetailsFormData,
                    this.terminalSettingsFormStore.terminalSettingsFormData,
                    this.transactionLimitsFormStore.transactionLimitsFormData,
                    this.penniesFormStore.penniesFormData,
                    this.checkoutSettingsFormStore.checkoutSettingsFormData,
                    this.storeAndDepartmentSelectionFormStore.stores,
                    AppStore.countriesStore.countries,
                    AppStore.merchantCategoryCodesStore.merchantCategoryCodes,
                    AppStore.acquirersStore.acquirers
                )
                this.currentStep++
                return
            }
            case RequestSummaryStore.stepName: {
                this.currentStep++
                this.submitOrder()
                return
            }
        }

        this.currentOnboardingDetailsStep++
    }

    handleGoBack = (stepName) => {
        if (stepName === ProgressStore.stepName) {
            this.currentStep = 0
            this.currentOnboardingDetailsStep = 0
        }
        if (this.onboardingSteps[this.currentStep].key !== 'onboardingDetails') {
            this.currentStep--
        } else {
            this.currentOnboardingDetailsStep--
        }
    }

    submitOrder() {
        this.progressStore.status = 'loading'

        const request = createOnboardingRequestFromData(
            this.storeAndDepartmentSelectionFormStore.storeAndDepartmentSelectionData,
            this.merchantDetailsFormStore.merchantDetailsFormData,
            this.terminalSettingsFormStore.terminalSettingsFormData,
            this.transactionLimitsFormStore.transactionLimitsFormData,
            this.penniesFormStore.penniesFormData,
            this.checkoutSettingsFormStore.checkoutSettingsFormData
        )
        submitOnboardingRequest(request)
        .then(result => {
            log('Result: ', result)
            this.progressStore.status = 'success'
        }).catch(err => {
            error('Error: ', err.message)
            this.progressStore.status = 'failure';
            (this.progressStore as ProgressStore).setErrorCodeAndMessage('-1', err.message)
        }).finally(() => {
            this.placingOrder = false
        })
    }
}
