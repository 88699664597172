// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HuReBqlwK0YZJPiQ32qNR {\n  padding: 0; }\n\n.ga8zHWL21K-VxUk-ADr6n.ga8zHWL21K-VxUk-ADr6n {\n  margin-bottom: 0; }\n\n._1CHJuCzvlAC4P9kUT11-nQ {\n  width: 100px;\n  height: 100px; }\n\n._1Q-9wlQ4MDzQ1x5KvjWMGw {\n  display: flex;\n  flex-direction: column; }\n\n._1H7jVyVPLxowzpOqOHNtjx {\n  padding: 0; }\n\n._19B5ZrQgxdG7N0h7QZxJeI {\n  font-size: 24px;\n  height: 24px; }\n\n._2Ci9l1RXU1BGZRhO5PWclu {\n  color: #cc0000; }\n\n._1GUChU9XH4zpkqAzyC4Jfp {\n  color: #3CB371; }\n\n._32A089IlbdVXsa675o5Az1 {\n  color: #00BFFF; }\n\n._1bFRJcCDY6QzvYCulNIMu8 {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  ._1bFRJcCDY6QzvYCulNIMu8:hover {\n    cursor: pointer; }\n\n._34rg8SVMRCQ5PWAipJjGut {\n  cursor: pointer; }\n  ._34rg8SVMRCQ5PWAipJjGut:hover {\n    background: #eaeaea;\n    border-radius: 4px; }\n\n._2m5gebBHwAv3FwnyeO7_od {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 5px; }\n  ._2m5gebBHwAv3FwnyeO7_od .ant-space-item {\n    height: 27px; }\n\n.s_xeW4tbpVKkdnXQrcNpQ {\n  color: #f5222d; }\n  .s_xeW4tbpVKkdnXQrcNpQ path {\n    fill: #f5222d; }\n\n._1WXLx5TlXS86qjiXi22HYC {\n  color: #3CB371; }\n  ._1WXLx5TlXS86qjiXi22HYC path {\n    fill: #3CB371; }\n\n._3VJq7aBkG1QqaK195Qd1yL {\n  color: #e28800; }\n  ._3VJq7aBkG1QqaK195Qd1yL path {\n    fill: #e28800; }\n\n._2HJ9VpCMmoDREMRQFA2N3P {\n  margin-right: 8px; }\n\n._8vFwSClhjR4dn2euSCzYQ {\n  display: flex;\n  justify-content: space-between; }\n\n._1ALvMvqY9msvU4S5sWCJIz {\n  margin-right: 8px; }\n\n.RqQjJ8TKu0yjGfdx2pDN6 {\n  margin-right: 8px; }\n\n._1KypswRSBwHPq8EWrUpUvT {\n  display: flex;\n  flex-direction: column;\n  align-items: end; }\n  ._1KypswRSBwHPq8EWrUpUvT .ant-btn {\n    width: 250px;\n    margin-bottom: 8px; }\n", ""]);
// Exports
exports.locals = {
	"pageHeader": "HuReBqlwK0YZJPiQ32qNR",
	"title": "ga8zHWL21K-VxUk-ADr6n",
	"avatar": "_1CHJuCzvlAC4P9kUT11-nQ",
	"lastCommunication": "_1Q-9wlQ4MDzQ1x5KvjWMGw",
	"link": "_1H7jVyVPLxowzpOqOHNtjx",
	"icon": "_19B5ZrQgxdG7N0h7QZxJeI",
	"uninstallIcon": "_2Ci9l1RXU1BGZRhO5PWclu",
	"launcherIcon": "_1GUChU9XH4zpkqAzyC4Jfp",
	"setLauncherIcon": "_32A089IlbdVXsa675o5Az1",
	"actions": "_1bFRJcCDY6QzvYCulNIMu8",
	"battery": "_34rg8SVMRCQ5PWAipJjGut",
	"deviceIconData": "_2m5gebBHwAv3FwnyeO7_od",
	"iconRed": "s_xeW4tbpVKkdnXQrcNpQ",
	"iconGreen": "_1WXLx5TlXS86qjiXi22HYC",
	"iconOrange": "_3VJq7aBkG1QqaK195Qd1yL",
	"backButton": "_2HJ9VpCMmoDREMRQFA2N3P",
	"pushAppHeader": "_8vFwSClhjR4dn2euSCzYQ",
	"restartButton": "_1ALvMvqY9msvU4S5sWCJIz",
	"cancel": "RqQjJ8TKu0yjGfdx2pDN6",
	"extra": "_1KypswRSBwHPq8EWrUpUvT"
};
module.exports = exports;
