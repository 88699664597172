import React from 'react'
import translations from './translations'

translations.add('en', {
    companyName: 'Company name',
    orderRef: 'Order ref',
    orderPlacedOn: 'Order placed on',
    orderPlacedBy: 'Order placed by',
    deliverTo: 'Deliver to',
    bundles: 'Bundles',
    status: 'Status'
})
