import { CloseSquareFilled } from '@ant-design/icons'
import React from 'react'
import { ISideMenuItemProps } from './props'
import styles from './SideMenuItemTitle.scss'

export const SideMenuItemTitle: React.FC<ISideMenuItemProps> = ({ text, onCancelClick }) => {
    return <div className={styles.container}>
        <div className={styles.text}>{text}</div>
        <CloseSquareFilled onClick={onCancelClick} />
    </div>
}