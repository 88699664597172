import React from 'react'
import translations from './translations'

translations.add('en', {
    loading: 'Please wait',
    failure: 'Failure',
    failedToLoad: 'Failed to load',
    loadingInProgress: 'Loading order history...',
    retry: 'Retry'
})
