// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2kgRcTVSftI2b9FBZ5t863 :hover {\n  cursor: text; }\n", ""]);
// Exports
exports.locals = {
	"filtersSelect": "_2kgRcTVSftI2b9FBZ5t863"
};
module.exports = exports;
