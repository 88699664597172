import { IOrderTypeFormStore } from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/IOrderTypeFormStore'
import { SelectItem } from '~/code/common/components'
import { createOrderTypeFormData, OrderType, OrderTypeFormData } from '~/code/POS/pages'
import orderTypeTranslations from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/translations/translations'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { LARGE_PAGE_SIZE } from '~/code/models'
import { isEmpty } from 'dna-common'
import { AppStore } from '~/code/AppStore'
import { fetchMerchantDepartments, fetchMerchantStores } from '~/code/stores/NewOrderStore/services/fetchers'
import { log } from 'dna-common'

export class OrderTypeFormStore implements IOrderTypeFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            orderTypeFormData: observable,
            orderTypes: observable,
            _stores: observable,
            _departments: observable,
            isLoadingDepartments: observable,
            isLoadingStores: observable,
            selectedOrderType: observable,
            selectedDepartmentId: observable,
            selectedStoreId: observable,
            canGoBack: observable,
            stores: computed,
            departments: computed,
            selectedStore: computed,
            selectedDepartment: computed,
            loadStores: action,
            loadDepartments: action,
            onStoreChange: action,
            onDepartmentChange: action,
            setOrderType: action,
            setOrderTypeFormData: action,
            clearStoresAndDepartments: action,
            clearDepartments: action,
            goBack: action
        })

        this.orderTypeFormData = createOrderTypeFormData()
        this.orderTypes = [
            {label: orderTypeTranslations().newBundle, value: 'newBundle' as OrderType},
            // {label: orderTypeTranslations().newDepartment, value: 'newDepartment' as OrderType},
            {label: orderTypeTranslations().newStoreAndDepartment, value: 'newStoreAndDepartment' as OrderType}
        ]

        reaction(() => this.selectedStoreId, () => {
            this.clearDepartments()
            this.loadDepartments()
        }, { fireImmediately: false })
    }

    static stepName: string = 'orderType'

    orderTypeFormData: OrderTypeFormData
    orderTypes: SelectItem[]

    _stores: SelectItem[] = []

    _departments: SelectItem[] = []

    isLoadingDepartments: boolean = false

    isLoadingStores: boolean = false

    selectedOrderType: OrderType = 'newBundle'

    selectedDepartmentId: number = 0

    selectedStoreId: number = 0

    canGoBack: boolean = false

    get stores(): SelectItem[] {
        this.loadStores()
        return this._stores
    }

    get departments(): SelectItem[] {
        this.loadDepartments()
        return this._departments
    }

    get selectedStore() {
        return  this._stores?.find(item => item.value === this.selectedStoreId)?.params || null
    }

    get selectedDepartment() {
        return  this._departments?.find(item => item.value === this.selectedDepartmentId)?.params  || null
    }

    currentPageForStores = 0
    currentPageForDepartments = 0

    lastResponseSizeForStores = -1
    lastResponseSizeForDepartments = -1

    loadStores = async () => {
        if ((this.lastResponseSizeForStores < LARGE_PAGE_SIZE && this.lastResponseSizeForStores > 0) || this.isLoadingStores || isEmpty(AppStore.newOrderStore?.selectedCompany?.merchantId)) {
            return
        }

        if (isEmpty(this._stores)) {
            this.currentPageForStores = 0
        }

        await runInAction(async () => {
            this.currentPageForStores++
            this.isLoadingStores = true

            const internalIdentifier = AppStore.internalIdentifierEncoded ? `&internalIdentifier=${AppStore.internalIdentifierEncoded}` : ''
            const responseStores = await fetchMerchantStores(this.currentPageForStores, LARGE_PAGE_SIZE, AppStore.newOrderStore?.selectedCompany?.merchantId, internalIdentifier)
            this.lastResponseSizeForStores = responseStores.length || 0
            this._stores = [...this._stores, ...responseStores]
            if (!this.selectedStoreId) {
                this.selectedStoreId = this._stores[0].value
            }
            this.isLoadingStores = false

            // load departments
            this.loadDepartments()
        })
    }

    loadDepartments = async () => {
        if ((this.lastResponseSizeForDepartments < LARGE_PAGE_SIZE && this.lastResponseSizeForDepartments > 0) || this.isLoadingDepartments || !this.selectedStoreId) {
            return
        }

        if (isEmpty(this._departments)) {
            this.currentPageForDepartments = 0
        }

        await runInAction(async () => {
            this.currentPageForDepartments++
            this.isLoadingDepartments = true

            const internalIdentifier = AppStore.internalIdentifierEncoded ? `&internalIdentifier=${AppStore.internalIdentifierEncoded}` : ''
            const responseDepartments = await fetchMerchantDepartments (this.currentPageForDepartments, LARGE_PAGE_SIZE, this.selectedStoreId, internalIdentifier)
            this.lastResponseSizeForDepartments = responseDepartments.length || 0
            this._departments = [...this._departments, ...responseDepartments]
            if (!this.selectedDepartmentId && this._departments.length > 0) {
                this.selectedDepartmentId = this._departments[0].value
            }
            this.isLoadingDepartments = false
        })
    }

    onStoreChange = (storeId) => {
        if (storeId !== this.selectedStoreId) {
            this.selectedStoreId = storeId
        }
    }

    onDepartmentChange = (departmentId) => {
        this.selectedDepartmentId = departmentId
    }

    setOrderType = (orderType: OrderType) => {
        this.selectedOrderType = orderType
        this.orderTypeFormData.storeId = null
        this.orderTypeFormData.departmentId = null

        if (orderType === 'newStoreAndDepartment') {
            this.selectedStoreId = null
            this.selectedDepartmentId = null
            // this.informationFormData = { // TODO remove later if tests pass without this logic
            //     ...this.informationFormData,
            //     ...createInformationFormData()
            // }
        } else if (orderType === 'newDepartment') {
            this.selectedStoreId = this._stores[0]?.value || null
        } else {
            this.selectedStoreId = this._stores[0]?.value || null
            // this.informationFormData = { // TODO remove later if tests pass without this logic
            //     ...this.informationFormData,
            //     ...this.selectedStoreInformation
            // }
        }
    }

    setOrderTypeFormData = (data: OrderTypeFormData) => {
        log(this.orderTypeFormData)
        this.orderTypeFormData = {...this.orderTypeFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.orderTypeFormData, OrderTypeFormStore.stepName)
    }

    clearStoresAndDepartments = () => {
        this._stores = []
        this.currentPageForStores = 0
        this.lastResponseSizeForStores = -1
        this.selectedStoreId = null
        this.isLoadingStores = false
        this.clearDepartments()
    }

    clearDepartments = () => {
        this._departments = []
        this.lastResponseSizeForDepartments = -1
        this.currentPageForDepartments = 0
        this.isLoadingDepartments = false
        this.selectedDepartmentId = null
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(OrderTypeFormStore.stepName)
    }
}
