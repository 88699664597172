import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    updateApp: string
    cancel: string
    packageVersionRequired: string
    appDisplayName: string
    appDeveloper: string
    install: string
    selectPackageVersion: string
    searchPackageNameOrDisplayName: string
}>()
