import React from 'react'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { AppCategoriesStore } from '~/code/ODIN/stores/ApplicationManagementStore/AppCategoriesStore'
import { IAppCategoriesStore } from '~/code/ODIN/pages/ApplicationManagement/IAppCategoriesStore'
import { DeviceManufacturerStore } from '~/code/ODIN/stores/ApplicationManagementStore/DeviceManufacturerStore'
import { IDeviceManufacturerStore } from '~/code/ODIN/pages/ApplicationManagement/IDeviceManufacturerStore'
import { get as getFromStore } from 'store-connector'
import { UploadNewApp } from '~/code/ODIN/stores/ApplicationManagementStore/models/UploadNewApp'
import { message } from 'antd'
import { IDeviceTypeStore } from '~/code/ODIN/pages/DeviceManagement/IDeviceTypeStore'
import { DeviceTypeStore } from '~/code/ODIN/stores/DeviceManagementStore/DeviceTypeStore'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import { IAddNewApplicationStore } from '~/code/ODIN/pages/ApplicationManagement/components/AddNewApplication/IAddNewApplicationStore'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import page from 'page'
import { AppDeveloperStore } from '~/code/ODIN/stores/ApplicationManagementStore/AppDeveloperStore'
import { IAppDeveloperStore } from '~/code/ODIN/pages/ApplicationManagement/IAppDeveloperStore'
import { prepareFormData } from '~/code/ODIN/stores/ApplicationManagementStore/services/utils'

export class AddNewApplicationStore implements IAddNewApplicationStore {
    constructor (type: PackageType) {
        makeObservable(this, {
            isLoading: observable,
            isAppUploadModalOpen: observable,
            packageVersionId: observable,
            permissions: computed,
            setIsAppUploadModalOpen: action,
            setPackageVersionId: action,
            uploadNewApp: action
        })

        this.appCategoriesStore = new AppCategoriesStore()
        this.deviceManufacturerStore = new DeviceManufacturerStore()
        this.deviceTypesStore = new DeviceTypeStore()
        this.appDevelopersStore = new AppDeveloperStore()
        this.packageType = type
    }

    parentStore: IAppStore
    appCategoriesStore: IAppCategoriesStore
    deviceManufacturerStore: IDeviceManufacturerStore
    deviceTypesStore: IDeviceTypeStore
    appDevelopersStore: IAppDeveloperStore
    isLoading: boolean = false
    isAppUploadModalOpen: boolean = false
    packageVersionId = null
    packageType = null

    public get permissions(): string[] {
        return this.parentStore?.userInfo?.permissions
    }

    setIsAppUploadModalOpen (isAppUploadModalOpen: boolean): void {
        this.isAppUploadModalOpen = isAppUploadModalOpen
    }

    setPackageVersionId (packageVersionId: number): void {
        this.packageVersionId = packageVersionId
    }

    public uploadNewApp = (url: string, values: UploadNewApp): Promise<any> => {
        const headers = []
        const accessToken = getFromStore('accessToken')
        headers.push(['Authorization', `Bearer ${accessToken}`])

        this.isLoading = true

        const formData = prepareFormData(values)

        formData.append('PackageTypeId', String(this.packageType))

        return fetch(url, {
            method: 'POST',
            body: formData,
            headers
        })
            .then(res => res.json())
            .then(res => {
                if (res.status >= 300 && !res?.errors?.length) {
                    message.error(res.title)
                    throw new Error()
                }

                if (res?.errors?.length) {
                    const errors = res?.errors?.map(error => <div key={error.errorMessage}>{`${error.propertyName} - ${error.errorMessage}`}</div>)
                    message.error(<>{errors}</>)
                    throw new Error()
                } else {
                    runInAction(() => {
                        this.packageVersionId = res.packageVersionId
                    })
                    this.setIsAppUploadModalOpen(true)
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
