// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq {\n  padding: 21px; }\n  @media (max-width: 390px) {\n    ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq {\n      padding: 11px; } }\n  ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq > div {\n    margin-bottom: 20px; }\n  ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq > div:last-child {\n    margin-bottom: 5px; }\n  ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq ._3x8x2keAmbbQTInDK3ByH5 {\n    line-height: normal;\n    font-size: 22px;\n    text-align: center;\n    letter-spacing: 0.4px; }\n  ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq ._1sAOi7cq5teLo27Ewhl2pU {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    flex-wrap: wrap;\n    line-height: 60px; }\n    @media (max-width: 365px) {\n      ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq ._1sAOi7cq5teLo27Ewhl2pU {\n        justify-content: center; } }\n  ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq .lFUBqDw6V3qWqqnekbvJf {\n    text-align: center; }\n  ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq ._2hdDzVKlWa1gQ6mWSC6hag {\n    width: 100%; }\n    ._2onMQTAcL7HVj6QkPGhEWJ ._26JQOOga2BuiYoubtx32zq ._2hdDzVKlWa1gQ6mWSC6hag > div {\n      margin-right: auto;\n      margin-left: auto;\n      display: block;\n      width: 120px; }\n", ""]);
// Exports
exports.locals = {
	"ModalIdleWarning": "_2onMQTAcL7HVj6QkPGhEWJ",
	"idleTimerModal": "_26JQOOga2BuiYoubtx32zq",
	"title": "_3x8x2keAmbbQTInDK3ByH5",
	"buttonsWrapper": "_1sAOi7cq5teLo27Ewhl2pU",
	"content": "lFUBqDw6V3qWqqnekbvJf",
	"countDown": "_2hdDzVKlWa1gQ6mWSC6hag"
};
module.exports = exports;
