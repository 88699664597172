import { ConfigurationReportModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/components/ConfigurationReport/models/ConfigurationReportModel'
import { ConfigurationReportResponse } from '~/code/stores/OrderDetailsStore/models/ConfigurationReportResponse'
import { isEmpty } from 'dna-common'

export function configurationReportResponseToConfigurationReportModel (response: ConfigurationReportResponse): ConfigurationReportModel {
    if (isEmpty(response) || isEmpty(response.results) ) {
        return  null
    }

    const configReport: ConfigurationReportModel = {
     ...response.results[0]
    }

    return configReport
}
