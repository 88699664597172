import { action, makeObservable, observable } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { ISystemInfoModalStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/SystemInfoModal/ISystemInfoModalStore'
import { SystemInfoStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/SystemInfoStore'
import { PaymentsStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/PaymentsStore'
import { CommunicationDetailsStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/CommunicationDetailsStore'
import { ApnDetailsStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/ApnDetailsStore'

export class SystemInfoModalStore implements ISystemInfoModalStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isSystemInfoModalOpen: observable,
            setIsSystemInfoModalOpen: action
        })

        this.parentStore = parentStore
        this.systemInfoStore = new SystemInfoStore(parentStore)
        this.paymentsStore = new PaymentsStore(parentStore)
        this.communicationDetailsStore = new CommunicationDetailsStore(parentStore)
        this.apnDetailsStore = new ApnDetailsStore(parentStore)
    }

    parentStore: IAppStore
    systemInfoStore: SystemInfoStore
    paymentsStore: PaymentsStore
    communicationDetailsStore: CommunicationDetailsStore
    apnDetailsStore: ApnDetailsStore
    isSystemInfoModalOpen: boolean = false

    setIsSystemInfoModalOpen = (isSystemInfoModalOpen: boolean) => {
        this.isSystemInfoModalOpen = isSystemInfoModalOpen
    }
}
