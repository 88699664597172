import React from 'react'
import { action, computed, makeObservable, observable } from 'mobx'
import { ArrowLeftOutlined } from '@ant-design/icons/lib'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import page from 'page'
import { SvgSadFace } from '~/assets/icons'
import { Button } from '~/code/common/components'
import { IProgressStore } from '~/code/POS/pages/NewOrder/components/common/Progress/IProgressStore'
import { ProgressType } from '~/code/POS/pages/NewOrder/components/common/Progress/models/ProgressType'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import translations from './translations'
import styles from './styles.scss'
import { OnboardingStepKey } from '~/code/stores/CheckoutV3Onboarding/models'

export class ProgressStore implements IProgressStore {
    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            errorCode: observable,
            errorMessage: observable,
            status: observable,
            icon: computed,
            title: computed,
            messages: computed,
            actionsContent: computed,
            setStatus: action,
            setErrorCodeAndMessage: action,
            goToRequestHistory: action,
            reviewOrder: action
        })
        this.status = 'loading'
    }

    static stepName: OnboardingStepKey = 'onboardingSubmission'

    public errorCode: string = ''

    public errorMessage: any = ''

    status

    get icon(): JSX.Element {
        switch (this.status) {
            case 'success': {
                return <CheckOutlined/>
            }
            case 'failure': {
                return <SvgSadFace />
            }
            default: {
                return <LoadingOutlined/>
            }
        }
    }

    get title(): string {
        switch (this.status) {
            case 'success': {
                return translations().success
            }
            case 'failure': {
                return translations().sorry
            }
            default: {
                return translations().pleaseWait
            }
        }
    }

    get messages(): any[] {
        switch (this.status) {
            case 'success': {
                return [
                    translations().requestHasBeenSubmitted,
                    translations().trackRequestStatusInRequestHistory
                ]
            }
            case 'failure': {
                return [
                    translations().unableToSubmitRequest,
                    <>{this.errorMessage}</>,
                    translations().pleaseContactUs
                ]
            }
            default: {
                return [
                    translations().submittingRequest
                ]
            }
        }
    }

    get actionsContent(): JSX.Element {
        return <>
            { this.status === 'success' &&
            <Button onClick={() => this.goToRequestHistory()}>
                <ArrowLeftOutlined /> {translations().requestHistory}
            </Button>
            }
            { this.status === 'failure' &&
            <div className={classNames(styles.failureButtonsWrapper, styles.centered)}>
                {
                    <Button outline={'outlined'} onClick={() => this.reviewOrder()}>
                        {translations().reviewOrder}
                    </Button>
                }
            </div>
            }
        </>
    }

    public setStatus(status: ProgressType) {
        this.status = status
    }

    public setErrorCodeAndMessage(errorCode: string, message: any) {
        this.errorCode = `${errorCode}`
        this.errorMessage = message
    }

    goToRequestHistory = () => {
        this.formSubmitHandler.clear()
        page('/employee-functions/checkout-v3-onboarding/request-history')
    }

    reviewOrder = () => {
        this.formSubmitHandler.handleGoBack(ProgressStore.stepName)
    }

}
