import React from 'react'
import { observer } from 'mobx-react'
import { SummaryBlock } from '~/code/common/components'
import { dispatchReportModelToSummaryBlockData } from './services'
import { DispatchReportProps } from './props'

export const DispatchReport = observer(({report}: DispatchReportProps) => {
    return <div>
        {
            report.map((item, index) => {
                return <div key={`${item.orderReference}${index}`}>
                    <SummaryBlock data={dispatchReportModelToSummaryBlockData(item)}/>
                </div>
            })
        }
    </div>
})
