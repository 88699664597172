// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Adt_wGhoO1f-gnLnS7xC2 {\n  width: 100%; }\n  ._1Adt_wGhoO1f-gnLnS7xC2 .ant-input {\n    height: 147px;\n    max-height: 147px;\n    background: #FFFFFF;\n    border: 1px solid #BABABA;\n    border-radius: 4px;\n    color: #000;\n    font-family: \"Open Sans\"; }\n  ._3MT8AVuu7jOB7hz14SHCIi .ant-input-affix-wrapper .ant-input:not(:first-child) {\n    padding-left: 44px; }\n  .ASHILRg7tjB3GK5ewYLSW .ant-input {\n    background: #EDEDED; }\n\n.ant-form-explain {\n  font-family: \"Open Sans\"; }\n\n.ant-form-item-label {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 16px;\n  letter-spacing: 0.333333px;\n  color: #8C8C8C;\n  text-transform: none;\n  padding-bottom: 5px; }\n  .ant-form-item-label label {\n    color: #8C8C8C; }\n", ""]);
// Exports
exports.locals = {
	"TextArea": "_1Adt_wGhoO1f-gnLnS7xC2",
	"TextArea__prefixed": "_3MT8AVuu7jOB7hz14SHCIi",
	"TextArea__disabled": "ASHILRg7tjB3GK5ewYLSW"
};
module.exports = exports;
