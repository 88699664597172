import React from 'react'
import { action, computed, makeObservable, observable } from 'mobx'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { INewOrderStore } from '~/code/POS/pages/NewOrder/INewOrderStore'
import { IProgressStore } from '~/code/POS/pages/NewOrder/components/common/Progress/IProgressStore'
import { SvgSadFace } from '~/assets/icons'
import { ProgressType } from '~/code/POS/pages/NewOrder/components/common/Progress/models/ProgressType'
import { Button, CopyText, Label } from '~/code/common/components'
import { ArrowLeftOutlined } from '@ant-design/icons/lib'
import newOrderTranslations from '~/code/POS/pages/NewOrder/translations'
import translations from './translations'
import styles from './styles.scss'

const ErrorMessage: React.FC<{}> = (props) => (
    <p>{newOrderTranslations().error}: <Label type={'error'} className={styles.wordBreakAll}>{props.children}</Label></p>
)

export class ProgressStore implements IProgressStore {
    constructor (parentStore: INewOrderStore) {
        makeObservable(this, {
            errorCode: observable,
            errorMessage: observable,
            status: observable,
            icon: computed,
            title: computed,
            messages: computed,
            actionsContent: computed,
            canGoBack: computed,
            setStatus: action,
            setErrorCodeAndMessage: action
        })

        this.parentStore = parentStore
        this.status = 'loading'
    }

    private parentStore: INewOrderStore

    public errorCode: string = ''

    public errorMessage: any = ''

    status

    get icon(): JSX.Element {
        switch (this.status) {
            case 'success': {
                return <CheckOutlined/>
            }
            case 'failure': {
                return <SvgSadFace />
            }
            default: {
                return <LoadingOutlined/>
            }
        }
    }

    get title(): string {
        switch (this.status) {
            case 'success': {
                return newOrderTranslations().success
            }
            case 'failure': {
                return newOrderTranslations().sorry
            }
            default: {
                return newOrderTranslations().pleaseWait
            }
        }
    }

    get renderOrderReferenceLabel(): JSX.Element {
        return <Label type={'info'}><CopyText iconClassName={ styles.orderReferenceCopyTextIcon } text={ this.parentStore.orderReference }/></Label>
    }

    get messages(): any[] {
        switch (this.status) {
            case 'success': {
                return [
                    newOrderTranslations().orderHasBeenPlaced,
                    this.parentStore.orderReference ?
                        <p>{newOrderTranslations().orderReference}: { this.renderOrderReferenceLabel } </p> :
                        null,
                    newOrderTranslations().trackOrderInOrderHistory
                ]
            }
            case 'failure': {
                return [
                    newOrderTranslations().unableToPlaceOrder,
                    this.parentStore.orderReference ?
                        <p>{newOrderTranslations().orderReference}: { this.renderOrderReferenceLabel } </p> :
                        null,
                    <>{this.errorMessage}</>,
                    newOrderTranslations().pleaseContactUs
                ]
            }
            default: {
                return [
                    newOrderTranslations().placingOrder
                ]
            }
        }
    }

    get actionsContent(): JSX.Element {
        return <>
            { this.status === 'success' &&
            <Button onClick={() => this.goToOrderHistory()}>
                <ArrowLeftOutlined /> {translations().orderHistory}
            </Button>
            }
            { this.status === 'failure' &&
            <div className={`${styles.failureButtonsWrapper} ${!this.canGoBack ? styles.centered : ''}`}>
                {
                    this.canGoBack &&
                    <Button outline={'outlined'} onClick={() => this.goBackToInformation()}>
                        {translations().reviewOrder}
                    </Button>
                }
                <Button onClick={() => this.goToContactUs()}>
                    {translations().contactUs}
                </Button>
            </div>
            }
        </>
    }

    get canGoBack(): boolean {
        return this.errorCode === '1' || this.errorCode === '20'
    }

    public setStatus(status: ProgressType) {
        this.status = status
    }

    public setErrorCodeAndMessage(errorCode: string, message: any) {
        this.errorCode = `${errorCode}`
        switch (errorCode) {
            case '19':
                this.errorMessage = <ErrorMessage>{newOrderTranslations().error19Text} - ER19</ErrorMessage>
                break
            case '20':
                this.errorMessage = <ErrorMessage>{newOrderTranslations().error20Text} - ER20</ErrorMessage>
                break
            default:
                this.errorMessage = message && Array.isArray(message) ? message.map(item => {
                    const errCode = Number(item.errorCode) < 10 ? `0${Number(item.errorCode)}` : item.errorCode
                    return <ErrorMessage>{`${item.property.replace('Orders[0].', '')} - ER${errCode}`}</ErrorMessage>
                }) : null
        }
    }

    goToContactUs () {
        this.parentStore.goToContactUs()
    }

    goToOrderHistory () {
        this.parentStore.goToOrderHistory()
    }

    goBackToInformation () {
        this.parentStore.setCurrentStep(this.parentStore.goBackToInformationReviewStep) // 'information'
    }

}
