import React from 'react'
import translations from './translations'

translations.add('en', {
    applicationManagement: 'Application Details',
    latestApplicationInfo: 'Latest Application Info',
    uploadNewVersion: 'Upload New Version',
    category: 'Category',
    installationPackage: 'Installation Package',
    email: 'Email',
    appSignedBy: 'App Signed By',
    versionHistory: 'Version History',
    developerName: 'Developer Name',
    packageName: 'Package Name',
    deviceTypes: 'Device Types'
})
