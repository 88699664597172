import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Spin, Form } from 'antd'
import { isEmpty } from 'dna-common'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { ACQUIRER_IDS, getFieldValue } from '~/code/services'
import { Input, LabelWithTooltip } from '~/code/common/components'
import { BundleSelect } from '~/code/POS/pages/NewOrder/components/common/BundleSelect'
import { ONLY_DIGITS_PATTERN } from '~/code/models/Patterns'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { AcquirerAssignedTidList } from '~/code/POS/pages/NewOrder/components/common/AcquirerAssignedTidList'
import { STANDARD_MID_LENGTH } from '~/code/models'
import { acquirerTidHelpers } from '~/code/POS/pages/NewOrder/services/utils'
import { BUNDLE_ACQUIRER_REQUIREMENT_TYPE } from '~/code/POS/pages/NewOrder/services/constants'
import translations from './translations'
import { ProductsFormProps } from './props'
import styles from './ProductsForm.scss'

export const ProductsForm = observer(({ store }: ProductsFormProps) => {
    const {
        productsFormData: data, setProductsFormData, bundles, loadBundles, canGoBack, goBack, acquirerAssignedTids, setAcquirerAssignedTids,
        acquirerId, merchantId, selectedDepartment, totalTIDsList, setTotalTIDsList, setIsTIDDuplicateExist
    } = store
    const [form] = Form.useForm()

    const [selectedBundle, setBundle] = useState<{bundleCode, description, acquirerRequiredTids}>(bundles?.find(item => item.value === data.bundleCode)?.params)

    const [acquirerAssignedTIDValidateStatus, setAcquirerAssignedTIDValidateStatus] = useState<ValidateStatus[]>([])
    const { handleAcquirerAssignedTidChange, initiateAcquirerAssignedTids, onTIDBlur } = acquirerTidHelpers({
        acquirerAssignedTids, acquirerAssignedTIDValidateStatus, setAcquirerAssignedTIDValidateStatus, setAcquirerAssignedTids, selectedDepartment
    })

    const onFinish = (values) => {
        setProductsFormData(values)
    }

    const getFieldInitialValue = (fieldName) => {
        return getFieldValue(data, fieldName)
    }

    const onBundleChange = (value) => {
        const bundle = bundles.find(item => item.value === value)
        setBundle(bundle.params)
    }

    useEffect(() => {
        const _selectedBundle = bundles?.find(item => item.value === data.bundleCode)?.params
        form.setFieldsValue({
            bundleCode: _selectedBundle?.bundleCode
        })
        setBundle(_selectedBundle)
    }, [bundles])

    useEffect(() => {
        setTotalTIDsList([])
    }, [acquirerAssignedTids])

    const _shouldShowTIDFormItem = () => {
        const isDNAPaymentsAcquirer = +acquirerId === ACQUIRER_IDS.DNA_PAYMENTS
        const isDnaMID = merchantId.length !== STANDARD_MID_LENGTH
        const isTidRequired = selectedBundle?.acquirerRequiredTids === BUNDLE_ACQUIRER_REQUIREMENT_TYPE.DEVICE_TID
            || selectedBundle?.acquirerRequiredTids === BUNDLE_ACQUIRER_REQUIREMENT_TYPE.CNP_TID

        return ((isDNAPaymentsAcquirer && isDnaMID) || selectedDepartment?.acquiresWithDna) && isTidRequired
    }

    return (
        <div className={styles.ProductsForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().bundle} tooltipText={translations().bundleTooltip} tooltipPosition={'right'} />}
                                name={'bundleCode'}
                                initialValue={selectedBundle?.bundleCode}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().bundleRequired
                                    }
                                ]}
                            >
                                <BundleSelect
                                    placeholder={translations().bundle}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    notFoundContent={<Spin size={'small'}/>}
                                    onFocus={loadBundles}
                                    selectItems={bundles.slice()}
                                    onChange={onBundleChange}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().bundleDescription}
                                name={'bundleDescription'}
                            >
                                <div className={styles.label}>{selectedBundle?.description || translations().noDescription}</div>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().quantity}
                                name={'quantity'}
                                initialValue={getFieldInitialValue('quantity')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const onlyNumberRegexp = ONLY_DIGITS_PATTERN
                                    const value = e.currentTarget.value
                                    _shouldShowTIDFormItem() && initiateAcquirerAssignedTids(+value)
                                    if (!onlyNumberRegexp.test(value) && !isEmpty(value)) {
                                        return form.getFieldValue('quantity')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().quantityRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().quantity}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                { _shouldShowTIDFormItem() &&
                    <Row justify={'space-between'}>
                        <AcquirerAssignedTidList
                            sizes={{ xs: 24, sm: 10 }}
                            totalTIDsList={totalTIDsList}
                            acquirerAssignedTids={store.acquirerAssignedTids}
                            acquirerAssignedTIDValidateStatus={acquirerAssignedTIDValidateStatus}
                            handleAcquirerAssignedTidChange={handleAcquirerAssignedTidChange}
                            setIsTIDDuplicateExist={setIsTIDDuplicateExist}
                            onTIDBlur={onTIDBlur}
                            required={true}
                        />
                    </Row>
                }
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
