import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useForm } from 'antd/es/form/Form'
import { Col, Form, Row } from 'antd'
import { TransactionLimitsFormProps } from './props'
import { getFieldInitialValue } from '~/code/services'
import translations from './translations'
import styles from './TransactionLimitsForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { InputWithCheckbox } from './components/InputWithCheckbox/InputWithCheckbox'
import { transactionLimitInputValidator } from './services'

const getFormInitialValues = data => {
    return {
        dailyValue: getFieldInitialValue(data, 'dailyValue', 0),
        dailyVolume: getFieldInitialValue(data, 'dailyVolume', 0),
        monthlyValue: getFieldInitialValue(data, 'monthlyValue', 0),
        monthlyVolume: getFieldInitialValue(data, 'monthlyVolume', 0),
        individualTransactionValue: getFieldInitialValue(data, 'individualTransactionValue', 0),
        maxRefundValue: getFieldInitialValue(data, 'maxRefundValue', 0)
    }
}

export const TransactionLimitsForm = observer(({ store }: TransactionLimitsFormProps) => {
    const { transactionLimitsFormData: data, setTransactionLimitsFormData, canGoBack, goBack } = store
    const [form] = useForm()

    useEffect(() => {
        form.setFieldsValue(getFormInitialValues(data))
    }, [form, data])

    const onFinish = (values) => {
        setTransactionLimitsFormData(values)
    }

    const formInitialValues = getFormInitialValues(data)

    return <div className={styles.TransactionLimitsForm}>
        <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={formInitialValues}>
            <Row justify={'space-between'}>
                <Col xs={24} md={10}>
                    <Form.Item
                        label={translations().dailyTransactionLimitByValue}
                        name={'dailyValue'}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    transactionLimitInputValidator(value)
                                }
                            }
                        ]}
                    >
                        <InputWithCheckbox
                            inputPlaceholder={translations().dailyTransactionLimitByValue}
                            uncheckedDefaultValue={''}
                            checkedValue={0}
                            checkBoxTitle={translations().unlimited}
                            checkBoxChecked={data.dailyValue === 0}
                            inputType={'number'}
                        />
                    </Form.Item>
                    <Form.Item
                        label={translations().dailyTransactionLimitByVolume}
                        name={'dailyVolume'}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    transactionLimitInputValidator(value)
                                }
                            }
                        ]}
                    >
                        <InputWithCheckbox
                            inputPlaceholder={translations().dailyTransactionLimitByVolume}
                            uncheckedDefaultValue={''}
                            checkedValue={0}
                            checkBoxTitle={translations().unlimited}
                            checkBoxChecked={data.dailyVolume === 0}
                            inputType={'number'}
                        />
                    </Form.Item>
                    <Form.Item
                        label={translations().monthlyTransactionLimitByValue}
                        name={'monthlyValue'}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    transactionLimitInputValidator(value)
                                }
                            }
                        ]}
                    >
                        <InputWithCheckbox
                            inputPlaceholder={translations().monthlyTransactionLimitByValue}
                            uncheckedDefaultValue={''}
                            checkedValue={0}
                            checkBoxTitle={translations().unlimited}
                            checkBoxChecked={data.monthlyValue === 0}
                            inputType={'number'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={10}>
                    <Form.Item
                        label={translations().monthlyTransactionLimitByVolume}
                        name={'monthlyVolume'}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    transactionLimitInputValidator(value)
                                }
                            }
                        ]}
                    >
                        <InputWithCheckbox
                            inputPlaceholder={translations().monthlyTransactionLimitByVolume}
                            uncheckedDefaultValue={''}
                            checkedValue={0}
                            checkBoxTitle={translations().unlimited}
                            checkBoxChecked={data.monthlyVolume === 0}
                            inputType={'number'}
                        />
                    </Form.Item>
                    <Form.Item
                        label={translations().maximumSingleTransactionByValue}
                        name={'individualTransactionValue'}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    transactionLimitInputValidator(value)
                                }
                            }
                        ]}
                    >
                        <InputWithCheckbox
                            inputPlaceholder={translations().maximumSingleTransactionByValue}
                            uncheckedDefaultValue={''}
                            checkedValue={0}
                            checkBoxTitle={translations().unlimited}
                            checkBoxChecked={data.individualTransactionValue === 0}
                            inputType={'number'}
                        />
                    </Form.Item>
                    <Form.Item
                        label={translations().maximumRefundByValue}
                        name={'maxRefundValue'}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    transactionLimitInputValidator(value)
                                }
                            }
                        ]}
                    >
                        <InputWithCheckbox
                            inputPlaceholder={translations().maximumRefundByValue}
                            uncheckedDefaultValue={''}
                            checkedValue={0}
                            checkBoxTitle={translations().unlimited}
                            checkBoxChecked={data.maxRefundValue === 0}
                            inputType={'number'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
        </Form>
    </div>
})
