// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2j42NmmyAepCeUnADRlzL4 {\n  padding-top: 16px; }\n\n.-udpW_ssB_kR64eAHp-AW {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n\n.xVMUT2M8uDtT4aZmKviZy ._3fDaRkVwA1zUF7fr9hdOaY {\n  width: 100%;\n  padding-top: 20px; }\n\n.xVMUT2M8uDtT4aZmKviZy ._2kjIK8oCpj6GA5t_QRJH7Q {\n  padding-top: 20px; }\n", ""]);
// Exports
exports.locals = {
	"ProductListForm_goBackButtonContainer": "_2j42NmmyAepCeUnADRlzL4",
	"ProductListForm_submitButtonContainer": "-udpW_ssB_kR64eAHp-AW",
	"ProductListForm": "xVMUT2M8uDtT4aZmKviZy",
	"addProductButtonWrapper": "_3fDaRkVwA1zUF7fr9hdOaY",
	"buttonsWrapper": "_2kjIK8oCpj6GA5t_QRJH7Q"
};
module.exports = exports;
