// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1t-Use40Cwq8wVMKFNi8SE {\n  padding-bottom: 8px; }\n  ._1t-Use40Cwq8wVMKFNi8SE ._1Z8zSXbpBH78PGUoLeYa3n {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 16px;\n    color: #8C8C8C;\n    padding-bottom: 9px; }\n  ._1t-Use40Cwq8wVMKFNi8SE ._3JrOcwhQ9WIIJbANOe8IJA {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 19px;\n    color: #000000; }\n", ""]);
// Exports
exports.locals = {
	"Prop": "_1t-Use40Cwq8wVMKFNi8SE",
	"propLabel": "_1Z8zSXbpBH78PGUoLeYa3n",
	"propValue": "_3JrOcwhQ9WIIJbANOe8IJA"
};
module.exports = exports;
