import React from 'react'
import translations from './translations'

translations.add('en', {
    id: 'ID',
    createdDateTime: 'Date/Time',
    appUserId: 'App User ID',
    operation: 'Operation',
    userFirstName: 'User First Name',
    userSurname: 'User Surname',
    userEmailAddress: 'User Email Address',
    noDate: '--'
})
