import React from 'react'
import { observer } from 'mobx-react'
import { Typography, Tabs } from 'antd'
import { Page } from '~/code/common/pages/Page'
import translations from '~/code/ODIN/pages/MerchantSchedule/translations'
import { SelectMerchant } from '~/code/ODIN/components/SelectMerchant/SelectMerchant'
import { MaintenanceSchedule } from '~/code/ODIN/pages/MerchantSchedule/MaintenanceSchedule/MaintenanceSchedule'
import { DeviceStatusSchedule } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/DeviceStatusSchedule'
import { IMerchantScheduleProps } from './props'

const { Title } = Typography
const { TabPane } = Tabs

export const MerchantSchedule = observer((props: IMerchantScheduleProps) => {
    const { store } = props
    const { maintenanceScheduleStore, deviceStatusScheduleStore } = store

    return (
        <Page>
            <Title level={3}>{translations().merchantSchedules}</Title>

            { !store.selectedMerchant && (
                <SelectMerchant/>
            ) }

            {
                store.selectedMerchant && (
                    <Tabs defaultActiveKey="1">
                        {store.permissions?.includes('odin.merchantSchedules.maintenanceSchedule.view') && (
                            <TabPane tab={translations().maintenanceSchedule} key="1">
                                <MaintenanceSchedule store={maintenanceScheduleStore}/>
                            </TabPane>
                        )}
                        {store.permissions?.includes('odin.merchantSchedules.deviceStatus.view') && (
                            <TabPane tab={translations().deviceStatusSchedule} key="2">
                                <DeviceStatusSchedule store={deviceStatusScheduleStore}/>
                            </TabPane>
                        )}
                    </Tabs>
                )
            }
        </Page>
    )
})
