import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Form, Row, Spin } from 'antd'
import { Select } from '~/code/common/components'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { useForm } from 'antd/es/form/Form'
import { getFieldValue } from '~/code/services'
import { EntityDetails } from '~/code/common/components/EntityDetails/EntityDetails'
import {
    generateDepartmentEntityData,
    generateStoreEntityData
} from './services'
import translations from './translations'
import { StoreAndDepartmentSelectionFormProps } from './props'
import { isEmpty } from 'dna-common'

export const StoreAndDepartmentSelectionForm = observer(({store}: StoreAndDepartmentSelectionFormProps) => {
    const {
        stores,
        selectedStore,
        isLoadingStores,
        selectedDepartment,
        storeAndDepartmentSelectionData: data,
        setStoreAndDepartmentSelectionData,
        loadStores,
        onStoreChange,
        onDepartmentChange,
        canGoBack,
        goBack
    } = store

    const [form] = useForm()

    const onFinish = (values) => {
        setStoreAndDepartmentSelectionData(values)
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value || defaultValue || ''
    }

    const handleStoreChange = (merchantStoreId) => {
        onStoreChange(merchantStoreId)
    }

    const handleDepartmentChange = (merchantDepartmentId) => {
        onDepartmentChange(merchantDepartmentId)
    }

    const handleScroll = (e, callback) => {
        const isEndOfList = e.target.scrollTop + e.target.clientHeight

        if (isEndOfList === e.target.scrollHeight ) {
            callback()
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            storeId: selectedStore?.value,
            departmentId: selectedDepartment?.value
        })
    }, [selectedStore, selectedDepartment])

    const isDepartmentSelectionDisabled = isEmpty(stores) || isEmpty(selectedStore?.params?.departments)

    return (
        <div>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().store}
                            name={'storeId'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().storeRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().store}
                                    onSelect={handleStoreChange}
                                    onFocus={loadStores}
                                    onPopupScroll={(e) => {handleScroll(e, loadStores)}}
                                    notFoundContent={isLoadingStores ? <Spin size={'small'}/> : undefined}
                                    selectItems={stores.slice()}/>
                        </Form.Item>
                        { selectedStore &&
                            <EntityDetails data={generateStoreEntityData(selectedStore.params)}/>
                        }
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().department}
                            name={'departmentId'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().departmentRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().department}
                                    onSelect={handleDepartmentChange}
                                    disabled={isDepartmentSelectionDisabled}
                                    selectItems={selectedStore?.params?.departments}/>
                        </Form.Item>
                        { !isDepartmentSelectionDisabled && selectedDepartment &&
                            <EntityDetails data={generateDepartmentEntityData(selectedDepartment.params)}/>
                        }
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
