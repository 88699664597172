import { action, makeObservable, observable, runInAction } from 'mobx'
import { notification } from 'antd'
import { isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { fetchPackages } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { Package } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/Package'
import { SMALL_PAGE_SIZE } from '~/code/models'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import commonTranslations from '~/code/translations/translations'
import { PackageFilter } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/PackageFilter'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class DevicePackagesStore {
    constructor (parentStore: IAppStore, packageType: PackageType) {
        makeObservable(this, {
            isLoading: observable,
            packages: observable,
            pageNumber: observable,
            pageSize: observable,
            totalPageItems: observable,
            totalCount: observable,
            options: observable,
            setPackages: action,
            loadPackages: action,
            setOptions: action
        })

        this.parentStore = parentStore
        this.packageType = packageType
    }

    parentStore: IAppStore
    isLoading: boolean = false
    packageType: PackageType = PackageType.Application
    packages: Package[] = []
    totalPageItems: number = 0
    totalCount: number = 0
    pageSize: number = SMALL_PAGE_SIZE
    pageNumber: number = 0
    options: PackageFilter = null

    setPackages = (packages: Package[]) => {
        this.packages = packages
    }

    public loadPackages = (deviceId: number, pageNumber?: number) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber

        if (this.pageNumber === 1) {
            this.packages = []
        }

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            packageTypeId: this.packageType,
            ...this.options
        }

        fetchPackages(deviceId, queryParams)
            .then(response => {
                runInAction(() => {
                    const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                    response?.result?.length > 0 ? this.setPackages(response?.result?.map((item) => ({...item, key: `${item.name}-${item.packageVersionId}-${item.packageTypeId}`}))) : this.setPackages([])
                    this.totalCount = pagination.totalCount
                    this.totalPageItems = pagination.totalPages
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    public setOptions = (options) => {
        this.options = options
    }
}
