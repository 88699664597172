// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1QO1C3Bkvuqdpr8q--7Y6I {\n  display: inline-block;\n  margin-right: 5px; }\n\n._2LznHCHfdrTNI_EgrF4Tyc .ant-popover-inner-content {\n  padding: 0; }\n  ._2LznHCHfdrTNI_EgrF4Tyc .ant-popover-inner-content .rc-calendar-range-middle {\n    display: none; }\n\n._1WWLmFTQ_MPf8QzG64fd0T {\n  box-shadow: none !important;\n  border: none !important;\n  font-family: 'Open Sans'; }\n  ._1WWLmFTQ_MPf8QzG64fd0T .rc-calendar-footer-btn {\n    display: flex;\n    padding: 10px 12px; }\n\n.-hmClHpxOhrg04_b_Sd3a {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: 0 12px;\n  font-family: 'Open Sans'; }\n  .-hmClHpxOhrg04_b_Sd3a ._8YLaG-uMRgilLeHCBLU1 {\n    color: black;\n    cursor: pointer; }\n    .-hmClHpxOhrg04_b_Sd3a ._8YLaG-uMRgilLeHCBLU1:hover, .-hmClHpxOhrg04_b_Sd3a .hnraRP_J7SBsC7iCudYn4 {\n      color: #00A0DC; }\n\n@media screen and (max-width: 750px) {\n  ._1WWLmFTQ_MPf8QzG64fd0T {\n    width: 253px; }\n  ._2LznHCHfdrTNI_EgrF4Tyc .ant-popover-placement-bottomLeft {\n    top: 195px !important; }\n  .-hmClHpxOhrg04_b_Sd3a {\n    flex-flow: row wrap;\n    justify-content: flex-start; }\n    .-hmClHpxOhrg04_b_Sd3a ._8YLaG-uMRgilLeHCBLU1 {\n      width: 33.33%;\n      text-align: center; } }\n", ""]);
// Exports
exports.locals = {
	"rangePickerContainer": "_1QO1C3Bkvuqdpr8q--7Y6I",
	"popover": "_2LznHCHfdrTNI_EgrF4Tyc",
	"rangeCalendarContainer": "_1WWLmFTQ_MPf8QzG64fd0T",
	"datePeriodContainer": "-hmClHpxOhrg04_b_Sd3a",
	"datePeriod": "_8YLaG-uMRgilLeHCBLU1",
	"datePeriod__active": "hnraRP_J7SBsC7iCudYn4"
};
module.exports = exports;
