// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._37H_4EqsqXkVLsKmKN5FwO {\n  width: 100%; }\n  ._3sHTyfg30GkXZL0Z5MBhPP {\n    padding-top: 16px; }\n  ._10IDIVwYV4stTLdIMeqi2R {\n    padding-top: 16px;\n    display: flex;\n    flex-flow: row-reverse; }\n  ._1DHx-2gLb6tKPGDo2tUPl0 {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #000000;\n    padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"MerchantDetailsForm": "_37H_4EqsqXkVLsKmKN5FwO",
	"MerchantDetailsForm_goBackButtonContainer": "_3sHTyfg30GkXZL0Z5MBhPP",
	"MerchantDetailsForm_submitButtonContainer": "_10IDIVwYV4stTLdIMeqi2R",
	"MerchantDetailsForm_subgroupTitle": "_1DHx-2gLb6tKPGDo2tUPl0"
};
module.exports = exports;
