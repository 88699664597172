// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3zZ9idcfmaP61q1mwRIDtK ._2rTxIXSDRiTuLggHkG1zG9 {\n  padding-bottom: 5px; }\n  ._3zZ9idcfmaP61q1mwRIDtK ._2rTxIXSDRiTuLggHkG1zG9 ._1OXLYduQx5w_Pzn3f_j-XI {\n    color: #8C8C8C; }\n\n._3zZ9idcfmaP61q1mwRIDtK ._2AqUO82GfOWVsHFy54gkLI {\n  width: 100%; }\n", ""]);
// Exports
exports.locals = {
	"PlaceOrderConfiguration": "_3zZ9idcfmaP61q1mwRIDtK",
	"labelWrapper": "_2rTxIXSDRiTuLggHkG1zG9",
	"label": "_1OXLYduQx5w_Pzn3f_j-XI",
	"select": "_2AqUO82GfOWVsHFy54gkLI"
};
module.exports = exports;
