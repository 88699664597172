import { createPartnerMerchant, PartnerMerchant } from '~/code/POS/pages/NewOrder/models/partner/PartnerMerchant'

export interface PartnerPlaceOrderRequest {
    partnerId: string
    orderReference: string
    progressUpdateUrl: string
    brandingOrganizationId: number
    poNumber: string
    requestedDeliveryAndOrActivationDate: string
    internalIdentifier: string
    merchant: PartnerMerchant
}

export function createPlaceOrderRequest(): PartnerPlaceOrderRequest {
    return {
        partnerId: '',
        orderReference: '',
        progressUpdateUrl: '',
        brandingOrganizationId: 0,
        poNumber: '',
        requestedDeliveryAndOrActivationDate: '',
        internalIdentifier: '',
        merchant: createPartnerMerchant()
    }
}
