import { checkODINHTTPStatus } from '~/code/services/http-response-handler'
import { getWithAuth } from '~/code/services/authorised-requests'
import { AppCategory } from '~/code/ODIN/stores/ApplicationManagementStore/models/AppCategory'
import { DeviceManufacturer } from '~/code/ODIN/stores/ApplicationManagementStore/models/DeviceManufacturer'
import { Application } from '~/code/ODIN/stores/ApplicationManagementStore/models/Application'
import { Developer } from '~/code/ODIN/stores/ApplicationManagementStore/models/Developer'

export const fetchAppCategories = () =>
    checkODINHTTPStatus(getWithAuth<AppCategory[]>(`/odin-api/api/PackageCategories`))

export const fetchDeviceManufacturer = (filter) =>
    checkODINHTTPStatus(getWithAuth<DeviceManufacturer[]>(`/odin-api/api/DeviceManufacturer`, filter))

export const fetchAllApps = (filter) =>
    checkODINHTTPStatus(getWithAuth<Application[]>(`/odin-api/api/Packages`, filter))

export const fetchDevelopers = (filter) =>
    checkODINHTTPStatus(getWithAuth<Developer[]>(`/odin-api/api/PackageDevelopers`, filter))
