import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx'
import { notification } from 'antd'
import { error } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { fetchDeviceStatusConfiguration, fetchOwnerGroupsDeviceStatusConfiguration, fetchSaveDeviceStatusConfiguration } from './services/fetchers'
import translations from '~/code/ODIN/pages/MerchantSchedule/translations'
import { IDeviceStatusScheduleStore } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/IDeviceStatusScheduleStore'
import { DeviceStatusSettings } from '~/code/ODIN/stores/MerchantScheduleStore/models/DeviceStatusSettings'
import { DeviceStatusConfiguration } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/DeviceStatusConfiguration'
import { CommunicationMediaTypes } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/CommunicationMediaTypes'
import { getCronStringFromDeviceConfigsByDateType } from '~/code/ODIN/services/cron'
import { getCommunicationMethodLabelByType } from '~/code/ODIN/pages/DeviceManagementDetails/utils/getCommunicationMethodLabelByType'
import { DEFAULT_NOTIFICATION_DURATION } from '~/code/ODIN/models'

export class DeviceStatusScheduleStore implements IDeviceStatusScheduleStore {

    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            configurations: observable,
            settings: observable,
            permissions: computed,
            saveSchedule: action,
            setConfigurations: action,
            parseConfiguration: action,
            loadOwnerGroupConfiguration: action,
            loadConfiguration: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    configurations: DeviceStatusConfiguration[] = [
        {
            title: translations().ethernet,
            description: '',
            type: CommunicationMediaTypes.ethernet,
            dateType: 'minutes',
            hours: 0,
            minutes: 0
        },
        {
            title: translations().wiFi,
            description: '',
            type: CommunicationMediaTypes.wifi,
            dateType: 'minutes',
            hours: 0,
            minutes: 0
        },
        {
            title: translations().bluetooth,
            description: '',
            type: CommunicationMediaTypes.bluetooth,
            dateType: 'minutes',
            hours: 0,
            minutes: 0
        },
        {
            title: translations().cellular,
            description: '',
            type: CommunicationMediaTypes.cellular,
            dateType: 'hours',
            hours: 0,
            minutes: 0
        }
    ]
    settings: DeviceStatusSettings = null

    public isLoading: boolean = false

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    public setConfigurations = (configurations: DeviceStatusConfiguration[]) => {
        this.configurations = configurations
    }

    public saveSchedule = () => {

        if (this.isLoading) {
            return
        }

        this.isLoading = true

        const body: DeviceStatusSettings = {
            ScheduleSettings: this.configurations.map(config => ({
                communicationMediaType: config.type,
                cronExpression: getCronStringFromDeviceConfigsByDateType(config.dateType, config.hours, config.minutes)
            }))
        }

        fetchSaveDeviceStatusConfiguration(this.parentStore.odinSelectedMerchant?.merchantId, body)
            .then(_ => {
                notification.success({
                    message: translations().configSaved,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
                this.loadConfiguration()
            })
            .catch((err: Error) => {
                error(`FAILED: ${err.message}`)
                notification.error({
                    message: translations().errorOnSavingConfig,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    loadConfiguration = () => {
        this.isLoading = true

        if (!this.parentStore.odinSelectedMerchant?.merchantId) {
            return
        }

        fetchDeviceStatusConfiguration(this.parentStore.odinSelectedMerchant?.merchantId)
            .then(response => {
                runInAction(() => {
                    this.settings = response.result
                    this.configurations = this.parseConfiguration(response.result.ScheduleSettings)
                })
            })
            .catch((err: Error) => {
                error(`FAILED: ${err.message}`)
                this.loadOwnerGroupConfiguration()
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    loadOwnerGroupConfiguration = () => {
        this.isLoading = true

        fetchOwnerGroupsDeviceStatusConfiguration(this.parentStore.odinSelectedMerchant?.ownerGroupId)
            .then((response) => {
                runInAction(() => {
                    this.settings = response.result
                    this.configurations = this.parseConfiguration(response.result.ScheduleSettings)
                })
            })
            .catch(err => {
                error(`FAILED: ${err.message}`)
                notification.error({
                    message: translations().errorOnSavingConfig,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    parseConfiguration(scheduleSettings) {
        return scheduleSettings.map(setting => {
            const configuration = this.configurations.find(config => config.type === setting.communicationMediaType)

            const [_, minutes, hours] = setting.cronExpression.split(' ')

            if (minutes?.length >= 3) {
                const [beforeMinutes, afterMinutes] = minutes.split('/')

                configuration.minutes = afterMinutes ? +afterMinutes : +beforeMinutes
                configuration.dateType = 'minutes'
            }

            if (hours?.length >= 3) {
                const [beforeHours, afterHours] = hours.split('/')

                configuration.hours = afterHours ? +afterHours : +beforeHours
                configuration.dateType = 'hours'
            }

            const time = setting.dateType === 'hours' ? configuration.hours : configuration.minutes

            configuration.description = translations().whenConnectedViaConnectionTypeEvery(
                getCommunicationMethodLabelByType(+configuration.type), time, configuration.dateType
            )

            return configuration
        })
    }
}
