import React from 'react'
import translations from './translations'

translations.add('en', {
    cn: 'CN',
    checkoutV3Onboarding: 'Checkout V3 Onboarding',
    merchantOnboardingRequirements: 'IMPORTANT: Before onboarding a merchant to Checkout v3 please ensure that the Optomany boarding processes have been completed successfully.',
    searchMerchantPlaceholder: 'Search by merchant name or number',
    noMerchantFound: 'No merchant found. Please, make  sure that you have entered correct data',
    noMerchantSelectedDescription: <>No merchant selected<br/>Please use the form above to select a merchant</>,

    cancelOnboarding: 'Cancel Onboarding',
    cancelOnboardingConfirmText: <p>
        Are you sure you want to cancel the current onboarding process?
        <br/>
        All entered data will be lost
    </p>,
    confirmCancelOnboarding: 'Yes, cancel onboarding',
    cancelCancelOnboarding: 'No, keep onboarding',

    storeAndDepartmentSelection: 'Store & Department Selection',
    merchantDetails: 'Merchant Details',
    terminalSettings: 'Terminal Settings',
    pennies: 'Pennies',
    checkoutSettings: 'Checkout Settings',
    transactionLimits: 'Transaction Limits',

    onboardingDetails: 'Onboarding Details',
    requestSummary: 'Request Summary',
    progress: 'Progress'
})
