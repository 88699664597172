export interface TransactionLimitsFormData {
    dailyValue: number
    dailyVolume: number
    monthlyValue: number
    monthlyVolume: number
    individualTransactionValue: number
    maxRefundValue: number
}

export function createTransactionLimitsFormData(): TransactionLimitsFormData {
    return {
        dailyValue: 0,
        dailyVolume: 0,
        monthlyValue: 0,
        monthlyVolume: 0,
        individualTransactionValue: 0,
        maxRefundValue: 0
    }
}
