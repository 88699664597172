import React, { forwardRef } from 'react'
import { TreeSelect as AntTreeSelect } from 'antd'
import { TreeSelectProps } from './props'
import styles from './TreeSelect.scss'

export const TreeSelect = forwardRef<any, TreeSelectProps<any>>((props, forwardedRef) => {
    return <div className={ styles.TreeSelect }>
        <AntTreeSelect ref={ forwardedRef } {...props} />
    </div>
})
