var api = require("!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../node_modules/css-modules-typescript-loader/index.js!../../../../../node_modules/css-loader/dist/cjs.js??ref--8-2!../../../../../node_modules/sass-loader/dist/cjs.js!./Checkbox.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};