// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._24p9WBrEIf7IkG88qkO3v0 {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 24px;\n  color: #000000;\n  margin-bottom: 26px; }\n  ._19J1FvfYrbZWRdTJTvEzzI {\n    font-size: 16px; }\n  .YBCLjuwAs_a3lr8z-MNHn {\n    font-size: 14px;\n    color: #8C8C8C; }\n", ""]);
// Exports
exports.locals = {
	"GroupTitle": "_24p9WBrEIf7IkG88qkO3v0",
	"GroupTitle__small": "_19J1FvfYrbZWRdTJTvEzzI",
	"GroupTitle__description": "YBCLjuwAs_a3lr8z-MNHn"
};
module.exports = exports;
