// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SlrLl0N7u3nzfw0hZGJK {\n  width: 100%;\n  padding: 20px 0px; }\n  .SlrLl0N7u3nzfw0hZGJK ._35bqhnLYOgfbHHnOiBcU7v {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    color: #000000; }\n  .SlrLl0N7u3nzfw0hZGJK ._3aDGjGWXFB8cJPu4WFhIrz {\n    width: 100%;\n    padding-top: 50px; }\n", ""]);
// Exports
exports.locals = {
	"NoCompanySelected": "SlrLl0N7u3nzfw0hZGJK",
	"noCompanySelectedText": "_35bqhnLYOgfbHHnOiBcU7v",
	"buttonWrapper": "_3aDGjGWXFB8cJPu4WFhIrz"
};
module.exports = exports;
