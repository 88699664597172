import React from 'react'
import translations from './translations'

translations.add('en', {
    updateApp: 'Update App',
    installPriority: 'Install Priority',
    cancel: 'Cancel',
    pushUpdate: 'Push Update',
    appVersion: 'App Version',
    pleaseSelectPriority: 'Please Select Priority',
    pleaseTypePackageVersionId: 'App Version is Required'
})
