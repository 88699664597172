// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2a-wYT6n46HAXFnSALrwBA {\n  padding-bottom: 5px;\n  padding-top: 5px; }\n\n._3S4tNqN3tUPSO1d7aLT0sZ {\n  padding-top: 10px; }\n", ""]);
// Exports
exports.locals = {
	"InputWithCheckbox": "_2a-wYT6n46HAXFnSALrwBA",
	"checkboxContainer": "_3S4tNqN3tUPSO1d7aLT0sZ"
};
module.exports = exports;
