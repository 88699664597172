import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Card, Col, Descriptions, Form, List, Modal, Row, Tabs, Typography } from 'antd'
import { FormInstance } from 'antd/es/form'
import { useForm } from 'antd/es/form/Form'
import { Button, Input, ListActions, Select } from '~/code/common/components'
import { DeliveryStatus } from '~/code/ODIN/pages/DeviceManagementDetails/models/DeliveryStatus'
import { PushTask } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/PushTask'
import { FilterActions } from '~/code/common/components/FilterActions/FilterActions'
import { PushTasksModalProps } from './props'
import translations from './translations'
import styles from './styles.scss'
import { NotificationTypes } from '~/code/ODIN/models'

const {Title} = Typography

export const PushTasksModal = observer((props: PushTasksModalProps) => {
    const {store, title, deviceId} = props

    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
    const [form] = useForm()
    const formRef = React.createRef<FormInstance>()

    const onFilterFinish = (values: any, deliveryStatus: DeliveryStatus) => {
        store.setOptions(values, deliveryStatus)

        store.loadTasks(deviceId, deliveryStatus, 1)
        setIsFilterVisible(false)
    }

    const handleReset = (deliveryStatus: DeliveryStatus) => {
        store.setOptions(null, deliveryStatus)
        store.loadTasks(deviceId, deliveryStatus, 1)
        formRef.current!.resetFields()
        setIsFilterVisible(false)
    }
    
    const handleRefresh = (deliveryStatus: DeliveryStatus) => {
        store.loadTasks(deviceId, deliveryStatus, 1)
    }

    useEffect(() => {
        store.loadTasks(deviceId, DeliveryStatus.NotSent)
        store.loadTasks(deviceId, DeliveryStatus.Sent)
        store.loadTasks(deviceId, DeliveryStatus.Delivered)
        store.loadTasks(deviceId, DeliveryStatus.Errored)
        store.loadTasks(deviceId, DeliveryStatus.All)
    }, [])

    const renderList = (notificationType: DeliveryStatus) => (
        <>
            <ListActions
                pageInfo={{
                    from: store.pageNumber[notificationType] * store.pageSize[notificationType] - 5,
                    to: store.pageNumber[notificationType] * store.pageSize[notificationType] > store.totalCount[notificationType]
                        ? store.totalCount[notificationType]
                        : store.pageNumber[notificationType] * store.pageSize[notificationType],
                    total: store.totalCount[notificationType]
                }}
                className={styles.listActions}
                currentTotalAmount={store.tasks[notificationType].length}
                onReloadClick={() => handleRefresh(notificationType)}
                onGoNextPageClick={() => store.loadTasks(deviceId, store.pageNumber[notificationType] + 1)}
                onGoPrevPageClick={() => store.loadTasks(deviceId, store.pageNumber[notificationType] - 1)}
                isNextPageDisabled={store.pageNumber[notificationType] >= store.totalPageItems[notificationType]}
                isPrevPageDisabled={store.pageNumber[notificationType] <= 1}
                onGoFirstPageClick={() => store.loadTasks(deviceId, notificationType, 1)}
                onGoLastPageClick={() => store.loadTasks(deviceId, notificationType, store.totalPageItems[notificationType])}
                isGoToFirstPageDisabled={store.pageNumber[notificationType] <= 1}
                isGoToLastPageDisabled={store.pageNumber[notificationType] >= store.totalPageItems[notificationType]}
                isFilterDisabled={false}
                isFilterVisible={isFilterVisible}
                onFilterClick={() => setIsFilterVisible(true)}
                filterContent={() => {
                    return <div>
                        <Form
                            ref={
                                formRef}
                            form={form}
                            onFinish={(values) => onFilterFinish(values, notificationType)}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item name={'NotificationTypeId'}>
                                        <Select
                                            selectItems={NotificationTypes}
                                            placeholder={translations().notificationType}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item key={'placeRequest'}>
                                        <FilterActions
                                            onCancel={() => setIsFilterVisible(false)}
                                            onReset={() => handleReset(notificationType)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }}
            />
            <List
                loading={store.isLoading[notificationType]}
                itemLayout="vertical"
                size="large"
                pagination={null}
                dataSource={store.tasks[notificationType]}
                renderItem={(item: PushTask) => (
                    <Card className={styles.body}>
                        <Descriptions column={2}>
                            <Descriptions.Item><Title level={5}>{item.notificationTypeDisplayName}</Title></Descriptions.Item>
                            <Descriptions.Item label={translations().initiated}>{moment(item.createdDateTime).format('DD/MM/YY HH:mm')}</Descriptions.Item>
                            {item.additionalData && <Descriptions.Item label={translations().appName}>{item.additionalData}</Descriptions.Item>}
                            <Descriptions.Item label={translations().requestedBy}>{`${item.userFirstName} ${item.userSurname} (${item.userEmailAddress})`}</Descriptions.Item>
                            {item.deliveryStatusName && <Descriptions.Item label={translations().deliveryStatusName}>{item.deliveryStatusName}</Descriptions.Item>}
                            {item.errorDetails && <Descriptions.Item label={translations().errorDetails}>{item.errorDetails}</Descriptions.Item>}
                        </Descriptions>
                    </Card>
                )}
            />
        </>
    )

    return <>
        <Modal
            title={title}
            visible={store.isPushTasksModalOpen}
            onOk={() => store.setIsPushTasksModalOpen(false)}
            onCancel={() => store.setIsPushTasksModalOpen(false)}
            width={1000}
            footer={[
                <Button key="back" className={styles.backButton} onClick={() => store.setIsPushTasksModalOpen(false)}>
                    {translations().cancel}
                </Button>
            ]}
        >
            <Tabs defaultActiveKey={String(DeliveryStatus.All)}>
                <Tabs.TabPane tab={translations().all} key={DeliveryStatus.All}>
                    {renderList(DeliveryStatus.All)}
                </Tabs.TabPane>
                {store.tasks[DeliveryStatus.NotSent].length && (
                    <Tabs.TabPane tab={translations().notSent} key={DeliveryStatus.NotSent}>
                        {renderList(DeliveryStatus.NotSent)}
                    </Tabs.TabPane>
                )}

                {store.tasks[DeliveryStatus.Sent].length && (
                    <Tabs.TabPane tab={translations().sent} key={DeliveryStatus.Sent}>
                        {renderList(DeliveryStatus.Sent)}
                    </Tabs.TabPane>
                )}

                {store.tasks[DeliveryStatus.Delivered].length && (
                    <Tabs.TabPane tab={translations().delivered} key={DeliveryStatus.Delivered}>
                        {renderList(DeliveryStatus.Delivered)}
                    </Tabs.TabPane>
                )}

                {store.tasks[DeliveryStatus.Errored].length && (
                    <Tabs.TabPane tab={translations().errored} key={DeliveryStatus.Errored}>
                        {renderList(DeliveryStatus.Errored)}
                    </Tabs.TabPane>
                )}
            </Tabs>
        </Modal>
    </>
})
