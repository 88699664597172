import React from 'react'
import { Input } from '~/code/common/components'
import { LoadingOutlined } from '@ant-design/icons'
import { InputProps } from 'antd/es/input'
import { Spin } from 'antd'
import { observer } from 'mobx-react'
import styles from './InputWithLoader.scss'

interface InputWithLoaderProps extends InputProps {
    loading: boolean
    placeholder: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
}

const InputWithLoader = observer((props: InputWithLoaderProps) => {
    const { loading, placeholder, onChange, ...rest } = props

    const loadingIcon = <LoadingOutlined className={styles.LoadingIcon} spin />
    const suffix = loading ? <Spin indicator={loadingIcon} /> : <span />

    return <Input
        placeholder={placeholder}
        suffix={suffix}
        onChange={onChange}
        {...rest}
    />
})

export { InputWithLoader }
