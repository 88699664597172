// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3a5je2pjQH4MLVnTCjqaKY {\n  padding: 0; }\n\n._2SdEdVdnuiOoV6b-LnvXeM._2SdEdVdnuiOoV6b-LnvXeM {\n  margin-bottom: 0; }\n\n._1tHovsFz3Kpy7G7RH6ZhYv {\n  width: 100px;\n  height: 100px; }\n\n._1yEYv6z7LZQMIT31ssp_I8 {\n  display: flex;\n  flex-direction: column; }\n\n.V-k9TVKl2Awhj3_MMgRms {\n  padding: 0; }\n\n._3qqvqu6vQI3GDT2-mddG7a {\n  font-size: 24px;\n  height: 24px; }\n\n._3az7tpBP31pEBtt-t_IpUm {\n  color: #cc0000; }\n\n._297Q6PPh3GFBBdJsk4PzrL {\n  color: #3CB371; }\n\n._1pGCY5OKUoIhjp3w1ySaCU {\n  color: #00BFFF; }\n\n._3ppnaZ6wQwctMdBJCQBCnq {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  ._3ppnaZ6wQwctMdBJCQBCnq:hover {\n    cursor: pointer; }\n\n._2okSfC4kcYWcrGUA5dahOu {\n  cursor: pointer; }\n  ._2okSfC4kcYWcrGUA5dahOu:hover {\n    background: #eaeaea;\n    border-radius: 4px; }\n\n._2CosyjDI1I_dg8kLH5TJrA {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 5px; }\n  ._2CosyjDI1I_dg8kLH5TJrA .ant-space-item {\n    height: 27px; }\n\n._1eeQ3vUybjYlUU1Z0oEoCZ {\n  color: #f5222d; }\n  ._1eeQ3vUybjYlUU1Z0oEoCZ path {\n    fill: #f5222d; }\n\n.-lJ_jtR-lPJrrDuFwdG_j {\n  color: #3CB371; }\n  .-lJ_jtR-lPJrrDuFwdG_j path {\n    fill: #3CB371; }\n\n._3qxyIYl3AhIru0qL7fZEXh {\n  color: #e28800; }\n  ._3qxyIYl3AhIru0qL7fZEXh path {\n    fill: #e28800; }\n\n.Zx-lm30Dro6hIqhEdRyRM {\n  margin-right: 8px; }\n\n._10NzZorx81tdaA7h0BUbVn {\n  display: flex;\n  justify-content: space-between; }\n\n._32sUAdHv_RIPSzBP8PNYG9 {\n  margin-right: 8px; }\n", ""]);
// Exports
exports.locals = {
	"pageHeader": "_3a5je2pjQH4MLVnTCjqaKY",
	"title": "_2SdEdVdnuiOoV6b-LnvXeM",
	"avatar": "_1tHovsFz3Kpy7G7RH6ZhYv",
	"lastCommunication": "_1yEYv6z7LZQMIT31ssp_I8",
	"link": "V-k9TVKl2Awhj3_MMgRms",
	"icon": "_3qqvqu6vQI3GDT2-mddG7a",
	"uninstallIcon": "_3az7tpBP31pEBtt-t_IpUm",
	"launcherIcon": "_297Q6PPh3GFBBdJsk4PzrL",
	"setLauncherIcon": "_1pGCY5OKUoIhjp3w1ySaCU",
	"actions": "_3ppnaZ6wQwctMdBJCQBCnq",
	"battery": "_2okSfC4kcYWcrGUA5dahOu",
	"deviceIconData": "_2CosyjDI1I_dg8kLH5TJrA",
	"iconRed": "_1eeQ3vUybjYlUU1Z0oEoCZ",
	"iconGreen": "-lJ_jtR-lPJrrDuFwdG_j",
	"iconOrange": "_3qxyIYl3AhIru0qL7fZEXh",
	"backButton": "Zx-lm30Dro6hIqhEdRyRM",
	"pushAppHeader": "_10NzZorx81tdaA7h0BUbVn",
	"restartButton": "_32sUAdHv_RIPSzBP8PNYG9"
};
module.exports = exports;
