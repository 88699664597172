// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2CSUHaFQzXcwu9COLL6J76 ._1xRbBSJQhXr79anlngHqhs {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 32px;\n  line-height: 48px;\n  color: #000000;\n  padding-bottom: 24px; }\n\n._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad {\n  height: 100%; }\n  ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._3rQtCzjnMIzUk_XlyRb_Nn {\n    padding-top: 5px;\n    padding-bottom: 20px; }\n  ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._2jbsDWOKG1jbIBM4kS3Mn5 {\n    padding-bottom: 20px; }\n  ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._2R496C-TuqE3l6-WrEWU3k {\n    display: flex;\n    justify-content: flex-end;\n    padding-bottom: 20px;\n    background-color: #ffffff; }\n    ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._2R496C-TuqE3l6-WrEWU3k ._15jF6WmGHX0bDjXakcpPkv {\n      width: 40px;\n      height: 40px;\n      border-radius: 20px;\n      background-color: #00A0DC;\n      padding: 7px;\n      cursor: pointer; }\n      ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._2R496C-TuqE3l6-WrEWU3k ._15jF6WmGHX0bDjXakcpPkv i {\n        font-size: 25px;\n        font-weight: 600;\n        color: #ffffff; }\n  ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._2PqZy_0E83eb3Le63iIafd {\n    padding: 20px; }\n  ._2CSUHaFQzXcwu9COLL6J76 .HbkkDrd6PHm7co6th7tad ._1qh_bV2SP3wY2qkDVOVi8W {\n    display: flex;\n    width: 100%;\n    justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"OrderHistory": "_2CSUHaFQzXcwu9COLL6J76",
	"header": "_1xRbBSJQhXr79anlngHqhs",
	"content": "HbkkDrd6PHm7co6th7tad",
	"rangePickerAndStatusContainer": "_3rQtCzjnMIzUk_XlyRb_Nn",
	"searchBarContainer": "_2jbsDWOKG1jbIBM4kS3Mn5",
	"refreshButtonWrapper": "_2R496C-TuqE3l6-WrEWU3k",
	"refreshButton": "_15jF6WmGHX0bDjXakcpPkv",
	"empty": "_2PqZy_0E83eb3Le63iIafd",
	"loadMoreButtonWrapper": "_1qh_bV2SP3wY2qkDVOVi8W"
};
module.exports = exports;
