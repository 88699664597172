import page from 'page'
import moment from 'moment'
import { IOrderHistoryStore } from '~/code/POS/pages/OrderHistory/IOrderHistoryStore'
import { isOrderHistoryPage } from '~/code/services'
import { OrderListItemModel } from '~/code/POS/pages/OrderHistory/components/OrdersListView/components/OrderListItem/models/OrderListItemModel'
import { IOrderHistoryParentStore } from './models/IOrderHistoryParentStore'
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { AppStore as appStore } from '~/code/AppStore'
import { SearchItem } from '~/code/common/components/SearchBar/models/SearchItem'
import { generateOrderStatusHistory, mapOrderStatus } from '~/code/stores/OrderHistoryStore/services/order-status-history-utils'
import { OrderStatus } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/models/OrderStatus'
import translations from '~/code/POS/pages/OrderHistory/pages/OrderDetails/translations'
import { Bundle } from '~/code/POS/pages/OrderHistory/models'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { persist } from 'mobx-persist'
import { fetchOrders } from './services/fetchers'
import { RangePickerStore } from '../RangePickerStore/RangePickerStore'
import { FiltersSelectStore } from '../FiltersSelectStore/FiltersSelectStore'
import { error, isEmpty } from 'dna-common'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'

export class OrderHistoryStore implements IOrderHistoryStore {

    public rangePickerStore: RangePickerStore
    public filtersSelectStore: FiltersSelectStore

    constructor (parentStore: IOrderHistoryParentStore) {
        makeObservable(this, {
            prevOwnerGroupId: observable,
            isLoading: observable,
            orders: observable,
            chosenOrderStatusId: observable,
            userInfo: computed,
            selectedEmployeeCompany: computed,
            internalIdentifier: computed,
            internalIdentifierEncoded: computed,
            isPrevPageDisabled: computed,
            isNextPageDisabled: computed,
            setOrderStatusId: action.bound,
            loadOrdersIfEmpty: action,
            clearAndLoadOrders: action,
            loadOrders: action,
            onOrderItemClick: action,
            loadNextPage: action,
            loadPrevPage: action,
            refreshCurrentPage: action,
            selectEmployeeCompany: action
        })

        this.rangePickerStore = new RangePickerStore(this.loadOrders)
        this.filtersSelectStore = new FiltersSelectStore(this.loadOrders)

        autorun(() => {
            if (this.selectedEmployeeCompany !== undefined && parentStore.isAuthenticated()) {
                this.clearAndLoadOrders()
            }
        }, {delay: 300})

        autorun(() => {
            if (this.prevOwnerGroupId !== this.userInfo?.ownerGroupId && isOrderHistoryPage()) {
                this.clearAndLoadOrders()
            }

            runInAction(() => {
                this.prevOwnerGroupId = this.userInfo?.ownerGroupId
            })
        }, {delay: 300})
    }

    private currentPageNumber = 1
    private pageSize = 20

    @persist
    public prevOwnerGroupId = null

    public isLoading: boolean = false

    public orders: OrderListItemModel[] = []

    chosenOrderStatusId: string = '0'

    get userInfo(): UserInfo {
        return appStore?.userInfo
    }

    get selectedEmployeeCompany(): SearchItem {
        return appStore?.companySelectStore?.selectedEmployeeCompany
    }

    get internalIdentifier(): string {
        return appStore.internalIdentifier
    }

    get internalIdentifierEncoded(): string {
        return appStore.internalIdentifierEncoded
    }

    public get isPrevPageDisabled(): boolean {
        return this.currentPageNumber === 1
    }

    public get isNextPageDisabled(): boolean {
        return this.orders?.length < 20
    }

    setOrderStatusId(statusId: string) {
        this.chosenOrderStatusId = statusId
        this.loadOrders()
    }

    public loadOrdersIfEmpty = () => {
        if (isEmpty(this.orders)) {
            this.loadOrders()
        }
    }

    public clearAndLoadOrders = () => {
        if (!this.isLoading) {
            this.currentPageNumber = 1
            this.orders = []
            this.loadOrders()
        }
    }

    public loadOrders = (pageNumber?) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true
        this.currentPageNumber = isEmpty(pageNumber) ? 1 : pageNumber
        if (this.currentPageNumber === 1) {
            this.orders = []
        }
        const { ownerGroupId } = this.userInfo
        
        const queryParams = {
            pageNumber: this.currentPageNumber,
            pageSize: this.pageSize,
            internalIdentifier: this.internalIdentifier || undefined,
            orderStatusId: this.chosenOrderStatusId !== '0' ? this.chosenOrderStatusId : undefined,
            ownerGroupId,
            orderReference: this.filtersSelectStore.orderReference,
            placedBy: this.filtersSelectStore.placedBy,
            bundleCode: this.filtersSelectStore.bundleCode,
            oneTwoThreeSendDealId: this.filtersSelectStore.oneTwoThreeSendDealId,
            orderCreateDateTimeFrom: `${this.rangePickerStore.startDate.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
            orderCreateDateTimeTo: `${this.rangePickerStore.endDate.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
        }
        fetchOrders(queryParams)
            .then(response => {
                if (response && response.length > 0) {
                    this.orders = response.map(orderInfo => {
                        const bundles: Bundle[] = orderInfo.details.map(item => ({
                            bundle: item.bundleCode,
                            bundleDescription: item.bundleDescription,
                            bundleQuantity: item.quantity,
                            priceOverride: item.priceOverride
                        }))

                        const orderListItemModel: OrderListItemModel = {
                            companyName: orderInfo.details[0].merchantName,
                            deliveryName: orderInfo.details[0].deliveryName,
                            deliveryTown: orderInfo.details[0].deliveryTown,
                            deliveryPostcode: orderInfo.details[0].deliveryPostcode,
                            isExistingMerchant: Boolean(orderInfo.details[0].existingMerchantOrder),
                            bundles,
                            placedBy: orderInfo.placedBy,
                            status: mapOrderStatus(orderInfo.status),
                            statusLabel: translations()[OrderStatus[orderInfo.orderStatusId]],
                            statusHistory: generateOrderStatusHistory(orderInfo.orderStatusId),
                            orderReference: orderInfo.orderReference,
                            additionalInfo: orderInfo,
                            createdDateTime: moment(orderInfo.createdDateTime).format('DD.MM.YYYY HH:mm:ss'),
                            canBeCancelled: orderInfo.canBeCancelled,
                            accountNumber: orderInfo.accountNumber
                        }

                        return orderListItemModel
                    })
                }
            })
            .catch((err: Error) => {
                error(`FAILED: ${err.message}`)
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    public onOrderItemClick = (orderItem: OrderListItemModel) => {
        page(`${LOCATION_POS_PATH_NAMES.ORDER_HISTORY}/${orderItem.orderReference}`)
    }

    public loadNextPage = () => {
        this.currentPageNumber++
        this.loadOrders(this.currentPageNumber)
    }

    loadPrevPage = () => {
        this.currentPageNumber--
        this.loadOrders(this.currentPageNumber)
    }

    refreshCurrentPage = () => {
        this.loadOrders(this.currentPageNumber)
    }

    selectEmployeeCompany = () => {
        appStore?.companySelectStore?.setModalVisible(true)
    }
}
