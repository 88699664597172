import React, { forwardRef, LegacyRef } from 'react'
import { Input as AntInput } from 'antd'
import InputMask from 'react-input-mask'
import { InputProps } from 'antd/es/input'
import classNames from 'classnames'
import styles from './Input.scss'

interface AdditionalInputProps extends InputProps {
    mask?: string
    textTransform?: 'uppercase' | 'lowercase'
}

const Input = forwardRef((props: AdditionalInputProps = {}, forwardedRef: LegacyRef<AntInput>) => {
    const { textTransform, onBlur, onChange, ...rest } = props

    const onCustomBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        const prevValue = e.target.value
        const newValue = e.target.value?.trim()
        e.target.value = newValue
        if (prevValue !== newValue) {
            onChange(e)
        }
        onBlur && onBlur(e)
    }

    const input = () => {
        if (props.type === 'password') {
            return <AntInput.Password { ...rest } onBlur={onCustomBlur} onChange={onChange}/>
        } else if (props.type === 'masked') {
            return <InputMask { ...rest } onBlur={onCustomBlur} onChange={onChange}/>
        } else {
            return <AntInput { ...rest } ref={ forwardedRef } onBlur={onCustomBlur} onChange={onChange}/>
        }
    }

    const className = classNames(styles.Input,
        {
            [styles.Input__disabled]: props.disabled,
            [styles.lowercase]: textTransform === 'lowercase',
            [styles.uppercase]: textTransform === 'uppercase'
        })

    return (
        <div className={ className } >
            { input() }
        </div>
    )
})

export { Input }
