import { IOrderDetailsFormStore } from '~/code/POS/pages/NewOrder/components/123send/OrderDetailsForm/IOrderDetailsFormStore'
import { SelectItem } from '~/code/common/components'
import { action, computed, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { AppStore } from '~/code/AppStore'
import { BrandingOrganisationsStore } from '~/code/stores/NewOrderStore/common/BrandingOrganisationsStore'
import { MerchantType } from '~/code/POS/pages/NewOrder/models/constants'
import { createOrderDetailsFormData, OrderDetailsFormData } from '~/code/POS/pages/NewOrder/components/123send'
import { isEmpty } from 'dna-common'
import { fetch123SendOrderTypes, fetch123SendPartnerCompanies } from '~/code/stores/NewOrderStore/services/fetchers'
import { ORDER_TYPE } from '~/code/POS/pages/NewOrder/components/123send/OrderDetailsForm/models/OrderType'
import { log } from 'dna-common'
import { v4 as uuid } from 'uuid'

export class OrderDetailsForm123SendStore implements IOrderDetailsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            countries: computed,
            brandingOrganisations: computed,
            merchantType: computed,
            orderTypes: computed,
            partnerCompanies: computed,
            useMainContact: observable,
            orderDetailsFormData: observable,
            _orderTypes: observable,
            isLoadingOrderTypes: observable,
            _partnerCompanies: observable,
            isLoadingPartnerCompanies: observable,
            canGoBack: observable,
            setOrderDetailsFormData: action,
            onOrderTypeChange: action,
            addUpgradeRow: action,
            deleteUpgradeRow: action,
            onSerialNumberChange: action,
            onTIDChange: action,
            setUseMainContact: action,
            loadOrderTypes: action,
            loadPartnerCompanies: action
        })

        this.orderDetailsFormData = createOrderDetailsFormData()
    }

    useMainContact: boolean = false

    orderDetailsFormData: OrderDetailsFormData

    _orderTypes: SelectItem[] = []
    isLoadingOrderTypes: boolean = false

    _partnerCompanies: SelectItem[] = []
    isLoadingPartnerCompanies: boolean = false

    canGoBack: boolean = true

    static stepName: string = 'orderDetails'

    // helper stores
    private readonly brandingOrganisationsStore = new BrandingOrganisationsStore()

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get brandingOrganisations (): SelectItem[] {
        return this.brandingOrganisationsStore.brandingOrganisations
    }

    get merchantType(): MerchantType {
        return AppStore.newOrderStore?.placeOrderConfigStore.merchantType
    }

    get orderTypes(): SelectItem[] {
        this.loadOrderTypes()
        return this._orderTypes
    }

    get partnerCompanies(): SelectItem[] {
        this.loadPartnerCompanies()
        return this._partnerCompanies
    }

    setOrderDetailsFormData = (data: OrderDetailsFormData) => {
        const { upgradeDetails, ...restData} = data
        this.orderDetailsFormData = {...this.orderDetailsFormData, ...restData}
        this.formSubmitHandler.handleFormSubmit(data)
    }

    onOrderTypeChange = (orderType: number) => {
        if (orderType === ORDER_TYPE.upgrade) {
            if (isEmpty(this.orderDetailsFormData.upgradeDetails)) {
                this.orderDetailsFormData.upgradeDetails.push(this.createUpgradeRow())
            }
        } else {
            this.orderDetailsFormData.upgradeDetails = []
        }
    }

    addUpgradeRow = () => {
        this.orderDetailsFormData.upgradeDetails = [...this.orderDetailsFormData.upgradeDetails,
            this.createUpgradeRow()
        ]
    }

    private createUpgradeRow = () => {
        return {
            id: uuid(),
            serialNumber: '',
            tid: ''
        }
    }

    deleteUpgradeRow = (index) => {
        this.orderDetailsFormData.upgradeDetails = [
            ...this.orderDetailsFormData.upgradeDetails.slice(0, index),
            ...this.orderDetailsFormData.upgradeDetails.slice(index + 1, this.orderDetailsFormData.upgradeDetails.length)
        ]
        this.orderDetailsFormData = {...this.orderDetailsFormData}
        log('upgrade details: ', this.orderDetailsFormData.upgradeDetails)
    }

    onSerialNumberChange =  (index, value) => {
        this.orderDetailsFormData.upgradeDetails[index].serialNumber = value
        this.orderDetailsFormData = {...this.orderDetailsFormData}
    }

    onTIDChange = (index, value) => {
        this.orderDetailsFormData.upgradeDetails[index].tid = value
        this.orderDetailsFormData = {...this.orderDetailsFormData}
    }

    setUseMainContact(use: boolean) {
        this.useMainContact = use
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(OrderDetailsForm123SendStore.stepName)
    }

    loadOrderTypes = async () => {
        if (this.isLoadingOrderTypes || !isEmpty(this._orderTypes)) { return }
        this.isLoadingOrderTypes = true
        this._orderTypes = await fetch123SendOrderTypes()
        this.isLoadingOrderTypes = false
    }

    loadPartnerCompanies = async () => {
        if (this.isLoadingPartnerCompanies || !isEmpty(this._partnerCompanies)) { return }
        this.isLoadingPartnerCompanies = true
        this._partnerCompanies = await fetch123SendPartnerCompanies()
        this.isLoadingPartnerCompanies = false
    }
}
