// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2RQKZwlq1O8zZQfPwPcPso {\n  display: inline-flex;\n  align-items: center; }\n  ._2RQKZwlq1O8zZQfPwPcPso ._1-SzsvrFUTK3C9NFsKd_5x {\n    font-size: inherit;\n    cursor: pointer;\n    color: #8D99A7;\n    margin-left: 5px; }\n    ._2RQKZwlq1O8zZQfPwPcPso ._1-SzsvrFUTK3C9NFsKd_5x:hover {\n      color: #4F4F4F; }\n  ._2RQKZwlq1O8zZQfPwPcPso ._3EJHFE8HEBAEAtdAx8-gzG {\n    position: absolute;\n    top: 0;\n    left: 0;\n    opacity: 0;\n    z-index: -900; }\n", ""]);
// Exports
exports.locals = {
	"CopyText": "_2RQKZwlq1O8zZQfPwPcPso",
	"copyTextIcon": "_1-SzsvrFUTK3C9NFsKd_5x",
	"copyTextInput": "_3EJHFE8HEBAEAtdAx8-gzG"
};
module.exports = exports;
