import { ApolloClient, gql } from '@apollo/client/core'
import { PAGE_SIZE } from '~/code/models'
import { OnboardingRequest } from '~/code/stores/CheckoutV3Onboarding/models/backend/OnboardingRequest'
import { error } from 'dna-common'
import { post, form, ApiResponse } from 'back-connector'
import { graphQlQuery } from '~/code/stores/CheckoutV3Onboarding/services/apollo-client'
import { ScopeType } from '~/code/stores/CheckoutV3Onboarding/models/ScopeType'
import { ICDNUploadResponse } from '~/code/stores/CheckoutV3Onboarding/models/backend/ICDNUploadResponse'
import { getDnaApiTokenByScope, refreshDnaApiTokenByScope } from '~/code/stores/CheckoutV3Onboarding/services/utils'
import { AcquisitionChannel } from '~/code/stores/CheckoutV3Onboarding/models/backend/AcquisitionChannel'
import { mapAcquisitionChannelResponseToSelectItems } from '~/code/stores/CheckoutV3Onboarding/services/data-mappers'
import { getWithDnaAuth, postWithDnaAuth } from '~/code/services/authorised-requests'

export function fetchMerchants (searchValue: string, client: ApolloClient<any>) {
    return graphQlQuery(
        {
            query: gql`
                    query merchantStores {
                      merchants(
                        first: ${PAGE_SIZE}
                        where: { OR: [{name_contains: "${searchValue}"}, { merchantId: ${searchValue}}] }
                      ) {
                        nodes {
                          name
                          merchantId
                          corporateRegistrationNumber
                        }
                        pageInfo {
                          hasNextPage
                        }
                        totalCount
                      }
                    }
                    `
        }, client
    )
}

export function fetchMerchantStores (merchantId: number, client: ApolloClient<any>) {
    return graphQlQuery(
        {
            query: gql`
            query merchantStores {
              merchants(first: 1, where: { merchantId: ${merchantId} }) {
                nodes {
                  name
                  merchantId
                  merchantStores {
                    nodes {
                      name
                      address1
                      address2
                      address3
                      countryId
                      townCity
                      countyState
                      postZipCode
                      emailAddress
                      merchantStoreId
                      merchantDepartments {
                        nodes{
                          allowedCardSchemes{
                            acquirerId
                            cardSchemeId
                            currencyId
                            merchantNumber
                            paymentLimits {
                              paymentLimitTypeId
                              value
                            }
                          }
                          cardCollections
                          donationServiceConfiguration {
                            charityId
                            activationTimes{
                              startTime
                              endTime
                            }
                            donationServiceAdjustmentType {
                              # donationServiceAdjustmentBands{
                              #   adjustmentValue
                              #   bandCeilingValue
                              #   currencyId
                              # }
                              donationServiceAdjustmentTypeId
                              name
                            }
                            donationServiceConfigurationId
                            donationServiceProviderAssociationId
                            donationServiceProviderId
                            invitationPromptTimeout
                            merchantNumber
                            name
                            storeId
                            supportedCharity {
                              charityId
                              charityNumber
                              # logos {
                              #   charityLogoId
                              #   imageMimeType
                              #   logo
                              #   name
                              # }
                              name
                              receiptTextResourceName
                            }
                          }
                          emailAddress
                          mcc
                          merchantDepartmentId
                          name
                          paymentAmounts {
                            amount
                            amountIdentifier
                            currencyId
                            processorTypeId
                          }
                          paymentMethods {
                            name
                            paymentMethodId
                          }
                          status
                          telephoneNumber
                          transactionAmounts {
                            amount
                            amountIdentifier
                            currencyId
                          }
                        }
                      }
                    }
                    pageInfo {
                      hasNextPage
                    }
                    totalCount
                  }
                }
              }
            }
            `
        }, client
    )
}

export async function submitOnboardingRequest(request: OnboardingRequest) {
    return postWithDnaAuth('/dna-api/checkout/onboarding', 'checkout_onboarding', request)
}

export function authoriseInDNAApi(scope: ScopeType) {
    return post<any>('/dna-oauth/oauth2/token',
        form({
            client_id: _DNA_CLIENT_ID_,
            client_secret: _DNA_CLIENT_SECRET_,
            grant_type: 'client_credentials',
            scope
        })
    ).
    then( response => {
        if (response) {
            return response.result.access_token
        } else {
            throw Error('Could not obtain token')
        }
    })
}

export async function uploadImageToCDN(formData: FormData): Promise<ICDNUploadResponse> {
    return new Promise(async (resolve, reject) => {
        const token = await getDnaApiTokenByScope('checkout_file_management')

        const xhr = new XMLHttpRequest()

        xhr.open('post', '/dna-api/checkout/files/upload')
        xhr.responseType = 'json'
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.onload = async () => {
            if (xhr.status === 401) {
                await refreshDnaApiTokenByScope('checkout_file_management')
                    .then(() => {
                        uploadImageToCDN(formData)
                    })
            }
            resolve(xhr.response)
        }
        xhr.onerror = () => {
            reject(xhr.response)
        }
        xhr.send(formData)
    })
}

export async function removeImageFromCDN(path: string): Promise<ApiResponse<null>> {
    return postWithDnaAuth('/dna-api/checkout/files/delete', 'checkout_file_management', { path })
}

export const fetchAcquisitionChannels = async () => {
    return getWithDnaAuth<AcquisitionChannel[]>('/dna-api/checkout/partners', 'checkout_onboarding')
        .then((response) => {
            return mapAcquisitionChannelResponseToSelectItems(response.result)
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}
