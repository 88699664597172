// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1p_kpp6HbnJKnE-ZrJUPVH {\n  background-color: #FFFFFF;\n  display: flex;\n  flex-direction: row;\n  text-transform: uppercase;\n  font-weight: 600; }\n  ._2NFCDaZJNkRo7YfiGBZy4c {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 24px;\n    text-align: center;\n    color: #FFFFFF;\n    width: 24px;\n    height: 24px;\n    border-radius: 12px;\n    background: #8C8C8C; }\n    ._2jZZRTSVCXkw9C70JzckHi {\n      background: #00A0DC; }\n  .WWEg6mls62RBN-5F_dCRf {\n    margin-left: 12px;\n    padding-bottom: 10px;\n    color: #8C8C8C; }\n    ._2DlWUqouKKIjOhdloZ0sF_ {\n      border-bottom: 2px solid #00A0DC;\n      color: #00A0DC; }\n", ""]);
// Exports
exports.locals = {
	"Tab": "_1p_kpp6HbnJKnE-ZrJUPVH",
	"Tab_order": "_2NFCDaZJNkRo7YfiGBZy4c",
	"Tab_order__active": "_2jZZRTSVCXkw9C70JzckHi",
	"Tab_title": "WWEg6mls62RBN-5F_dCRf",
	"Tab_title__active": "_2DlWUqouKKIjOhdloZ0sF_"
};
module.exports = exports;
