import React from 'react'
import page from 'page'
import { observer } from 'mobx-react'
import { List, Card, Typography, Tooltip, Row, Col, Popover } from 'antd'
import { Page } from '~/code/common/pages/Page'
import { FiltersSelect } from '~/code/common/components/FiltersSelect'
import { ListActions, SearchBar } from '~/code/common/components'
import translations from '~/code/ODIN/pages/MerchantSelection/translations'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models/Constants'
import { MerchantSearchItem } from '~/code/ODIN/stores/MerchantSelectionStore/models/MerchantSearchItem'
import { MoreOutlined } from '@ant-design/icons'
import { IMerchantSelectionProps } from './props'
import styles from './MerchantSelection.scss'

const { Title, Text, Link } = Typography

export const MerchantSelection = observer((props: IMerchantSelectionProps) => {
    const { store } = props

    const refresh = () => {
        store.loadMerchants()
    }

    const loadNextPage = () => {
        store.loadMerchants(store.pageNumber + 1)
    }

    const loadPrevPage = () => {
        store.loadMerchants(store.pageNumber - 1)
    }

    const loadLastPage = () => {
        store.loadMerchants(store.totalPageItems)
    }

    const loadFirstPage = () => {
        store.loadMerchants(1)
    }

    const moreMenu = (item: MerchantSearchItem) => (
        <div className={styles.lastCommunication}>
            <Link
                onClick={() => {
                    store.setSelectedMerchant(item)
                    page(LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT)
                }}
                disabled={!store.permissions?.includes('odin.deviceManagement.view')}
            >
                {translations().terminalManagement}
            </Link>
            <Link
                onClick={() => {
                    store.setSelectedMerchant(item)
                    page(LOCATION_ODIN_PATH_NAMES.MERCHANT_SCHEDULE)
                }}
                disabled={!store.permissions?.includes('odin.merchantSchedules.view')}
            >
                {translations().merchantSchedule}
            </Link>
            {store.filtersSelectMerchantStore.serialNumber && (
                <Link
                    onClick={() => {
                        store.setSelectedMerchant(item)
                        store.loadMerchantDevices(store.filtersSelectMerchantStore.serialNumber)
                    }}
                >
                    {`${translations().viewDevice} ${store.filtersSelectMerchantStore.serialNumber}`}
                </Link>
            )}
        </div>
    )

    const listItem = (item: MerchantSearchItem) => {
        const tooltip = item.storeNameMatches.map(name => <p key={name}>{name}</p>)

        return (
            <List.Item>
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={23}>
                            <Row gutter={[0, 16]}>
                                <Col xs={24} md={8}>
                                    <Title level={5}>
                                        {item.merchantName}
                                    </Title>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Text>
                                        {item.address}
                                    </Text>
                                </Col>
                                <Col offset={5} xs={24} md={3}>
                                    <Title level={5}>
                                        {item.accountNumber}
                                    </Title>
                                </Col>
                                <Col xs={24} md={24}>
                                    {item.storeNameMatches.length ? (
                                        <Tooltip placement="bottom" title={tooltip}>
                                            <a>
                                                {translations().merchantStoreNamesMatchSearchTerm}
                                            </a>
                                        </Tooltip>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>
                        <Col className={styles.flexAlignCenter} span={1}>
                            <Popover content={moreMenu(item)} placement="bottom" trigger="hover">
                                <MoreOutlined style={{fontSize: '24px'}} />
                            </Popover>
                        </Col>
                    </Row>
                </Card>
            </List.Item>
        )
    }

    const renderPagination = () => {
        return store.merchants.length ? (
            <ListActions
                className={styles.listActions}
                pageInfo={{
                    from: store.merchants.length ? store.pageNumber * store.pageSize - 9 : 0,
                    to: store.pageNumber * store.pageSize > store.totalCount
                        ? store.totalCount
                        : store.pageNumber * store.pageSize,
                    total: store.totalCount
                }}
                currentTotalAmount={store.merchants.length}
                onReloadClick={refresh}
                onGoNextPageClick={loadNextPage}
                onGoPrevPageClick={loadPrevPage}
                isNextPageDisabled={store.pageNumber >= store.totalPageItems}
                isPrevPageDisabled={store.pageNumber <= 1}
                onGoFirstPageClick={loadFirstPage}
                onGoLastPageClick={loadLastPage}
                isGoToFirstPageDisabled={store.pageNumber <= 1}
                isGoToLastPageDisabled={store.pageNumber >= store.totalPageItems}
            />
        ) : null
    }

    return (
        <Page>
            <Title level={3}>{translations().searchMerchant}</Title>

            <SearchBar>
                <FiltersSelect store={store.filtersSelectMerchantStore} />
            </SearchBar>

            <br/>

            {renderPagination()}

            <List
                className={styles.list}
                grid={{
                    gutter: 16,
                    column: 1
                }}
                loading={store.isLoading}
                dataSource={store.merchants}
                renderItem={item => listItem(item)}
            />

            {renderPagination()}
        </Page>
    )
})
