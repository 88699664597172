import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import page from 'page'
import classNames from 'classnames'
import { isEmpty, log } from 'dna-common'
import moment from 'moment'
import { useForm } from 'antd/es/form/Form'
import { Avatar, Button as AntButton, Card, Col, Descriptions, Form, Modal, PageHeader, Row, Select, Skeleton, Space, Tabs, Typography } from 'antd'
import { HddOutlined, InfoCircleOutlined, SyncOutlined, WifiOutlined } from '@ant-design/icons'
import { Page } from '~/code/common/pages/Page'
import { Button, IconButton } from '~/code/common/components'
import { SelectMerchant } from '~/code/ODIN/components/SelectMerchant/SelectMerchant'
import translations from '~/code/ODIN/pages/DeviceManagementDetails/translations'
import { Package } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/Package'
import { DetailsForPerformingInstallation } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DetailsForPerformingInstallation'
import { formatBytes } from '~/code/ODIN/services/utils'
import { SystemInfoModal } from '~/code/ODIN/pages/DeviceManagementDetails/components/SystemInfoModal/SystemInfoModal'
import { ConnectionStatus } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/ConnectionStatus'
import SvgBluetooth from '~/assets/icons/Bluetooth'
import { PrioritySelect } from '~/code/ODIN/pages/DeviceManagementDetails/components/PrioritySelect/PrioritySelect'
import { SvgReload } from '~/assets/icons'
import SvgBatteryLow from '~/assets/icons/BatteryLow'
import SvgBatteryHigh from '~/assets/icons/BatteryHigh'
import MobileSignalLevel from '~/assets/icons/MobileSignalLevel'
import { AppVersion } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AppVersion'
import { Audit } from '~/code/ODIN/pages/DeviceManagementDetails/components/Audit'
import { AppUpdateModal } from '~/code/ODIN/pages/DeviceManagementDetails/components/AppUpdateModal/AppUpdateModal'
import { ManagementActions } from '~/code/ODIN/pages/DeviceManagementDetails/components/ManagementActions'
import { PushTasksModal } from '~/code/ODIN/pages/DeviceManagementDetails/components/PushTasksModal/PushTasksModal'
import { PushNewAppsModal } from '~/code/ODIN/pages/DeviceManagementDetails/components/PushNewAppsModal/PushNewAppsModal'
import { BatteryModal } from '~/code/ODIN/pages/DeviceManagementDetails/components/BatteryModal/BatteryModal'
import { CommunicationMethodModal } from '~/code/ODIN/pages/DeviceManagementDetails/components/CommunicationMethodModal/CommunicationMethodModal'
import { DeviceApplications } from '~/code/ODIN/pages/DeviceManagementDetails/components/DeviceApplications/DeviceApplications'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import { getCommunicationMethodLabelByType } from './utils/getCommunicationMethodLabelByType'
import { IDeviceManagementDetailsProps } from './props'
import styles from './DeviceManagementDetails.scss'
import EthernetGreen from '~/assets/icons/EthernetGreen'
import EthernetRed from '~/assets/icons/EthernetRed'

const {Title, Text} = Typography

export const DeviceManagementDetails = observer((props: IDeviceManagementDetailsProps) => {
    const {store} = props
    const [pickedApp, setPickedApp] = useState<Package>({} as Package)
    const [updateToLatestForm] = useForm()
    const [installNewAppForm] = useForm()
    const [uninstallAppForm] = useForm()
    const [setAsLauncherForm] = useForm()

    useEffect(() => {
        if (props.id) {
            store.setDeviceId(props.id)
            store.loadDeviceDetailsById(props.id)
            store.deviceFirmwareStore.loadPackages(props.id)
            store.notificationPriorityStore.loadNotificationPriorities()
        }
    }, [])

    const onPushTasksClick = () => {
        store.pushTasksStore.setIsPushTasksModalOpen(true)
    }

    const onReloadClick = () => {
        store.loadDeviceDetailsById(props.id)
    }

    const onPackageActionFormFinish = (values: DetailsForPerformingInstallation, type: 'setAsLauncher' | 'uninstall' | 'update' | 'install') => {
        if (!values.packageVersionId) {
            values.packageVersionId = pickedApp.packageVersionId
        }

        if (!values.priorityId) {
            values.priorityId = store.priorityId
        }

        switch (type) {
            case 'setAsLauncher':
                store.applicationFirmwareManagementStore.setAsLauncher(props.id, values)
                store.applicationFirmwareManagementStore.setIsSetAsLauncherModalOpen(false)
                setAsLauncherForm.resetFields()
                break
            case 'uninstall':
                store.applicationFirmwareManagementStore.uninstallApp(props.id, values)
                store.applicationFirmwareManagementStore.setIsAppUninstallModalOpen(false)
                uninstallAppForm.resetFields()
                break
            case 'update':
                store.applicationFirmwareManagementStore.updateApp(props.id, values)
                store.applicationFirmwareManagementStore.setIsUpdateAppToLatestVersionModalOpen(false)
                updateToLatestForm.resetFields()
                break
            case 'install':
                store.applicationFirmwareManagementStore.installApp(props.id, values)
                installNewAppForm.resetFields()
                break
        }
    }

    const onPackageActionFormFinishFailed = (errorInfo: any) => {
        log(errorInfo)
    }

    const renderAppUninstallModal = () => (
        <Modal
            title={`${store.deviceDetails.serialNumber} - ${translations().deleteApp}`}
            visible={store.applicationFirmwareManagementStore.isAppUninstallModalOpen}
            footer={[
                <Button key="back" className={styles.backButton} onClick={() => store.applicationFirmwareManagementStore.setIsAppUninstallModalOpen(false)}>
                    {translations().cancel}
                </Button>,
                <Button
                    key="submit"
                    htmlType="submit"
                    form="uninstallApp"
                    disabled={
                        !store.permissions?.includes('odin.deviceManagement.device.packages.uninstall') &&
                        !store.permissions?.includes('odin.deviceManagement.device.application.uninstall') &&
                        !store.permissions?.includes('odin.deviceManagement.device.firmware.uninstall')
                    }
                >
                    {translations().uninstall}
                </Button>
            ]}
        >
            <Form
                form={uninstallAppForm}
                id="uninstallApp"
                name="uninstallApp"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={(values) => onPackageActionFormFinish(values, 'uninstall')}
                onFinishFailed={onPackageActionFormFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label={translations().installPriority}
                    name="priorityId"
                >
                    <PrioritySelect
                        dataSource={store.notificationPriorityStore.notificationPriorities}
                        onChange={store.setPriorityId}
                    />
                </Form.Item>

                {translations().areYouSureYouWantToDelete(pickedApp.name)}
            </Form>
        </Modal>
    )

    const renderSetAsLauncherModal = () => (
        <Modal
            title={`${store.deviceDetails.serialNumber} - ${translations().changeLauncher}`}
            visible={store.applicationFirmwareManagementStore.isSetAsLauncherModalOpen}
            onCancel={() => store.applicationFirmwareManagementStore.setIsSetAsLauncherModalOpen(false)}
            footer={[
                <Button key="back" className={styles.backButton} onClick={() => store.applicationFirmwareManagementStore.setIsSetAsLauncherModalOpen(false)}>
                    {translations().cancel}
                </Button>,
                <Button disabled={!store.permissions?.includes('odin.deviceManagement.device.launcher.request')} key="submit" htmlType="submit" form="setAsLauncher">
                    {translations().setAsLauncher}
                </Button>
            ]}
        >
            <Form
                form={setAsLauncherForm}
                id="setAsLauncher"
                name="setAsLauncher"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={(values) => {
                    store.setPriorityId(1)
                    onPackageActionFormFinish(values, 'setAsLauncher')
                }}
                onFinishFailed={onPackageActionFormFinishFailed}
                autoComplete="off"
            >
                {translations().areYouSureYouWantToSetLauncher(pickedApp.name)}
            </Form>
        </Modal>
    )

    const renderAvailablePackagesSelect = (appVersions: AppVersion[]) => {
        return (
            <Select placeholder={translations().selectPackageVersion} style={{ width: 200 }}>
                {
                    appVersions.map(version => (
                        <Select.Option key={version.packageVersionId} value={version.packageVersionId}>{version.version}</Select.Option>
                    ))
                }
            </Select>
        )
    }

    const manufacturer = store.deviceTypesStore.deviceTypes
        ?.find(deviceType => deviceType.params.manufacturer === store.deviceDetails.manufacturer && deviceType.params.model === store.deviceDetails.model)

    const startupDateTime = moment(store.deviceDetails.startupDateTime)
    const currentDate = moment()

    const differenceDate = moment.duration(currentDate.diff(startupDateTime))
    const differenceLabel = `
        ${differenceDate.months() ? `${differenceDate.months()} months, ` : ''}
        ${differenceDate.days() ? `${differenceDate.days()} days, ` : ''}
        ${differenceDate.hours() ? `${differenceDate.hours()} hours, ` : ''}
        ${differenceDate.minutes() ? `${differenceDate.minutes()} minutes` : ''}`

    const getUptimeLabel = () => {
        if (store.deviceDetails.online && store.deviceDetails.isRegistered) {
            return differenceLabel
        }

        if (!store.deviceDetails.online && store.deviceDetails.isRegistered) {
            return `${translations().lastTimeDeviceWasOnline} ${moment(store.deviceDetails.startupDateTime).format('DD/MM/YYYY HH:mm:ss')}`
        }

        return 'N/A'
    }

    const getStatus = () => {
        if (store.deviceDetails.isRegistered) {
            return store.deviceDetails.online ? translations().online : translations().offline
        } else {
            return translations().odinRegistrationRequired
        }
    }

    const getIconLabel = (text: string) => `${store.deviceDetails.isRegistered ? text : 'N/A'}`

    const isIconShown = (status: ConnectionStatus) => status !== ConnectionStatus.NotSet && status !== ConnectionStatus.Disabled && !isEmpty(status)

    const getNetworkStatusLabel = (status: ConnectionStatus) => {
        if (status === ConnectionStatus.Enabled) {
            return translations().notConnected
        }

        if (status === ConnectionStatus.Connected) {
            return translations().connected
        }

        return 'N/A'
    }

    const getMemoryRatio = () => {
        return store.deviceDetails.usedBytes / (store.deviceDetails.usedBytes + store.deviceDetails.availableBytes)
    }

    const renderUpgradeBtn = (firmware) => {
        if (store.permissions?.includes('odin.deviceManagement.device.firmware.install') && firmware.newestVersion !== firmware.version) {
            if (firmware.newestVersion) {
                return (
                    <Button
                        className={styles.upgradeBtn}
                        onClick={() => {
                            store.applicationFirmwareManagementStore.loadAvailablePackages(props.id, {packageTypeId: firmware.packageTypeId})
                            store.applicationFirmwareManagementStore.setIsUpdateFirmwareToLatestVersionModalOpen(true)
                            store.applicationFirmwareManagementStore.loadAvailablePackageVersions(firmware.packageId)
                        }}
                        disabled={
                            !store.deviceDetails.isRegistered ||
                            !store.permissions?.includes('odin.deviceManagement.device.firmware.install') ||
                            firmware.packageId === 0
                        }
                    >
                        {translations().upgradeFirmware}
                    </Button>
                )
            } else {
                return (
                    <Text
                        className={styles.upToDate}
                        type={'success'}
                    >
                        {translations().upToDate}
                    </Text>
                )
            }
        } else return null
    }

    return (
        <Page>
            {
                !store.selectedMerchant && (
                    <SelectMerchant/>
                )
            }

            {
                store.selectedMerchant && (
                    <>
                        <PageHeader
                            ghost={false}
                            onBack={() => page(LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT)}
                            className={styles.pageHeader}
                            title={
                                <Title className={styles.title} level={3}>
                                    {`${translations().deviceManagement} - ${store.deviceDetails.serialNumber}`}
                                </Title>
                            }
                            extra={store.permissions?.includes('odin.deviceManagement.device.forceResync.request') && [
                                <Button
                                    key={'forceReSync'}
                                    loading={store.isForceReSyncLoading}
                                    onClick={() => store.forceReSync(props.id)}
                                    icon={<SyncOutlined />}
                                    disabled={!store.deviceDetails.isRegistered}
                                >
                                    {store.isForceReSyncLoading ? translations().thisPageWillRefresh : translations().forceReSync}
                                </Button>
                            ]}
                        >
                            <Card loading={store.isDetailsLoading}>
                                <Card.Meta
                                    description={
                                        <>
                                            <Descriptions
                                                title={<div className={styles.deviceInfo}>
                                                    <div className={styles.deviceInfoTitle}>{translations().deviceInfo}</div>
                                                    <IconButton icon={<SvgReload/>} className={styles.reload} onClick={onReloadClick} />
                                                </div>}
                                                size="small"
                                                column={2}
                                                extra={
                                                    store.permissions?.includes('odin.deviceManagement.device.pushTasks.view') &&
                                                        <Button disabled={!store.deviceDetails.isRegistered} onClick={() => onPushTasksClick()}>{translations().pushTasks}</Button>
                                                }
                                            >
                                                <Descriptions.Item className={styles.serialNumber} label={<Text type={'secondary'}>{translations().serialNumber}</Text>}>
                                                    <Text strong>{store.deviceDetails.serialNumber}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().merchantName}</Text>}>
                                                    <Text strong>{store.deviceDetails.merchantName}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().merchantStore}</Text>}>
                                                    <Text strong>{store.deviceDetails.merchantStoreName}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().friendlyName}</Text>}>
                                                    <Text
                                                        editable={{ onChange: (value) => store.patchFriendlyName(props.id, value) }}
                                                        strong
                                                    >
                                                        {store.deviceDetails.friendlyName}
                                                    </Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().lastCommunication}</Text>}>
                                                    <Text strong>{store.deviceDetails.lastCommunication ? moment(store.deviceDetails.lastCommunication).format('DD/MM/YYYY HH:mm') : translations().noData}</Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().status}</Text>}>
                                                    <Text strong>{getStatus()}</Text>
                                                </Descriptions.Item>
                                            </Descriptions>

                                            <Descriptions colon={false} size="small" column={2}>
                                                <Descriptions.Item>
                                                    <Row gutter={[16, 16]}>
                                                        {store.permissions?.includes('odin.deviceManagement.device.batteryHistory.view') && store.deviceDetails.batteryLevel && (
                                                            <Col>
                                                                <Space
                                                                    className={
                                                                        classNames(styles.deviceIconData, styles.battery, {
                                                                            [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                        })
                                                                    }
                                                                    align="center"
                                                                    direction="vertical"
                                                                    onClick={() => {
                                                                        if (!store.deviceDetails.isRegistered) {
                                                                            return
                                                                        }
                                                                        store.deviceBatteryHistoryStore.loadBatteryHistory(props.id)
                                                                        store.deviceBatteryHistoryStore.setIsBatteryHistoryModalOpen(true)
                                                                    }}
                                                                >
                                                                    {store.deviceDetails.batteryLevel < 50 ? (
                                                                        <SvgBatteryLow className={classNames(styles.icon, styles.iconRed)}/>
                                                                    ) : null}
                                                                    {store.deviceDetails.batteryLevel >= 50 ? (
                                                                        <SvgBatteryHigh className={classNames(styles.icon, styles.iconGreen)}/>
                                                                    ) : null}
                                                                    <div className={classNames(styles.clickableIcon, {
                                                                        [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                    })}>{getIconLabel(`${store.deviceDetails.batteryLevel}%`)}</div>

                                                                </Space>
                                                            </Col>
                                                        )}
                                                        {isIconShown(store.deviceDetails.wiFi) && (
                                                            <Col>
                                                                <Space className={styles.deviceIconData} align="center" direction="vertical">
                                                                    <WifiOutlined className={classNames(styles.icon, {
                                                                        [styles.iconRed]: store.deviceDetails.wiFi === ConnectionStatus.Enabled,
                                                                        [styles.iconGreen]: store.deviceDetails.wiFi === ConnectionStatus.Connected,
                                                                        [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                    })}/>
                                                                    {getNetworkStatusLabel(store.deviceDetails.wiFi)}
                                                                </Space>
                                                            </Col>
                                                        )}
                                                        {isIconShown(store.deviceDetails.mobile) && (
                                                            <Col>
                                                                <Space className={styles.deviceIconData} align="center" direction="vertical">
                                                                    <MobileSignalLevel className={classNames(styles.icon, {
                                                                        [styles.iconRed]: store.deviceDetails.mobile === ConnectionStatus.Enabled,
                                                                        [styles.iconGreen]: store.deviceDetails.mobile === ConnectionStatus.Connected,
                                                                        [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                    })}/>
                                                                    {getNetworkStatusLabel(store.deviceDetails.mobile)}
                                                                </Space>
                                                            </Col>
                                                        )}
                                                        {isIconShown(store.deviceDetails.bluetooth) && (
                                                            <Col>
                                                                <Space className={styles.deviceIconData} align="center" direction="vertical">
                                                                    <SvgBluetooth className={classNames(styles.icon, {
                                                                        [styles.iconRed]: store.deviceDetails.bluetooth === ConnectionStatus.Enabled,
                                                                        [styles.iconGreen]: store.deviceDetails.bluetooth === ConnectionStatus.Connected,
                                                                        [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                    })}/>
                                                                    {getNetworkStatusLabel(store.deviceDetails.bluetooth)}
                                                                </Space>
                                                            </Col>
                                                        )}
                                                        <Col>
                                                            <Space className={styles.deviceIconData} align="center" direction="vertical">
                                                                <HddOutlined className={classNames(styles.icon, {
                                                                    [styles.iconDisabled]: !store.deviceDetails.isRegistered,
                                                                    [styles.iconOrange]: getMemoryRatio() > 0.32 && getMemoryRatio() < 0.66,
                                                                    [styles.iconGreen]: getMemoryRatio() < 0.33,
                                                                    [styles.iconRed]: getMemoryRatio() > 0.65
                                                                })}/>
                                                                {getIconLabel(`${formatBytes(store.deviceDetails.usedBytes)}/${formatBytes(store.deviceDetails.availableBytes + store.deviceDetails.usedBytes)}`)}
                                                            </Space>
                                                        </Col>
                                                        {store.deviceDetails.ethernet === ConnectionStatus.Connected ? (
                                                            <Col>
                                                                <Space className={styles.deviceIconData} align="center" direction="vertical">
                                                                    <EthernetGreen className={styles.icon}/>
                                                                    {translations().connected}
                                                                </Space>
                                                            </Col>
                                                        ) : null}
                                                        {store.deviceDetails.ethernet === ConnectionStatus.Enabled ? (
                                                            <Col>
                                                                <Space className={styles.deviceIconData} align="center" direction="vertical">
                                                                    <EthernetRed className={styles.icon}/>
                                                                    {translations().notConnected}
                                                                </Space>
                                                            </Col>
                                                        ) : null}
                                                        <Col>
                                                            <Space
                                                                className={classNames(styles.deviceIconData, styles.clickableIcon, {
                                                                    [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                })}
                                                                align="center"
                                                                direction="vertical"
                                                                onClick={() => {
                                                                    if (!store.deviceDetails.isRegistered) {
                                                                        return
                                                                    }
                                                                    store.systemInfoModalStore.setIsSystemInfoModalOpen(true)
                                                                }}
                                                            >
                                                                <InfoCircleOutlined className={classNames(styles.icon, {
                                                                    [styles.iconDisabled]: !store.deviceDetails.isRegistered
                                                                })}/>
                                                                {translations().systemInfo}
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Descriptions.Item>
                                            </Descriptions>

                                            <Descriptions colon={false} size="small" column={2}>
                                                {store.permissions?.includes('odin.deviceManagement.device.commsMethodHistory.view') && (
                                                    <Descriptions.Item label={<Text type={'secondary'}>{translations().lastCommsMethodUsed}</Text>}>
                                                        <div className={styles.lastCommunication}>
                                                            <Text strong>
                                                                {getCommunicationMethodLabelByType(+store.deviceDetails.lastCommunicationMediaType) || translations().noData}
                                                            </Text>
                                                            <AntButton
                                                                onClick={() => {
                                                                    store.deviceCommunicationsMethodHistoryStore.loadCommunicationsMethodHistory(props.id)
                                                                    store.deviceCommunicationsMethodHistoryStore.setIsCommunicationHistoryModalOpen(true)
                                                                }}
                                                                className={styles.link}
                                                                type={'link'}
                                                                disabled={!store.deviceDetails.isRegistered}
                                                            >
                                                                {translations().seeHistory}
                                                            </AntButton>
                                                        </div>
                                                    </Descriptions.Item>
                                                )}
                                                {store.permissions?.includes('odin.deviceManagement.device.uptimeHistory.view') && store.deviceDetails.startupDateTime && (
                                                    <Descriptions.Item label={<Text type={'secondary'}>{translations().uptime}</Text>}>
                                                        <div className={styles.lastCommunication}>
                                                            <Text strong>{getUptimeLabel()}</Text>
                                                        </div>
                                                    </Descriptions.Item>
                                                )}
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().lastMetricReceivedDateTime}</Text>}>
                                                    <div className={styles.lastCommunication}>
                                                        <Text strong>{getIconLabel(moment(store.deviceDetails.lastMetricReceivedDateTime).format('DD/MM/YY HH:mm:ss') || translations().noData)}</Text>
                                                    </div>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </>
                                    }
                                    avatar={<div className={styles.deviceInfoAvatar}>
                                        <Avatar className={styles.avatar} src={manufacturer?.params?.imageUrl} />
                                        <br/>
                                        {`${manufacturer?.params?.manufacturer} - ${manufacturer?.params?.model}`}
                                    </div>}
                                />
                            </Card>
                        </PageHeader>
                        <br/>
                        <Tabs defaultActiveKey="appAndFirmwareManagement">
                            <Tabs.TabPane tab={translations().appAndFirmwareManagement} key="appAndFirmwareManagement">
                                {store.deviceFirmwareStore?.packages?.length > 0 ? (
                                    <div className={styles.firmwareTitle}>{translations().firmware}
                                        <SvgReload className={styles.reloadIcon} onClick={() => store.deviceFirmwareStore.loadPackages(props.id)}/>
                                    </div>
                                ) : null}
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={16} md={20} className={styles.upgradeFirmware}>
                                        {store.deviceFirmwareStore.isLoading ? <Skeleton active={true}/> : null}
                                        {store.deviceFirmwareStore.packages.map(firmware => (
                                            <>
                                                <Row className={styles.firmwareContainer}>
                                                    <div
                                                        key={firmware.packageVersionId}
                                                        className={styles.firmware}
                                                    >
                                                        {`${firmware.name} - ${firmware.version}`}
                                                    </div>
                                                    {firmware.newestVersion === firmware.version && (
                                                        <Text
                                                            className={styles.upToDate}
                                                            type={'success'}
                                                        >
                                                            {translations().upToDate}
                                                        </Text>
                                                    )}
                                                    {renderUpgradeBtn(firmware)}
                                                </Row>
                                            </>
                                        ))}
                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <div className={styles.applicationTitleContainer}>
                                    <div className={styles.firmwareTitle}>{translations().application}</div>
                                    {store.permissions?.includes('odin.deviceManagement.device.application.install') && (
                                        <Button
                                            className={styles.upgradeBtn}
                                            onClick={() => {
                                                store.pushAppsStore.setIsPushAppsModalOpen(true)
                                            }}
                                            disabled={!store.deviceDetails.isRegistered && !store.permissions?.includes('odin.deviceManagement.device.application.install')}
                                        >
                                            {translations().pushApp}
                                        </Button>
                                    )}
                                </div>
                                {store.permissions?.includes('odin.deviceManagement.view') && (
                                    <DeviceApplications
                                        packagesStore={store.devicePackagesStore}
                                        applicationManagementStore={store.applicationFirmwareManagementStore}
                                        appDeveloperStore={store.appDeveloperStore}
                                        isRegistered={store.deviceDetails.isRegistered}
                                        deviceId={props.id}
                                        setPickedApp={setPickedApp}
                                        permissions={store.permissions}
                                        deviceDetails={store.deviceDetails}
                                    />
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane disabled={!store.deviceDetails.isRegistered} tab={translations().managementActions} key="managementActions">
                                <ManagementActions deviceDetails={store.deviceDetails} deviceId={props.id} store={store.managementActionsStore}/>
                            </Tabs.TabPane>
                            {store.permissions?.includes('odin.deviceManagement.device.auditLog.view') && (
                                <Tabs.TabPane disabled={!store.deviceDetails.isRegistered} tab={translations().audit} key="audit">
                                    <Audit deviceId={props.id} store={store.auditStore}/>
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                        {store.deviceCommunicationsMethodHistoryStore.isCommunicationHistoryModalOpen &&
                            <CommunicationMethodModal store={store.deviceCommunicationsMethodHistoryStore} deviceDetails={store.deviceDetails}/>}
                        {store.deviceBatteryHistoryStore.isBatteryHistoryModalOpen &&
                            <BatteryModal store={store.deviceBatteryHistoryStore} deviceDetails={store.deviceDetails}/>}
                        {renderAppUninstallModal()}
                        {renderSetAsLauncherModal()}
                        <AppUpdateModal
                            form={updateToLatestForm}
                            isVisible={store.applicationFirmwareManagementStore.isUpdateAppToLatestVersionModalOpen}
                            isDeviceRegistered={store.deviceDetails.isRegistered}
                            title={translations().updateApp}
                            serialNumber={store.deviceDetails.serialNumber}
                            onOk={onPackageActionFormFinish}
                            onClose={() => store.applicationFirmwareManagementStore.setIsUpdateAppToLatestVersionModalOpen(false)}
                            notificationPriorities={store.notificationPriorityStore.notificationPriorities}
                            setPriorityId={store.setPriorityId}
                            renderAvailablePackagesSelect={() => renderAvailablePackagesSelect(store.applicationFirmwareManagementStore.appVersions)}
                        />

                        <AppUpdateModal
                            form={updateToLatestForm}
                            isVisible={store.applicationFirmwareManagementStore.isUpdateFirmwareToLatestVersionModalOpen}
                            isDeviceRegistered={store.deviceDetails.isRegistered}
                            title={translations().updateFirmware}
                            serialNumber={store.deviceDetails.serialNumber}
                            onOk={onPackageActionFormFinish}
                            onClose={() => store.applicationFirmwareManagementStore.setIsUpdateFirmwareToLatestVersionModalOpen(false)}
                            notificationPriorities={store.notificationPriorityStore.notificationPriorities}
                            setPriorityId={store.setPriorityId}
                            renderAvailablePackagesSelect={() => renderAvailablePackagesSelect(store.applicationFirmwareManagementStore.appVersions)}
                        />

                        <PushNewAppsModal
                            store={store.pushAppsStore}
                            deviceId={props.id}
                            onFinish={onPackageActionFormFinish}
                            onFinishFailed={onPackageActionFormFinishFailed}
                        />

                        {store.pushTasksStore.isPushTasksModalOpen && (
                            <PushTasksModal
                                deviceId={props.id}
                                store={store.pushTasksStore}
                                title={`${store.deviceDetails.serialNumber} - ${store.deviceDetails.friendlyName} - ${translations().pushTaskProgress}`}
                            />
                        )}
                        {store.systemInfoModalStore.isSystemInfoModalOpen && (
                            <SystemInfoModal
                                deviceId={props.id}
                                store={store.systemInfoModalStore}
                                title={`${store.deviceDetails.serialNumber} - ${store.deviceDetails.manufacturer} ${store.deviceDetails.model} - ${translations().systemInfo}`}
                            />
                        )}
                    </>
                )
            }
        </Page>
    )
})
