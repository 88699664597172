// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3NJ08IfG9KT2BFOeIgM8iv {\n  width: 100%;\n  background: #FFFFFF;\n  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  padding: 24px; }\n  .iTZQae3bWa8TMoncq6H8P {\n    font-family: Poppins;\n    font-style: normal;\n    font-size: 25px;\n    line-height: 25px;\n    color: #000000;\n    padding-bottom: 32px; }\n  ._2Jsu9G6IcskM6A8oA6Klz4 {\n    margin-bottom: 24px; }\n  ._1UymKn4sFHDv-fKluQnkr {\n    display: flex;\n    align-items: center; }\n  ._3fihYr_B3zERwFlSEWjtFb {\n    text-align: right; }\n  ._1MFUXNQEIyPcWG1JyaOV2v {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 19px;\n    color: #8C8C8C; }\n    ._1MFUXNQEIyPcWG1JyaOV2v:hover {\n      color: #8C8C8C; }\n  ._3NJ08IfG9KT2BFOeIgM8iv .ant-form-item {\n    margin-bottom: unset; }\n  ._3NJ08IfG9KT2BFOeIgM8iv input:-webkit-autofill,\n  ._3NJ08IfG9KT2BFOeIgM8iv input:-webkit-autofill:hover,\n  ._3NJ08IfG9KT2BFOeIgM8iv input:-webkit-autofill:focus,\n  ._3NJ08IfG9KT2BFOeIgM8iv input:-webkit-autofill:active {\n    -webkit-box-shadow: 0 0 0 30px white inset !important; }\n  ._3NJ08IfG9KT2BFOeIgM8iv input {\n    -webkit-box-shadow: 0 0 0 30px white inset !important;\n    filter: none; }\n", ""]);
// Exports
exports.locals = {
	"LoginForm": "_3NJ08IfG9KT2BFOeIgM8iv",
	"LoginForm_title": "iTZQae3bWa8TMoncq6H8P",
	"LoginForm_inputContainer": "_2Jsu9G6IcskM6A8oA6Klz4",
	"LoginForm_footerContainer": "_1UymKn4sFHDv-fKluQnkr",
	"LoginForm_submitButtonContainer": "_3fihYr_B3zERwFlSEWjtFb",
	"LoginForm_forgotPassword": "_1MFUXNQEIyPcWG1JyaOV2v"
};
module.exports = exports;
