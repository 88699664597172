// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2EPw8VwT9eObhdzR6I9G-2 {\n  margin-bottom: 8px; }\n  ._2EPw8VwT9eObhdzR6I9G-2 .ant-card-body {\n    padding: 8px; }\n\n._3ke-TVnnjTLFu9nXOnRRkJ {\n  margin-right: 8px; }\n\n._2kAlPCvWVz2TiDZs2sUXfe .ant-collapse-item .ant-collapse-header {\n  padding: 0; }\n\n._2hBCWYfvXnkYsmLVlVc8Hv {\n  text-decoration: underline; }\n", ""]);
// Exports
exports.locals = {
	"body": "_2EPw8VwT9eObhdzR6I9G-2",
	"backButton": "_3ke-TVnnjTLFu9nXOnRRkJ",
	"collapse": "_2kAlPCvWVz2TiDZs2sUXfe",
	"inUseApnTitle": "_2hBCWYfvXnkYsmLVlVc8Hv"
};
module.exports = exports;
