import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    companyName: string
    orderRef: string
    orderPlacedOn: string
    orderPlacedBy: string
    deliverTo: string
    bundles: string
    status: string
}>()

