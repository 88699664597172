import translations from '../translations'

export const companyTypes = {
    '10000': translations().directMerchant,
    '10001': translations().resellerCustomer,
    '10002': translations().agent,
    '10003': translations().partner,
    '10005': translations().dealerEposSupplier,
    '10006': translations().referrer,
    '10007': translations().parentCompany,
    '10008': translations().iso,
    '10009': translations().acquirer
}