import React from 'react'
import { PlaceOrderConfigurationStore } from '~/code/stores/PlaceOrderConfigurationStore/PlaceOrderConfigurationStore'
import translations from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration/translations/translations'
import { COUNTRIES, InlineSelect, MERCHANT_TYPES, ORDER_TYPES, ORGANISATION_TYPES } from '~/code/POS/pages'
import { OrderType } from '~/code/POS/pages/NewOrder/models/constants'
import { action, computed, makeObservable, observable } from 'mobx'

export class PlaceOrderConfiguration123SendStore extends PlaceOrderConfigurationStore {

    constructor (parentStore) {
        super(parentStore)

        makeObservable(this, {
            orderType: observable,
            customContent: computed,
            setSelectedOrderType: action,
            onOrderTypeChange: action
        })
    }

    orderType: OrderType

    clearAndSetup() {
        super.clearAndSetup()
        this.orderType = 'standard'
    }

    get customContent() {
        return <>
            {translations().orderingFor}
            <InlineSelect
                key={'merchantType'}
                selectItems={MERCHANT_TYPES}
                value={this.merchantType}
                shouldAskSelectConfirmation={this.parentStore.shouldShowForm}
                selectConfirmationTitle={translations().areYouSureToChangeMerchantType}
                selectConfirmationYesButtonText={translations().yesSureToChangeMerchantType}
                selectConfirmationNoButtonText={translations().noKeepMerchantType}
                onSelect={this.onMerchantTypeChange}/>
            {translations().merchant}
            {
                this.merchantType === 'existing' &&
                <>
                    {translations().ofType}
                    <InlineSelect
                        key={'orderType'}
                        selectItems={ORDER_TYPES}
                        value={this.orderType}
                        shouldAskSelectConfirmation={this.parentStore.shouldShowForm}
                        selectConfirmationTitle={translations().areYouSureToChangeOrderType}
                        selectConfirmationYesButtonText={translations().yesSureToChangeOrderType}
                        selectConfirmationNoButtonText={translations().noKeepOrderType}
                        onSelect={this.onOrderTypeChange}/>
                </>
            }

            {
                this.shouldShowOrganisationTypeSelection &&
                <>
                    {this.organisationType === 'soleTrader' ? translations().organisationTypeSoleTrader : translations().organisationTypeCompany}
                    <InlineSelect
                        key={'organisationType'}
                        selectItems={ORGANISATION_TYPES}
                        value={this.organisationType}
                        shouldAskSelectConfirmation={this.parentStore.shouldShowForm}
                        selectConfirmationTitle={translations().areYouSureToChangeOrganisationType}
                        selectConfirmationYesButtonText={translations().yesSureToChangeOrganisationType}
                        selectConfirmationNoButtonText={translations().noKeepOrganisationType}
                        onSelect={this.onOrganisationTypeChange}/>
                </>
            }

            {
                this.shouldShowCountrySelection &&
                <>
                    {translations().andLocatedIn}
                    <InlineSelect
                        key={'country'}
                        selectItems={COUNTRIES}
                        value={this.country}
                        shouldAskSelectConfirmation={this.parentStore.shouldShowForm}
                        selectConfirmationTitle={translations().areYouSureToChangeCountry}
                        selectConfirmationYesButtonText={translations().yesSureToChangeCountry}
                        selectConfirmationNoButtonText={translations().noKeepCountry}
                        onSelect={this.onCountryChange}/>
                </>
            }
        </>
    }

    setSelectedOrderType = (orderType: OrderType) => {
        this.orderType = orderType
    }

    onOrderTypeChange = (orderType) => {
        if (orderType === this.orderType) return
        this.setSelectedOrderType(orderType)
        this.parentStore.clearAndSetUp()
    }
}
