// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1LhuiOoNnGAK7wCkTg6TKl ._24LOiRbZ9kDXqabB4ywRh- {\n  min-height: 73.1333px;\n  display: flex;\n  align-items: flex-end;\n  padding-top: 38px; }\n\n._1LhuiOoNnGAK7wCkTg6TKl ._2SXuVs-jaJBNO2J88nCNmy {\n  padding-top: 16px; }\n\n._1LhuiOoNnGAK7wCkTg6TKl .PPPzxm7uiTdJUbR5POgho {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n\n._1LhuiOoNnGAK7wCkTg6TKl ._3dMv7WcOZo5q0ho9QF85t_ {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 24px;\n  color: #000000;\n  height: 73.1333px;\n  margin-bottom: 26px;\n  display: flex;\n  align-items: flex-end; }\n\n._1LhuiOoNnGAK7wCkTg6TKl ._1l4AlkPuxJpFsX-2KiKc8o {\n  background: #F5F5F5;\n  border-radius: 8px;\n  margin-left: -50px;\n  padding-left: 50px;\n  margin-right: -10px;\n  padding-right: 10px;\n  margin-top: -10px;\n  padding-top: 10px; }\n\n._1LhuiOoNnGAK7wCkTg6TKl ._2ukzpkW7Fi1Q05mgGmvWA- {\n  margin-bottom: 10px; }\n\n._1LhuiOoNnGAK7wCkTg6TKl ._1uJyn0phoaAnxlk-EeZEEI {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px;\n  line-height: 20px;\n  text-transform: uppercase;\n  color: #00A0DC;\n  transform: rotate(-90deg);\n  transform-origin: left top 0;\n  position: relative;\n  top: 229px;\n  left: -40px;\n  height: 0px; }\n", ""]);
// Exports
exports.locals = {
	"ProcessingDetailsForm": "_1LhuiOoNnGAK7wCkTg6TKl",
	"checkBoxContainer": "_24LOiRbZ9kDXqabB4ywRh-",
	"goBackButtonContainer": "_2SXuVs-jaJBNO2J88nCNmy",
	"submitButtonContainer": "PPPzxm7uiTdJUbR5POgho",
	"subgroupTitle": "_3dMv7WcOZo5q0ho9QF85t_",
	"amexContainer": "_1l4AlkPuxJpFsX-2KiKc8o",
	"lessMargin": "_2ukzpkW7Fi1Q05mgGmvWA-",
	"amexTitle": "_1uJyn0phoaAnxlk-EeZEEI"
};
module.exports = exports;
