import { MenuItem } from '~/code/common/layouts/MainLayout/components/SideMenu/models'
import { isEmpty } from 'dna-common'

export function computeSelectedKey(pathname: string, items: MenuItem[]) {
    let selectedKey = ''
    for (const item of items) {
        if (pathname.startsWith(item.route)) {
            selectedKey = item.route
        }

        if (!isEmpty(item.items)) {
            selectedKey = computeSelectedKey(pathname, item.items) || selectedKey
        }
    }
    return selectedKey
}

export function computeOpenKeys(pathname: string, items: MenuItem[]) {
    const openKeys = []
    for (const item of items) {
        if (pathname.startsWith(item.route) && !isEmpty(item.items)) {
            openKeys.push(item.route)
        }

        if (!isEmpty(item.items)) {
            openKeys.push(...computeOpenKeys(pathname, item.items))
        }
    }
    return openKeys
}
