import { getWithAuth, putWithAuth } from '~/code/services/authorised-requests'
import { checkODINHTTPStatus } from '~/code/services/http-response-handler'
import { MerchantMaintenanceScheduleConfig } from '~/code/ODIN/stores/MerchantScheduleStore/models/MerchantMaintenanceScheduleConfig'
import { DeviceStatusSettings } from '~/code/ODIN/stores/MerchantScheduleStore/models/DeviceStatusSettings'

export const fetchSaveMaintenanceConfiguration = (merchantId: number, body: MerchantMaintenanceScheduleConfig) => {
    const headers = []

    headers.push(['id', merchantId])
    return checkODINHTTPStatus(putWithAuth<MerchantMaintenanceScheduleConfig>(`/odin-api/api/Merchants/${merchantId}/Configurations/Maintenance`, body, headers))
}

export const fetchSaveDeviceStatusConfiguration = (merchantId: number, body: DeviceStatusSettings) => {
    const headers = []

    headers.push(['id', merchantId])
    return checkODINHTTPStatus(putWithAuth<DeviceStatusSettings>(`/odin-api/api/Merchants/${merchantId}/Configurations/DeviceStatus`, body, headers))
}

export const fetchMaintenanceConfiguration = (merchantId: number) => {
    return checkODINHTTPStatus(getWithAuth<MerchantMaintenanceScheduleConfig>(`/odin-api/api/Merchants/${merchantId}/Configurations/Maintenance`))
}

export const fetchOwnerGroupsMaintenanceConfiguration = (ownerGroupId: number) => {
    return checkODINHTTPStatus(getWithAuth<MerchantMaintenanceScheduleConfig>(`/odin-api/api/OwnerGroups/${ownerGroupId}/Configurations/Maintenance`))
}

export const fetchDeviceStatusConfiguration = (merchantId: number) => {
    return checkODINHTTPStatus(getWithAuth<DeviceStatusSettings>(`/odin-api/api/Merchants/${merchantId}/Configurations/DeviceStatus`))
}

export const fetchOwnerGroupsDeviceStatusConfiguration = (ownerGroupId: number) => {
    return checkODINHTTPStatus(getWithAuth<DeviceStatusSettings>(`/odin-api/api/OwnerGroups/${ownerGroupId}/Configurations/DeviceStatus`))
}
