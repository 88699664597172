import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { SMALL_PAGE_SIZE } from '~/code/models'
import { error, isEmpty } from 'dna-common'
import { IDeviceManagementStore } from '~/code/ODIN/pages/DeviceManagement/IDeviceManagementStore'
import { IAppStore } from '~/code/IAppStore'
import { MerchantDevice } from '~/code/ODIN/stores/DeviceManagementStore/models/MerchantDevice'
import { DeviceFilter } from '~/code/ODIN/stores/DeviceManagementStore/models/DeviceFilter'
import { MerchantSearchItem } from '../MerchantSelectionStore/models/MerchantSearchItem'
import { addDeviceType, fetchDevices } from './services/fetchers'
import { DeviceTypeStore } from '~/code/ODIN/stores/DeviceManagementStore/DeviceTypeStore'
import { IDeviceTypeStore } from '~/code/ODIN/pages/DeviceManagement/IDeviceTypeStore'
import { SelectItem } from '~/code/common/components'
import { AddDeviceTypeData } from '~/code/ODIN/stores/DeviceManagementStore/models/AddDeviceTypeData'
import { message, notification } from 'antd'
import translations from '~/code/ODIN/pages/DeviceManagement/translations'
import React from 'react'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { get, get as getFromStore } from 'store-connector'
import { download } from '~/code/ODIN/services/utils'
import { DeviceManufacturerStore } from '~/code/ODIN/stores/ApplicationManagementStore/DeviceManufacturerStore'
import { IDeviceManufacturerStore } from '~/code/ODIN/pages/ApplicationManagement/IDeviceManufacturerStore'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import commonTranslations from '~/code/translations/translations'

export class DeviceManagementStore implements IDeviceManagementStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            isAddBundleModalOpen: observable,
            isAddDeviceTypeLoading: observable,
            options: observable,
            devices: observable,
            pageNumber: observable,
            totalPageItems: observable,
            pageSize: observable,
            selectedMerchant: computed,
            isDeviceTypesLoading: computed,
            selectDeviceTypes: computed,
            permissions: computed,
            merchantDevices: computed,
            loadDevices: action,
            setIsAddBundleModalOpen: action,
            closeAddBundleModal: action,
            loadDeviceTypes: action,
            addDeviceType: action,
            downloadDevicesCSV: action
        })

        this.parentStore = parentStore
        this.deviceTypeStore = new DeviceTypeStore()
        this.deviceManufacturerStore = new DeviceManufacturerStore()

        autorun(() => {
            if (this.selectedMerchant && parentStore.isAuthenticated()) {
                runInAction(() => {
                    this.devices = []
                    this.options = null
                    this.loadDevices()
                })
            }
        }, {delay: 100})
    }

    public parentStore: IAppStore
    public deviceTypeStore: IDeviceTypeStore
    public deviceManufacturerStore: IDeviceManufacturerStore
    public pageNumber = 1
    public pageSize = SMALL_PAGE_SIZE
    public totalPageItems: number = 1
    public totalCount: number = 0
    public isAddBundleModalOpen: boolean = false
    public isLoading: boolean = false
    public isAddDeviceTypeLoading: boolean = false
    public devices: MerchantDevice[] = []
    public options: DeviceFilter = {
        manufacturer: '',
        model: '',
        serialNumber: '',
        hardwarePtid: '',
        merchantStoreId: '',
        createdDateTime: '',
        friendlyName: '',
        isRegistered: null,
        online: null
    }

    public get selectedMerchant(): MerchantSearchItem {
        return this.parentStore?.odinSelectedMerchant
    }

    public get permissions(): string[] {
        return this.parentStore?.userInfo?.permissions
    }

    public get isDeviceTypesLoading(): boolean {
        return this.deviceTypeStore?.isLoading
    }

    public get selectDeviceTypes(): SelectItem[] {
        return this.deviceTypeStore?.deviceTypes
    }

    public get merchantDevices(): MerchantDevice[] {
        return this.devices
    }

    public loadDevices = (pageNumber?: number) => {
        if (this.isLoading) { return }

        this.isLoading = true
        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber
        if (this.pageNumber === 1) {
            this.devices = []
        }

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            ...this.options
        }

        if (get('portalType') !== 'ODIN') {
            return
        }

        fetchDevices(this.selectedMerchant?.merchantId, queryParams)
            .then(response => {
                runInAction(() => {
                    const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                    this.devices = response?.result?.length > 0 ? response?.result?.map(device => ({...device, key: device.deviceId})) : []
                    this.totalPageItems = pagination.totalPages
                    this.totalCount = pagination.totalCount
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    public addDeviceType = (addDeviceTypeData: AddDeviceTypeData) => {
        this.isAddDeviceTypeLoading = true

        addDeviceType(this.selectedMerchant?.merchantId, addDeviceTypeData)
            .then(_ => {
                message.success(translations().deviceTypeAddedSuccessfully)
                this.closeAddBundleModal()
            })
            .catch((err: Error) => {
                message.error(
                    JSON.parse(err.message)?.error?.errors?.map(errorItem => (
                        <>
                            <div>{errorItem.propertyName} - {errorItem.errorMessage}</div>
                        </>
                    ))
                )
            })
            .finally(() => {
                runInAction(() => {
                    this.isAddDeviceTypeLoading = false
                })
            })
    }

    public downloadDevicesCSV = () => {
        const headers = []
        const accessToken = getFromStore('accessToken')
        headers.push(['Authorization', `Bearer ${accessToken}`])

        const options = Object.fromEntries(Object.entries(this.options).filter(([_, v]) => v != null))
        delete options.isRegistered

        const queryParams = Object.keys(options)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(options[k]))
            .join('&')

        return fetch(`/odin-api/api/Merchants/${this.selectedMerchant?.merchantId}/MerchantDevices/Download?${queryParams}`, {
            method: 'GET',
            headers
        })
            .then(res => res.blob())
            .then(res => {
                download(res, `devices-${this.selectedMerchant?.merchantId}`)
            })
    }

    loadDeviceTypes = () => {
        this.deviceTypeStore?.loadDeviceTypes()
    }

    public setOptions = (options) => {
        this.options = options

        this.loadDevices(1)
    }

    public setIsAddBundleModalOpen = (isOpen: boolean) => {
        this.isAddBundleModalOpen = isOpen
    }

    public closeAddBundleModal = () => {
        this.setIsAddBundleModalOpen(false)
    }
}
