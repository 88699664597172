import { computed, makeObservable, observable } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { IMerchantScheduleStore } from '~/code/ODIN/pages/MerchantSchedule/IMerchantScheduleStore'
import { MaintenanceScheduleStore } from '~/code/ODIN/stores/MerchantScheduleStore/MaintenanceScheduleStore'
import { IMaintenanceScheduleStore } from '~/code/ODIN/pages/MerchantSchedule/MaintenanceSchedule/IMaintenanceScheduleStore'
import { DeviceStatusScheduleStore } from '~/code/ODIN/stores/MerchantScheduleStore/DeviceStatusScheduleStore'
import { IDeviceStatusScheduleStore } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/IDeviceStatusScheduleStore'
import { MerchantSearchItem } from '~/code/ODIN/stores/MerchantSelectionStore/models/MerchantSearchItem'

export class MerchantScheduleStore implements IMerchantScheduleStore {

    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            selectedMerchant: computed,
            permissions: computed,
            maintenanceScheduleStore: observable,
            deviceStatusScheduleStore: observable
        })

        this.parentStore = parentStore
        this.maintenanceScheduleStore = new MaintenanceScheduleStore(parentStore)
        this.deviceStatusScheduleStore = new DeviceStatusScheduleStore(parentStore)
    }

    parentStore: IAppStore
    maintenanceScheduleStore: IMaintenanceScheduleStore
    deviceStatusScheduleStore: IDeviceStatusScheduleStore

    get selectedMerchant(): MerchantSearchItem {
        return this.parentStore.odinSelectedMerchant
    }

    get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }
}
