export interface ProductStore {
    id?: string
    name: string
    companyRegistrationNumber: string
    vatNumber?: string
    telephoneNumber: string
    email: string
    country: number
    countryName: string
    postcode: string
    addressLine1: string
    addressLine2: string
    town: string
    county?: string
    isNew: boolean
}

export function createProductStore(): ProductStore {
    return {
        id: '',
        name: '',
        companyRegistrationNumber: '',
        vatNumber: '',
        telephoneNumber: '',
        email: '',
        country: 0,
        countryName: '',
        postcode: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        isNew: true
    }
}
