import { MAX_LIMIT_CHARS } from './index'
import translations from '../translations'

const isDecimal = (value: string) => !!value.split('.')[1]

const validateNumber = (value: string) => {
    if (value.length > MAX_LIMIT_CHARS) {
        throw new Error(translations().max13Chars)
    }
}

const validateNumberWithDecimal = (value: string) => {
    const arrValue = value.split('.')
    const [beforeDecimal, afterDecimal] = arrValue

    if (beforeDecimal.length > MAX_LIMIT_CHARS) {
        throw new Error(translations().max13Chars)
    }

    if (afterDecimal.length > 2) {
        throw new Error(translations().max2DecimalPlaces)
    }
}

export const transactionLimitInputValidator = (value: string | number) => {
    if (value === 0) return

    if (!value) {
        throw new Error(translations().fieldRequired)
    }

    const strValue = value.toString()

    if (isDecimal(strValue)) {
        validateNumberWithDecimal(strValue)
    } else {
        validateNumber(strValue)
    }
}
