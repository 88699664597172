import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Col, Form, Row } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, SummaryBlock, ButtonWithConfirmation } from '~/code/common/components'
import translations from './translations'
import { RequestSummaryProps } from './props'
import styles from './RequestSummary.scss'

export const RequestSummary = observer(({store}: RequestSummaryProps) => {
    const { requestSummaryData } = store


    const [form] = Form.useForm()

    const onFinish = () => {
        store.placeOrder()
    }

    return (
        <div className={styles.RequestSummary}>
            <div className={styles.header}>
                {translations().requestSummary}
            </div>
            { requestSummaryData.map((item, index) => {
                return <SummaryBlock data={item} key={index}/>
            })}
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={18} lg={19}>
                        <Row>
                            <Col xs={24} sm={12}>
                                <Form.Item  key={'goBack'}>
                                    <Button outline={'outlined'} onClick={() => store.goBack()}>
                                        <ArrowLeftOutlined />{translations().back}
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <div className={styles.cancelRequestButtonContainer}>
                                    <ButtonWithConfirmation
                                        type={'button'}
                                        statusType={'danger'}
                                        popConfirmTitle={ translations().cancelRequestConfirmText }
                                        confirmButtonTitle={ translations().confirmCancelRequest }
                                        cancelButtonTitle={ translations().cancelCancelRequest }
                                        onConfirm={ () => store.clearAndSetUp() }
                                    >
                                        <><DeleteOutlined /> {translations().cancelRequest} </>
                                    </ButtonWithConfirmation>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} lg={5}>
                        <div className={styles.submitButtonContainer}>
                            <Form.Item  key={'placeRequest'}>
                                <Button htmlType={'submit'}>
                                    {translations().submitRequest}<ArrowRightOutlined />
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
})
