import { AddDepartmentFormData, addDepartmentFormDataToExistingSDDepartment, createAddDepartmentFormData, createSDDepartment, MerchantDetailsFormData, SDDepartment, SDStore } from '~/code/POS/pages'
import { SelectItem } from '~/code/common/components'
import { IAddDepartmentFormStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/models/IAddDepartmentFormStore'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { error, isEmpty } from 'dna-common'
import { AppStore } from '~/code/AppStore'
import { StoresAndDepartmentsFormStore } from '~/code/stores/NewOrderStore/common/StoresAndDepartmentsFormStore'
import { message } from 'antd'
import { NO_CONTENT } from '~/code/models'
import { fetchDepartmentsByValue } from '../services/fetchers'
import { NewOrderStore } from '../NewOrderStore'

export class AddDepartmentFormStore implements IAddDepartmentFormStore {

    constructor (
        public storesAndDepartmentsFormStore: StoresAndDepartmentsFormStore
    ) {
        makeObservable(this, {
            addDepartmentFormData: observable,
            isDepartmentNameLoading: observable,
            isDepartmentNameValid: observable,
            shouldShowTIDFormItem: observable,
            isNewMerchant: computed,
            isFormVisible: computed,
            acquirers: computed,
            countries: computed,
            currencies: computed,
            merchantCategoryCodes: computed,
            setAddDepartmentFormData: action,
            getDepartments: action.bound,
            checkNewDepartmentStoreNames: action.bound
        })

        this.addDepartmentFormData = createAddDepartmentFormData()
    }

    newOrderStore: NewOrderStore

    addDepartmentFormData: AddDepartmentFormData

    isDepartmentNameLoading: boolean = false

    isDepartmentNameValid: boolean = true

    get isNewMerchant(): boolean {
        return AppStore.newOrderStore?.placeOrderConfigStore.merchantType === 'new'
    }

    get isFormVisible(): boolean {
        return this.storesAndDepartmentsFormStore.isAddDepartmentFormVisible
    }

    get acquirers (): SelectItem[] {
        return AppStore.acquirersStore.acquirers.filter((acquirer) => acquirer.label !== 'Optomany')
    }

    get countries (): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get currencies (): SelectItem[] {
      return AppStore.currenciesStore.currencies
    }

    get merchantCategoryCodes (): SelectItem[] {
        return AppStore.merchantCategoryCodesStore.merchantCategoryCodes
    }

    shouldShowTIDFormItem = false

    setAddDepartmentFormData = (data: AddDepartmentFormData) => {
        if (isEmpty(data.storeId)) {
            return
        }
        const store: SDStore = this.storesAndDepartmentsFormStore.stores.find(item => item.id === data.storeId)
        let department: SDDepartment
        if (data.departmentId) {
            department = store.departments.find( item => item.id === data.departmentId)
            if (department) {
                addDepartmentFormDataToExistingSDDepartment(data, department)
            } else {
                department = createSDDepartment()
                addDepartmentFormDataToExistingSDDepartment(data, department)
                store.departments.push(department)
            }
        } else {
            data.departmentId = uuid()
            department = createSDDepartment()
            addDepartmentFormDataToExistingSDDepartment(data, department)
            store.departments.push(department)
        }
        this.addDepartmentFormData = data
    }

    checkNewDepartmentStoreNames = (value) => {
        if (this.storesAndDepartmentsFormStore.stores[this.storesAndDepartmentsFormStore.stores.length - 1].departments
            .some((department) => department.name.toLowerCase() === value.toLowerCase().trim())) {
            this.isDepartmentNameValid = false
        } else {
            this.isDepartmentNameValid = true
        }
    }

    getDepartments(value: string) {
        this.isDepartmentNameLoading = true
        let internalIdentifier = AppStore.internalIdentifierEncoded ? AppStore.internalIdentifierEncoded : ''
        internalIdentifier = this.newOrderStore?.internalIdentifierEncoded ? this.newOrderStore?.internalIdentifierEncoded : internalIdentifier
        internalIdentifier = `&internalIdentifier=${internalIdentifier}`

        return fetchDepartmentsByValue(value, this.addDepartmentFormData.storeId, internalIdentifier)
            .then(response => {
                if (!response) {
                    return
                }

                runInAction(() => {
                    if (response.length > 0) {
                        const departments = response.map(d => d.name.toLowerCase())
                        if (departments.includes(value.toLowerCase())) {
                            this.isDepartmentNameValid = false
                        } else {
                            this.isDepartmentNameValid = true
                        }
                    } else {
                        this.isDepartmentNameValid = true
                    }
                })
            })
            .catch((err: Error) => {
                if (err.name === NO_CONTENT) {
                    runInAction(() => {
                        this.isDepartmentNameValid = true
                    })
                    return
                }
                error(`FAILED: ${err.message}`)
                message.error(err.message)
            })
            .finally(() => {
                runInAction(() => {
                    this.isDepartmentNameLoading = false
                })
            })
    }

}
