import { NewOrderStore } from '~/code/stores/NewOrderStore/NewOrderStore'
import {
    AddDepartmentForm,
    AddProductForm,
    AddStoreForm, createAddDepartmentFormData, createAddProductFormData, createAddStoreFormData, createDeliveryDetailsFormData,
    createMerchantDetailsFormData,
    createOrderDetailsFormData,
    createPlaceOrderRequestFromData as createPartnerPlaceOrderRequestFromData, createProductListFormData,
    DeliveryDetailsForm, deliveryDetailsFormDataToSummaryBlock, IDeliveryDetailsFormStore,
    MerchantDetailsForm, merchantDetailsFormDataToSummaryBlock,
    OrderDetailsForm, orderDetailsFormDataToSummaryBlock, OrderDetailsModel,
    ProductListForm, ProductListFormData, productListFormDataToSummaryBlock,
    StoresAndDepartmentsForm
} from '~/code/POS/pages'
import React from 'react'
import { action, computed, makeObservable, observable, override, reaction } from 'mobx'
import page from 'page'
import { error, log } from 'dna-common'
import translations from '~/code/POS/pages/NewOrder/translations/translations'
import { SummaryBlockData } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import moment from 'moment'
import { orderDetailsToSummaryBlock } from '~/code/POS/pages/NewOrder/services/order-summary-utils'
import { createValidCompanyNumber, setStoreIdsToOrderRequest, setDepartmentIdsToOrderRequest, getAllStoreDepartments, getExistingStoreIds, getExistingDepartmentIds } from '~/code/stores/NewOrderStore/services/utils'
import { MerchantDetailsFormStore } from '~/code/stores/NewOrderStore/partner/MerchantDetailsFormStore'
import { DeliveryDetailsPartnerStore } from '~/code/stores/NewOrderStore/partner/DeliveryDetailsPartnerStore'
import { IMerchantDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/IMerchantDetailsFormStore'
import { observer } from 'mobx-react'
import { IStoresAndDepartmentsFormStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/IStoresAndDepartmentsFormStore'
import { StoresAndDepartmentsFormStore } from '~/code/stores/NewOrderStore/common/StoresAndDepartmentsFormStore'
import { IProductListFormStore } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/IProductListFormStore'
import { ProductListFormPartnerStore } from '~/code/stores/NewOrderStore/partner/ProductListFormPartnerStore'
import { IOrderDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/OrderDetailsForm/IOrderDetailsFormStore'
import { OrderDetailsFormPartnerStore } from '~/code/stores/NewOrderStore/partner/OrderDetailsFormPartnerStore'
import { AppStore } from '~/code/AppStore'
import { OrderInfo } from '~/code/stores/OrderHistoryStore/models/OrderInfo'
import { convertPropertyNames, pascalCaseToCamelCase } from '~/code/services/case-converter'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { placePartnerMerchantRequestToAddDepartment, placePartnerMerchantRequestToAddStore, placePartnerMerchantRequestToDeliveryDetails, placePartnerMerchantRequestToMerchantDetails, placePartnerMerchantRequestToOrderDetails, placePartnerMerchantRequestToProductList, placePartnerMerchantRequestToStoresAndDepartments } from '~/code/stores/NewOrderStore/services/mapper'
import { PartnerPlaceOrderRequest } from '~/code/stores/NewOrderStore/models/PartnerPlaceOrderRequest'
import { fetchPartnerMerchants } from '../services/fetchers'
import { MerchantType } from '~/code/POS/pages/NewOrder/models/constants'
import { INewOrderPartnerStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/partner/INewOrderPartnerStore'


export class NewOrderPartnerStore extends NewOrderStore implements INewOrderPartnerStore {

    constructor () {
        super()

        makeObservable(this, {
            generateCreateOrderSteps: override,
            generatePlaceOrderRequestAndURL: override,
            createOrderFromStringAndOrder: override,
            createOrderFromOrderInfo: override,
            onMerchantTypeChange: override,
            clearAndSetUp: override,
            orderSummaryData: override,
            shouldShowForm: override,
            setShouldShowForm: override,
            _isPartnerFailedOrder: observable,
            internalIdentifierEncoded: override,
            isPartnerFailedOrder: computed,
            handleFormSubmit: action,
            setIsPartnerFailedOrder: action,
            setMerchantType: action,
            handleGoBack: action,
            searchExistingMerchants: action
        })

        // when the selected company changes
        reaction(() => this.selectedItem, (selectedItem) => {
            if (this.placeOrderConfigStore.merchantType === 'new' && selectedItem) {
                const company = selectedItem?.additionalInfo
                if (this.placeOrderConfigStore.country === 'uk') {
                    this.merchantDetailsStore.merchantDetailsFormData = {
                        ...this.merchantDetailsStore.merchantDetailsFormData,
                        ...this.generateInformationFormDataFromCHResponse(company),
                        receiveSalesAndMarketing: true
                    }
                } else {
                    this.merchantDetailsStore.merchantDetailsFormData = {
                        ...this.merchantDetailsStore.merchantDetailsFormData,
                        ...this.generateInformationFormDataFromCROResponse(company),
                        receiveSalesAndMarketing: true
                    }
                }
            } else {
                // we need to load stores immediately
                (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).clearAndLoadStores()
            }
        }, {fireImmediately: false})

        reaction(() => this.placeOrderConfigStore.merchantType, (merchantType) => {
            this.generateCreateOrderSteps()
            this.storesAndDepartmentsStore.canGoBack = merchantType === 'new'
        })

        reaction(() => (this.orderDetailsFormStore as OrderDetailsFormPartnerStore).useMainContact, (useMainContact) => {
            const merchantDetails = this.merchantDetailsStore.merchantDetailsFormData
            this.orderDetailsFormStore.orderDetailsFormData.companyName = useMainContact ? merchantDetails.companyName : ''
            this.orderDetailsFormStore.orderDetailsFormData.firstName = useMainContact ? merchantDetails.contactName : ''
            this.orderDetailsFormStore.orderDetailsFormData.surname = useMainContact ? merchantDetails.contactSurname : ''
            this.orderDetailsFormStore.orderDetailsFormData.email = useMainContact ? merchantDetails.contactEmail : ''
        })
    }

    private readonly merchantDetailsStore: IMerchantDetailsFormStore = new MerchantDetailsFormStore(this)
    private readonly storesAndDepartmentsStore: IStoresAndDepartmentsFormStore = new StoresAndDepartmentsFormStore(this, this.merchantDetailsStore)
    private readonly addStoreFormStore = (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).addStoreFormStore
    private readonly addDepartmentFormStore = (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).addDepartmentFormStore
    readonly productListFormStore: IProductListFormStore = new ProductListFormPartnerStore(this)
    private readonly addProductFormStore = this.productListFormStore as ProductListFormPartnerStore
    private readonly orderDetailsFormStore: IOrderDetailsFormStore = new OrderDetailsFormPartnerStore(this)
    private readonly deliveryDetailsStore: IDeliveryDetailsFormStore = new DeliveryDetailsPartnerStore(this)

    public _isPartnerFailedOrder: boolean = false

    merchantDetailsForm = observer(() => <MerchantDetailsForm store={this.merchantDetailsStore} setManuallyEnteredCompanyName={this.setManuallyEnteredCompanyName}/>)
    storesAndDepartmentsForm = <StoresAndDepartmentsForm store={this.storesAndDepartmentsStore}>
        <AddStoreForm
            store={this.addStoreFormStore}
            configCountry={this.placeOrderConfigStore.country}
            onClose={(this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).onAddStoreFormClose}/>
        <AddDepartmentForm
            store={this.addDepartmentFormStore}
            onClose={(this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).onAddDepartmentFormClose}/>
    </StoresAndDepartmentsForm>
    productListForm = <ProductListForm store={this.productListFormStore}>
        <AddProductForm
            store={this.productListFormStore as ProductListFormPartnerStore}
            onClose={this.addProductFormStore.onAddProductFormClose}/>
    </ProductListForm>
    orderDetailsForm = <OrderDetailsForm store={this.orderDetailsFormStore}/>
    deliveryDetailsForm = <DeliveryDetailsForm store={this.deliveryDetailsStore}/>

    get isPartnerFailedOrder(): boolean {
        return this._isPartnerFailedOrder
    }

    setIsPartnerFailedOrder(value: boolean): void {
        this._isPartnerFailedOrder = value
    }

    setShouldShowForm (value: boolean) {
        this._shouldShowForm = value
        this.setIsPartnerFailedOrder(value)
    }

    get shouldShowForm(): boolean {
        return this._shouldShowForm || this.isPartnerFailedOrder || !isEmpty(this.selectedCompanyName)
    }

    get internalIdentifierEncoded(): string {
        return AppStore.internalIdentifierEncoded
    }

    handleFormSubmit (data, stepName) {
        switch (stepName) {
            case StoresAndDepartmentsFormStore.stepName: {
                this.productListFormStore.stores = this.storesAndDepartmentsStore.stores
                break
            }
            case ProductListFormPartnerStore.stepName: {
                const productListFormData = data as ProductListFormData
                const instructions = this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions.map(item => {
                    return {storeId: item.storeId, isCustom: item.isCustom, deliveryInstructions: item.deliveryInstructions}
                })

                // update the stores list in the delivery details form
                this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions = []
                productListFormData.products.forEach(product => {
                    if (isEmpty(this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions?.find(item => item.storeId === product.storeId))) {
                        this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions.push({
                            storeId: product.storeId,
                            isCustom: false,
                            storeName: this.storesAndDepartmentsStore.stores.find(item => item.id === product.storeId).name,
                            deliveryInstructions: ''
                        })
                    }
                })

                // set instructions
                this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions.forEach(instructionsItem => {
                    const foundInstructions = instructions.find(item => item.storeId === instructionsItem.storeId)
                    instructionsItem.deliveryInstructions = foundInstructions?.deliveryInstructions || ''
                    instructionsItem.isCustom = foundInstructions?.isCustom || false
                })
                break
            }
        }

        if (this.createOrderSteps[this.currentCreateOrderStep].key === 'deliveryDetails') {
            this.currentStep++
        } else {
            this.currentCreateOrderStep++
        }
    }

    handleGoBack () {
        if (this.steps[this.currentStep] !== 'creatingOrder') {
            this.currentStep--
        } else {
            this.currentCreateOrderStep--
        }
    }

    // TODO maybe this method will be in some other separate store, that works only with steps: generates and navigates
    generateCreateOrderSteps() {
        let steps: any[] = [
            {title: translations().merchantDetails, key: 'merchantDetails', children: <this.merchantDetailsForm/>},
            {title: translations().storesAndDepartments, key: 'storesAndDepartments', children: this.storesAndDepartmentsForm},
            {title: translations().productList, key: 'productList', children: this.productListForm},
            {title: translations().orderDetails, key: 'orderDetails', children: this.orderDetailsForm},
            {title: translations().deliveryDetails, key: 'deliveryDetails', children: this.deliveryDetailsForm}
        ]

        if (this.placeOrderConfigStore.merchantType === 'existing') {
            steps = steps.filter(item => item.key !== 'merchantDetails')
        }
        this.createOrderSteps = steps.map((item, index) => {return {...item, order: index + 1}})
    }

    get orderSummaryData() {
        log('order summary for partner')
        const data: SummaryBlockData[] = []

        const _orderReference = this.accountNumber()
        if (isEmpty(this.orderReference)) { this.orderReference = `${_orderReference}-${moment().unix()}` }
        data.push(orderDetailsToSummaryBlock(this.orderReference, this.selectedEmployeeCompany?.title))

        this.createOrderSteps.forEach(step => {
            switch (step.key) {
                case 'merchantDetails': {
                    data.push(merchantDetailsFormDataToSummaryBlock(this.merchantDetailsStore.merchantDetailsFormData, AppStore.countriesStore.countries))
                    break
                }
                case 'productList': {
                    data.push(productListFormDataToSummaryBlock(this.productListFormStore.productListFormData, this.storesAndDepartmentsStore.stores))
                    break
                }
                case 'orderDetails': {
                    data.push(orderDetailsFormDataToSummaryBlock(this.orderDetailsFormStore.orderDetailsFormData, this.orderDetailsFormStore.brandingOrganisations))
                    break
                }
                case 'deliveryDetails': {
                    data.push(deliveryDetailsFormDataToSummaryBlock(this.deliveryDetailsStore.deliveryDetailsFormData, AppStore.countriesStore.countries))
                    break
                }
            }
        })
        return data
    }

    clearAndSetUp () {
        super.clearAndSetUp()
        this.merchantDetailsStore && (this.merchantDetailsStore.merchantDetailsFormData = createMerchantDetailsFormData())
        this.storesAndDepartmentsStore && (this.storesAndDepartmentsStore.setStores([]))
        this.addStoreFormStore && (this.addStoreFormStore.addStoreFormData = createAddStoreFormData())
        this.addDepartmentFormStore && (this.addDepartmentFormStore.addDepartmentFormData = createAddDepartmentFormData())
        this.productListFormStore && (this.productListFormStore.productListFormData = createProductListFormData())
        this.addProductFormStore && (this.addProductFormStore.addProductFormData = createAddProductFormData())
        this.orderDetailsFormStore && (this.orderDetailsFormStore.orderDetailsFormData = createOrderDetailsFormData())
        this.deliveryDetailsStore && (this.deliveryDetailsStore.deliveryDetailsFormData = createDeliveryDetailsFormData())
    }

    setMerchantType(type: MerchantType) {
        this.placeOrderConfigStore.setSelectedMerchantType(type)
    }

    generatePlaceOrderRequestAndURL() {
        // merchantId is used only for existing merchants
        const merchantId = this.selectedItem?.additionalInfo?.merchantId || ''
        const companyNumber = createValidCompanyNumber(this.placeOrderConfigStore.country, this.selectedItem?.additionalInfo, this.merchantDetailsStore.merchantDetailsFormData.companyNumber)

        const request = createPartnerPlaceOrderRequestFromData(
            {...this.merchantDetailsStore.merchantDetailsFormData, companyNumber},
            this.storesAndDepartmentsStore.stores,
            this.productListFormStore.productListFormData,
            this.orderDetailsFormStore.orderDetailsFormData,
            this.deliveryDetailsStore.deliveryDetailsFormData,
            this.internalIdentifier,
            this.orderReference,
            this.accountNumber(),
            merchantId,
            this.createOrderSteps,
            this.productListFormStore.acquirerAssignedTids)
        const requestURL = `/api/partner-orders/place-order`

        return {requestURL, request}
    }

    createOrderFromStringAndOrder (previousOrder: string, orderDetailsModel: OrderDetailsModel) {
        const order: PartnerPlaceOrderRequest = convertPropertyNames(JSON.parse(previousOrder), pascalCaseToCamelCase)

        const allStores = order?.merchant?.stores
        const allDepartments = getAllStoreDepartments(allStores)
        const existingStoreIds = getExistingStoreIds(allStores)
        const existingDepartments = getExistingDepartmentIds(allStores)

        const isAllStoresExist = existingStoreIds.length === allStores?.length

        const isAllDepartmentsExist = existingDepartments.length === allDepartments?.length

        const isExistingStoreWithDepartments = !isEmpty(order?.merchant?.existingMerchantId) && isAllStoresExist && isAllDepartmentsExist

        if (isExistingStoreWithDepartments) {
            this.setMerchantType('existing')
        } else {
            this.setMerchantType('new')
        }

        const orderWithStoreIds = setStoreIdsToOrderRequest(order)
        const orderWithDepartmentIds = setDepartmentIdsToOrderRequest(orderWithStoreIds)
        // clear the forms and set up
        this.clearAndSetUp()

        this.setIsPartnerFailedOrder(true)

        // fill the forms with new data
        this.merchantDetailsStore.merchantDetailsFormData = placePartnerMerchantRequestToMerchantDetails(orderWithDepartmentIds)
        this.storesAndDepartmentsStore.stores = placePartnerMerchantRequestToStoresAndDepartments(orderWithDepartmentIds)
        this.addStoreFormStore.addStoreFormData = placePartnerMerchantRequestToAddStore(orderWithDepartmentIds)
        this.productListFormStore.productListFormData.products = placePartnerMerchantRequestToProductList(orderWithDepartmentIds)
        this.addDepartmentFormStore.addDepartmentFormData = placePartnerMerchantRequestToAddDepartment(orderWithDepartmentIds)
        this.orderDetailsFormStore.orderDetailsFormData = placePartnerMerchantRequestToOrderDetails(orderWithDepartmentIds)
        this.deliveryDetailsStore.deliveryDetailsFormData = placePartnerMerchantRequestToDeliveryDetails(orderWithDepartmentIds)

        this.generateCreateOrderSteps()

        page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
    }

    // tslint:disable-next-line:no-empty
    createOrderFromOrderInfo (orderInfo: OrderInfo) {}

    // tslint:disable-next-line:no-empty
    onMerchantTypeChange (merchantType: MerchantType): void {}

    loadStores() {
        this.storesAndDepartmentsStore.loadStores()
    }

    public searchExistingMerchants(value) {
        const data = {
            pageNumber: 1,
            pageSize: 10,
            searchValue: value
        }
        if (this.internalIdentifier) {
            data['internalIdentifier'] = this.internalIdentifier // this gets encoded when the data object is converted into query params
        }

        return fetchPartnerMerchants(data)
        .then( response => {
            if (response && response.length > 0) {
                this.existingMerchants = response.map(m => {
                    return { id: m.merchantId, name: m.merchantName }
                })
            } else {
                this.existingMerchants = []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
    }
}
