import React from 'react'
import translations from './translations'

translations.add('en', {
    updateApp: 'Update App',
    cancel: 'Cancel',
    packageVersionRequired: 'Package Version Required',
    appDisplayName: 'App Display Name',
    appDeveloper: 'App Developer',
    install: 'Install',
    selectPackageVersion: 'Select Package Version',
    searchPackageNameOrDisplayName: 'Search Package Name or Display Name'
})
