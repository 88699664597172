// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2O_cvnWZvMT9MIWn4_JqQ9 {\n  margin-bottom: 24px; }\n  ._2O_cvnWZvMT9MIWn4_JqQ9 ._1IuxlTqS_vsFur907ktquo {\n    display: inline-block;\n    background-color: #F5F5F5;\n    padding: 5px 10px;\n    border-radius: 5px 5px 0px 0px;\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 18px;\n    text-transform: uppercase;\n    color: #00A0DC; }\n  ._2O_cvnWZvMT9MIWn4_JqQ9 ._1LhZU5yGM4pXhhAl3ZSOgm {\n    background: #F5F5F5;\n    border-radius: 0px 8px 8px 8px;\n    padding: 10px; }\n  ._2O_cvnWZvMT9MIWn4_JqQ9 ._296ljU2xib1-oNlaZFl42L {\n    display: block; }\n    ._2O_cvnWZvMT9MIWn4_JqQ9 ._2GGeqmj10FpVUWmE_ncbFK {\n      display: none; }\n  ._2O_cvnWZvMT9MIWn4_JqQ9 ._3OG-C27ANNnlfnT0HYRchM {\n    margin-bottom: 10px; }\n", ""]);
// Exports
exports.locals = {
	"AcquirerDetails": "_2O_cvnWZvMT9MIWn4_JqQ9",
	"groupHeader": "_1IuxlTqS_vsFur907ktquo",
	"groupContent": "_1LhZU5yGM4pXhhAl3ZSOgm",
	"hideableContent": "_296ljU2xib1-oNlaZFl42L",
	"hideableContent__noDisplay": "_2GGeqmj10FpVUWmE_ncbFK",
	"lessMargin": "_3OG-C27ANNnlfnT0HYRchM"
};
module.exports = exports;
