import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    createdDate: string
    accountNumber: string
    errMsgCH: string
    errMsgCRO: string
    agreeWithTerms: (company: string) => string
    confirmationRequired: string
}>()

