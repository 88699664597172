import React, { useState } from 'react'
import { Col, Form, Row } from 'antd'
import { observer } from 'mobx-react'
import { useForm } from 'antd/es/form/Form'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { Checkbox, GroupTitle, ImageUploadWithPreview, Input, Select } from '~/code/common/components'
import { ColourPicker } from './componets'
import translations from './translations'
import { CheckoutSettingsFormProps } from './props'

export const CheckoutSettingsForm = observer(({ store }: CheckoutSettingsFormProps) => {
    const { checkoutSettingsFormData: data, integrationTypes, setCheckoutSettingsFormData, secondaryLogoImagePositions, canGoBack, goBack, uploadImage } = store
    const [form] = useForm()
    const [usePartnerSettings, setUsePartnerSettings] = useState(true)

    const onFinish = (values) => {
        values.usePartnerSettings = usePartnerSettings
        setCheckoutSettingsFormData(values)
    }

    const formInitialValues = {
        usePartnerSettings: data.usePartnerSettings,
        integrationTypes: data.integrationTypes?.slice(),
        mandatoryFields: {
            accountCity: data.mandatoryFields?.accountCity,
            accountEmail: data.mandatoryFields?.accountEmail,
            accountCountry: data.mandatoryFields?.accountCountry,
            accountStreet1: data.mandatoryFields?.accountStreet1,
            accountLastName: data.mandatoryFields?.accountLastName,
            accountFirstName: data.mandatoryFields?.accountFirstName,
            accountPostalCode: data.mandatoryFields?.accountPostalCode
        },
        customStyle: {
            primaryColor: data.customStyle?.primaryColor,
            headerLogoUrl: data.customStyle?.headerLogoUrl,
            headerLogoText: data.customStyle?.headerLogoText,
            headerStartColor: data.customStyle?.headerStartColor,
            actionButtonColor: data.customStyle?.actionButtonColor,
            actionButtonBackground: data.customStyle?.actionButtonBackground,
            headerFinishColor: data.customStyle?.headerFinishColor,
            closeButtonOpacity: data.customStyle?.closeButtonOpacity,
            closeButtonColor: data.customStyle?.closeButtonColor,
            showPoweredByBlock: data.customStyle?.showPoweredByBlock,
            headerLogoTextColor: data.customStyle?.headerLogoTextColor,
            showHeaderBottomLine: data.customStyle?.showHeaderBottomLine,
            showOrderNumberBlock: data.customStyle?.showOrderNumberBlock,
            headerLogoMobileWidth: data.customStyle?.headerLogoMobileWidth,
            headerLogoDesktopWidth: data.customStyle?.headerLogoDesktopWidth,
            headerLogoMobileHeight: data.customStyle?.headerLogoMobileHeight,
            headerSecondaryLogoUrl: data.customStyle?.headerSecondaryLogoUrl,
            headerLogoDesktopHeight: data.customStyle?.headerLogoDesktopHeight,
            headerLogoTextSecondary: data.customStyle?.headerLogoTextSecondary,
            actionButtonBorderRadius: data.customStyle?.actionButtonBorderRadius,
            headerLogoTextMarginLeft: data.customStyle?.headerLogoTextMarginLeft,
            showOrderDescriptionBlock: data.customStyle?.showOrderDescriptionBlock,
            showPaymentSecuredByBlock: data.customStyle?.showPaymentSecuredByBlock,
            headerSecondaryLogoPosition: data.customStyle?.headerSecondaryLogoPosition
        }
    }

    return <div>
        <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={formInitialValues}>
            <Row justify={'space-between'}>
                <Col xs={24} md={10}>
                    <GroupTitle size={'small'} content={translations().checkoutCustomisations} />
                    <Form.Item
                        name={'usePartnerSettings'}
                    >
                        <Checkbox onChange={(e) => setUsePartnerSettings(e.target.checked)} checked={usePartnerSettings}>
                            {translations().usePartnerDefinedSettings}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            {
                !usePartnerSettings && <>
                    <Row justify={'space-between'}>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().integrationTypes}
                                name={'integrationTypes'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().integrationTypesRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().integrationTypes}
                                        mode={'multiple'}
                                        selectItems={integrationTypes?.slice()}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <GroupTitle size={'small'} content={translations().mandatoryFields} />
                    <Row justify={'space-between'}>
                        <Col xs={24} md={10}>
                            <Form.Item
                                name={['mandatoryFields', 'accountFirstName']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountFirstName}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['mandatoryFields', 'accountLastName']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountLastName}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['mandatoryFields', 'accountEmail']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountEmail}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                            <Form.Item
                                name={['mandatoryFields', 'accountCountry']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountCountry}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['mandatoryFields', 'accountCity']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountCity}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['mandatoryFields', 'accountPostalCode']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountPostalCode}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['mandatoryFields', 'accountStreet1']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().accountStreet1}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <GroupTitle size={'small'} content={translations().colors} />
                    <Row justify={'space-between'}>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().primaryColor}
                                name={['customStyle', 'primaryColor']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().primaryColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                            <Form.Item
                                label={translations().headerStartColor}
                                name={['customStyle', 'headerStartColor']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerStartColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                            <Form.Item
                                label={translations().headerFinishColor}
                                name={['customStyle', 'headerFinishColor']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerFinishColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                            <Form.Item
                                label={translations().headerLogoTextColor}
                                name={['customStyle', 'headerLogoTextColor']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoTextColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().actionButtonBackgroundColor}
                                name={['customStyle', 'actionButtonBackground']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().actionButtonBackgroundColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                            <Form.Item
                                label={translations().actionButtonColor}
                                name={['customStyle', 'actionButtonColor']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().actionButtonColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                            <Form.Item
                                label={translations().closeButtonColor}
                                name={['customStyle', 'closeButtonColor']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().closeButtonColorRequired
                                    }
                                ]}
                            >
                                <ColourPicker />
                            </Form.Item>
                        </Col>
                    </Row>
                    <GroupTitle size={'small'} content={translations().images} />
                    <Row justify={'space-between'}>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().headerLogoImageURL}
                                name={['customStyle', 'headerLogoImageURL']}
                                rules={[
                                    {
                                        // required: true,
                                        message: translations().headerLogoImageURLRequired
                                    }
                                ]}
                            >
                                <ImageUploadWithPreview name="headerLogoImageURL" uploadFile={uploadImage}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().secondaryLogoImageURL}
                                name={['customStyle', 'secondaryLogoImageURL']}
                                rules={[
                                    {
                                        // required: true,
                                        message: translations().secondaryLogoImageURLRequired
                                    }
                                ]}
                            >
                                <ImageUploadWithPreview name="secondaryLogoImageURL" uploadFile={uploadImage} />
                            </Form.Item>
                            <Form.Item
                                label={translations().secondaryLogoImagePosition}
                                name={['customStyle', 'headerSecondaryLogoPosition']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().secondaryLogoImagePositionRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().secondaryLogoImagePosition}
                                        selectItems={secondaryLogoImagePositions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <GroupTitle size={'small'} content={translations().otherSettings} />
                    <Row justify={'space-between'}>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().headerLogoText}
                                name={['customStyle', 'headerLogoText']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoTextRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoText}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().headerLogoTextSecondary}
                                name={['customStyle', 'headerLogoTextSecondary']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoTextSecondaryRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoTextSecondary}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().headerLogoTextMarginLeft}
                                name={['customStyle', 'headerLogoTextMarginLeft']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoTextMarginLeftRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoTextMarginLeft}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().closeButtonOpacity}
                                name={['customStyle', 'closeButtonOpacity']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().closeButtonOpacityRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().closeButtonOpacity}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().actionButtonBorderRadius}
                                name={['customStyle', 'actionButtonBorderRadius']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().actionButtonBorderRadiusRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().actionButtonBorderRadius}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().headerLogoMobileWidth}
                                name={['customStyle', 'headerLogoMobileWidth']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoMobileWidthRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoMobileWidth}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().headerLogoMobileHeight}
                                name={['customStyle', 'headerLogoMobileHeight']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoMobileHeightRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoMobileHeight}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().headerLogoDesktopWidth}
                                name={['customStyle', 'headerLogoDesktopWidth']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoDesktopWidthRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoDesktopWidth}/>
                            </Form.Item>
                            <Form.Item
                                label={translations().headerLogoDesktopHeight}
                                name={['customStyle', 'headerLogoDesktopHeight']}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().headerLogoDesktopHeightRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().headerLogoDesktopHeight}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <Col xs={24} md={10}>
                            <Form.Item
                                name={['customStyle', 'showPoweredByBlock']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().showPoweredByBlock}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['customStyle', 'showHeaderBottomLine']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().showHeaderBottomLine}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['customStyle', 'showOrderNumberBlock']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().showOrderNumberBlock}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                            <Form.Item
                                name={['customStyle', 'showOrderDescriptionBlock']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().showOrderDescriptionBlock}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['customStyle', 'showPaymentSecuredByBlock']}
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        transform: checked => (checked || undefined),
                                        type: 'boolean'
                                    }
                                ]}
                            >
                                <Checkbox>
                                    {translations().showPaymentSecuredByBlock}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }

            <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
        </Form>
    </div>
})
