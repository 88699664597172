import { Modal } from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import React from 'react'
import { MIDWarningModalProps } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/components/MIDWarningModal/props'
import { WARNING_COLOR } from '~/code/POS/constants'
import translations from './translations'

export const MIDWarningModal = ({mids, visible, onCancel, onOk, twoToneColor}: MIDWarningModalProps) => {
    return (
        <Modal
            title={<><WarningTwoTone twoToneColor={twoToneColor || WARNING_COLOR}/>{translations().warning}</>}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            okText={translations().continue}
            cancelText={translations().amend}
        >
            <div>
                <p>{translations().areYouSureToContinueWithGivenMIDs(mids)}</p>
            </div>
        </Modal>
    )
}
