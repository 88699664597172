// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3f_r2fJGGwevgS_jo4elKo {\n  transition: 0.3s; }\n  ._3f_r2fJGGwevgS_jo4elKo:hover {\n    cursor: pointer;\n    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); }\n\n._2ygsxyzERMLBmraQPDCqZc {\n  margin-top: 20px;\n  margin-bottom: 20px; }\n\n.GIIJTLsNHPnZd2ljhxCUl {\n  color: #8C8C8C; }\n\n.sxUktwUWhM_ycy30G5KlR {\n  font-weight: bold; }\n\n._3rcYZxsHbagXi3M47Zdmlq {\n  height: 50px;\n  width: 50px; }\n\n._1ZlIISp9PVpoUATq6tZ4RD {\n  max-width: 400px; }\n", ""]);
// Exports
exports.locals = {
	"card": "_3f_r2fJGGwevgS_jo4elKo",
	"listActions": "_2ygsxyzERMLBmraQPDCqZc",
	"text": "GIIJTLsNHPnZd2ljhxCUl",
	"title": "sxUktwUWhM_ycy30G5KlR",
	"avatar": "_3rcYZxsHbagXi3M47Zdmlq",
	"filterPanel": "_1ZlIISp9PVpoUATq6tZ4RD"
};
module.exports = exports;
