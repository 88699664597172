import React from 'react'
import { PageProps } from './props'
import styles from './Page.scss'

const Page = ({children}: PageProps) => {
    return <div className={styles.Page}>
            { children }
    </div>
}

export { Page }
