import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import page from 'page'
import { Avatar, Card, Col, Form, List, Row, Typography, Select as AntSelect } from 'antd'
import classNames from 'classnames'
import { Input, ListActions, Select } from '~/code/common/components'
import { Application } from '~/code/ODIN/stores/ApplicationManagementStore/models/Application'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import { FormInstance } from 'antd/es/form'
import { FilterActions } from '~/code/common/components/FilterActions/FilterActions'
import { useForm } from 'antd/es/form/Form'
import translations from './translations'
import { IAllApplicationsProps } from './props'
import styles from './AllApplications.scss'

const {Title, Text} = Typography
const {Option} = AntSelect

export const AllApplications = observer((props: IAllApplicationsProps) => {
    const { store, packageType } = props
    const formRef = React.createRef<FormInstance>()
    const [form] = useForm()
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)

    useEffect(() => {
        store.loadAllApps()
    }, [])

    const refresh = () => {
        store.loadAllApps()
    }

    const loadNextPage = () => {
        store.loadAllApps(store.pageNumber + 1)
    }

    const loadPrevPage = () => {
        store.loadAllApps(store.pageNumber - 1)
    }

    const handleReset = () => {
        store.setOptions({})
        formRef.current!.resetFields()
        setIsFilterVisible(false)
    }

    const onFilterFinish = (values: any) => {
        store.setOptions({
            name: values.name,
            developer: values.developer
        })

        store.loadAllApps(1)
        setIsFilterVisible(false)
    }

    const getRedirectUrl = (item) => {
        switch (packageType) {
            case PackageType.SystemConfiguration:
                page(`${LOCATION_ODIN_PATH_NAMES.FIRMWARE_MANAGEMENT}/${item.packageId}`)
                break
            case PackageType.Firmware:
                page(`${LOCATION_ODIN_PATH_NAMES.FIRMWARE_MANAGEMENT}/${item.packageId}`)
                break
            case PackageType.Application:
                page(`${LOCATION_ODIN_PATH_NAMES.APPLICATION_MANAGEMENT}/${item.packageId}`)
                break
        }
    }

    const renderListItem = (item: Application) => (
        <List.Item>
            <Card
                className={styles.card}
                onClick={() => getRedirectUrl(item)}
            >
                <Row gutter={[0, 16]}>
                    <Col xs={24} md={3}>
                        <Avatar className={styles.avatar} src={item.iconUrl} />
                    </Col>
                    <Col xs={24} md={7}>
                        <Title level={5}>
                            {item.displayName}
                        </Title>
                    </Col>
                    <Col xs={24} md={8}>
                        <div>
                            <Text className={classNames(styles.title, styles.text)}>
                                {`${translations().category}: `}
                            </Text>
                            <Text className={styles.text}>
                                {item.category}
                            </Text>
                        </div>
                        <div>
                            <Text className={classNames(styles.title, styles.text)}>
                                {`${translations().latestVersion}: `}
                            </Text>
                            <Text className={styles.text}>
                                {item.latestVersion}
                            </Text>
                        </div>
                    </Col>
                    <Col xs={24} md={6}>
                        <Text className={classNames(styles.title, styles.text)}>
                            {`${translations().uploadedDateTime}: `}
                        </Text>
                        <Text className={styles.text}>
                            {moment(item.uploadedDateTime).format('DD/MM/YY HH:mm:ss')}
                        </Text>
                    </Col>
                </Row>
            </Card>
        </List.Item>
    )

    return (
        <>
            <ListActions
                className={styles.listActions}
                pageInfo={{
                    from: store.allApps.length ? store.pageNumber * store.pageSize - 19 : 0,
                    to: store.pageNumber * store.pageSize > store.totalCount ? store.totalCount : store.pageNumber * store.pageSize,
                    total: store.totalCount
                }}
                currentTotalAmount={store.allApps.length}
                onReloadClick={refresh}
                onGoNextPageClick={loadNextPage}
                onGoPrevPageClick={loadPrevPage}
                isNextPageDisabled={store.pageNumber >= store.totalPageItems}
                isPrevPageDisabled={store.pageNumber <= 1}
                isFilterDisabled={false}
                isFilterVisible={isFilterVisible}
                onFilterClick={() => setIsFilterVisible(true)}
                filterContent={() => {
                    return <div style={{ padding: 8 }}>
                        <Form
                            ref={formRef}
                            form={form}
                            onFinish={onFilterFinish}
                        >
                            <Row className={styles.filterPanel} gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item name={'developer'}>
                                        <Select
                                            showSearch
                                            loading={store.appDeveloperStore.isLoading}
                                            selectItems={store.appDeveloperStore.developers}
                                            placeholder={translations().selectAppDeveloper}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {store.appDeveloperStore.developers?.map(developer => (
                                                <Option key={developer.value} value={developer.label}>{developer.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'name'}>
                                        <Input placeholder={translations().searchName}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item  key={'placeRequest'}>
                                        <FilterActions onCancel={() => setIsFilterVisible(false)} onReset={() => handleReset()}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }}
            />

            <List
                grid={{
                    gutter: 16,
                    column: 1
                }}
                loading={store.isLoading}
                dataSource={store.allApps}
                renderItem={item => renderListItem(item)}
            />
        </>
    )
})
