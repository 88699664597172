export interface ProcessingDetailsFormData {
    acquirer: string
    merchantId: string
    merchantCategoryCode: string
    currency: number
    bankAccountNumber: string
    sortCode: string
    postcode: string
    isCashBackChecked: boolean
    cashBack: number
    isGratuityChecked: boolean
    gratuity: number
    streamlineCompanyId: number
    brandingOrganisationId: number
    preferredSubmission: string
    accountName: string
    amexMerchantId: string
    amexAcquirer: string
    amexBankAccountNumber: string
    amexSortCode: string
    preAuthorisation?: boolean
}

export function createProcessingDetailsFormData(): ProcessingDetailsFormData {
    return {
        acquirer: '',
        merchantId: '',
        merchantCategoryCode: '',
        currency: null,
        bankAccountNumber: '',
        sortCode: '',
        postcode: '',
        isCashBackChecked: false,
        cashBack: 0,
        isGratuityChecked: false,
        gratuity: 0,
        streamlineCompanyId: 0,
        brandingOrganisationId: 0,
        preferredSubmission: '',
        accountName: '',
        amexMerchantId: '',
        amexAcquirer: '',
        amexBankAccountNumber: '',
        amexSortCode: ''
    }
}
