import { observer } from 'mobx-react'
import React from 'react'
import { Popover } from 'antd'
import { RangePickerProps } from './props'
import styles from './RangePicker.scss'
import { LinkLabel } from '~/code/common/components'
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import 'rc-calendar/assets/index.css'
import en_GB_locale from 'rc-calendar/lib/locale/en_GB'
import { getPeriods } from '~/code/stores/RangePickerStore/services'


export const RangePicker = observer((props: RangePickerProps) => {
    const { store } = props

    const content = <RangeCalendar
        locale={en_GB_locale}
        showToday={false}
        showOk={false}
        showDateInput={false}
        className={styles.rangeCalendarContainer}
        selectedValue={[store.startDate, store.endDate]}
        onSelect={store.setDates}
        renderFooter={() => (
            <div className={styles.datePeriodContainer}>
                {getPeriods().map(datePeriod => (
                    <span
                        key={datePeriod.value}
                        className={`${styles.datePeriod} ${datePeriod.value === store.period && styles.datePeriod__active}`}
                        onClick={() => store.setPeriod(datePeriod.value)}
                    >
                        {datePeriod.label}
                    </span>
                ))}
            </div>
        )}
    />


    return <div className={styles.rangePickerContainer}>
        <Popover
            overlayClassName={styles.popover}
            placement={'bottomLeft'}
            visible={store.open}
            onVisibleChange={store.setOpen}
            trigger={'click'}
            content={content}
        >
            <LinkLabel>
                {store.isSelectedPeriod ? store.selectedPeriod?.label : `${store.startDate?.format('YYYY-MM-DD')} - ${store.endDate?.format('YYYY-MM-DD')}`}
            </LinkLabel>
        </Popover>
    </div>
})
