import translations from './translations'

translations.add('en', {
    createdDate: 'Created date',
    accountNumber: 'Account number',
    errMsgCH: 'Companies House UK is currently unavailable, please use the “Add Manually“ option to place an order. We apologise for the inconvenience.',
    errMsgCRO: 'Companies Registration Office Ireland is currently unavailable, please use the “Add Manually“ option to place an order. We apologise for the inconvenience.',
    agreeWithTerms: (company: string) => `I confirm that this order is placed in accordance with the T&Cs defined within ${company}'s contract with Optomany Ltd`,
    confirmationRequired: 'Please agree to the terms and conditions before placing the order'
})
