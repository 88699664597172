import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, List, Row, Tooltip, Typography } from 'antd'
import { VersionHistory } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/models/VersionHistory'
import classNames from 'classnames'
import moment from 'moment'
import translations from './translations'
import { VersionHistoryListProps } from './props'
import styles from './VersionHistoryList.scss'
import { FileUploadModal } from '~/code/ODIN/components/FileUploadModal/FileUploadModal'
import { getFileUploadPackageType } from '~/code/ODIN/pages/ApplicationManagementDetails/utils/getFileUploadPackageType'

const {Title, Text} = Typography

export const VersionHistoryList = observer((
    {store, maxFileSize, packageType, applicationId}: VersionHistoryListProps
) => {
    const {versionHistory, isLoading, onUploadFileModalOk, addNewVersionStore} = store

    const renderCard = (item: VersionHistory) => (
        <Card
            className={classNames(styles.card, {[styles.isNeedToUploadFile]: !item.isUploaded})}
            hoverable={!item.isUploaded}
            onClick={() => !item.isUploaded && store.openFileUploadModal(item)}
        >
            <Row gutter={[0, 16]}>
                <Col xs={24} md={8}>
                    <Title level={5}>
                        {translations().details}
                    </Title>
                    <Title level={5}>
                        {item.version}
                    </Title>
                </Col>
                <Col xs={24} md={10}>
                    <div>
                        <Text className={classNames(styles.title, styles.text)}>
                            {`${translations().appSignedBy}: `}
                        </Text>
                        <Text className={styles.text}>
                            {item.deviceManufacturerName}
                        </Text>
                    </div>
                    <div>
                        <Text className={classNames(styles.title, styles.text)}>
                            {`${translations().category}: `}
                        </Text>
                        <Text className={styles.text}>
                            {item.categories.map(category => `${category.name} `)}
                        </Text>
                    </div>
                    <div>
                        <Text className={classNames(styles.title, styles.text)}>
                            {`${translations().supportedDeviceTypes}: `}
                        </Text>
                        <Text className={styles.text}>
                            {item.supportedDeviceTypes.map(type => `${type.name} `)}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} md={6}>
                    <Text className={classNames(styles.title, styles.text)}>
                        {`${translations().uploadedDateTime}: `}
                    </Text>
                    <Text className={styles.text}>
                        {moment(item.uploadedDateTime).format('DD/MM/YY HH:mm:ss')}
                    </Text>
                </Col>
            </Row>
        </Card>
    )

    const renderListItem = (item: VersionHistory) => (
        <List.Item>
            {item.isUploaded && (
                renderCard(item)
            )}
            {!item.isUploaded && (
                <Tooltip placement="bottom" title={translations().needToFileUpload}>
                    {renderCard(item)}
                </Tooltip>
            )}
        </List.Item>
    )

    return (
        <>
            <List
                grid={{
                    gutter: 16,
                    column: 1
                }}
                loading={isLoading}
                dataSource={versionHistory}
                renderItem={item => renderListItem(item)}
                rowKey={(item) => item.version}
            />

            <FileUploadModal
                megabytes={maxFileSize}
                permission={getFileUploadPackageType(packageType)}
                onOk={() => {
                    onUploadFileModalOk(applicationId)
                }}
                store={addNewVersionStore}
            />
        </>
    )
})
