// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1VN2rpbxIpQueiN4TQbtVi ._1emN9FWwMytzkB7QTv6LdX {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 32px;\n  line-height: 48px;\n  color: #000000;\n  padding-bottom: 16px; }\n\n._1VN2rpbxIpQueiN4TQbtVi ._24JUZCSx-VqE8E-g0Bf0EU {\n  display: flex;\n  flex-flow: row-reverse; }\n  @media (max-width: 575px) {\n    ._1VN2rpbxIpQueiN4TQbtVi ._24JUZCSx-VqE8E-g0Bf0EU {\n      flex-flow: row; } }\n\n._1VN2rpbxIpQueiN4TQbtVi ._31aYmVlOqwzBHpP8I8z967 {\n  display: flex;\n  flex-flow: row-reverse; }\n\n._1VN2rpbxIpQueiN4TQbtVi .W2OtxRXLuueYfm6cb5oWS {\n  display: flex;\n  align-items: center;\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 13px;\n  line-height: 20px;\n  color: #9B9B9B;\n  cursor: pointer; }\n  ._1VN2rpbxIpQueiN4TQbtVi .W2OtxRXLuueYfm6cb5oWS .ant-checkbox {\n    top: 0; }\n", ""]);
// Exports
exports.locals = {
	"OrderSummary": "_1VN2rpbxIpQueiN4TQbtVi",
	"header": "_1emN9FWwMytzkB7QTv6LdX",
	"cancelOrderButtonContainer": "_24JUZCSx-VqE8E-g0Bf0EU",
	"submitButtonContainer": "_31aYmVlOqwzBHpP8I8z967",
	"agreeWithTermsLabel": "W2OtxRXLuueYfm6cb5oWS"
};
module.exports = exports;
