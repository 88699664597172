import translations from './translations'

translations.add('en', {
    companyName: 'Company name',
    companyNameRequired: 'Company name is required',
    companyNameWrong: 'Company name is invalid',
    tradingAs: 'Trading as',
    accountManager: 'Account manager',
    accountManagerRequired: 'Account manager is required',
    tradingAsRequired: 'Trading as is required',
    companyNumber: 'Company number',
    companyNumberRequired: 'Company number is required',
    companyNumberUKInvalid: 'Company number must consist of 8 digits or 2 letters followed by 6 digits or 2 letters followed by 5 digits followed by 1 letter',
    companyNumberROIInvalid: 'Company number must consist of 5 or 6 digits',
    companyNumberUKTooltip: 'Company registration number (CRN) as registered with Companies House. If you do not have a company number, please select the “sole trader” option.',
    companyNumberROITooltip: 'Company or business number as registered with Companies Registration Office (CRO). If you do not have a company or business number, please select the “sole trader” option.',
    vatNumber: 'VAT number',
    vatNumberRequired: 'VAT number is required',

    address: 'Address',

    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',
    addressLine3Required: 'Address line 3 is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    county: 'County',
    countyRequired: 'County is required',
    country: 'Country',
    countryRequired: 'Country is required',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',

    contact: 'Main Contact',
    financeContact: 'Finance Contact',
    financeContactUseMain: 'Use main contact',
    financeContactSameAsMain: 'Finance contact is same as the main one',

    contactName: 'First name',
    contactNameRequired: 'First name is required',
    contactSurname: 'Surname',
    contactSurnameRequired: 'Surname is required',
    jobTitle: 'Job title',
    jobTitleRequired: 'Job title required',
    email: 'Email',
    emailInvalid: 'Invalid email',
    emailRequired: 'Email is required',
    contactPhoneNumber: 'Telephone number',
    contactPhoneNumberInvalid: 'Invalid telephone number',
    contactPhoneNumberRequired: 'Telephone number is required',
    contactMobilePhone: 'Mobile number',
    contactMobilePhoneInvalid: 'Invalid mobile number',
    contactMobilePhoneRequired: 'Mobile number is required',

    communicationPreferences: 'Communication Preferences',
    receiveSalesAndMarketing: 'Receive sales and marketing communications',
    dontReceiveSalesAndMarketing: `Don't receive sales and marketing communications`,
    receiveSalesAndMarketingTooltip: 'Select this option if the merchant is happy for Optomany to keep them updated by email, post, SMS, phone and other electronic means. We\'ll always treat the contact details with the utmost care and in a GDPR compliant manner',

    nextStep: 'Next',
    back: 'Back'
})
