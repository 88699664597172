// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._34_3zpfVGCXiZyeb-Gosn9 {\n  font-size: 24px; }\n", ""]);
// Exports
exports.locals = {
	"LoadingIcon": "_34_3zpfVGCXiZyeb-Gosn9"
};
module.exports = exports;
