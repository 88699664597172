import React from "react";
const SvgCall = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <mask
      id="call_svg__a"
      maskUnits="userSpaceOnUse"
      x={3}
      y={3}
      width={18}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.2-.19.45-.29.71-.29.1 0 .21.01.31.05 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1zm2.54 2c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51zm9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#call_svg__a)">
      <path fill="#00A0DC" d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default SvgCall;
