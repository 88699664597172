import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { CopyOutlined } from '@ant-design/icons'
import { Col, Row, Form, Checkbox, Typography } from 'antd'
import { isEmpty, validatePostalCodeOfUK } from 'dna-common'
import { Button, Input, LabelWithTooltip, GroupTitle, Drawer, LinkButton, PostCode, CountrySelect, InputWithLoader } from '~/code/common/components'
import { COMPANY_NUMBER_LENGTH, COMPANY_NUMBER_PATTERN, COMPANY_NUMBER_TYPING_PATTERN, PHONE_NUMBER_PATTERN, PHONE_NUMBER_TYPING_PATTERN, ROI_COMPANY_NUMBER_LENGTH, ROI_COMPANY_NUMBER_PATTERN, ROI_COMPANY_NUMBER_TYPING_PATTERN, VALID_NAME_PATTERN, VALID_TEXT_PATTERN, ROI_ID, UK_ID, VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES } from '~/code/models'
import { getFieldValue } from '~/code/services'
import { searchAddressesByPostcode } from '~/code/POS/pages/NewOrder/services/fetchers'
import translations from './translations'
import { AddStoreFormProps } from './props'
import formStyles from '~/assets/styles/form.scss'
import styles from './AddStoreForm.scss'
import _ from 'lodash'

export const AddStoreForm = observer( ({ store, configCountry , onClose}: AddStoreFormProps) => {
    const { addStoreFormData: data, countries, setAddStoreFormData, isNewMerchant, isFormVisible, useMerchantDetails, isStoreNameLoading, getStores, isStoreNameValid } = store
    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            ...data
        })
    }, [data, data?.storeId])

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value || defaultValue || ''
    }

    const companyNumberPattern = () => {
        switch (configCountry) {
            case 'roi': {
                return [ROI_COMPANY_NUMBER_PATTERN, ROI_COMPANY_NUMBER_TYPING_PATTERN, ROI_COMPANY_NUMBER_LENGTH]
            }
            default: {
                return [COMPANY_NUMBER_PATTERN, COMPANY_NUMBER_TYPING_PATTERN, COMPANY_NUMBER_LENGTH]
            }
        }
    }

    const onUseMerchantDetailsClick = () => {
        useMerchantDetails()
    }

    const onFinish = (values) => {
        if (isStoreNameLoading) {
            return
        }
        values.storeCountryName = countries.find(item => item.value === values.storeCountry)?.label
        setAddStoreFormData(values)
        form.resetFields()
        onDrawerClose()
    }

    const onDrawerClose = () => {
        onClose && onClose()
    }

    const delayedQuery = useCallback(_.debounce(q => {getStores(q).then(() => {
        form.validateFields(['storeName'])
    })}, 500), [])

    function onStoreNameChange(value) {
        if (isNewMerchant) return
        delayedQuery(value)
    }

    const userBasedCountryInitialValue = configCountry === 'roi' ? ROI_ID : UK_ID
    const formInitialValues = {
        storeId: getFieldInitialValue('storeId'),
        storeName: getFieldInitialValue('storeName'),
        storeCompanyRegistrationNumber: getFieldInitialValue('storeCompanyRegistrationNumber'),
        storeVATNumber: getFieldInitialValue('storeVATNumber'),
        storeTelephoneNumber: getFieldInitialValue('storeTelephoneNumber'),
        storeEmail: getFieldInitialValue('storeEmail'),
        storeCountry: isEmpty(countries) ? null : ( isEmpty(getFieldInitialValue('storeCountry')) ? userBasedCountryInitialValue : Number(getFieldInitialValue('storeCountry')) ),
        storePostcode: getFieldInitialValue('storePostcode'),
        storeAddressLine1: getFieldInitialValue('storeAddressLine1'),
        storeAddressLine2: getFieldInitialValue('storeAddressLine2'),
        storeAddressLine3: getFieldInitialValue('storeAddressLine3'),
        storeTown: getFieldInitialValue('storeTown'),
        storeCounty: getFieldInitialValue('storeCounty'),
        useAxeptConnectCloud: getFieldInitialValue('useAxeptConnectCloud', false)
    }

    return (
        <Drawer
                    title={data.storeId ? translations().editStore : translations().addStore}
                    width={'50%'}
                    onClose={onDrawerClose}
                    shouldScrollToTopOnVisible={true}
                    visible={isFormVisible}>
            <Form layout={'vertical'} form={form} onFinish={onFinish} className={styles.AddStoreForm}
                  initialValues={formInitialValues}
                  scrollToFirstError={{ behavior: actions => {
                        actions.forEach(({ el, top }) => {
                            el.scrollTop = top - 50
                        })
                  }}}
            >
                <Row justify="space-between" gutter={24}>
                    <Col xs={isNewMerchant ? 16 : 24}><GroupTitle content={translations().store}/></Col>
                    {
                        isNewMerchant &&
                        <Col xs={8}>
                            <div className={styles.useMerchantDetailsButtonWrapper}>
                                <LinkButton onClick={onUseMerchantDetailsClick}><CopyOutlined/> {translations().useMerchantDetails}</LinkButton>
                            </div>
                        </Col>
                    }
                </Row>
                <Form.Item style={{ display: 'none' }} name={'storeId'}>
                    <Input />
                </Form.Item>
                <Row justify="space-between" gutter={24}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translations().storeName}
                            name={'storeName'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeName')
                                }
                                const val = value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                return val
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().storeNameRequired
                                },
                                {
                                    validator(rule, value) {
                                        if (!isStoreNameValid) {
                                            return Promise.reject(new Error(translations().storeNameAlreadyInUse))
                                        }
                                        return Promise.resolve()
                                    }
                                }
                            ]}
                            extra={<p className={styles.storeNameNotification}>{translations().tradingAsExtraText}</p>}
                        >
                            <InputWithLoader
                                placeholder={translations().storeName}
                                loading={isStoreNameLoading}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (!e.target.value) {
                                        return
                                    }

                                    onStoreNameChange(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translations().telephoneNumber}
                            name={'storeTelephoneNumber'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                const value = e.currentTarget.value
                                const test = phoneRegexp.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeTelephoneNumber')
                                }
                                return value
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().telephoneNumberRequired
                                },
                                {
                                    pattern: PHONE_NUMBER_PATTERN,
                                    message: translations().telephoneNumberInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().telephoneNumber}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={24}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translations().vatNumber}
                            name={'storeVATNumber'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeVATNumber')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: translations().vatNumberRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().vatNumber}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translations().email}
                            name={'storeEmail'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().emailRequired
                                },
                                {
                                    type: 'email',
                                    message: translations().emailInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().email} textTransform={'lowercase'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={24}>
                    <Col xs={24} sm={12}>
                        <Form.Item shouldUpdate noStyle>
                            {() => store.newOrderStore?.selectedItem?.additionalInfo?.merchantsAtStoreLevel || store.isMerchantsAtStoreLevel ? (
                                <Form.Item
                                    label={<LabelWithTooltip label={translations().companyNumber} tooltipText={configCountry === 'roi' ? translations().companyNumberROITooltip : translations().companyNumberUKTooltip} tooltipPosition={'right'}/>}
                                    name={'storeCompanyRegistrationNumber'}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const regex = companyNumberPattern()[1] as RegExp
                                        const value = e.currentTarget.value
                                        const test = regex.test(value)
                                        if (!test) {
                                            return form.getFieldValue('storeCompanyRegistrationNumber')
                                        }
                                        return value
                                    }}
                                    rules={[
                                        {
                                            required: false,
                                            message: translations().companyNumberRequired
                                        },
                                        {
                                            pattern: companyNumberPattern()[0] as RegExp,
                                            message: configCountry === 'roi' ? translations().companyNumberROIInvalid : translations().companyNumberUKInvalid
                                        }
                                    ]}
                                >
                                    <Input className={formStyles.input_uppercase}
                                           placeholder={translations().companyNumber}/>
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}></Col>
                </Row>
                <GroupTitle size={'small'} content={translations().address}/>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <CountrySelect
                            form={form}
                            initialValue={getFieldInitialValue('storeCountry')}
                            fieldName={'storeCountry'}
                            userCountry={configCountry}
                            countries={countries}
                            required={true}
                        />
                        <Form.Item shouldUpdate noStyle>
                            {() => {
                                return (
                                    <Form.Item
                                        label={translations().postcode}
                                        name={'storePostcode'}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations().postcodeRequired
                                            },
                                            {
                                                validator: async (rule, value) => {
                                                    const isUK = form.getFieldValue('storeCountry') === UK_ID
                                                    if (value && isUK && !validatePostalCodeOfUK(value)) {
                                                        throw new Error(translations().postcodeInvalid)
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <PostCode
                                            isUK={ form.getFieldValue('storeCountry') === UK_ID }
                                            getPostCodeAddresses={ (postCode: string) => searchAddressesByPostcode(postCode) }
                                            onAddressSelect={ (a) => {
                                                form.setFieldsValue({
                                                    storeAddressLine1: a.line_1,
                                                    storeAddressLine2: a.line_2,
                                                    storeAddressLine3: a.line_3,
                                                    storeTown: a.town_or_city,
                                                    storeCounty: a.county
                                                })
                                            } }
                                            placeholder={translations().postcode}
                                        />
                                    </Form.Item>
                                )}
                            }
                        </Form.Item>
                        <Form.Item
                            label={translations().addressLine1}
                            name={'storeAddressLine1'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeAddressLine1')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().addressLine1Required
                                }
                            ]}
                        >
                            <Input placeholder={translations().addressLine1}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().addressLine2}
                            name={'storeAddressLine2'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeAddressLine2')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: translations().addressLine2Required
                                }
                            ]}
                        >
                            <Input placeholder={translations().addressLine2}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().addressLine3}
                            name={'storeAddressLine3'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeAddressLine3')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                        >
                            <Input placeholder={translations().addressLine3}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={translations().town}
                            name={'storeTown'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeTown')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().townRequired
                                },
                                {
                                    pattern: VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES,
                                    message: translations().townInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().town}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().county}
                            name={'storeCounty'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('storeCounty')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: translations().countyRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().county}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().integrationOptions}
                            valuePropName={'checked'}
                            name={'useAxeptConnectCloud'}
                        >
                            <Checkbox
                                defaultChecked={data.useAxeptConnectCloud}
                                onChange={() => form.setFieldsValue({
                                    useAxeptConnectCloud: !form.getFieldValue('useAxeptConnectCloud')
                                })}
                            >{translations().axeptLabel}</Checkbox>
                            <div style={{marginTop: 10}}>
                                <Typography.Text style={{color: '#8C8C8C'}} italic={true}>{translations().axeptDescription}</Typography.Text>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={formStyles.buttonsContainer}>
                    <div className={formStyles.cancelButtonContainer}>
                        <Button outline={'outlined'} onClick={onDrawerClose}>
                            {translations().cancel}
                        </Button>
                    </div>
                    <div className={formStyles.submitButtonContainer}>
                        <Button className={null} htmlType={'submit'}>
                            { data.storeId ? translations().save : translations().add }
                        </Button>
                    </div>
                </div>
            </Form>
        </Drawer>
    )
})
