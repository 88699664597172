import translations from './translations'

translations.add('en', {
    brandingOrganisation: 'Custom branding selection',
    brandingOrganisationTooltip: 'If customer branding is required it will have been configured with Optomany prior to ordering, if your branding does not appear and you believe it should please contact Optomany.',
    orderInformation: 'Order Information',
    poNumber: 'PO number',
    poNumberRequired: 'PO number is required',
    activationDate: 'Requested activation date',
    contactsForActivation: 'Activation Contact',
    contactsForActivationTooltip: 'Please confirm the contact details for the person who is to receive the details (via email) required to activate the solution(s) ordered',
    useMainContact: 'use main contact',
    companyName: 'Company name',
    companyNameRequired: 'Company name is required',
    firstName: 'First name',
    firstNameRequired: 'First name is required',
    surname: 'Surname',
    surnameRequired: 'Surname is required',
    email: 'Email',
    emailRequired: 'Email is required',
    emailInvalid: 'Invalid email',
    back: 'Back',
    nextStep: 'Next',
    noWhiteSpace: 'No whitespace allowed'
})
