import { action, computed, makeObservable, observable } from 'mobx'
import moment, { Moment } from 'moment'
import { PeriodType } from '~/code/models'
import { IRangePickerStore } from '~/code/POS/pages/OrderHistory/components/RangePicker/IRangePickerStore'
import { getPeriodDates } from '~/code/services'
import { getPeriods } from './services'

export class RangePickerStore implements IRangePickerStore {

    constructor(public loadOrders: (pageNumber?) => void) {
        makeObservable(this, {
            open: observable,
            period: observable,
            startDate: observable,
            endDate: observable,
            isSelectedPeriod: computed,
            selectedPeriod: computed,
            setOpen: action,
            setPeriod: action,
            setDates: action
        })
    }

    open: boolean = false

    period: PeriodType = PeriodType.Week

    startDate: Moment = moment().startOf('isoWeek')

    endDate: Moment = moment().endOf('day')

    public get isSelectedPeriod(): boolean {
        return !!this.period
    }

    public get selectedPeriod() {
        return getPeriods().find(({ value }) => value === this.period)
    }

    setOpen = (open: boolean = !this.open) => {
        this.open = open
    }

    setPeriod = (period: PeriodType) => {
        const { startDate, endDate } = getPeriodDates(period)
        this.startDate = startDate
        this.endDate = endDate
        this.period = period
        this.setOpen()
        this.loadOrders()
    }

    setDates = (dates: Moment[]) => {
        if (dates.length !== 2) return

        const [startDate, endDate] = dates

        if (startDate !== this.startDate && endDate !== this.endDate) this.setOpen()

        this.startDate = startDate
        this.endDate = endDate
        this.period = null
        this.loadOrders()
    }
}
