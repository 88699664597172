import React from 'react'
import moment from 'moment'
import translations from '~/code/ODIN/pages/DeviceManagementDetails/translations'
import { getCommunicationMethodLabelByType } from '~/code/ODIN/pages/DeviceManagementDetails/utils/getCommunicationMethodLabelByType'

export const communicationsMethodColumns = [
    {
        title: `${translations().dateTime}`,
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: date => date ? moment(date).format('DD/MM/YY HH:mm:ss') : null
    },
    {
        title: translations().communicationsMethod,
        dataIndex: 'communicationMediaType',
        key: 'communicationMediaType',
        render: method => getCommunicationMethodLabelByType(+method)
    }
]

export const batteryColumns = [
    {
        title: `${translations().dateTime}`,
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: date => date ? moment(date).format('DD/MM/YY HH:mm:ss') : date
    },
    {
        title: translations().batteryPercentage,
        dataIndex: 'batteryLevel',
        key: 'batteryLevel'
    }
]
