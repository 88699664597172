import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    new: string
    existing: string
    soleTrader: string
    company: string
    uk: string
    roi: string
    other: string
    orderingFor: string
    merchant: string
    organisationTypeCompany: string
    organisationTypeSoleTrader: string
    andLocatedIn: string
    areYouSureToChangeMerchantType: JSX.Element
    yesSureToChangeMerchantType: string
    noKeepMerchantType: string
    areYouSureToChangeOrganisationType: JSX.Element
    yesSureToChangeOrganisationType: string
    noKeepOrganisationType: string
    areYouSureToChangeCountry: JSX.Element
    yesSureToChangeCountry: string
    noKeepCountry: string
    areYouSureToChangeOrderType: JSX.Element
    yesSureToChangeOrderType: string
    noKeepOrderType: string
    standard: string
    hardwareOnly: string
    ofType: string
}>()
