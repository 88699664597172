import React from 'react'
import { message, notification } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { error, isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { fetchCreateNewDeviceLog, fetchDeviceLogs, restartDevice } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { IManagementActionsStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/ManagementActions/IManagementActionsStore'
import { RestartDevice } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/RestartDevice'
import translations from '~/code/ODIN/pages/DeviceManagementDetails/components/ManagementActions/translations'
import { RetrieveLog } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/RetrieveLog'
import { get } from 'store-connector'
import { ApiResponse } from 'back-connector'
import { RestartHistoryStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/RestartHistoryStore'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { SMALL_PAGE_SIZE } from '~/code/models'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import commonTranslations from '~/code/translations/translations'

export class ManagementActionsStore implements IManagementActionsStore {
    parentStore: IAppStore
    isLoading: boolean = false
    isCreateLogLoading: boolean = false
    isLogLoading: boolean = false
    isLogsLoading: boolean = false
    isRestartCaptchaVisible: boolean = false
    isRetrieveLogCaptchaVisible: boolean = false
    logs: RetrieveLog[] = []
    restartHistoryStore: RestartHistoryStore
    pageNumber: number = 0
    pageSize: number = SMALL_PAGE_SIZE
    totalCount: number = 0
    totalPagesItems: number = 0

    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            isLogLoading: observable,
            isLogsLoading: observable,
            logs: observable,
            isRestartCaptchaVisible: observable,
            isRetrieveLogCaptchaVisible: observable,
            isCreateLogLoading: observable,
            pageNumber: observable,
            pageSize: observable,
            totalCount: observable,
            totalPagesItems: observable,
            permissions: computed,
            restartDevice: action,
            setLogs: action,
            loadDeviceLogs: action,
            downloadDeviceLog: action,
            setIsRestartCaptchaVisible: action,
            setIsRetrieveLogCaptchaVisible: action,
            clearAndLoadDeviceLogs: action,
            createDeviceLog: action
        })

        this.parentStore = parentStore
        this.restartHistoryStore = new RestartHistoryStore(parentStore)
    }

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    public restartDevice = (deviceId: number, data: RestartDevice) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        restartDevice(deviceId, data)
            .then((_) => {
                notification.info({
                    message: translations().restartRequested
                })
            })
            .catch((err: Error) => {
                const errorObject = JSON.parse(err.message)

                const errors = errorObject?.error?.errors?.map(item => <div key={item.errorMessage}>{`${item.propertyName} - ${item.errorMessage}`}</div>)
                notification.error({
                    message: <>{errors}</>,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    public createDeviceLog = (deviceId: number, data: RestartDevice) => {
        if (this.isCreateLogLoading) {
            return
        }

        this.isCreateLogLoading = true

        fetchCreateNewDeviceLog(deviceId, data)
            .then((_) => {
                notification.info({
                    message: translations().createNewLogRequested
                })
            })
            .catch((err: Error) => {
                const errorObject = JSON.parse(err.message)

                const errors = errorObject?.error?.errors?.map(item => <div key={item.errorMessage}>{`${item.propertyName} - ${item.errorMessage}`}</div>)
                notification.error({
                    message: <>{errors}</>,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isCreateLogLoading = false
                })
            })
    }

    public loadDeviceLogs = (deviceId: number, currentPage?) => {
        if (this.isLogsLoading) {
            return
        }

        this.logs = []

        this.isLogsLoading = true

        this.pageNumber = isEmpty(currentPage) ? 1 : currentPage

        if (this.pageNumber === 1) {
            this.logs = []
        }

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber
        }

        fetchDeviceLogs(deviceId, queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    runInAction(() => {
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.setLogs(response?.result)
                        this.totalCount = pagination.totalCount
                        this.totalPagesItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLogsLoading = false
                })
            })
    }

    public downloadDeviceLog = (deviceId: number, deviceLogId: string) => {
        if (this.isLogLoading) {
            return
        }

        this.isLogLoading = true

        fetch(`/odin-api/api/Devices/${deviceId}/Logs/${deviceLogId}`, {
            headers: {
                Authorization: `Bearer ${get('accessToken')}`
            }
        })
            .then(response => {
                let fileName = ''
                response.headers.forEach(header => {
                    if (header.startsWith('attachment; filename=')) {
                        fileName = header.substring(
                            header.indexOf('=') + 1,
                            header.lastIndexOf(';')
                        )
                    }
                })
                return response.blob()
                    .then(blob => ({
                        fileName,
                        blob
                    }))
            })
            .then(({ blob, fileName }) => ({
                uri: URL.createObjectURL(blob),
                fileName
            }))
            .then(({uri, fileName}) => {
                const link = document.createElement('a')
                link.href = uri
                link.download = fileName ? fileName : 'file' + '.zip'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch(err => error(err))
            .finally(() => {
                runInAction(() => {
                    this.isLogLoading = false
                })
            })
    }

    public setLogs = (logs: RetrieveLog[]) => {
        this.logs = logs
    }

    public setIsRestartCaptchaVisible = (isRestartCaptchaVisible: boolean) => {
        this.isRestartCaptchaVisible = isRestartCaptchaVisible
    }

    public setIsRetrieveLogCaptchaVisible = (isRetrieveLogCaptchaVisible: boolean) => {
        this.isRetrieveLogCaptchaVisible = isRetrieveLogCaptchaVisible
    }

    public clearAndLoadDeviceLogs = (deviceId: number) => {
        this.logs = []
        this.loadDeviceLogs(deviceId)
    }
}
