import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    noDate: string
    category: string
    displayName: string
    latestVersion: string
    packageId: string
    packageName: string
    supportedDeviceTypes: string
    uploadedDateTime: string
    pleaseSelectAppDeveloper: string
    selectAppDeveloper: string
    searchName: string
    search: string
    reset: string
    cancel: string
}>()
