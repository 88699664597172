import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Tabs, Typography } from 'antd'
import { Page } from '~/code/common/pages/Page'
import { Button } from '~/code/common/components'
import { PlusOutlined } from '@ant-design/icons'
import { AllApplications } from '~/code/ODIN/components/AllApplications'
import { AddNewApplication } from '~/code/ODIN/pages/ApplicationManagement/components/AddNewApplication/AddNewApplication'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import translations from './translations'
import { IApplicationManagementProps } from './props'
import styles from './ApplicationManagement.scss'

const {Title} = Typography
const {TabPane} = Tabs

export const ApplicationManagement = observer((props: IApplicationManagementProps) => {
    const { store } = props
    const [isAddNewApp, setIsAddNewApp] = useState<boolean>(false)

    return (
        <Page>
            <Title level={3}>{isAddNewApp ? `${translations().applicationManagement} - ${translations().uploadNewApplication}` : translations().applicationManagement}</Title>

            {!isAddNewApp && (
                <>
                    {store.permissions?.includes('odin.applicationManagement.create') && (
                        <div className={styles.upload}>
                            <Button
                                disabled={!store.permissions?.includes('odin.applicationManagement.create')}
                                onClick={() => setIsAddNewApp(true)}
                                icon={<PlusOutlined />}
                            >
                                {translations().uploadNewApplication}
                            </Button>
                        </div>
                    )}

                    <Tabs defaultActiveKey="1">
                        {store.permissions?.includes('odin.applicationManagement.view') && (
                            <TabPane tab={translations().allApps} key="1">
                                <AllApplications packageType={PackageType.Application} store={store.allAppsStore}/>
                            </TabPane>
                        )}
                    </Tabs>
                </>
            )}

            {isAddNewApp && <AddNewApplication store={store.addNewApplicationStore} setIsAddNewApp={setIsAddNewApp}/>}
        </Page>
    )
})
