// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3TxBRR_uLrTVxhy0dfXCJ6 {\n  width: 100%;\n  height: 100%;\n  padding: 25px 75px; }\n  @media (max-width: 980px) {\n    ._3TxBRR_uLrTVxhy0dfXCJ6 {\n      padding: 25px 8px; } }\n", ""]);
// Exports
exports.locals = {
	"Page": "_3TxBRR_uLrTVxhy0dfXCJ6"
};
module.exports = exports;
