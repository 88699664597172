// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1uv42Qr5YoohzWl4p9jrOk {\n  margin-right: 8px; }\n\n.u4uOIAR8Gd0xYku32UYZY {\n  display: flex;\n  justify-content: end; }\n", ""]);
// Exports
exports.locals = {
	"cancel": "_1uv42Qr5YoohzWl4p9jrOk",
	"upload": "u4uOIAR8Gd0xYku32UYZY"
};
module.exports = exports;
