import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Collapse, Input, List, Row, Select, Typography } from 'antd'
import { Button } from '~/code/common/components'
import { CommunicationMediaTypes } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/CommunicationMediaTypes'
import { DeviceStatusDateType } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/DeviceStatusDateType'
import { DeviceStatusConfiguration } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/DeviceStatusConfiguration'
import translations from '~/code/ODIN/pages/MerchantSchedule/translations'
import { DeviceStatusScheduleProps } from './props'
import styles from './DeviceStatusSchedule.scss'
import { LoadingView } from '~/code/POS/pages'

const { Text } = Typography
const { Option } = Select
const { Panel } = Collapse

export const DeviceStatusSchedule = observer((props: DeviceStatusScheduleProps) => {
    const { store } = props

    useEffect(() => {
        store.loadConfiguration()
    }, [])

    const handleDeviceDateTypeChange = (dateType: DeviceStatusDateType, index: number) => {
        const newConfigs = [...store.configurations]
        newConfigs[index].dateType = dateType

        store.setConfigurations(newConfigs)
    }

    const handleDeviceTimeChange = (time: number, index: number) => {
        const newConfigs = [...store.configurations]

        if (newConfigs[index].dateType === 'days') {
            newConfigs[index].hours = time
        }

        if (newConfigs[index].dateType === 'hours') {
            newConfigs[index].hours = time
        }

        if (newConfigs[index].dateType === 'minutes') {
            newConfigs[index].minutes = time
        }

        store.setConfigurations(newConfigs)
    }

    const getValueByDateType = (configuration: DeviceStatusConfiguration) => {
        switch (configuration.dateType) {
            case 'days':
                return configuration.days
            case 'hours':
                return configuration.hours
            case 'minutes':
                return configuration.minutes
        }
    }

    const renderDeviceScheduleTimePicker = (type: CommunicationMediaTypes, index: number) => {
        const defaultValue = type === CommunicationMediaTypes.cellular ? 'hours' : 'minutes'
        const configuration = store.configurations?.find(config => config.type === type)

        return <div className={styles.date}>
            <Input
                className={styles.timePicker}
                maxLength={2}
                type={'number'}
                value={getValueByDateType(configuration)}
                onChange={(e) => handleDeviceTimeChange(+e.target.value, index)}
                disabled={!store.permissions?.includes('odin.merchantSchedules.deviceStatus.update')}
            />
            <Select
                defaultValue={defaultValue}
                style={{ width: 120 }}
                value={store.configurations[index]?.dateType}
                onChange={(value: DeviceStatusDateType) => handleDeviceDateTypeChange(value, index)}
                disabled={!store.permissions?.includes('odin.merchantSchedules.deviceStatus.update')}
            >
                <Option disabled={type === CommunicationMediaTypes.cellular} value="minutes">{translations().minutes}</Option>
                <Option value="hours">{translations().hours}</Option>
                <Option value="days">{translations().days}</Option>
            </Select>
        </div>
    }

    if (store.isLoading) {
        return <div>
            <LoadingView status={'loading'} message={translations().loading}/>
            <div id={'loadingViewBottom'}/>
        </div>
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Text>{translations().thisIsHowRegularlyYourDeviceProvide}</Text>
            </Col>
            <Col span={24}>
                <Text type={'danger'}>{translations().settingsApplyToAllDevices}</Text>
            </Col>
            <Col span={24}>
                <Collapse defaultActiveKey={[CommunicationMediaTypes.ethernet]}>
                    {store.configurations?.map((item, index) => (
                        <Panel header={item.title} key={item.type}>
                            <List.Item actions={[renderDeviceScheduleTimePicker(item.type, index)]}>
                                <List.Item.Meta title={item.description}/>
                            </List.Item>
                        </Panel>
                    ))}
                </Collapse>
            </Col>
            <Col span={24}>
                { store.permissions?.includes('odin.merchantSchedules.deviceStatus.update') && (
                    <Button
                        disabled={!store.permissions?.includes('odin.merchantSchedules.deviceStatus.update')}
                        loading={store.isLoading}
                        onClick={() => store.saveSchedule()}
                    >
                        {translations().save}
                    </Button>
                )}
            </Col>
        </Row>
    )
})
