import {
    CheckoutSettingsFormData, createCheckoutSettingsFormData,
    ICheckoutSettingsFormStore
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { OnboardingDetailsStepKey } from '~/code/stores/CheckoutV3Onboarding/models'
import { SelectItem } from '~/code/common/components'
import { ICDNUploadResponse } from '~/code/stores/CheckoutV3Onboarding/models/backend/ICDNUploadResponse'
import { ImageUploadStore } from '~/code/stores/CheckoutV3Onboarding/stores/ImageUploadStore/ImageUploadStore'
import { AppStore } from '~/code/AppStore'
import translations from '~/code/stores/CheckoutV3Onboarding/stores/PenniesFormStore/translations/translations'

export class CheckoutSettingsFormStore implements ICheckoutSettingsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            checkoutSettingsFormData: observable,
            secondaryLogoImagePositions: computed,
            donationModes: computed,
            merchantCompanyName: computed,
            integrationTypes: computed,
            setImage: action,
            goBack: action,
            uploadImage: action
        })

        this.checkoutSettingsFormData = createCheckoutSettingsFormData()
        this.imageUploadStore = new ImageUploadStore()
    }

    static stepName: OnboardingDetailsStepKey = 'customisations'

    public imageUploadStore: ImageUploadStore

    checkoutSettingsFormData: CheckoutSettingsFormData

    get secondaryLogoImagePositions(): SelectItem[] {
        return [
            { label: 'Right', value: 'right' },
            { label: 'Left', value: 'left' }
        ]
    }

    setCheckoutSettingsFormData = (data: CheckoutSettingsFormData) => {
        this.checkoutSettingsFormData = {...this.checkoutSettingsFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.checkoutSettingsFormData, CheckoutSettingsFormStore.stepName)
    }

    canGoBack: boolean = true

    get donationModes(): SelectItem[] {
        return [
            { label: translations().topUp, value: 'TopUp' },
            { label: translations().roundUp, value: 'RoundUp' }
        ]
    }

    get merchantCompanyName() {
        return AppStore.checkoutV3OnboardingStore?.merchantDetailsFormStore?.merchantDetailsFormData?.companyNameFull
    }

    get integrationTypes(): SelectItem[] {
        return [
            { label: 'Seamless', value: 'seamless' },
            { label: 'Embedded', value: 'embedded' },
            { label: 'Hosted', value: 'hosted' }
        ]
    }

    setImage (cdnImageData: ICDNUploadResponse, name: string) {
        this.checkoutSettingsFormData[name] = cdnImageData.url
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(CheckoutSettingsFormStore.stepName)
    }

    uploadImage = (image: File, name?: string) => {
        const companyName = this.merchantCompanyName?.replace(' ', '_').toLowerCase()

        return this.imageUploadStore?.uploadImage(image, companyName).then(res => {
            this.setImage(res, name)
            return res
        })
    }
}
