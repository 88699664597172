import translations from './translations'

translations.add('en', {
    contactUs: 'Contact Us',
    orderHistory: 'Order History',
    reviewOrder: 'Review Order',
    requestHistory: 'Request History',
    success: 'Success',
    requestHasBeenSubmitted: 'Your request has been submitted',
    trackRequestStatusInRequestHistory: 'You can track the progress of your request in the "Onboarding Request History" section',
    sorry: 'Sorry',
    error: 'Error',
    unableToSubmitRequest: 'Unfortunately, we are unable to process your request at this time',
    requestReference: 'Onboarding request reference',
    pleaseContactUs: 'Please contact us using the details provided and one of the team will be happy to assist',
    submittingRequest: 'Your request is being submitted...',
    pleaseWait: 'Please wait'
})
