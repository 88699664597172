import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    cancel: string
    partNumber: string
    androidVersion: string
    operatingSystemLanguage: string
    timeZone: string
    paymentChannelId: string
    visa: string
    mastercard: string
    amex: string
    wifi: string
    cellular: string
    deptId: string
    cardScheme: string
    acquirer: string
    currency: string
    MID: string
    TID: string
    payments: string
    communicationDetails: string
    ipAddress: string
    subnet: string
    gateway: string
    signalStrength: string
    macAddress: string
    networkOperator: string
    simNumber: string
    apn: string
    apnName: string
    isCurrentApn: string
    inUseAPNDetails: string
    additionalAPNDetails: string
    noAPNsConfigured: string
}>()
