// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2SXkrwclrbf7C66q3VrIfN {\n  color: black; }\n  ._3C2gXWXdLdfdiW46cSUl6 {\n    color: #00a0dc; }\n  ._1ucjouKRXhGCDp1W_i7-2- {\n    color: #f5222d; }\n", ""]);
// Exports
exports.locals = {
	"Label": "_2SXkrwclrbf7C66q3VrIfN",
	"Label__info": "_3C2gXWXdLdfdiW46cSUl6",
	"Label__error": "_1ucjouKRXhGCDp1W_i7-2-"
};
module.exports = exports;
