import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    orderHistory: string
    refresh: string
    for: string
    status: string
    noOrders: string
    any: string
    failedValidationRound1: string
    receivedAndPassedValidationRound1: string
    passedValidationRound2: string
    failedValidationRound2: string
    dispatching: string
    completed: string
    duplicateOrder: string
    partialSuccess: string
    failed: string
    reversed: string
}>()
