import React, { MutableRefObject, useRef, useState } from 'react'
import ReactIdleTimer from 'react-idle-timer'
import { observer } from 'mobx-react'
import { ModalIdleWarning } from './components/ModalIdleWarning'
import { TIME_OUT_TIME } from './models/constants'
import { IdleTimerProps } from './props'

export const IdleTimer = observer((props: IdleTimerProps) => {
    const [showIdleWarning, setShowIdleWarning] = useState(false)
    const idleTimerRef: MutableRefObject<ReactIdleTimer> = useRef<ReactIdleTimer>(null)

    const onIdle = (e) => {
        idleTimerRef.current.pause()
        setShowIdleWarning(true)
    }

    const onProlongSession = () => {
        setShowIdleWarning(false)
        idleTimerRef.current.reset()
    }

    const onCancelSession = () => {
        setShowIdleWarning(false)
        props.onCancelSession()
    }

    return <div>
        <ReactIdleTimer
            ref={idleTimerRef}
            element={document}
            onIdle={onIdle}
            debounce={250}
            timeout={props.timeOut || TIME_OUT_TIME} />
        { showIdleWarning &&
            <ModalIdleWarning
                visible={showIdleWarning}
                onOk={onProlongSession}
                onCancel={onCancelSession}/>
        }
    </div>
})
