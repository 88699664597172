import React from 'react'
import { observer } from 'mobx-react'
import { Dropdown } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { UserDetails, UserShortInfo } from '~/code/common/layouts/MainLayout/components/Header/components'
import { HeaderProps } from './props'
import styles from './Header.scss'

export const Header = observer((props: HeaderProps) => {
    const { store } = props
    const { userInfo } = store
    const hasUserInfo = userInfo && userInfo.firstName
    const userInfoBlock = (
        <UserDetails userInfo={userInfo}/>
    )

    return (
        <div className={styles.Header}>
            <div className={styles.Header_leftContent}>
                <div className={styles.Header_menuToggle} onClick={() => { store.toggleSideMenu() }}>
                    { store.isSideMenuVisible ? <MenuFoldOutlined type={'menu-fold'} /> : <MenuUnfoldOutlined type={'menu-unfold'}/> }
                </div>
                <div className={styles.Header_logo}/>
            </div>
            <div className={styles.Header_rightContent}>
                { hasUserInfo &&
                    <Dropdown overlay={userInfoBlock} placement={'bottomRight'}>
                        <div><UserShortInfo userInfo={userInfo}/></div>
                    </Dropdown>
                }
                <div className={styles.Header_logOut} onClick={store.logOut}/>
            </div>
        </div>
    )
})
