// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/dna_payments_logo_dark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/img/logo_dark.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/img/divider.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "._2U6W1-YyMAOd5gE9EJQVx2 {\n  width: 100%;\n  height: 100%;\n  background-color: #F5F5F5;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column; }\n  ._3bWQR8bTInZ8pnFucgdxOd {\n    width: 100%;\n    max-width: 450px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    margin-top: -45px; }\n  ._3au67PSK76PiA8C2epRWB- {\n    padding-bottom: 28px;\n    display: flex; }\n  .j6X6ydgZQOuv_GX4N2i9h {\n    width: 210px;\n    height: 40px;\n    background-size: contain !important;\n    shape-rendering: geometricPrecision;\n    cursor: pointer; }\n    ._1fFFmDV3DjM_PqTwDn3X1p {\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat; }\n    ._1RpdL52eN7BXP_zz8KkUo2 {\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center no-repeat; }\n  ._2U6W1-YyMAOd5gE9EJQVx2 ._3HO24UFJ0KuLbQ2bt6YsLL {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") center no-repeat;\n    height: 36px;\n    width: 26px; }\n  ._1yYNr5sOmQ7meYBgz2jOrQ {\n    width: 100%; }\n  ._2U6W1-YyMAOd5gE9EJQVx2 .tXfU-XMAI47EppFuSXUEl {\n    font-size: 24px; }\n", ""]);
// Exports
exports.locals = {
	"Login": "_2U6W1-YyMAOd5gE9EJQVx2",
	"Login_content": "_3bWQR8bTInZ8pnFucgdxOd",
	"Login_logoWrapper": "_3au67PSK76PiA8C2epRWB-",
	"Login_logo": "j6X6ydgZQOuv_GX4N2i9h",
	"Login_logo_dna": "_1fFFmDV3DjM_PqTwDn3X1p",
	"Login_logo_optomany": "_1RpdL52eN7BXP_zz8KkUo2",
	"divider": "_3HO24UFJ0KuLbQ2bt6YsLL",
	"Login_formWrapper": "_1yYNr5sOmQ7meYBgz2jOrQ",
	"icon": "tXfU-XMAI47EppFuSXUEl"
};
module.exports = exports;
