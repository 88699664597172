import { SummaryBlockData } from '~/code/common/components/SummaryBlock/models/SummaryBlockData'
import { ConfigurationReportModel } from '~/code/POS/pages'
import translations from '../translations'
import { StoreConfigurationModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/components/ConfigurationReport/models/StoreConfigurationModel'
import { DepartmentConfigurationModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/components/ConfigurationReport/models/DepartmentConfigurationModel'
import { removeEmptyFieldsFromRowData } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/services/order-detail-utils'
import { DeviceDetailModel } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddStoreForm/models/DeviceDetailModel'

export function configurationReportModelToSummaryBlockData(configurationReport: ConfigurationReportModel = new ConfigurationReportModel()): SummaryBlockData[] {
    const {
        orderReference, orderId, opportunityNo, salesSource, referee, merchantName, merchantSystemId, noOfStores,
        noOfDepartments, storeConfiguration
    } = configurationReport

    const summaryBlockDataArray: SummaryBlockData[] = []
    const reportSummaryData: SummaryBlockData = {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().orderReference,
                        value: `${orderReference ? orderReference : translations().noReference }`,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().orderId,
                        value: orderId,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().opportunityNo,
                        value: opportunityNo,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().salesSource,
                        value: salesSource,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().referee,
                        value: referee,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().merchantName,
                        value: merchantName,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().merchantSystemId,
                        value: merchantSystemId,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().noOfStores,
                        value: noOfStores,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().noOfDepartments,
                        value: noOfDepartments,
                        span: {xs: 24, sm: 12}
                    }
                ]
            }
        ]
    }
    summaryBlockDataArray.push(reportSummaryData)
    summaryBlockDataArray.push(...storeConfigArrayToSummaryBlockDataArray(storeConfiguration))
    reportSummaryData.rows = removeEmptyFieldsFromRowData(reportSummaryData.rows)

    return summaryBlockDataArray
}

export function storeConfigArrayToSummaryBlockDataArray(storeConfigArray: StoreConfigurationModel[]): SummaryBlockData[] {
    const result: SummaryBlockData[] = []
    if (storeConfigArray?.length > 1) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < storeConfigArray.length; i++ ){
            const storeConfigSummaryData = storeConfigurationToSummaryBlockData(storeConfigArray[i], i + 1)
            result.push(storeConfigSummaryData)
            result.push(...departmentConfigArrayToSummaryBlockDataArray(storeConfigArray[i].departmentConfiguration))
            result.push(...deviceDetailArrayToSummaryBlockDataArray(storeConfigArray[i].deviceDetail))
        }
    } else if (storeConfigArray?.length === 1){
        const storeConfigSummaryData = storeConfigurationToSummaryBlockData(storeConfigArray[0])
        result.push(storeConfigSummaryData)
        result.push(...departmentConfigArrayToSummaryBlockDataArray(storeConfigArray[0].departmentConfiguration))
        result.push(...deviceDetailArrayToSummaryBlockDataArray(storeConfigArray[0].deviceDetail))
    }

    return result
}

export function storeConfigurationToSummaryBlockData(storeConfig: StoreConfigurationModel = new StoreConfigurationModel(), order?: number): SummaryBlockData {
    const {
        merchantStoreId, name, storeKey, licenceKey, s300PEDCount, yomaniPEDCount, yomaniXRPEDCount, xenteoPEDCount,
        xenteoECOPEDCount, s800Count, s900Count, s300Count, a920GoCount, a920ProCount, a920PositiveCount,
        v200CCount, v240MCount, wlPaymentsAppCount
    } = storeConfig
    return {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: `${translations().storeConfiguration}${order > 0 ? ` - ${order}` : ''}`
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().merchantStoreId,
                        value: merchantStoreId,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().storeName,
                        value: name,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().storeKey,
                        value: storeKey,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().licenceKey,
                        value: licenceKey,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().s300PEDCount,
                        value: s300PEDCount,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().yomaniPEDCount,
                        value: yomaniPEDCount,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().yomaniXRPEDCount,
                        value: yomaniXRPEDCount,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().xenteoPEDCount,
                        value: xenteoPEDCount,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().xenteoECOPEDCount,
                        value: xenteoECOPEDCount,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().s800Count,
                        value: s800Count,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().s900Count,
                        value: s900Count,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().s300Count,
                        value: s300Count,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().a920GoCount,
                        value: a920GoCount,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().a920ProCount,
                        value: a920ProCount,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().a920PositiveCount,
                        value: a920PositiveCount,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().wlPaymentsAppCount,
                        value: wlPaymentsAppCount,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().v200CCount,
                        value: v200CCount,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().v240MCount,
                        value: v240MCount,
                        span: {xs: 24, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function departmentConfigArrayToSummaryBlockDataArray(departmentConfigArray: DepartmentConfigurationModel[]): SummaryBlockData[] {
    const result: SummaryBlockData[] = []
    if (departmentConfigArray?.length > 1) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < departmentConfigArray.length; i++ ){
            const storeConfigSummaryData = departmentConfigurationToSummaryBlockData(departmentConfigArray[i], i + 1)
            result.push(storeConfigSummaryData)
        }
    } else if (departmentConfigArray?.length === 1){
        const storeConfigSummaryData = departmentConfigurationToSummaryBlockData(departmentConfigArray[0])
        result.push(storeConfigSummaryData)
    }

    return result
}

export function deviceDetailArrayToSummaryBlockDataArray(deviceDetailConfigArray: DeviceDetailModel[]): SummaryBlockData[] {
    const result: SummaryBlockData[] = []
    if (deviceDetailConfigArray?.length > 1) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < deviceDetailConfigArray.length; i++ ){
            const storeConfigSummaryData = deviceDetailToSummaryBlockData(deviceDetailConfigArray[i], i + 1)
            result.push(storeConfigSummaryData)
        }
    } else if (deviceDetailConfigArray?.length === 1){
        const storeConfigSummaryData = deviceDetailToSummaryBlockData(deviceDetailConfigArray[0])
        result.push(storeConfigSummaryData)
    }

    return result
}

export function departmentConfigurationToSummaryBlockData(departmentConfig: DepartmentConfigurationModel = new DepartmentConfigurationModel(), order?: number): SummaryBlockData {
    const {
        merchantDepartmentId, name, signatureKey, cnpChannelCount, tids
    } = departmentConfig
    return {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: `${translations().departmentConfiguration}${order > 0 ? ` - ${order}` : ''}`
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().merchantDepartmentId,
                        value: merchantDepartmentId,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().departmentName,
                        value: name,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().signatureKey,
                        value: signatureKey,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().cnpChannelCount,
                        value: cnpChannelCount,
                        span: {xs: 24, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().tids,
                        value: tids?.join(','),
                        span: {xs: 24, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function deviceDetailToSummaryBlockData(deviceDetail: DeviceDetailModel = new DeviceDetailModel(), order?: number): SummaryBlockData {
    const {
        productId, name, quantity
    } = deviceDetail
    return {
        header: null,
        rows: [
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: `${translations().deviceDetail}${order > 0 ? ` - ${order}` : ''}`
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: translations().productId,
                        value: productId,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().deviceName,
                        value: name,
                        span: {xs: 24, sm: 12}
                    },
                    {
                        type: 'field',
                        label: translations().quantity,
                        value: quantity,
                        span: {xs: 24, sm: 12}
                    }
                ]
            }
        ]
    }
}
