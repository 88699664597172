import React from 'react'
import translations from './translations'

translations.add('en', {
    noProductAdded: 'Click the button below to start adding products to the order',
    products: 'Products',
    nextStep: 'Next',
    addProduct: 'Add New Products',
    back: 'Back'
})
