import React from "react";
const SvgSearch = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <mask
      id="search_svg__a"
      maskUnits="userSpaceOnUse"
      x={3}
      y={3}
      width={18}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.965 14.255h.79l4.99 5-1.49 1.49-5-4.99v-.79l-.27-.28a6.471 6.471 0 01-4.23 1.57 6.5 6.5 0 116.5-6.5c0 1.61-.59 3.09-1.57 4.23l.28.27zm-9.71-4.5c0 2.49 2.01 4.5 4.5 4.5s4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5-4.5 2.01-4.5 4.5z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#search_svg__a)">
      <path fill="#00A0DC" d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default SvgSearch;
