import translations from './translations'

translations.add('en', {
    companyName: 'Company name',
    companyNameRequired: 'Company name is required',
    companyNameInvalid: 'Company name is invalid',
    tradingAs: 'Store name',
    tradingAsRequired: 'Store name is required',
    storeNameAlreadyInUse: 'This store name is already in use',
    tradingAsExtraText: 'PLEASE NOTE: If ordering a terminal solution this will appear as the merchant name on all receipts.',
    companyNumber: 'Company number',
    companyNumberRequired: 'Company number is required',
    companyNumberUKInvalid: 'Company number must consist of 8 digits or 2 letters followed by 6 digits or 2 letters followed by 5 digits followed by 1 letter',
    companyNumberROIInvalid: 'Company number must consist of 5 or 6 digits',
    companyNumberUKTooltip: 'Company registration number (CRN) as registered with Companies House. If you do not have a company number, please select the “sole trader” option.',
    companyNumberROITooltip: 'Company or business number as registered with Companies Registration Office (CRO). If you do not have a company or business number, please select the “sole trader” option.',
    vatNumber: 'VAT number',
    vatNumberRequired: 'VAT number is required',

    merchantAddress: 'Store address',

    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',
    addressLine3Required: 'Address line 3 is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    townInvalid: 'Town/City is invalid',
    county: 'County',
    countyRequired: 'County is required',
    country: 'Country',
    countryRequired: 'Country is required',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',

    contactName: 'Contact name',
    contactNameRequired: 'Contact name is required',
    contactSurname: 'Contact surname',
    contactSurnameRequired: 'Contact surname is required',
    jobTitle: 'Job title',
    jobTitleRequired: 'Job title required',
    email: 'Email',
    emailInvalid: 'Invalid email',
    emailRequired: 'Email is required',
    contactPhoneNumber: 'Contact phone number',
    contactPhoneNumberInvalid: 'Invalid contact phone number',
    contactPhoneNumberRequired: 'Contact phone number is required',
    contactMobilePhone: 'Contact mobile phone',
    contactMobilePhoneInvalid: 'Invalid contact mobile phone',
    contactMobilePhoneRequired: 'Contact mobile phone is required',

    nextStep: 'Next',
    back: 'Back'
})
