// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3y6kp1nytOyMaIi7h8Pvvz .ant-card-actions > li > span {\n  display: inline; }\n\n._3y6kp1nytOyMaIi7h8Pvvz .wyi5Nq046T9MVS1Es-Mpj {\n  cursor: default; }\n  ._3y6kp1nytOyMaIi7h8Pvvz .wyi5Nq046T9MVS1Es-Mpj:hover {\n    color: rgba(0, 0, 0, 0.45); }\n\n._3y6kp1nytOyMaIi7h8Pvvz ._27h9g8n4Vj5alofDl-OUTF:hover {\n  color: #D23153; }\n\n._3y6kp1nytOyMaIi7h8Pvvz .BsX2fK3xdwGscktF93sj0 {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n", ""]);
// Exports
exports.locals = {
	"DepartmentCard": "_3y6kp1nytOyMaIi7h8Pvvz",
	"noAction": "wyi5Nq046T9MVS1Es-Mpj",
	"deleteButton": "_27h9g8n4Vj5alofDl-OUTF",
	"textEllipsis": "BsX2fK3xdwGscktF93sj0"
};
module.exports = exports;
