import { IProductListFormStore } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/IProductListFormStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { v4 as uuid } from 'uuid'
import { AddProductFormData, addProductFormDataToExistingProduct, createAddProductFormData, createProduct, createProductListFormData, IAddProductFormStore, Product, ProductBundle, ProductListFormData, productToAddProductFormData, SDStore } from '~/code/POS/pages'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { AppStore } from '~/code/AppStore'
import { fetchBundles } from '~/code/stores/NewOrderStore/services/fetchers'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'
import { AcquirerAssignedTIDsStore } from '~/code/stores/NewOrderStore/common/AcquirerAssignedTIDsStore'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'

export class ProductListFormPartnerStore implements IProductListFormStore, IAddProductFormStore {

    constructor (public parentStore: IFormSubmitHandler) {
        makeObservable(this, {
            isFormVisible: observable,
            currentStep: observable,
            canGoBack: observable,
            _bundles: observable,
            _isLoadingBundles: observable,
            productListFormData: observable,
            addProductFormData: observable,
            addProductFormCurrentStep: observable,
            bundles: computed,
            isTIDDuplicateExist: computed,
            acquirerAssignedTids: computed,
            totalTIDsList: computed,
            loadBundles: action,
            onAddProductFormClose: action,
            addProduct: action,
            deleteProduct: action,
            editProduct: action,
            setAddProductFormData: action,
            setProductListFormData: action,
            setAcquirerAssignedTids: action,
            goBack: action,
            setIsTIDDuplicateExist: action,
            setTotalTIDsList: action,
            setProducts: action
        })

        this.productListFormData = createProductListFormData()
        this.addProductFormData = createAddProductFormData()
    }

    readonly acquirerAssignedTidsStore = new AcquirerAssignedTIDsStore(this)
    static stepName: string = 'productList'

    isFormVisible: boolean

    currentStep: number = 0

    canGoBack: boolean = true

    _bundles: SelectItem[] = []
    _isLoadingBundles: boolean = false

    productListFormData: ProductListFormData

    addProductFormData: AddProductFormData

    addProductFormCurrentStep: number = 0

    get bundles(): SelectItem[] {
        this.loadBundles()
        return this._bundles
    }

    get acquirerAssignedTids(): AcquirerAssignedTid[] {
        return this.acquirerAssignedTidsStore?.acquirerAssignedTids
    }

    get totalTIDsList(): string[] {
        return this.acquirerAssignedTidsStore?.totalTIDsList
    }

    get isTIDDuplicateExist(): boolean {
        return this.acquirerAssignedTidsStore?.isTIDDuplicateExist
    }

    stores: SDStore[] = []

    async loadBundles() {
        if (this._isLoadingBundles || !isEmpty(this._bundles)) { return }
        this._isLoadingBundles = true
        const internalIdentifier = AppStore.userInfo?.userType === 'employee' && AppStore.internalIdentifier ? `?internalIdentifier=${AppStore.internalIdentifierEncoded}` : ''
        this._bundles = await fetchBundles(null, internalIdentifier)
        this._isLoadingBundles = false
    }

    onAddProductFormClose = () => {
        this.isFormVisible = false
    }

    addProduct () {
        if (this.addProductFormData.id) {
            this.addProductFormCurrentStep = 0
            this.addProductFormData = createAddProductFormData()
        }
        this.isFormVisible = true
    }

    deleteProduct (productId: string) {
        this.productListFormData.products =
            this.productListFormData.products.filter(item => item.id !== productId)
    }

    editProduct (productId: string) {
        this.addProductFormCurrentStep = 0
        this.addProductFormData = productToAddProductFormData(this.productListFormData.products.find(product => product.id === productId))
        this.isFormVisible = true
    }

    setAddProductFormData (data: AddProductFormData) {
        let product: Product
        if (data.id) {
            product = this.productListFormData.products.find(item => item.id === data.id)
            if (product) {
                addProductFormDataToExistingProduct(data, product)
            } else {
                product = createProduct()
                addProductFormDataToExistingProduct(data, product)
                this.productListFormData.products.push(product)
            }
        } else {
            // check if there is a product for the same store and department combination
            const existingProduct = this.productListFormData.products?.find(item => (item.storeId === data.storeId && item.departmentId === data.departmentId))
            if (existingProduct) {
                data.bundles.forEach(bundle => {
                    const existingBundle = existingProduct.bundles.find(item => item.bundleCode === bundle.bundleCode)
                    if (existingBundle) {
                        existingBundle.bundleQuantity = Number(existingBundle.bundleQuantity) + Number(bundle.bundleQuantity)
                    } else {
                        existingProduct.bundles.push(bundle)
                    }
                })
            } else {
                data.id = uuid()
                product = createProduct()
                addProductFormDataToExistingProduct(data, product)
                this.productListFormData.products.push(product)
            }
        }

        this.productListFormData = {...this.productListFormData}
        this.addProductFormData = createAddProductFormData()
    }

    setProductListFormData = (data: ProductListFormData) => {
        this.productListFormData = {...this.productListFormData, ...data}
        this.parentStore.handleFormSubmit(this.productListFormData)
    }

    setAcquirerAssignedTids = (acquirerAssignedTids: AcquirerAssignedTid[]) => {
        this.acquirerAssignedTidsStore?.setAcquirerAssignedTids(acquirerAssignedTids)
    }

    setTotalTIDsList = (bundles: ProductBundle[]) => {
        this.acquirerAssignedTidsStore?.setTotalTIDsList(bundles)
    }

    setIsTIDDuplicateExist = (isTIDDuplicateExist) => {
        this.acquirerAssignedTidsStore?.setIsTIDDuplicateExist(isTIDDuplicateExist)
    }

    setProducts = (products: Product[]) => {
        this.productListFormData.products = products
    }

    goBack = () => {
        this.parentStore.handleGoBack(ProductListFormPartnerStore.stepName)
    }
}
