import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    store: string
    department: string
    bundles: string
    edit: string
    delete: string
    areYouSureToDelete: string
    yesDelete: string
    noDontDelete: string
}>()

