import { action, makeObservable, observable, runInAction } from 'mobx'
import { isEmpty } from 'dna-common'
import { notification } from 'antd'
import { IAppStore } from '~/code/IAppStore'
import { fetchBatteryHistory } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { BatteryHistory } from './models/BatteryHistory'
import { TDeviceBatteryHistoryStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/TDeviceBatteryHistoryStore'
import { SMALL_PAGE_SIZE } from '~/code/models'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import commonTranslations from '~/code/translations/translations'

export class DeviceBatteryHistoryStore implements TDeviceBatteryHistoryStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            deviceBatteryHistory: observable,
            isBatteryHistoryModalOpen: observable,
            pageNumber: observable,
            pageSize: observable,
            totalPageItems: observable,
            totalCount: observable,
            setDeviceBatteryHistory: action,
            setIsBatteryHistoryModalOpen: action,
            loadBatteryHistory: action,
            setPageNumber: action,
            setPageSize: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    public isLoading: boolean = false
    public deviceBatteryHistory: BatteryHistory[] = []
    public isBatteryHistoryModalOpen: boolean = false
    public pageNumber: number = 0
    public pageSize: number = SMALL_PAGE_SIZE
    public totalPageItems: number
    public totalCount: number

    setDeviceBatteryHistory = (deviceBatteryHistory: BatteryHistory[]) => {
        this.deviceBatteryHistory = deviceBatteryHistory
    }

    public loadBatteryHistory = (deviceId: number, pageNumber?: number) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber

        if (this.pageNumber === 1) {
            this.setDeviceBatteryHistory([])
        }

        const queryParams = {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
        }

        fetchBatteryHistory(deviceId, queryParams)
            .then(response => {
                if (response?.result?.length) {
                    runInAction(() => {
                        this.setDeviceBatteryHistory(response.result.map((item) => ({...item, key: `${item.dateTime}-${item.batteryLevel}`})))
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    public setIsBatteryHistoryModalOpen = (isBatteryHistoryModalOpen: boolean) => {
        this.isBatteryHistoryModalOpen = isBatteryHistoryModalOpen
    }

    public setPageNumber = (page: number) => {
        this.pageNumber = page
    }

    public setPageSize = (size: number) => {
        this.pageSize = size
    }
}
