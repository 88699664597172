import React, { useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Table } from 'antd'
import translations from './translations'
import { IAuditProps } from './props'
import styles from './Audit.scss'
import { ListActions } from '~/code/common/components'

export const Audit = observer((props: IAuditProps) => {
    const { store, deviceId } = props

    useEffect(() => {
        store.loadAudit(deviceId)
    }, [])

    const columns = [
        {
            title: translations().operation,
            dataIndex: 'Operation',
            key: 'Operation'
        },
        {
            title: translations().userFirstName,
            dataIndex: 'UserFirstName',
            key: 'UserFirstName'
        },
        {
            title: translations().userSurname,
            dataIndex: 'UserSurname',
            key: 'UserSurname'
        },
        {
            title: translations().userEmailAddress,
            dataIndex: 'UserEmailAddress',
            key: 'UserEmailAddress'
        },
        {
            title: translations().createdDateTime,
            dataIndex: 'CreatedDateTime',
            key: 'CreatedDateTime',
            render: date => date ? moment(date).format('DD/MM/YY HH:mm:ss') : translations().noDate
        }
    ]

    return (
        <>
            <ListActions
                pageInfo={{
                    from: store.auditData.length ? store.pageNumber * store.pageSize - 9 : 0,
                    to: store.pageNumber * store.pageSize > store.totalCount ? store.totalCount : store.pageNumber * store.pageSize,
                    total: store.totalCount
                }}
                className={styles.listActions}
                currentTotalAmount={store.auditData?.length}
                onReloadClick={() => store.loadAudit(deviceId)}
                onGoNextPageClick={() => store.loadAudit(deviceId, store.pageNumber + 1)}
                onGoPrevPageClick={() => store.loadAudit(deviceId, store.pageNumber - 1)}
                isNextPageDisabled={store.pageNumber >= store.totalPageItems}
                isPrevPageDisabled={store.pageNumber <= 1}
                onDownloadClick={() => store.downloadAuditFile(deviceId)}
            />
            <Table
                bordered
                loading={store.isLoading}
                dataSource={store.auditData}
                columns={columns}
            />
        </>
    )
})
