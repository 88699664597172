import React from 'react'
import translations from './translations'

translations.add('en', {
    appName: 'Name',
    appNameRequired: 'Name Required',
    appDeveloper: 'Developer',
    newAppDeveloper: 'New Developer Name',
    appDeveloperRequired: 'New Developer Name Required',
    appInstallationPackage: 'Installation Package',
    appInstallationPackageRequired: 'Installation Package Required',
    appInstallationPackagePlaceholder: 'Installation Package i.e. com.eft.positive',
    appVersionNumber: 'Version Number',
    appVersionNumberRequired: 'Version Number required',
    appVersionNumberInvalid: 'Version Number is invalid',
    appVersionCode: 'Version Code',
    appVersionCodeRequired: 'Version Code Required',
    newApplicationDetails: 'New App Details',
    graphics: 'Graphics',
    cancel: 'Cancel',
    uploadApp: 'Upload App',
    invalidEmail: 'Invalid Email',
    developmentEmail: 'Development Email',
    developmentEmailRequired: 'Development Email Required',
    appProduction: 'App Production',
    selectAppCategory: 'Select App Category',
    appSignedBy: 'App Signed By',
    whichDeviceManufacturerSignedAPK: 'Which device manufacturer signed the APK?',
    appCategoryRequired: 'App Category Is Required',
    deviceManufacturer: 'Device Manufacturer',
    deviceManufacturerRequired: 'Device Manufacturer Is Required',
    releaseDetails: 'Release Details',
    linkToReleaseNotes: 'Link to Release Notes',
    appIcon: 'App Icon',
    appIconRequired: 'App Icon Required',
    appModels: 'App Models',
    appModelsRequired: 'App Models required',
    documentationUrl: 'Documentation URL',
    invalidUrl: 'Documentation URL invalid',
    youCanUploadOnlySvgOrPng: 'You can only upload SVG/PNG file!',
    imageMemoryRestriction: 'Image must smaller than 500KB!',
    imageMaxPixelSizeRestriction: 'Image max pixel size is 100x100!',
    pleaseSelectDeveloper: 'Please Select Developer',
    addNewDeveloper: 'Add New Developer?',
    upload: 'Upload',
    applicationCreatedSuccessfully: 'Application Created Successfully'
})
