import translations from '~/code/translations'
import { isEmpty } from 'dna-common'
import { MERCHANT_ID_TYPING_PATTERN } from '~/code/models/Patterns'

export const ACQUIRER_IDS = {
    WORLD_PAY: 1,
    BARCLAYCARD_STERLING: 2,
    GLOBAL_PAY: 3,
    AMEX: 4,
    ELAVON: 5,
    BARCLAYCARD_MC: 6,
    OPTOMANY: 7,
    LLOYDS_CARD_NET: 8,
    FDMS: 9,
    AIB: 10,
    SWEDBANKS: 23,
    BORGUN: 24,
    DNA_PAYMENTS: 37,
    DNA_PAYMENTS_ECOM: 43
}

const acquirerBasedLength = {
    1:  {operator: '=', value: 8, message: translations().invalidWorldPayMIDLength},      // WorldPay
    2:  {operator: '=', value: 7, message: translations().invalidBarclaycardSterlingMIDLength},      // Barclaycard Sterling
    3:  {operator: '=', value: 8, message: translations().invalidGlobalPayMIDLength},      // GlobalPay
    4:  {operator: '=', value: 10, message: translations().invalidAMEXMIDLength},     // AMEX
    5:  {operator: '=', value: 10, message: translations().invalidElavonMIDLength},     // Elavon
    6:  {operator: '=', value: 7, message: translations().invalidBarclaycardMCMIDLength},      // Barclaycard MC
    7:  {operator: '>', value: 5, message: translations().invalidOptomanyMIDLength},      // Optomany more than 5
    8:  {operator: '=', value: 15, message: translations().invalidLloydsCardNetMIDLength},     // Lloyds CardNet
    9:  {operator: '=', value: 15, message: translations().invalidFDMSMIDLength},     // FDMS
    10: {operator: '=', value: 9, message: translations().invalidAIBMIDLength},      // AIB
    23: {operator: '>', value: 5, message: translations().invalidSwedbankMIDLength},      // Swedbank more than 5
    24: {operator: '=', value: 7, message: translations().invalidBorgunMIDLength},       // Borgun
    37: {operator: '=', value: 11, message: translations().invalidDNAPaymentsMIDLength}       // DNA Payments, usually 11 digits
}

const tidAcquirerBasedLength = {
    37: {operator: '=', value: 8, message: translations().invalidDNAPaymentsTIDLength}       // DNA Payments 8 digits
}

const tidAcquirerBasedRegex = {
    43: {value: /^[a-zA-Z0-9_]{0,36}$/, message: translations().invalidDNAPaymentsEComTIDFormat}       // DNA Payments eCom up to 36 characters
}

function isValidLength(length: number, {operator = '', value = 0} = {}) {
    switch (operator) {
        case '=': {
            return length === value
        }
        case '<': {
            return length < value
        }
        case '>': {
            return length > value
        }
        case '>=': {
            return length >= value
        }
        case '<=': {
            return length <= value
        }
        default: {
            return true
        }
    }
}

function isValidByRegex(value: string, regex: RegExp) {
    if (!value || !regex) {
        return undefined
    }
    return regex.test(value)
}

export function validateVisaMID(visaMID: string, acquirerId) {
    if (isEmpty(visaMID) || isEmpty(acquirerId)) {
        return undefined
    }

    if (!isValidLength(visaMID.length, acquirerBasedLength[acquirerId])) {
        return acquirerBasedLength[acquirerId].message
    }
    return undefined
}

export const getValidMerchantIdValue = (value, previousValue) => {
    const test = MERCHANT_ID_TYPING_PATTERN.test(value)
    if (!test) {
        return previousValue
    }
    return value
}

export function validateMerchantId(merchantId: string, acquirerId) {
    if (isEmpty(merchantId) || isEmpty(acquirerId)) {
        return undefined
    }

    if (!isValidLength(merchantId.length, acquirerBasedLength[acquirerId])) {
        return acquirerBasedLength[acquirerId].message
    }
    return undefined
}

export function validateTID(tid: string, acquirerId: number) {
    if (isEmpty(tid) || isEmpty(acquirerId)) {
        return undefined
    }

    if (tidAcquirerBasedLength[acquirerId] && !isValidLength(tid.length, tidAcquirerBasedLength[acquirerId])) {
        return tidAcquirerBasedLength[acquirerId].message
    }

    if (tidAcquirerBasedRegex[acquirerId]?.value && !isValidByRegex(tid, tidAcquirerBasedRegex[acquirerId]?.value)) {
        return tidAcquirerBasedRegex[acquirerId].message
    }

    return undefined
}
