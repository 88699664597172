// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ERxkjAh4hvw2WM7AtEvt .ant-select-selection-item.ant-select-selection-item.ant-select-selection-item {\n  height: 38px; }\n\n._1lakqN9_H-Rh7yQSUhMvoG {\n  display: flex; }\n  ._1lakqN9_H-Rh7yQSUhMvoG .ant-list-item-meta {\n    display: flex;\n    align-items: center;\n    justify-content: center; }\n  ._1lakqN9_H-Rh7yQSUhMvoG .ant-avatar {\n    height: 42px;\n    width: 42px; }\n\n.vGfI1c9NAbl9ry44TPYBP {\n  width: 50px;\n  margin-right: 8px;\n  border-radius: 16px; }\n\n._3YDEOs1y4edvxAWWhGOcFQ {\n  display: flex;\n  justify-content: end; }\n\n._3AvE9_Pvt_3VfIr2yUFdzF {\n  display: flex;\n  align-items: center; }\n  ._3AvE9_Pvt_3VfIr2yUFdzF .ant-list-item-meta-content {\n    min-width: 120px;\n    display: flex; }\n  ._3AvE9_Pvt_3VfIr2yUFdzF .ant-list-item-meta-title {\n    margin-bottom: 0;\n    margin-right: 4px; }\n\n._3PUQ7FYTQA-oYXqj0TAaNZ .ant-upload {\n  height: 128px;\n  width: 128px; }\n\n._3XJ7UOAJz33UbQkikLexeG {\n  margin: 8px; }\n", ""]);
// Exports
exports.locals = {
	"deviceTypeSelect": "_ERxkjAh4hvw2WM7AtEvt",
	"deviceTypes": "_1lakqN9_H-Rh7yQSUhMvoG",
	"deviceTypeImage": "vGfI1c9NAbl9ry44TPYBP",
	"upload": "_3YDEOs1y4edvxAWWhGOcFQ",
	"model": "_3AvE9_Pvt_3VfIr2yUFdzF",
	"iconUpload": "_3PUQ7FYTQA-oYXqj0TAaNZ",
	"addNewDev": "_3XJ7UOAJz33UbQkikLexeG"
};
module.exports = exports;
