import { action, computed, IComputedValue, makeObservable, observable } from 'mobx'
import { Language, Translation } from '~/code/common/components/Translation/Translation'

// import storage from '~/services/storage' // TODO connect with storage

class TranslationStore {

    constructor() {
        makeObservable(this, {
            _lang: observable,
            lang: computed,
            setLang: action
        })

        this._lang = /*storage.get('lang') ||*/ 'en'
    }

    public _lang: Language

    public get lang() {
        return this._lang
    }

    public setLang(value) {
        // storage.set('lang', value) TODO connect with storage
        this._lang = value
    }

    public create<T>(): Translation<T> {

        const map = new Map<string, T>()

        const current: IComputedValue<T> = computed(() => map.get(this._lang) || map.get('en'))

        const translation: Translation<T> = () => current.get()

        translation.add = (lang, value) => {
            map.set(lang, value)
            return translation
        }

        return translation
    }
}

const translationBuilder = new TranslationStore()

export { translationBuilder as TranslationBuilder }
