import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import {
    createFakeTerminalSettingsFormData,
    ITerminalSettingsFormStore,
    TerminalSettingsFormData
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm/models'
import { OnboardingDetailsStepKey } from '~/code/stores/CheckoutV3Onboarding/models'
import translations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm/translations'
import { AppStore } from '~/code/AppStore'

export class TerminalSettingsFormStore implements ITerminalSettingsFormStore {
    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            terminalSettingsFormData: observable,
            acquirers: computed,
            countries: computed,
            defaultTransactionTypes: computed,
            goBack: action,
            setTerminalSettingsFormData: action
        })

        this.terminalSettingsFormData = createFakeTerminalSettingsFormData()
    }

    static stepName: OnboardingDetailsStepKey = 'terminalSettings'

    terminalSettingsFormData: TerminalSettingsFormData

    get acquirers(): SelectItem[] {
        return AppStore.acquirersStore.acquirers
    }

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get defaultTransactionTypes(): SelectItem[] {
        return [
            { label: translations().sale, value: 'SALE'},
            { label: translations().auth, value: 'AUTH'}
        ]
    }

    canGoBack: boolean = true

    goBack = () => {
        this.formSubmitHandler.handleGoBack(TerminalSettingsFormStore.stepName)
    }

    setTerminalSettingsFormData = (data: TerminalSettingsFormData) => {
        this.terminalSettingsFormData = {...this.terminalSettingsFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.terminalSettingsFormData, TerminalSettingsFormStore.stepName)
    }
}
