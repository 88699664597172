import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'

export const getFileUploadPackageType = (packageType: PackageType) => {
    switch (packageType) {
        case PackageType.Application:
            return 'odin.applicationManagement.uploadPackage'
        case PackageType.Firmware:
            return 'odin.firmwareManagement.uploadPackage'
        case PackageType.SystemConfiguration:
            return 'odin.packageManagement.uploadPackage'
    }
}
