import translations from './translations'

translations.add('en', {
    serialNumber: 'Serial Number',
    hardwarePtid: 'Hardware Ptid',
    name: 'Name',
    merchantNumber: 'Merchant Number',
    accountNumber: 'Account Number',
    nameSavedSuccessfully: 'Name Saved Successfully'
})
