import React from 'react'
import { Modal, Table } from 'antd'
import { observer } from 'mobx-react'
import translations from '~/code/ODIN/pages/DeviceManagementDetails/translations/translations'
import { Button, ListActions } from '~/code/common/components'
import { batteryColumns } from '~/code/ODIN/pages/DeviceManagementDetails/models/Constants'
import { BatteryModalProps } from './props'

export const BatteryModal = observer(({store, deviceDetails}: BatteryModalProps) => {
    const refresh = () => {
        store.loadBatteryHistory(deviceDetails.deviceId)
    }

    const loadNextPage = () => {
        store.loadBatteryHistory(deviceDetails.deviceId, store.pageNumber + 1)
    }

    const loadPrevPage = () => {
        store.loadBatteryHistory(deviceDetails.deviceId, store.pageNumber - 1)
    }

    return (
        <Modal
            title={`${deviceDetails.serialNumber} - ${deviceDetails.friendlyName} - ${translations().batteryData}`}
            visible={store.isBatteryHistoryModalOpen}
            onOk={() => store.setIsBatteryHistoryModalOpen(false)}
            onCancel={() => store.setIsBatteryHistoryModalOpen(false)}
            footer={[
                <Button
                    key="back"
                    onClick={() => store.setIsBatteryHistoryModalOpen(false)}
                >
                    {translations().cancel}
                </Button>
            ]}
        >
            <ListActions
                pageInfo={{
                    from: store.pageNumber * store.pageSize - 10,
                    to: store.pageNumber * store.pageSize > store.totalCount ? store.totalCount : store.pageNumber * store.pageSize,
                    total: store.totalCount
                }}
                currentTotalAmount={store.deviceBatteryHistory.length}
                onReloadClick={refresh}
                onGoNextPageClick={loadNextPage}
                onGoPrevPageClick={loadPrevPage}
                isNextPageDisabled={store.pageNumber >= store.totalPageItems}
                isPrevPageDisabled={store.pageNumber <= 1}
            />
            <br/>
            <Table
                pagination={false}
                loading={store.isLoading}
                dataSource={store.deviceBatteryHistory}
                columns={batteryColumns}
                scroll={{ y: 240 }}
            />
        </Modal>
    )
})
