import { OTTSendPlaceOrderRequest, createPlaceOrderRequest } from '~/code/POS/pages/NewOrder/models/123send/123SendPlaceOrderRequest'
import { removeEmptyFields } from '~/code/services'
import { OTTSendMerchantDetailsFormData } from '~/code/POS/pages/NewOrder/components/123send/MerchantDetailsForm/models'
import { DeliveryDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/DeliveryDetailsForm/models'
import { ProductListFormData } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/ProductListFormData'
import { Product } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/Product'
import { PartnerDepartment } from '~/code/POS/pages/NewOrder/models/partner/PartnerDepartment'
import { SDStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDStore'
import { PartnerStore } from '~/code/POS/pages/NewOrder/models/partner/PartnerStore'
import { SDDepartment } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDDepartment'
import { OrderDetailsFormData } from '~/code/POS/pages/NewOrder/components/123send'
import { ORDER_TYPE } from '~/code/POS/pages/NewOrder/components/123send/OrderDetailsForm/models/OrderType'
import { OTTSendBundle } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/models/OTTSendBundle'
import { isEmpty } from 'dna-common'
import { BusinessEntity } from '~/code/stores/NewOrderStore/123send/models/BusinessEntity'

import dnaPaymentsDarkLogo from '~/assets/img/dna_payments_logo_dark.svg'
import OTTDarkLogo from '~/assets/img/123send-logo_dark.png'
import optomanyDarkLogo from '~/assets/img/logo_dark.png'
import activePaymentsLogo from '~/assets/img/child-companies/active-payments-logo.png'
import kwalitasLogo from '~/assets/img/child-companies/kwalitas-logo.png'
import cardCuttersLogo from '~/assets/img/child-companies/card-cutters-logo.png'
import firstPaymentsLogo from '~/assets/img/child-companies/first-payments-logo.png'
import { CompanyEntity } from '~/code/POS/pages/NewOrder/models/123send/BusinessEntity'

export function createPlaceOrderRequestFromData(
    merchantDetails: OTTSendMerchantDetailsFormData,
    partnerStores: SDStore[],
    productListFormData: ProductListFormData,
    orderDetailsFormData: OrderDetailsFormData,
    deliveryDetailsFormData: DeliveryDetailsFormData,
    orderReference: string,
    merchantId: string,
    merchantAccountNumber: string,
    steps: any[],
    businessEntityId: number,
    internalIdentifier?: string
) { // TODO specify the type
    const request = createPlaceOrderRequest()

    steps.forEach(step => {
        switch (step.key) {
            case 'merchantDetails': {
                merchantDetailsToRequest(request, merchantDetails)
                break
            }
            case 'productList': {
                productsListFormDataToRequest(request, partnerStores, productListFormData)
                break
            }
            case 'orderDetails': {
                orderDetailsFormDataToRequest(request, orderDetailsFormData)
                break
            }
            case 'deliveryDetails': {
                deliveryDetailsFormDataToRequest(request, deliveryDetailsFormData)
                break
            }
        }
    })

    request.businessEntityId = businessEntityId

    if (!isEmpty(merchantId)) {
        request.merchant.existingMerchantId = Number(merchantId)
        delete request.merchant.newMerchant
    } else {
        request.orderTypeId = ORDER_TYPE.new
    }

    if (request?.merchant?.existingMerchantId) {
        request.directMerchantCustomerId = merchantAccountNumber
    }

    request.internalIdentifier = internalIdentifier?.trim()
    request.orderReference = orderReference?.trim()

    removeEmptyFields(request)
    removeStoreIds(request)
    return request
}

export function merchantDetailsToRequest (request: OTTSendPlaceOrderRequest, merchantDetails: OTTSendMerchantDetailsFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }

    if (!merchantDetails) {
        return request
    }

    delete request.merchant.existingMerchantId
    merchantDetails.companyName && (request.merchant.newMerchant.name = merchantDetails.companyName.trim())
    merchantDetails.companyNumber && (request.merchant.newMerchant.companyRegistrationNumber = merchantDetails.companyNumber.trim())
    request.merchant.newMerchant.tradingAs = merchantDetails.tradingAs?.trim()
    request.merchant.newMerchant.accountManagerId = merchantDetails.accountManager
    request.merchant.newMerchant.address.line1 = merchantDetails.addressLine1?.trim()
    request.merchant.newMerchant.address.line2 = merchantDetails.addressLine2?.trim()
    request.merchant.newMerchant.address.townOrCity = merchantDetails.town?.trim()
    request.merchant.newMerchant.address.county = merchantDetails.county?.trim()
    request.merchant.newMerchant.address.countryCode = Number(merchantDetails.country)
    request.merchant.newMerchant.address.postcode = merchantDetails.postcode?.trim()

    request.merchant.newMerchant.contact.firstName = merchantDetails.contactName?.trim()
    request.merchant.newMerchant.contact.surname = merchantDetails.contactSurname?.trim()
    request.merchant.newMerchant.contact.telephoneNumber = merchantDetails.contactPhoneNumber?.trim()
    request.merchant.newMerchant.contact.mobileNumber = merchantDetails.contactMobilePhone?.trim()
    request.merchant.newMerchant.contact.email = merchantDetails.contactEmail?.trim()
    request.merchant.newMerchant.contact.jobTitle = merchantDetails.contactJobTitle?.trim()


    if (merchantDetails.financeContactUseMain) {
        request.merchant.newMerchant.financeContact = {
            useMainContact: merchantDetails.financeContactUseMain
        }
    } else {
        request.merchant.newMerchant.financeContact = {
            useMainContact: merchantDetails.financeContactUseMain,
            firstName: merchantDetails.financeContactName?.trim(),
            surname: merchantDetails.financeContactSurname?.trim(),
            telephoneNumber: merchantDetails.financeContactPhoneNumber?.trim(),
            email: merchantDetails.financeContactEmail?.trim(),
            jobTitle: merchantDetails.financeContactJobTitle?.trim()
        }
    }


    request.merchant.newMerchant.communicationDetails = {
        receiveSalesAndMarketing: merchantDetails.receiveSalesAndMarketing,
        paperBasedInvoices: false
    }

    // DEFAULT STATIC VALUES
    request.merchant.newMerchant.roles = [{
        name: 'Supervisor',
        purchase: true,
        refund: true,
        cashback: false,
        users: [{
            userId: '1234',
            userPin: '1234'
        }]
    }]

    return request
}

export function orderDetailsFormDataToRequest (request: OTTSendPlaceOrderRequest, orderDetailsFormData: OrderDetailsFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!orderDetailsFormData) {
        return request
    }

    request.poNumber = orderDetailsFormData.poNumber
    request.dealId = orderDetailsFormData.dealId
    request.containsAcquirerRevenue = orderDetailsFormData.containsAcquirerRevenue
    request.orderTypeId = orderDetailsFormData.orderType
    request.requestedDeliveryAndOrActivationDate = orderDetailsFormData.requestedDeliveryAndOrActivationDate

    if (orderDetailsFormData.partnerCompany) {
        request.partnerDetails = {
            partnerCompanyDetailsId: orderDetailsFormData.partnerCompany,
            agentName: orderDetailsFormData.partnerCompanyAgentName
        }
    }

    if (!isEmpty(orderDetailsFormData.upgradeDetails)) {
        request.upgradeDetails = orderDetailsFormData.upgradeDetails.map(item => ({serialNumber: item.serialNumber, tid: item.tid}))
    }

    return request
}

export function deliveryDetailsFormDataToRequest (request: OTTSendPlaceOrderRequest, deliveryDetailsFormData: DeliveryDetailsFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!deliveryDetailsFormData) {
        return request
    }

    request.merchant.deliveryDetails = {}
    request.merchant.deliveryDetails.deliverToMerchant = deliveryDetailsFormData.deliverTo === 'deliverToMerchant'
    request.merchant.deliveryDetails.deliverToStore = deliveryDetailsFormData.deliverTo === 'deliverToStore'
    if (request.merchant.deliveryDetails.deliverToStore) {
        deliveryDetailsFormData.storeDeliveryInstructions.map(item => {
            const store = request.merchant.stores.find(storeItem => storeItem.id === item.storeId)
            store.deliveryInstructions = item.isCustom ? item.deliveryInstructions : deliveryDetailsFormData.deliveryInstructions
        })
    } else {
        request.merchant.deliveryDetails.deliveryInstructions = deliveryDetailsFormData.deliveryInstructions
    }

    if (deliveryDetailsFormData.deliverTo === 'deliverToNewAddress') {
        request.merchant.deliveryDetails.newHardwareAddress = {
            address: {
                deliveryName: deliveryDetailsFormData.deliveryName,
                tradingAs: deliveryDetailsFormData.tradingAs,
                countryCode: deliveryDetailsFormData.country,
                postcode: deliveryDetailsFormData.postcode,
                line1: deliveryDetailsFormData.addressLine1,
                line2: deliveryDetailsFormData.addressLine2,
                townOrCity: deliveryDetailsFormData.town,
                county: deliveryDetailsFormData.county
            },
            contact: {
                firstName: deliveryDetailsFormData.contactName,
                surname: deliveryDetailsFormData.contactSurname,
                jobTitle: deliveryDetailsFormData.jobTitle,
                email: deliveryDetailsFormData.email,
                telephoneNumber: deliveryDetailsFormData.contactPhoneNumber,
                mobileNumber: deliveryDetailsFormData.contactMobilePhone
            }
        }
    }

    return request
}

export function productsListFormDataToRequest(request: OTTSendPlaceOrderRequest, partnerStores: SDStore[], productListFormData: ProductListFormData) {

    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!productListFormData) {
        return request
    }

    productListFormData.products.forEach( (product: Product) => {
        let requestStore: PartnerStore  = request.merchant.stores.find(item => item.id === product.storeId)
        const partnerStore = partnerStores.find(item => item.id === product.storeId)
        const partnerDepartment = partnerStore.departments.find(item => item.id === product.departmentId)

        const standardBundles = (product.bundles as OTTSendBundle[])
            .filter(item => item.bundleType === 'standard')
            ?.map(bundle => {
                return {
                    bundleId: bundle.bundleCode,
                    quantity: bundle.bundleQuantity,
                    priceOverride: bundle.bundleCustomPrice
                }
            })
        if (requestStore) {
            // find department
            let department = requestStore.departments.find(item => item.id === product.departmentId)
            if (department) {
                department.productDetails.push(...standardBundles)
            } else {
                department = createDepartment(partnerDepartment, standardBundles, product.bundles as OTTSendBundle[])
                requestStore.departments.push(department)
            }
        } else {
            if (partnerStore.isNew) {
                requestStore = {
                    id: partnerStore.id,
                    newStore: {
                        name: partnerStore.name,
                        address: {
                            line1: partnerStore.addressLine1,
                            line2: partnerStore.addressLine2,
                            line3: partnerStore.addressLine3,
                            townOrCity: partnerStore.town,
                            county: partnerStore.county,
                            postcode: partnerStore.postcode,
                            countryCode: partnerStore.country
                        },
                        telephoneNumber: partnerStore.telephoneNumber,
                        email: partnerStore.email,
                        useAxeptConnectCloud: partnerStore.useAxeptConnectCloud
                    },
                    departments: [
                        createDepartment(partnerDepartment, standardBundles, product.bundles as OTTSendBundle[])
                    ]
                }
            } else {
                requestStore = {
                    id: partnerStore.id,
                    existingStoreId: Number(partnerStore.id),
                    departments: [
                        createDepartment(partnerDepartment, standardBundles, product.bundles as OTTSendBundle[])
                    ]
                }
            }

            request.merchant.stores.push(requestStore)
        }

        const hardwareBundles = (product.bundles as OTTSendBundle[]).filter(item => item.bundleType === 'hardware')
        if (!isEmpty(hardwareBundles)) {
            request.merchant.hardwareOnlyProductDetails = hardwareBundles.map(bundle => { return { bundleId: bundle.bundleCode, quantity: bundle.bundleQuantity, priceOverride: bundle.bundleCustomPrice } })
        }
    })

    return request
}

function createDepartment(partnerDepartment: SDDepartment, bundles: {bundleId, quantity, priceOverride?}[], allBundles: OTTSendBundle[]): PartnerDepartment {
    const productDetails = [...bundles]
    const acquirerAssignedTids = allBundles
        .map(bundle => bundle.acquirerAssignedTids?.map(tid => tid.tid))
        .reduce((acc, val) => acc.concat(val), [])
        .filter(acquirerAssignedTid => !isEmpty(acquirerAssignedTid))

    const isVerifoneProductExist = allBundles
        .map(bundle => bundle.containsVerifoneProduct)
        .filter(containsVerifoneProduct => containsVerifoneProduct)
        .length > 0

    let result: PartnerDepartment

    if (partnerDepartment.isNew) {
        const transactionTypes = {
            cashbackAccepted: partnerDepartment.cashBackAccepted,
            cashbackMaxValue: partnerDepartment.cashBack,
            gratuityAccepted: partnerDepartment.gratuityAccepted,
            gratuityMaxValue: partnerDepartment.gratuity,
            purchaseAccepted: partnerDepartment.purchaseAccepted,
            purchaseMaxValue: partnerDepartment.purchase,
            refundAccepted: partnerDepartment.refundAccepted,
            refundMaxValue: partnerDepartment.refund,
            preAuthorisation: partnerDepartment.preAuthorisation
        }
        !partnerDepartment.cashBackAccepted && delete transactionTypes.cashbackMaxValue
        !partnerDepartment.gratuityAccepted && delete transactionTypes.gratuityMaxValue
        !partnerDepartment.purchaseAccepted && delete transactionTypes.purchaseMaxValue
        !partnerDepartment.refundAccepted && delete transactionTypes.refundMaxValue

        result = {
            newDepartment: {
                name: partnerDepartment.name,
                currencyId: partnerDepartment.currencyId,
                merchantCategoryCode: partnerDepartment.merchantCategoryCode,
                streamlineCompanyId: partnerDepartment.streamlineCompanyId,
                transactionTypes,
                cardSchemeDetails: {
                    visaMasterCard: {
                        visaMid: partnerDepartment.visaMID,
                            visaDebitMid: partnerDepartment.visaDebitMID,
                            mastercardMid: partnerDepartment.mastercardMID,
                            mastercardDebitMid: partnerDepartment.mastercardDebitMID,
                            maestroDebitMid: partnerDepartment.maestroDebitMID,
                            acquirerId: partnerDepartment?.acquirer,
                            accountNumber: partnerDepartment.bankAccountNumber,
                            sortCode: partnerDepartment.sortCode
                    },
                    americanExpress: {
                        mid: partnerDepartment.amexMerchantID,
                            acquirerId: partnerDepartment.amexAcquirer,
                            accountNumber: partnerDepartment.amexBankAccountNumber,
                            sortCode: partnerDepartment.amexSortCode
                    },
                    dinersDiscover: {
                        mid: partnerDepartment.dinersDiscoverMerchantID,
                            acquirerId: partnerDepartment.dinersDiscoverAcquirer,
                            accountNumber: partnerDepartment.dinersDiscoverBankAccountNumber,
                            sortCode: partnerDepartment.dinersDiscoverSortCode
                    },
                    flexecash: {
                        mid: partnerDepartment.flexecashMerchantID,
                            acquirerId: partnerDepartment.flexecashAcquirer,
                            accountNumber: partnerDepartment.flexecashBankAccountNumber,
                            sortCode: partnerDepartment.flexecashSortCode
                    }
                    // ,
                    // unionPay: {
                    //     mid: partnerDepartment.unionPayMerchantID,
                    //     acquirerId: partnerDepartment.unionPayAcquirer,
                    //     accountNumber: partnerDepartment.unionPayBankAccountNumber,
                    //     sortCode: partnerDepartment.unionPaySortCode
                    // }
                },
                verifoneTransactionTypes: {
                    accountVerification: partnerDepartment.accountVerification || false,
                    cashAdvance: partnerDepartment.cashAdvance || false,
                    preAuth: partnerDepartment.preAuth || false,
                    moto: partnerDepartment.moto || false
                }
            },
            payerAuthenticationDetails: {
                visaMid: partnerDepartment.visaMID,
                    mastercardMid: partnerDepartment.mastercardMID
            },
            acquirerAssignedTids: acquirerAssignedTids.length === 0 ? null : acquirerAssignedTids,
            productDetails
        }

        if (result.newDepartment && !result.newDepartment.streamlineCompanyId) {
            delete result.newDepartment.streamlineCompanyId
        }

        if (partnerDepartment.departmentType === 'general') {
            delete result.payerAuthenticationDetails
        }

        if (!isVerifoneProductExist) {
            delete result.newDepartment.verifoneTransactionTypes
        }
    } else {
        result = {
            existingDepartmentId: Number(partnerDepartment.id),
            productDetails,
            ...(partnerDepartment.acquiresWithDna || partnerDepartment.acquiresWithDnaEcommerce ? {acquirerAssignedTids} : {})
        }
    }

    return result
}

function removeStoreIds(request: OTTSendPlaceOrderRequest) {
    const stores = request?.merchant?.stores
    if (!isEmpty(stores)) {
        stores.forEach(store => {
            delete store.id
        })
    }
}

function getBusinessEntityLogo(entity: BusinessEntity) {
    switch (entity.name) {
        case 'DNA Payments':
            return dnaPaymentsDarkLogo
        case 'Active Payments':
            return activePaymentsLogo
        case 'FPMS':
            return firstPaymentsLogo
        case '123Send':
            return OTTDarkLogo
        case 'Kwalitas':
            return kwalitasLogo
        case 'Card Cutters':
            return cardCuttersLogo
        case 'Optomany':
            return optomanyDarkLogo
    }
}

export const mapBusinessEntities = (entities: BusinessEntity[]): CompanyEntity[] => {
    return entities.map(entity => ({
        logo: getBusinessEntityLogo(entity),
        ...entity
    }))
}
