import translations from './translations'

translations.add('en', {
    newBundle: 'Add New Bundle',
    newStoreAndDepartment: 'Add New Store/Location',
    newDepartment: 'Add New Department',
    orderType: 'Order type',
    orderTypeRequired: 'Order type is required',
    pointInformation: 'Store & Department Selection',
    storeAndDepartmentInfo: 'Store & Department Information',
    store: 'Store',
    storeId: 'Store Id',
    storeAddressLine1: 'Address Line 1',
    storePostcode: 'Post Code',
    departmentId: 'Department Id',
    visaMid: 'Visa MID',
    mastercardMid: 'Mastercard MID',
    amexMid: 'Amex MID',
    dinersMid: 'Diners MID',
    unionPayMid: 'Unionpay MID',
    aliPayMid: 'AliPay MID',
    flexecashMid: 'Flexecash MID',
    departmentType: 'Department Type',
    storeRequired: 'Store is required',
    department: 'Department',
    departmentRequired: 'Department is required',
    nextStep: 'Next'
})
