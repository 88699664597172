import { getWithAuth, postWithAuth } from '~/code/services/authorised-requests'
import { checkODINHTTPStatus } from '~/code/services/http-response-handler'
import { MerchantDevice } from '~/code/ODIN/stores/DeviceManagementStore/models/MerchantDevice'
import { DeviceType } from '~/code/ODIN/stores/DeviceManagementStore/models/DeviceType'
import { AddDeviceTypeData } from '~/code/ODIN/stores/DeviceManagementStore/models/AddDeviceTypeData'

export const fetchDevices = (merchantId: number, filter) =>
    checkODINHTTPStatus(getWithAuth<MerchantDevice[]>(`/odin-api/api/Merchants/${merchantId}/MerchantDevices`, filter))

export const fetchDeviceTypes = (queryParams) =>
    checkODINHTTPStatus(getWithAuth<DeviceType[]>(`/odin-api/api/DeviceTypes`, queryParams))

export const addDeviceType = (merchantId: number, addDeviceTypeData: AddDeviceTypeData) =>
    checkODINHTTPStatus(postWithAuth<null>(`/odin-api/api/Merchants/${merchantId}/MerchantDevices/Device`, addDeviceTypeData))
