import React from 'react'
import translations from './translations'

translations.add('en', {
    noStoreAdded: 'There are no stores yet, click the button below to add a new one',
    addStore: 'Add New Store',
    nextStep: 'Next',
    back: 'Back',
    storeName: 'Store Name',
    search: 'Search',
    reset: 'Reset',
    cancel: 'Cancel'
})
