import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Avatar, Card, Descriptions, Form, PageHeader, Tabs, Typography } from 'antd'
import { Page } from '~/code/common/pages/Page'
import { Button, Checkbox, Input, Modal } from '~/code/common/components'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { AddNewFirmware } from '~/code/ODIN/pages/FirmwareManagement/components/AddNewFirmware/AddNewFirmware'
import { VersionHistoryList } from '~/code/ODIN/pages/ApplicationManagementDetails/components/VersionHistoryList/VersionHistoryList'
import { FIRMWARE_MAX_FILE_SIZE, LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import page from 'page'
import translations from './translations'
import { IFirmwareManagementDetailsProps } from './props'
import styles from './FirmwareManagementDetails.scss'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'

const {Title, Text} = Typography
const {TabPane} = Tabs

export const FirmwareManagementDetails = observer(({store, id}: IFirmwareManagementDetailsProps) => {
    const {
        loadApplicationDetailsById, isLoading, applicationDetails, addNewVersionStore, versionHistoryStore, permissions,
        fillDeviceManufacturerOnNewVersionAdd
    } = store
    const [isAddNewVersion, setIsAddNewVersion] = useState<boolean>(false)
    const [changeAvailable, setChangeAvailable] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (id) {
            loadApplicationDetailsById(id)
            versionHistoryStore.loadVersionHistoryById(id)
        }
    }, [])

    useEffect(() => {
        form.validateFields(['VersionNumberRegex'])
    }, [changeAvailable])

    const onFinish = (values) => {
        store.patchRegex(id, values.VersionNumberRegex)
    }

    const onCheckboxChange = (e: { target: { checked: boolean } }) => {
        setChangeAvailable(e.target.checked)
    }

    const onAddNewVersionButtonClick = () => {
        setIsAddNewVersion(true)
        fillDeviceManufacturerOnNewVersionAdd(applicationDetails.deviceManufacturerName)
    }

    return (
        <Page>
            {!isAddNewVersion && (
                <PageHeader
                    ghost={false}
                    onBack={() => page(LOCATION_ODIN_PATH_NAMES.FIRMWARE_MANAGEMENT)}
                    className={styles.pageHeader}
                    title={
                        <Title className={styles.title} level={3}>
                            {`${translations().firmwareManagement} - ${id}`}
                        </Title>
                    }
                    extra={<div className={styles.extra}>
                        {permissions?.includes('odin.firmwareManagement.createVersion') && (
                            <Button
                                key={'firmwareManagement.createVersion'}
                                onClick={onAddNewVersionButtonClick}
                                icon={<PlusOutlined />}
                            >
                                {translations().uploadNewVersion}
                            </Button>
                        )}
                        {permissions?.includes('odin.firmwareManagement.partialUpdate') && (
                            <Button
                                key={'changeVersionNumberRegex'}
                                onClick={() => store.setIsCreateNewRegexVersionModalOpen(true)}
                                icon={<EditOutlined/>}
                            >
                                {translations().changeVersionNumberRegex}
                            </Button>
                        )}
                    </div>}
                >
                    {permissions?.includes('odin.firmwareManagement.lastVersionDetail') && (
                        <Card loading={isLoading}>
                            <Card.Meta
                                description={
                                    <>
                                        <Descriptions
                                            title={applicationDetails?.displayName}
                                            size="small"
                                            column={2}
                                        >
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().displayName}</Text>}>
                                                <Text strong>{applicationDetails?.displayName}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().installationPackage}</Text>}>
                                                <Text strong>{applicationDetails?.packageName}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().developerName}</Text>}>
                                                <Text strong>{applicationDetails?.developerName}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().supportedDevices}</Text>}>
                                                <Text strong>{applicationDetails?.deviceTypes?.map(device => `${device.name} `)}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().developerEmail}</Text>}>
                                                <Text strong>{applicationDetails?.developerEmail}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().packageDocumentationUri}</Text>}>
                                                <Text strong>{applicationDetails?.packageDocumentationUri}</Text>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                }
                                avatar={<Avatar className={styles.avatar} src="link" />}
                            />
                        </Card>
                    )}
                    <br/>
                    {permissions?.includes('odin.firmwareManagement.versionHistory') && (
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={translations().versionHistory} key="1">
                                <VersionHistoryList
                                    applicationId={id}
                                    packageType={PackageType.Firmware}
                                    maxFileSize={FIRMWARE_MAX_FILE_SIZE}
                                    store={versionHistoryStore}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                </PageHeader>
            )}

            {isAddNewVersion && <AddNewFirmware store={addNewVersionStore} setIsAddNewApp={setIsAddNewVersion} applicationDetails={applicationDetails}/>}

            <Modal
                key={'changeVersionNumberRegex'}
                visible={store.isCreateNewRegexVersionModalOpen}
                title={translations().versionNumberRegex}
                onOk={() => store.setIsCreateNewRegexVersionModalOpen(false)}
                onCancel={() => store.setIsCreateNewRegexVersionModalOpen(false)}
                footer={[
                    <Button key={'cancel'} outline={'outlined'} onClick={() => store.setIsCreateNewRegexVersionModalOpen(false)} className={styles.cancel}>
                        {translations().cancel}
                    </Button>,
                    <Button key={'ok'} type={'primary'} loading={store.isPatchRegexLoading} form="changeVersionNumberRegex" htmlType="submit">
                        {translations().saveRegex}
                    </Button>
                ]}
                type={'info'}
                smallerWidth
            >
                <Form
                    id="changeVersionNumberRegex"
                    name="changeVersionNumberRegex"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label={translations().versionNumberRegex}
                        name="VersionNumberRegex"
                        rules={[{ required: true, message: translations().versionNumberRegexRequired }]}
                    >
                        <Input placeholder={`\\A(?<major>\\d+)\\.(?<minor>\\d+)\\.(?<patch>\\d+)\\z`} disabled={!changeAvailable} />
                    </Form.Item>

                    <Form.Item>
                        <Checkbox checked={changeAvailable} onChange={onCheckboxChange}>
                            {translations().allowToChangeRegex}
                        </Checkbox>
                    </Form.Item>

                    <p>{translations().theInputRegexProvidesTheFollowingFormat}</p>
                    <p>{`${translations().major}.${translations().minor}.${translations().patch}`}</p>
                    <p>{translations().theFollowingCharactersSupported}</p>
                    <p>{translations().major} = a-z A-Z 0-9_</p>
                    <p>{translations().minor} = 0-9</p>
                    <p>{translations().patch} = a-z A-Z 0-9_</p>
                    <p>{translations().ifYouWishToContinuePleaseSaveRegex}</p>
                </Form>
            </Modal>
        </Page>
    )
})
