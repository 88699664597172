import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    loading: string
    failure: string
    failedToLoad: string
    loadingInProgress: string
    retry: string
}>()

