// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1CPzYpQyH-rUFKSK9Uuc46 {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 32px;\n  line-height: 48px;\n  color: #000000;\n  padding-bottom: 24px; }\n", ""]);
// Exports
exports.locals = {
	"PageHeader": "_1CPzYpQyH-rUFKSK9Uuc46"
};
module.exports = exports;
