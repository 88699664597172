import React from 'react'
import page from 'page'
import { Result } from 'antd'
import { observer } from 'mobx-react'
import { get } from 'store-connector'
import { Button } from '~/code/common/components'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import translations from './translations'

const NotFound = observer(() => {
    return <Result
        status="404"
        title="404"
        subTitle={translations().noPage}
        extra={
            <Button
                type="primary"
                onClick={() => {
                    if (!get('portalType')) {
                        page(LOCATION_POS_PATH_NAMES.LOGIN)
                    } else if (get('portalType') === 'POS') {
                        page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
                    } else {
                        page(LOCATION_ODIN_PATH_NAMES.MERCHANT_SELECTION)
                    }
                }}>
                {translations().back}
            </Button>
        }
    />
})

export { NotFound }
