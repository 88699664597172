import React from 'react'
import translations from './translations'

translations.add('en', {
    orderReference: 'Order Reference',
    noReference: 'No reference',
    orderingOnBehalfOf: 'Ordering on behalf of',
    orderSummary: 'Order Summary',
    placeOrder: 'Place Order',
    information: 'Information',
    deliveryAddress: 'Delivery Address',
    processingDetails: 'Processing Details',
    products: 'Products',
    merchantDetails: 'Merchant Details',
    orderDetails: 'Order Details',
    deliveryDetails: 'Delivery Details',
    sameAsMerchantAddress: 'Same as merchant address',
    loadingTermsAndConditions: 'Loading terms & conditions...',
    termsAndConditionsFileUrl: '/docs/terms_en.pdf',
    agreeWithTerms: (company: string) => `I confirm that this order is placed in accordance with the T&Cs defined within ${company}'s contract with Optomany Ltd`,
    confirmationRequired: 'Please agree to the terms and conditions before placing the order',
    back: 'Back',
    cancelOrder: 'Cancel Order',
    cancelOrderConfirmText: (orderReference: string) => <p>
        Are you sure you want to cancel the current order #{orderReference}?
        <br/>
        All entered data will be lost
    </p>,
    confirmCancelOrder: 'Yes, cancel order',
    cancelCancelOrder: 'No, keep order'
})
