import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { AddStoreFormData, addStoreFormDataToExistingSDStore, createAddStoreFormData, createSDStore, IAddStoreFormStore, SDStore } from '~/code/POS/pages'
import { SelectItem } from '~/code/common/components'
import { AppStore } from '~/code/AppStore'
import { StoresAndDepartmentsFormStore } from '~/code/stores/NewOrderStore/common/StoresAndDepartmentsFormStore'
import { IMerchantDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/IMerchantDetailsFormStore'
import { message } from 'antd'
import { error } from 'dna-common'
import { NO_CONTENT } from '~/code/models'
import { fetchStoresByValue } from '../services/fetchers'
import { NewOrderStore } from '../NewOrderStore'

export class AddStoreFormStore implements IAddStoreFormStore {

    constructor (
        public storesAndDepartmentsFormStore: StoresAndDepartmentsFormStore,
        public merchantDetailsFormStore: IMerchantDetailsFormStore
    ) {
        makeObservable(this, {
            addStoreFormData: observable,
            isStoreNameLoading: observable,
            isStoreNameValid: observable,
            countries: computed,
            isFormVisible: computed,
            isNewMerchant: computed,
            isMerchantsAtStoreLevel: computed,
            setAddStoreFormData: action,
            useMerchantDetails: action,
            getStores: action.bound
        })

        this.addStoreFormData = createAddStoreFormData()
    }

    newOrderStore: NewOrderStore

    // helper stores
    countriesStore = AppStore.countriesStore

    addStoreFormData: AddStoreFormData

    isStoreNameLoading: boolean = false

    isStoreNameValid: boolean = true

    get countries (): SelectItem[] {
        return this.countriesStore.countries
    }

    get isFormVisible(): boolean {
        return this.storesAndDepartmentsFormStore.isAddStoreFormVisible
    }

    get isNewMerchant(): boolean {
        return AppStore.newOrderStore?.placeOrderConfigStore.merchantType === 'new'
    }

    get isMerchantsAtStoreLevel(): boolean {
        return AppStore.userInfo.merchantsAtStoreLevel
    }

    setAddStoreFormData = (data: AddStoreFormData) => {
        let store: SDStore
        if (data.storeId) {
            store = this.storesAndDepartmentsFormStore.stores.find( item => item.id === data.storeId)
            if (store) {
                addStoreFormDataToExistingSDStore(data, store)
            } else {
                store = createSDStore()
                addStoreFormDataToExistingSDStore(data, store)
                this.storesAndDepartmentsFormStore.stores.push(store)
            }
        } else {
            data.storeId = uuid()
            store = createSDStore()
            addStoreFormDataToExistingSDStore(data, store)
            this.storesAndDepartmentsFormStore.stores.push(store)
        }
        this.addStoreFormData = createAddStoreFormData()
    }

    useMerchantDetails = () => {
        const merchantDetails = this.merchantDetailsFormStore.merchantDetailsFormData
        this.addStoreFormData = {
            ...this.addStoreFormData,
            storeName: merchantDetails.companyName,
            storeCompanyRegistrationNumber: merchantDetails.companyNumber,
            storeVATNumber: merchantDetails.vatNumber,
            storeTelephoneNumber: merchantDetails.contactPhoneNumber,
            storeEmail: merchantDetails.contactEmail,
            storeCountry: merchantDetails.country,
            storePostcode: merchantDetails.postcode,
            storeAddressLine1: merchantDetails.addressLine1,
            storeAddressLine2: merchantDetails.addressLine2,
            storeTown: merchantDetails.town,
            storeCounty: merchantDetails.county
        }
    }

    getStores(value: string): Promise<void> {
        this.isStoreNameLoading = true
        let internalIdentifier = AppStore.internalIdentifierEncoded ? AppStore.internalIdentifierEncoded : ''
        internalIdentifier = this.newOrderStore?.internalIdentifierEncoded ? this.newOrderStore?.internalIdentifierEncoded : internalIdentifier
        internalIdentifier = `&internalIdentifier=${internalIdentifier}`

        const merchantId = AppStore.newOrderStore?.selectedCompany?.merchantId
        return fetchStoresByValue(value, merchantId, internalIdentifier)
            .then(response => {
                if (!response) {
                    return
                }

                runInAction(() => {
                    if (response.length > 0) {
                        const stores = response.map(s => s.storeName.toLowerCase())
                        if (stores.includes(value.toLowerCase())) {
                            this.isStoreNameValid = false
                        } else {
                            this.isStoreNameValid = true
                        }
                    } else {
                        this.isStoreNameValid = true
                    }
                })
            })
            .catch((err: Error) => {
                if (err.name === NO_CONTENT) {
                    runInAction(() => {
                        this.isStoreNameValid = true
                    })
                    return
                }
                error(`FAILED: ${err.message}`)
                message.error(err.message)
            })
            .finally(() => {
                runInAction(() => {
                    this.isStoreNameLoading = false
                })
            })
    }
}
