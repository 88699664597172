import {
    createMerchantDetailsFormData,
    IMerchantDetailsFormStore,
    MerchantDetailsFormData
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/MerchantDetailsForm/models'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { action, computed, makeObservable, observable } from 'mobx'
import { OnboardingDetailsStepKey } from '~/code/stores/CheckoutV3Onboarding/models'
import { SelectItem } from '~/code/common/components'
import { AppStore } from '~/code/AppStore'
import translations from './translations'
import { isEmpty } from 'dna-common'
import { fetchAcquisitionChannels } from '~/code/stores/CheckoutV3Onboarding/services/fetchers'

export class MerchantDetailsFormStore implements IMerchantDetailsFormStore {
    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            _acquisitionChannels: observable,
            _isAcquisitionChannelsLoading: observable,
            merchantDetailsFormData: observable,
            countries: computed,
            currencies: computed,
            acquisitionChannels: computed,
            merchantCategoryCodes: computed,
            isAcquisitionChannelsLoading: computed,
            loadAcquisitionChannels: action,
            setMerchantDetailsFormData: action,
            goBack: action
        })
        this.merchantDetailsFormData = createMerchantDetailsFormData()
    }

    static stepName: OnboardingDetailsStepKey = 'merchantDetails'

    _acquisitionChannels: SelectItem[] = []

    _isAcquisitionChannelsLoading: boolean = false

    merchantDetailsFormData: MerchantDetailsFormData

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get currencies(): SelectItem[] {
        return [
            {label: translations().gbp, value: 'gbp'},
            {label: translations().usd, value: 'usd'}
        ]
    }

    get acquisitionChannels() {
        this.loadAcquisitionChannels()
        return this._acquisitionChannels
    }

    get merchantCategoryCodes(): SelectItem[] {
        return AppStore.merchantCategoryCodesStore.merchantCategoryCodes
    }

    get isAcquisitionChannelsLoading(): boolean {
        return this._isAcquisitionChannelsLoading
    }

    onCompanyNameChange (value: string) {
        AppStore.checkoutV3OnboardingStore?.setManuallyEnteredCompanyName(value)
    }

    async loadAcquisitionChannels() {
        if (this._isAcquisitionChannelsLoading || !isEmpty(this._acquisitionChannels)) { return }
        this._isAcquisitionChannelsLoading = true
        this._acquisitionChannels = await fetchAcquisitionChannels()
        this._isAcquisitionChannelsLoading = false
    }

    setMerchantDetailsFormData = (data: MerchantDetailsFormData) => {
        this.merchantDetailsFormData = {...this.merchantDetailsFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.merchantDetailsFormData, MerchantDetailsFormStore.stepName)
    }

    canGoBack = true

    goBack = () => {
        this.formSubmitHandler.handleGoBack(MerchantDetailsFormStore.stepName)
    }
}
