import React from "react";
const SvgLastPage = props => (
  <svg
    id="last-page_svg__\u0421\u043B\u043E\u0439_1"
    x={0}
    y={0}
    viewBox="0 0 1024 1024"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".last-page_svg__st0{fill:#babac4}"}</style>
    <g id="last-page_svg__Artboard">
      <g id="last-page_svg__Group-2" transform="translate(-38 100)">
        <path
          id="last-page_svg__Rectangle-3"
          className="last-page_svg__st0"
          d="M496.9 369.5c24 24 24 62.9 0 86.9L322.4 630.9c-24 24-62.9 24-86.9 0-24-24-24-62.9 0-86.9l174.4-174.4c24-24.1 63-24.1 87-.1z"
        />
        <path
          id="last-page_svg__Rectangle-3-Copy"
          className="last-page_svg__st0"
          d="M496.9 447.2c24-24 24-62.9 0-86.9L322.4 185.9c-24-24-62.9-24-86.9 0-24 24-24 62.9 0 86.9l174.4 174.4c24 24 63 24 87 0z"
        />
      </g>
      <g id="last-page_svg__Group-2-Copy" transform="translate(525 100)">
        <path
          id="last-page_svg__Rectangle-3_1_"
          className="last-page_svg__st0"
          d="M301.1 369.5c24 24 24 62.9 0 86.9L126.7 630.9c-24 24-62.9 24-86.9 0-24-24-24-62.9 0-86.9l174.4-174.4c24-24.1 62.9-24.1 86.9-.1z"
        />
        <path
          id="last-page_svg__Rectangle-3-Copy_1_"
          className="last-page_svg__st0"
          d="M301.1 447.2c24-24 24-62.9 0-86.9L126.7 185.9c-24-24-62.9-24-86.9 0-24 24-24 62.9 0 86.9l174.4 174.4c24 24 62.9 24 86.9 0z"
        />
      </g>
    </g>
  </svg>
);
export default SvgLastPage;
