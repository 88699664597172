import { DeviceStatusDateType } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/DeviceStatusDateType'

export function getCronStringFromMaintenanceValues(
    weekDays: string[],
    hours: number,
    minutes: number
) {
   if (weekDays.length) {
      return `0 ${minutes} ${hours} ? * ${weekDays} *`
   }

   return `0 ${minutes} ${hours} ? * * *`
}

export function getCronStringFromDeviceConfigsByDateType(
    dateType: DeviceStatusDateType,
    hours: number,
    minutes: number
) {
   switch (dateType) {
      case 'days':
         return `0 ${minutes} 0 */${hours} * ? *`
      case 'hours':
         return `0 0 0/${hours} * * ? *`
      case 'minutes':
         return `0 0/${minutes} * * * ? *`
   }
}
