import React from 'react'

export const UK_ID = 826
export const ROI_ID = 372
export const UK_CURRENCY_NUMBER_CODE = 826
export const STANDARD_MID_LENGTH = 8

export const TIMEZONES = {
    'Europe/London': 'uk',
    'Europe/Dublin': 'roi'
}

export const OWNER_GROUPS = {
    'optomany': 10000,
    '123send': 10001
}
