// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".B6vagShFOuYm2BPDiyb1F .ant-dropdown-menu-item {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 40px;\n  color: #000000; }\n", ""]);
// Exports
exports.locals = {
	"PostalCodeDropDown": "B6vagShFOuYm2BPDiyb1F"
};
module.exports = exports;
