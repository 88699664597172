import React from 'react'
import translations from './translations'

export const translationValues = {
    addProduct: 'Add Products',
    addBundle: 'Add',

    store: 'Merchant Store',
    storeName: 'Name',
    storeNameRequired: 'Name is required',
    telephoneNumber: 'Telephone number',
    telephoneNumberRequired: 'Telephone number is required',
    telephoneNumberInvalid: 'Invalid telephone number',
    companyNumber: 'Company number',
    companyNumberRequired: 'Company number is required',
    companyNumberUKInvalid: 'Company number must consist of 8 digits or 2 letters followed by 6 digits or 2 letters followed by 5 digits followed by 1 letter',
    companyNumberROIInvalid: 'Company number must consist of 5 or 6 digits',
    companyNumberUKTooltip: 'Company registration number (CRN) as registered with Companies House. If you do not have a company number, please select the “sole trader” option.',
    companyNumberROITooltip: 'Company or business number as registered with Companies Registration Office (CRO). If you do not have a company or business number, please select the “sole trader” option.',
    vatNumber: 'VAT number',
    vatNumberRequired: 'VAT number is required',
    email: 'Email',
    emailRequired: 'Email is required',
    emailInvalid: 'Invalid email',

    address: 'Address',
    country: 'Country',
    countryRequired: 'Country is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',
    county: 'County',
    countyRequired: 'County is required',
    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',
    axeptConnectCloud: 'Axept Connect Cloud',
    yes: 'Yes',
    no: 'No',

    department: 'Merchant Department',
    departmentId: 'Department Id',
    departmentName: 'Name',
    departmentNameRequired: 'Name is required',
    departmentRequired: 'Department is required',
    departmentType: 'Type',
    general: 'General',
    eCommerce: 'eCommerce',
    merchantCategoryCode: 'Merchant category code',
    merchantCategoryCodeRequired: 'Merchant category code is required',
    merchantCategoryCodeTooltip: 'A Merchant Category Code (MCC) is a four-digit number used to classify a business by the types of goods or services it provides. Optomany require this information to ensure the correct settings are applied to every merchant for their category. The MCC should be provided by your acquirer.',
    streamlineCompanyId: 'Streamline company ID',
    streamlineCompanyIdRequired: 'Streamline company ID is required',
    streamlineCompanyIdInvalid: 'Streamline company ID must be 6 or 8 digits',
    streamlineCompanyIdTooltip: 'Mandatory if using Worldpay as the acquirer.',

    transactionTypes: 'Transaction types',
    cashBack: 'Max. cashback',
    enableCashBack: 'Enable cashback',
    cashBackRequired: 'Max. cashback is required',
    cashBackInvalid: 'Invalid number',
    cashBackMaxExceeded: 'Max. cashback value is 100.00',
    gratuity: 'Max. gratuity',
    enableGratuity: 'Enable gratuity',
    gratuityRequired: 'Max. gratuity is required',
    refund: 'Max. refund',
    enableRefund: 'Enable refund',
    refundRequired: 'Max. refund is required',
    purchase: 'Max. purchase',
    enablePurchase: 'Enable purchase',
    purchaseRequired: 'Max. purchase is required',

    cardSchemeDetails: 'Card scheme details',
    noCardSchemeSpecified: 'No card scheme is specified',
    visaMID: 'Visa MID',
    visaMIDRequired: 'Visa MID is required',
    visaDebitMID: 'Visa Debit MID',
    visaDebitMIDRequired: 'Visa Debit MID is required',
    mastercardDebitMID: 'Debit Mastercard MID',
    mastercardDebitMIDRequired: 'Debit Mastercard MID is required',
    maestroDebitMID: 'Maestro MID',
    maestroDebitMIDRequired: 'Maestro MID is required',

    mastercardMID: 'Mastercard MID',
    mastercardMIDRequired: 'Mastercard MID is required',
    accountNumber: 'Bank account number',
    accountNumberRequired: 'Bank account number is required',
    accountNumberInvalid: 'Bank account number must be 8 digits',
    sortCode: 'Sort code',
    sortCodeRequired: 'Sort code is required',
    sortCodeInvalid: 'Sort code must be 6 digits',

    acquirer: 'Acquirer',
    acquirerRequired: 'Acquirer is required',
    acquirerTooltip: 'An acquirer is the financial institution that processes credit or debit card payment processing services on behalf of the merchant. The acquirer will have issued a Merchant ID for card processing.',
    acquirerAssignedTID: 'Acquirer assigned TID',

    preferredSubmission: 'Preferred submission',
    preferredSubmissionRequired: 'Preferred submission is required',
    preferredSubmissionTooltip: '“Main” Submission sees funds submitted at midnight, “Extended” submits later in the morning. The precise times may vary dependent upon the acquirer. For more details contact Optomany.',
    brandingOrganisation: 'Custom branding selection',
    brandingOrganisationTooltip: 'If customer branding is required it will have been configured with Optomany prior to ordering, if your branding does not appear and you believe it should please contact Optomany.',
    accountName: 'Account name',
    accountNameRequired: 'Account name is required',
    accountNameTooltip: 'Used as the “Merchant Department” name in OCC. This is also displayed to the user when logging into axept GO and more than one Merchant Department is available.',
    currency: 'Currency',
    currencyRequired: 'Currency is required',
    currencyTooltip: 'This must be the currency which has been agreed with the Acquirer when setting up the merchant agreement. An invalid entry will affect the processing of authorisations and/or settlements.',

    merchantId: 'Merchant ID',
    merchantIdRequired: 'Merchant ID is required',
    amexMerchantIdTooltip: 'Merchant ID issued by American Express - different from the main acquirer MID.',

    americanExpress: 'American Express',
    dinersDiscover: 'Diners Discover',
    unionPay: 'Union Pay',
    flexecash: 'Flexecash',

    selectStoreAndDepartment: 'Select Store & Department',
    selectBundles: 'Select Bundles',
    proceedToBundlesSelection: 'Proceed to Bundles Selection',
    reselectStoreAndDepartment: 'Reselect Store & Department',
    complete: 'Complete',

    cancel: 'Cancel',
    addToOrder: 'Add Products',
    save: 'Save',

    invalidWorldPayMIDLength: 'Worldpay MID is usually 8 digits, please check and ensure the MID is correct',
    invalidBarclaycardSterlingMIDLength: 'Barclaycard Sterling MID is usually 7 digits, please check and ensure the MID is correct',
    invalidGlobalPayMIDLength: 'GlobalPay MID is usually 8 digits, please check and ensure the MID is correct',
    invalidAMEXMIDLength: 'AMEX MID is usually 10 digits, please check and ensure the MID is correct',
    invalidElavonMIDLength: 'Elavon MID is usually 10 digits, please check and ensure the MID is correct',
    invalidBarclaycardMCMIDLength: 'Barclaycard MC MID is usually 7 digits, please check and ensure the MID is correct',
    invalidOptomanyMIDLength: 'Optomany MID is usually more than 5 digits, please check and ensure the MID is correct',
    invalidLloydsCardNetMIDLength: 'Lloyds CardNet MID is usually 15 digits, please check and ensure the MID is correct',
    invalidFDMSMIDLength: 'FDMS MID is usually 15 digits, please check and ensure the MID is correct',
    invalidAIBMIDLength: 'AIB MID is usually 9 digits, please check and ensure the MID is correct',
    invalidSwedbankMIDLength: 'Swedbank MID is usually more than 5 digits, please check and ensure the MID is correct',
    invalidBorgunMIDLength: 'Borgun MID is usually 7 digits, please check and ensure the MID is correct',

    bundle: 'Bundle',
    bundles: 'Bundles',
    bundleRequired: 'Bundle is required',
    bundleTooltip: 'Select from agreed product bundles, if a bundle is missing or incorrect please contact Optomany.',
    bundleDescription: 'Bundle description',
    noDescription: 'No description',
    quantity: 'Quantity',
    quantityRequired: 'Quantity is required',
    selectedBundles: 'Selected bundles',

    noBundleAdded: <>No bundle added to the order<br/>Please use the form above to add bundles</>
}

translations.add('en', translationValues)
