import { IProductListFormStore } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/IProductListFormStore'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { AddProductFormData, addProductFormDataToExistingProduct, createAddProductFormData, createProduct, createProductListFormData, Product, ProductListFormData, productToAddProductFormData, SDDepartment, SDStore } from '~/code/POS/pages'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { fetchBundles } from '~/code/stores/NewOrderStore/services/fetchers'
import { IAddProductForm123SendStore } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/IAddProductForm123SendStore'
import { OTTSendBundle } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/models/OTTSendBundle'
import { INewOrder123SendStore } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/components/123send/INewOrder123SendStore'
import { AppStore } from '~/code/AppStore'
import { AcquirerAssignedTIDsStore } from '~/code/stores/NewOrderStore/common/AcquirerAssignedTIDsStore'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'

export class ProductListForm123SendStore implements IProductListFormStore, IAddProductForm123SendStore {

    constructor (public parentStore: INewOrder123SendStore) {
        makeObservable(this, {
            productType: observable,
            shouldExcludeVerifoneProducts: observable,
            shouldShowBundleTypeCheckbox: observable,
            isFormVisible: observable,
            currentStep: observable,
            _bundles: observable,
            _isLoadingBundles: observable,
            selectedStore: observable,
            selectedDepartment: observable,
            productListFormData: observable,
            addProductFormData: observable,
            addProductFormCurrentStep: observable,
            canGoBack: observable,
            bundles: computed,
            acquirers: computed,
            acquirerAssignedTids: computed,
            totalTIDsList: computed,
            isTIDDuplicateExist: computed,
            addDepartmentFormData: computed,
            setSelectedStore: action,
            setSelectedDepartment: action,
            onAddProductFormClose: action,
            loadBundles: action,
            showOnlyHardwareOnlyBundles: action,
            addProduct: action,
            deleteProduct: action,
            editProduct: action,
            deleteBundle: action,
            addBundle: action,
            setAddProductFormData: action,
            setProductListFormData: action,
            setProducts: action,
            setAcquirerAssignedTids: action,
            setTotalTIDsList: action,
            setIsTIDDuplicateExist: action,
            goBack: action
        })

        this.productListFormData = createProductListFormData()
        this.addProductFormData = createAddProductFormData()

        reaction( () => this.parentStore.addDepartmentFormStore.addDepartmentFormData.departmentAcquirer, () => {
            this.setProducts(
                this.productListFormData.products
                    .filter(product => product.departmentId !== this.parentStore.addDepartmentFormStore.addDepartmentFormData.departmentId)
            )
        })
    }

    static stepName: string = 'productList'

    shouldShowOnlyHardwareOnlyBundles: boolean = false

    productType: 'verifone' | 'non-verifone' = null

    shouldExcludeVerifoneProducts: boolean = false

    shouldShowBundleTypeCheckbox: boolean = false

    isFormVisible: boolean

    currentStep: number = 0

    _bundles: SelectItem[] = []
    _isLoadingBundles: boolean = false

    selectedStore: SDStore

    selectedDepartment: SDDepartment

    productListFormData: ProductListFormData

    addProductFormData: AddProductFormData

    addProductFormCurrentStep: number = 0

    canGoBack: boolean = true

    private readonly acquirerAssignedTIDsStore = new AcquirerAssignedTIDsStore(this)

    get acquirerAssignedTids(): AcquirerAssignedTid[] {
        return this.acquirerAssignedTIDsStore?.acquirerAssignedTids
    }

    get totalTIDsList(): string[] {
        return this.acquirerAssignedTIDsStore?.totalTIDsList
    }

    get addDepartmentFormData() {
        return this.parentStore.addDepartmentFormStore.addDepartmentFormData
    }

    get isTIDDuplicateExist(): boolean {
        return this.acquirerAssignedTIDsStore?.isTIDDuplicateExist
    }

    setAcquirerAssignedTids = (acquirerAssignedTIDs: AcquirerAssignedTid[]) => {
        this.acquirerAssignedTIDsStore?.setAcquirerAssignedTids(acquirerAssignedTIDs)
    }

    setTotalTIDsList = (bundles: OTTSendBundle[]) => {
        this.acquirerAssignedTIDsStore?.setTotalTIDsList(bundles)
    }

    setIsTIDDuplicateExist = (isTIDDuplicateExist) => {
        this.acquirerAssignedTIDsStore?.setIsTIDDuplicateExist(isTIDDuplicateExist)
    }

    get bundles(): SelectItem[] {
        this.loadBundles()

        let _resultBundles = [...this._bundles]
        if (this.productType === 'verifone') {
            _resultBundles = _resultBundles?.filter(bundle => bundle.params?.containsVerifoneProduct)
        }

        if (this.productType === 'non-verifone' || this.shouldExcludeVerifoneProducts) {
            _resultBundles = _resultBundles?.filter(bundle => !bundle.params?.containsVerifoneProduct)
        }

        return _resultBundles
    }

    stores: SDStore[] = []

    get acquirers() {
      return AppStore.acquirersStore.acquirers
    }

    setSelectedStore = (store: SDStore) => {
        this.selectedStore = store
        this.setSelectedDepartment(this.selectedStore?.departments[0])
    }

    setSelectedDepartment = (department: SDDepartment) => {
        this.selectedDepartment = department
        const acquirer = this.acquirers?.find(item => item.value === department?.acquirer)
        if (acquirer){
            this.shouldExcludeVerifoneProducts = !acquirer.params.supportedByVerifone
        } else {
            this.shouldExcludeVerifoneProducts = false
        }
        this._bundles = []
    }

    onAddProductFormClose = () => {
        this.isFormVisible = false
    }

    loadBundles = async () => {
        if (this._isLoadingBundles || !isEmpty(this._bundles)) { return }
        this._isLoadingBundles = true
        this._bundles = await fetchBundles(`/api/bundles/123send-bundles?hardwareOnly=${this.shouldShowOnlyHardwareOnlyBundles}`)
        this._isLoadingBundles = false
    }

    showOnlyHardwareOnlyBundles = (value: boolean) => {
        this.shouldShowOnlyHardwareOnlyBundles = value
        this._bundles = []
        this.loadBundles()
    }

    addProduct () {
        if (this.addProductFormData.id) {
            this.addProductFormCurrentStep = 0
            this.addProductFormData = createAddProductFormData()
        }
        this.isFormVisible = true
    }

    deleteProduct (productId: string) {
        this.productListFormData.products =
            this.productListFormData.products.filter(item => item.id !== productId)
        if (this.productListFormData.products.length === 0) {
            this.productType = null
        }
    }

    editProduct (productId: string) {
        this.addProductFormCurrentStep = 0
        this.addProductFormData = productToAddProductFormData(this.productListFormData.products.find(product => product.id === productId))
        this.isFormVisible = true
    }

    deleteBundle = (bundleCode) => {
        this.addProductFormData.bundles = this.addProductFormData.bundles.filter(bundle => bundle.bundleCode !== bundleCode)
    }

    addBundle = (bundle: {containsVerifoneProduct, bundleCode, bundleQuantity, bundleCustomPrice, bundleTypeIsHardware, description, acquirerAssignedTids}, departmentId) => {
        const bundles = this.addProductFormData.bundles as OTTSendBundle[]

        const selectedBundleContainsVerifoneProduct = bundle.containsVerifoneProduct
        this.productType = selectedBundleContainsVerifoneProduct ? 'verifone' : 'non-verifone'

        const productsInOtherDepartments = this.productListFormData.products.filter(item => item.departmentId !== departmentId)
        const otherDepartmentsContainVerifoneProduct = productsInOtherDepartments.some(item => item.bundles.some(_bundle => (_bundle as OTTSendBundle).containsVerifoneProduct))

        if (selectedBundleContainsVerifoneProduct && otherDepartmentsContainVerifoneProduct) {
            throw Error()
        }

        const foundBundle = bundles.find(item => item.bundleCode === bundle.bundleCode)
        if (foundBundle) {
            foundBundle.bundleQuantity = Number(foundBundle.bundleQuantity) + Number(bundle.bundleQuantity)
            foundBundle.bundleCustomPrice = bundle.bundleCustomPrice
            this.addProductFormData.bundles = bundles.filter(item => item.bundleCode !== bundle.bundleCode)
            this.addProductFormData.bundles.unshift(foundBundle)
        } else {
            (this.addProductFormData.bundles as OTTSendBundle[]).unshift({
                bundleType: bundle.bundleTypeIsHardware ? 'hardware' : 'standard',
                bundleCustomPrice: bundle.bundleCustomPrice,
                bundleCode: bundle.bundleCode,
                bundleDescription: bundle.description,
                bundleQuantity: bundle.bundleQuantity,
                containsVerifoneProduct: bundle.containsVerifoneProduct,
                acquirerAssignedTids: bundle.acquirerAssignedTids
            })
        }
    }

    setAddProductFormData = (data: AddProductFormData) => {
        let product: Product
        if (data.id) {
            product = this.productListFormData.products.find(item => item.id === data.id)
            if (product) {
                addProductFormDataToExistingProduct(data, product)
            } else {
                product = createProduct()
                addProductFormDataToExistingProduct(data, product)
                this.productListFormData.products.push(product)
            }
        } else {
            // check if there is a product for the same store and department combination
            const existingProduct = this.productListFormData.products?.find(item => (item.storeId === data.storeId && item.departmentId === data.departmentId))
            if (existingProduct) {
                data.bundles.forEach(bundle => {
                    const existingBundle = existingProduct.bundles.find(item => item.bundleCode === bundle.bundleCode)
                    if (existingBundle) {
                        existingBundle.bundleQuantity = Number(bundle.bundleQuantity) // Number(existingBundle.bundleQuantity) +
                    } else {
                        existingProduct.bundles.push(bundle)
                    }
                })
            } else {
                data.id = uuid()
                product = createProduct()
                addProductFormDataToExistingProduct(data, product)
                this.productListFormData.products.push(product)
            }
        }

        this.productListFormData = {...this.productListFormData}
        this.addProductFormData = createAddProductFormData()
        // this.showOnlyHardwareOnlyBundles(false)
    }

    setProductListFormData = (data: ProductListFormData) => {
        this.productListFormData = {...this.productListFormData, ...data}
        this.parentStore.handleFormSubmit(this.productListFormData)
    }

    goBack = () => {
        this.parentStore.handleGoBack(ProductListForm123SendStore.stepName)
    }

    setProducts = (products: Product[]) => {
        this.productListFormData.products = products
    }
}
