import { StoreConfigurationModel } from '../index'

export class ConfigurationReportModel {
    orderReference: string
    orderId: number
    opportunityNo: number
    salesSource: string
    referee: string
    merchantName: string
    merchantSystemId: number
    noOfStores: number
    noOfDepartments: number
    storeConfiguration: StoreConfigurationModel[] = []
}
