import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form } from 'antd'
import { validatePostalCodeOfUK } from 'dna-common'
import { getFieldValue } from '~/code/services'
import { Input, Checkbox, LabelWithTooltip, PostCode, CountrySelect } from '~/code/common/components'
import { VALID_TEXT_PATTERN, COMPANY_NUMBER_PATTERN, COMPANY_NUMBER_TYPING_PATTERN, PHONE_NUMBER_PATTERN, PHONE_NUMBER_TYPING_PATTERN, VALID_NAME_PATTERN, VALID_NAME_WITHOUT_SPACE_PATTERN, ROI_COMPANY_NUMBER_PATTERN, ROI_COMPANY_NUMBER_TYPING_PATTERN, ROI_COMPANY_NUMBER_LENGTH, COMPANY_NUMBER_LENGTH, VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES } from '~/code/models/Patterns'
import { searchAddressesByPostcode } from '~/code/POS/pages/NewOrder/services/fetchers'
import { UK_ID } from '~/code/models/Constants'
import translations from './translations'
import { MerchantDetailsFormProps } from './props'
import styles from './MerchantDetailsForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'

export const MerchantDetailsForm = observer(({ store, setManuallyEnteredCompanyName }: MerchantDetailsFormProps) => {
    const { merchantDetailsFormData: data, setMerchantDetailsFormData, countries, isNewOrderForSoleTrader, configCountry, canGoBack, goBack } = store
    const [form] = Form.useForm()

    const onFinish = (values) => {
        setMerchantDetailsFormData({companyName: null, companyNumber: null, vatNumber: null, ...values})
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const companyNumberPattern = () => {
        switch (configCountry) {
            case 'roi': {
                return [ROI_COMPANY_NUMBER_PATTERN, ROI_COMPANY_NUMBER_TYPING_PATTERN, ROI_COMPANY_NUMBER_LENGTH]
            }
            default: {
                return [COMPANY_NUMBER_PATTERN, COMPANY_NUMBER_TYPING_PATTERN, COMPANY_NUMBER_LENGTH]
            }
        }
    }

    return (
        <div className={styles.MerchantDetailsForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().companyName}
                                name={'companyName'}
                                initialValue={getFieldInitialValue('companyName')}
                                getValueFromEvent={
                                    (e: React.FormEvent<HTMLInputElement>) => {
                                        const regex = VALID_NAME_PATTERN
                                        const value = e.currentTarget.value
                                        const test = regex.test(value)
                                        if (!test) {
                                            return form.getFieldValue('companyName')
                                        }
                                        const val = value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                        setManuallyEnteredCompanyName(val)
                                        return val
                                    }
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: translations().companyNameRequired
                                    },
                                    {
                                        pattern: VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES,
                                        message: translations().companyNameInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().companyName}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().tradingAs}
                                name={'tradingAs'}
                                initialValue={getFieldInitialValue('tradingAs')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('tradingAs')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().tradingAsRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().tradingAs}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().vatNumber}
                                name={'vatNumber'}
                                initialValue={getFieldInitialValue('vatNumber')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('vatNumber')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().vatNumberRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().vatNumber}/>
                            </Form.Item>
                        </div>
                        {!isNewOrderForSoleTrader &&
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().companyNumber} tooltipText={configCountry === 'roi' ? translations().companyNumberROITooltip : translations().companyNumberUKTooltip} tooltipPosition={'right'}/>}
                                name={'companyNumber'}
                                initialValue={getFieldInitialValue('companyNumber')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = companyNumberPattern()[1] as RegExp
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('companyNumber')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().companyNumberRequired
                                    },
                                    {
                                        pattern: companyNumberPattern()[0] as RegExp,
                                        message: configCountry === 'roi' ? translations().companyNumberROIInvalid : translations().companyNumberUKInvalid
                                    }
                                ]}
                            >
                                <Input
                                    textTransform={'uppercase'}
                                    placeholder={translations().companyNumber}
                                    disabled={getFieldInitialValue('companyNumber')?.length >= companyNumberPattern()[2]}/>
                            </Form.Item>
                        </div>
                        }
                    </Col>
                </Row>
                <div className={styles.MerchantDetailsForm_subgroupTitle}>
                    {translations().address}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <CountrySelect
                            form={form}
                            initialValue={getFieldInitialValue('country')}
                            fieldName={'country'}
                            userCountry={configCountry}
                            countries={countries}
                            required={true}
                        />
                        <div>
                            <Form.Item shouldUpdate noStyle>
                                {() => {
                                    return (
                                        <Form.Item
                                            label={translations().postcode}
                                            name={'postcode'}
                                            initialValue={getFieldInitialValue('postcode')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: translations().postcodeRequired
                                                },
                                                {
                                                    validator: async (rule, value) => {
                                                        const isUK = form.getFieldValue('country') === UK_ID
                                                        if (value && isUK && !validatePostalCodeOfUK(value)) {
                                                            throw new Error(translations().postcodeInvalid)
                                                        }
                                                    }
                                                }
                                            ]}
                                        >
                                            <PostCode
                                                isUK={ form.getFieldValue('country') === UK_ID }
                                                getPostCodeAddresses={ (postCode: string) => searchAddressesByPostcode(postCode) }
                                                onAddressSelect={ (a) => {
                                                    form.setFieldsValue({
                                                        addressLine1: a.line_1,
                                                        addressLine2: a.line_2,
                                                        addressLine3: a.line_3,
                                                        town: a.town_or_city,
                                                        county: a.county
                                                    })
                                                } }
                                                placeholder={translations().postcode}
                                            />
                                        </Form.Item>
                                    )}
                                }
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().addressLine1}
                                name={'addressLine1'}
                                initialValue={getFieldInitialValue('addressLine1')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine1')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().addressLine1Required
                                    }
                                ]}
                            >
                                <Input placeholder={translations().addressLine1}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().addressLine2}
                                name={'addressLine2'}
                                initialValue={getFieldInitialValue('addressLine2')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine2')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                            >
                                <Input placeholder={translations().addressLine2}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().town}
                                name={'town'}
                                initialValue={getFieldInitialValue('town')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('town')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().townRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().town}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().county}
                                name={'county'}
                                initialValue={getFieldInitialValue('county')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('county')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().countyRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().county}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className={styles.MerchantDetailsForm_subgroupTitle}>
                    {translations().contact}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().contactName}
                                name={'contactName'}
                                initialValue={getFieldInitialValue('contactName')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_WITHOUT_SPACE_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactName')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactNameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactName}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().contactSurname}
                                name={'contactSurname'}
                                initialValue={getFieldInitialValue('contactSurname')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_WITHOUT_SPACE_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactSurname')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactSurnameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactSurname}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().jobTitle}
                                name={'contactJobTitle'}
                                initialValue={getFieldInitialValue('contactJobTitle')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('town')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().jobTitleRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().jobTitle}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().email}
                                name={'contactEmail'}
                                initialValue={getFieldInitialValue('contactEmail')}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().emailRequired
                                    },
                                    {
                                        type: 'email',
                                        message: translations().emailInvalid
                                    }
                                ]}
                            >
                                <Input textTransform={'lowercase'} placeholder={translations().email}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().contactPhoneNumber}
                                name={'contactPhoneNumber'}
                                initialValue={getFieldInitialValue('contactPhoneNumber')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactPhoneNumber')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactPhoneNumberRequired
                                    },
                                    {
                                        pattern: PHONE_NUMBER_PATTERN,
                                        message: translations().contactPhoneNumberInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactPhoneNumber}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().contactMobilePhone}
                                name={'contactMobilePhone'}
                                initialValue={getFieldInitialValue('contactMobilePhone')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactMobilePhone')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactMobilePhoneRequired
                                    },
                                    {
                                        pattern: PHONE_NUMBER_PATTERN,
                                        message: translations().contactMobilePhoneInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactMobilePhone}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className={styles.MerchantDetailsForm_subgroupTitle}>
                    {translations().communicationPreferences}
                </div>
                <Row gutter={16}>
                    <Col xs={24}>
                        <div>
                            <Form.Item
                                name={'receiveSalesAndMarketing'}
                                initialValue={data.receiveSalesAndMarketing}
                                valuePropName={'checked'}
                            >
                                <Checkbox>
                                    <LabelWithTooltip label={translations().receiveSalesAndMarketing} tooltipText={translations().receiveSalesAndMarketingTooltip} tooltipPosition={'right'} />
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
