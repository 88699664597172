import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Tabs, Typography } from 'antd'
import { Page } from '~/code/common/pages/Page'
import { Button } from '~/code/common/components'
import { PlusOutlined } from '@ant-design/icons'
import { AllApplications } from '~/code/ODIN/components/AllApplications'
import { AddNewFirmware } from '~/code/ODIN/pages/FirmwareManagement/components/AddNewFirmware/AddNewFirmware'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import translations from './translations'
import { IFirmwareManagementProps } from './props'
import styles from './FirmwareManagement.scss'

const {Title} = Typography
const {TabPane} = Tabs

export const FirmwareManagement = observer((props: IFirmwareManagementProps) => {
    const { store } = props
    const [isAddNewPackage, setIsAddNewPackage] = useState<boolean>(false)

    return (
        <Page>
            <Title level={3}>{isAddNewPackage ? `${translations().firmwareManagement} - ${translations().uploadNewPackage}` : translations().firmwareManagement}</Title>

            { !isAddNewPackage && (
                <>
                    <div className={styles.upload}>
                        {store.permissions?.includes('odin.firmwareManagement.create') && (
                            <Button onClick={() => setIsAddNewPackage(true)} icon={<PlusOutlined />}>
                                {translations().uploadNewPackage}
                            </Button>
                        )}
                    </div>

                    <Tabs defaultActiveKey="1">
                        {store.permissions?.includes('odin.firmwareManagement.view') && (
                            <TabPane tab={translations().allPackages} key="1">
                                <AllApplications packageType={PackageType.Firmware} store={store.allAppsStore}/>
                            </TabPane>
                        )}
                    </Tabs>
                </>
            ) }

            { isAddNewPackage && <AddNewFirmware store={store.addNewFirmwareStore} setIsAddNewApp={setIsAddNewPackage}/>}
        </Page>
    )
})
