import { Order } from '~/code/POS/pages/NewOrder/models/Order/Order'

export interface PlaceOrderRequest {
    isoCustomerId: string
    progressUpdateUrl: string
    orderReference: string
    deviceDetails: {
        userId: string
        userPin: string
    }
    orders: Order[]
    deliveryAddress: {
        deliveryName: string
        line1: string
        line2: string
        townOrCity: string
        county: string
        postcode: string
        countryCode: number
    }
    internalIdentifier: string
}

export function createPlaceOrderRequest(): PlaceOrderRequest {
    return {
        isoCustomerId: '', // if an employee use selected company account number, if non-employee get it from web token account number
        progressUpdateUrl: '',
        orderReference: '',
        deviceDetails: {
            userId: '1234',
            userPin: '1234'
        },
        orders: [],
        deliveryAddress: {
            deliveryName: '',
            line1: '',
            line2: '',
            townOrCity: '',
            county: '',
            postcode: '',
            countryCode: 0
        },
        internalIdentifier: ''
    }
}
