import translations from './translations'

translations.add('en', {
    newOrder: 'New Order',
    orderHistory: 'Order History',
    userGuide: 'User Guide',
    faq: 'FAQ',
    contactUs: 'Contact Us',
    selectCompany: 'Select Company',
    employeeFunctions: 'Employee Functions',
    createCheckoutV3Account: 'Checkout V3 Onboarding',
    deviceManagement: 'Device Management',
    applicationManagement: 'Application Management',
    firmwareManagement: 'Firmware Management',
    merchantSchedule: 'Merchant Schedule',
    noMerchantSelected: 'No Merchant Selected'
})
