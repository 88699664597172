// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._24jeREZ9-jB7HE-y-IdQIK {\n  padding-top: 16px; }\n\n._2BvKuVKVzIsUYGtD1s6ruk {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n\n._GK2Lga0QVDMh4I50s4t7 {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 36px;\n  color: #000000;\n  padding-bottom: 13px;\n  display: flex; }\n\n._3U_lJmIJXxz6jXVTqIc_08 .g24F1oPkTskuoNWaJRMPm {\n  font-size: 14px;\n  line-height: 36px; }\n\n._3U_lJmIJXxz6jXVTqIc_08 ._5KxXsrYfGsHruOKoFfXZ {\n  display: flex;\n  padding-left: 6px;\n  padding-top: 2px; }\n\n._3U_lJmIJXxz6jXVTqIc_08 ._2F5I7iThxtQjCUXR7o5WGC {\n  padding-top: 32px; }\n", ""]);
// Exports
exports.locals = {
	"OrderDetailsForm_goBackButtonContainer": "_24jeREZ9-jB7HE-y-IdQIK",
	"OrderDetailsForm_submitButtonContainer": "_2BvKuVKVzIsUYGtD1s6ruk",
	"OrderDetailsForm_subgroupTitle": "_GK2Lga0QVDMh4I50s4t7",
	"OrderDetailsForm": "_3U_lJmIJXxz6jXVTqIc_08",
	"tooltipIcon": "g24F1oPkTskuoNWaJRMPm",
	"tooltipIconWrapper": "_5KxXsrYfGsHruOKoFfXZ",
	"deleteUpgradeRowButtonWrapper": "_2F5I7iThxtQjCUXR7o5WGC"
};
module.exports = exports;
