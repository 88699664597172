import React from 'react'
import Helmet from 'react-helmet'
import { observer } from 'mobx-react'
import { IPageTitleStore } from './IPageTitleStore'
import translations from './translations'

const PageTitle = observer(({ store }: {store: IPageTitleStore}) => {
    return (
        <Helmet>
            <title>{store?.currentPageTitle || translations().defaultTitle}</title>
        </Helmet>
    )
})

export { PageTitle }
