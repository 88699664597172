import {
    createMerchantDetailsFormData,
    MerchantDetailsFormData
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/MerchantDetailsForm/models'
import { StoreData, DepartmentData } from '../models'
import { StoreAndDepartmentSelectionData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/StoreAndDepartmentSelectionForm/models'
import { TerminalSettingsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm/models'
import { TransactionLimitsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm/models'
import { CheckoutSettingsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/models'
import { PenniesFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/PenniesForm/models'
import { createOnboardingRequest, OnboardingRequest } from '~/code/stores/CheckoutV3Onboarding/models/backend/OnboardingRequest'
import { AcquisitionChannel } from '~/code/stores/CheckoutV3Onboarding/models/backend/AcquisitionChannel'
import { SelectItem } from '~/code/common/components'

export function storeAndDepartmentDataToMerchantDetailsFormData (merchantName: string, storeData: StoreData, departmentData: DepartmentData): MerchantDetailsFormData {
    const result: MerchantDetailsFormData = {
        ...createMerchantDetailsFormData(),
        companyNameFull: merchantName,
        companyNameShort: merchantName,
        addressLine1: storeData.address1,
        addressLine2: storeData.address2,
        country: storeData.countryId,
        town: storeData.townCity,
        postcode: storeData.postZipCode,
        mcc: departmentData.mcc,
        createMerchantPortalAccount: true,
        credentials: {
            email: storeData.emailAddress
        }
    }

    return result
}

export function storeAndDepartmentSelectionFormDataToOnboardingRequest (data: StoreAndDepartmentSelectionData,
                                                                        request: OnboardingRequest): void {
    request.terminalSettings.optomanyPlatform.merchantStoreId = data.storeId
    request.terminalSettings.optomanyPlatform.merchantDepartmentId = data.departmentId
}

export function merchantDetailsFormDataToRequest (data: MerchantDetailsFormData, request: OnboardingRequest): void {
    request.merchant = {
        companyNameFull: data.companyNameFull,
        companyNameShort: data.companyNameShort,
        descriptor: data.descriptor,
        website: data.website,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        country: 'GB', // data.country,
        city: data.town,
        postCode: data.postcode,
        mcc: String(data.mcc),
        currency: data.currency,
        acquisitionChannel: data.acquisitionChannel,
        createMerchantPortalAccount: true,
        credentials: {
            email: data.credentials?.email,
            password: data.credentials?.password
        },
        initiatorEmail: data.initiatorEmail
    }
}

export function terminalSettingsFormDataToRequest (data: TerminalSettingsFormData, request: OnboardingRequest): void {
    request.terminalSettings = {
        ...request.terminalSettings,
        defaultTransactionType: data.defaultTransactionType,
        optomanyPlatform: {
            ...request.terminalSettings.optomanyPlatform,
            acquirerId: data.optomanyPlatform?.acquirerId,
            paymentChannelId: Number(data.optomanyPlatform?.paymentChannelId),
            cardAcceptorNumber: data.optomanyPlatform?.cardAcceptorNumber,
            merchantDepartmentId: data.optomanyPlatform?.merchantDepartmentId,
            merchantName: data.optomanyPlatform?.merchantName,
            merchantUrl: data.optomanyPlatform?.merchantUrl,
            merchantStoreId: data.optomanyPlatform?.merchantStoreId,
            visaMid: data.optomanyPlatform?.visaMid,
            mastercardMid: data.optomanyPlatform?.mastercardMid,
            amexMid: data.optomanyPlatform?.amexMid,
            unionPayMid: data.optomanyPlatform?.unionPayMid,
            avsHouseMatrix: Number(data.optomanyPlatform?.avsHouseMatrix),
            avsPostCodeMatrix: Number(data.optomanyPlatform?.avsPostCodeMatrix),
            cscMatrix: Number(data.optomanyPlatform?.cscMatrix),
            paMatrix: Number(data.optomanyPlatform?.paMatrix),
            enableAvsForNonUKCustomers: data.optomanyPlatform?.enableAvsForNonUKCustomers,
            countryId: data.optomanyPlatform?.countryId,
            terminalDeviceInfo: {
                ...request.terminalSettings.optomanyPlatform.terminalDeviceInfo,
                attributes: data.optomanyPlatform?.terminalDeviceInfo?.attributes,
                capabilities: data.optomanyPlatform?.terminalDeviceInfo?.capabilities,
                terminalType: data.optomanyPlatform?.terminalDeviceInfo?.terminalType,
                attributesUsed: data.optomanyPlatform?.terminalDeviceInfo?.attributes,
                poiCapabilities: data.optomanyPlatform?.terminalDeviceInfo?.poiCapabilities,
                capabilitiesUsed: data.optomanyPlatform?.terminalDeviceInfo?.capabilities
            }
        }
    }
}

export function transactionLimitsFormDataToRequest(data: TransactionLimitsFormData, request: OnboardingRequest): void {
    request.terminalSettings.riskLimits = {
        dailyValue: Number(data.dailyValue),
        dailyVolume: Number(data.dailyVolume),
        monthlyValue: Number(data.monthlyValue),
        monthlyVolume: Number(data.monthlyVolume),
        individualTransactionValue: Number(data.individualTransactionValue),
        maxRefundValue: Number(data.maxRefundValue)
    }
}

export function penniesFormDataToRequest(data: PenniesFormData, request: OnboardingRequest): void {
    if (!data.isPenniesUsed) {
        delete request.checkoutSettings.donation
        return
    }

    request.checkoutSettings.donation = {
        logo: data.logo,
        donationMode: data.donationMode,
        adjustmentBands: data.adjustmentBands?.map(band => ({
            bandCeilValue: Number(band.bandCeilValue),
            bandFloorValue: Number(band.bandFloorValue),
            adjustmentValue: Number(band.adjustmentValue)
        })),
        logoImageMimeType: data.logoImageMimeType,
        penniesDonationActiveText: data.penniesDonationActiveText,
        penniesDonationInactiveText: data.penniesDonationInactiveText
    }
}

export function checkoutSettingsFormDataToRequest(data: CheckoutSettingsFormData, request: OnboardingRequest): void {
    if (data.usePartnerSettings) {
        delete request.checkoutSettings.customStyle
        delete request.checkoutSettings.integrationTypes
        delete request.checkoutSettings.mandatoryFields
        return
    }
    request.checkoutSettings = {
        ...request.checkoutSettings,
        integrationTypes: data.integrationTypes,
        mandatoryFields: {
            ...data.mandatoryFields
        },
        customStyle: {
            ...request.checkoutSettings.customStyle,
            ...data.customStyle,
            headerLogoMobileWidth: Number(data.customStyle.headerLogoMobileWidth),
            headerLogoDesktopWidth: Number(data.customStyle.headerLogoDesktopWidth),
            headerLogoMobileHeight: Number(data.customStyle.headerLogoMobileHeight),
            headerLogoDesktopHeight: Number(data.customStyle.headerLogoDesktopHeight),
            actionButtonBorderRadius: Number(data.customStyle.actionButtonBorderRadius),
            headerLogoTextMarginLeft: Number(data.customStyle.headerLogoTextMarginLeft),
            closeButtonOpacity: Number(data.customStyle.closeButtonOpacity)
        }
    }
}

export function createOnboardingRequestFromData (storeAndDepartmentSelectionFormData: StoreAndDepartmentSelectionData,
                                                 merchantDetailsFormData: MerchantDetailsFormData,
                                                 terminalSettingsFormData: TerminalSettingsFormData,
                                                 transactionLimitsFormData: TransactionLimitsFormData,
                                                 penniesFormData: PenniesFormData,
                                                 checkoutSettingsFormData: CheckoutSettingsFormData) {
    const request = createOnboardingRequest()

    storeAndDepartmentSelectionFormDataToOnboardingRequest(storeAndDepartmentSelectionFormData, request)
    merchantDetailsFormDataToRequest(merchantDetailsFormData, request)
    terminalSettingsFormDataToRequest(terminalSettingsFormData, request)
    transactionLimitsFormDataToRequest(transactionLimitsFormData, request)
    penniesFormDataToRequest(penniesFormData, request)
    checkoutSettingsFormDataToRequest(checkoutSettingsFormData, request)

    return request
}

export function mapAcquisitionChannelResponseToSelectItems(res: AcquisitionChannel[] = []): SelectItem[] {
    return res.map(channel => {
        return {
            value: channel.name,
            label: channel.name,
            params: channel
        }
    })
}
