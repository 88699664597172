import { IInformationFormStore } from '~/code/POS/pages/NewOrder/components/iso/InformationForm/IInformationFormStore'
import { createInformationFormData, InformationFormData } from '~/code/POS/pages'
import { SelectItem } from '~/code/common/components'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { AppStore } from '~/code/AppStore'
import { ConfigCountry, MerchantType } from '~/code/POS/pages/NewOrder/models/constants'
import { NO_CONTENT } from '~/code/models'
import { error } from 'dna-common'
import { message } from 'antd'
import { fetchStoresByValue } from '../services/fetchers'

export class InformationFormStore implements IInformationFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler, canGoBack?: boolean) {
        makeObservable(this, {
            isStoreNameLoading: observable,
            isStoreNameValid: observable,
            canGoBack: observable,
            informationFormData: observable,
            countries: computed,
            configCountry: computed,
            merchantType: computed,
            isNewOrderForSoleTrader: computed,
            isNewMerchant: computed,
            onCompanyNameChange: action,
            setInformationFormData: action,
            goBack: action,
            getStores: action.bound
        })

        this.informationFormData = createInformationFormData()
        this.canGoBack = canGoBack
    }

    static stepName = 'information'

    isStoreNameLoading: boolean = false

    isStoreNameValid: boolean = true

    canGoBack: boolean = false

    informationFormData: InformationFormData

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get configCountry(): ConfigCountry {
        return AppStore.newOrderStore?.placeOrderConfigStore.country
    }

    get merchantType(): MerchantType {
        return AppStore.newOrderStore?.placeOrderConfigStore.merchantType
    }

    get isNewOrderForSoleTrader(): boolean {
        return AppStore.newOrderStore?.isNewOrderForSoleTrader
    }

    get isNewMerchant(): boolean {
        return AppStore.newOrderStore?.placeOrderConfigStore?.merchantType === 'new'
    }

    onCompanyNameChange = (value: string) => {
        AppStore.newOrderStore?.setManuallyEnteredCompanyName(value)
    }

    setInformationFormData = (data: InformationFormData) => {
        this.informationFormData = {...this.informationFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.informationFormData, InformationFormStore.stepName)
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(InformationFormStore.stepName)
    }

    getStores(value: string): Promise<void> {
        this.isStoreNameLoading = true
        const internalIdentifier = AppStore.internalIdentifierEncoded ? `&internalIdentifier=${AppStore.internalIdentifierEncoded}` : ''
        const merchantId = AppStore.newOrderStore?.selectedCompany?.merchantId
        return fetchStoresByValue(value, merchantId, internalIdentifier)
            .then(response => {
                if (!response) {
                    return
                }

                runInAction(() => {
                    if (response.length > 0) {
                        const stores = response.map(s => s.storeName.toLowerCase())
                        if (stores.includes(value.toLowerCase())) {
                            this.isStoreNameValid = false
                        } else {
                            this.isStoreNameValid = true
                        }
                    } else {
                        this.isStoreNameValid = true
                    }
                })
            })
            .catch((err: Error) => {
                if (err.name === NO_CONTENT) {
                    runInAction(() => {
                        this.isStoreNameValid = true
                    })
                    return
                }
                error(`FAILED: ${err.message}`)
                message.error(err.message)
            })
            .finally(() => {
                runInAction(() => {
                    this.isStoreNameLoading = false
                })
            })
    }

}
