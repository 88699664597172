// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ex--9wr1xxjWQjRldO_ka {\n  transition: 0.3s;\n  margin: 10px; }\n\n._2MvbyvmdtlYDnTXwnONOQZ {\n  background-color: #f5fdff; }\n\n._3GczoIMCwx3K04ZfsjUJqu {\n  color: #8C8C8C; }\n\n.VsBZDtMB7UGshwG9uZA53 {\n  font-weight: bold; }\n", ""]);
// Exports
exports.locals = {
	"card": "_2ex--9wr1xxjWQjRldO_ka",
	"isNeedToUploadFile": "_2MvbyvmdtlYDnTXwnONOQZ",
	"text": "_3GczoIMCwx3K04ZfsjUJqu",
	"title": "VsBZDtMB7UGshwG9uZA53"
};
module.exports = exports;
