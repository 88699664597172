import React from 'react'
import { Button, Input } from '~/code/common/components'
import { Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { AddNewDeveloperProps } from './props'
import translations from './translations'
import styles from './AddNewDeveloper.scss'

export const AddNewDeveloper = observer(({store}: AddNewDeveloperProps) => {

    const onFinish = (values) => {
        store.addNewDeveloper(values)
    }

    return (
        <Form
            className={styles.form}
            onFinish={onFinish}
        >
            <Form.Item
                name={'NewDeveloperName'}
                rules={[
                    {
                        required: true,
                        message: translations().nameRequired
                    }
                ]}
            >
                <Input value={store.newDeveloper.NewDeveloperName} placeholder={translations().enterName}/>
            </Form.Item>
            <Form.Item
                name={'NewDeveloperEmail'}
                rules={[
                    {
                        required: true,
                        message: translations().emailRequired
                    },
                    {
                        type: 'email',
                        message: translations().emailInvalid
                    }
                ]}
            >
                <Input value={store.newDeveloper.NewDeveloperEmail} placeholder={translations().enterEmail}/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" style={{ whiteSpace: 'nowrap' }}>
                    <PlusOutlined /> {translations().addDeveloper}
                </Button>
            </Form.Item>
        </Form>
    )
})
