import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form } from 'antd'
import { validatePostalCodeOfUK } from 'dna-common'
import { getFieldValue } from '~/code/services'
import { Input, LabelWithTooltip, CountrySelect, PostCode, Button, InputWithLoader } from '~/code/common/components'
import { VALID_TEXT_PATTERN, COMPANY_NUMBER_PATTERN, COMPANY_NUMBER_TYPING_PATTERN, PHONE_NUMBER_PATTERN, PHONE_NUMBER_TYPING_PATTERN, VALID_NAME_PATTERN, VALID_NAME_WITHOUT_SPACE_PATTERN, ROI_COMPANY_NUMBER_PATTERN, ROI_COMPANY_NUMBER_TYPING_PATTERN, ROI_COMPANY_NUMBER_LENGTH, COMPANY_NUMBER_LENGTH, ROI_ID, UK_ID, VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES } from '~/code/models'
import { searchAddressesByPostcode } from '~/code/POS/pages/NewOrder/services/fetchers'
import translations from './translations'
import { InformationFormProps } from './props'
import styles from './InformationForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import _ from 'lodash'

export const InformationForm = observer(({ store }: InformationFormProps) => {
    const { 
        informationFormData: data, 
        setInformationFormData, 
        onCompanyNameChange, 
        countries, 
        configCountry, 
        merchantType, 
        isNewOrderForSoleTrader, 
        canGoBack, 
        goBack,
        isStoreNameLoading,
        getStores,
        isStoreNameValid,
        isNewMerchant
    } = store

    const onFinish = (values) => {
        if (isStoreNameLoading) {
            return
        }
        
        setInformationFormData({companyName: null, companyNumber: null, vatNumber: null, ...values})
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const [form] = Form.useForm()

    const isUK = () => {
        return form.getFieldValue('country') === UK_ID
    }

    const companyNumberPattern = () => {
        switch (configCountry) {
            case 'roi': {
                return [ROI_COMPANY_NUMBER_PATTERN, ROI_COMPANY_NUMBER_TYPING_PATTERN, ROI_COMPANY_NUMBER_LENGTH]
            }
            default: {
                return [COMPANY_NUMBER_PATTERN, COMPANY_NUMBER_TYPING_PATTERN, COMPANY_NUMBER_LENGTH]
            }
        }
    }

    const delayedQuery = useCallback(_.debounce(q => {getStores(q).then(() => {
        form.validateFields(['tradingAs'])
    })}, 500), [])

    function onStoreNameChange(value) {
        if (isNewMerchant) return
        delayedQuery(value)
    }

    return (
        <div className={styles.InformationForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        { merchantType === 'new' &&
                            <div className={styles.InformationForm_inputContainer}>
                                <Form.Item
                                    label={translations().companyName}
                                    name={'companyName'}
                                    initialValue={getFieldInitialValue('companyName')}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const regex = VALID_NAME_PATTERN
                                        const value = e.currentTarget.value
                                        const test = regex.test(value)
                                        if (!test) {
                                            return form.getFieldValue('companyName')
                                        }
                                        const val = value.replace('&', ' and ').replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                        onCompanyNameChange(val)
                                        return val
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: translations().companyNameRequired
                                        },
                                        {
                                            pattern: VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES,
                                            message: translations().companyNameInvalid
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().companyName}/>
                                </Form.Item>
                            </div>
                        }
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().tradingAs}
                                name={'tradingAs'}
                                initialValue={getFieldInitialValue('tradingAs')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('tradingAs')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().tradingAsRequired
                                    },
                                    {
                                        validator(rule, value) {
                                            if (!isStoreNameValid) {
                                                return Promise.reject(new Error(translations().storeNameAlreadyInUse))
                                            }
                                            return Promise.resolve()
                                        }
                                    }
                                ]}
                                extra={translations().tradingAsExtraText}
                            >
                                <InputWithLoader
                                    placeholder={translations().tradingAs}
                                    loading={isStoreNameLoading}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (!e.target.value) {
                                            return
                                        }
                                        
                                        onStoreNameChange(e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    {merchantType === 'new' &&
                        <Col xs={24} md={10}>
                            <div className={styles.InformationForm_inputContainer}>
                                <Form.Item
                                    label={translations().vatNumber}
                                    name={'vatNumer'}
                                    initialValue={getFieldInitialValue('vatNumber')}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const regex = VALID_TEXT_PATTERN
                                        const value = e.currentTarget.value
                                        const test = regex.test(value)
                                        if (!test) {
                                        return form.getFieldValue('vatNumber')
                                    }
                                        return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                    }}
                                    rules={[
                                        {
                                            required: false,
                                            message: translations().vatNumberRequired
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().vatNumber}/>
                                </Form.Item>
                            </div>
                            {!isNewOrderForSoleTrader &&
                            <div className={styles.InformationForm_inputContainer}>
                                <Form.Item
                                    label={<LabelWithTooltip label={translations().companyNumber} tooltipText={configCountry === 'roi' ? translations().companyNumberROITooltip : translations().companyNumberUKTooltip} tooltipPosition={'right'}/>}
                                    name={'companyNumber'}
                                    initialValue={getFieldInitialValue('companyNumber')}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const regex = companyNumberPattern()[1] as RegExp
                                        const value = e.currentTarget.value
                                        const test = regex.test(value)
                                        if (!test) {
                                            return form.getFieldValue('companyNumber')
                                        }
                                        return value
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: translations().companyNumberRequired
                                        },
                                        {
                                            pattern: companyNumberPattern()[0] as RegExp,
                                            message: configCountry === 'roi' ? translations().companyNumberROIInvalid : translations().companyNumberUKInvalid
                                        }
                                    ]}
                                >
                                    <Input textTransform={'uppercase'}
                                              placeholder={translations().companyNumber}
                                              disabled={getFieldInitialValue('companyNumber')?.length >= companyNumberPattern()[2]}/>
                                </Form.Item>
                            </div>
                            }
                        </Col>
                    }
                </Row>
                <div className={styles.InformationForm_subgroupTitle}>
                    {translations().merchantAddress}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <CountrySelect
                            form={form}
                            initialValue={getFieldInitialValue('country')}
                            fieldName={'country'}
                            userCountry={configCountry}
                            countries={countries.slice()}
                            required={true}
                        />
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item shouldUpdate noStyle>
                                {() => {
                                    return (
                                        <Form.Item
                                            label={translations().postcode}
                                            name={'postcode'}
                                            initialValue={getFieldInitialValue('postcode')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: translations().postcodeRequired
                                                },
                                                {
                                                    validator: async (rule, value) => {
                                                        if (value && isUK() && !validatePostalCodeOfUK(value)) {
                                                            throw new Error(translations().postcodeInvalid)
                                                        }
                                                    }
                                                }
                                            ]}
                                        >
                                            <PostCode
                                                isUK={ isUK() }
                                                getPostCodeAddresses={ (postCode: string) => searchAddressesByPostcode(postCode) }
                                                onAddressSelect={ (a) => {
                                                    form.setFieldsValue({
                                                        addressLine1: a.line_1,
                                                        addressLine2: a.line_2,
                                                        addressLine3: a.line_3,
                                                        town: a.town_or_city,
                                                        county: a.county
                                                    })
                                                } }
                                                placeholder={translations().postcode}
                                            />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().addressLine1}
                                name={'addressLine1'}
                                initialValue={getFieldInitialValue('addressLine1')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine1')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().addressLine1Required
                                    }
                                ]}
                            >
                                <Input placeholder={translations().addressLine1}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().addressLine2}
                                name={'addressLine2'}
                                initialValue={getFieldInitialValue('addressLine2')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine2')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                            >
                                <Input placeholder={translations().addressLine2}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().addressLine3}
                                name={'addressLine3'}
                                initialValue={getFieldInitialValue('addressLine3')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine3')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                            >
                                <Input placeholder={translations().addressLine3}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().town}
                                name={'town'}
                                initialValue={getFieldInitialValue('town')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('town')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().townRequired
                                    },
                                    {
                                        pattern: VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES,
                                        message: translations().townInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().town}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().county}
                                name={'county'}
                                initialValue={getFieldInitialValue('county')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('county')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().countyRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().county}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().contactName}
                                name={'contactName'}
                                initialValue={getFieldInitialValue('contactName')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_WITHOUT_SPACE_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactName')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactNameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactName}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().contactSurname}
                                name={'contactSurname'}
                                initialValue={getFieldInitialValue('contactSurname')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_WITHOUT_SPACE_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactSurname')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactSurnameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactSurname}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().jobTitle}
                                name={'jobTitle'}
                                initialValue={getFieldInitialValue('jobTitle')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('town')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().jobTitleRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().jobTitle}/>
                            </Form.Item>
                        </div>
                        <div className={`${styles.InformationForm_inputContainer}`}>
                            <Form.Item
                                label={translations().email}
                                name={'email'}
                                initialValue={getFieldInitialValue('email')}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().emailRequired
                                    },
                                    {
                                        type: 'email',
                                        message: translations().emailInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().email} textTransform={'lowercase'}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().contactPhoneNumber}
                                name={'contactPhoneNumber'}
                                initialValue={getFieldInitialValue('contactPhoneNumber')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactPhoneNumber')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactPhoneNumberRequired
                                    },
                                    {
                                        pattern: PHONE_NUMBER_PATTERN,
                                        message: translations().contactPhoneNumberInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactPhoneNumber}/>
                            </Form.Item>
                        </div>
                        <div className={styles.InformationForm_inputContainer}>
                            <Form.Item
                                label={translations().contactMobilePhone}
                                name={'contactMobilePhone'}
                                initialValue={getFieldInitialValue('contactMobilePhone')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return form.getFieldValue('contactMobilePhone')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().contactMobilePhoneRequired
                                    },
                                    {
                                        pattern: PHONE_NUMBER_PATTERN,
                                        message: translations().contactMobilePhoneInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().contactMobilePhone}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
