import { DepartmentType } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/models/DepartmentType'

export interface AddDepartmentFormData {
    storeId: string // id of a store which this department belongs to
    departmentId: string
    departmentName: string
    departmentCurrency: number
    departmentCurrencyName: string
    departmentMerchantCategoryCode: number
    departmentMerchantCategoryCodeLabel: string
    departmentType: DepartmentType
    departmentStreamlineCompanyId: string
    departmentEnableCashBack: boolean
    departmentCashBack: number
    departmentEnableGratuity: boolean
    departmentGratuity: number
    departmentEnableRefund: boolean
    departmentRefund: number
    departmentEnablePurchase: boolean
    departmentPurchase: number
    departmentVisaMID: string
    departmentVisaDebitMID: string
    departmentAcquirer: number
    departmentAcquirerName: string
    departmentBankAccountNumber: string
    departmentSortCode: string
    departmentMastercardMID: string
    departmentMastercardDebitMID: string
    departmentMaestroDebitMID: string
    departmentAmexMerchantId: string
    departmentAmexAcquirer: number
    departmentAmexAcquirerName: string
    departmentAmexBankAccountNumber: string
    departmentAmexSortCode: string
    departmentDinersDiscoverMerchantId: string
    departmentDinersDiscoverAcquirer: number
    departmentDinersDiscoverAcquirerName: string
    departmentDinersDiscoverBankAccountNumber: string
    departmentDinersDiscoverSortCode: string
    departmentUnionPayMerchantId: string
    departmentUnionPayAcquirer: number
    departmentUnionPayAcquirerName: string
    departmentUnionPayBankAccountNumber: string
    departmentUnionPaySortCode: string
    departmentFlexecashMerchantId: string
    departmentFlexecashAcquirer: number
    departmentFlexecashAcquirerName: string
    departmentFlexecashBankAccountNumber: string
    departmentFlexecashSortCode: string
    departmentAcquirerAssignedTIDs: string[]
    departmentAccountVerification?: boolean
    departmentCashAdvance?: boolean
    departmentMoto?: boolean
    departmentPreAuth?: boolean
    preAuthorisation?: boolean
}

export function createAddDepartmentFormData(): AddDepartmentFormData {
    return {
        storeId: '',
        departmentId: '',
        departmentName: '',
        departmentCurrency: 0,
        departmentCurrencyName: '',
        departmentMerchantCategoryCode: 0,
        departmentMerchantCategoryCodeLabel: '',
        departmentType: 'general',
        departmentStreamlineCompanyId: '',
        departmentEnableCashBack: false,
        departmentCashBack: 0,
        departmentEnableGratuity: false,
        departmentGratuity: 0,
        departmentEnableRefund: false,
        departmentRefund: 0,
        departmentEnablePurchase: false,
        departmentPurchase: 0,
        departmentVisaMID: '',
        departmentVisaDebitMID: '',
        departmentAcquirer: 0,
        departmentAcquirerName: '',
        departmentBankAccountNumber: '',
        departmentSortCode: '',
        departmentMastercardMID: '',
        departmentMastercardDebitMID: '',
        departmentMaestroDebitMID: '',
        departmentAmexMerchantId: '',
        departmentAmexAcquirer: 0,
        departmentAmexAcquirerName: '',
        departmentAmexBankAccountNumber: '',
        departmentAmexSortCode: '',
        departmentDinersDiscoverMerchantId: '',
        departmentDinersDiscoverAcquirer: 0,
        departmentDinersDiscoverAcquirerName: '',
        departmentDinersDiscoverBankAccountNumber: '',
        departmentDinersDiscoverSortCode: '',
        departmentUnionPayMerchantId: '',
        departmentUnionPayAcquirer: 0,
        departmentUnionPayAcquirerName: '',
        departmentUnionPayBankAccountNumber: '',
        departmentUnionPaySortCode: '',
        departmentFlexecashMerchantId: '',
        departmentFlexecashAcquirer: 0,
        departmentFlexecashAcquirerName: '',
        departmentFlexecashBankAccountNumber: '',
        departmentFlexecashSortCode: '',
        departmentAcquirerAssignedTIDs: []
    }
}
