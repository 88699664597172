import translations from '~/code/ODIN/pages/DeviceManagementDetails/translations/translations'
import { Select } from 'antd'
import React from 'react'
import { PrioritySelectProps } from '~/code/ODIN/pages/DeviceManagementDetails/components/PrioritySelect/props'

export const PrioritySelect = ({ dataSource, value, onChange }: PrioritySelectProps) => {
    return <Select value={value} placeholder={translations().selectPriorityType} style={{ width: 200 }} onChange={(id) => onChange(id)}>
        {
            dataSource.map(priority => (
                <Select.Option key={priority.priorityId} value={priority.priorityId}>{priority.description}</Select.Option>
            ))
        }
    </Select>
}
