// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2DDasVnOzrZWodM-o8__xN {\n  margin-bottom: 16px; }\n\n._2lm6DdXZQXoJdEJ28dUZoB {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n  ._2lm6DdXZQXoJdEJ28dUZoB:hover {\n    cursor: pointer; }\n\n.ySSQbwe2CYiPKZj-mwUuD {\n  font-size: 24px;\n  height: 24px; }\n\n.b6Bxob_N5SMAGclhF6XdE {\n  color: #cc0000; }\n\n._1R4vR885awNEwM2_VpJpU_ {\n  color: #3CB371; }\n\n._1bPcadHSZQ0mB4BrsXRAL5 {\n  color: #00BFFF; }\n\n._16IeozWBPDGPpx1lIheNTj {\n  padding: 8px;\n  max-width: 30rem; }\n", ""]);
// Exports
exports.locals = {
	"listActions": "_2DDasVnOzrZWodM-o8__xN",
	"actions": "_2lm6DdXZQXoJdEJ28dUZoB",
	"icon": "ySSQbwe2CYiPKZj-mwUuD",
	"uninstallIcon": "b6Bxob_N5SMAGclhF6XdE",
	"launcherIcon": "_1R4vR885awNEwM2_VpJpU_",
	"setLauncherIcon": "_1bPcadHSZQ0mB4BrsXRAL5",
	"filter": "_16IeozWBPDGPpx1lIheNTj"
};
module.exports = exports;
