// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Kb7Gtl-rhc636AO8lSMZ5 {\n  margin-right: 8px; }\n\n._1jGhApa2hynjh81p5AjNa2 {\n  min-width: 200px; }\n\n._2MSygoAlYJ5voqaN6nVYXT {\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  margin-bottom: 5px;\n  color: #000000; }\n\n.jvptXoqpD4n7G-72KF62r {\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 12px;\n  line-height: 22px;\n  margin-bottom: 5px;\n  color: #8C8C8C; }\n\n.hJGacCOOcvR8HbilR_k04 {\n  word-break: break-word;\n  font-family: Open Sans, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 16px;\n  color: #8C8C8C; }\n\n._1-V7BwS-eS8bITlg5VWDhu {\n  margin-bottom: 16px; }\n\n._3nLlwAYoCrhXtbJ4bCooZV {\n  max-width: 400px; }\n\n.A9qtNAmSNLbrqQtMBNbtz {\n  width: 100%; }\n\n._3_LqWyO4rlZ7GWd8VpF7Uw {\n  height: 5rem;\n  width: 5rem; }\n\n.NvsMuZ9ZAgnZHxXCJM2t {\n  min-width: 150px; }\n", ""]);
// Exports
exports.locals = {
	"cancel": "_3Kb7Gtl-rhc636AO8lSMZ5",
	"deviceTypes": "_1jGhApa2hynjh81p5AjNa2",
	"header": "_2MSygoAlYJ5voqaN6nVYXT",
	"title": "jvptXoqpD4n7G-72KF62r",
	"subtitle": "hJGacCOOcvR8HbilR_k04",
	"actionList": "_1-V7BwS-eS8bITlg5VWDhu",
	"filterPanel": "_3nLlwAYoCrhXtbJ4bCooZV",
	"filter": "A9qtNAmSNLbrqQtMBNbtz",
	"image": "_3_LqWyO4rlZ7GWd8VpF7Uw",
	"filterItem": "NvsMuZ9ZAgnZHxXCJM2t"
};
module.exports = exports;
