import { action, makeObservable, observable } from 'mobx'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'
import { IAcquirerAssignedTidListStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/common/AcquirerAssignedTidList/IAcquirerAssignedTidListStore'
import { ProductBundle } from '~/code/POS/pages'

export class AcquirerAssignedTIDsStore implements IAcquirerAssignedTidListStore {
    private parentStore

    constructor (parentStore) {
        makeObservable(this, {
            acquirerAssignedTids: observable,
            totalTIDsList: observable,
            isTIDDuplicateExist: observable,
            setAcquirerAssignedTids: action,
            setTotalTIDsList: action,
            setIsTIDDuplicateExist: action
        })

        this.parentStore = parentStore
    }

    acquirerAssignedTids: AcquirerAssignedTid[] = []
    totalTIDsList: string[]
    isTIDDuplicateExist: boolean

    setAcquirerAssignedTids(acquirerAssignedTIDs: AcquirerAssignedTid[]) {
        this.acquirerAssignedTids = acquirerAssignedTIDs
    }

    setTotalTIDsList = (bundles: ProductBundle[]) => {
        const currentEnteredTIDs = this.acquirerAssignedTids.map(tid => tid.tid)

        this.totalTIDsList = bundles
            .reduce((acc, val) => {
                const savedTIDs = val.acquirerAssignedTids.map(tid => tid.tid)

                return acc.concat(savedTIDs)
            }, [])
            .concat(currentEnteredTIDs)
    }

    setIsTIDDuplicateExist = (isTIDDuplicateExist: boolean) => {
        this.isTIDDuplicateExist = isTIDDuplicateExist
    }
}
