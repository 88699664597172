import { persist } from 'mobx-persist'

export class MerchantSearchItem {
    @persist merchantId: number
    @persist ownerGroupId: number
    @persist merchantName: string
    @persist accountNumber: string
    @persist address: string
    @persist('map') storeNameMatches: string[]
}
