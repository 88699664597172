import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { fetchCountries } from '~/code/stores/NewOrderStore/services/fetchers'

export class CountriesStore {

    constructor () {
        makeObservable(this, {
            _countries: observable,
            _isLoading: observable,
            countries: computed,
            load: action
        })
    }

    _countries: SelectItem[] = []
    _isLoading: boolean = false

    get countries() {
        this.load()
        return this._countries
    }

    async load() {
        if (this._isLoading || !isEmpty(this._countries)) { return }
        this._isLoading = true
        this._countries = await fetchCountries()
        this._isLoading = false
    }
}
