import React from 'react'
import translations from './translations'
import { LinkLabel } from '~/code/common/components/LinkLabel/LinkLabel'

translations.add('en', {
    userGuide: 'User Guide',
    userGuideDescriptionText: (link: string) => {
        return <>
            In order to view the User Guide, please click on <LinkLabel onClick={() => window.open(link, 'blank')}>this link</LinkLabel>
        </>
    }
})
