import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    emailInvalid: string
    enterEmail: string
    enterName: string
    addDeveloper: string
    nameRequired: string
    emailRequired: string
}>()
