import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    cancel: string
    initiated: string
    appName: string
    requestedBy: string
    notSent: string
    sent: string
    delivered: string
    errored: string
    all: string
    deliveryStatusName: string
    errorDetails: string
    notificationType: string
}>()
