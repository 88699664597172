import React from 'react'
import { AppStore, AppStoreContext } from '~/code/AppStore'
import { observer } from 'mobx-react'
import { PageTitle } from '~/code/common/components/PageTitle'
import '~/assets/styles/main.scss'
import { Router } from '~/code/startup'

const App = observer(({ store }: { store: typeof AppStore }) => {
    const Layout = observer((props) => store.currentLayout(props))
    const Page = observer(() => store.currentPage())
    Router(store)

    return <>
        <AppStoreContext.Provider value={store}>
            <PageTitle store={store}/>
            <Layout>
                <Page/>
            </Layout>
        </AppStoreContext.Provider>
    </>
})

export { App }
