// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Vw5Od6FX4sJa5NKx7lY1f {\n  color: #00A0DC;\n  border-bottom: 1px dashed #00A0DC;\n  cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"LinkLabel": "Vw5Od6FX4sJa5NKx7lY1f"
};
module.exports = exports;
