import { createMerchant, Merchant } from '~/code/POS/pages/NewOrder/models/partner/Merchant'
import { createDeliveryDetails, DeliveryDetails } from '~/code/POS/pages/NewOrder/models/partner/DeliveryDetails'
import { PartnerStore } from '~/code/POS/pages/NewOrder/models/partner/PartnerStore'
import { ActivationDetails, createActivationDetails } from '~/code/POS/pages/NewOrder/models/partner/ActivationDetails'

export interface PartnerMerchant {
    existingMerchantId?: number
    newMerchant?: Merchant
    deliveryDetails: DeliveryDetails
    stores: PartnerStore[]
    activationDetails: ActivationDetails
}

export function createPartnerMerchant(): PartnerMerchant {
    return {
        existingMerchantId: 0,
        newMerchant: createMerchant(),
        deliveryDetails: createDeliveryDetails(),
        stores: [],
        activationDetails: createActivationDetails()
    }
}
