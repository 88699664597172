// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5BP1nN5WSbqkdVYjtOvvV {\n  padding-bottom: 20px; }\n  ._5BP1nN5WSbqkdVYjtOvvV ._3ZFZV0ZSdvDTt86czIXmFR {\n    min-height: 73.1333px;\n    display: flex;\n    align-items: flex-end;\n    padding-top: 38px; }\n", ""]);
// Exports
exports.locals = {
	"AddDepartmentForm": "_5BP1nN5WSbqkdVYjtOvvV",
	"checkBoxContainer": "_3ZFZV0ZSdvDTt86czIXmFR"
};
module.exports = exports;
