export interface OrderDetailsFormData {
    poNumber: string
    brandingOrganisationId: number
    requestedDeliveryAndOrActivationDate: string
    useMainContact: boolean
    companyName: string
    firstName: string
    surname: string
    email: string
}

export function createOrderDetailsFormData(): OrderDetailsFormData {
    return {
        poNumber: '',
        brandingOrganisationId: 0,
        requestedDeliveryAndOrActivationDate: '',
        useMainContact: false,
        companyName: '',
        firstName: '',
        surname: '',
        email: ''
    }
}
