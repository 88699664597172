// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3umgoNwcx7vereIh7zGuTQ {\n  width: 100%; }\n  .QjeShuN0xaOR7lwy9gShO {\n    padding-top: 16px; }\n  ._3jjYf4RlWwwmE5nMLviAuN {\n    padding-top: 16px;\n    display: flex;\n    flex-flow: row-reverse; }\n  ._2GuXTFWIu_ENSMWgv4pxdL {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #000000;\n    padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"MerchantDetailsForm": "_3umgoNwcx7vereIh7zGuTQ",
	"MerchantDetailsForm_goBackButtonContainer": "QjeShuN0xaOR7lwy9gShO",
	"MerchantDetailsForm_submitButtonContainer": "_3jjYf4RlWwwmE5nMLviAuN",
	"MerchantDetailsForm_subgroupTitle": "_2GuXTFWIu_ENSMWgv4pxdL"
};
module.exports = exports;
