import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Descriptions, Modal, Row, Table, Tabs, Typography, Collapse } from 'antd'
import { Button } from '~/code/common/components'
import { PaymentChannelType } from '~/code/ODIN/pages/DeviceManagementDetails/models/PaymentChannelType'
import { CommunicationMediaTypes } from '~/code/ODIN/pages/MerchantSchedule/DeviceStatusSchedule/models/CommunicationMediaTypes'
import { SystemInfoModalProps } from './props'
import translations from './translations'
import styles from './styles.scss'

const {Text} = Typography
const {Panel} = Collapse

export const SystemInfoModal = observer((props: SystemInfoModalProps) => {
    const {store, title, deviceId} = props

    useEffect(() => {
        store.systemInfoStore.loadSystemInfo(deviceId)
        store.paymentsStore.loadMidAndTid(deviceId)
        store.communicationDetailsStore.loadCommunicationDetails(deviceId)
        store.apnDetailsStore.loadApnDetails(deviceId)
    }, [])

    const midTidColumns = [
        {
            title: translations().deptId,
            dataIndex: 'merchantDepartmentId',
            key: 'merchantDepartmentId'
        },
        {
            title: translations().cardScheme,
            dataIndex: 'cardSchemeName',
            key: 'cardSchemeName'
        },
        {
            title: translations().acquirer,
            dataIndex: 'acquirerName',
            key: 'acquirerName'
        },
        {
            title: translations().currency,
            dataIndex: 'currencyName',
            key: 'currencyName'
        },
        {
            title: translations().MID,
            dataIndex: 'mid',
            key: 'mid'
        },
        {
            title: translations().TID,
            dataIndex: 'tid',
            key: 'tid'
        }
    ]

    const renderApnDetails = (mediaTypeId) => {
        const currentApn = store.apnDetailsStore.apnDetails
            .filter(apnDetail => apnDetail.isCurrentApn)
        const otherApn = store.apnDetailsStore.apnDetails
            .filter(apnDetail => !apnDetail.isCurrentApn)

        if (mediaTypeId !== CommunicationMediaTypes.cellular) {
            return null
        }

        if (store.apnDetailsStore.apnDetails.length === 0) {
            return (
                <p>{translations().noAPNsConfigured}</p>
            )
        }

        return (
            <>
                <Descriptions
                    size="small"
                    column={1}
                >
                    {
                        currentApn.length > 0 ?
                            currentApn.map((apnDetail) => (
                                <>
                                    <p className={styles.inUseApnTitle}>{translations().inUseAPNDetails}</p>
                                    <Descriptions.Item label={<Text type={'secondary'}>{translations().apn}</Text>}>
                                        <Text strong>{apnDetail.apn}</Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<Text type={'secondary'}>{translations().apnName}</Text>}>
                                        <Text strong>{apnDetail.apnName}</Text>
                                    </Descriptions.Item>
                                </>
                            )) : (
                                <>
                                    <p className={styles.inUseApnTitle}>{translations().inUseAPNDetails}</p>
                                    <Descriptions.Item label={<Text type={'secondary'}>{translations().apn}</Text>}>
                                        <Text strong>N/A</Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<Text type={'secondary'}>{translations().apnName}</Text>}>
                                        <Text strong>N/A</Text>
                                    </Descriptions.Item>
                                </>
                            )

                    }
                </Descriptions>
                {
                    otherApn.length > 0 && (
                        <Collapse className={styles.collapse} ghost>
                            <Panel header={translations().additionalAPNDetails} key="1">
                                {
                                    otherApn.map((apnDetail) => (
                                        <Descriptions
                                            size="small"
                                            column={1}
                                        >
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().apn}</Text>}>
                                                <Text strong>{apnDetail.apn}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().apnName}</Text>}>
                                                <Text strong>{apnDetail.apnName}</Text>
                                            </Descriptions.Item>
                                            <br/>
                                        </Descriptions>
                                    ))
                                }
                            </Panel>
                        </Collapse>
                    )
                }
            </>
        )
    }

    return <>
        <Modal
            title={title}
            visible={store.isSystemInfoModalOpen}
            onOk={() => store.setIsSystemInfoModalOpen(false)}
            onCancel={() => store.setIsSystemInfoModalOpen(false)}
            width={1000}
            footer={[
                <Button key="back" className={styles.backButton} onClick={() => store.setIsSystemInfoModalOpen(false)}>
                    {translations().cancel}
                </Button>
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={16}>
                    <Descriptions
                        size="small"
                        column={1}
                    >
                        {store.systemInfoStore.systemInfo.partNumber && (
                            <Descriptions.Item label={<Text type={'secondary'}>{translations().partNumber}</Text>}>
                                <Text strong>{store.systemInfoStore.systemInfo.partNumber}</Text>
                            </Descriptions.Item>
                        )}
                        {store.systemInfoStore.systemInfo.androidVersion && (
                            <Descriptions.Item label={<Text type={'secondary'}>{translations().androidVersion}</Text>}>
                                <Text strong>{store.systemInfoStore.systemInfo.androidVersion}</Text>
                            </Descriptions.Item>
                        )}
                        {store.systemInfoStore.systemInfo.operatingSystemLanguage && (
                            <Descriptions.Item label={<Text type={'secondary'}>{translations().operatingSystemLanguage}</Text>}>
                                <Text strong>{store.systemInfoStore.systemInfo.operatingSystemLanguage}</Text>
                            </Descriptions.Item>
                        )}
                        {store.systemInfoStore.systemInfo.timeZone && (
                            <Descriptions.Item label={<Text type={'secondary'}>{translations().timeZone}</Text>}>
                                <Text strong>{store.systemInfoStore.systemInfo.timeZone}</Text>
                            </Descriptions.Item>
                        )}
                        {store.systemInfoStore.systemInfo.paymentChannelId && (
                            <Descriptions.Item label={<Text type={'secondary'}>{translations().paymentChannelId}</Text>}>
                                <Text strong>{store.systemInfoStore.systemInfo.paymentChannelId}</Text>
                            </Descriptions.Item>
                        )}
                    </Descriptions>

                    <br/>

                    <Tabs defaultActiveKey={String(PaymentChannelType.Visa)}>
                        <Tabs.TabPane tab={translations().payments} key={PaymentChannelType.Visa}>
                            <Table
                                loading={store.paymentsStore.isLoading}
                                columns={midTidColumns}
                                dataSource={store.paymentsStore.midAndTid}
                                pagination={{
                                    total: store.paymentsStore.totalCount,
                                    current: store.paymentsStore.pageNumber,
                                    pageSize: store.paymentsStore.pageSize,
                                    onChange: (pageNumber) => {
                                        store.paymentsStore.loadMidAndTid(deviceId, pageNumber)
                                    }
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
                <Col xs={24} sm={8}>
                    <Tabs defaultActiveKey={String(CommunicationMediaTypes.wifi)}>
                        {store.communicationDetailsStore.communicationDetails?.map(detail => {
                            return (
                                <Tabs.TabPane tab={detail.mediaTypeName} key={detail.mediaTypeId}>
                                    <>
                                        <Descriptions
                                            size="small"
                                            column={1}
                                        >
                                            {detail.mediaTypeName && detail.mediaStatusName && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{detail.mediaTypeName}</Text>}>
                                                    <Text strong>{detail.mediaStatusName}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.ipAddress && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().ipAddress}</Text>}>
                                                    <Text strong>{detail.ipAddress}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.subnet && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().subnet}</Text>}>
                                                    <Text strong>{detail.subnet}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.signalStrength && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().signalStrength}</Text>}>
                                                    <Text strong>{detail.signalStrength}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.macAddress && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().macAddress}</Text>}>
                                                    <Text strong>{detail.macAddress}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.gatewayAddress && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().gateway}</Text>}>
                                                    <Text strong>{detail.gatewayAddress}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.networkOperator && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().networkOperator}</Text>}>
                                                    <Text strong>{detail.networkOperator}</Text>
                                                </Descriptions.Item>
                                            )}
                                            {detail.simNumber && (
                                                <Descriptions.Item label={<Text type={'secondary'}>{translations().simNumber}</Text>}>
                                                    <Text strong>{detail.simNumber}</Text>
                                                </Descriptions.Item>
                                            )}
                                        </Descriptions>
                                        {renderApnDetails(detail.mediaTypeId)}
                                    </>
                                </Tabs.TabPane>
                            )
                        })}
                    </Tabs>
                </Col>
                <Col xs={24} sm={12}>

                </Col>
            </Row>
        </Modal>
    </>
})
