import React from 'react'
import { ListItemProps } from './props'
import styles from './ListItem.scss'

export const ListItem = ({title, description, children, record, onClick}: ListItemProps) => {
    return  <div className={styles.ListItem} onClick={() => onClick(record)}>
        <div className={styles.ListItem_wrapper}>
            {
                children || (
                    <>
                        <div className={styles.ListItem_title}>{title}</div>
                        <div className={styles.ListItem_description}>{description}</div>
                    </>
                )
            }
        </div>
    </div>
}
