import React from 'react'
import { render } from 'react-dom'

import { App } from './App'
import { AppStore } from './AppStore'
import './startup/Router'
import 'antd/dist/antd.css'

export const finishedLoading = () => {
    render(<App store={AppStore} />, document.getElementById('pos-ordering-content'))
}

