import translations from '../translations'

export interface OrderStatus {
    orderStatusId: string
    status: string
}

export const orderStatuses: OrderStatus[] = [
    {
        orderStatusId: '0',
        status: translations().any
    },
    {
        orderStatusId: '1',
        status: translations().failedValidationRound1
    },
    {
        orderStatusId: '2',
        status: translations().receivedAndPassedValidationRound1
    },
    {
        orderStatusId: '3',
        status: translations().passedValidationRound2
    },
    {
        orderStatusId: '4',
        status: translations().failedValidationRound2
    },
    {
        orderStatusId: '7',
        status: translations().dispatching
    },
    {
        orderStatusId: '8',
        status: translations().completed
    },
    {
        orderStatusId: '9',
        status: translations().duplicateOrder
    },
    {
        orderStatusId: '11',
        status: translations().partialSuccess
    },
    {
        orderStatusId: '12',
        status: translations().failed
    },
    {
        orderStatusId: '13',
        status: translations().reversed
    }
]