import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import classNames from 'classnames'
import { Avatar, Col, Form, Row, Table, Tooltip, Select as AntSelect } from 'antd'
import { FormInstance } from 'antd/es/form'
import { useForm } from 'antd/es/form/Form'
import { ColumnsType } from 'antd/es/table/Table'
import { FilterActions } from '~/code/common/components/FilterActions/FilterActions'
import { Input, ListActions, Select } from '~/code/common/components'
import { CategoryType } from '~/code/ODIN/pages/DeviceManagementDetails/models/CategoryType'
import { Package } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/Package'
import { DeleteOutlined, MobileOutlined, ReloadOutlined } from '@ant-design/icons'
import translations from './translations'
import { DeviceApplicationsProps } from './props'
import styles from './DeviceApplications.scss'

const {Option} = AntSelect

export const DeviceApplications = observer((
    {packagesStore, applicationManagementStore, deviceId, isRegistered, setPickedApp, appDeveloperStore, permissions, deviceDetails}: DeviceApplicationsProps
) => {
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
    const [form] = useForm()
    const formRef = React.createRef<FormInstance>()

    useEffect(() => {
        packagesStore.loadPackages(deviceId)
    }, [])

    const onFilterFinish = (values: any) => {
        packagesStore.setOptions(values)

        packagesStore.loadPackages(deviceId, 1)
        setIsFilterVisible(false)
    }

    const handleReset = () => {
        packagesStore.setOptions(null)
        packagesStore.loadPackages(deviceId, 1)
        formRef.current!.resetFields()
        setIsFilterVisible(false)
    }

    const canBeALauncher = (record) => {
        const {manufacturer} = deviceDetails

        if (record.canBeLauncherApplication && !record.isLauncherApplication && permissions.includes('odin.deviceManagement.device.launcher.request')) {
            switch (manufacturer) {
                case 'NexGo':
                    return false
                case 'NEXGO':
                    return false
                case 'Verifone':
                    return false
                default: return true
            }
        } else return false
    }

    const deviceInstalledAppColumns = [
        {
            title: translations().app,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) =>
                <div style={{width: 150}}><Avatar src={record.iconUrl}/>{` ${text} (${record.version})`}</div>
        },
        {
            title: translations().category,
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: translations().developer,
            dataIndex: 'developer',
            key: 'developer'
        },
        {
            title: translations().installTime,
            dataIndex: 'installedDateTime',
            key: 'installedDateTime',
            render: date => date ? moment(date).format('DD/MM/YY HH:mm:ss') : date
        },
        {
            title: translations().updateTime,
            dataIndex: 'updateDateTime',
            key: 'updateDateTime',
            render: date => date ? moment(date).format('DD/MM/YY HH:mm:ss') : date
        },
        {
            title: translations().action,
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            render: (text, record) => (
                <Row gutter={[8, 8]}>
                    {canBeALauncher(record) && renderSetAsLauncherAction(record)}
                    {record.isLauncherApplication && renderIsLauncherAction()}
                    {record.newestVersion && renderUpdateToNewestVersionAction(record)}
                    {(record.category !== CategoryType.Unknown && record.category !== CategoryType.SystemApp)
                        && permissions.includes('odin.deviceManagement.device.application.uninstall') && renderUninstallAction(record)}
                </Row>
            )
        }
    ] as ColumnsType<Package>

    const renderIsLauncherAction = () => (
        <Col>
            <div className={classNames(styles.actions)}>
                <Tooltip placement="top" title={translations().launcher}>
                    <MobileOutlined disabled={!isRegistered} size={14} className={classNames(styles.icon, styles.launcherIcon)} />
                </Tooltip>
            </div>
        </Col>
    )

    const renderSetAsLauncherAction = (record: Package) => (
        <Col>
            <div className={classNames(styles.actions)}>
                <Tooltip placement="top" title={translations().setAsLauncher}>
                    <MobileOutlined
                        disabled={!isRegistered}
                        onClick={() => {
                            setPickedApp(record)
                            applicationManagementStore.setIsSetAsLauncherModalOpen(true)
                        }}
                        size={14}
                        className={classNames(styles.icon, styles.setLauncherIcon)}
                    />
                </Tooltip>
            </div>
        </Col>
    )

    const renderUpdateToNewestVersionAction = (record: Package) => (
        <Col>
            <div className={classNames(styles.actions)}>
                {record.newestVersion !== record.version && (
                    <Tooltip placement="top" title={translations().updateToLatestVersion}>
                        <ReloadOutlined
                            disabled={!isRegistered}
                            onClick={() => {
                                applicationManagementStore.loadAvailablePackages(deviceId, {
                                    packageId: record.packageId,
                                    packageTypeId: record.packageTypeId
                                })
                                applicationManagementStore.setIsUpdateAppToLatestVersionModalOpen(true)
                                applicationManagementStore.setPackageId(record.packageId)
                            }}
                            size={14}
                            className={classNames(styles.icon, styles.setLauncherIcon)}
                        />
                    </Tooltip>
                )}
            </div>
        </Col>
    )

    const renderUninstallAction = (record: Package) => (
        <Col>
            <div className={classNames(styles.actions)}>
                <Tooltip placement="top" title={translations().uninstall}>
                    <DeleteOutlined
                        disabled={!isRegistered}
                        onClick={() => {
                            applicationManagementStore.setIsAppUninstallModalOpen(true)
                            setPickedApp(record)
                        }}
                        size={14}
                        className={classNames(styles.icon, styles.uninstallIcon)}
                    />
                </Tooltip>
            </div>
        </Col>
    )

    return (
        <>
            <ListActions
                pageInfo={{
                    from: packagesStore.packages.length ? packagesStore.pageNumber * packagesStore.pageSize - 9 : 0,
                    to: packagesStore.pageNumber * packagesStore.pageSize > packagesStore.totalCount
                        ? packagesStore.totalCount
                        : packagesStore.pageNumber * packagesStore.pageSize,
                    total: packagesStore.totalCount
                }}
                className={styles.listActions}
                currentTotalAmount={packagesStore.packages.length}
                onReloadClick={() => packagesStore.loadPackages(deviceId)}
                onGoNextPageClick={() => packagesStore.loadPackages(deviceId, packagesStore.pageNumber + 1)}
                onGoPrevPageClick={() => packagesStore.loadPackages(deviceId, packagesStore.pageNumber - 1)}
                isNextPageDisabled={packagesStore.pageNumber >= packagesStore.totalPageItems}
                isPrevPageDisabled={packagesStore.pageNumber <= 1}
                onGoFirstPageClick={() => packagesStore.loadPackages(deviceId, 1)}
                onGoLastPageClick={() => packagesStore.loadPackages(deviceId, packagesStore.totalPageItems)}
                isGoToFirstPageDisabled={packagesStore.pageNumber <= 1}
                isGoToLastPageDisabled={packagesStore.pageNumber >= packagesStore.totalPageItems}
                isFilterDisabled={false}
                isFilterVisible={isFilterVisible}
                onFilterClick={() => setIsFilterVisible(true)}
                filterContent={() => {
                    return <div className={styles.filter}>
                        <Form
                            ref={
                                formRef}
                            form={form}
                            onFinish={onFilterFinish}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item name={'Name'}>
                                        <Input placeholder={translations().appName}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'Category'}>
                                        <Input placeholder={translations().category}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'developer'}>
                                        <Select
                                            showSearch
                                            loading={appDeveloperStore.isLoading}
                                            selectItems={appDeveloperStore.developers}
                                            placeholder={translations().selectAppDeveloper}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {appDeveloperStore.developers?.map(developer => (
                                                <Option key={developer.value} value={developer.label}>{developer.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'CanBeLauncherApplication'}>
                                        <Select
                                            selectItems={[
                                                {
                                                    value: true,
                                                    label: translations().yes
                                                },
                                                {
                                                    value: false,
                                                    label: translations().no
                                                }
                                            ]}
                                            placeholder={translations().canBeALauncherApp}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item key={'placeRequest'}>
                                        <FilterActions onCancel={() => setIsFilterVisible(false)} onReset={() => handleReset()}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }}
            />
            <Table
                bordered
                loading={packagesStore.isLoading}
                dataSource={packagesStore.packages}
                columns={deviceInstalledAppColumns}
                pagination={false}
            />
        </>
    )
})
