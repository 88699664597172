import { IDeliveryDetailsFormStore, DeliveryDetailsFormData, createDeliveryDetailsFormData } from '~/code/POS/pages/NewOrder/components/partner/DeliveryDetailsForm'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { action, computed, makeObservable, observable } from 'mobx'
import { ConfigCountry, MerchantType } from '~/code/POS/pages/NewOrder/models/constants'
import { SelectItem } from '~/code/common/components'
import { AppStore } from '~/code/AppStore'

export class DeliveryDetailsPartnerStore implements IDeliveryDetailsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            deliveryDetailsFormData: observable,
            canGoBack: observable,
            merchantType: computed,
            configCountry: computed,
            countries: computed,
            setDeliveryDetailsFormData: action,
            goBack: action
        })

        this.deliveryDetailsFormData = createDeliveryDetailsFormData()
    }

    static stepName: string = 'deliveryDetails'

    deliveryDetailsFormData: DeliveryDetailsFormData

    canGoBack: boolean = true

    get merchantType(): MerchantType {
        return AppStore.newOrderStore?.placeOrderConfigStore?.merchantType
    }

    get configCountry(): ConfigCountry {
        return AppStore.newOrderStore?.placeOrderConfigStore?.country
    }

    get countries(): SelectItem[] {
        return AppStore.countriesStore?.countries
    }

    setDeliveryDetailsFormData = (data: DeliveryDetailsFormData) => {
        this.deliveryDetailsFormData = { ...this.deliveryDetailsFormData, ...data }
        this.formSubmitHandler?.handleFormSubmit(this.deliveryDetailsFormData, DeliveryDetailsPartnerStore.stepName)
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(DeliveryDetailsPartnerStore.stepName)
    }

    // TODO take out comments if the functionality is required
    // @action
    // setDeliverToMerchant(value: boolean) {
    //     this.deliveryDetailsFormData.addressLine1 = value ? this.merchantDetailsStore.merchantDetailsFormData.addressLine1 : ''
    //     this.deliveryDetailsFormData.addressLine2 = value ? this.merchantDetailsStore.merchantDetailsFormData.addressLine2 : ''
    //     this.deliveryDetailsFormData.town = value ? this.merchantDetailsStore.merchantDetailsFormData.town : ''
    //     this.deliveryDetailsFormData.county = value ? this.merchantDetailsStore.merchantDetailsFormData.county : ''
    //     this.deliveryDetailsFormData.country = value ? this.merchantDetailsStore.merchantDetailsFormData.country : 0
    //     this.deliveryDetailsFormData.postcode = value ? this.merchantDetailsStore.merchantDetailsFormData.postcode : ''
    //     this.deliveryDetailsFormData.tradingAs = value ? this.merchantDetailsStore.merchantDetailsFormData.tradingAs : ''
    //     this.deliveryDetailsFormData.contactName = value ? this.merchantDetailsStore.merchantDetailsFormData.contactName : ''
    //     this.deliveryDetailsFormData.contactSurname = value ? this.merchantDetailsStore.merchantDetailsFormData.contactName : ''
    //     this.deliveryDetailsFormData.jobTitle = value ? this.merchantDetailsStore.merchantDetailsFormData.jobTitle : ''
    //     this.deliveryDetailsFormData.email = value ? this.merchantDetailsStore.merchantDetailsFormData.email : ''
    //     this.deliveryDetailsFormData.contactPhoneNumber = value ? this.merchantDetailsStore.merchantDetailsFormData.contactPhoneNumber : ''
    //     this.deliveryDetailsFormData.contactMobilePhone = value ? this.merchantDetailsStore.merchantDetailsFormData.contactMobilePhone : ''
    // }
}
