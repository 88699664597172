import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'

export interface ProductBundle {
    bundleCode: string
    bundleDescription: string
    bundleQuantity: number
    acquirerAssignedTids?: AcquirerAssignedTid[]
}

export function createProductBundle(): ProductBundle {
    return {
        bundleCode: '',
        bundleDescription: '',
        bundleQuantity: 0
    }
}
