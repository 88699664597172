import { PostCode, PostCodeAddress, error as errorLog } from 'dna-common'
import { removeFullStops } from '~/code/services'
import { get } from 'back-connector'
const _ = undefined

export async function searchAddressesByPostcode(postCode): Promise<PostCode> {
    const headers = []
    headers.push(['Authorization', `Basic ${_GET_ADDRESS_API_KEY_}`])

    const { result, error } = await  get<PostCode>(`/dna-api/postcodes/${postCode}`, _,  headers)

    if (error) {
        errorLog(`FAILED: ${error.message}`)
        return null
    }

    result.addresses = result.addresses.map(a => {
        const obj = {}
        for (const [key, value] of Object.entries(a)) {
            if (typeof value === 'string') {
                obj[key] = removeFullStops(value)
            } else if (Array.isArray(value)) {
                obj[key] = value.map(v => removeFullStops(v))
            }
        }
        return obj as PostCodeAddress
    })

    return result
}
