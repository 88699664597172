// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3WDyWbz_dVZZo-FErkAlsg {\n  width: 100%; }\n  ._3WDyWbz_dVZZo-FErkAlsg .ant-picker-input {\n    height: 50px;\n    background: #FFFFFF;\n    color: #000;\n    font-family: \"Open Sans\"; }\n  ._3WDyWbz_dVZZo-FErkAlsg .ant-picker {\n    width: 100%;\n    border: 1px solid #BABABA;\n    border-radius: 4px; }\n    ._3WDyWbz_dVZZo-FErkAlsg .ant-picker:hover {\n      border: 1px solid #BABABA; }\n  ._3WDyWbz_dVZZo-FErkAlsg .ant-picker-large {\n    padding: 0px 11px; }\n  ._1zTjWleynP1Z4dk4w576r .ant-picker-input {\n    background: #EDEDED; }\n\n.ant-form-item-label {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 16px;\n  letter-spacing: 0.333333px;\n  color: #8C8C8C;\n  text-transform: none;\n  padding-bottom: 5px; }\n  .ant-form-item-label label {\n    color: #8C8C8C; }\n", ""]);
// Exports
exports.locals = {
	"DatePicker": "_3WDyWbz_dVZZo-FErkAlsg",
	"DatePicker__disabled": "_1zTjWleynP1Z4dk4w576r"
};
module.exports = exports;
