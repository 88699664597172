import React from 'react'
import translations from './translations'

translations.add('en', {
    cancel: 'Cancel',
    partNumber: 'Part Number',
    androidVersion: 'Android Version',
    operatingSystemLanguage: 'Operating System Language',
    timeZone: 'Time Zone',
    paymentChannelId: 'Payment Channel Id',
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'Amex',
    wifi: 'Wi-Fi',
    cellular: 'Cellular',
    deptId: 'Dept Id',
    cardScheme: 'Card Scheme',
    acquirer: 'Acquirer',
    currency: 'Currency',
    MID: 'MID',
    TID: 'TID',
    payments: 'Payments',
    communicationDetails: 'Communication Details',
    ipAddress: 'IP Address',
    subnet: 'Subnet',
    gateway: 'Gateway',
    signalStrength: 'Signal Strength',
    macAddress: 'MAC Address',
    networkOperator: 'Network Operator',
    simNumber: 'ICCID',
    apn: 'APN',
    apnName: 'APN Name',
    isCurrentApn: 'Is Current APN',
    inUseAPNDetails: 'In Use APN Details',
    additionalAPNDetails: 'Additional APN Details',
    noAPNsConfigured: 'No APNs configured'
})
