import React from 'react'
import localTranslations from './translations'
import { translationValues } from '../../../common/ProductListForm/components/AddProductForm/translations/translations.en'


localTranslations.add('en', {
    ...translationValues,
    showOnlyHardwareOnlyBundles: 'Show only hardware only bundles',
    customPrice: 'Custom price',
    otherDepartmentsContainVerifoneProduct: <>
        <p>All Verifone solutions must be added to one Merchant Department.  Please either:</p>
        <ul>
            <li>
                Add this bundle alongside the existing Verifone bundle or
            </li>
            <li>
                Remove the other bundle containing Verifone products before attempting to re-add this one.
            </li>
        </ul>
    </>,
    whenMixedOrderHardwareOnlyDeliveredToMerchant: 'When an order contains a mixture of "standard" items and "Hardware Only" items the hardware items will always be dispatched to the merchant address.',
    acquirerAssignedTid: 'Acquirer assigned TID',
    acquirerAssignedTidRequired: 'Acquirer assigned TID is required',
    invalidDNAPaymentsTIDLength: 'DNA Payments TID must be 8 digits',
    invalidDNAPaymentsTIDDuplicate: 'DNA Payments TID is duplicate',
    acquirerIssuedTid: 'Acquirer Issued TID',
    acquirerIssuedTidRequired: 'Acquirer issued TID is required'
})
