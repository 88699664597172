import { DepartmentType } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/models/DepartmentType'

export interface SDDepartment { // Stores and Departments Department
    storeId?: string
    id?: string
    isNew?: boolean
    name: string
    currencyId?: number
    currencyName?: string
    merchantCategoryCode?: number
    merchantCategoryCodeLabel?: string
    streamlineCompanyId?: string
    cashBackAccepted?: boolean
    cashBack?: number
    gratuityAccepted?: boolean
    gratuity?: number
    refundAccepted?: boolean
    refund?: number
    purchaseAccepted?: boolean
    purchase?: number
    visaMID?: string
    visaDebitMID?: string
    mastercardMID?: string
    mastercardDebitMID?: string
    maestroDebitMID?: string
    acquirer?: number
    acquirerName?: string
    bankAccountNumber?: string
    sortCode?: string
    amexMerchantID?: string
    amexAcquirer?: number
    amexAcquirerName?: string
    amexBankAccountNumber?: string
    amexSortCode?: string
    unionPayMerchantID?: string
    unionPayAcquirer?: number
    unionPayAcquirerName?: string
    unionPayBankAccountNumber?: string
    unionPaySortCode?: string
    dinersDiscoverMerchantID?: string
    dinersDiscoverAcquirer?: number
    dinersDiscoverAcquirerName?: string
    dinersDiscoverBankAccountNumber?: string
    dinersDiscoverSortCode?: string
    flexecashMerchantID?: string
    flexecashAcquirer?: number
    flexecashAcquirerName?: string
    flexecashBankAccountNumber?: string
    flexecashSortCode?: string

    amexCpcMid?: string,
    dinersMid?: string,
    discoverMid?: string,
    flexecashMid?: string,
    jcbMid?: string,
    maestroMid?: string,
    // mastercardMid?: string,
    mastercardOneMid?: string,
    // mastercardDebitMid?: string,
    // visaMid?: string,
    visaAtmMid?: string,
    visaCpcMiMid?: string,
    visaCpcVatMid?: string,
    // visaDebitMid?: string,
    visaElectronMid?: string,
    ukMaestroMid?: string,
    unionPayMid?: string,
    unionPayAmexMid?: string,
    unionPayDinersMid?: string,
    unionPayJcbMid?: string,
    unionPayMaestroMid?: string,
    unionPayMastercardMid?: string,
    unionPayVisaMid?: string,
    visaPurchasingMid?: string,
    nectarMid?: string,
    creationMid?: string,
    aliPayMid?: string,
    departmentType?: DepartmentType,
    acquirerAssignedTIDs: string[]

    accountVerification?: boolean
    cashAdvance?: boolean
    moto?: boolean
    preAuth?: boolean
    acquiresWithDna?: boolean
    acquiresWithDnaEcommerce?: boolean
    preAuthorisation?: boolean
}

export function createSDDepartment(): SDDepartment {
    return {
        storeId: '',
        id: '',
        isNew: true,
        name: '',
        currencyId: 0,
        currencyName: '',
        merchantCategoryCode: 0,
        merchantCategoryCodeLabel: '',
        streamlineCompanyId: '',
        cashBackAccepted: false,
        cashBack: 0,
        gratuityAccepted: false,
        gratuity: 0,
        refundAccepted: false,
        refund: 0,
        purchaseAccepted: false,
        purchase: 0,
        visaMID: '',
        visaDebitMID: '',
        mastercardMID: '',
        mastercardDebitMID: '',
        maestroDebitMID: '',
        acquirer: 0,
        acquirerName: '',
        bankAccountNumber: '',
        sortCode: '',
        amexMerchantID: '',
        amexAcquirer: 0,
        amexAcquirerName: '',
        amexBankAccountNumber: '',
        amexSortCode: '',
        unionPayMerchantID: '',
        unionPayAcquirer: 0,
        unionPayAcquirerName: '',
        unionPayBankAccountNumber: '',
        unionPaySortCode: '',
        dinersDiscoverMerchantID: '',
        dinersDiscoverAcquirer: 0,
        dinersDiscoverAcquirerName: '',
        dinersDiscoverBankAccountNumber: '',
        dinersDiscoverSortCode: '',
        flexecashMerchantID: '',
        flexecashAcquirer: 0,
        flexecashAcquirerName: '',
        flexecashBankAccountNumber: '',
        flexecashSortCode: '',
        departmentType: 'general',
        acquirerAssignedTIDs: []
    }
}
