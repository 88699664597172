import { InformationFormData } from '~/code/POS/pages/NewOrder/components/iso/InformationForm/models'
import { createOrder, Order } from '~/code/POS/pages/NewOrder/models/Order/Order'
import { DeliveryAddressFormData } from '~/code/POS/pages/NewOrder/components/iso/DeliveryAddressForm/models'
import { createPlaceOrderRequest, PlaceOrderRequest } from '~/code/stores/NewOrderStore/models/PlaceOrderRequest'
import { removeEmptyFields } from '~/code/services'
import { ProcessingDetailsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/models'
import { ProductsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/models'
import { createInformationFormData } from '~/code/POS/pages/NewOrder/components/iso/InformationForm/models/InformationFormData'
import { createDeliveryAddressFormData } from '~/code/POS/pages/NewOrder/components/iso/DeliveryAddressForm/models/DeliveryAddressFormData'
import { createProcessingDetailsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/models/ProcessingDetailsFormData'
import { createProductsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/models/ProductsFormData'
import { OrderTypeFormData } from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/models/OrderTypeFormData'
import { ROI_ID } from '~/code/models/Constants'
import { removeRedundantCharsFromCompanyNum } from '~/code/stores/NewOrderStore/services/utils'
import { isEmpty } from 'dna-common'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'

// TODO this mapper should be where the store is

export function createPlaceOrderRequestFromData(information: InformationFormData,
                                        orderTypeFormData: OrderTypeFormData,
                                        deliveryAddress: DeliveryAddressFormData,
                                        processingDetails: ProcessingDetailsFormData,
                                        products: ProductsFormData,
                                        internalIdentifier: string,
                                        isoCustomerId: string,
                                        orderReference: string,
                                        merchantId: string,
                                        steps: any[],
                                        acquirerAssignedTids: AcquirerAssignedTid[]
) { // TODO specify the type
    const request = createPlaceOrderRequest()
    const order = createOrder()
    request.orders.push(order)

    steps.forEach(step => {
        switch (step.key) {
            case 'orderType': {
                orderTypeToOrder(order, orderTypeFormData)
                break
            }
            case 'information': {
                informationToOrder(order, information)
                break
            }
            case 'deliveryAddress': {
                deliveryAddressToPlaceOrderRequest(request, deliveryAddress)
                break
            }
            case 'processingDetails': {
                processingDetailsToOrder(order, processingDetails)
                break
            }
            case 'products': {
                productsToOrder(order, products)
                break
            }
        }
    })

    if (!isEmpty(merchantId)) {
        order.existingMerchantId = Number(merchantId)
    }

    if (!isEmpty(acquirerAssignedTids)) {
        order.acquirerAssignedTids = acquirerAssignedTids?.map(({tid}) => tid)
    }

    request.internalIdentifier = internalIdentifier?.trim()
    request.isoCustomerId = isoCustomerId?.trim()
    request.orderReference = orderReference?.trim()

    removeEmptyFields(request)
    return request
}

export function informationToOrder (order: Order, information: InformationFormData) {
    if (!order) {
        order = createOrder()
    }
    if (!information) {
        return order
    }

    information.companyName && (order.merchant.name = information.companyName.trim())
    information.companyNumber && (order.merchant.companyRegistrationNumber = information.companyNumber.trim()) // information.companyNumber.trim()
    order.merchant.storeName = information.tradingAs?.trim()

    order.merchant.contact.firstName = information.contactName?.trim()
    order.merchant.contact.surname = information.contactSurname?.trim()
    order.merchant.contact.telephoneNumber = information.contactPhoneNumber?.trim()
    order.merchant.contact.mobileNumber = information.contactMobilePhone?.trim()
    order.merchant.contact.email = information.email?.trim()

    order.address.line1 = information.addressLine1?.trim()
    order.address.line2 = information.addressLine2?.trim()
    order.address.line3 = information.addressLine3?.trim()
    order.address.townOrCity = information.town?.trim()
    order.address.county = information.county?.trim()
    order.address.countryCode = Number(information.country)
    order.address.postcode = information.postcode?.trim()

    return order
}

export function orderTypeToOrder (order: Order, orderTypeFormData: OrderTypeFormData) {
    if (!order) {
        order = createOrder()
    }
    if (!orderTypeFormData) {
        return order
    }

    order.existingStoreId = orderTypeFormData.storeId || null
    order.existingDepartmentId = orderTypeFormData.departmentId || null

    return order
}

export function placeOrderRequestToInformation (request: PlaceOrderRequest) {
    const information = createInformationFormData()

    const order = request?.orders[0]
    if (!order){
        return information
    }

    const configCountry = order.address?.countryCode === ROI_ID ? 'roi' : 'uk'

    information.companyName = order.merchant?.name?.trim()
    information.companyNumber = removeRedundantCharsFromCompanyNum(order.merchant?.companyRegistrationNumber, configCountry)
    information.tradingAs = order.merchant?.storeName?.trim()

    information.contactName = order.merchant?.contact?.firstName?.trim()
    information.contactSurname = order.merchant?.contact?.surname?.trim()
    information.contactPhoneNumber = order.merchant?.contact?.telephoneNumber?.trim()
    information.contactMobilePhone = order.merchant?.contact?.mobileNumber?.trim()
    information.email = order.merchant?.contact?.email?.trim()

    information.addressLine1 = order.address?.line1?.trim()
    information.addressLine2 = order.address?.line2?.trim()
    information.addressLine3 = order.address?.line3?.trim()
    information.town = order.address?.townOrCity?.trim()
    information.county = order.address?.county?.trim()
    information.country = order.address?.countryCode
    information.postcode = order.address?.postcode?.trim()

    return information
}

export function deliveryAddressToPlaceOrderRequest(request: PlaceOrderRequest, deliveryAddress: DeliveryAddressFormData) {
    if (!request) {
        request = createPlaceOrderRequest()
    }
    if (!deliveryAddress) {
        return request
    }

    if (isEmpty(request.orders)) {
        request.orders.push(createOrder())
    }

    request.orders[0].deliveryDetails.deliverToStore = !!deliveryAddress.useMerchantAddress
    request.orders[0].deliveryDetails.instructions = deliveryAddress.deliveryInstructions?.trim()

    if (deliveryAddress.useMerchantAddress) {
        request.deliveryAddress = null
        return request
    }

    request.deliveryAddress.line1 = deliveryAddress.addressLine1?.trim()
    request.deliveryAddress.line2 = deliveryAddress.addressLine2?.trim()
    request.deliveryAddress.townOrCity = deliveryAddress.town?.trim()
    request.deliveryAddress.county = deliveryAddress.county?.trim()
    request.deliveryAddress.postcode = deliveryAddress.postcode?.trim()
    request.deliveryAddress.countryCode = Number(deliveryAddress.country)
    request.deliveryAddress.deliveryName = deliveryAddress.recipientName?.trim()

    return request
}

export function placeOrderRequestToDeliveryAddress (request: PlaceOrderRequest) {
    const deliveryAddress = createDeliveryAddressFormData()

    if (!request) {
        return deliveryAddress
    }
    const order = request.orders[0]

    deliveryAddress.useMerchantAddress = !!order?.deliveryDetails?.deliverToStore
    deliveryAddress.deliveryInstructions = order?.deliveryDetails?.instructions?.trim()

    if (deliveryAddress.useMerchantAddress) {
        return deliveryAddress
    }

    const requestAddress = request.deliveryAddress

    deliveryAddress.addressLine1 = requestAddress?.line1?.trim()
    deliveryAddress.addressLine2 = requestAddress?.line2?.trim()
    deliveryAddress.town = requestAddress?.townOrCity?.trim()
    deliveryAddress.county = requestAddress?.county?.trim()
    deliveryAddress.postcode = requestAddress?.postcode?.trim()
    deliveryAddress.country = requestAddress?.countryCode
    deliveryAddress.recipientName = requestAddress?.deliveryName?.trim()

    return deliveryAddress
}

export function processingDetailsToOrder(order: Order, processingDetails: ProcessingDetailsFormData) {
    if (!order) {
        order = createOrder()
    }
    if (!processingDetails) {
        return order
    }

    order.departmentDetails.name = processingDetails.accountName?.trim()
    order.departmentDetails.merchantCategoryCode = processingDetails.merchantCategoryCode
    order.departmentDetails.streamlineCompanyId = processingDetails.streamlineCompanyId.toString()?.trim()
    order.departmentDetails.currencyId = processingDetails.currency
    order.brandingOrganizationId = processingDetails.brandingOrganisationId
    order.departmentDetails.maxCashback = processingDetails.cashBack ? processingDetails.cashBack : 0
    order.departmentDetails.maxGratuity = processingDetails.gratuity ? processingDetails.gratuity : 0

    order.departmentDetails.visaMasterCard.mid = processingDetails.merchantId?.trim()
    order.departmentDetails.visaMasterCard.acquirerId = Number(processingDetails?.acquirer)
    order.departmentDetails.visaMasterCard.accountNumber = processingDetails.bankAccountNumber?.trim()
    order.departmentDetails.visaMasterCard.sortCode = processingDetails.sortCode?.trim()

    order.departmentDetails.submissionId = Number(processingDetails.preferredSubmission)

    order.departmentDetails.preAuthorisation = processingDetails.preAuthorisation

    if (processingDetails.amexMerchantId){
        order.departmentDetails.amex.acquirerId = processingDetails.amexAcquirer?.toString().trim()
        order.departmentDetails.amex.mid = processingDetails.amexMerchantId?.toString().trim()
        order.departmentDetails.amex.accountNumber = processingDetails.amexBankAccountNumber?.toString().trim()
        order.departmentDetails.amex.sortCode = processingDetails.amexSortCode?.toString().trim()
    } else {
        order.departmentDetails.amex = null
    }

    return order
}

export function placeOrderRequestToProcessingDetails(request: PlaceOrderRequest) {
    const processingDetails = createProcessingDetailsFormData()
    const order = request.orders[0]
    if (!request || !order) {
        return processingDetails
    }

    processingDetails.accountName = order.departmentDetails?.name?.trim()
    processingDetails.merchantCategoryCode = order.departmentDetails?.merchantCategoryCode
    processingDetails.streamlineCompanyId = order.departmentDetails?.streamlineCompanyId ? Number(order.departmentDetails?.streamlineCompanyId) : 0
    processingDetails.currency = order.departmentDetails?.currencyId
    processingDetails.brandingOrganisationId = order.brandingOrganizationId
    processingDetails.cashBack = order.departmentDetails?.maxCashback
    processingDetails.gratuity = order.departmentDetails?.maxGratuity
    processingDetails.isCashBackChecked = order.departmentDetails?.maxCashback > 0
    processingDetails.isGratuityChecked = order.departmentDetails?.maxGratuity > 0

    processingDetails.merchantId = order.departmentDetails?.visaMasterCard?.mid
    processingDetails.acquirer = order.departmentDetails?.visaMasterCard?.acquirerId?.toString()
    processingDetails.bankAccountNumber = order.departmentDetails?.visaMasterCard?.accountNumber
    processingDetails.sortCode = order.departmentDetails?.visaMasterCard?.sortCode

    processingDetails.preferredSubmission = order.departmentDetails?.submissionId?.toString()

    if (order.departmentDetails?.amex){
        processingDetails.amexAcquirer = order.departmentDetails.amex.acquirerId
        processingDetails.amexMerchantId = order.departmentDetails.amex.mid
        processingDetails.amexBankAccountNumber = order.departmentDetails.amex.accountNumber
        processingDetails.amexSortCode = order.departmentDetails.amex.sortCode
    }

    return processingDetails
}

export function productsToOrder(order: Order, products: ProductsFormData) {
    if (!order) {
        order = createOrder()
    }
    if (!products) {
        return order
    }

    order.productDetails.bundleId = products.bundleCode?.trim()
    order.productDetails.quantity = Number(products.quantity)

    return order
}

export function placeOrderRequestToProducts(request: PlaceOrderRequest) {
    const products = createProductsFormData()
    const order = request.orders[0]
    if (!request || !order) {
        return products
    }

    products.bundleCode = order.productDetails?.bundleId
    products.quantity = order.productDetails?.quantity

    return products
}
