import React, { Component, forwardRef, LegacyRef } from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'
import styles from './DatePicker.scss'

const DatePicker = forwardRef((props: DatePickerProps = {}, forwardedRef: LegacyRef<Component>) => {
    return (
        <div className={ styles.DatePicker } >
            <AntDatePicker {...props} ref={forwardedRef}/>
        </div>
    )
})

export { DatePicker }
