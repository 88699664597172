import React from 'react'
import translations from './translations'

translations.add('en', {
    bundle: 'Bundle',
    description: 'Description',
    quantity: 'Quantity',
    edit: 'Edit',
    delete: 'Delete',
    acquirerIssuedTid: 'Acquirer Issued TIDs'
})
