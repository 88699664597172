import React from 'react'
import { observer } from 'mobx-react'
import { Checkbox, Col, Form, Row } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, SummaryBlock, ButtonWithConfirmation } from '~/code/common/components'
import translations from './translations'
import { OrderSummaryProps } from './props'
import styles from './OrderSummary.scss'

export const OrderSummary = observer((props: OrderSummaryProps) => {
    const { store } = props
    const companyName = store.userInfo.userType === 'employee' ?
        store.selectedEmployeeCompany?.additionalInfo?.companyRawName || '123Send' : store.userInfo?.companyName

    const [form] = Form.useForm()

    const onFinish = (values) => {
        store.placeOrder()
    }

    return (
        <div className={styles.OrderSummary}>
            <div className={styles.header}>
                {translations().orderSummary}
            </div>
            { store.orderSummaryData.map((item, index) => {
                return <SummaryBlock data={item} key={index}/>
            })}
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Form.Item
                    name={'agreeWithTerms'}
                    valuePropName={'checked'}
                    rules={[
                        {
                            required: true,
                            transform: checked => (checked || undefined),
                            type: 'boolean',
                            message: store.orderSummaryCheckError
                        }
                    ]}
                >
                    <Checkbox className={styles.agreeWithTermsLabel}>
                        {store.orderSummaryCheckText}
                    </Checkbox>
                </Form.Item>
                <Row gutter={16}>
                    <Col xs={18} lg={19}>
                        <Row>
                            <Col xs={24} sm={12}>
                                <Form.Item  key={'goBack'}>
                                    <Button outline={'outlined'} onClick={() => store.goBack()}>
                                        <ArrowLeftOutlined />{translations().back}
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <div className={styles.cancelOrderButtonContainer}>
                                    <ButtonWithConfirmation
                                        type={'button'}
                                        statusType={'danger'}
                                        popConfirmTitle={ translations().cancelOrderConfirmText(store.orderReference) }
                                        confirmButtonTitle={ translations().confirmCancelOrder }
                                        cancelButtonTitle={ translations().cancelCancelOrder }
                                        onConfirm={ () => store.clearAndSetUp() }
                                    >
                                        <><DeleteOutlined /> {translations().cancelOrder} </>
                                    </ButtonWithConfirmation>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} lg={5}>
                        <div className={styles.submitButtonContainer}>
                            <Form.Item  key={'placeOrder'}>
                                <Button htmlType={'submit'}>
                                    {translations().placeOrder}<ArrowRightOutlined />
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
})
