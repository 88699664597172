import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Spin, Form, Modal } from 'antd'
import { LabelWithTooltip } from '~/code/common/components/LabelWithTooltip/LabelWithTooltip'
import { Select, ErrorMessage, Checkbox, GroupTitle, Button, Input, Drawer, MIDInput, InputWithLoader } from '~/code/common/components'
import { UK_CURRENCY_NUMBER_CODE } from '~/code/models'
import { getFieldValue, validateVisaMID, validateDecimals, ACQUIRER_IDS } from '~/code/services'
import { BANK_ACCOUNT_NUMBER_PATTERN, BANK_ACCOUNT_NUMBER_TYPING_PATTERN, SORT_CODE_PATTERN, SORT_CODE_TYPING_PATTERN, STREAMLINE_COMPANY_ID_PATTERN, STREAMLINE_COMPANY_ID_TYPING_PATTERN, TWO_DECIMAL_POINTS_NUMBER_PATTERN, VALID_NAME_PATTERN } from '~/code/models/Patterns'
import { digitsToSortCode } from '~/code/POS/pages/NewOrder/services/common/processing-utils'
import { AcquirerDetails, MIDWarningModal } from './components'
import { DepartmentType, WORLDPAY_ID } from './models'
import translations from './translations'
import { AddDepartmentFormProps } from './props'
import formStyles from '~/assets/styles/form.scss'
import styles from './AddDepartmentForm.scss'
import _ from 'lodash'
import { isEmpty } from 'dna-common'

export const AddDepartmentForm = observer( ({ store, onClose, shouldShowVerifoneProperties }: AddDepartmentFormProps) => {
    const {
        addDepartmentFormData: data,
        setAddDepartmentFormData,
        isFormVisible,
        countries,
        currencies,
        acquirers,
        merchantCategoryCodes,
        isDepartmentNameLoading,
        getDepartments,
        isDepartmentNameValid,
        isNewMerchant
    } = store
    const [form] = Form.useForm()

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const [noCardSchemeSpecified, setNoCardSchemeSpecified] = useState(false)
    const [departmentType, setDepartmentType] = useState(getFieldInitialValue('departmentType') || 'general')
    const [filteredAcquirers, setFilteredAcquirers] = useState(acquirers)
    const [isCashBackChecked, setCashBackChecked] = useState(data.departmentEnableCashBack)
    const [isGratuityChecked, setGratuityChecked] = useState(data.departmentEnableGratuity)
    const [isRefundChecked, setRefundChecked] = useState(data.departmentEnableRefund)
    const [isPurchaseChecked, setPurchaseChecked] = useState(data.departmentEnablePurchase || true)
    const [departmentBankAccountNumber, setDepartmentBankAccountNumber] = useState(data.departmentBankAccountNumber || '')
    const [departmentSortCode, setDepartmentSortCode] = useState(data.departmentSortCode || '')

    const [visaMIDHelp, setVisaMIDHelp] = useState(validateVisaMID(data.departmentVisaMID, data.departmentAcquirer))
    const [visaMIDValidateStatus, setVisaMIDValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(visaMIDHelp) ? undefined : 'warning')

    const [visaDebitMIDHelp, setVisaDebitMIDHelp] = useState(validateVisaMID(data.departmentVisaDebitMID, data.departmentAcquirer))
    const [visaDebitMIDValidateStatus, setVisaDebitMIDValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(visaDebitMIDHelp) ? undefined : 'warning')

    const [mastercardMIDHelp, setMastercardMIDHelp] = useState(validateVisaMID(data.departmentMastercardMID, data.departmentAcquirer))
    const [mastercardMIDValidateStatus, setMastercardMIDValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(mastercardMIDHelp) ? undefined : 'warning')

    const [mastercardDebitMIDHelp, setMastercardDebitMIDHelp] = useState(validateVisaMID(data.departmentMastercardDebitMID, data.departmentAcquirer))
    const [mastercardDebitMIDValidateStatus, setMastercardDebitMIDValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(mastercardDebitMIDHelp) ? undefined : 'warning')

    const [maestroDebitMIDHelp, setMaestroDebitMIDHelp] = useState(validateVisaMID(data.departmentMaestroDebitMID, data.departmentAcquirer))
    const [maestroDebitMIDValidateStatus, setMaestroDebitMIDValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(maestroDebitMIDHelp) ? undefined : 'warning')

    const [isMIDWarningVisible, setMIDWarningVisible] = useState(false)
    const [hasAgreedToContinueWithGivenMIDs, setHasAgreedToContinueWithGivenMIDs] = useState(false)
    const [filledMIDs, setFilledMIDs] = useState([])

    const setMIDStatus = (_mid: string, setMIDHelp, setMIDValidateStatus) => {
        const warning = validateVisaMID(_mid, form.getFieldValue('departmentAcquirer'))
        setMIDHelp(warning)
        setMIDValidateStatus(isEmpty(warning) ? undefined : 'warning')
    }


    const departmentTypes = [
        {
            label: translations().general,
            value: 'general'
        },
        {
            label: translations().eCommerce,
            value: 'eCommerce'
        }
    ]


    let amexDefaultValue = !isEmpty(acquirers.slice()) ? 4 : null
    const merchantCategoryCodeInitialValue = isEmpty(merchantCategoryCodes) ? null : getFieldInitialValue('departmentMerchantCategoryCode')
    const currencyInitialValue = isEmpty(currencies) ? null : ( getFieldInitialValue('departmentCurrency') || UK_CURRENCY_NUMBER_CODE )
    const departmentTypeInitialValue: DepartmentType = getFieldInitialValue('departmentType') || 'general'

    useEffect(() => {
        acquirers.slice()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            departmentCurrency: currencyInitialValue
        })
    }, [currencyInitialValue])

    // set acquirer's initial value when the list of acquirers is fetched
    useEffect(() => {
        let acquirerInitialValue = getFieldInitialValue('departmentAcquirer')
        acquirerInitialValue = isEmpty(acquirers) || isEmpty(acquirerInitialValue) ? null : Number(acquirerInitialValue)
        amexDefaultValue = !isEmpty(acquirers.slice()) ? 4 : null
        form.setFieldsValue({
            departmentAcquirer: acquirerInitialValue,
            departmentAmexAcquirer: getFieldInitialValue('departmentAmexAcquirer', amexDefaultValue)
        })
    }, [acquirers])

    useEffect(() => {
        form.resetFields()
    }, [data?.departmentId])

    useEffect(() => {
        if (departmentType === 'general') {
            setFilteredAcquirers(acquirers.filter((acquirer) => acquirer.value !== ACQUIRER_IDS.DNA_PAYMENTS_ECOM))
        }

        if (departmentType === 'eCommerce') {
            setFilteredAcquirers(acquirers.filter((acquirer) => acquirer.value !== ACQUIRER_IDS.DNA_PAYMENTS))
        }
    }, [departmentType, acquirers])

    const fieldChecked = (fieldName: string) => {
        form.resetFields([fieldName])
        form.setFieldsValue({[fieldName]: ''})
    }

    const onCashBackCheck = (checked: boolean) => {
        fieldChecked('departmentCashBack')
        setCashBackChecked(checked)
    }

    const onGratuityCheck = (checked: boolean) => {
        fieldChecked('departmentGratuity')
        setGratuityChecked(checked)
    }

    const onRefundCheck = (checked: boolean) => {
        fieldChecked('departmentRefund')
        setRefundChecked(checked)
    }

    const onPurchaseCheck = (checked: boolean) => {
        fieldChecked('departmentPurchase')
        setPurchaseChecked(checked)
    }

    const onAcquirerChange = () => {
        form.resetFields(['departmentStreamlineCompanyId'])
    }

    const onAcquirerSelect = () => {
        setMIDFieldsStatuses()
    }

    const onDepartmentBankAccountNumberChange = (event) => {
        const value = event.target.value
        if (value?.length < 9){
            setDepartmentBankAccountNumber(value)
        }
    }

    const onDepartmentSortCodeChange = (event) => {
        setDepartmentSortCode(event.target.value)
    }

    const setMIDFieldsStatuses = () => {
        setMIDStatus(form.getFieldValue('departmentVisaMID'), setVisaMIDHelp, setVisaMIDValidateStatus)
        setMIDStatus(form.getFieldValue('departmentVisaDebitMID'), setVisaDebitMIDHelp, setVisaDebitMIDValidateStatus)
        setMIDStatus(form.getFieldValue('departmentMastercardMID'), setMastercardMIDHelp, setMastercardMIDValidateStatus)
        setMIDStatus(form.getFieldValue('departmentMastercardDebitMID'), setMastercardDebitMIDHelp, setMastercardDebitMIDValidateStatus)
        setMIDStatus(form.getFieldValue('departmentMaestroDebitMID'), setMaestroDebitMIDHelp, setMaestroDebitMIDValidateStatus)
    }

    const onVisaMIDBlur = () => {
        const visaMIDValue = form.getFieldValue('departmentVisaMID')
        form.setFieldsValue({
            departmentVisaDebitMID: form.getFieldValue('departmentVisaDebitMID') || visaMIDValue,
            departmentMastercardMID: form.getFieldValue('departmentMastercardMID') || visaMIDValue,
            departmentMastercardDebitMID: form.getFieldValue('departmentMastercardDebitMID') || visaMIDValue,
            departmentMaestroDebitMID: form.getFieldValue('departmentMaestroDebitMID') || visaMIDValue
        })
        onMIDBlur()
        setMIDFieldsStatuses()
    }

    const onMIDBlur = () => {
        setNoCardSchemeSpecified(
            isEmpty(form.getFieldValue('departmentVisaMID')) &&
            isEmpty(form.getFieldValue('departmentVisaDebitMID')) &&
            isEmpty(form.getFieldValue('departmentMastercardMID')) &&
            isEmpty(form.getFieldValue('departmentMastercardDebitMID')) &&
            isEmpty(form.getFieldValue('departmentMaestroDebitMID'))
        )
    }

    const onFinish = (values) => {
        if (isDepartmentNameLoading) {
            return
        }

        if (isEmpty(values.departmentVisaMID) && isEmpty(values.departmentVisaDebitMID)
            && isEmpty(values.departmentMastercardMID) && isEmpty(values.departmentMastercardDebitMID)
            && isEmpty(values.departmentMaestroDebitMID)) {
            setNoCardSchemeSpecified(true)
            return
        } else {
            setNoCardSchemeSpecified(false)
        }

        /// If a user does not populate all of the MID boxes (which is the default):
        // Then a prompt should appear to the user which says:
        // “You have only requested that <chosen schemes> be configured for this order, are you sure?”
        // For example:
        // “You have only requested that Mastercard and Mastercard Debit be configured for this order, are you sure?”
        if (!hasAgreedToContinueWithGivenMIDs &&
            (isEmpty(values.departmentVisaMID) || isEmpty(values.departmentVisaDebitMID)
                || isEmpty(values.departmentMastercardMID) || isEmpty(values.departmentMastercardDebitMID)
                || isEmpty(values.departmentMaestroDebitMID))) {

            const mids = [
                !isEmpty(values.departmentVisaMID) ? translations().midNamesWithoutMID.visa : '',
                !isEmpty(values.departmentVisaDebitMID) ? translations().midNamesWithoutMID.visaDebit : '',
                !isEmpty(values.departmentMastercardMID) ? translations().midNamesWithoutMID.mastercard : '',
                !isEmpty(values.departmentMastercardDebitMID) ? translations().midNamesWithoutMID.mastercardDebit : '',
                !isEmpty(values.departmentMaestroDebitMID) ? translations().midNamesWithoutMID.maestroDebit : ''
            ].filter((mid) => mid !== '')
            setFilledMIDs(mids)

            setMIDWarningVisible(true)
            return
        }

        if (values.departmentSortCode.indexOf('-') >= 0 ) {
            values.departmentSortCode = values.departmentSortCode.replace(/-/g, '')
        }
        if (values.departmentAmexSortCode?.indexOf('-') >= 0 ) {
            values.departmentAmexSortCode = values.departmentAmexSortCode.replace(/-/g, '')
        }
        if (values.departmentDinersDiscoverSortCode?.indexOf('-') >= 0 ) {
            values.departmentDinersDiscoverSortCode = values.departmentDinersDiscoverSortCode.replace(/-/g, '')
        }
        if (values.departmentUnionPaySortCode?.indexOf('-') >= 0 ) {
            values.departmentUnionPaySortCode = values.departmentUnionPaySortCode.replace(/-/g, '')
        }
        if (values.departmentFlexecashSortCode?.indexOf('-') >= 0 ) {
            values.departmentFlexecashSortCode = values.departmentFlexecashSortCode.replace(/-/g, '')
        }

        if (isEmpty(values.departmentAmexMerchantId)) {
            values.departmentAmexAcquirer = undefined
        }

        values.storeId = data.storeId
        values.departmentId = data.departmentId
        values.storeCountryName = countries.find(item => item.value === values.storeCountry)?.label
        values.departmentMerchantCategoryCodeLabel = merchantCategoryCodes.find(item => item.value === values.departmentMerchantCategoryCode)?.label
        values.departmentCurrencyName = currencies.find(item => item.value === values.departmentCurrency)?.label
        values.departmentAcquirerName = acquirers.find(item => item.value === values.departmentAcquirer)?.label
        values.departmentAmexAcquirerName = acquirers.find(item => item.value === values.departmentAmexAcquirer)?.label
        values.departmentDinersDiscoverAcquirerName = acquirers.find(item => item.value === values.departmentDinersDiscoverAcquirer)?.label
        values.departmentFlexecashAcquirerName = acquirers.find(item => item.value === values.departmentFlexecashAcquirer)?.label

        setAddDepartmentFormData(values)
        clearForm()
        onDrawerClose()
    }

    const clearForm = () => {
        setPurchaseChecked(true)
        setGratuityChecked(false)
        setCashBackChecked(false)
        setRefundChecked(false)

        setMIDStatus('', setVisaMIDHelp, setVisaMIDValidateStatus)
        setMIDStatus('', setVisaDebitMIDHelp, setVisaDebitMIDValidateStatus)
        setMIDStatus('', setMastercardMIDHelp, setMastercardMIDValidateStatus)
        setMIDStatus('', setMastercardDebitMIDHelp, setMastercardDebitMIDValidateStatus)
        setMIDStatus('', setMaestroDebitMIDHelp, setMaestroDebitMIDValidateStatus)

        setHasAgreedToContinueWithGivenMIDs(false)
        setFilledMIDs([])

        form.resetFields()
    }

    const onDrawerClose = () => {
        onClose && onClose()
    }

    const delayedQuery = useCallback(_.debounce(q => {getDepartments(q).then(() => {
        form.validateFields(['departmentName'])
    })}, 500), [])

    function onDepartmentNameChange(value) {
        const regexForUUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
        if (isNewMerchant || regexForUUID.test(data.storeId)) {
            store.checkNewDepartmentStoreNames(value)
            form.validateFields(['departmentName'])
            return
        }
        delayedQuery(value)
    }

    const formInitialValues = {
        departmentName: getFieldInitialValue('departmentName'),
        departmentCurrency: currencyInitialValue,
        departmentMerchantCategoryCode: merchantCategoryCodeInitialValue,
        departmentType: departmentTypeInitialValue,
        departmentEnablePurchase: isPurchaseChecked,
        departmentPurchase: getFieldInitialValue('departmentPurchase'),
        departmentEnableRefund: isRefundChecked,
        departmentRefund: getFieldInitialValue('departmentRefund'),
        departmentEnableCashBack: isCashBackChecked,
        departmentCashBack: getFieldInitialValue('departmentCashBack'),
        departmentEnableGratuity: isGratuityChecked,
        departmentGratuity: getFieldInitialValue('departmentGratuity'),
        departmentAcquirer: getFieldInitialValue('departmentAcquirer'),
        departmentStreamlineCompanyId: getFieldInitialValue('departmentStreamlineCompanyId'),
        departmentBankAccountNumber: getFieldInitialValue('departmentBankAccountNumber'),
        departmentSortCode: digitsToSortCode(getFieldInitialValue('departmentSortCode')),
        departmentVisaMID: getFieldInitialValue('departmentVisaMID'),
        departmentVisaDebitMID: getFieldInitialValue('departmentVisaDebitMID'),
        departmentMastercardMID: getFieldInitialValue('departmentMastercardMID'),
        departmentMastercardDebitMID: getFieldInitialValue('departmentMastercardDebitMID'),
        departmentMaestroDebitMID: getFieldInitialValue('departmentMaestroDebitMID'),
        departmentAmexMerchantId: getFieldInitialValue('departmentAmexMerchantId'),
        departmentAmexAcquirer: getFieldInitialValue('departmentAmexAcquirer') || amexDefaultValue,
        departmentAmexBankAccountNumber: getFieldInitialValue('departmentAmexBankAccountNumber'),
        departmentAmexSortCode: getFieldInitialValue('departmentAmexSortCode'),
        departmentDinersDiscoverMerchantId: getFieldInitialValue('departmentDinersDiscoverMerchantId'),
        departmentDinersDiscoverAcquirer: getFieldInitialValue('departmentDinersDiscoverAcquirer'),
        departmentDinersDiscoverBankAccountNumber: getFieldInitialValue('departmentDinersDiscoverBankAccountNumber'),
        departmentDinersDiscoverSortCode: getFieldInitialValue('departmentDinersDiscoverSortCode'),
        departmentFlexecashMerchantId: getFieldInitialValue('departmentFlexecashMerchantId'),
        departmentFlexecashAcquirer: getFieldInitialValue('departmentFlexecashAcquirer'),
        departmentFlexecashBankAccountNumber: getFieldInitialValue('departmentFlexecashBankAccountNumber'),
        departmentFlexecashSortCode: getFieldInitialValue('departmentFlexecashSortCode'),
        departmentAcquirerAssignedTIDs: data.departmentAcquirerAssignedTIDs?.slice() || [],
        preAuthorisation: data.preAuthorisation || false
    }

    const handleMIDWarningCancel = () => {
        setMIDWarningVisible(false)
    }

    const handleMIDWarningOk = () => {
        setHasAgreedToContinueWithGivenMIDs(true)
        form.submit()
        setMIDWarningVisible(false)
    }

    return (
        <Drawer
            title={data.departmentId ? translations().editDepartment : translations().addDepartment}
            width={'50%'}
            onClose={onDrawerClose}
            visible={isFormVisible}
            shouldScrollToTopOnVisible={true}
        >
            <Form layout={'vertical'} form={form} onFinish={onFinish} className={styles.AddDepartmentForm}
                  scrollToFirstError={{ behavior: actions => {
                    actions.forEach(({ el, top }) => {
                        el.scrollTop = top - 50
                    })
                  }}}
                  initialValues={formInitialValues}
            >
                <Row>
                    <Col xs={24}>
                        <GroupTitle content={translations().department}/>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translations().departmentName}
                            name={'departmentName'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('departmentName')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().departmentNameRequired
                                },
                                {
                                    validator(rule, value) {
                                        if (!isDepartmentNameValid) {
                                            return Promise.reject(new Error(translations().departmentNameAlreadyInUse))
                                        }
                                        return Promise.resolve()
                                    }
                                }
                            ]}
                        >
                            <InputWithLoader
                                placeholder={translations().departmentName}
                                loading={isDepartmentNameLoading}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (!e.target.value) {
                                        return
                                    }

                                    onDepartmentNameChange(e.target.value)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<LabelWithTooltip label={translations().currency} tooltipText={translations().currencyTooltip} tooltipPosition={'right'} />}
                            name={'departmentCurrency'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().currencyRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().currency}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    notFoundContent={<Spin size={'small'}/>}
                                    selectItems={currencies.slice()}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={<LabelWithTooltip label={translations().merchantCategoryCode} tooltipText={translations().merchantCategoryCodeTooltip} tooltipPosition={'right'} />}
                            name={'departmentMerchantCategoryCode'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().merchantCategoryCodeRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().merchantCategoryCode}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    notFoundContent={<Spin size={'small'}/>}
                                    selectItems={merchantCategoryCodes.slice()}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().departmentType}
                            name={'departmentType'}
                        >
                            <Select placeholder={translations().departmentType}
                                    onChange={setDepartmentType}
                                    selectItems={departmentTypes}/>
                        </Form.Item>
                    </Col>
                </Row>
                <GroupTitle size={'small'} content={translations().cardSchemeDetails} />
                <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={ <LabelWithTooltip label={translations().acquirer} tooltipText={translations().acquirerTooltip} tooltipPosition={'right'} />}
                            name={'departmentAcquirer'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().acquirerRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().acquirer}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    notFoundContent={<Spin size={'small'}/>}
                                    onChange={onAcquirerChange}
                                    onSelect={onAcquirerSelect}
                                    selectItems={filteredAcquirers.slice()}/>
                        </Form.Item>
                        <Form.Item shouldUpdate noStyle>
                            {() => {
                                return (
                                    <Form.Item
                                        label={<LabelWithTooltip label={translations().streamlineCompanyId} tooltipText={translations().streamlineCompanyIdTooltip} tooltipPosition={'right'} />}
                                        name={'departmentStreamlineCompanyId'}
                                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                            const typingRegexp = STREAMLINE_COMPANY_ID_TYPING_PATTERN
                                            const value = e.currentTarget.value
                                            const test = typingRegexp.test(value)
                                            if (!test) {
                                                return form.getFieldValue('departmentStreamlineCompanyId')
                                            }
                                            return value
                                        }}
                                        rules={[
                                            {
                                                required: form.getFieldValue('departmentAcquirer') === WORLDPAY_ID,
                                                message: translations().streamlineCompanyIdRequired
                                            },
                                            {
                                                pattern: STREAMLINE_COMPANY_ID_PATTERN,
                                                message: translations().streamlineCompanyIdInvalid
                                            }
                                        ]}
                                    >
                                        <Input placeholder={translations().streamlineCompanyId} disabled={form.getFieldValue('departmentAcquirer') !== WORLDPAY_ID}/>
                                    </Form.Item>
                                )}
                            }
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={12}>
                                <Form.Item
                                    label={translations().accountNumber}
                                    name={'departmentBankAccountNumber'}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const phoneRegexp = BANK_ACCOUNT_NUMBER_TYPING_PATTERN
                                        const value = e.currentTarget.value
                                        const test = phoneRegexp.test(value)
                                        if (!test) {
                                            return form.getFieldValue('departmentBankAccountNumber')
                                        }
                                        return value
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: translations().accountNumberRequired
                                        },
                                        {
                                            pattern: BANK_ACCOUNT_NUMBER_PATTERN,
                                            message: translations().accountNumberInvalid
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().accountNumber} onBlur={onDepartmentBankAccountNumberChange}/>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label={translations().sortCode}
                                    name={'departmentSortCode'}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const phoneRegexp = SORT_CODE_TYPING_PATTERN
                                        const value = e.currentTarget.value.replace(/-/g, '')
                                        const test = phoneRegexp.test(value)
                                        if (!test) {
                                            return form.getFieldValue('departmentSortCode')
                                        }
                                        return value
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: translations().sortCodeRequired
                                        },
                                        {
                                            pattern: SORT_CODE_PATTERN,
                                            message: translations().sortCodeInvalid
                                        }
                                    ]}
                                >
                                    <Input type={'masked'} className={'ant-input'} mask={'99-99-99'} placeholder={translations().sortCode} onBlur={onDepartmentSortCodeChange}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={12}>
                        <MIDInput label={translations().visaMID}
                                  form={form}
                                  fieldName={'departmentVisaMID'}
                                  requiredMessage={translations().visaMIDRequired}
                                  midHelp={visaMIDHelp}
                                  midValidateStatus={visaMIDValidateStatus}
                                  setMIDStatus={(value) => setMIDStatus(value, setVisaMIDHelp, setVisaMIDValidateStatus)}
                                  onBlur={onVisaMIDBlur}/>

                        <MIDInput label={translations().visaDebitMID}
                                  form={form}
                                  fieldName={'departmentVisaDebitMID'}
                                  requiredMessage={translations().visaDebitMIDRequired}
                                  midHelp={visaDebitMIDHelp}
                                  midValidateStatus={visaDebitMIDValidateStatus}
                                  setMIDStatus={(value) => setMIDStatus(value, setVisaDebitMIDHelp, setVisaDebitMIDValidateStatus)}
                                  onBlur={onMIDBlur}/>

                        <MIDInput label={translations().mastercardMID}
                                  form={form}
                                  fieldName={'departmentMastercardMID'}
                                  requiredMessage={translations().mastercardMIDRequired}
                                  midHelp={mastercardMIDHelp}
                                  midValidateStatus={mastercardMIDValidateStatus}
                                  setMIDStatus={(value) => setMIDStatus(value, setMastercardMIDHelp, setMastercardMIDValidateStatus)}
                                  onBlur={onMIDBlur}/>

                        <MIDInput label={translations().mastercardDebitMID}
                                  form={form}
                                  fieldName={'departmentMastercardDebitMID'}
                                  requiredMessage={translations().mastercardDebitMIDRequired}
                                  midHelp={mastercardDebitMIDHelp}
                                  midValidateStatus={mastercardDebitMIDValidateStatus}
                                  setMIDStatus={(value) => setMIDStatus(value, setMastercardDebitMIDHelp, setMastercardDebitMIDValidateStatus)}
                                  onBlur={onMIDBlur}/>

                        <MIDInput label={translations().maestroDebitMID}
                                  form={form}
                                  fieldName={'departmentMaestroDebitMID'}
                                  requiredMessage={translations().maestroDebitMIDRequired}
                                  midHelp={maestroDebitMIDHelp}
                                  midValidateStatus={maestroDebitMIDValidateStatus}
                                  setMIDStatus={(value) => setMIDStatus(value, setMaestroDebitMIDHelp, setMaestroDebitMIDValidateStatus)}
                                  onBlur={onMIDBlur}/>

                        {
                            noCardSchemeSpecified && <ErrorMessage content={translations().noCardSchemeSpecified}/>
                        }
                    </Col>
                </Row>
                {isFormVisible && <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        {/* AMEX */}
                        <AcquirerDetails
                            store={store}
                            form={form}
                            groupTitle={ translations().americanExpress }
                            acquirerField={'departmentAmexAcquirer'}
                            isAcquirerSelectionDisabled={true}
                            bankAccountNumberField={'departmentAmexBankAccountNumber'}
                            merchantIdField={'departmentAmexMerchantId'}
                            sortCodeField={'departmentAmexSortCode'}
                            departmentBankAccountNumber={departmentBankAccountNumber}
                            departmentSortCode={departmentSortCode}
                            acquirerDefaultValue={amexDefaultValue}
                        />
                        {/* Diners Discover */}
                        <AcquirerDetails
                            store={store}
                            form={form}
                            groupTitle={ translations().dinersDiscover }
                            acquirerField={'departmentDinersDiscoverAcquirer'}
                            bankAccountNumberField={'departmentDinersDiscoverBankAccountNumber'}
                            merchantIdField={'departmentDinersDiscoverMerchantId'}
                            sortCodeField={'departmentDinersDiscoverSortCode'}
                            departmentBankAccountNumber={departmentBankAccountNumber}
                            departmentSortCode={departmentSortCode}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        {/* FLEX E CASH */}
                        <AcquirerDetails
                            store={store}
                            form={form}
                            groupTitle={ translations().flexecash }
                            acquirerField={'departmentFlexecashAcquirer'}
                            bankAccountNumberField={'departmentFlexecashBankAccountNumber'}
                            merchantIdField={'departmentFlexecashMerchantId'}
                            sortCodeField={'departmentFlexecashSortCode'}
                            departmentBankAccountNumber={departmentBankAccountNumber}
                            departmentSortCode={departmentSortCode}
                        />
                    </Col>
                </Row>}
                <GroupTitle size={'small'} content={translations().transactionTypes}/>
                <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.checkBoxContainer}>
                                    <Form.Item
                                        name={'departmentEnablePurchase'}
                                        valuePropName={'checked'}
                                        rules={[
                                            {
                                                transform: checked => (checked || undefined),
                                                type: 'boolean'
                                            }
                                        ]}
                                    >
                                        <Checkbox onChange={(e) => onPurchaseCheck(e.target.checked)}>
                                            {translations().enablePurchase}
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label={translations().purchase}
                                    name={'departmentPurchase'}
                                    rules={[
                                        {
                                            required: isPurchaseChecked,
                                            message: translations().purchaseRequired
                                        },
                                        {
                                            validator: (rule, value) => {
                                                return validateDecimals(value, form.getFieldValue('departmentCurrency'), translations().decimalPlacesError)
                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().purchase} disabled={!isPurchaseChecked}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.checkBoxContainer}>
                                    <Form.Item
                                        name={'departmentEnableRefund'}
                                        valuePropName={'checked'}
                                        rules={[
                                            {
                                                transform: checked => (checked || undefined),
                                                type: 'boolean'
                                            }
                                        ]}
                                    >
                                        <Checkbox onChange={(e) => onRefundCheck(e.target.checked)}>
                                            {translations().enableRefund}
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label={translations().refund}
                                    name={'departmentRefund'}
                                    rules={[
                                        {
                                            required: isRefundChecked,
                                            message: translations().refundRequired
                                        },
                                        {
                                            validator: (rule, value) => {
                                                return validateDecimals(value, form.getFieldValue('departmentCurrency'), translations().decimalPlacesError)
                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().refund} disabled={!isRefundChecked}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.checkBoxContainer}>
                                    <Form.Item
                                        name={'departmentEnableCashBack'}
                                        valuePropName={'checked'}
                                        rules={[
                                            {
                                                transform: checked => (checked || undefined),
                                                type: 'boolean'
                                            }
                                        ]}
                                    >
                                        <Checkbox onChange={(e) => onCashBackCheck(e.target.checked)}>
                                            {translations().enableCashBack}
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label={translations().cashBack}
                                    name={'departmentCashBack'}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = e.currentTarget.value
                                        let tempValue = value.replace(/ /g, '')
                                        if (value.includes('.')) {
                                            const length = tempValue.length < tempValue.indexOf('.') + 3 ? tempValue.length : tempValue.indexOf('.') + 3
                                            tempValue = tempValue.substr(0, length)
                                        }
                                        const convertedValue = Number(tempValue)
                                        if (isNaN(convertedValue)) {
                                            return form.getFieldValue('departmentCashBack')
                                        }
                                        if ((!isNaN(convertedValue) && (tempValue.endsWith('.') || tempValue.endsWith('.0') || tempValue.endsWith('.00')) )) {
                                            return tempValue
                                        }
                                        return convertedValue
                                    }}
                                    rules={[
                                        {
                                            required: isCashBackChecked,
                                            message: translations().cashBackRequired
                                        },
                                        {
                                            pattern: TWO_DECIMAL_POINTS_NUMBER_PATTERN,
                                            message: translations().cashBackInvalid
                                        },
                                        {
                                            validator: (rule, value) => {
                                                if (value && !isNaN(value) && value > 100) {
                                                    return Promise.reject(new Error(translations().cashBackMaxExceeded))
                                                }

                                                return validateDecimals(value, form.getFieldValue('departmentCurrency'), translations().decimalPlacesError)
                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().cashBack} disabled={!isCashBackChecked}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.checkBoxContainer}>
                                    <Form.Item
                                        name={'departmentEnableGratuity'}
                                        valuePropName={'checked'}
                                        rules={[
                                            {
                                                transform: checked => (checked || undefined),
                                                type: 'boolean'
                                            }
                                        ]}
                                    >
                                        <Checkbox onChange={(e) => onGratuityCheck(e.target.checked)}>
                                            {translations().enableGratuity}
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label={translations().gratuity}
                                    name={'departmentGratuity'}
                                    rules={[
                                        {
                                            required: isGratuityChecked,
                                            message: translations().gratuityRequired
                                        },
                                        {
                                            validator: (rule, value) => {
                                                return validateDecimals(value, form.getFieldValue('departmentCurrency'), translations().decimalPlacesError)
                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().gratuity} disabled={!isGratuityChecked}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*<Form.Item noStyle={true} shouldUpdate={true}>*/}
                {/*    {() => {*/}
                {/*        if (form.getFieldValue('departmentAcquirer') === ACQUIRER_IDS.DNA_PAYMENTS ) {*/}
                {/*            return (*/}
                {/*                <Row>*/}
                {/*                    <Col>*/}
                {/*                        <Form.Item name={'preAuthorisation'} valuePropName={'checked'}*/}
                {/*                        >*/}
                {/*                            <Checkbox>{translations().preathorization}</Checkbox>*/}
                {/*                        </Form.Item>*/}
                {/*                    </Col>*/}
                {/*                </Row>*/}
                {/*            )*/}
                {/*        } else return <></>*/}
                {/*    }}*/}
                {/*</Form.Item>*/}
                {
                    shouldShowVerifoneProperties &&
                    <>
                        <GroupTitle size={'small'} content={translations().departmentVerifoneTransactionTypes}/>
                        <Row gutter={16}>
                            <Col xs={12}>
                                <Form.Item
                                    name={'departmentAccountVerification'}
                                    initialValue={data.departmentAccountVerification}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox>
                                        {translations().departmentAccountVerification}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    name={'departmentCashAdvance'}
                                    initialValue={data.departmentCashAdvance}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox>
                                        {translations().departmentCashAdvance}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    name={'departmentMoto'}
                                    initialValue={data.departmentMoto}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox>
                                        {translations().departmentMoto}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    name={'departmentPreAuth'}
                                    initialValue={data.departmentPreAuth}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox>
                                        {translations().departmentPreAuth}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                }
                <div className={formStyles.buttonsContainer}>
                    <div className={formStyles.cancelButtonContainer}>
                        <Button outline={'outlined'} onClick={onDrawerClose}>
                            {translations().cancel}
                        </Button>
                    </div>
                    <div className={formStyles.submitButtonContainer}>
                        <Button className={null} htmlType={'submit'}>
                            { data.departmentId ? translations().save : translations().add }
                        </Button>
                    </div>
                </div>
            </Form>
            <MIDWarningModal
                mids={filledMIDs}
                visible={isMIDWarningVisible}
                onCancel={handleMIDWarningCancel}
                onOk={handleMIDWarningOk}
            />
        </Drawer>
    )
})
