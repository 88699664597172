// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._14jxXquyY3Z2nSuhNcllAF {\n  margin-right: 8px; }\n\n._3CaRH7IkuODadXqIjGyqPw {\n  margin-right: 8px; }\n", ""]);
// Exports
exports.locals = {
	"search": "_14jxXquyY3Z2nSuhNcllAF",
	"cancel": "_3CaRH7IkuODadXqIjGyqPw"
};
module.exports = exports;
