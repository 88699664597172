import { SDDepartment } from '~/code/POS/pages'

export interface SDStore { // Stores and Departments Store
    id?: string
    name: string
    companyRegistrationNumber?: string
    vatNumber?: string
    telephoneNumber?: string
    email?: string
    country?: number
    countryName?: string
    postcode: string
    addressLine1: string
    addressLine2: string
    addressLine3?: string
    town?: string
    county?: string
    isNew?: boolean
    departments?: SDDepartment[]
    isLoading?: boolean
    useAxeptConnectCloud: boolean
}

export function createSDStore(): SDStore {
    return {
        id: '',
        name: '',
        companyRegistrationNumber: '',
        vatNumber: '',
        telephoneNumber: '',
        email: '',
        country: 0,
        countryName: '',
        postcode: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        isNew: true,
        departments: [],
        isLoading: false,
        useAxeptConnectCloud: false
    }
}
