import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Avatar, Col, Form, List, message, notification, Row, Select as AntSelect, TreeSelect as AntTreeSelect, Typography, Upload } from 'antd'
import { runInAction } from 'mobx'
import { isEmpty, log } from 'dna-common'
import { CloudUploadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Input, TreeSelect } from '~/code/common/components'
import { AsyncSelect } from '~/code/common/components/AsyncSelect/AsyncSelect'
import { FileUploadModal } from '~/code/ODIN/components/FileUploadModal/FileUploadModal'
import { getFileUploadPackageType } from '~/code/ODIN/pages/ApplicationManagementDetails/utils/getFileUploadPackageType'
import { APPLICATION_MAX_FILE_SIZE, DEFAULT_NOTIFICATION_DURATION } from '~/code/ODIN/models'
import { VALID_URL_PATTERN, ALPHABETIC_NUMERIC_PATTERN_WITH_DOT_AND_DASH } from '~/code/models'
import { AddNewDeveloper } from '~/code/ODIN/pages/ApplicationManagement/components/AddNewDeveloper/AddNewDeveloper'
import { getBase64, getFieldInitialValue } from '~/code/services'
import translations from './translations'
import { AddNewApplicationProps } from './props'
import styles from './AddNewApplication.scss'

const {Title} = Typography
const {Option} = AntSelect
const {SHOW_ALL} = AntTreeSelect

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/svg' || file.type === 'image/png'
    const isLt500KB = file.size / 1024 / 1024 < 0.5

    let img
    let isMaxPixelSizeValid = false

    img = new Image()
    const objectUrl = window.URL.createObjectURL(file)

    img.onload = function () {
        if (this.width < 100 || this.height < 100) {
            isMaxPixelSizeValid = true
        }

        window.URL.revokeObjectURL(objectUrl)

        if (!isJpgOrPng) {
            message.error(translations().youCanUploadOnlySvgOrPng)
        }

        if (!isLt500KB) {
            message.error(translations().imageMemoryRestriction)
        }

        if (!isMaxPixelSizeValid) {
            message.error(translations().imageMaxPixelSizeRestriction)
        }

        return isJpgOrPng && isLt500KB && isMaxPixelSizeValid
    }
    img.src = objectUrl
}

const getFormInitialValues = data => {
    if (!data) {
        return null
    }

    return {
        DisplayName: getFieldInitialValue(data, 'displayName'),
        PackageName: getFieldInitialValue(data, 'packageName'),
        Categories: getFieldInitialValue(data, 'categories').map(item => item.packageCategoryId),
        VersionCode: getFieldInitialValue(data, 'versionCode'),
        VersionNumber: getFieldInitialValue(data, 'versionNumber'),
        PackageDeveloperId: getFieldInitialValue(data, 'developerId'),
        DocumentationUrl: getFieldInitialValue(data, 'documentationUrl'),
        DeviceManufacturerId: getFieldInitialValue(data, 'deviceManufacturerId'),
        SupportedDeviceTypes: getFieldInitialValue(data, 'deviceTypes').map(item => item.deviceTypeId),
        PackageIcon: getFieldInitialValue(data, 'packageIcon')
    }
}

export const AddNewApplication = observer((
    {store, setIsAddNewApp, applicationDetails: data, packageType}: AddNewApplicationProps
) => {
    const {appCategoriesStore, deviceManufacturerStore, deviceTypesStore, appDevelopersStore, uploadNewApp, isLoading} = store
    const [form] = Form.useForm()
    const [isAddNewDeveloper, setIsAddNewDeveloper] = useState(false)
    const [imageUrl, setImageUrl] = useState<ArrayBuffer | string>()
    const [iconFile, setIconFile] = useState<ArrayBuffer | string>()

    useEffect(() => {
        if (data) {
            form.setFieldsValue(getFormInitialValues(data))
        }
    }, [form, data])

    const prepareDeveloper = (values) => {
        const developer = appDevelopersStore.developers.find(dev => dev.value === values.PackageDeveloperId)

        if (developer?.params?.NewDeveloperName || developer?.params?.NewDeveloperEmail) {
            values.NewDeveloperName = developer?.params?.NewDeveloperName
            values.NewDeveloperEmail = developer?.params?.NewDeveloperEmail
            delete values.PackageDeveloperId
        }
    }

    const onFinish = (values) => {
        if (store.packageVersionId) {
            store.setIsAppUploadModalOpen(true)
            return
        }

        prepareDeveloper(values)

        if (!!data) {
            values.PackageIcon = iconFile ? iconFile : values.PackageIcon
            uploadNewApp(`/odin-api/api/Packages/${data?.packageId}/CreateNewVersion`, values)
        } else {
            values.PackageIcon = iconFile
            uploadNewApp('/odin-api/api/Packages', values)
        }

        runInAction(() => {
            store.packageVersionId = null
        })
    }

    const getFile = (e) => {
        getBase64(e.fileList[0].originFileObj)
            .then(image => {
                setImageUrl(image)
            })

        setIconFile(e.file.originFileObj)

        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const formInitialValues = getFormInitialValues(data)

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{translations().upload}</div>
        </div>
    )

    return (
        <>
            <Title level={4}>{translations().newApplicationDetails}</Title>
            <Form
                layout="vertical"
                form={form}
                initialValues={formInitialValues}
                onFinish={onFinish}
            >
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().appName}
                            name={'DisplayName'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appNameRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().appName}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().appInstallationPackage}
                            name={'PackageName'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appInstallationPackageRequired
                                }
                            ]}
                        >
                            <Input disabled={!!data} placeholder={translations().appInstallationPackagePlaceholder}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().appVersionCode}
                            name={'VersionCode'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appVersionCodeRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().appVersionCode}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().appDeveloper}
                            name={'PackageDeveloperId'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appDeveloperRequired
                                }
                            ]}
                        >
                            <AsyncSelect
                                placeholder={translations().pleaseSelectDeveloper}
                                loading={appDevelopersStore.isLoading}
                                selectItems={appDevelopersStore.developers}
                                totalPages={appDevelopersStore.totalPageItems}
                                currentPage={appDevelopersStore.pageNumber}
                                onChange={(e) => log(e)}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                loadMore={() => appDevelopersStore.loadDevelopers()}
                                style={{ width: '100%' }}
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider/>
                                        <Checkbox
                                            className={styles.addNewDev}
                                            checked={isAddNewDeveloper}
                                            onChange={(e) => setIsAddNewDeveloper(e.target.checked)}
                                        >
                                            {translations().addNewDeveloper}
                                        </Checkbox>
                                        {isAddNewDeveloper && <AddNewDeveloper store={appDevelopersStore}/>}
                                    </>
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label={translations().appVersionNumber}
                            name={'VersionNumber'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appVersionNumberRequired
                                },
                                {
                                    pattern: ALPHABETIC_NUMERIC_PATTERN_WITH_DOT_AND_DASH,
                                    message: translations().appVersionNumberInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().appVersionNumber}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().documentationUrl}
                            name={'DocumentationUrl'}
                            rules={[
                                {
                                    pattern: VALID_URL_PATTERN,
                                    message: translations().invalidUrl
                                }
                            ]}
                        >
                            <Input placeholder={translations().documentationUrl}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Title level={4}>{translations().appProduction}</Title>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().selectAppCategory}
                            name={'Categories'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appCategoryRequired
                                }
                            ]}
                        >
                            <TreeSelect
                                treeData={appCategoriesStore.appCategories}
                                treeCheckable={true}
                                showCheckedStrategy={SHOW_ALL}
                                treeDefaultExpandAll
                                multiple
                                showSearch={false}
                                placeholder={translations().selectAppCategory}
                                onChange={(e) => log(e)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={translations().deviceManufacturer}
                            name={'DeviceManufacturerId'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().deviceManufacturerRequired
                                }
                            ]}
                        >
                            <AsyncSelect
                                placeholder={translations().whichDeviceManufacturerSignedAPK}
                                loading={deviceManufacturerStore.isLoading}
                                selectItems={deviceManufacturerStore.selectDeviceManufacturer}
                                totalPages={deviceManufacturerStore.totalPageItems}
                                currentPage={deviceManufacturerStore.pageNumber}
                                onChange={(e) => {
                                    const deviceManufacturer = deviceManufacturerStore.selectDeviceManufacturer.find(manufacturer => manufacturer.value === e)
                                    runInAction(() => {
                                        deviceTypesStore.manufacturer = deviceManufacturer.label
                                    })
                                }}
                                loadMore={() => deviceManufacturerStore.loadDeviceManufacturer()}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().appModels}
                            name={'SupportedDeviceTypes'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appModelsRequired
                                }
                            ]}
                        >
                            <AsyncSelect
                                mode={'multiple'}
                                className={styles.deviceTypeSelect}
                                placeholder={translations().appModels}
                                loading={deviceTypesStore.isLoading}
                                selectItems={deviceTypesStore.filteredByManufacturerDeviceTypes}
                                totalPages={deviceTypesStore.totalPageItems}
                                currentPage={deviceTypesStore.pageNumber}
                                showSearch={false}
                                onChange={(e) => log(e)}
                                loadMore={() => deviceTypesStore.loadDeviceTypes()}
                                style={{ width: '100%' }}
                                disabled={isEmpty(deviceTypesStore.filteredByManufacturerDeviceTypes)}
                            >
                                {deviceTypesStore.filteredByManufacturerDeviceTypes.map(item => (
                                    <Option className={styles.deviceTypes} key={item.value} value={item.value}>
                                        <List.Item.Meta
                                            className={styles.model}
                                            avatar={<Avatar src={item.params.imageUrl} />}
                                            title={item.params.manufacturer}
                                            description={item.params.model}
                                        />
                                    </Option>
                                ))}
                            </AsyncSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Title level={4}>{translations().graphics}</Title>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            name={'PackageIcon'}
                            label={translations().appIcon}
                            rules={[
                                {
                                    required: true,
                                    message: translations().appIconRequired
                                }
                            ]}
                            getValueFromEvent={getFile}
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                fileList={[]}
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                            >
                                {imageUrl ? <img src={imageUrl as string} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <div className={styles.upload}>
                        <Button icon={<DeleteOutlined />} htmlType="button" style={{ margin: '0 8px' }} onClick={() => setIsAddNewApp(false)}>
                            {translations().cancel}
                        </Button>
                        <Button loading={isLoading} icon={<CloudUploadOutlined />} htmlType="submit" type="primary">
                            {translations().uploadApp}
                        </Button>
                    </div>
                </Form.Item>
            </Form>

            <FileUploadModal
                megabytes={APPLICATION_MAX_FILE_SIZE}
                permission={getFileUploadPackageType(packageType)}
                onOk={() => {
                    notification.success({
                        message: translations().applicationCreatedSuccessfully,
                        duration: DEFAULT_NOTIFICATION_DURATION
                    })
                    store.setPackageVersionId(null)
                    setIsAddNewApp(false)
                }}
                store={store}
            />
        </>
    )
})
