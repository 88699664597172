// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fBkFtZiZvbL1FiZJSKEEe {\n  font-family: Open Sans;\n  color: #00A0DC; }\n\n.ant-tooltip-inner {\n  font-family: Open Sans; }\n", ""]);
// Exports
exports.locals = {
	"HelpIcon": "fBkFtZiZvbL1FiZJSKEEe"
};
module.exports = exports;
