import { ProductBundle } from '../../..'

export interface AddProductFormData {
    id: string

    storeId: string
    departmentId: string

    bundles: ProductBundle[]
}

export function createAddProductFormData(): AddProductFormData {
    return {
        id: '',

        storeId: '',
        departmentId: '',

        bundles: []
    }
}
