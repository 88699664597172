import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    storeInfo: string
    address: string
    postcode: string
    town: string
    country: string
    departments: string
    addNewDepartment: string
    noActionAvailable: string | JSX.Element
    hasNoDepartments: string
    areYouSureToDeleteStore: JSX.Element
    yesDelete: string
    cancelDelete: string
    new: string
}>()

