import React from 'react'
import {  Button as AntButton } from 'antd'
import classNames from 'classnames'
import { ButtonProps } from './props'
import styles from './Button.scss'

const Button = (props: ButtonProps) => {
    return <div className={ classNames(styles.Button, {[styles.Button__outlined]: props.outline, [styles.Button__danger]: props.status === 'danger', [styles.Button__disabled]: props.disabled}) }>
        <AntButton { ...props }>
            { props.children }
        </AntButton>
    </div>
}

export { Button }
