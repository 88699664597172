import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    deviceInfo: string
    deviceManagement: string
    applicationManagement: string
    serialNumber: string
    friendlyName: string
    merchantName: string
    merchantStore: string
    status: string
    online: string
    offline: string
    lastCommunication: string
    forceReSync: string
    thisPageWillRefresh: string
    noData: string
    lastCommsMethodUsed: string
    seeHistory: string
    dateTime: string
    communicationsMethod: string
    batteryData: string
    uptime: string
    batteryPercentage: string
    appAndFirmwareManagement: string
    managementActions: string
    audit: string
    size: string
    changeLauncher: string
    updateApp: string
    updateFirmware: string
    upToDate: string
    deleteApp: string
    changeLauncherRequested: string
    available: string
    disabled: string
    enabled: string
    locked: string
    installPriority: string
    cancel: string
    pushUpdate: string
    selectPriorityType: string
    selectPackageVersion: string
    appVersion: string
    pushApp: string
    ok: string
    install: string
    installRequested: string
    packageVersionRequired: string
    areYouSureYouWantToDelete: (name: string) => string
    areYouSureYouWantToSetLauncher: (name: string) => string
    enterPackageName: string
    searchPackageNameOrDisplayName: string 
    pushTaskProgress: string
    pushTasks: string
    lastMetricReceivedDateTime: string
    systemInfo: string
    lastTimeDeviceWasOnline: string
    odinRegistrationRequired: string
    notConnected: string
    connected: string
    type: string
    application: string
    firmware: string
    upgradeFirmware: string
    pleaseSelectPriority: string
    uninstall: string
    setAsLauncher: string
    updateAppRequested: (message: string) => string
    installAppRequested: (message: string) => string
    appDisplayName: string
    appDeveloper: string
    uninstallAppRequested: string
    pleaseTypePackageVersionId: string
    bluetooth: string
    cellular: string
    ethernet: string
    notSet: string
    wifi: string
}>()
