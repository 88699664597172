import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { fetchCurrencies } from '~/code/stores/NewOrderStore/services/fetchers'

export class CurrenciesStore {

    constructor () {
        makeObservable(this, {
            _currencies: observable,
            _isLoadingCurrencies: observable,
            currencies: computed,
            loadCurrencies: action
        })
    }

    _currencies: SelectItem[] = []
    _isLoadingCurrencies: boolean = false

    get currencies() {
        this.loadCurrencies()
        return this._currencies
    }

    async loadCurrencies() {
        if (this._isLoadingCurrencies || !isEmpty(this._currencies)) { return }
        this._isLoadingCurrencies = true
        this._currencies = await fetchCurrencies()
        this._isLoadingCurrencies = false
    }
}
