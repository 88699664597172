import { DeliverToType } from './DeliverToType'

export interface DeliveryDetailsFormData {
    country: number
    postcode: string
    deliverTo: DeliverToType
    addressLine1: string
    addressLine2: string
    town: string
    county: string
    tradingAs: string
    deliveryName: string
    deliveryInstructions: string
    contactName: string
    contactSurname: string
    jobTitle: string
    email: string
    contactPhoneNumber: string
    contactMobilePhone: string
    storeDeliveryInstructions?: {
        storeId: string
        isCustom: boolean
        storeName: string
        deliveryInstructions: string
    }[]
}

export function createDeliveryDetailsFormData(): DeliveryDetailsFormData {
    return {
        country: 0,
        postcode: '',
        deliverTo: 'deliverToStore',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        tradingAs: '',
        deliveryName: '',
        deliveryInstructions: '',
        contactName: '',
        contactSurname: '',
        jobTitle: '',
        email: '',
        contactPhoneNumber: '',
        contactMobilePhone: '',
        storeDeliveryInstructions: []
    }
}
