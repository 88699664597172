export interface MerchantDetailsFormData {
    companyNameFull?: string
    companyNameShort?: string
    descriptor?: string
    website?: string
    addressLine1?: string
    addressLine2?: string
    country?: number
    town?: string
    county?: string
    postcode?: string
    mcc?: number
    currency?: string[]
    acquisitionChannel: string
    createMerchantPortalAccount: true
    credentials: {
        email: string
        password?: string
    },
    initiatorEmail: string
}

export function createMerchantDetailsFormData(): MerchantDetailsFormData {
    return {
        companyNameFull: '',
        companyNameShort: '',
        descriptor: '',
        website: '',
        addressLine1: '',
        addressLine2: '',
        country: 0,
        town: '',
        county: '',
        postcode: '',
        mcc: 0,
        currency: ['gbp'],
        acquisitionChannel: '',
        createMerchantPortalAccount: true,
        credentials: {
            email: '',
            password: ''
        },
        initiatorEmail: ''
    }
}
