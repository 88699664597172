// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._39SYn_6brKvRPP2o2Y1OP7 {\n  height: 1px;\n  background-color: #BABABA;\n  opacity: 0.3;\n  margin-bottom: 16px; }\n", ""]);
// Exports
exports.locals = {
	"Divider": "_39SYn_6brKvRPP2o2Y1OP7"
};
module.exports = exports;
