import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { error } from 'dna-common'
import { fetchDeviceDetailsById } from './services/fetchers'
import { IPushAppsStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/PushNewAppsModal/IPushAppsStore'
import { DeviceDetails } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DeviceDetails'
import { NotificationPriorityStore } from '~/code/ODIN/stores/DeviceManagementDetailsStore/NotificationPriorityStore'
import { IApplicationFirmwareManagementStore } from '~/code/ODIN/pages/DeviceManagementDetails/IApplicationFirmwareManagementStore'

export class PushAppsStore implements IPushAppsStore {
    constructor(parentStore: IAppStore, applicationFirmwareManagementStore: IApplicationFirmwareManagementStore) {
        makeObservable(this, {
            isPushAppsModalOpen: observable,
            setIsPushAppsModalOpen: action, 
            deviceDetails: observable,
            isDetailsLoading: observable,
            priorityId: observable,
            applicationFirmwareManagementStore: observable,
            permissions: computed,
            loadDeviceDetailsById: action,
            setPriorityId: action,
            setPackageId: action
        })

        this.parentStore = parentStore
        this.applicationFirmwareManagementStore = applicationFirmwareManagementStore
        this.notificationPriorityStore = new NotificationPriorityStore(parentStore)
    }

    parentStore: IAppStore

    public notificationPriorityStore: NotificationPriorityStore
    public applicationFirmwareManagementStore: IApplicationFirmwareManagementStore
    public deviceDetails: DeviceDetails = {} as DeviceDetails
    public isDetailsLoading: boolean = false
    public isPushAppsModalOpen: boolean = false
    public packageName: string
    public priorityId: number
    
    setIsPushAppsModalOpen = (isPushAppsModalOpen: boolean) => {
        this.isPushAppsModalOpen = isPushAppsModalOpen
    }    

    setPriorityId = (id: number) => {
        this.priorityId = id
    }

    setPackageId = (packageId: number) => {
        this.parentStore.applicationFirmwareManagementStore.setPackageId(packageId)
    }

    public get permissions(): string[] {
        return this.parentStore?.userInfo?.permissions
    }
    
    public loadDeviceDetailsById = (deviceId: number) => {
        if (this.isDetailsLoading) {
            return
        }

        this.isDetailsLoading = true

        fetchDeviceDetailsById(deviceId)
            .then(response => {
                runInAction(() => {
                    this.deviceDetails = response.result
                })
            })
            .catch((err: Error) => {
                error(`FAILED: ${err.message}`)
            })
            .finally(() => {
                runInAction(() => {
                    this.isDetailsLoading = false
                })
            })
    }
}
