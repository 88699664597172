// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Y-p_pcZx-30PBog-4aZvj {\n  background-color: #FFFFFF;\n  padding: 0px 16px; }\n  .LS5JAGdrPzbQ70yM7LtxA {\n    border-bottom: #F5F5F5 solid 1px;\n    padding: 16px 0px; }\n  ._2aPWqE-ZanuR5G_ygfgTcR {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 16px;\n    color: #8C8C8C; }\n  ._3wGP4bbxEdnGY4b9i6WF9A {\n    font-family: Open Sans;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 19px; }\n", ""]);
// Exports
exports.locals = {
	"ListItem": "Y-p_pcZx-30PBog-4aZvj",
	"ListItem_wrapper": "LS5JAGdrPzbQ70yM7LtxA",
	"ListItem_title": "_2aPWqE-ZanuR5G_ygfgTcR",
	"ListItem_description": "_3wGP4bbxEdnGY4b9i6WF9A"
};
module.exports = exports;
