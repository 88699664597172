import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    app: string
    category: string
    developer: string
    installTime: string
    updateTime: string
    action: string
    launcher: string
    setAsLauncher: string
    updateToLatestVersion: string
    uninstall: string
    appName: string
    pleaseSelectAppDeveloper: string
    selectAppDeveloper: string
    yes: string
    no: string
    selectCanBeLauncherApplication: string
    canBeALauncherApp: string
}>()
