import React from 'react'
import { OrderListItemProps } from './props'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import { ExistingMerchantIcon } from '~/code/POS/pages'
import translations from './translations'
import styles from './OrderListItem.scss'
import { formatPriceWithCurrency } from '~/code/services/ui-helpers'
import { Bundle } from '~/code/POS/pages/OrderHistory/models'

export const OrderListItem = (props: OrderListItemProps) => {
    const {
        companyName,
        orderReference,
        deliveryName,
        deliveryTown,
        deliveryPostcode,
        bundles,
        placedBy,
        status,
        statusLabel,
        createdDateTime,
        isExistingMerchant
    } = props.orderListItem

    const listItemClassName = status === 'failed' ? styles.OrderListItem_failed : ''
    let statusClassName = ''

    switch (status) {
        case 'failed': {
            statusClassName = styles.status_failed
            break
        }
        case 'success': {
            statusClassName = styles.status_success
            break
        }
        case 'reversed': {
            statusClassName = styles.status_reversed
            break
        }
        case 'cancelled': {
            statusClassName = styles.status_cancelled
            break
        }
        default: {
            statusClassName = ''
        }
    }

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.orderListItem)
        }
    }

    return <div className={`${styles.OrderListItem} ${listItemClassName}`} onClick={ onClick }>
        <Row gutter={16}>
            <Col xs={24} sm={12} md={14}>
                <Row gutter={16}>
                    <Col xs={12} sm={14}>
                        { companyName && <div className={styles.header}>
                            <span>{companyName ? companyName : translations().companyName}</span> { isExistingMerchant && <ExistingMerchantIcon className={styles.icon} /> }
                        </div> }
                        <div className={styles.subtitle}>{translations().orderRef}: {orderReference}</div>
                        {
                            createdDateTime && (
                                <div className={ classNames(styles.subtitle) }>
                                    { translations().orderPlacedOn }: { createdDateTime }
                                </div>
                            )
                        }
                        {
                            placedBy && (
                                <div className={ classNames(styles.subtitle) }>
                                    { translations().orderPlacedBy }: { placedBy }
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={12} sm={10}>
                        <div className={styles.title}>{translations().deliverTo}</div>
                        <div className={styles.subtitle}>{deliveryName}</div>
                        <div className={styles.subtitle}>{deliveryTown}</div>
                        <div className={styles.subtitle}>{deliveryPostcode}</div>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} md={10}>
                <Row gutter={16}>
                    <Col xs={12} sm={16}>
                        <div className={styles.title}>{translations().bundles}</div>
                        { bundles.map((bundle, index) => (
                           <div className={styles.groupWrapper} key={`${bundle.bundle}-${index}`}>
                               <div className={styles.subtitle}>{`${bundle.bundle} (x${bundle.bundleQuantity}) ${renderBundlePriceOverride(bundle)}`}</div>
                           </div>
                        ))}
                    </Col>
                    <Col xs={12} sm={8}>
                        <div className={styles.title}>{translations().status}</div>
                        <div className={`${styles.status} ${statusClassName}`}>{statusLabel}</div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

function renderBundlePriceOverride(bundle: Bundle) {
    return (bundle.priceOverride !== undefined && bundle.priceOverride !== null) ? formatPriceWithCurrency(bundle.priceOverride) : ''
}
