import React from 'react'
import { Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { LoginForm } from './components'
import translations from './translations'
import { LoginProps } from './props'
import styles from './Login.scss'
import mainStyles from '~/assets/styles/main.scss'

export const Login = observer( (props: LoginProps) => {
    const { store } = props
    return (
        <div className={styles.Login}>
            <div className={styles.Login_content}>
                <div className={styles.Login_logoWrapper}>
                    <div className={ classNames(styles.Login_logo_dna, styles.Login_logo) }/>
                </div>
                <div className={styles.Login_formWrapper}>
                    <Spin spinning={store.isLoading} tip={translations().signingIn} className={mainStyles.spin}>
                        <LoginForm store={props.store}/>
                    </Spin>
                </div>
            </div>
        </div>
    )
})
