// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2deKa8htnBtOICHg0iTAbS {\n  width: 100%;\n  min-height: 100%;\n  background-color: #F5F5F5; }\n  ._3HzPU-2cZyKTT2VERkjmTQ {\n    background-color: #00a0dc;\n    width: 100%;\n    margin: 0px auto;\n    padding: 0;\n    position: fixed;\n    z-index: 12; }\n  .Ae4pqzXDm2lvxoACjxKMB {\n    max-width: 1220px;\n    width: 100%;\n    margin: 0px auto; }\n  ._2ZxpnV1X2i471cC1qmkJ5a {\n    width: 100%;\n    max-width: 1220px;\n    margin: 0 auto;\n    background: #F5F5F5; }\n    ._2ZxpnV1X2i471cC1qmkJ5a .ant-affix {\n      z-index: 1; }\n  ._2deKa8htnBtOICHg0iTAbS ._2Y8ji1R3di-kz0-6xEmG-b {\n    width: 276px;\n    height: 100%; }\n    ._2deKa8htnBtOICHg0iTAbS ._2X8FiVwXerSlhwHryv_esO {\n      display: none; }\n  ._2peAVI0iUlL3Vx8WPB4F8D {\n    position: fixed;\n    height: 100%;\n    background: #F5F5F5;\n    width: 276px !important;\n    min-width: 276px !important;\n    max-width: 276px !important;\n    padding-top: calc(64px + 8px);\n    z-index: 2; }\n    .rRsJR-O1C42m0WmfoGLop {\n      display: none; }\n  ._2Mn11mqs8FGR8T1i1C0ZDU {\n    background: #FFFFFF;\n    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.085);\n    padding-top: 64px;\n    z-index: 3; }\n  ._3LjTfhsPHA7AXzdEhmim04 {\n    background: #FFFFFF; }\n  ._1LKCxp1531AZIJR4cBoCH8 {\n    background: #FFFFFF;\n    padding: 0;\n    text-align: center;\n    color: var(--antd-wave-shadow-color); }\n  @media (max-width: 1090px) {\n    ._2peAVI0iUlL3Vx8WPB4F8D {\n      z-index: 4; }\n      ._2Y1yi3_31NHLQRxgQhWkJK {\n        display: block;\n        box-shadow: 3px 0 3px 0px rgba(0, 0, 0, 0.085); }\n    ._2deKa8htnBtOICHg0iTAbS ._2Y8ji1R3di-kz0-6xEmG-b {\n      display: none; }\n      ._2deKa8htnBtOICHg0iTAbS .guowd90gFnw7Dx849i5Y5 {\n        display: block;\n        position: absolute; } }\n", ""]);
// Exports
exports.locals = {
	"MainLayout": "_2deKa8htnBtOICHg0iTAbS",
	"MainLayout_headerWrapper": "_3HzPU-2cZyKTT2VERkjmTQ",
	"MainLayout_header": "Ae4pqzXDm2lvxoACjxKMB",
	"MainLayout_body": "_2ZxpnV1X2i471cC1qmkJ5a",
	"siderWrapper": "_2Y8ji1R3di-kz0-6xEmG-b",
	"siderWrapper__hidden": "_2X8FiVwXerSlhwHryv_esO",
	"MainLayout_sider": "_2peAVI0iUlL3Vx8WPB4F8D",
	"MainLayout_sider__hidden": "rRsJR-O1C42m0WmfoGLop",
	"MainLayout_contentWrapper": "_2Mn11mqs8FGR8T1i1C0ZDU",
	"MainLayout_content": "_3LjTfhsPHA7AXzdEhmim04",
	"MainLayout_footer": "_1LKCxp1531AZIJR4cBoCH8",
	"MainLayout_sider__visible": "_2Y1yi3_31NHLQRxgQhWkJK",
	"siderWrapper__visible": "guowd90gFnw7Dx849i5Y5"
};
module.exports = exports;
