import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    acquirer: string
    acquirerRequired: string
    acquirerTooltip: string
    streamlineCompanyId: string
    streamlineCompanyIdRequired: string
    streamlineCompanyIdInvalid: string
    streamlineCompanyIdTooltip: string
    merchantId: string
    merchantIdRequired: string
    accountNumber: string
    accountNumberRequired: string
    accountNumberInvalid: string
    sortCode: string
    sortCodeRequired: string
    sortCodeInvalid: string
    cashBack: string
    enableCashBack: string
    cashBackRequired: string
    cashBackInvalid: string
    cashBackMaxExceeded: string
    gratuity: string
    enableGratuity: string
    gratuityRequired: string
    preferredSubmission: string
    preferredSubmissionRequired: string
    preferredSubmissionTooltip: string
    brandingOrganisation: string
    brandingOrganisationTooltip: string
    merchantCategoryCode: string
    merchantCategoryCodeRequired: string
    merchantCategoryCodeTooltip: string
    accountName: string
    accountNameRequired: string
    accountNameTooltip: string
    currency: string
    currencyRequired: string
    currencyTooltip: string
    americanExpress: string
    amexMerchantIdTooltip: string
    back: string
    nextStep: string
    preathorization: string
}>()

