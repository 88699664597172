import { createOTTSendMerchant, OTTSendMerchant } from '~/code/POS/pages/NewOrder/models/123send/123SendMerchant'

export interface OTTSendPlaceOrderRequest { // OTTSend = 123send
    dealId: string
    orderTypeId: number
    containsAcquirerRevenue: boolean
    orderReference: string
    businessEntityId: number
    progressUpdateUrl: string
    poNumber: string
    requestedDeliveryAndOrActivationDate: string
    internalIdentifier: string
    partnerDetails?: {
        partnerCompanyDetailsId?: number
        agentName?: string
    }
    upgradeDetails?: {
        tid: string
        serialNumber: string
    }[],
    directMerchantCustomerId?: string | number,
    merchant: OTTSendMerchant
}

export function createPlaceOrderRequest(): OTTSendPlaceOrderRequest {
    return {
        dealId: '',
        orderTypeId: 0,
        containsAcquirerRevenue: false,
        orderReference: '',
        businessEntityId: null,
        progressUpdateUrl: '',
        poNumber: '',
        requestedDeliveryAndOrActivationDate: '',
        internalIdentifier: '',
        merchant: createOTTSendMerchant()
    }
}
