import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    departmentName: string
    departmentId: string
    visaMID: string
    departmentType: string
    general: string
    eCommerce: string
    noValue: string
    noActionAvailable: string | JSX.Element
    areYouSureToDeleteDepartment: JSX.Element
    yesDelete: string
    cancelDelete: string
    dnaPayments: string
    dnaEPayments: string
    acquirer: string
    new: string
}>()

