import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { error, isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { v4 as uuid } from 'uuid'
import { fetchCommunicationDetails } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { EXTRA_SMALL_PAGE_SIZE } from '~/code/models'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { CommunicationDetail } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/CommunicationDetail'
import { MediaStatus } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/MediaStatus'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class CommunicationDetailsStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            communicationDetails: observable,
            totalPageItems: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,
            permissions: computed,
            setCommunicationDetails: action,
            loadCommunicationDetails: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    isLoading: boolean = false
    communicationDetails: CommunicationDetail[] = []
    totalPageItems: number = 0
    totalCount: number = 0
    pageSize: number = EXTRA_SMALL_PAGE_SIZE
    pageNumber: number = 0

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    setCommunicationDetails = (communicationDetails: CommunicationDetail[]) => {
        this.communicationDetails = communicationDetails
    }

    public loadCommunicationDetails = (deviceId: number, pageNumber?: number) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber

        if (this.pageNumber === 1) {
            this.communicationDetails = []
        }

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber
        }

        fetchCommunicationDetails(deviceId, queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    runInAction(() => {
                        const communicationDetails = response.result
                            .filter(item => item.mediaStatusId === MediaStatus.Connected)
                            .map((item) => ({...item, key: uuid()}))
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.setCommunicationDetails(communicationDetails)
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
