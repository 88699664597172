import React from 'react'
import translations from './translations'

translations.add('en', {
    restartDevice: 'Restart Device',
    restart: 'Restart',
    retrieveLog: 'Retrieve Log',
    restartRequested: 'Restart Requested',
    createNewLogRequested: 'Create New Log Requested',
    cancel: 'Cancel',
    installPriority: 'Install Priority',
    downloadLog: 'Download Log',
    availableLogsFromLast2Weeks: 'Available Logs From The Last 2 Weeks:',
    pleaseEnterCaptcha: 'Please type the characters above to initiate the restart:',
    pleaseEnterCaptchaForLog: 'Please type the characters above to initiate retrieve logs:',
    captchaIsRequired: 'Captcha Is Required',
    createdDateTime: 'Created Date Time',
    startupDateTime: 'Startup Date Time',
    restartHistory: 'Restart History',
    invalidCode: 'Invalid Code'
})
