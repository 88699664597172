import React from 'react'
import translations from './translations'

translations.add('en', {
    firmwareManagement: 'Packages Details',
    latestApplicationInfo: 'Latest Application Info',
    uploadNewVersion: 'Upload New Version',
    category: 'Category',
    installationPackage: 'Installation Package',
    email: 'Email',
    appSignedBy: 'App Signed By',
    versionHistory: 'Version History',
    displayName: 'Display Name',
    supportedDevices: 'Supported Devices',
    versionNumberRegex: 'Version Number Regex',
    versionNumberRegexRequired: 'Version Number Regex Required',
    cancel: 'Cancel',
    saveRegex: 'Save Regex',
    changeVersionNumberRegex: 'Change Version Regex',
    theInputRegexProvidesTheFollowingFormat: 'The input regex provides the following format for version numbers:',
    major: 'Major',
    minor: 'Minor',
    patch: 'Patch',
    theFollowingCharactersSupported: 'The following characters are supported:',
    ifYouWishToContinuePleaseSaveRegex: 'If you wish to continue, please save the regex input.\n' +
        'Recommend that an online regex tool is used to verify that the version number you wish to save matches the regex.',
    allowToChangeRegex: 'Allow To Change Regex',
    developerName: 'Developer Name',
    developerEmail: 'Developer Email',
    packageDocumentationUri: 'Package Documentation ULR'
})
