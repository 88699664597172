import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    contactUs: string
    orderHistory: string
    reviewOrder: string
    requestHistory: string
    success: string
    sorry: string
    error: string
    pleaseWait: string
    requestHasBeenSubmitted: string
    requestReference: string
    trackRequestStatusInRequestHistory: string
    unableToSubmitRequest: string
    pleaseContactUs: string
    submittingRequest: string
}>()

