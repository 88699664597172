import React from "react";
const SvgSadFace = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
    <mask
      id="sad-face_svg__a"
      maskUnits="userSpaceOnUse"
      x={4}
      y={4}
      width={40}
      height={40}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.98 4C12.94 4 4 12.96 4 24s8.94 20 19.98 20C35.04 44 44 35.04 44 24S35.04 4 23.98 4zM14 19a3 3 0 116 0 3 3 0 01-6 0zm17-3a3 3 0 100 6 3 3 0 000-6zM18 31v-3h12v3H18zM8 24c0 8.84 7.16 16 16 16s16-7.16 16-16S32.84 8 24 8 8 15.16 8 24z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#sad-face_svg__a)">
      <path fill="#f5222d" d="M0 0h48v48H0z" />
    </g>
  </svg>
);
export default SvgSadFace;
