import React, { forwardRef, LegacyRef } from 'react'
import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input/TextArea'
import styles from './TextArea.scss'


const TextArea = forwardRef((props: TextAreaProps = {}, forwardedRef: LegacyRef<Input>) => {
    const textAreaStateClass = props.disabled ? styles.TextArea__disabled : ''
    return (
        <div className={ `${styles.TextArea} ${textAreaStateClass}` } >
            <Input.TextArea {...props}/>
        </div>
    )
})

export { TextArea }
