import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import translations from './translations'
import styles from './LoadingView.scss'
import { Button } from '~/code/common/components'
import { SvgSadFace } from '~/assets/icons'
import { LoadingViewProps } from '~/code/POS/pages'

export const LoadingView: React.FC<LoadingViewProps> = (props) => {
    let iconClass = ''
    switch (props.status) {
        case 'loading': iconClass = styles.icon_loading; break
        case 'failure': iconClass = styles.icon_failure; break
        default: iconClass = styles.icon_loading
    }

    const title = props.title ? props.title : (props.status === 'failure' ? translations().failure : translations().loading)
    const icon = props.icon ? props.icon : (props.status === 'failure' ? <SvgSadFace /> : <LoadingOutlined/>)
    const message = props.message ? props.message : (props.status === 'failure' ? translations().failedToLoad : translations().loadingInProgress)
    const buttonTitle = props.buttonTitle ? props.buttonTitle : translations().retry
    const showRetryButton = !props.hideRetryButton && props.status === 'failure'

    return <div className={styles.LoadingView}>
        <div className={styles.title}>
            {title}
        </div>
        <div className={`${styles.icon} ${iconClass}`}>
            {icon}
        </div>
        <div className={styles.message}>
            <p>{ message }</p>
        </div>
        {showRetryButton && <div className={styles.buttonWrapper}>
            <Button onClick={props.retry ? props.retry : undefined}>
                {buttonTitle}
            </Button>
        </div>}
    </div>
}
