import React from 'react'
import { observer } from 'mobx-react'
import { PageHeader } from '~/code/common/components'
import { Page } from '~/code/POS/pages'
import translations from './translations'
import { AppStore } from '~/code/AppStore'

const UserGuide = observer(() => {
    const link = AppStore.userInfo.companyType === 'partner' ? _PARTNER_USER_GUIDE_LINK_ : _ISO_USER_GUIDE_LINK_
    return <Page>
            <PageHeader>{translations().userGuide}</PageHeader>
            <div>
                {translations().userGuideDescriptionText(link)}
            </div>
    </Page>
})

export { UserGuide }
