import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { getFieldInitialValue } from '~/code/services'
import { Col, Form, Row, Spin } from 'antd'
import {
    VALID_DESCRIPTOR_PATTERN,
    VALID_NAME_PATTERN,
    VALID_TEXT_PATTERN, VALID_URL_PATTERN
} from '~/code/models'
import { CountrySelect, GroupTitle, Input, LabelWithTooltip, PostCode, Select } from '~/code/common/components'
import { validatePostalCodeOfUK } from 'dna-common'
import { searchAddressesByPostcode } from '~/code/POS/pages'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import translations from './translations'
import { MerchantDetailsProps } from './props'

const getFormInitialValues = data => {
    return {
        companyNameFull: getFieldInitialValue(data, 'companyNameFull'),
        companyNameShort: getFieldInitialValue(data, 'companyNameShort'),
        descriptor: getFieldInitialValue(data, 'descriptor'),
        website: getFieldInitialValue(data, 'website'),
        addressLine1: getFieldInitialValue(data, 'addressLine1'),
        addressLine2: getFieldInitialValue(data, 'addressLine2'),
        country: getFieldInitialValue(data, 'country'),
        town: getFieldInitialValue(data, 'town'),
        county: getFieldInitialValue(data, 'county'),
        postcode: getFieldInitialValue(data, 'postcode'),
        mcc: getFieldInitialValue(data, 'mcc'),
        currency: getFieldInitialValue(data, 'currency'),
        acquisitionChannel: getFieldInitialValue(data, 'acquisitionChannel'),
        createMerchantPortalAccount: true,
        credentials: {
            email: getFieldInitialValue(data.credentials, 'email'),
            password: getFieldInitialValue(data.credentials, 'password')
        },
        initiatorEmail: getFieldInitialValue(data, 'initiatorEmail')
    }
}

export const MerchantDetailsForm = observer(({ store }: MerchantDetailsProps) => {
    const { merchantDetailsFormData: data, setMerchantDetailsFormData,
        countries, currencies, merchantCategoryCodes, onCompanyNameChange, canGoBack, goBack, acquisitionChannels, isAcquisitionChannelsLoading } = store

    const onFinish = (values) => {
        setMerchantDetailsFormData(values)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(getFormInitialValues(data))
    }, [form, data])

    const formInitialValues = getFormInitialValues(data)

    return (
        <div>
            <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={formInitialValues}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().companyNameFull}
                            name={'companyNameFull'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('companyNameFull')
                                }
                                const val = value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                onCompanyNameChange(val)
                                return val
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().companyNameFullRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().companyNameFull}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().companyNameShort}
                            name={'companyNameShort'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('companyNameShort')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().companyNameShortRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().companyNameShort}/>
                        </Form.Item>
                        <Form.Item
                            label={<LabelWithTooltip label={translations().descriptor} tooltipText={translations().descriptorTooltip} tooltipPosition={'right'}/>}
                            name={'descriptor'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_DESCRIPTOR_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('descriptor')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().descriptorRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().descriptor} maxLength={21}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().website}
                            name={'website'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().websiteRequired
                                },
                                {
                                    pattern: VALID_URL_PATTERN,
                                    message: translations().invalidUrl
                                }
                            ]}
                        >
                            <Input placeholder={translations().website}/>
                        </Form.Item>
                        <Form.Item
                            label={ translations().mcc }
                            name={'mcc'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().mccRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().mcc}
                                    notFoundContent={<Spin size={'small'}/>}
                                    selectItems={merchantCategoryCodes?.slice()}/>
                        </Form.Item>
                        <Form.Item
                            label={ translations().currency}
                            name={'currency'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().currencyRequired
                                }
                            ]}
                        >
                            <Select placeholder={translations().currency}
                                    mode={'multiple'}
                                    selectItems={
                                        currencies?.slice()
                                    }/>
                        </Form.Item>
                        <Form.Item
                            label={translations().acquisitionChannel}
                            name={'acquisitionChannel'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().acquisitionChannelRequired
                                }
                            ]}
                        >
                            <Select
                                placeholder={translations().acquisitionChannel}
                                showSearch={true}
                                filterOption={(input, option) => {
                                    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                selectItems={acquisitionChannels}
                                loading={isAcquisitionChannelsLoading}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <CountrySelect
                            form={form}
                            initialValue={getFieldInitialValue(data, 'country')}
                            fieldName={'country'}
                            userCountry={'uk'}
                            countries={countries.slice()}
                            required={true}
                        />
                        <Form.Item shouldUpdate noStyle>
                            {() => {
                                return (
                                    <Form.Item
                                        label={translations().postcode}
                                        name={'postcode'}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations().postcodeRequired
                                            },
                                            {
                                                validator: async (rule, value) => {
                                                    if (value && !validatePostalCodeOfUK(value)) {
                                                        throw new Error(translations().postcodeInvalid)
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <PostCode
                                            isUK={ true }
                                            getPostCodeAddresses={ (postCode: string) => searchAddressesByPostcode(postCode) }
                                            onAddressSelect={ (a) => {
                                                form.setFieldsValue({
                                                    addressLine1: a.line_1,
                                                    addressLine2: a.line_2,
                                                    addressLine3: a.line_3,
                                                    town: a.town_or_city,
                                                    county: a.county
                                                })
                                            } }
                                            placeholder={translations().postcode}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                        <Form.Item
                            label={translations().addressLine1}
                            name={'addressLine1'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('addressLine1')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().addressLine1Required
                                }
                            ]}
                        >
                            <Input placeholder={translations().addressLine1}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().addressLine2}
                            name={'addressLine2'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('addressLine2')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                        >
                            <Input placeholder={translations().addressLine2}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().town}
                            name={'town'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('town')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: translations().townRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().town}/>
                        </Form.Item>
                        <Form.Item
                            label={translations().county}
                            name={'county'}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('county')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: translations().countyRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().county}/>
                        </Form.Item>
                    </Col>
                </Row>
                <GroupTitle size={'small'} content={translations().initiatorInfo} />
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().initiatorEmail}
                            name={'initiatorEmail'}
                            rules={[
                                {
                                    required: true,
                                    message: translations().emailRequired
                                },
                                {
                                    type: 'email',
                                    message: translations().emailInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().initiatorEmail} textTransform={'lowercase'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <GroupTitle size={'small'} content={translations().credentials} />
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().email}
                            name={['credentials', 'email']}
                            rules={[
                                {
                                    required: true,
                                    message: translations().emailRequired
                                },
                                {
                                    type: 'email',
                                    message: translations().emailInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().email} textTransform={'lowercase'}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().password}
                            name={['credentials', 'password']}
                        >
                            <Input type={'password'} placeholder={translations().password} />
                        </Form.Item>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
