import React from 'react'
import { HelpIcon } from '~/code/common/components/HelpIcon/HelpIcon'
import { LabelWithTooltipProps } from './props'
import styles from './LabelWithTooltip.scss'

export const LabelWithTooltip = (props: LabelWithTooltipProps) => {
        return <>
            {
                props.tooltipPosition === 'left' ?
                    <><HelpIcon text={props.tooltipText}/><span className={styles.space}/>{props.label}</> :
                    <>{props.label}<span className={styles.space}/><HelpIcon text={props.tooltipText} /></>
            }
        </>
}
