import { FieldData } from './FieldData'

export class RowData {
    fields: (FieldData | RowData) []
    type?: 'collapse'
    span?: {
        xs?: number,
        sm?: number,
        offset?: number
    }
}
