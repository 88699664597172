import React from 'react'
import { Col, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { PlainButton } from '../index'
import translations from './translations'
import { BundleListItemProps } from './props'
import styles from './BundleListItem.scss'
import { isEmpty } from 'dna-common'

export const BundleListItem = (props: BundleListItemProps) => {
    const {
        onDeleteClick
    } = props

    const {
        bundleCode,
        bundleDescription,
        bundleQuantity,
        acquirerAssignedTids
    } = props.bundleListItem

    return (
        <div className={classNames(styles.BundleListItem, {[styles.BundleListItem__highlighted]: props.isHighlighted})}>
            <Row gutter={16}>
                <Col xs={24} sm={8}>
                    <div className={styles.header}>{translations().bundle}</div>
                    <div className={styles.title}>{bundleCode}</div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className={styles.header}>{translations().description}</div>
                    <div className={styles.title}>{bundleDescription}</div>
                </Col>
                <Col xs={24} sm={4}>
                    <div className={styles.header}>{translations().quantity}</div>
                    <div className={styles.title}>{bundleQuantity}</div>
                </Col>
                { !isEmpty(acquirerAssignedTids) &&
                    <Col xs={24} sm={24}>
                        <div className={styles.header}>{translations().acquirerIssuedTid}</div>
                        { acquirerAssignedTids.map((tid) => <div className={styles.title}>{tid.tid}</div>) }
                    </Col>
                }
            </Row>
            <div className={styles.buttonsWrapper}>
                <PlainButton
                    icon={<DeleteOutlined/>}
                    text={translations().delete}
                    className={styles.deleteButton}
                    onClick={onDeleteClick}
                />
            </div>
        </div>
    )
}
