import React from 'react'
import { TabProps } from '~/code/common/components/Tab/props'
import styles from './Tab.scss'

export const Tab = (props: TabProps) => {
    const orderClass = `${styles.Tab_order} ${props.isActive ? styles.Tab_order__active : ''}`
    const titleClass = `${styles.Tab_title} ${props.isActive ? styles.Tab_title__active : ''}`
    return <div className={styles.Tab}>
        <div className={orderClass}>{props.order}</div>
        <div className={titleClass}>{props.title}</div>
    </div>
}
