export interface PenniesFormData {
    logo: string
    charityImage: string
    donationMode: string
    adjustmentBands: {
        bandCeilValue: number
        bandFloorValue: number
        adjustmentValue: number
    }[]
    logoImageMimeType: string
    penniesDonationActiveText: string
    penniesDonationInactiveText: string
    isPenniesUsed?: boolean
}

export function createPenniesFormData (): PenniesFormData {
    return  {
        logo: '',
        charityImage: '',
        donationMode: '',
        adjustmentBands: [],
        logoImageMimeType: '',
        penniesDonationActiveText: '',
        penniesDonationInactiveText: 'Thanks for Your Donation!'
    }
}
