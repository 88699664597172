import translations from './translations'

translations.add('en', {
    checkoutCustomisations: 'Checkout customisations',
    usePartnerDefinedSettings: 'Use partner defined settings',

    integrationTypes: 'Integration types',
    integrationTypesRequired: 'Integration types are required',

    mandatoryFields: 'Mandatory fields',
    accountCity: 'Account city',
    accountEmail: 'Account email',
    accountCountry: 'Account country',
    accountStreet1: 'Account street',
    accountLastName: 'Account last name',
    accountFirstName: 'Account first name',
    accountPostalCode: 'Account postal code',

    colors: 'Colours',
    images: 'Images',
    otherSettings: 'Other Settings',

    // colors
    primaryColor: 'Primary Colour',
    primaryColorRequired: 'Primary Colour is required',
    headerStartColor: 'Header Start Colour',
    headerStartColorRequired: 'Header Start Colour is required',
    headerFinishColor: 'Header Finish Colour',
    headerFinishColorRequired: 'Header Finish Colour is required',
    actionButtonBackgroundColor: 'Action Button Background Colour',
    actionButtonBackgroundColorRequired: 'Action Button Background Colour is required',
    actionButtonColor: 'Action Button Colour',
    actionButtonColorRequired: 'Action Button Colour is required',
    closeButtonColor: 'Close Button Colour',
    closeButtonColorRequired: 'Close Button Colour is required',
    headerLogoTextColor: 'Header Logo Text Colour',
    headerLogoTextColorRequired: 'Header Logo Text Colour Required',

    // images
    headerLogoImageURL: 'Header Logo Image URL',
    headerLogoImageURLRequired: 'Header Logo Image URL is required',
    secondaryLogoImageURL: 'Secondary Logo Image URL',
    secondaryLogoImageURLRequired: 'Secondary Logo Image URL is required',
    secondaryLogoImagePosition: 'Secondary Logo Image Position',
    secondaryLogoImagePositionRequired: 'Secondary Logo Image Position is required',

    // other settings
    headerLogoText: 'Header logo text',
    headerLogoTextRequired: 'Header logo text is required',
    headerLogoTextSecondary: 'Header logo secondary text',
    headerLogoTextSecondaryRequired: 'Header logo secondary text is required',
    headerLogoTextMarginLeft: 'Header logo text margin left',
    headerLogoTextMarginLeftRequired: 'Header logo text margin left is required',
    headerLogoMobileWidth: 'Header logo mobile width',
    headerLogoMobileWidthRequired: 'Header logo mobile width is required',
    headerLogoMobileHeight: 'Header logo mobile height',
    headerLogoMobileHeightRequired: 'Header logo mobile height is required',
    headerLogoDesktopWidth: 'Header logo desktop width',
    headerLogoDesktopWidthRequired: 'Header logo desktop width is required',
    headerLogoDesktopHeight: 'Header logo desktop height',
    headerLogoDesktopHeightRequired: 'Header logo desktop height is required',
    actionButtonBorderRadius: 'Action button border radius',
    actionButtonBorderRadiusRequired: 'Action button border radius is required',
    closeButtonOpacity: 'Close button opacity',
    closeButtonOpacityRequired: 'Close button opacity is required',
    showPoweredByBlock: 'Show powered by block',
    showHeaderBottomLine: 'Show header bottom line',
    showOrderNumberBlock: 'Show order number block',
    showOrderDescriptionBlock: 'Show order description block',
    showPaymentSecuredByBlock: 'Show payment secured by block'
})
