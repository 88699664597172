import React from 'react'
import styles from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/OrderTypeForm.scss'
import { Form } from 'antd'
import translations from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/translations/translations'

export const generateStoreDetails = (store) => {
    return (
        <div>
            <div className={styles.OrderTypeForm_inputContainer}>
                <Form.Item
                    label={translations().storeId}
                    name={'storeId'}
                >
                    <div className={styles.OrderTypeForm_label}>{store?.merchantStoreId}</div>
                </Form.Item>
            </div>
            <div className={styles.OrderTypeForm_inputContainer}>
                <Form.Item
                    label={translations().storeAddressLine1}
                    name={'storeAddressLine1'}
                >
                    <div className={styles.OrderTypeForm_label}>{store?.addressLine1}</div>
                </Form.Item>
            </div>
            <div className={styles.OrderTypeForm_inputContainer}>
                <Form.Item
                    label={translations().storePostcode}
                    name={'storePostcode'}
                >
                    <div className={styles.OrderTypeForm_label}>{store?.postcode}</div>
                </Form.Item>
            </div>
        </div>
    )
}

export const generateDepartmentDetails = (department) => {
    return (
        <div>
            <div className={styles.OrderTypeForm_inputContainer}>
                <Form.Item
                    label={translations().departmentId}
                    name={'departmentId'}
                >
                    <div className={styles.OrderTypeForm_label}>{department.merchantDepartmentId}</div>
                </Form.Item>
            </div>
            { generateVisibleMIDs(department) }
            {
                department.departmentType &&
                <div className={styles.OrderTypeForm_inputContainer}>
                    <Form.Item
                        label={translations().departmentType}
                        name={'departmentType'}
                    >
                        <div className={styles.OrderTypeForm_label}>{department.departmentType}</div>
                    </Form.Item>
                </div>
            }
        </div>
    )
}

export  const generateVisibleMIDs = (department) => {
    const generateMidComponent = (mid, label, name) => {
        return <div className={styles.OrderTypeForm_inputContainer} key={name + mid}>
            <Form.Item
                label={label}
                name={name}
            >
                <div className={styles.OrderTypeForm_label}>{mid}</div>
            </Form.Item>
        </div>
    }

    const result = []
    if (department?.visaMid) {
        result.push(generateMidComponent(department.visaMid, translations().visaMid, 'visaMid'))
    }

    if (department?.mastercardMid) {
        result.push(generateMidComponent(department.mastercardMid, translations().mastercardMid, 'mastercardMid'))
        if (result.length >= 2) return result
    }

    if (department?.amexCpcMid){
        result.push(generateMidComponent(department.amexCpcMid, translations().amexMid, 'amexMid'))
        if (result.length >= 2) return result
    }

    if (department?.dinersMid){
        result.push(generateMidComponent(department.dinersMid, translations().dinersMid, 'dinersMid'))
        if (result.length >= 2) return result
    }

    if (department?.unionPayMid) {
        result.push(generateMidComponent(department.unionPayMid, translations().unionPayMid, 'unionPayMid'))
        if (result.length >= 2) return result
    }

    if (department?.aliPayMid) {
        result.push(generateMidComponent(department.aliPayMid, translations().aliPayMid, 'aliPayMid'))
        if (result.length >= 2) return result
    }

    if (department?.flexecashMid) {
        result.push(generateMidComponent(department.flexecashMid, translations().flexecashMid, 'flexecashMid'))
        if (result.length >= 2) return result
    }

    return result
}
