// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2mkWR-Muv8Qxkjz7HNyPCt {\n  text-transform: uppercase; }\n  ._2mkWR-Muv8Qxkjz7HNyPCt::-webkit-input-placeholder {\n    text-transform: initial; }\n  ._2mkWR-Muv8Qxkjz7HNyPCt:-moz-placeholder {\n    text-transform: initial; }\n  ._2mkWR-Muv8Qxkjz7HNyPCt::-moz-placeholder {\n    text-transform: initial; }\n  ._2mkWR-Muv8Qxkjz7HNyPCt:-ms-input-placeholder {\n    text-transform: initial; }\n\n.jmV5HbGujyegE7K2zRXV {\n  text-transform: lowercase; }\n  .jmV5HbGujyegE7K2zRXV::-webkit-input-placeholder {\n    text-transform: initial; }\n  .jmV5HbGujyegE7K2zRXV:-moz-placeholder {\n    text-transform: initial; }\n  .jmV5HbGujyegE7K2zRXV::-moz-placeholder {\n    text-transform: initial; }\n  .jmV5HbGujyegE7K2zRXV:-ms-input-placeholder {\n    text-transform: initial; }\n\n._2uswqtgGb2yu852WNCXRre {\n  display: flex;\n  justify-content: flex-end;\n  flex-direction: row; }\n  ._2uswqtgGb2yu852WNCXRre ._3nzeV1goaYUfdmMOqWjclj {\n    padding-top: 16px;\n    padding-right: 20px; }\n  ._2uswqtgGb2yu852WNCXRre ._1bpJZcweqH5KPfBPaklZBC {\n    padding-top: 16px;\n    display: flex;\n    flex-flow: row-reverse; }\n\n._3zSeEVBRbJh9VVrL6ekZQZ {\n  padding-top: 20px; }\n  ._3zSeEVBRbJh9VVrL6ekZQZ .eqlQJAmScAbTPgrpxucNR {\n    padding-top: 16px; }\n  ._3zSeEVBRbJh9VVrL6ekZQZ .Cbuyc0TGMZ2jYOAoD7VG4 {\n    padding-top: 16px;\n    display: flex;\n    flex-flow: row-reverse; }\n", ""]);
// Exports
exports.locals = {
	"input_uppercase": "_2mkWR-Muv8Qxkjz7HNyPCt",
	"input_email": "jmV5HbGujyegE7K2zRXV",
	"buttonsContainer": "_2uswqtgGb2yu852WNCXRre",
	"cancelButtonContainer": "_3nzeV1goaYUfdmMOqWjclj",
	"submitButtonContainer": "_1bpJZcweqH5KPfBPaklZBC",
	"navigationButtonsContainer": "_3zSeEVBRbJh9VVrL6ekZQZ",
	"goBackButtonContainer": "eqlQJAmScAbTPgrpxucNR",
	"nextButtonContainer": "Cbuyc0TGMZ2jYOAoD7VG4"
};
module.exports = exports;
