import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    terminalManagement: string
    serialNumber: string
    briefSerialNumber: string
    ptid: string
    friendlyName: string
    name: string
    model: string
    merchant: string
    merchantStore: string
    created: string
    status: string
    registrationStatus: string
    deviceId: string
    lastCommunication: string
    briefLastCommunication: string
    locked: string
    unlocked: string
    manufacturer: string
    merchantId: string
    merchantStoreId: string
    merchantStoreName: string
    store: string
    online: string
    action: string
    search: string
    reset: string
    filter: string
    yes: string
    no: string
    createdDateTime: string
    addBundle: string
    deviceManagementAddBundle: string
    cancel: string
    pleaseSelect: string
    deviceType: string
    pleaseInputSerialNumber: string
    deviceName: string
    pleaseInputDeviceName: string
    pleaseSelectDeviceType: string
    deviceTypeAddedSuccessfully: string
    offline: string
    searchManufacturer: string
    searchModel: string
    searchSerialNumber: string
    searchFriendlyName: string
    searchCreatedDateTime: string
    odinRegistrationRequired: string
    type: string
    selectDeviceType: string
    selectStatus: string
    selectIsRegistered: string
    isOnline: string
    onlineStatus: string
    isRegistered: string
}>()
