import React from 'react'
import { action, makeObservable, observable } from 'mobx'
import page from 'page'
import { AppStore as appStore } from '~/code/AppStore'
import { MenuItem } from '~/code/common/layouts/MainLayout/components/SideMenu/models'
import { ISideMenuStore } from '~/code/common/layouts/MainLayout/components/SideMenu/ISideMenuStore'
import { IAppStore } from '~/code/IAppStore'

export class SideMenuStore implements ISideMenuStore {

    constructor (parentStore: IAppStore, items) {
        makeObservable(this, {
            items: observable,
            onMenuItemClicked: action,
            setItems: action
        })

        this.items = items
    }

    items: MenuItem[]

    setItems = (items: MenuItem[]) => {
        this.items = items
    }

    public onMenuItemClicked(item: MenuItem) {
        if (item.status !== 'active') return
        const shouldHideSider = window.matchMedia('(max-width: 1090px)').matches
        if (shouldHideSider) {
            appStore.toggleSideMenu()
        }
        page(item.route)
    }
}
