import React from 'react'
import { observer } from 'mobx-react'
import { StepsProps } from './props'
import styles from './Steps.scss'

export const Steps = observer((props: StepsProps) => {
    const { steps } = props

    const stepClassModifier = (status) => {
        switch (status) {
            case 'passed': {
                return styles.step__passed
            }
            case 'active': {
                return styles.step__active
            }
            case 'failed': {
                return styles.step__failed
            }
            default: {
                return styles.step__waiting
            }
        }
    }

    const generateLabelsAndDots = () => {
        const result = []
        result[0] = []
        result[1] = []

        steps.map((step, index) => {
            const stepClassName = `${styles.step} ${stepClassModifier(step.status)}`
            result[0].push(
                <div className={`${stepClassName} ${styles.label}`} key={`label-${index}`}>
                    <div>
                        { step.label }
                    </div>
                </div>
            )

            result[1].push(
                <div className={stepClassName} key={`dot-${index}`}>
                    <div className={styles.dot}/>
                    <div className={styles.tail}/>
                </div>
            )
        })

        return result
    }

    const labelsAndDots = generateLabelsAndDots()

    return <div className={styles.Steps}>
        <div className={styles.stepsRow}>
            { labelsAndDots[0] }
        </div>
        <div className={styles.stepsRow}>
            { labelsAndDots[1] }
        </div>
    </div>
})
