import React from 'react'
import translations from './translations'

translations.add('en', {
    today: 'today',
    yesterday: 'yesterday',
    thisWeek: 'this week',
    thisMonth: 'this month',
    thisYear: 'this year'
})
