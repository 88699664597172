import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { notification } from 'antd'
import { error } from 'dna-common'
import { fetchAppCategories } from '~/code/ODIN/stores/ApplicationManagementStore/services/fetchers'
import { AppCategory } from '~/code/ODIN/stores/ApplicationManagementStore/models/AppCategory'
import { IAppCategoriesStore } from '~/code/ODIN/pages/ApplicationManagement/IAppCategoriesStore'
import { mapAppCategoryToTreeDataSource } from './services/utils'
import commonTranslations from '~/code/translations/translations'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class AppCategoriesStore implements IAppCategoriesStore {
    constructor () {
        makeObservable(this, {
            isLoading: observable,
            _appCategories: observable,
            appCategories: computed,
            setAppCategories: action,
            loadAppCategories: action
        })
    }

    isLoading: boolean = false
    _appCategories: AppCategory[] = []

    public get appCategories(): AppCategory[] {
        if (!this._appCategories.length && !this.isLoading) {
            this.loadAppCategories()
        }

        return this._appCategories
    }

    public loadAppCategories = () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        fetchAppCategories()
            .then(response => {
                if (response?.result?.length > 0) {
                    this.setAppCategories(response.result.map(mapAppCategoryToTreeDataSource))
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    setAppCategories (appCategories: AppCategory[]): void {
        this._appCategories = appCategories
    }
}
