import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    orderStatusErrorCodes: { [code: string]: string }
    fetchError: string
    cancelOrderSuccessMsg: string
    cancelOrderErrMsg: string
    cancelling: string
    notYetDispatched: string
}>()

