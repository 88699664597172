import { context } from '~/code/AppStore'
import moment, { Moment } from 'moment'
import { PeriodType } from '../models'
import { currenciesWithDecimals } from '../POS/pages/NewOrder/models/constants'
import { isEmpty } from 'dna-common'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { message } from 'antd'

export function hasAnyFieldValue(fieldsError) {
    return Object.keys(fieldsError).some(field => {
        if (typeof fieldsError[field] === 'object') {
            return hasAnyFieldValue(fieldsError[field])
        } else {
            return fieldsError[field]
        }
    })
}

export function JSONParseRecursively (object) {
    let parsedObject
    try {
        parsedObject = JSON.parse(object)
        Object.keys(parsedObject).forEach(key => {
            parsedObject[key] = JSONParseRecursively(parsedObject[key])
        })
    } catch (e) {
        parsedObject = object
    }

    return parsedObject
}

export const getFieldInitialValue = (data, fieldName, defaultValue?: any) => {
    const value =  getFieldValue(data, fieldName)
    return value ? value : (defaultValue || defaultValue === 0) ? defaultValue : ''
}

export function getFieldValue(object, fieldName: string) {
    return object ? object[fieldName] ? object[fieldName] : '' : ''
}

export function removeEmptyFields(object) {
    Object.keys(object).forEach(key => {
        if (object[key] instanceof Array) {
            (object[key] as []).map(item => {
                removeEmptyFields(item)
            })
            if (JSON.stringify(object[key]) === '[]'){
                delete object[key]
            }
        }
        if (object[key] instanceof Object) {
            removeEmptyFields(object[key])
            if (JSON.stringify(object[key]) === '{}'){
                delete object[key]
            }
        } else if (object[key] === '' || object[key] === -1 || object[key] === '-1' || object[key] === null) {
            delete object[key]
        }
    })
}

export function isOrderHistoryPage() {
    return context.pathname.startsWith(LOCATION_POS_PATH_NAMES.ORDER_HISTORY)
}

export function isOrderDetailsPage() {
    return context.pathname.startsWith(LOCATION_POS_PATH_NAMES.ORDER_HISTORY_DETAILS.replace('*', ''))
}

export function getItemAtIndex(array, index) {
    return isEmpty(array) ? undefined : array[index]
}

export function getPeriodDates(period: PeriodType) {
    let startDate: Moment = null
    let endDate: Moment = null

    switch (period) {
        case PeriodType.Day:
            startDate = moment().startOf('day')
            endDate = moment().endOf('day')
            break
        case PeriodType.Yesterday:
            startDate = moment().subtract(1, 'days').startOf('day')
            endDate = moment().subtract(1, 'days').endOf('day')
            break
        case PeriodType.Week:
            startDate = moment().startOf('isoWeek')
            endDate = moment().endOf('day')
            break
        case PeriodType.Month:
            startDate = moment().startOf('month')
            endDate = moment().endOf('day')
            break
        case PeriodType.Year:
            startDate = moment().startOf('year')
            endDate = moment().endOf('day')
            break
    }

    return { startDate, endDate }
}

export function removeFullStops(value: string): string {
    return value.replace(/[.]/g, '')
}

export function countDecimals(num: number) {
    if (Math.floor(num) === num) return 0
    const afterDecimal = num.toString().split('.')[1]
    return afterDecimal?.length || 0
}

export function validateDecimals(value: any, currencyId: any, errMessage: string) {
    const decimals = countDecimals(value)
    const currency = currenciesWithDecimals.find(c => c.id === currencyId)
    if (decimals > currency.decimalPlaces) {
        return Promise.reject(new Error(errMessage))
    }
    return Promise.resolve()
}

export function beforeFileUpload(file: File, errMessage: string, megabytes: number) {
    const isLtMb = file.size / 1024 / 1024 < megabytes
    if (!isLtMb) {
        message.error(errMessage)
    }
    return isLtMb
}

export function getBase64(file): Promise<ArrayBuffer | string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}
