import React from 'react'
import styles from './LinkLabel.scss'

interface LinkLabelProps {
    children: React.ReactChild
    onClick?: React.MouseEventHandler<HTMLSpanElement>
}

export const LinkLabel = (props: LinkLabelProps) => {
    const { onClick, children } = props

    return <span className={styles.LinkLabel} onClick={onClick}>
        { children }
    </span>
}
