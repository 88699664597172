import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { DELIVERY_INSTRUCTIONS_MAX_LENGTH, DELIVERY_INSTRUCTIONS_PATTERN } from '~/code/models/Patterns'
import { TextArea } from '~/code/common/components/TextArea'
import { DeliveryInstructionsProps } from './props'
import translations from './translations'
import styles from './DeliveryInstructions.scss'

export const DeliveryInstructions = (props: DeliveryInstructionsProps) => {
    const [charsCount, setCharsCount] = useState(0)

    useEffect(() => {
        setCharsCount(0)
    }, [props.disabled])

    return (
        <Form.Item className={styles.DeliveryInstructions}
                   help={charsCount > 0 && !props.disabled ? `${charsCount}/${DELIVERY_INSTRUCTIONS_MAX_LENGTH}` : undefined}
                   name={props.id}
                   initialValue={props.initialValue}
                   getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                       const regex = DELIVERY_INSTRUCTIONS_PATTERN
                       const value = e.currentTarget.value
                       const test = regex.test(value)
                       const result = (!test || value?.length > DELIVERY_INSTRUCTIONS_MAX_LENGTH) ?
                           props.form.getFieldValue(props.id) :
                           value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')

                       setCharsCount(result.length)
                       return result
                   }}
        >
            <TextArea
                rows={props.rows || undefined}
                autoSize={props.autoSize || undefined}
                placeholder={translations().deliveryInstructions} disabled={props.disabled}
            />
        </Form.Item>
    )
}
