import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    addressLine1: string
    addressLine1Required: string
    addressLine2: string
    addressLine2Required: string
    addressLine3: string
    addressLine3Required: string
    town: string
    townRequired: string
    county: string
    countyRequired: string
    country: string
    countryRequired: string
    postcode: string
    postcodeRequired: string
    postcodeInvalid: string
    useMerchantAddress: string
    recipientName: string
    deliveryInstructions: string
    back: string
    nextStep: string
}>()

