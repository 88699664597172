import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { fetchAcquirers } from '~/code/stores/NewOrderStore/services/fetchers'

export class AcquirersStore {

    constructor (public fetchAcquirersFunction?: () => Promise<SelectItem[]>) {
        makeObservable(this, {
            items: observable,
            isLoading: observable,
            acquirers: computed,
            load: action,
            setIsLoading: action,
            setItems: action
        })
    }

    items: SelectItem[] = []
    isLoading: boolean = false

    get acquirers() {
        this.load()
        return this.items
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    setItems(items: SelectItem[]) {
        this.items = items
    }

    async load() {
        if (this.isLoading || !isEmpty(this.items)) { return }
        this.setIsLoading(true)
        if (this.fetchAcquirersFunction) {
            const _items = await this.fetchAcquirersFunction()
            this.setItems(_items)
        } else {
            const _items = await fetchAcquirers()
            this.setItems(_items)
        }

        this.setIsLoading(false)
    }
}
