import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    appName: string
    appNameRequired: string
    appDeveloper: string
    newAppDeveloper: string
    appDeveloperRequired: string
    appInstallationPackage: string
    appInstallationPackageRequired: string
    appInstallationPackagePlaceholder: string
    appVersionNumber: string
    appVersionNumberRequired: string
    appVersionNumberInvalid: string
    appVersionCode: string
    appVersionCodeRequired: string
    newApplicationDetails: string
    graphics: string
    cancel: string
    uploadApp: string
    invalidEmail: string
    developmentEmail: string
    developmentEmailRequired: string
    appProduction: string
    selectAppCategory: string
    appSignedBy: string
    whichDeviceManufacturerSignedAPK: string
    appCategoryRequired: string
    deviceManufacturer: string
    deviceManufacturerRequired: string
    releaseDetails: string
    linkToReleaseNotes: string
    appIcon: string
    appIconRequired: string
    appModels: string
    appModelsRequired: string
    documentationUrl: string
    invalidUrl: string
    youCanUploadOnlySvgOrPng: string
    imageMemoryRestriction: string
    imageMaxPixelSizeRestriction: string
    pleaseSelectDeveloper: string
    addNewDeveloper: string
    upload: string
    applicationCreatedSuccessfully: string
}>()
