import React from 'react'
import translations from './translations'

translations.add('en', {
    orderHistory: 'Order History',
    refresh: 'Refresh',
    for: 'For ',
    status: 'status ',
    noOrders: 'No orders for the selected period',
    any: 'any',
    failedValidationRound1: 'failed validation round 1',
    receivedAndPassedValidationRound1: 'received and passed validation round 1',
    passedValidationRound2: 'passed validation round 2',
    failedValidationRound2: 'failed validation round 2',
    dispatching: 'dispatching',
    completed: 'completed',
    duplicateOrder: 'duplicate order',
    partialSuccess: 'partial success',
    failed: 'failed',
    reversed: 'reversed'
})
