export const ONLY_DIGITS_PATTERN = /^\d+$/
export const DECIMAL_WITH_PRECISION_2 = /^\d+(\.\d{1,2})?$/
export const DECIMAL_WITH_PRECISION_2_TYPING_PATTERN = /^\d*(\.?\d{0,2})?$/

export const PHONE_NUMBER_PATTERN = /^\+?(\d){5,12}$/
export const PHONE_NUMBER_TYPING_PATTERN = /^\+?(\d){0,12}$/

export const COMPANY_NUMBER_LENGTH = 8
export const COMPANY_NUMBER_PATTERN = /^([a-zA-Z]{2}|(\d){2})((\d){6}|((\d){5}[a-zA-Z]{1}))$/
export const COMPANY_NUMBER_TYPING_PATTERN = /^([a-zA-Z]{0,2}|(\d){0,2})((\d){0,6}|((\d){5}[a-zA-Z]{0,1}))$/

export const ROI_COMPANY_NUMBER_LENGTH = 5
export const ROI_COMPANY_NUMBER_PATTERN = /^(\d){5}|(\d){6}$/
export const ROI_COMPANY_NUMBER_TYPING_PATTERN = /^(\d){0,6}$/

export const MERCHANT_ID_PATTERN = /^(\d)+$/
export const MERCHANT_ID_TYPING_PATTERN = /^(\d)*$/

export const STREAMLINE_COMPANY_ID_PATTERN = /^\d{6}(?:\d{2})?$/
export const STREAMLINE_COMPANY_ID_TYPING_PATTERN = /^(\d){0,6}(?:\d{2})?$/

export const BANK_ACCOUNT_NUMBER_PATTERN = /^(\d){8}$/
export const BANK_ACCOUNT_NUMBER_TYPING_PATTERN = /^(\d){0,8}$/

export const TWO_DECIMAL_NUMBER_TYPING_PATTERN = /^(\d){0,2}$/

export const SORT_CODE_PATTERN = /^(\d{6})|(\d{2}-\d{2}-\d{2})$/
export const SORT_CODE_TYPING_PATTERN = /^__|\d_|\d\d-__|\d_|\d\d-__|\d_|\d\d$/

export const TWO_DECIMAL_POINTS_NUMBER_PATTERN = /^((\d)+\.\d{1,2})|(\d+)$/

export const VALID_TEXT_PATTERN = /^([a-zA-Z0-9]*([\\\/&\-\[\]]|\'|\(|\))*)+(\x20|[a-zA-Z0-9]|[\\\/&\-\[\]]|\'|\(|\)){0,180}$/
export const VALID_NAME_PATTERN = /^[a-zA-Z0-9]*(\x20|-|&|\\|\/|\[|\]|[a-zA-Z0-9]){0,180}$/ // /^[a-zA-Z0-9]*(\x20|-|&|\(|\)|\\|\/|\[|\]|[a-zA-Z0-9]){0,180}$/
export const VALID_DESCRIPTOR_PATTERN = /^[a-zA-Z0-9]*(\x20|-|&|\\|\/|\[|\]|[a-zA-Z0-9]){0,21}$/
export const VALID_NAME_WITHOUT_SPACE_PATTERN = /^[a-zA-Z0-9]*(-|&|\(|\)|\\|\/|\[|\]|[a-zA-Z0-9]){0,180}$/

export const DELIVERY_INSTRUCTIONS_MAX_LENGTH = 50
export const DELIVERY_INSTRUCTIONS_PATTERN = /^([a-zA-Z0-9]+\s?[\@\(\)\*\.\\:\+\-\&\'\!\,\"]*\s?)*$/
export const POSTCODE_PATTERN = /^.{0,20}$/

export const VALID_TEXT_REPLACE_PATTERN = /[^\w\s'\(\)\\\/&\-\[\]]|\(|\)|(\s){2,}|-{2,}|'{2,}|&{2,}|\({2,}|\){2,}|\\{2,}|\/{2,}/gi

export const MORE_THAN_2_WHITESPACE = /\s{2,}/gm

export const OTTSEND_DEAL_ID_PATTERN = /(?=[a-zA-Z0-9]*[a-zA-Z])(?=[a-zA-Z0-9]*[0-9])[a-zA-Z0-9]{18}$/
export const OTTSEND_DEAL_ID_TYPING_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9]{0,18}$/

export const VALID_URL_PATTERN = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
export const VALID_ALPHABETIC_NUMERIC_WORD_WITH_SPACES = /^[A-Za-z0-9 -_]*[A-Za-z0-9][A-Za-z0-9 -_]*$/

export const ALPHABETIC_NUMERIC_PATTERN_WITH_DOT_AND_DASH = /^[A-Za-z0-9-._]*[A-Za-z0-9][A-Za-z0-9-._]*$/

