// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._4i_aVPeppwCML02X1na6M {\n  width: 200px;\n  padding: 26px; }\n\n._2frZXgSppo1MzuBO31QedH {\n  width: 100%;\n  min-height: 450px;\n  margin-bottom: 10px; }\n\n.QQirL1Q9GZqznfipOGlwX.QQirL1Q9GZqznfipOGlwX {\n  min-height: 324px; }\n\n.rJVMryXjAv3HP2tQNhOwM {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 50%;\n  min-height: 81px;\n  max-height: 81px;\n  text-align: center;\n  padding: 10px; }\n  .rJVMryXjAv3HP2tQNhOwM:first-child {\n    width: 100%; }\n  .rJVMryXjAv3HP2tQNhOwM:hover {\n    cursor: pointer; }\n  ._3ZhirJWmSC5eoE1_ktCJuG .ant-card-body {\n    padding: 0; }\n  ._12Et-irM4N1D0ZkmhBP9c9 {\n    height: 100%;\n    max-width: 85%;\n    max-height: 70px; }\n  ._3FEvG50omSwAL9AiTwzyem {\n    margin: 0; }\n\n._1HsaJOloCHDY-rbOumeopG {\n  font-family: Poppins, serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 32px;\n  line-height: 48px;\n  color: #000000;\n  padding-bottom: 24px; }\n  ._1FrPq3ei7piJipAJOGTPLG {\n    font-size: 16px;\n    line-height: 16px;\n    padding-bottom: 24px; }\n\n._3sg6VMw6Yu6VR7kLSJxcYh._3sg6VMw6Yu6VR7kLSJxcYh {\n  position: absolute;\n  margin: -90px 75px; }\n\n.RZZ--r2tA3Vk7alqaGCjI {\n  margin-top: 25px !important; }\n\n._3rIR7I1DQtuL1_JFpMXlSF .ant-card-body {\n  padding: 0; }\n", ""]);
// Exports
exports.locals = {
	"dnaLogo": "_4i_aVPeppwCML02X1na6M",
	"companiesContainer": "_2frZXgSppo1MzuBO31QedH",
	"newMerchantLogo": "QQirL1Q9GZqznfipOGlwX",
	"company": "rJVMryXjAv3HP2tQNhOwM",
	"companyContainer": "_3ZhirJWmSC5eoE1_ktCJuG",
	"companyLogo": "_12Et-irM4N1D0ZkmhBP9c9",
	"companyDescription": "_3FEvG50omSwAL9AiTwzyem",
	"header": "_1HsaJOloCHDY-rbOumeopG",
	"headerDescription": "_1FrPq3ei7piJipAJOGTPLG",
	"back": "_3sg6VMw6Yu6VR7kLSJxcYh",
	"backFirstForm": "RZZ--r2tA3Vk7alqaGCjI",
	"tooltip": "_3rIR7I1DQtuL1_JFpMXlSF"
};
module.exports = exports;
