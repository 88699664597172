import translations from './translations'

translations.add('en', {
    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',
    addressLine3Required: 'Address line 3 is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    county: 'County',
    countyRequired: 'County is required',
    country: 'Country',
    countryRequired: 'Country is required',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',

    useMerchantAddress: 'use merchant address',

    recipientName: 'Recipient name',
    deliveryInstructions: 'Delivery instructions',

    back: 'Back',
    nextStep: 'Next'
})
