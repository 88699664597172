import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Select } from '~/code/common/components'
import { FiltersSelectProps } from './props'
import { Select as AntSelect } from 'antd'
import translations from './translations'
import styles from './FiltersSelect.scss'

const { Option } = AntSelect


export const FiltersSelect = observer((props: FiltersSelectProps) => {
    const { store } = props
    const [selectedItems, setSelectedItems] = useState(store.values ? store.values.slice() : [])

    const filteredOptions = store.options.filter(o => !selectedItems.find(item => item.key === o.key))

    return <Select
        className={styles.filtersSelect}
        labelInValue
        placeholder={translations().startTypingAnything}
        mode={'multiple'}
        filterOption={false}
        searchValue={store.query}
        value={selectedItems}
        onChange={(value) => {
            store.setValues(value)
            setSelectedItems(value)
        }}
        onSearch={store.setQuery}
        onSelect={store.onSelect}
        onDeselect={store.onDeselect}
        open={!!store.query}
        bordered={false}
    >
        {filteredOptions.map(option => {
            return (
                <Option
                    key={option.key}
                    value={option.value}
                >
                    {option.label}: {store.query}
                </Option>
            )
        })}
    </Select>
})
