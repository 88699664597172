// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Gb6pyCrMSNceFdd6asjsL {\n  margin-bottom: 12px; }\n\n._2rzLBQu2VXmTiC6-jOOa8 {\n  margin-bottom: 12px; }\n", ""]);
// Exports
exports.locals = {
	"formItem": "Gb6pyCrMSNceFdd6asjsL",
	"explanationTextWrapper": "_2rzLBQu2VXmTiC6-jOOa8"
};
module.exports = exports;
