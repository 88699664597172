import { action, makeObservable, observable, runInAction } from 'mobx'
import { error, isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { fetchPushTasks } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { IPushTasksStore } from '~/code/ODIN/pages/DeviceManagementDetails/components/PushTasksModal/IPushTasksStore'
import { PushTask } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/PushTask'
import { EXTRA_SMALL_PAGE_SIZE } from '~/code/models'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { DeliveryStatus } from '~/code/ODIN/pages/DeviceManagementDetails/models/DeliveryStatus'
import { PushTaskFilter } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/PushTaskFilter'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class PushTasksStore implements IPushTasksStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isPushTasksModalOpen: observable,
            isLoading: observable,
            tasks: observable,
            totalPageItems: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,
            options: observable,
            setTasks: action,
            loadTasks: action,
            setIsPushTasksModalOpen: action,
            setOptions: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    isPushTasksModalOpen: boolean = false
    isLoading = {
        [DeliveryStatus.NotSent]: false,
        [DeliveryStatus.Sent]: false,
        [DeliveryStatus.Delivered]: false,
        [DeliveryStatus.Errored]: false,
        [DeliveryStatus.All]: false
    }
    tasks = {
        [DeliveryStatus.NotSent]: [],
        [DeliveryStatus.Sent]: [],
        [DeliveryStatus.Delivered]: [],
        [DeliveryStatus.Errored]: [],
        [DeliveryStatus.All]: []
    }
    totalPageItems = {
        [DeliveryStatus.NotSent]: 0,
        [DeliveryStatus.Sent]: 0,
        [DeliveryStatus.Delivered]: 0,
        [DeliveryStatus.Errored]: 0,
        [DeliveryStatus.All]: 0
    }
    totalCount = {
        [DeliveryStatus.NotSent]: 0,
        [DeliveryStatus.Sent]: 0,
        [DeliveryStatus.Delivered]: 0,
        [DeliveryStatus.Errored]: 0,
        [DeliveryStatus.All]: 0
    }
    pageSize = {
        [DeliveryStatus.NotSent]: EXTRA_SMALL_PAGE_SIZE,
        [DeliveryStatus.Sent]: EXTRA_SMALL_PAGE_SIZE,
        [DeliveryStatus.Delivered]: EXTRA_SMALL_PAGE_SIZE,
        [DeliveryStatus.Errored]: EXTRA_SMALL_PAGE_SIZE,
        [DeliveryStatus.All]: EXTRA_SMALL_PAGE_SIZE
    }
    pageNumber = {
        [DeliveryStatus.NotSent]: 0,
        [DeliveryStatus.Sent]: 0,
        [DeliveryStatus.Delivered]: 0,
        [DeliveryStatus.Errored]: 0,
        [DeliveryStatus.All]: 0
    }
    options = {
        [DeliveryStatus.NotSent]: null,
        [DeliveryStatus.Sent]: null,
        [DeliveryStatus.Delivered]: null,
        [DeliveryStatus.Errored]: null,
        [DeliveryStatus.All]: null
    }

    public loadTasks = (deviceId: number, deliveryStatus: DeliveryStatus, pageNumber?: number) => {
        if (this.isLoading[deliveryStatus]) {
            return
        }

        this.isLoading[deliveryStatus] = true

        this.pageNumber[deliveryStatus] = isEmpty(pageNumber) ? 1 : pageNumber
        if (this.pageNumber[deliveryStatus] === 1) {
            this.tasks = {
                [DeliveryStatus.NotSent]: [],
                [DeliveryStatus.Sent]: [],
                [DeliveryStatus.Delivered]: [],
                [DeliveryStatus.Errored]: [],
                [DeliveryStatus.All]: []
            }
        }

        const queryParams = {
            pageNumber: this.pageNumber[deliveryStatus],
            pageSize: this.pageSize[deliveryStatus],
            ...this.options[deliveryStatus]
        }

        if (deliveryStatus !== DeliveryStatus.All) {
            queryParams['DeliveryStatus'] = deliveryStatus
        }

        fetchPushTasks(deviceId, queryParams)
            .then(response => {
                if (response?.result?.length) {
                    runInAction(() => {
                        this.setTasks(response?.result?.map((item) => ({...item, key: item.id})), deliveryStatus)
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.totalCount[deliveryStatus] = pagination.totalCount
                        this.totalPageItems[deliveryStatus] = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading[deliveryStatus] = false
                })
            })
    }

    setTasks = (tasks: PushTask[], deliveryStatus: DeliveryStatus) => {
        this.tasks[deliveryStatus] = tasks
    }

    setIsPushTasksModalOpen = (isPushTasksModalOpen: boolean) => {
        this.isPushTasksModalOpen = isPushTasksModalOpen
    }

    setOptions = (options: PushTaskFilter, deliveryStatus: DeliveryStatus) => {
        this.options[deliveryStatus] = options
    }
}
