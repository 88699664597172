import React from 'react'
import translations from './translations'

translations.add('en', {
    requestSummary: 'Request Summary',

    back: 'Back',
    cancelRequest: 'Cancel',
    cancelRequestConfirmText: <p>
        Are you sure you want to cancel the current request?
        <br/>
        All entered data will be lost
    </p>,
    confirmCancelRequest: 'Yes, cancel request',
    cancelCancelRequest: 'No, keep request',

    submitRequest: 'Submit'
})
