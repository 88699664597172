// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DQIWJ5jCvmZkBFOJdsWw5 {\n  padding-top: 16px; }\n\n.GwrMYO95SvpE0D1em6Y-m {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n\n.OLXDv-TtewpcTw_cMYOH5 {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 36px;\n  color: #000000;\n  padding-bottom: 13px;\n  display: flex; }\n\n._17jFbrF04G55fdGuk4hSZ_ .jRMjYZyq49ZH1Nxvlooyw {\n  font-size: 14px;\n  line-height: 36px; }\n\n._17jFbrF04G55fdGuk4hSZ_ ._24aumyKJ6r0wX6VlLHYU3z {\n  display: flex;\n  padding-left: 6px;\n  padding-top: 2px; }\n", ""]);
// Exports
exports.locals = {
	"OrderDetailsForm_goBackButtonContainer": "_3DQIWJ5jCvmZkBFOJdsWw5",
	"OrderDetailsForm_submitButtonContainer": "GwrMYO95SvpE0D1em6Y-m",
	"OrderDetailsForm_subgroupTitle": "OLXDv-TtewpcTw_cMYOH5",
	"OrderDetailsForm": "_17jFbrF04G55fdGuk4hSZ_",
	"tooltipIcon": "jRMjYZyq49ZH1Nxvlooyw",
	"tooltipIconWrapper": "_24aumyKJ6r0wX6VlLHYU3z"
};
module.exports = exports;
