// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3NvlzqoJvOg1ic_USysO_t ._3IouSUFQHcjyGSGnOAjWP4 {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 32px;\n  line-height: 48px;\n  color: #000000;\n  padding-bottom: 16px; }\n\n._3NvlzqoJvOg1ic_USysO_t ._2VzX2L_nuZKAbKuu9br82S {\n  display: flex;\n  flex-flow: row-reverse; }\n  @media (max-width: 575px) {\n    ._3NvlzqoJvOg1ic_USysO_t ._2VzX2L_nuZKAbKuu9br82S {\n      flex-flow: row; } }\n\n._3NvlzqoJvOg1ic_USysO_t ._3PrUhXoxA_rJaO_d6da2nf {\n  display: flex;\n  flex-flow: row-reverse; }\n", ""]);
// Exports
exports.locals = {
	"RequestSummary": "_3NvlzqoJvOg1ic_USysO_t",
	"header": "_3IouSUFQHcjyGSGnOAjWP4",
	"cancelRequestButtonContainer": "_2VzX2L_nuZKAbKuu9br82S",
	"submitButtonContainer": "_3PrUhXoxA_rJaO_d6da2nf"
};
module.exports = exports;
