import React from 'react'
import translations from './translations'

translations.add('en', {
    app: 'App',
    category: 'Category',
    developer: 'Developer',
    installTime: 'Install Time',
    updateTime: 'Update Time',
    action: 'Action',
    uninstall: 'Uninstall',
    setAsLauncher: 'Set as launcher',
    launcher: 'Launcher',
    updateToLatestVersion: 'Update to latest version',
    appName: 'App Name',
    pleaseSelectAppDeveloper: 'Please Select App Developer',
    selectAppDeveloper: 'Select App Developer',
    yes: 'Yes',
    no: 'No',
    selectCanBeLauncherApplication: 'Select Can Be A Launcher Application',
    canBeALauncherApp: 'Can Be A Launcher App?'
})
