// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16GubvdbcyNcDj0xdml37p ._327PMXDg6tXPDpxSXTe7xL {\n  padding-bottom: 20px; }\n\n._16GubvdbcyNcDj0xdml37p ._38oPdNwI8zUEkGkZ1F6dHp {\n  width: 100%; }\n\n._16GubvdbcyNcDj0xdml37p .SGKpp9QfzrhbJDkryOHkE {\n  margin-top: 0;\n  margin-bottom: 20px; }\n\n._2Bw8t7qrL_DaFuAMJWh69v {\n  margin-right: 8px; }\n", ""]);
// Exports
exports.locals = {
	"StoresAndDepartmentsForm": "_16GubvdbcyNcDj0xdml37p",
	"wrapperWithBottomPadding": "_327PMXDg6tXPDpxSXTe7xL",
	"addStoreButtonWrapper": "_38oPdNwI8zUEkGkZ1F6dHp",
	"listActions": "SGKpp9QfzrhbJDkryOHkE",
	"button": "_2Bw8t7qrL_DaFuAMJWh69v"
};
module.exports = exports;
