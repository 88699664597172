import React, { useRef, useState } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import translations from './translations'
import { CopyProps } from './props'
import styles from './CopyText.scss'
import { CopyFilled } from '@ant-design/icons/lib'


export const CopyText: React.FC<CopyProps> = ({ text, className = '', style = {}, iconClassName= ''}) => {
    const inputEl = useRef<HTMLInputElement>()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const copy = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.select()
            inputEl.current.setSelectionRange(0, 99999)
            document.execCommand('copy')
            setIsTooltipOpen(true)
            setTimeout(() => setIsTooltipOpen(false), 1200)
        }
    }

    return (
        <span className={ classNames(styles.CopyText, className) } style={ style }>
            { text }
            <Tooltip title={ translations().copied } visible={ isTooltipOpen }>
                {/*<SvgCopy className={ classNames(styles.copyTextIcon, iconClassName) } onClick={ copy } />*/}
                <CopyFilled className={ classNames(styles.copyTextIcon, iconClassName) } onClick={ copy }/>
            </Tooltip>
            <input className={ styles.copyTextInput } value={ text } ref={ inputEl } readOnly />
        </span>
    )
}

export default CopyText
