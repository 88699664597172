import React from 'react'
import { observer } from 'mobx-react'
import { SideMenuProps } from './props'
import { MenuItem } from '~/code/common/layouts/MainLayout/components/SideMenu/models'
import { computeOpenKeys, computeSelectedKey } from '~/code/common/layouts/MainLayout/components/SideMenu/services'
import { Menu } from 'antd'
import { isEmpty } from 'dna-common'
import SubMenu from 'antd/es/menu/SubMenu'
import { observable, runInAction } from 'mobx'
import page from 'page'
import styles from './SideMenu.scss'

const context = observable({
    pathname: ''
})

page((ctx, next) => {
    runInAction(() => {
        context.pathname = ctx.pathname
    })
    next()
})

export const SideMenu = observer((props: SideMenuProps) => {
    const { store } = props
    const { items } = store

    const onMenuItemClicked = (item: MenuItem) => {
        store.onMenuItemClicked(item)
    }

    return (
        <div className={styles.SideMenu}>
            <Menu
                mode={'inline'}
                selectedKeys={[computeSelectedKey(context?.pathname, items)]}
                defaultOpenKeys={computeOpenKeys(context?.pathname, items)}
                className={styles.menu}
                inlineIndent={16}
            >
                {
                    items.map(item => {
                        return renderMenuItem(item, onMenuItemClicked)
                    })
                }
            </Menu>
        </div>
    )
})

function renderMenuItem(item: MenuItem, onMenuItemClicked) {
    if (isEmpty(item.items)) {
        return <Menu.Item key={item.route}
                          icon={item.icon}
                          className={styles.menuItem}
                          disabled={item.status === 'soon'}
                          onClick={() => onMenuItemClicked(item)}>
            { item.title }
            { item.status === 'soon' && <div className={styles.status}>
                <span>{item.status}</span>
            </div>
            }
        </Menu.Item>
    }
    return <SubMenu key={item.route} icon={item.icon} title={item.title} className={styles.subMenu} >
        {item.items.map(_item => renderMenuItem(_item, onMenuItemClicked))}
    </SubMenu>
}
