import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Divider, Drawer, Empty, Form, notification, Row, Spin, Steps } from 'antd'
import { v4 as uuid } from 'uuid'
import { ACQUIRER_IDS, getFieldValue } from '~/code/services'
import { Button, Checkbox, Input, LabelWithTooltip } from '~/code/common/components'
import { ONLY_DIGITS_PATTERN } from '~/code/models'
import { BundleSelect } from '~/code/POS/pages/NewOrder/components/common/BundleSelect'
import { DepartmentDetails, StoreDetails } from '../../common/ProductListForm/components/AddProductForm/components'
import { AddProduct123SendFormProps } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/props'
import { BundleListItem } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/components'
import { OTTSendBundle } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/models/OTTSendBundle'
import { SDDepartment } from '~/code/POS/pages'
import translations from './translations'
import { isEmpty, log } from 'dna-common'
import { BundlePriceSelect } from '~/code/POS/pages/NewOrder/components/common/BundlePriceSelect'
import { BUNDLE_ACQUIRER_REQUIREMENT_TYPE, ONE_TWO_THREE_SEND_BUNDLE_CUSTOM_PRICES } from '~/code/POS/pages/NewOrder/services/constants'
import { acquirerTidHelpers, getOTTCustomBundlePriceValue } from '~/code/POS/pages/NewOrder/services/utils'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { AcquirerAssignedTidList } from '~/code/POS/pages/NewOrder/components/common/AcquirerAssignedTidList'
import { ZeroPriceWarningModal } from '~/code/POS/pages/NewOrder/components/common'
import parentStyles from '../../common/ProductListForm/components/AddProductForm/AddProductForm.scss'
import styles from './AddProduct123SSendForm.scss'

const { Step } = Steps

export const AddProduct123SendForm = observer(({ store, onClose  }: AddProduct123SendFormProps) => {
    const {
        addProductFormData: data, addBundle, showOnlyHardwareOnlyBundles, acquirerAssignedTids, setAcquirerAssignedTids,
        totalTIDsList, setTotalTIDsList, isFormVisible, currentStep, stores, selectedStore, selectedDepartment,
        setSelectedStore, setSelectedDepartment, setAddProductFormData, bundles, loadBundles,
        isTIDDuplicateExist, setIsTIDDuplicateExist
    } = store

    useEffect(() => {
        if (selectedStore?.departments?.length) {
            setSelectedDepartment(selectedStore.departments[0])
        }
    }, [selectedStore])

    const [form] = Form.useForm()
    const [isModalOpen, setModal] = useState<boolean>(false)
    const [bundleCodeHasData, setBundleCodeHasValue] = useState<boolean>(false)
    const [bundleQuantityHasData, setBundleQuantityHasData] = useState<boolean>(false)
    const [bundleAcquirerTIDsHasData, setBundleAcquirerTIDsHasData] = useState<boolean>(false)
    const [bundleAdded, setBundleAdded] = useState<boolean>(false)
    const [_currentStep, setCurrentStep] = useState<number>(currentStep || 0)
    const [acquirerAssignedTIDValidateStatus, setAcquirerAssignedTIDValidateStatus] = useState<ValidateStatus[]>([])
    const { handleAcquirerAssignedTidChange, initiateAcquirerAssignedTids, onTIDBlur } = acquirerTidHelpers({
        acquirerAssignedTids, acquirerAssignedTIDValidateStatus, setAcquirerAssignedTIDValidateStatus, setAcquirerAssignedTids, isOTTFlow: true, selectedDepartment
    })
    const selectedBundleItem = null
    const [selectedBundle, setBundle] = useState({containsVerifoneProduct: false, bundleCode: selectedBundleItem?.params?.bundleCode,
        description: selectedBundleItem?.params?.description || translations().noDescription, acquirerRequiredTids: 0})

    useEffect(() => {
        setCurrentStep(currentStep)
    }, [currentStep])

    useEffect(() => {
        cleanState()
    }, [isFormVisible])

    useEffect(() => {
        acquirerAssignedTids.forEach(tid => {
            if (tid.tid || selectedBundle?.acquirerRequiredTids === 0) {
                setBundleAcquirerTIDsHasData(true)
            } else {
                setBundleAcquirerTIDsHasData(false)
            }
        })
    }, [acquirerAssignedTids, selectedBundle])

    useEffect(() => {
        setTotalTIDsList(data.bundles as OTTSendBundle[])
    }, [acquirerAssignedTids])

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const bundleCodeInitialValue = isEmpty(bundles) ? null : getFieldInitialValue('bundleCode')

    const onShowOnlyHardwareOnlyBundlesChange = (checked: boolean) => {
        showOnlyHardwareOnlyBundles(checked)
        form.setFieldsValue({'bundleCode': undefined})
    }

    const onBundleChange = (value) => {
        const bundle = bundles.find(item => item.value === value)
        setBundle(bundle.params)
        setBundleCodeHasValue(!!value)
        form.setFieldsValue({'bundleCustomPrice': getOTTCustomBundlePriceValue(bundle.params)})
    }

    const onBundleQuantityChange = (event) => {
        setBundleQuantityHasData(!!event.target.value)
    }

    const cleanState = () => {
        setBundle(null)
        form.setFieldsValue({
            bundleCode: null,
            bundleDescription: null,
            bundleQuantity: null,
            bundleCustomPrice: null,
            acquirerAssignedTids: null
        })

        setBundleCodeHasValue(false)
        setBundleQuantityHasData(false)
        setAcquirerAssignedTids([{uid: uuid(), tid: ''}])
        setAcquirerAssignedTIDValidateStatus([])
    }

    const onFinish = (values) => {
        setAddProductFormData({
            ...values,
            storeId: selectedStore?.id,
            departmentId: selectedDepartment?.id,
            bundles: data.bundles
        })
        form.resetFields()
        setCurrentStep(0)
        const _selectedStore = stores[0]
        setSelectedStore(_selectedStore)
        setSelectedDepartment(_selectedStore.departments[0])
        onDrawerClose()
    }

    const onDrawerClose = () => {
        // in order to clean up the form for the next configuration without reinitializing the form
        if (!isEmpty(form.getFieldValue('id'))) {
            form.resetFields()
        }
        onClose && onClose()
    }

    const onBundleDeleteClick = (bundleCode) => {
        store.deleteBundle(bundleCode)
    }

    const handleModal = () => {
        if (store.addDepartmentFormData.departmentAcquirer !== ACQUIRER_IDS.DNA_PAYMENTS
            && form.getFieldValue('bundleCustomPrice') === 0) {
            setModal(true)
        } else {
            onAddBundleClick()
        }
    }

    const handleModalOk = () => {
        setModal(false)
        onAddBundleClick()
    }


    const onAddBundleClick = () => {
        try {
            addBundle({
                containsVerifoneProduct: selectedBundle.containsVerifoneProduct,
                bundleCode: selectedBundle.bundleCode,
                bundleQuantity: form.getFieldValue('bundleQuantity'),
                bundleCustomPrice: form.getFieldValue('bundleCustomPrice'),
                bundleTypeIsHardware: form.getFieldValue('bundleTypeIsHardware'),
                acquirerAssignedTids: _shouldShowTIDFormItem() ? acquirerAssignedTids.reverse() : [],
                description: selectedBundle.description
            }, selectedDepartment.id)
        } catch (err) {
            log('Error: ', err)
            notification.error({message: translations().otherDepartmentsContainVerifoneProduct})
            return
        } finally {
            setModal(false)
        }

        setBundleAdded(true)
        setTimeout(() => { setBundleAdded(false) }, 1000)
        cleanState()
    }

    const onStoreChange = (value) => {
        const _store = stores?.find(item => item.id === value)
        setSelectedStore(_store)
        onDepartmentChange(_store.departments[0].id)
        form.setFieldsValue({'departmentId' : _store.departments[0]?.id})
    }

    const onDepartmentChange = (value) => {
        const department: SDDepartment = selectedStore?.departments?.find(item => item.id === value)
        setSelectedDepartment(department)
    }

    const _shouldShowTIDFormItem = () => {
        const isDNAPaymentsAcquirer = selectedDepartment?.acquirer === ACQUIRER_IDS.DNA_PAYMENTS
        const isDNAPaymentsEcomAcquirer = selectedDepartment?.acquirer === ACQUIRER_IDS.DNA_PAYMENTS_ECOM
        const isTidRequired = selectedBundle?.acquirerRequiredTids === BUNDLE_ACQUIRER_REQUIREMENT_TYPE.DEVICE_TID
            || selectedBundle?.acquirerRequiredTids === BUNDLE_ACQUIRER_REQUIREMENT_TYPE.CNP_TID

        return (isDNAPaymentsAcquirer || isDNAPaymentsEcomAcquirer || selectedDepartment?.acquiresWithDna || selectedDepartment?.acquiresWithDnaEcommerce)
            && isTidRequired
    }

    const storesAndDepartmentsView = () => {
        return <>
            {/* Store Details */}
            <StoreDetails storeIdInitialValue={selectedStore?.id} stores={stores} storeIdFieldName={'storeId'} onStoreChange={onStoreChange}/>

            {/* Department Details */}
            <DepartmentDetails departments={selectedStore?.departments} departmentIdInitialValue={selectedDepartment?.id} departmentIdFieldName={'departmentId'} onDepartmentChange={onDepartmentChange}/>
        </>
    }

    const bundlesView = () => {
        return <>
            {/* Bundles */}
            <div className={parentStyles.AddProductForm_subgroupTitle}>
                {translations().bundles}
            </div>
            {
                <>
                    <Row gutter={16}>
                        <Col xs={24}>
                            <div>
                                <Form.Item
                                    name={'bundleTypeIsHardware'}
                                    className={styles.formItem}
                                    valuePropName={'checked'}
                                    rules={[
                                        {
                                            transform: checked => (checked || undefined),
                                            type: 'boolean'
                                        }
                                    ]}
                                >
                                    <Checkbox onChange={(e) => onShowOnlyHardwareOnlyBundlesChange(e.target.checked)}>
                                        {translations().showOnlyHardwareOnlyBundles}
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={24} className={styles.explanationTextWrapper}>
                            <p className={'smallExplanationText'}>*{ translations().whenMixedOrderHardwareOnlyDeliveredToMerchant }</p>
                        </Col>
                    </Row>
                </>
            }
            <Row gutter={24}>
                <Col xs={24} sm={20}>
                    <Row gutter={24}>
                        <Col xs={24} sm={10}>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().bundle} tooltipText={translations().bundleTooltip} tooltipPosition={'right'} />}
                                name={'bundleCode'}
                                initialValue={bundleCodeInitialValue}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().bundleRequired
                                    }
                                ]}
                            >
                                <BundleSelect
                                    placeholder={translations().bundle}
                                    showSearch={true}
                                    filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    notFoundContent={<Spin size={'small'}/>}
                                    onFocus={() => { loadBundles() }}
                                    selectItems={bundles.slice()}
                                    onChange={onBundleChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7}>
                            <Form.Item
                                label={translations().quantity}
                                name={'bundleQuantity'}
                                initialValue={getFieldInitialValue('bundleQuantity')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const onlyNumberRegexp = ONLY_DIGITS_PATTERN
                                    const value = e.currentTarget.value
                                    _shouldShowTIDFormItem() && initiateAcquirerAssignedTids(+value)
                                    if (!onlyNumberRegexp.test(value) && !isEmpty(value)) {
                                        return form.getFieldValue('bundleQuantity')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().quantityRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().quantity} onChange={onBundleQuantityChange}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7}>
                            <Form.Item
                                label={translations().customPrice}
                                name={'bundleCustomPrice'}
                                initialValue={getFieldInitialValue('bundleCustomPrice')}
                            >
                                <BundlePriceSelect allowClear={true} selectItems={ONE_TWO_THREE_SEND_BUNDLE_CUSTOM_PRICES} bundle={selectedBundle}/>
                            </Form.Item>
                        </Col>
                        {
                            _shouldShowTIDFormItem() && <AcquirerAssignedTidList
                                totalTIDsList={totalTIDsList}
                                acquirerAssignedTids={acquirerAssignedTids}
                                acquirerAssignedTIDValidateStatus={acquirerAssignedTIDValidateStatus}
                                handleAcquirerAssignedTidChange={handleAcquirerAssignedTidChange}
                                onTIDBlur={onTIDBlur}
                                setIsTIDDuplicateExist={setIsTIDDuplicateExist}
                                required={true}
                            />
                        }
                    </Row>
                </Col>
                <Col xs={24} sm={4}>
                    <Row>
                        <Col xs={24} sm={24}>
                            <div className={parentStyles.AddProductForm_buttonsContainer}>
                                <div className={parentStyles.AddProductForm_addToProductButtonContainer}>
                                    <Button
                                        className={null}
                                        onClick={handleModal}
                                        disabled={!bundleCodeHasData || !bundleQuantityHasData || (_shouldShowTIDFormItem() && !bundleAcquirerTIDsHasData) || isTIDDuplicateExist}
                                    >
                                        {translations().addBundle}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider className={parentStyles.divider}>{translations().selectedBundles}</Divider>
            <>
                { data.bundles?.map((bundle, index) =>
                    <BundleListItem key={bundle.bundleCode} isHighlighted={index === 0 && bundleAdded} bundleListItem={bundle as OTTSendBundle} onDeleteClick={ () => onBundleDeleteClick(bundle.bundleCode)}/>) }
                {
                    isEmpty(data.bundles) &&
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={translations().noBundleAdded} key={'EmptyView'}/>
                }
            </>
        </>
    }

    const steps = [
        {
            title: translations().selectStoreAndDepartment,
            content: storesAndDepartmentsView()
        },
        {
            title: translations().selectBundles,
            content: bundlesView()
        }
    ]

    const next = () => {
        setCurrentStep(_currentStep + 1)
    }

    const prev = () => {
        setCurrentStep(_currentStep - 1)
    }

    return <div>
        <ZeroPriceWarningModal
            open={isModalOpen}
            onCancel={() => setModal(false)}
            onOk={() => handleModalOk()}
        />
        <Drawer
            title={translations().addProduct}
            width={'50%'}
            onClose={onDrawerClose}
            visible={isFormVisible}
        >
            <div className={parentStyles.AddProductForm}>
                <Form layout={'vertical'} form={form} onFinish={onFinish}>
                    <Form.Item style={{ display: 'none' }} name={'id'} initialValue={getFieldInitialValue('id')}>
                        <Input />
                    </Form.Item>
                    <Steps size={'small'} current={_currentStep}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className={parentStyles.stepsContent}>{steps[_currentStep].content}</div>
                    <div className={parentStyles.stepsActions}>
                        {_currentStep < steps.length - 1 && (
                            <Button onClick={next}>
                                {translations().proceedToBundlesSelection}
                            </Button>
                        )}
                        {_currentStep === steps.length - 1 && (
                            <Button htmlType={'submit'} disabled={isEmpty(data.bundles)}>
                                {translations().complete}
                            </Button>
                        )}
                        {_currentStep > 0 && (
                            <Button outline={'outlined'} onClick={prev}>
                                {translations().reselectStoreAndDepartment}
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        </Drawer>
    </div>
})
