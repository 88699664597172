import React from "react";
const SvgArrowRight = props => (
  <svg width="1em" height="1em" viewBox="0 0 87 24" fill="none" {...props}>
    <mask
      id="arrow-right_svg__a"
      maskUnits="userSpaceOnUse"
      x={14}
      y={8}
      width={59}
      height={8}
    >
      <path d="M58.147 11h-43.37v2h43.37v3l14.408-4-14.408-4v3z" fill="#fff" />
    </mask>
    <g mask="url(#arrow-right_svg__a)">
      <path fill="#fff" d="M.333 0H87v24H.333z" />
    </g>
  </svg>
);
export default SvgArrowRight;
