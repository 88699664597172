import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    firmwareManagement: string
    latestApplicationInfo: string
    uploadNewVersion: string
    category: string
    installationPackage: string
    email: string
    appSignedBy: string
    versionHistory: string
    displayName: string
    supportedDevices: string
    versionNumberRegex: string
    versionNumberRegexRequired: string
    cancel: string
    saveRegex: string
    changeVersionNumberRegex: string
    theInputRegexProvidesTheFollowingFormat: string
    theFollowingCharactersSupported: string
    major: string
    minor: string
    patch: string
    ifYouWishToContinuePleaseSaveRegex: string
    allowToChangeRegex: string
    developerName: string
    developerEmail: string
    packageDocumentationUri: string
}>()
