import React, { useEffect, useState } from 'react'
import { Checkbox, Input } from '~/code/common/components'
import { InputWithCheckboxProps } from './props'
import styles from './InputWithCheckbox.scss'

export const InputWithCheckbox = ({value, onChange, inputPlaceholder, uncheckedDefaultValue, checkedValue, checkBoxTitle, checkBoxChecked, inputType}: InputWithCheckboxProps) => {
    const [checked, setChecked] = useState(checkBoxChecked)
    const [inputValue, setInputValue] = useState(value || (checked ? checkedValue : uncheckedDefaultValue))

    const onCheckboxChange = (event) => {
        const _checked = event.target.checked
        setChecked(_checked)
        if (_checked) {
            onChange(checkedValue)
        } else {
            const inputVal = value || uncheckedDefaultValue
            onChange(inputVal)
            setInputValue(inputVal)
        }
    }

    useEffect(() => {
        setInputValue(value)

        if (value === 0) {
            setChecked(true)
        }
    }, [value])

    const onInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const onInputBlur = () => {
        onChange(inputValue)
    }


    return <div className={styles.InputWithCheckbox}>
        <Input type={inputType || 'text'} placeholder={inputPlaceholder} value={checked ? '' : inputValue} onChange={onInputChange} disabled={checked} onBlur={onInputBlur}/>
        <div className={styles.checkboxContainer}>
            <Checkbox checked={checked} onChange={onCheckboxChange}>{checkBoxTitle}</Checkbox>
        </div>
    </div>
}
