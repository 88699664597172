import React from 'react'
import translations from './translations'

translations.add('en', {
    warning: 'Warning',
    areYouSureToContinueWithGivenMIDs: (mids) => {
        const chosenMIDs = mids.length > 1 ? mids.reduce((acc, mid, currentIndex) => {
            if (currentIndex === 0) {
                return `${mid}`
            }
            if (currentIndex === mids.length - 1) {
                return `${acc} and ${mid}`
            }
            return `${acc}, ${mid}`
        }, '') : mids[0]
        const be = mids?.length > 1 ? 'are' : 'is'
        return `You have only requested that ${chosenMIDs} ${be} configured for this order, are you sure you wish to continue?`
    },
    continue: 'Continue',
    amend: 'Amend'
})
