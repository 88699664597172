import { persist } from 'mobx-persist'
import { StepModel } from '~/code/common/components/Steps/models/StepModel'
import { Bundle } from '~/code/POS/pages/OrderHistory/models'

export class OrderDetailsModel {
    @persist companyName: string
    @persist orderReference: string
    @persist deliveryName: string
    @persist deliveryTown: string
    @persist deliveryPostcode: string
    @persist('list') bundles: Bundle[]
    @persist placedBy: string
    @persist status: 'success' | 'failed' | 'reversed' | 'cancellationRequested' | 'cancelled' | 'other'
    @persist statusLabel: string
    @persist('list') statusHistory: StepModel[]
    @persist merchantDetails: string
    @persist merchantAddress: string
    @persist mid: string
    @persist acquirerName: string
    @persist lastModifiedDateTime: string
    @persist createdDateTime?: string
    @persist('object') additionalInfo: any
    @persist isExistingMerchant: boolean
    @persist canBeCancelled: boolean
    @persist accountNumber?: string
    @persist isCancelBtnDisabled?: boolean
}

export const createOrderDetailsModel = (): OrderDetailsModel => {
    return {
        accountNumber: '',
        companyName: '',
        orderReference: '',
        deliveryName: '',
        deliveryTown: '',
        deliveryPostcode: '',
        bundles: [],
        placedBy: null,
        status: 'other',
        statusLabel: '',
        statusHistory: [],
        merchantDetails: '',
        merchantAddress: '',
        mid: '',
        acquirerName: '',
        lastModifiedDateTime: '',
        createdDateTime: null,
        additionalInfo: null,
        isExistingMerchant: false,
        canBeCancelled: false
    }
}
