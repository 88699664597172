import { BAD_REQUEST, FORBIDDEN, INTERNAL_SERVER_ERROR, NO_CONTENT, UNAUTHORISED, UNPROCESSABLE_ENTITY } from '~/code/models/ErrorNames'
import { AppStore } from '~/code/AppStore'

export function checkHTTPStatus<T>(promise: Promise<ApiResponse<T>>): Promise<T> {
    return promise.then(result => {
        let errorMessage = ''
        let errorName = ''
        switch (result.status) {
            case 200:
            case 201:
            case 202: {
                return result.result
            }
            case 204: {
                errorMessage = result && JSON.stringify(result)
                errorName = NO_CONTENT
                break
            }
            case 400: {
                errorMessage = 'Bad request'
                errorName = BAD_REQUEST
                break
            }
            case 401: {
                errorMessage = 'Unauthorized'
                errorName = UNAUTHORISED
                AppStore.authStore.refreshAccessToken()
                break
            }
            case 422: {
                errorMessage = result && JSON.stringify(result)
                errorName = UNPROCESSABLE_ENTITY
                break
            }
            default: {
                errorMessage = 'Unknown error, please try again'
            }
        }
        errorMessage = result.error?.message || errorMessage
        const error = new Error(errorMessage)
        error.name = errorName
        throw error
    })
}

export interface ApiResponse<T> {
    result?: T
    error?: ApiResponseError
    status?: number
    headers?: Headers
}

export interface ApiResponseError {
    code?: string
    message?: string
    traceId?: string
    title?: string
}

export function checkODINHTTPStatus<T>(promise: Promise<ApiResponse<T>>): Promise<ApiResponse<T>> {
    return promise.then(result => {
        let errorMessage = ''
        let errorName = ''
        switch (result.status) {
            case 200:
            case 201:
            case 202:
            case 204: {
                return result
            }
            case 400: {
                errorMessage = 'Bad request'
                errorName = BAD_REQUEST
                break
            }
            case 401: {
                errorMessage = 'Unauthorized'
                errorName = UNAUTHORISED
                AppStore.odinAuthStore.refreshAccessToken()
                break
            }
            case 422: {
                errorMessage = result && JSON.stringify(result)
                errorName = UNPROCESSABLE_ENTITY
                break
            }
            case 403: {
                errorMessage = `${result?.error?.title}. traceId - ${result?.error?.traceId}`
                errorName = FORBIDDEN
                break
            }
            case 500: {
                errorMessage = `${result?.error?.title}. traceId - ${result?.error?.traceId}`
                errorName = INTERNAL_SERVER_ERROR
                break
            }
            default: {
                errorMessage = 'Unknown error, please try again'
            }
        }
        errorMessage = result.error?.message || errorMessage
        const error = new Error(errorMessage)
        error.name = errorName
        throw error
    })
}
