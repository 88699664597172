import React, { useEffect } from 'react'
import { ROI_ID, UK_ID } from '~/code/models'
import { isEmpty } from 'dna-common'
import { CountrySelectProps } from './props'
import translations from '~/code/POS/pages/NewOrder/components/iso/InformationForm/translations/translations'
import { Select } from '~/code/common/components'
import { Form } from 'antd'

export const CountrySelect = (props: CountrySelectProps) => {
    const { countries, form, fieldName, initialValue, userCountry } = props
    useEffect(() => {
        const userBasedCountryInitialValue = userCountry === 'roi' ? ROI_ID : UK_ID
        const countryInitialValue = isEmpty(countries) ? null : ( isEmpty(initialValue) ? userBasedCountryInitialValue : Number(initialValue) )
        form.setFieldsValue({
            // @ts-ignore
            [fieldName]: countryInitialValue
        })
    }, [countries])

    return <Form.Item
        label={translations().country}
        name={fieldName}
        rules={[
            {
                required: props.required,
                message: translations().countryRequired
            }
        ]}
    >
        <Select placeholder={translations().country}
                showSearch={true}
                filterOption={(input, option) => {
                    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                selectItems={countries}/>
    </Form.Item>
}
