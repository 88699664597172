import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { notification } from 'antd'
import moment, { Moment } from 'moment'
import { error } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { MerchantMaintenanceScheduleConfig } from '~/code/ODIN/stores/MerchantScheduleStore/models/MerchantMaintenanceScheduleConfig'
import { IMaintenanceScheduleStore } from '~/code/ODIN/pages/MerchantSchedule/MaintenanceSchedule/IMaintenanceScheduleStore'
import { getCronStringFromMaintenanceValues } from '~/code/ODIN/services/cron'
import translations from '~/code/ODIN/pages/MerchantSchedule/translations'
import { fetchSaveMaintenanceConfiguration, fetchMaintenanceConfiguration, fetchOwnerGroupsMaintenanceConfiguration } from './services/fetchers'
import { DEFAULT_NOTIFICATION_DURATION } from '~/code/ODIN/models'

export class MaintenanceScheduleStore implements IMaintenanceScheduleStore {

    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            configurations: observable,
            days: observable,
            cron: observable,
            start: observable,
            end: observable,
            permissions: computed,
            setEnd: action,
            setStart: action,
            saveSchedule: action,
            loadConfiguration: action,
            loadOwnerGroupConfiguration: action,
            parseConfiguration: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    cron: string
    start: Moment = moment().hours(3).minutes(0)
    end: Moment = moment().hours(6).minutes(0)
    days: string[] = []
    configurations: MerchantMaintenanceScheduleConfig = null

    public isLoading: boolean = false

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    public setStart = (start: Moment) => {
        this.start = start
    }

    public setEnd = (end: Moment) => {
        this.end = end
    }

    public setDays = (days: string[]) => {
        this.days = days
    }

    public saveSchedule = () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        const startTime = moment(this.start)
        const endTime = moment(this.end)

        const duration = moment.duration(endTime.diff(startTime))

        // tslint:disable-next-line:radix
        const hours = parseInt(String(duration.asHours()))
        // tslint:disable-next-line:radix
        const minutes = parseInt(String(duration.asMinutes() % 60))

        this.cron = getCronStringFromMaintenanceValues(this.days, startTime.hours(), startTime.minutes())

        const body: MerchantMaintenanceScheduleConfig = {
            MaintenanceWindowSizeHours: hours,
            MaintenanceWindowSizeMinutes: minutes,
            CronExpression: this.cron
        }

        fetchSaveMaintenanceConfiguration(this.parentStore.odinSelectedMerchant?.merchantId, body)
            .then(_ => {
                notification.success({
                    message: translations().configSaved,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .catch((err: Error) => {
                error(`FAILED: ${err.message}`)
                notification.error({
                    message: translations().errorOnSavingConfig,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    loadConfiguration = () => {
        this.isLoading = true

        if (!this.parentStore.odinSelectedMerchant?.merchantId) {
            return
        }

        fetchMaintenanceConfiguration(this.parentStore.odinSelectedMerchant?.merchantId)
            .then((response) => {
                this.parseConfiguration(response.result)
            })
            .catch((err: Error) => {
                error(`FAILED: ${err.message}`)
                this.loadOwnerGroupConfiguration()
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    loadOwnerGroupConfiguration = () => {
        this.isLoading = true

        fetchOwnerGroupsMaintenanceConfiguration(this.parentStore.odinSelectedMerchant?.ownerGroupId)
            .then((response) => {
                this.parseConfiguration(response.result)
            })
            .catch(err => {
                error(`FAILED: ${err.message}`)
                notification.error({
                    message: translations().errorOnSavingConfig,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    parseCronDays = (dayOfWeek: string) => {
        const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

        if (dayOfWeek === '*') {
            this.setDays(days)
        } else if (dayOfWeek.includes('-')) {
            const [firstDay, lastDay] = dayOfWeek.split('-')

            this.setDays(days.splice(days.indexOf(firstDay), days.indexOf(lastDay) + 1))
        } else {
            this.setDays(dayOfWeek.split(','))
        }
    }

    parseConfiguration = (response: MerchantMaintenanceScheduleConfig) => {
        const [_, minutes, hours, __, ___, dayOfWeek] = response.CronExpression.split(' ')

        this.start = moment()
            .hours(+hours)
            .minutes(+minutes)
        this.end = moment()
            .hours(+hours + response.MaintenanceWindowSizeHours)
            .minutes(+minutes + response.MaintenanceWindowSizeMinutes)

        this.parseCronDays(dayOfWeek)

        this.configurations = response
    }
}
