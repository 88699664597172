// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3TZpMWeFyozmz0dwUhWTcH {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row; }\n  ._3TZpMWeFyozmz0dwUhWTcH ._1VA8VYGUHz-04qdfFWYYMM {\n    justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"failureButtonsWrapper": "_3TZpMWeFyozmz0dwUhWTcH",
	"centered": "_1VA8VYGUHz-04qdfFWYYMM"
};
module.exports = exports;
