import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    bundle: string
    description: string
    quantity: string
    customPrice: string
    acquirerAssignedTids: string
    edit: string
    delete: string
}>()

