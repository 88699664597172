import React from 'react'
import { action, makeObservable, observable, override, reaction } from 'mobx'
import moment from 'moment'
import page from 'page'
import { isEmpty, error } from 'dna-common'
import { NewOrderStore } from '../NewOrderStore'
import {
    createDeliveryAddressFormData, createInformationFormData, createOrderTypeFormData, createProductsFormData,
    DeliveryAddressForm,
    InformationForm, InformationFormData, OrderDetailsModel,
    OrderTypeForm, OrderTypeFormData,
    ProcessingDetailsForm,
    ProductsForm
} from '~/code/POS/pages'
import {
    deliveryAddressFormDataToSummaryBlock,
    informationFormDataToSummaryBlock,
    orderDetailsToSummaryBlock,
    orderTypeFormDataToSummaryBlock, processingDetailsFormDataToSummaryBlock, productsFormDataToSummaryBlock
} from '~/code/POS/pages/NewOrder/services/order-summary-utils'
import { SummaryBlockData } from '~/code/common/components'
import { getCurrencyOfCountry } from '~/code/services'
import { Bundle } from '~/code/POS/pages/NewOrder/models/Order/Bundle'
import { createPlaceOrderRequestFromData as createISOPlaceOrderRequestFromData, placeOrderRequestToDeliveryAddress, placeOrderRequestToInformation, placeOrderRequestToProcessingDetails, placeOrderRequestToProducts } from '~/code/POS/pages/NewOrder/services/mapper'
import { createValidCompanyNumber } from '~/code/stores/NewOrderStore/services/utils'
import translations from '~/code/POS/pages/NewOrder/translations/translations'
import { IInformationFormStore } from '~/code/POS/pages/NewOrder/components/iso/InformationForm/IInformationFormStore'
import { InformationFormStore } from '~/code/stores/NewOrderStore/iso/InformationFormStore'
import { IOrderTypeFormStore } from '~/code/POS/pages/NewOrder/components/iso/OrderTypeForm/IOrderTypeFormStore'
import { OrderTypeFormStore } from '~/code/stores/NewOrderStore/iso/OrderTypeFormStore'
import { AppStore } from '~/code/AppStore'
import { IDeliveryAddressFormStore } from '~/code/POS/pages/NewOrder/components/iso/DeliveryAddressForm/IDeliveryAddressFormStore'
import { DeliveryAddressStore } from '~/code/stores/NewOrderStore/iso/DeliveryAddressStore'
import { IProcessingDetailsFormStore } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/IProcessingDetailsFormStore'
import { ProcessingDetailsFormStore } from '~/code/stores/NewOrderStore/iso/ProcessingDetailsFormStore'
import { IProductsFormStore } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/IProductsFormStore'
import { ProductsFormStore } from '~/code/stores/NewOrderStore/iso/ProductsFormStore'
import { OrderInfo } from '~/code/stores/OrderHistoryStore/models/OrderInfo'
import { OrderDetails } from '~/code/stores/OrderHistoryStore/models/OrderDetails'
import { PlaceOrderRequest } from '~/code/stores/NewOrderStore/models/PlaceOrderRequest'
import { convertPropertyNames, pascalCaseToCamelCase } from '~/code/services/case-converter'
import { ConfigCountry, MerchantType } from '~/code/POS/pages/NewOrder/models/constants'
import { ROI_ID, UK_ID } from '~/code/models'
import { message } from 'antd'
import orderDetailsTranslations from '~/code/POS/pages/OrderHistory/pages/OrderDetails/translations/translations'
import { createProcessingDetailsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/models'
import { fetchISOMerchants } from '../services/fetchers'
import { INewOrderISOStore } from '~/code/POS/pages/NewOrder/models/iso/INewOrderISOStore'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'

export class NewOrderISOStore extends NewOrderStore implements INewOrderISOStore {

    constructor () {
        super()

        makeObservable(this, {
            generateCreateOrderSteps: override,
            generatePlaceOrderRequestAndURL: override,
            createOrderFromStringAndOrder: override,
            createOrderFromOrderInfo: override,
            onMerchantTypeChange: override,
            clearAndSetUp: override,
            orderSummaryData: override,
            placeOrder: override,
            getProcessingDetailsFormStore: observable,
            handleFormSubmit: action,
            handleGoBack: action,
            searchExistingMerchants: action
        })

        reaction(() => this.selectedItem, (selectedItem) => {
            if (this.placeOrderConfigStore.merchantType === 'new' && selectedItem) {
                const company = selectedItem?.additionalInfo
                if (this.placeOrderConfigStore.country === 'uk') {
                    this.informationFormStore.informationFormData = {
                        ...this.informationFormStore.informationFormData,
                        ...this.generateInformationFormDataFromCHResponse(company)
                    }
                } else {
                    this.informationFormStore.informationFormData = {
                        ...this.informationFormStore.informationFormData,
                        ...this.generateInformationFormDataFromCROResponse(company)
                    }
                }
            } else {
                // also we need to load stores immediately
                this.orderTypeFormStore.loadStores()
            }
        }, {fireImmediately: false})

        reaction( () => this.orderTypeFormStore.selectedOrderType, (orderType) => {
            this.generateCreateOrderSteps()
        }, {fireImmediately: true})

        reaction(() => this.placeOrderConfigStore.merchantType, (merchantType) => {
            this.generateCreateOrderSteps()
            this.informationFormStore.canGoBack = merchantType === 'existing'
        })

        reaction(() => this.deliveryAddressFormStore.deliveryAddressFormData.useMerchantAddress, (use: boolean) => {
            const deliveryAddressFormData = this.deliveryAddressFormStore.deliveryAddressFormData
            const informationFormData = this.informationFormStore.informationFormData
            deliveryAddressFormData.addressLine1 = use ? informationFormData.addressLine1 : ''
            deliveryAddressFormData.addressLine2 = use ? informationFormData.addressLine2 : ''
            deliveryAddressFormData.town = use ? informationFormData.town : ''
            deliveryAddressFormData.county = use ? informationFormData.county : ''
            deliveryAddressFormData.country = use ? informationFormData.country : 0
            deliveryAddressFormData.postcode = use ? informationFormData.postcode : ''
        })
    }

    private readonly informationFormStore: IInformationFormStore = new InformationFormStore(this)
    private readonly orderTypeFormStore: IOrderTypeFormStore = new OrderTypeFormStore(this)
    private readonly deliveryAddressFormStore: IDeliveryAddressFormStore = new DeliveryAddressStore(this)
    private readonly processingDetailsFormStore: IProcessingDetailsFormStore = new ProcessingDetailsFormStore(this)
    private readonly productsFormStore: IProductsFormStore = new ProductsFormStore(this)

    informationForm = <InformationForm store={this.informationFormStore} />
    orderTypeForm = <OrderTypeForm store={this.orderTypeFormStore}/>
    deliveryAddressForm = <DeliveryAddressForm store={this.deliveryAddressFormStore}/>
    processingDetailsForm = <ProcessingDetailsForm store={this.processingDetailsFormStore}/>
    productsForm = <ProductsForm store={this.productsFormStore}/>

    // TODO maybe this method will be in some other separate store, that works only with steps: generates and navigates
    generateCreateOrderSteps() {
        let steps: any[] = [
            {title: translations().orderType, key: 'orderType', children: this.orderTypeForm},
            {title: translations().information, key: 'information', children: this.informationForm},
            {title: translations().deliveryAddress, key: 'deliveryAddress', children: this.deliveryAddressForm},
            {title: translations().processingDetails, key: 'processingDetails', children: this.processingDetailsForm},
            {title: translations().products, key: 'products', children: this.productsForm}
        ]
        if (this.placeOrderConfigStore.merchantType === 'existing') {
            if (this.orderTypeFormStore.selectedOrderType !== 'newStoreAndDepartment') {
                steps = steps.filter(item => item.key !== 'information')
            }
            if (this.orderTypeFormStore.selectedOrderType === 'newBundle') {
                steps = steps.filter(item => item.key !== 'processingDetails')
            }
        } else {
            steps = steps.filter(item => item.key !== 'orderType')
        }
        this.createOrderSteps = steps.map((item, index) => {return {...item, order: index + 1}})
    }

    get orderSummaryData() {
        const data: SummaryBlockData[] = []

        const _orderReference = this.accountNumber()
        if (isEmpty(this.orderReference)) { this.orderReference = `${_orderReference}-${moment().unix()}` }
        data.push(orderDetailsToSummaryBlock(this.orderReference, this.selectedEmployeeCompany?.title))

        this.createOrderSteps.forEach(step => {
            switch (step.key) {
                case 'orderType': {
                    data.push(orderTypeFormDataToSummaryBlock(this.orderTypeFormStore.orderTypeFormData, this.orderTypeFormStore.stores, this.orderTypeFormStore.departments, this.selectedCompanyName))
                    break
                }
                case 'information': {
                    data.push(informationFormDataToSummaryBlock(this.informationFormStore.informationFormData, AppStore.countriesStore.countries))
                    break
                }
                case 'deliveryAddress': {
                    data.push(deliveryAddressFormDataToSummaryBlock(this.deliveryAddressFormStore.deliveryAddressFormData, AppStore.countriesStore.countries))
                    break
                }
                case 'processingDetails': {
                    data.push(processingDetailsFormDataToSummaryBlock(this.processingDetailsFormStore.processingDetailsFormData, AppStore.acquirersStore.acquirers, AppStore.merchantCategoryCodesStore.merchantCategoryCodes, AppStore.currenciesStore.currencies, this.processingDetailsFormStore.preferredSubmissions, this.processingDetailsFormStore.brandingOrganisations))
                    break
                }
                case 'products': {
                    const _bundles: Bundle[] = this.productsFormStore.bundles.map(item => item.params)
                    data.push(productsFormDataToSummaryBlock(this.productsFormStore.productsFormData, _bundles, this.productsFormStore.acquirerAssignedTids))
                    break
                }
            }
        })
        return data
    }

    placeOrder () {
        super.placeOrder()
        this.productsFormStore.setAcquirerAssignedTids([])
    }

    getProcessingDetailsFormStore(): IProcessingDetailsFormStore {
        return this.processingDetailsFormStore
    }

    getOrderTypeFormStore(): IOrderTypeFormStore {
        return this.orderTypeFormStore
    }

    generatePlaceOrderRequestAndURL() {
        const isoCustomerId = this.accountNumber()

        // merchantId is used only for existing merchants
        const merchantId = this.selectedItem?.additionalInfo?.merchantId || ''
        const companyNumber = createValidCompanyNumber(this.placeOrderConfigStore.country, this.selectedItem?.additionalInfo, this.informationFormStore.informationFormData.companyNumber)

        const request = createISOPlaceOrderRequestFromData(
            {...this.informationFormStore.informationFormData, companyNumber},
            this.orderTypeFormStore.orderTypeFormData,
            this.deliveryAddressFormStore.deliveryAddressFormData,
            this.processingDetailsFormStore.processingDetailsFormData,
            this.productsFormStore.productsFormData,
            this.internalIdentifier,
            isoCustomerId,
            this.orderReference,
            merchantId,
            this.createOrderSteps,
            this.productsFormStore.acquirerAssignedTids)
        const requestURL = `/api/iso-orders/place-order`

        return {requestURL, request}
    }

    createOrderFromStringAndOrder (previousOrder: string, orderDetailsModel: OrderDetailsModel) {
        const order: PlaceOrderRequest = convertPropertyNames(JSON.parse(previousOrder), pascalCaseToCamelCase)

        // determine the merchant type, was the order for a new or an existing merchant
        const orderDetails = order.orders[0]
        if (isEmpty(orderDetails?.existingMerchantId) && isEmpty(orderDetails.existingStoreId) && isEmpty(orderDetails.existingDepartmentId)) {
            this.placeOrderConfigStore.setSelectedMerchantType('new')

            // determine the organisation type
            const organisationType = orderDetails?.merchant?.companyRegistrationNumber ? 'company' : 'soleTrader'
            this.placeOrderConfigStore.setSelectedOrganisationType(organisationType)

            // determine the country
            const country: ConfigCountry = orderDetails?.address?.countryCode === UK_ID ? 'uk' : (orderDetails?.address?.countryCode === ROI_ID ? 'roi' : 'other')
            this.placeOrderConfigStore.setSelectedCountryLocal(country)
        } else {
            this.placeOrderConfigStore.setSelectedMerchantType('existing')
            if (!isEmpty(orderDetails.existingStoreId) && !isEmpty(orderDetails.existingDepartmentId)) {
                this.orderTypeFormStore.selectedOrderType = 'newBundle'
            } else if (!isEmpty(orderDetails.existingStoreId)) {
                this.orderTypeFormStore.selectedOrderType = 'newDepartment'
            } else {
                this.orderTypeFormStore.selectedOrderType = 'newStoreAndDepartment'
            }
        }

        // for now it's impossible to make an order if the order type was new bundle as the order does not contain merchant id => we cannot fetch stores
        if (this.placeOrderConfigStore.merchantType === 'existing' && this.orderTypeFormStore.selectedOrderType === 'newBundle') {
            message.error(orderDetailsTranslations().cannotRetryDueToLackOfInfo)
            this.clearAndSetUp()
            this.placeOrderConfigStore.setSelectedMerchantType('new')
            return
        }

        // clear the forms and set up
        this.clearAndSetUp()

        // fill the forms with new data
        this.orderTypeFormStore.orderTypeFormData.orderType = this.orderTypeFormStore.selectedOrderType
        this.informationFormStore.informationFormData = placeOrderRequestToInformation(order)
        this.deliveryAddressFormStore.deliveryAddressFormData = placeOrderRequestToDeliveryAddress(order)
        this.processingDetailsFormStore.processingDetailsFormData = placeOrderRequestToProcessingDetails(order)
        this.productsFormStore.productsFormData = placeOrderRequestToProducts(order)

        if (isEmpty(this.informationFormStore.informationFormData.companyName)) {
            this.informationFormStore.informationFormData.companyName = orderDetailsModel.companyName
            this.informationFormStore.informationFormData.companyNumber = orderDetailsModel.mid
        }

        this.selectedItem = {
            identifier: this.informationFormStore.informationFormData.companyNumber,
            title: this.informationFormStore.informationFormData.companyName,
            description: '',
            additionalInfo: null
        }

        this.generateCreateOrderSteps()

        page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
    }

    createOrderFromOrderInfo (orderInfo: OrderInfo) {
        this.clearAndSetUp()
        const orderDetails: OrderDetails = orderInfo.details[0]
        this.selectedItem = {
            identifier: null,
            title: orderDetails.merchantName,
            description: '',
            additionalInfo: null
        }
        this.informationFormStore.informationFormData = {
            ...this.informationFormStore.informationFormData,
            companyName: orderDetails.merchantName,
            tradingAs: orderDetails.merchantName,
            town: orderDetails.deliveryTown,
            postcode: orderDetails.deliveryPostcode
        }
        this.deliveryAddressFormStore.deliveryAddressFormData = {
            ...this.deliveryAddressFormStore.deliveryAddressFormData,
            town: orderDetails.deliveryTown,
            postcode: orderDetails.deliveryPostcode,
            recipientName: orderDetails.deliveryName
        }
        this.processingDetailsFormStore.processingDetailsFormData = {
            ...this.processingDetailsFormStore.processingDetailsFormData,
            acquirer: `${orderDetails.acquirerId}`,
            merchantId: orderDetails.mid
        }
        this.productsFormStore.productsFormData = {
            ...this.productsFormStore.productsFormData,
            bundleCode: orderDetails.bundleCode,
            quantity: orderDetails.quantity
        }

        page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
    }

    onMerchantTypeChange(merchantType: MerchantType) {
        if (merchantType === 'existing') {
            this.orderTypeFormStore.selectedOrderType = 'newBundle'
        }
    }

    clearAndSetUp () {
        super.clearAndSetUp()
        this.informationFormStore && (this.informationFormStore.informationFormData = createInformationFormData())
        if (this.orderTypeFormStore) {
            this.orderTypeFormStore.orderTypeFormData = createOrderTypeFormData(this.orderTypeFormStore?.selectedOrderType);
            (this.orderTypeFormStore as OrderTypeFormStore).clearStoresAndDepartments()
        }
        this.deliveryAddressFormStore && (this.deliveryAddressFormStore.deliveryAddressFormData = createDeliveryAddressFormData())
        this.processingDetailsFormStore && (this.processingDetailsFormStore.processingDetailsFormData = createProcessingDetailsFormData())
        this.productsFormStore && (this.productsFormStore.productsFormData = createProductsFormData())
    }

    handleFormSubmit(data, stepName) {
        switch (stepName) {
            case InformationFormStore.stepName: {
                const informationFormData = data as InformationFormData

                this.processingDetailsFormStore.processingDetailsFormData.accountName = this.informationFormStore.informationFormData.tradingAs
                const countryCurrency = getCurrencyOfCountry(informationFormData.country)
                if (countryCurrency) {
                    this.processingDetailsFormStore.processingDetailsFormData.currency = Number(countryCurrency.currency.numeric)
                }

                break
            }
            case OrderTypeFormStore.stepName: {
                const orderTypeFormData = data as OrderTypeFormData
                if (orderTypeFormData.orderType === 'newStoreAndDepartment') {
                    this.informationFormStore.informationFormData = {
                        ...this.informationFormStore.informationFormData,
                        ...createInformationFormData()
                    }
                } else {
                    this.informationFormStore.informationFormData = {
                        ...this.informationFormStore.informationFormData,
                        ...this.orderTypeFormStore.selectedStore
                    }
                }

                // TODO remove later if tests pass without this functionality
                // if (isEmpty(this.informationFormData?.addressLine1)) {
                //     const store = this.orderTypeFormStore.selectedStore
                //     this.informationFormData.addressLine1 = store?.addressLine1
                //     this.informationFormData.postcode = store?.postcode
                // }
            }
        }

        if (this.createOrderSteps[this.currentCreateOrderStep].key === 'products') {
            this.currentStep++
        } else {
            this.currentCreateOrderStep++
        }
    }

    handleGoBack() {
        if (this.steps[this.currentStep] !== 'creatingOrder') {
            this.currentStep--
        } else {
            this.currentCreateOrderStep--
        }
    }

    loadStores() {
        this.orderTypeFormStore.loadStores()
    }

    public searchExistingMerchants(value) {
        const data = {
            pageNumber: 1,
            pageSize: 10,
            searchValue: value
        }
        if (this.internalIdentifier) {
           data['internalIdentifier'] = this.internalIdentifier // this gets encoded when the data object is converted into query params
        }

        return fetchISOMerchants(data)
        .then( response => {
            if (response && response.length > 0) {
                this.existingMerchants = response.map(merchant => {
                    return {
                        id: merchant.merchantId,
                        name: merchant.merchantName
                    }
                })
            } else {
                this.existingMerchants = []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
    }
}
