import React from 'react'
import { Popconfirm } from 'antd'
import { observer } from 'mobx-react'
import { Button } from '~/code/common/components'
import { ButtonWithConfirmationProps } from './props'
import styles from './ButtonWithConfirmation.scss'

export const ButtonWithConfirmation = observer((props: ButtonWithConfirmationProps) => {
    const { children, type, statusType, confirmButtonTitle, cancelButtonTitle, popConfirmTitle, onConfirm, loading, disabled } = props

    const renderButton = () => {
        if (type === 'link') {
            return <span className={styles.confirmLink}>{ children }</span>
        } else {
            return <Button key={'submit'} type={'primary'} status={statusType} disabled={disabled} loading={loading}>
                { children }
            </Button>
        }
    }

    return <div className={styles.ButtonWithConfirmation}>
        <Popconfirm
            placement={'topRight'}
            title={popConfirmTitle}
            onConfirm={onConfirm}
            okText={confirmButtonTitle}
            cancelText={cancelButtonTitle}
            overlayClassName={ styles.PopConfirmDanger }
        >
            { renderButton() }
        </Popconfirm>
    </div>
})
