import translations from '../translations'

export function generateStoreEntityData(store) {
    return {
        storeId: {
            label: translations().storeId,
            value: store.merchantStoreId
        },
        addressLine1: {
            label: translations().addressLine1,
            value: store.address1
        },
        postcode: {
            label: translations().postcode,
            value: store.postZipCode
        }
    }
}

export function generateDepartmentEntityData(department) {
    return {
        departmentId: {
            label: translations().departmentId,
            value: department.merchantDepartmentId
        }
    }
}
