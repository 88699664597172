import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form } from 'antd'
import { Select, GroupTitle } from '~/code/common/components'
import { Prop } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/StoreCard/components/Prop/Prop'
import translations from '../../translations/translations'
import { StoreDetailsProps } from './props'
import styles from './StoreDetails.scss'

export const StoreDetails = observer(({ stores, storeIdFieldName, storeIdInitialValue, onStoreChange }: StoreDetailsProps) => {

    const getStores = () => {
        return stores.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }

    const [selectedStore, setSelectedStore] =
        useState(storeIdInitialValue && stores.find(item => item.id === storeIdInitialValue))

    const onStoreSelectChange = (value) => {
        setSelectedStore(stores.find(item => item.id === value))
        onStoreChange(value)
    }

    return <div className={styles.StoreDetails}>
        <Row>
            <Col xs={24}>
                <GroupTitle content={translations().store}/>
            </Col>
        </Row>
        <Row gutter={24}>
            <Col xs={24} sm={12}>
                <Form.Item
                    label={translations().storeName}
                    name={storeIdFieldName}
                    initialValue={storeIdInitialValue} // TODO this initial value must be set in a higher component that hosts the form
                >
                    <Select placeholder={translations().store}
                               onSelect={onStoreSelectChange}
                               selectItems={getStores()}/>
                </Form.Item>
            </Col>
        </Row>
        { selectedStore &&
        <Row gutter={16}>
            <Col sm={10}>
                <Prop label={translations().address}
                      value={ selectedStore?.addressLine1 ? `${selectedStore?.addressLine1}${selectedStore?.addressLine2 ? ', ' + selectedStore?.addressLine2 : ''}` : '' }/>
            </Col>
            <Col sm={4}>
                <Prop label={translations().postcode}
                      value={selectedStore?.postcode || ''}/>
            </Col>
            <Col sm={5}>
                <Prop label={translations().town}
                      value={selectedStore?.town || ''}/>
            </Col>
            <Col sm={5}>
                <Prop label={translations().country}
                      value={selectedStore?.countryName || ''}/>
            </Col>
        </Row>
        }
    </div>
})
