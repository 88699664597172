import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    storeId: string
    store: string
    storeRequired: string
    addressLine1: string
    postcode: string
    departmentId
    department: string
    departmentRequired: string
}>()

