import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    category: string
    details: string
    supportedDeviceTypes: string
    uploadedDateTime: string
    appSignedBy: string
    needToFileUpload: string
    applicationCreatedSuccessfully
}>()
