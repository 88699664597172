import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, Input, List, Form, Row, Col, Typography, Select } from 'antd'
import { Button, ListActions } from '~/code/common/components'
import { AvailablePackage } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AvailablePackage'
import { PrioritySelect } from '~/code/ODIN/pages/DeviceManagementDetails/components/PrioritySelect/PrioritySelect'
import { DetailsForPerformingInstallation } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DetailsForPerformingInstallation'
import translations from './translations'
import { PushAppsModalProps } from './props'
import styles from './PushNewAppsModal.scss'

const {Search} = Input

export const PushNewAppsModal = observer((props: PushAppsModalProps) => {
    const {store, deviceId, onFinishFailed} = props
    const [packageName, setPackageName] = useState<string>('')
    const [form] = Form.useForm()

    useEffect(() => {
        if (store.isPushAppsModalOpen) {
            store.loadDeviceDetailsById(deviceId)
            store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId, {
                packageTypeId: 1
            })
            store.notificationPriorityStore.loadNotificationPriorities()
        }
    }, [store.isPushAppsModalOpen])

    // tslint:disable-next-line:no-empty
    useEffect(() => {
    }, [store.applicationFirmwareManagementStore.isVersionLoading])

    const renderAvailablePackagesSelect = (packageId: number) => (
        <Select
            loading={store.applicationFirmwareManagementStore.isVersionLoading}
            onClick={() => {
                store.applicationFirmwareManagementStore.setPackageId(packageId)
            }}
            placeholder={translations().selectPackageVersion}
            style={{width: 200}}
        >
            {
                store.applicationFirmwareManagementStore.appVersions?.map(version => (
                    <Select.Option key={version.packageVersionId} value={version.packageVersionId}>{version.version}</Select.Option>
                ))
            }
        </Select>
    )

    const onSearch = () => {
        store.applicationFirmwareManagementStore.setAvailablePackageName(packageName)
        store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId)
    }

    const onCancel = () => {
        store.setPriorityId(null)
        store.setIsPushAppsModalOpen(false)
        store.applicationFirmwareManagementStore.setAvailablePackageName('')
        form.resetFields()
    }

    const onSubmit = (item: DetailsForPerformingInstallation) => {
        store.applicationFirmwareManagementStore.installApp(deviceId, item)
    }

    return (
        <>
            <Modal
                title={`${store.deviceDetails.serialNumber} - ${translations().updateApp}`}
                visible={store.isPushAppsModalOpen}
                width={1000}
                onCancel={onCancel}
                footer={[
                    <Button key="back" className={styles.backButton} onClick={onCancel}>
                        {translations().cancel}
                    </Button>
                ]}
            >
                <div className={styles.pushAppHeader}>
                    <PrioritySelect
                        value={store.priorityId}
                        dataSource={store.notificationPriorityStore.notificationPriorities}
                        onChange={(value) => store.setPriorityId(value)}
                    />
                    <Search
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPackageName(e.target.value)}
                        value={packageName}
                        placeholder={translations().searchPackageNameOrDisplayName}
                        allowClear
                        onSearch={onSearch}
                        style={{width: 500}}
                    />
                </div>

                {store.permissions?.includes('odin.deviceManagement.availablePackages') && (
                    <Form
                        id={`installNewApp`}
                        name={`installNewApp`}
                        layout="horizontal"
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >
                        <ListActions
                            className={styles.listActions}
                            pageInfo={{
                                from: store.applicationFirmwareManagementStore.availablePackages.length
                                    ? store.applicationFirmwareManagementStore.pageNumber * store.applicationFirmwareManagementStore.pageSize - 9 : 0,
                                to: store.applicationFirmwareManagementStore.pageNumber * store.applicationFirmwareManagementStore.pageSize > store.applicationFirmwareManagementStore.totalCount
                                    ? store.applicationFirmwareManagementStore.totalCount
                                    : store.applicationFirmwareManagementStore.pageNumber * store.applicationFirmwareManagementStore.pageSize,
                                total: store.applicationFirmwareManagementStore.totalCount
                            }}
                            currentTotalAmount={store.applicationFirmwareManagementStore.availablePackages?.length}
                            onReloadClick={() => store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId, {
                                packageTypeId: 1
                            })}
                            onGoNextPageClick={() => store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId, {
                                packageTypeId: 1,
                                pageNumber: store.applicationFirmwareManagementStore.pageNumber + 1
                            })}
                            onGoPrevPageClick={() => store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId, {
                                packageTypeId: 1,
                                pageNumber: store.applicationFirmwareManagementStore.pageNumber - 1
                            })}
                            isNextPageDisabled={store.applicationFirmwareManagementStore.pageNumber >= store.applicationFirmwareManagementStore.totalPageItems}
                            isPrevPageDisabled={store.applicationFirmwareManagementStore.pageNumber <= 1}
                            isGoToFirstPageDisabled={store.applicationFirmwareManagementStore.pageNumber <= 1}
                            isGoToLastPageDisabled={store.applicationFirmwareManagementStore.pageNumber > store.applicationFirmwareManagementStore.totalPageItems}
                            onGoLastPageClick={() => store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId, {
                                packageTypeId: 1,
                                pageNumber: store.applicationFirmwareManagementStore.totalPageItems
                            })}
                            onGoFirstPageClick={() => store.applicationFirmwareManagementStore.loadAvailablePackages(deviceId, {
                                packageTypeId: 1
                            })}
                        />
                        <List
                            header={
                                <Row className={styles.listHeader}>
                                    <Col span={6}>
                                        <Typography.Text strong>{translations().appDisplayName}</Typography.Text>
                                    </Col>
                                    <Col span={18}>
                                        <Typography.Text strong>{translations().appDeveloper}</Typography.Text>
                                    </Col>
                                </Row>
                            }
                            loading={store.applicationFirmwareManagementStore.isLoading}
                            itemLayout="vertical"
                            size="large"
                            pagination={false}
                            renderItem={(item: AvailablePackage) => (
                                <List.Item key={item.displayName}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={12} md={6}>
                                            {item.displayName}
                                        </Col>
                                        <Col xs={24} sm={12} md={6}>
                                            {item.developerName}
                                        </Col>
                                        <Col xs={24} sm={12} md={6}>

                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: translations().packageVersionRequired
                                                    }
                                                ]}
                                                name={`packageVersionId ${item.displayName}`}
                                            >
                                                {renderAvailablePackagesSelect(item.packageId)}
                                            </Form.Item>
                                        </Col>
                                        <Col offset={3} xs={21} sm={9} md={3}>
                                            <Form.Item shouldUpdate>
                                                {(formRef) => {
                                                    const isDisabled = (!(store.priorityId === null || typeof (store.priorityId) === 'undefined') && Boolean(formRef.getFieldValue(`packageVersionId ${item.displayName}`)))
                                                    return (
                                                        <Button disabled={!isDisabled} type="primary" htmlType="submit" onClick={() => onSubmit({
                                                            priorityId: store.priorityId,
                                                            packageVersionId: formRef.getFieldValue(`packageVersionId ${item.displayName}`)
                                                        })}>
                                                            {translations().install}
                                                        </Button>
                                                    )
                                                }}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )
                            }
                            dataSource={store.applicationFirmwareManagementStore.availablePackages}
                        />
                    </Form>
                )}
            </Modal>
        </>
    )
})
