import React from 'react'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'
import { ListItem } from '../ListItem'
import translations from './translations'
import styles from './UserDetails.scss'

export const UserDetails = ({userInfo}: {userInfo: UserInfo}) => {
    const items: {title: string, description: string}[] = []
    items.push({title: translations().email, description: userInfo.email})
    items.push({title: translations().companyName, description: `${userInfo.companyName} (${userInfo.accountNumber})` })

    return <div className={styles.UserDetails}>
        {items.map(item => {
            return <ListItem title={item.title} description={item.description} key={item.title}/>
        })}
    </div>
}

