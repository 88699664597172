// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3PbVckvdrwmpIbxaBxXaY9 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n  ._3PbVckvdrwmpIbxaBxXaY9 ._47oMq7iFn_LVy6lcbTHkR {\n    width: 100%;\n    max-width: 140px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis; }\n", ""]);
// Exports
exports.locals = {
	"container": "_3PbVckvdrwmpIbxaBxXaY9",
	"text": "_47oMq7iFn_LVy6lcbTHkR"
};
module.exports = exports;
