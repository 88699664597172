// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2R-jhHXR8GhSVDBSKwH4op {\n  margin-right: 8px; }\n\n._2DzrZplvIb-eT1sxql_cl- {\n  display: flex;\n  justify-content: space-between; }\n\n._3HPJOw90j578OYYrfGQPix {\n  margin-top: 25px;\n  margin-left: 25px; }\n\n._3C2-ttGC-kJJIi1Ke6TZ5y {\n  margin-top: 25px; }\n", ""]);
// Exports
exports.locals = {
	"backButton": "_2R-jhHXR8GhSVDBSKwH4op",
	"pushAppHeader": "_2DzrZplvIb-eT1sxql_cl-",
	"listHeader": "_3HPJOw90j578OYYrfGQPix",
	"listActions": "_3C2-ttGC-kJJIi1Ke6TZ5y"
};
module.exports = exports;
