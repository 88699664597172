import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Collapse, Row } from 'antd'
import { isEmpty } from 'dna-common'
import { CollapsiblePanel, FieldData, RowData } from './models'
import { Divider, Header, SubHeader } from './components'
import { SummaryBlockProps } from './props'
import styles from './SummaryBlock.scss'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

export const SummaryBlock = observer((props: SummaryBlockProps) => {
    const { data } = props

    const [isPasswordHidden, showPassword] = useState(false)

    const renderStars = (strLength: number) => {
        const str = ''
        return str.padStart(strLength, '*')
    }

    const renderField = (field: FieldData, index: number) => {
        if (field.type === 'subHeader') {
            return <SubHeader key={`subHeader-${index}`}>
                {field.value}
            </SubHeader>
        } else if (field.type === 'subHeaderBold') {
            return <SubHeader style={'bold'} key={`subHeaderBold-${index}`}>
                {field.value}
            </SubHeader>
        } else if (field.type === 'subHeaderSmall') {
            return <SubHeader style={'small'} key={`subHeaderSmall-${index}`}>
                {field.value}
            </SubHeader>
        } else if (field.type === 'divider') {
            return <Divider key={`divider-${index}`}/>
        } else if (field.type === 'password') {
            return <div className={styles.passwordFieldContainer}>
                <div className={styles.field} key={`field-${index}`}>
                    {field.label && <div className={styles.fieldLabel} key={`field-label-${index}`}>{field.label}</div>}
                    <div className={styles.fieldValue} key={`field-value-${index}`}>
                        {
                            !isPasswordHidden
                                ? renderStars(field.value.toString().length)
                                : field.renderValue ? field.renderValue() : (field.value || '')
                        }
                    </div>
                </div>
                <div>
                    <Button
                        className={styles.showPasswordButton}
                        type={'link'}
                        icon={isPasswordHidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                        onClick={() => showPassword(!isPasswordHidden)}
                    />
                </div>
            </div>
        } else {
            return <div className={styles.field} key={`field-${index}`}>
                {field.label && <div className={styles.fieldLabel} key={`field-label-${index}`}>{field.label}</div>}
                <div className={styles.fieldValue} key={`field-value-${index}`}>
                    {
                        field.renderValue ? field.renderValue() : (field.value || '')
                    }
                </div>
            </div>
        }
    }

    const renderRow = (row: RowData, key: string) => {
        return <>
            { row.fields && row.fields.map((field, index) => {
                if (field instanceof RowData || field.hasOwnProperty('fields')) {
                    return <Col xs={field.span && field.span.xs ? field.span.xs : 24}
                                sm={field.span && field.span.sm ? field.span.sm : 24}
                                key={`col-${index}`}>
                        {renderRowData(field as unknown as RowData, `innerRowData-${index}`)}
                    </Col>
                } else {
                    if (field.type === 'collapsiblePanel') {
                        return <Collapse.Panel header={(field.value as CollapsiblePanel).header} key={index}>
                            {
                                (field.value as CollapsiblePanel).content.map((rowData, rowDataIndex) => {
                                    return renderRowData(rowData, `collapseInnerRowData-${rowDataIndex}`)
                                })
                            }
                        </Collapse.Panel>
                    } else if (field.type !== 'divider' && isEmpty(field.value) && !field.renderValue) {
                        return null
                    }
                    return <Col xs={field.span && field.span.xs ? field.span.xs : 24}
                                sm={field.span && field.span.sm ? field.span.sm : 24}
                                offset={field.span?.offset || 0}
                                key={`col-${index}`}>
                        {
                            renderField(field, index)
                        }
                    </Col>
                }
            })}
        </>
    }

    const renderRowData = (row: RowData, key: string) => {
        return <Row gutter={16} key={key}>
            {
                row.type === 'collapse' ?
                    <Col xs={row.span && row.span.xs ? row.span.xs : 24}
                         sm={row.span && row.span.sm ? row.span.sm : 24}
                         key={`row-${key}`}>
                        <Collapse defaultActiveKey={['0']} className={styles.collapse} key={`collapse-${key}`}>
                            { renderRow(row, key) }
                        </Collapse>
                    </Col> :
                    <>{ renderRow(row, key) }</>
            }
        </Row>
    }


    return <div className={styles.SummaryBlock}>
        { data.header &&
            <Header> {data.header} </Header>
        }
        { data && data.rows && data.rows.map((row, index) => {
            return renderRowData(row, `row-${index}`)
        })}
    </div>
})
