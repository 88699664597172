// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2T1aKlFGXgzM9pMjFBAiUe {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 19px;\n  color: #000000; }\n  ._2T1aKlFGXgzM9pMjFBAiUe .ant-checkbox-wrapper {\n    display: flex; }\n  ._2T1aKlFGXgzM9pMjFBAiUe .ant-checkbox {\n    margin-top: 3px;\n    height: 100%; }\n", ""]);
// Exports
exports.locals = {
	"Checkbox": "_2T1aKlFGXgzM9pMjFBAiUe"
};
module.exports = exports;
