import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    noStoreAdded: string
    addStore: string
    nextStep: string
    back: string
    storeName: string
    search: string
    reset: string
    cancel: string
}>()

