import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { getWithAuth, postWithAuth } from '~/code/services/authorised-requests'
import { Acquirer } from '~/code/POS/pages/NewOrder/models/Order/Acquirer'
import { error } from 'dna-common'
import { Currency } from '~/code/POS/pages/NewOrder/models/Order/Currency'
import { Country } from '~/code/POS/pages/NewOrder/models/Order/Country'
import { MerchantCategoryCode } from '~/code/POS/pages/NewOrder/models/Order/MerchantCategoryCode'
import { Bundle } from '~/code/POS/pages/NewOrder/models/Order/Bundle'
import { BrandingOrganisation } from '~/code/POS/pages/NewOrder/models/Order/BrandingOrganisation'
import { SubmissionType } from '~/code/POS/pages/NewOrder/models/Order/SubmissionType'
import { AccountManager } from '~/code/POS/pages/NewOrder/models/123send/AccountManager'
import { OrderType } from '~/code/POS/pages/NewOrder/models/123send/OrderType'
import { PartnerCompany } from '~/code/POS/pages/NewOrder/models/123send/PartnerCompany'
import { get } from 'back-connector'
import { CHSearchResponse } from '~/code/POS/pages/NewOrder/models/CHSearchResponse'
import { ISOMerchant } from '~/code/POS/pages'
import { CROCompany } from '~/code/POS/pages/NewOrder/models/CROCompany'
import { EmployeeMerchant } from '~/code/POS/pages/NewOrder/models/EmployeeMerchant'
import { PartnerMerchant } from '~/code/POS/pages/NewOrder/models/PartnerMerchant'
import { BusinessEntity } from '~/code/stores/NewOrderStore/123send/models/BusinessEntity'

export const fetch123SendAcquirers = async () => {
    return checkHTTPStatus(getWithAuth<Acquirer[]>('/api/acquirers/123send-acquirers')).
        then( response => {
            if (response && response.length > 0) {
                return response.map(acquirer => ({
                    value: acquirer.Id || acquirer.id,
                    label: acquirer.Name || acquirer.name,
                    params: acquirer
                }))
            } else {
                return []
            }
        }).
        catch((err: Error) => {
            error(`FAILED TO LOAD 123 ACQUIRERS: ${err.message}`)
            return [] // in this case we return an empty list as there is no need to show an error in the ui
        })
}

export const fetch123SendAccountManagers = async (pageNumber, pageSize) => {
    return checkHTTPStatus(getWithAuth<AccountManager[]>(`/api/employees/123send-account-managers?pageNumber=${pageNumber}&pageSize=${pageSize}`)).
        then( response => {
            if (response && response.length > 0) {
                return response.map(manager => {
                    return { value: manager.AccountManagerId || manager.accountManagerId,
                        label: manager.Name || manager.name,
                        params: manager
                    }
                })
            } else {
                return []
            }
        }).
        catch((err: Error) => {
            error(`Failed to load 123 send account managers: ${err.message}`)
            return [] // in this case we return an empty list as there is no need to show an error in the ui
        })
}

export const fetch123SendOrderTypes = async () => {
    return checkHTTPStatus(getWithAuth<OrderType[]>(`/api/123send/order-types`)).
    then( response => {
        if (response && response.length > 0) {
            return response.map(orderType => {
                return { value: orderType.OrderTypeId || orderType.orderTypeId,
                    label: orderType.Description || orderType.description,
                    params: orderType
                }
            })
        } else {
            return []
        }
    }).
    catch((err: Error) => {
        error(`Failed to load 123 send account managers: ${err.message}`)
        return [] // in this case we return an empty list as there is no need to show an error in the ui
    })
}

export const fetch123SendPartnerCompanies = async () => {
    return checkHTTPStatus(getWithAuth<PartnerCompany[]>(`/api/123send/partners`)).
    then( response => {
        if (response && response.length > 0) {
            return response.map(partnerCompany => {
                return { value: partnerCompany.partnerCompanyDetailsId || partnerCompany.PartnerCompanyDetailsId,
                    label: partnerCompany.name || partnerCompany.Name,
                    params: partnerCompany
                }
            })
        } else {
            return []
        }
    }).
    catch((err: Error) => {
        error(`Failed to load 123 send account managers: ${err.message}`)
        return [] // in this case we return an empty list as there is no need to show an error in the ui
    })
}

export const placeOrder = (requestURL, request) => {
    return checkHTTPStatus(postWithAuth<any>(requestURL, request))
}

export const fetchCurrencies = async () => {
    return checkHTTPStatus(getWithAuth<Currency[]>('/api/currencies')).
        then( response => {
            if (response && response.length > 0) {
                return response.map(currency => {
                    return { value: currency.Id || currency.id,
                        label: currency.Name || currency.name,
                        params: currency}
                })
            } else {
                return []
            }
        }).
        catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}

export const fetchCountries = async () => {
    return checkHTTPStatus(getWithAuth<Country[]>('/api/countries')).
    then( response => {
        if (response && response.length > 0) {
            return response.map(country => {
                return { value: country.CountryCode || country.countryCode,
                    label: country.Name || country.name,
                    params: country}
            })
        } else {
            return []
        }
    }).
    catch((err: Error) => {
        error(`FAILED: ${err.message}`)
        return []
    })
}

export const fetchAcquirers = async () => {
    return checkHTTPStatus(getWithAuth<Acquirer[]>('/api/acquirers')).
    then( response => {
        if (response && response.length > 0) {
            return response.map(acquirer => {
                return { value: acquirer.Id || acquirer.id,
                    label: acquirer.Name || acquirer.name,
                    params: acquirer
                }
            })
        } else {
            return []
        }
    }).
    catch((err: Error) => {
        error(`FAILED: ${err.message}`)
        return []
    })
}

export const fetchMerchantCategoryCodes = async () => {
    return checkHTTPStatus(getWithAuth<MerchantCategoryCode[]>('/api/merchant-category-codes')).
    then( response => {
        if (response && response.length > 0) {
            return response.map(merchantCategoryCode => {
                return { value: merchantCategoryCode.Code || merchantCategoryCode.code,
                    label: `${merchantCategoryCode.Code || merchantCategoryCode.code} - ${merchantCategoryCode.Name || merchantCategoryCode.name}`,
                    params: merchantCategoryCode || merchantCategoryCode
                }
            })
        } else {
            return []
        }
    }).
    catch((err: Error) => {
        error(`FAILED: ${err.message}`)
        return []
    })
}

const bundleCodesToBeHidden = ['041-A80-AQC', '042-A920PRO-AQC', '043-A920PRO-AQC', '044-V200C-AQC']

export const fetchBundles = async (url?: string, internalIdentifier?: string) => {
    return checkHTTPStatus(getWithAuth<Bundle[]>(`${url || '/api/bundles'}${internalIdentifier || ''}`)).
        then( response => {
            if (response && response.length > 0) {
                return response
                    .filter(bundle => !bundleCodesToBeHidden.includes(bundle.BundleCode || bundle.bundleCode))
                    .map(bundle => ({ value: bundle.BundleCode || bundle.bundleCode,
                        label: bundle.BundleCode || bundle.bundleCode,
                        params: { ...bundle, description: (bundle.description || bundle.Description) }
                    }))
            } else {
                return []
            }
        }).
        catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}

export const fetchBrandingOrganisations = async (internalIdentifier?: string) => {
    return checkHTTPStatus(getWithAuth<BrandingOrganisation[]>(`/api/branding-organizations${internalIdentifier}`)).
        then( response => {
            if (response && response.length > 0) {
                return response.map(brandingOrganisation => {
                    return {value: brandingOrganisation.brandingOrganizationId, label: `(${brandingOrganisation.brandingOrganizationId}) ${brandingOrganisation.name}`, params: brandingOrganisation}
                })
            } else {
                return []
            }
        }).
        catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}


// iso related
export const fetchMerchantStores = async (pageNumber, pageSize, merchantId, internalIdentifier) => {
    return checkHTTPStatus(getWithAuth<any[]>(`/api/merchant-stores?pageNumber=${pageNumber}&pageSize=${pageSize}&merchantId=${merchantId}${internalIdentifier}`))
        .then( response => {
            if (response && response.length > 0) {
                return response.map(store => {
                    return {label: `${store.merchantStoreId} - ${store.storeName}`,
                        value: store.merchantStoreId,
                        params: { ...store, country: store.countryCode, countryName: store.country }
                    }})
            } else {
                return []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}

export const fetchMerchantDepartments = async (pageNumber, pageSize, storeId, internalIdentifier) => {
    return checkHTTPStatus(getWithAuth<any[]>(`/api/merchant-departments?pageNumber=${pageNumber}&pageSize=${pageSize}&merchantStoreId=${storeId}${internalIdentifier}`))
        .then( response => {
            if (response && response.length > 0) {
                return response.map(department => {
                    return {label: `${department.merchantDepartmentId} - ${department.name}`,
                        value: department.merchantDepartmentId,
                        params: department}
                })
            } else {
                return []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}

export const fetchPreferredSubmissions = async () => {
    return checkHTTPStatus(getWithAuth<SubmissionType[]>('/api/submission-types')).
    then( response => {
        if (response && response.length > 0) {
            return response.map(submissionType => {
                return {value: submissionType.submissionid, label: submissionType.name, params: submissionType}
            })
        } else {
            return []
        }
    }).
    catch((err: Error) => {
        error(`FAILED: ${err.message}`)
        return []
    })
}

// partner related
export const fetchPartnerMerchantStores = async (pageNumber, pageSize, merchantId, internalIdentifier, searchName: string) => {
    return checkHTTPStatus(getWithAuth<any[]>(`/api/merchant-stores?pageNumber=${pageNumber}&pageSize=${pageSize}&merchantId=${merchantId}${internalIdentifier}&searchValue=${searchName}`))
        .then( response => {
            if (response && response.length > 0) {
                return response.map(store => {
                    const partnerStore = {
                        ...store,
                        id: store.merchantStoreId,
                        name: store.storeName,
                        country: store.countryCode,
                        countryName: store.country,
                        isNew: false,
                        departments: [],
                        isLoading: true
                    }
                    return partnerStore
                })
            } else {
                return []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}

export const fetchPartnerMerchantDepartments = async (pageNumber, pageSize, storeId, internalIdentifier) => {
    return checkHTTPStatus(getWithAuth<any[]>(`/api/merchant-departments?pageNumber=${pageNumber}&pageSize=${pageSize}&merchantStoreId=${storeId}${internalIdentifier}`))
        .then( response => {
            if (response && response.length > 0) {
                return response.map(department => {
                    return {
                        ...department,
                        id: department.merchantDepartmentId,
                        visaMID: department.visaMid,
                        visaDebitMID: department.visaDebitMid,
                        mastercardMID: department.mastercardMid,
                        mastercardDebitMID: department.mastercardDebitMid,
                        maestroDebitMID: department.maestroDebitMid,
                        isNew: false,
                        departmentType: department.departmentType?.toLocaleLowerCase() === 'general' ? 'general' : 'eCommerce'
                    }
                })
            } else {
                return []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return []
        })
}

export const fetchCHCompanies = (value) => {
    return get<CHSearchResponse>('/ch-api/search/companies',
        {
            q: value
        }
    )
}

export const fetchCROCompanies = (value) => {
    return get<CROCompany[]>('/cro-api/cws/companies',
        {
            company_name: value,
            company_bus_ind: 'E',
            format: 'json'
        }
    )
}

export const fetchDepartmentsByValue = (value: string, merchantStoreId: string, internalIdentifier: string) =>
    checkHTTPStatus(getWithAuth<any[]>(`/api/merchant-departments?pageNumber=1&pageSize=1&searchValue=${value}&merchantStoreId=${merchantStoreId}${internalIdentifier}`))

export const fetchStoresByValue = (value: string, merchantId: string, internalIdentifier: string) =>
    checkHTTPStatus(getWithAuth<any[]>(`/api/merchant-stores?pageNumber=1&pageSize=1&searchValue=${value}&merchantId=${merchantId}${internalIdentifier}`))

export const fetch123SendMerchants = (data) =>
    checkHTTPStatus(getWithAuth<EmployeeMerchant[]>('/api/employees/companies-for-orders', data))

export const fetchPartnerMerchants = (data) =>
    checkHTTPStatus(getWithAuth<PartnerMerchant[]>('/api/merchants', data))

export const fetchISOMerchants = (data) =>
    checkHTTPStatus(getWithAuth<ISOMerchant[]>('/api/merchants/iso-merchants', data))

export const fetchBusinessEntities = () =>
    checkHTTPStatus(getWithAuth<BusinessEntity[]>('/api/123send/business-entities'))
