import React from 'react'
import classNames from 'classnames'
import { PlainButtonProps } from './props'
import styles from './PlainButton.scss'


export const PlainButton = (props: PlainButtonProps) => {
    return <div className={classNames(styles.PlainButton, props.className)} onClick={props.onClick}>
        <>{props.icon} {props.text} </>
    </div>
}
