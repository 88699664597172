// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3jzLYGNDuI5eGubr1WkgS {\n  width: 100%; }\n  ._4dhjo40JwJVbJ1SPRei6R {\n    background-color: transparent; }\n  ._2IiSsL4g1HKp4J7sAMeOrp {\n    text-transform: uppercase; }\n  ._2CHT1T73CCl8EU9b0mZNz0 {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #000000;\n    padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"InformationForm": "_3jzLYGNDuI5eGubr1WkgS",
	"InformationForm_inputContainer": "_4dhjo40JwJVbJ1SPRei6R",
	"InformationForm_inputUppercase": "_2IiSsL4g1HKp4J7sAMeOrp",
	"InformationForm_subgroupTitle": "_2CHT1T73CCl8EU9b0mZNz0"
};
module.exports = exports;
