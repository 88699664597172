// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1GorA30HFXwUT2pds5R2-m {\n  font-family: Poppins;\n  font-weight: 600;\n  color: #00A0DC;\n  cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"LinkButton": "_1GorA30HFXwUT2pds5R2-m"
};
module.exports = exports;
