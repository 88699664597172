import translations from './translations'

translations.add('en', {
    address: 'Address',
    contact: 'Contact',
    deliverToStore: 'Deliver to store',
    deliverToMerchant: 'Deliver to merchant',
    deliverToNewAddress: 'Deliver to a new address',
    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',
    addressLine3Required: 'Address line 3 is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    county: 'County',
    countyRequired: 'County is required',
    country: 'Country',
    countryRequired: 'Country is required',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',
    tradingAs: 'Trading as',
    tradingAsRequired: 'Trading as is required',
    deliveryName: 'Delivery name',
    deliveryNameRequired: 'Delivery name is required',

    useMerchantAddress: 'use merchant address',

    contactName: 'First name',
    contactNameRequired: 'First name is required',
    contactSurname: 'Surname',
    contactSurnameRequired: 'Surname is required',
    jobTitle: 'Job title',
    jobTitleRequired: 'Job title required',
    email: 'Email',
    emailInvalid: 'Invalid email',
    emailRequired: 'Email is required',
    contactPhoneNumber: 'Telephone number',
    contactPhoneNumberInvalid: 'Telephone number',
    contactPhoneNumberRequired: 'Telephone number is required',
    contactMobilePhone: 'Mobile number',
    contactMobilePhoneInvalid: 'Invalid mobile number',
    contactMobilePhoneRequired: 'Mobile number is required',

    deliveryInstructions: 'Delivery instructions',
    setCustomDeliveryInstructions: 'Set custom delivery instructions for',
    storeDeliveryInstructions: 'Store delivery instructions',

    back: 'Back',
    nextStep: 'Next'
})
