export interface AddStoreFormData {
    storeId: string
    storeName: string
    storeCompanyRegistrationNumber: string
    storeVATNumber: string
    storeTelephoneNumber: string
    storeEmail: string
    storeCountry: number
    storeCountryName: string
    storePostcode: string
    storeAddressLine1: string
    storeAddressLine2: string
    storeAddressLine3: string
    storeTown: string
    storeCounty: string
    useAxeptConnectCloud: boolean
}

export function createAddStoreFormData(): AddStoreFormData {
    return {
        storeId: '',
        storeName: '',
        storeCompanyRegistrationNumber: '',
        storeVATNumber: '',
        storeTelephoneNumber: '',
        storeEmail: '',
        storeCountry: 0,
        storeCountryName: '',
        storePostcode: '',
        storeAddressLine1: '',
        storeAddressLine2: '',
        storeAddressLine3: '',
        storeTown: '',
        storeCounty: '',
        useAxeptConnectCloud: false
    }
}
