import React from 'react'
import { Modal, Table } from 'antd'
import { observer } from 'mobx-react'
import translations from '~/code/ODIN/pages/DeviceManagementDetails/translations/translations'
import { Button, ListActions } from '~/code/common/components'
import { batteryColumns, communicationsMethodColumns } from '~/code/ODIN/pages/DeviceManagementDetails/models/Constants'
import { CommunicationMethodModalProps } from './props'
import styles from '~/code/ODIN/pages/DeviceManagementDetails/DeviceManagementDetails.scss'

export const CommunicationMethodModal = observer(({store, deviceDetails}: CommunicationMethodModalProps) => {
    const refresh = () => {
        store.loadCommunicationsMethodHistory(deviceDetails.deviceId)
    }

    const loadNextPage = () => {
        store.loadCommunicationsMethodHistory(deviceDetails.deviceId, store.pageNumber + 1)
    }

    const loadPrevPage = () => {
        store.loadCommunicationsMethodHistory(deviceDetails.deviceId, store.pageNumber - 1)
    }

    return (
        <Modal
            title={`${deviceDetails.serialNumber} - ${deviceDetails.friendlyName} - ${translations().communicationsMethod}`}
            visible={store.isCommunicationHistoryModalOpen}
            onOk={() => store.setIsCommunicationHistoryModalOpen(false)}
            onCancel={() => store.setIsCommunicationHistoryModalOpen(false)}
            footer={[
                <Button
                    key="back"
                    onClick={() => store.setIsCommunicationHistoryModalOpen(false)}
                >
                    {translations().cancel}
                </Button>
            ]}
        >
            <ListActions
                pageInfo={{
                    from: store.deviceCommunicationsMethodHistory.length ? store.pageNumber * store.pageSize - 9 : 0,
                    to: store.pageNumber * store.pageSize > store.totalCount ? store.totalCount : store.pageNumber * store.pageSize,
                    total: store.totalCount
                }}
                currentTotalAmount={store.deviceCommunicationsMethodHistory.length}
                onReloadClick={refresh}
                onGoNextPageClick={loadNextPage}
                onGoPrevPageClick={loadPrevPage}
                isNextPageDisabled={store.pageNumber >= store.totalPageItems}
                isPrevPageDisabled={store.pageNumber <= 1}
            />
            <br/>
            <Table
                pagination={false}
                loading={store.isLoading}
                dataSource={store.deviceCommunicationsMethodHistory}
                columns={communicationsMethodColumns}
                scroll={{ y: 240 }}
            />
        </Modal>
    )
})
