import { makeObservable, observable } from 'mobx'
import moment from 'moment'
import { error } from 'dna-common'
import { notification } from 'antd'
import { get as getFromStore, set } from 'store-connector'
import { postWithAuth } from '~/code/services/authorised-requests'
import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { RefreshAccessTokenResponse } from '~/code/stores/AuthStore/models/RefreshAccessTokenResponse'
import { IAuthParentStore } from '~/code/stores/AuthStore/models/IAuthParentStore'
import { UNAUTHORISED } from '~/code/models/ErrorNames'
import { REFRESH_ACCESS_TOKEN_BEFORE, RETRY_REFRESH_ACCESS_TOKEN_COUNT, RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT } from '~/code/stores/AuthStore/models/AuthConstants'
import translations from '~/code/translations/translations'

export class AuthStore {

    private _authUrl: string

    constructor (private parentStore: IAuthParentStore, private authUrl: string) {
        makeObservable(this, {
            showIdleWarning: observable
        })

        this._authUrl = authUrl
    }

    private retryCount = RETRY_REFRESH_ACCESS_TOKEN_COUNT

    public showIdleWarning: boolean = false

    public saveAndPlanRefreshAuthCredentials = (accessToken, refreshToken, expiresIn) => {
        const expiresAt = moment().add(expiresIn, 'seconds').format()
        set('accessToken', accessToken)
        set('refreshToken', refreshToken)
        set('expiresIn', expiresIn)
        set('expiresAt', expiresAt)
        setTimeout(this.refreshAccessToken, (expiresIn - REFRESH_ACCESS_TOKEN_BEFORE) * 1000)
        this.retryCount = RETRY_REFRESH_ACCESS_TOKEN_COUNT
    }

    /**
     * Refreshes the access token
     * If it fails to refresh the access token, it tries again every RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT ms until it succeeds
     * If it fails to refresh for RETRY_REFRESH_ACCESS_TOKEN_COUNT times, it makes the user log out
     * If it gets 401 Unauthorised response while refreshing the access token, it makes the user log out
     */
    public refreshAccessToken = () => {
        const _refreshToken = getFromStore('refreshToken')
        if (!_refreshToken) { return }

        checkHTTPStatus(postWithAuth<RefreshAccessTokenResponse>(this._authUrl, { refreshToken: _refreshToken }))
            .then(({ accessToken, refreshToken, expiresIn }) => {
                this.saveAndPlanRefreshAuthCredentials(accessToken, refreshToken, expiresIn)
            })
            .catch((err: Error) => {
                error('FAILED REFRESH:', err)
                if ( err.name === UNAUTHORISED ) {
                    notification.error({
                        message: translations()
                    })
                    this.parentStore.logOut()
                } else {
                    this.retryCount--
                    if (this.retryCount < 0){
                        notification.error({
                            message: translations()
                        })
                        this.parentStore.logOut()
                    } else {
                       setTimeout(this.refreshAccessToken, RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT)
                    }
                }
            })
    }
}
