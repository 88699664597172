export interface Merchant {
    name: string
    tradingAs: string
    companyRegistrationNumber: string
    vatNumber: string
    address: {
        line1: string
        line2: string
        line3: string
        townOrCity: string
        county: string
        postcode: string
        countryCode: number
    }
    contact: {
        jobTitle: string
        firstName: string
        surname: string
        telephoneNumber: string
        mobileNumber: string
        email: string
    }
    communicationDetails: {
        paperBasedInvoices: boolean
        receiveSalesAndMarketing: boolean
    }
    roles: [{
        name: string
        purchase: boolean
        refund: boolean
        cashback: boolean
        users: [{
            userId: string
            userPin: string
        }]
    }]
}

export function createMerchant(): Merchant {
    return {
        name: '',
        tradingAs: '',
        companyRegistrationNumber: '',
        vatNumber: '',
        address: {
            line1: '',
            line2: '',
            line3: '',
            townOrCity: '',
            county: '',
            postcode: '',
            countryCode: 0
        },
        contact: {
            jobTitle: '',
            firstName: '',
            surname: '',
            telephoneNumber: '',
            mobileNumber: '',
            email: ''
        },
        communicationDetails: {
            paperBasedInvoices: false,
            receiveSalesAndMarketing: true
        },
        roles: [{
            name: '',
            purchase: false,
            refund: false,
            cashback: false,
            users: [{
                userId: '',
                userPin: ''
            }]
        }]
    }
}
