import { IProcessingDetailsFormStore } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/IProcessingDetailsFormStore'
import { SelectItem } from '~/code/common/components'
import { createProcessingDetailsFormData, ProcessingDetailsFormData } from '~/code/POS/pages/NewOrder/components/iso/ProcessingDetailsForm/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { AppStore } from '~/code/AppStore'
import { BrandingOrganisationsStore } from '~/code/stores/NewOrderStore/common/BrandingOrganisationsStore'
import { PreferredSubmissionsStore } from '~/code/stores/NewOrderStore/common/PreferredSubmissionsStore'

export class ProcessingDetailsFormStore implements IProcessingDetailsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            processingDetailsFormData: observable,
            canGoBack: observable,
            acquirers: computed,
            brandingOrganisations: computed,
            currencies: computed,
            merchantCategoryCodes: computed,
            preferredSubmissions: computed,
            clearStreamlineCompanyId: action,
            clearAmexData: action,
            setProcessingDetailsFormData: action,
            goBack: action
        })

        this.processingDetailsFormData = createProcessingDetailsFormData()
    }

    static stepName: 'processingDetails'

    // helper stores
    private readonly brandingOrganisationsStore = new BrandingOrganisationsStore()
    private readonly preferredSubmissionsStore = new PreferredSubmissionsStore()

    processingDetailsFormData: ProcessingDetailsFormData

    canGoBack: boolean = true

    get acquirers (): SelectItem[] {
        return AppStore.acquirersStore.acquirers
    }

    get brandingOrganisations (): SelectItem[] {
        return this.brandingOrganisationsStore.brandingOrganisations
    }

    get currencies(): SelectItem[] {
        return AppStore.currenciesStore.currencies
    }

    get merchantCategoryCodes(): SelectItem[] {
        return AppStore.merchantCategoryCodesStore.merchantCategoryCodes
    }

    get preferredSubmissions(): SelectItem[] {
        return this.preferredSubmissionsStore.preferredSubmissions
    }

    clearStreamlineCompanyId = () => {
        this.processingDetailsFormData = {
            ...this.processingDetailsFormData,
            streamlineCompanyId: 0
        }
    }

    clearAmexData = () => {
        this.processingDetailsFormData = {
            ...this.processingDetailsFormData,
            amexAcquirer: '',
            amexBankAccountNumber: '',
            amexMerchantId: '',
            amexSortCode: ''
        }
    }

    setProcessingDetailsFormData = (data: ProcessingDetailsFormData) => {
        this.processingDetailsFormData = {...this.processingDetailsFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.processingDetailsFormData, ProcessingDetailsFormStore.stepName)
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(ProcessingDetailsFormStore.stepName)
    }

}
