import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    restartDevice: string
    restart: string
    retrieveLog: string
    restartRequested: string
    createNewLogRequested: string
    cancel: string
    installPriority: string
    downloadLog: string
    availableLogsFromLast2Weeks: string
    pleaseEnterCaptcha: string
    pleaseEnterCaptchaForLog: string
    captchaIsRequired: string
    createdDateTime: string
    startupDateTime: string
    restartHistory: string
    invalidCode: string
}>()
