// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._31afaogFW-cfJcbPtO4ZxA {\n  display: flex;\n  align-items: center; }\n  ._31afaogFW-cfJcbPtO4ZxA ._2Mu8e2aHXmwj6oc2NdAcS0 {\n    flex-grow: 2;\n    padding-left: 10px; }\n\n._30cBipcZziajxLb7jMBkJ4 {\n  width: 30px;\n  height: 30px;\n  cursor: pointer;\n  border: 1px solid #BABABA;\n  border-radius: 3px; }\n\n.i_dEXaeEvsj1e8Ewq-dR3 .ant-popover-inner-content {\n  padding: 0; }\n", ""]);
// Exports
exports.locals = {
	"ColourPicker": "_31afaogFW-cfJcbPtO4ZxA",
	"input": "_2Mu8e2aHXmwj6oc2NdAcS0",
	"trigger": "_30cBipcZziajxLb7jMBkJ4",
	"popover": "i_dEXaeEvsj1e8Ewq-dR3"
};
module.exports = exports;
