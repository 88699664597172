import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    today: string
    yesterday: string
    thisWeek: string
    thisMonth: string
    thisYear: string
}>()

