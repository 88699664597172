import React from 'react'
import { Badge, Card, Col, Popconfirm, Row } from 'antd'
import { Prop } from '../index'
import translations from './translations'
import { DepartmentCardProps } from './props'
import styles from './DepartmentCard.scss'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { GREEN_COLOR } from '~/code/POS/constants'

export const DepartmentCard = (props: DepartmentCardProps) => {
    const { department, onEditClick, onDeleteClick } = props
    const getActions = () => {
        if (department?.isNew) {
            return [
                <EditOutlined key={'edit'} onClick={onEditClick}/>,
                <Popconfirm
                    placement={'topRight'}
                    title={translations().areYouSureToDeleteDepartment}
                    onConfirm={onDeleteClick}
                    okText={translations().yesDelete}
                    cancelText={translations().cancelDelete}
                    key={department.id}
                >
                    <div className={styles.deleteButton}>
                        <DeleteOutlined key={'delete'}/>
                    </div>
                </Popconfirm>
            ]
        } else {
            return [
                <span className={styles.noAction}> {translations().noActionAvailable} </span>
            ]
        }
    }

    const renderAcquirerInfo = () => {

        const {acquiresWithDna, acquiresWithDnaEcommerce} = department

        if (acquiresWithDna && !acquiresWithDnaEcommerce) {
            return (
                <Col xs={12} sm={6}>
                    <Prop label={translations().acquirer}
                          value={translations().dnaPayments}/>
                </Col>
            )
        } else if (!acquiresWithDna && acquiresWithDnaEcommerce) {
            return (
                <Col xs={12} sm={6}>
                    <Prop label={translations().acquirer}
                          value={translations().dnaEPayments}/>
                </Col>
            )
        } else return null
    }

    const renderCard = () => {
        return <Card className={styles.DepartmentCard}
                     actions={getActions()} >
            <Row gutter={24}>
                <Col xs={12} sm={6}>
                    <Prop label={translations().departmentName }
                          value={ department.name || translations().noValue }/>
                </Col>
                {
                    !department?.isNew &&
                    <Col xs={12} sm={6}>
                        <Prop label={translations().departmentId}
                              value={ department.id || translations().noValue }/>
                    </Col>
                }
                <Col xs={12} sm={6}>
                    <Prop label={translations().visaMID }
                          value={ department.visaMID || translations().noValue } />
                </Col>
                <Col xs={12} sm={6}>
                    <Prop label={translations().departmentType}
                          value={ translations()[department.departmentType] || translations().noValue }/>
                </Col>
                {renderAcquirerInfo()}
            </Row>
        </Card>
    }

    const renderDepartment = () => {
        return department?.isNew ?
            <Badge.Ribbon text={translations().new} color={GREEN_COLOR}>
                { renderCard() }
            </Badge.Ribbon> : renderCard()
    }

    return renderDepartment()
}
