import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import translations from './translations'
import { Button } from '~/code/common/components'
import { EmptyViewProps } from './props'
import styles from './EmptyView.scss'

export const EmptyView = (props: EmptyViewProps) => {
    return (
        <div className={styles.EmptyView}>
            <div className={styles.message}>
                { translations().nothingToView }
            </div>
            <div className={styles.buttonWrapper}>
                <Button onClick={props.onNewOrderClick ? props.onNewOrderClick : undefined}>
                    <PlusOutlined /> {translations().newOrder}
                </Button>
            </div>
        </div>
    )
}
