import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { isEmpty } from 'dna-common'
import { notification } from 'antd'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { PAGE_SIZE } from '~/code/models'
import { IApplicationVersionHistoryStore } from '~/code/ODIN/pages/ApplicationManagementDetails/IApplicationVersionHistoryStore'
import { fetchApplicationVersionHistoryById } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/services/fetchers'
import { VersionHistory } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/models/VersionHistory'
import { IApplicationManagementDetailsStore } from '~/code/ODIN/pages/ApplicationManagementDetails/IApplicationManagementDetailsStore'
import translations from '~/code/ODIN/pages/ApplicationManagementDetails/components/VersionHistoryList/translations'
import commonTranslations from '~/code/translations'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class ApplicationVersionHistoryStore implements IApplicationVersionHistoryStore {
    constructor (parentStore: IApplicationManagementDetailsStore) {
        makeObservable(this, {
            isLoading: observable,
            pageNumber: observable,
            pageSize: observable,
            totalCount: observable,
            totalPageItems: observable,
            versionHistory: observable,
            addNewVersionStore: computed,
            loadVersionHistoryById: action.bound,
            openFileUploadModal: action.bound,
            onUploadFileModalOk: action.bound,
            emptyPackageVersionId: action.bound
        })

        this.parentStore = parentStore
    }

    parentStore: IApplicationManagementDetailsStore
    versionHistory: VersionHistory[] = []
    isLoading: boolean = false
    pageNumber: number = 0
    pageSize: number = PAGE_SIZE
    totalCount: number = 0
    totalPageItems: number = 0

    public get addNewVersionStore() {
        return this.parentStore.addNewVersionStore
    }

    public openFileUploadModal(version: VersionHistory) {
        this.addNewVersionStore.setIsAppUploadModalOpen(true)
        this.addNewVersionStore.setPackageVersionId(version.packageVersionId)
    }

    public onUploadFileModalOk(id) {
        this.loadVersionHistoryById(id)
        notification.success({
            message: translations().applicationCreatedSuccessfully,
            duration: DEFAULT_NOTIFICATION_DURATION
        })
        this.emptyPackageVersionId()
    }

    emptyPackageVersionId() {
        this.addNewVersionStore.setPackageVersionId(null)
    }

    public loadVersionHistoryById = (applicationId: number, pageNumber?) => {
        if (this.isLoading) {
            return
        }

        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber
        if (this.pageNumber === 1) {
            this.versionHistory = []
        }

        this.isLoading = true

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber
        }

        fetchApplicationVersionHistoryById(applicationId, queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    runInAction(() => {
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.versionHistory = response?.result?.map(item => ({...item, key: item.version}))
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
