import translations from './translations'

translations.add('en', {
    configReport: 'Configuration Report',
    orderReference: 'Order reference',
    noReference: 'No reference',
    orderId: 'Order id',
    opportunityNo: 'Opportunity number',
    salesSource: 'Sales source',
    referee: 'Referee',
    merchantName: 'Merchant name',
    merchantSystemId: 'Merchant system id',
    noOfStores: 'Number of stores',
    noOfDepartments: 'Number of departments',
    storeConfiguration: 'Store configuration',
    merchantStoreId: 'Merchant store id',
    storeName: 'Store name',
    storeKey: 'Store key',
    licenceKey: 'Licence key',
    s300PEDCount: 'S300 PED count',
    yomaniPEDCount: 'Yomani PED count',
    yomaniXRPEDCount: 'Yomani XR PED count',
    xenteoPEDCount: 'Xenteo PED count',
    xenteoECOPEDCount: 'Xenteo ECO PED count',
    s800Count: 'S800 count',
    s900Count: 'S900 count',
    s300Count: 'S300 count',
    a920GoCount: 'A920 Go count',
    a920ProCount: 'A920 Pro count',
    departmentConfiguration: 'Department configuration',
    merchantDepartmentId: 'Merchant department id',
    departmentName: 'Department Name',
    tids: 'TIDs',
    cnpChannelCount: 'CNP channel count',
    signatureKey: 'Signature key',
    a920PositiveCount: 'A920 Positive Count',
    wlPaymentsAppCount: 'WL Payments App Count',
    v200CCount: 'V200C Count',
    v240MCount: 'V240M Count',
    productId: 'Product ID',
    deviceName: 'Device Name',
    quantity: 'Quantity',
    deviceDetail: 'Device Details'
})
