import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    orderDetails: string
    companyName: string
    orderRef: string
    deliverTo: string
    bundle: string
    status: string
    lastUpdateTime: string
    quantity: string
    customPrice: string
    merchantInformation: string
    merchantDetails: string
    merchantAddress: string
    mid: string
    acquirer: string
    viewConfiguration: string
    viewDispatchReport: string
    back: string
    tryAgain: string
    reorder: string
    loading: string
    configurationReport: string
    noConfigurationReport: string
    noDispatchReport: string
    close: string
    orderSubmissionFailed: string
    orderSubmitted: string
    validationCompleted: string
    validationFailed: string
    dispatching: string
    completed: string
    orderFailed: string
    reversed: string
    cancellationRequested: string
    cancelled: string
    cancelling: string
    cannotRetryDueToLackOfInfo: string,
    orderPlacedOn: string
    orderPlacedBy: string
    noCompanySelected: string
    selectCompanyToViewTryAgain: string
    selectCompanyToViewErrorDetails: string
    selectCompanyToViewDispatchReport: string
    selectCompanyToViewConfigurationReport: string
    errorInfo: {
        title: string
        fields: string
        tooltip: string
    }
    areYouSureCancelOrder: string
    continueWithCancellation: string
    cancel: string
}>()
