import {
    IStoreAndDepartmentSelectionFormStore,
    StoreAndDepartmentSelectionData
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/StoreAndDepartmentSelectionForm/models'
import { SelectItem } from '~/code/common/components'
import { action, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { OnboardingDetailsStepKey } from '~/code/stores/CheckoutV3Onboarding/models'
import { log } from 'dna-common'

export class StoreAndDepartmentSelectionFormStore implements IStoreAndDepartmentSelectionFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            isLoadingStores: observable,
            stores: observable,
            selectedStore: observable,
            selectedDepartment: observable,
            storeAndDepartmentSelectionData: observable,
            loadStores: action,
            setStores: action,
            onDepartmentChange: action,
            onStoreChange: action,
            setStoreAndDepartmentSelectionData: action,
            goBack: action
        })
    }

    static stepName: OnboardingDetailsStepKey = 'storeAndDepartmentSelection'

    isLoadingStores: boolean = false

    stores: SelectItem[] = []

    selectedStore: SelectItem

    selectedDepartment: SelectItem

    storeAndDepartmentSelectionData: StoreAndDepartmentSelectionData

    loadStores () {
        // TODO
    }

    setStores = (stores: SelectItem[]) => {
        this.stores = stores.map(item => {
            return {
                ...item,
                params: {
                    ...item.params,
                    departments: item.params?.merchantDepartments?.nodes?.map(department => {
                        return { label: department.name, value: department.merchantDepartmentId, params: department }
                    })
                }
            }
        })
        this.selectedStore = null
        this.selectedDepartment = null
        log('New Stores:', this.stores?.slice())
    }

    onDepartmentChange =  (merchantDepartmentId) => {
        this.selectedDepartment = this.selectedStore.params.departments.find(item => item.value === merchantDepartmentId)
    }

    onStoreChange = (merchantStoreId) => {
        this.selectedStore = this.stores.find(item => item.value === merchantStoreId)
        this.selectedDepartment = this.selectedStore.params.departments[0]
    }

    setStoreAndDepartmentSelectionData = (data: StoreAndDepartmentSelectionData) => {
        this.storeAndDepartmentSelectionData = {...data}
        this.formSubmitHandler.handleFormSubmit(this.storeAndDepartmentSelectionData, StoreAndDepartmentSelectionFormStore.stepName)
    }

    // navigation related props
    canGoBack: boolean = false

    goBack = () => {
        this.formSubmitHandler.handleGoBack(StoreAndDepartmentSelectionFormStore.stepName)
    }

}
