export interface OrderDetailsFormData {
    poNumber: string
    requestedDeliveryAndOrActivationDate: string
    dealId: string
    uniqueOrderReference: string
    orderType: number
    containsAcquirerRevenue: boolean
    partnerCompany: number
    partnerCompanyAgentName: string
    upgradeDetails: { id: string, serialNumber: string, tid: string }[]
}

export function createOrderDetailsFormData(): OrderDetailsFormData {
    return {
        poNumber: '',
        requestedDeliveryAndOrActivationDate: '',
        dealId: '',
        uniqueOrderReference: '',
        orderType: null, // 1: new, 2: upgrade, 3: rolling upgrade
        containsAcquirerRevenue: false,
        partnerCompany: 0,
        partnerCompanyAgentName: '',
        upgradeDetails: []
    }
}
