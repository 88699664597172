import { createProductsFormData, ProductsFormData } from '~/code/POS/pages'
import { IProductsFormStore } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/IProductsFormStore'
import { SelectItem } from '~/code/common/components'
import { action, computed, makeObservable, observable } from 'mobx'
import { BundlesStore } from '~/code/stores/NewOrderStore/common/BundlesStore'
import { INewOrderISOStore } from '~/code/POS/pages/NewOrder/models/iso/INewOrderISOStore'
import { AcquirerAssignedTIDsStore } from '~/code/stores/NewOrderStore/common/AcquirerAssignedTIDsStore'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'
import { OTTSendBundle } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/models/OTTSendBundle'

export class ProductsFormStore implements IProductsFormStore {

    constructor (public parentStore: INewOrderISOStore) {
        makeObservable(this, {
            canGoBack: observable,
            productsFormData: observable,
            bundles: computed,
            acquirerId: computed,
            merchantId: computed,
            acquirerAssignedTids: computed,
            selectedDepartment: computed,
            totalTIDsList: computed,
            isTIDDuplicateExist: computed,
            loadBundles: action,
            setProductsFormData: action,
            goBack: action,
            setTotalTIDsList: action,
            setIsTIDDuplicateExist: action
        })

        this.productsFormData = createProductsFormData()
    }

    static readonly stepName: string = 'products'

    // helper stores
    private readonly bundlesStore = new BundlesStore()
    private readonly acquirerAssignedTIDsStore = new AcquirerAssignedTIDsStore(this)

    canGoBack: boolean = true

    productsFormData: ProductsFormData

    get bundles(): SelectItem[] {
        return this.bundlesStore.bundles
    }

    get acquirerId(): string {
        return this.parentStore?.getProcessingDetailsFormStore()?.processingDetailsFormData?.acquirer
    }

    get merchantId(): string {
        return this.parentStore?.getProcessingDetailsFormStore()?.processingDetailsFormData?.merchantId
    }

    get acquirerAssignedTids(): AcquirerAssignedTid[] {
        return this.acquirerAssignedTIDsStore?.acquirerAssignedTids
    }

    get totalTIDsList(): string[] {
        return this.acquirerAssignedTIDsStore?.totalTIDsList
    }

    get selectedDepartment() {
        return this.parentStore?.getOrderTypeFormStore()?.selectedDepartment
    }

    get isTIDDuplicateExist(): boolean {
        return this.acquirerAssignedTIDsStore?.isTIDDuplicateExist
    }

    loadBundles = () => {
        this.bundlesStore.load()
    }

    setProductsFormData =  (data: ProductsFormData) => {
        this.productsFormData = {...this.productsFormData, ...data}
        this.parentStore.handleFormSubmit(this.productsFormData, ProductsFormStore.stepName)
    }

    goBack = () => {
        this.parentStore.handleGoBack(ProductsFormStore.stepName)
    }

    setAcquirerAssignedTids = (acquirerAssignedTids: AcquirerAssignedTid[]) => {
        this.acquirerAssignedTIDsStore?.setAcquirerAssignedTids(acquirerAssignedTids)
    }

    setTotalTIDsList = (bundles: OTTSendBundle[]) => {
        this.acquirerAssignedTIDsStore?.setTotalTIDsList(bundles)
    }

    setIsTIDDuplicateExist = (isTIDDuplicateExist) => {
        this.acquirerAssignedTIDsStore?.setIsTIDDuplicateExist(isTIDDuplicateExist)
    }
}
