import React from 'react'
import translations from './translations'

translations.add('en', {
    terminalManagement: 'Device Management',
    merchantSchedule: 'Merchant Schedule',
    searchMerchant: 'Merchant Search',
    merchantStoreNamesMatchSearchTerm: 'Merchant Store names match search term',
    viewDevice: 'View Device'
})
