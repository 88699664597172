import React from "react";
const SvgCopy = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11.627 12.385h.31c1.16 0 1.747-.604 1.747-1.77V5.26h-3.305c-.574 0-.908-.34-.908-.908V1H6.582c-1.16 0-1.752.598-1.752 1.77v.257h.727c.808 0 1.435.223 2.068.856l3.14 3.14c.633.64.862 1.284.862 2.069v3.293zm-1.125-7.84h2.918c-.03-.182-.152-.34-.352-.54l-2.35-2.39c-.193-.199-.357-.328-.538-.351l.005 2.965c0 .222.088.316.317.316zM3.746 15.15h5.361c1.16 0 1.747-.597 1.747-1.763V8.945h-4.05c-.714 0-1.048-.34-1.048-1.049V3.795h-2.01C2.592 3.795 2 4.393 2 5.565v7.822c0 1.172.592 1.763 1.746 1.763zm3.076-6.978h3.744c-.052-.223-.21-.434-.468-.692L7.22 4.55c-.246-.251-.47-.41-.692-.468v3.797c0 .193.1.293.293.293z"
      fill="#8D99A7"
    />
  </svg>
);
export default SvgCopy;
