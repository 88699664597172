// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._30E6WkFdtJNiGSNDS3oCAk ._3yWD4jX1xhtiGbQ-jBgcIn {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 22px;\n  color: #000000; }\n\n._30E6WkFdtJNiGSNDS3oCAk ._37CanDCfUhz-mlPjmgk9eJ {\n  padding-top: 16px; }\n\n._30E6WkFdtJNiGSNDS3oCAk ._3ki2DlIhmXALrlY7mYEIfR {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n\n._30E6WkFdtJNiGSNDS3oCAk ._1_I1xoC7zCIswnMhwZUZQC {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 36px;\n  color: #000000;\n  padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"ProductsForm": "_30E6WkFdtJNiGSNDS3oCAk",
	"label": "_3yWD4jX1xhtiGbQ-jBgcIn",
	"goBackButtonContainer": "_37CanDCfUhz-mlPjmgk9eJ",
	"submitButtonContainer": "_3ki2DlIhmXALrlY7mYEIfR",
	"subgroupTitle": "_1_I1xoC7zCIswnMhwZUZQC"
};
module.exports = exports;
