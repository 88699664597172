import { ProductBundle } from '~/code/POS/pages'

export interface Product {
    id: string
    storeId: string // store id
    departmentId: string // department id
    bundles: ProductBundle[]
}

export function createProduct(): Product {
    return {
        id: '',
        storeId: '',
        departmentId: '',
        bundles: []
    }
}
