import React from 'react'
import { observer } from 'mobx-react'
import { Badge, Card, Col, List, Popconfirm, Row, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { SubHeader } from '~/code/common/components'
import { SDDepartment } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDDepartment'
import { DepartmentCard } from './components'
import { Prop } from './components'
import translations from './translations'
import { StoreCardProps } from './props'
import styles from './StoreCard.scss'
import { GREEN_COLOR } from '~/code/POS/constants'

export const StoreCard = observer((props: StoreCardProps) => {
    const { storeData, onEditClick, onDeleteClick, onDepartmentEditClick, onDepartmentDeleteClick, onDepartmentAddClick } = props
    const derivedDeps = [...storeData?.departments, {type: 'add'}]

    const getActions = () => {
        if (storeData?.isNew) {
            return [
                <EditOutlined key={'edit'} onClick={onEditClick}/>,
                <Popconfirm
                    placement={'topRight'}
                    title={translations().areYouSureToDeleteStore}
                    onConfirm={onDeleteClick}
                    okText={translations().yesDelete}
                    cancelText={translations().cancelDelete}
                    key={storeData.id}
                >
                    <div className={styles.deleteButton}><DeleteOutlined type={'delete'} key={'delete'} /></div>
                </Popconfirm>
            ]
        } else {
            return [
                <span className={styles.noAction}> {translations().noActionAvailable} </span>
            ]
        }
    }

    const getTitle = () => {
        const title = storeData?.name || 'Store name'
        if (derivedDeps.length === 1) {
            return <>
                <Tooltip placement={'top'} title={translations().hasNoDepartments}>
                    <InfoCircleOutlined type={'info-circle'} className={styles.warningIcon}/>
                </Tooltip>
                { title }
            </>
        }

        return title
    }

    const getClassName = () => {
        return classNames({[styles.warning]: derivedDeps.length === 1 && !storeData.isLoading})
    }

    const renderStore = () => {
        return storeData?.isNew ?
            <Badge.Ribbon text={translations().new} color={GREEN_COLOR}>
                { renderCard() }
            </Badge.Ribbon> : renderCard()
    }

    const renderCard = () => {
        return (
            <div className={styles.StoreCard}>
                <Card title={storeData.isLoading ? '' : getTitle()}
                      actions={getActions()}
                      loading={storeData.isLoading}
                      className={getClassName()}>
                    <div className={styles.content}>
                        <div className={styles.block}>
                            <SubHeader style={'small'}>
                                {translations().storeInfo}
                            </SubHeader>
                            <Row gutter={16}>
                                <Col xs={6} sm={10}>
                                    <Prop label={translations().address}
                                          value={storeData?.addressLine1 ? `${storeData?.addressLine1}${storeData?.addressLine2 ? ', ' + storeData?.addressLine2 : ''}` : ''}/>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <Prop label={translations().postcode}
                                          value={storeData?.postcode || ''}/>
                                </Col>
                                <Col xs={6} sm={5}>
                                    <Prop label={translations().town}
                                          value={storeData?.town || ''}/>
                                </Col>
                                <Col xs={6} sm={5}>
                                    <Prop label={translations().country}
                                          value={storeData?.countryName || ''}/>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.block}>
                            <SubHeader style={'small'}>
                                {translations().departments}
                            </SubHeader>
                            <List
                                grid={{
                                    gutter: 16,
                                    column: 1
                                }}
                                dataSource={derivedDeps}
                                renderItem={item => (
                                    <List.Item>
                                        {item.hasOwnProperty('type') && (item as { type: string }).type === 'add' ?
                                            <Card hoverable onClick={() => onDepartmentAddClick()}>
                                                <div className={styles.addContainer}>
                                                    <PlusOutlined type={'plus'} key={'plus'} className={styles.addIcon}/>
                                                    <p className={styles.addText}> {translations().addNewDepartment} </p>
                                                </div>
                                            </Card> :
                                            <DepartmentCard
                                                department={item as SDDepartment}
                                                onEditClick={() => onDepartmentEditClick(item as SDDepartment)}
                                                onDeleteClick={() => onDepartmentDeleteClick(item as SDDepartment)}/>
                                        }
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    return renderStore()
})
