import { DepartmentConfigurationModel } from '../index'
import { DeviceDetailModel } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddStoreForm/models/DeviceDetailModel'

export class StoreConfigurationModel {
    a920PositiveCount: number
    v200CCount: number
    v240MCount: number
    wlPaymentsAppCount: number
    merchantStoreId: number
    name: string
    storeKey: string
    licenceKey: string
    s300PEDCount: number
    yomaniPEDCount: number
    yomaniXRPEDCount: number
    xenteoPEDCount: number
    xenteoECOPEDCount: number
    s800Count: number
    s900Count: number
    s300Count: number
    a920GoCount: number
    a920ProCount: number
    departmentConfiguration: DepartmentConfigurationModel[]
    deviceDetail: DeviceDetailModel[]
}
