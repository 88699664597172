import { v4 as uuid } from 'uuid'
import { Product } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/Product'
import { ProductListItemModel } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/components/ProductListItem/models/ProductListItemModel'
import { AddProductFormData, createAddProductFormData } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/components/AddProductForm/models/AddProductFormData'
import { createProductDepartment, ProductDepartment } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/models/ProductDepartment'
import { isEmpty } from 'dna-common'
import { AddStoreFormData } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddStoreForm/models/AddStoreFormData'
import { SDStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDStore'
import { SDDepartment } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/SDDepartment'
import { AddDepartmentFormData } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/models/AddDepartmentFormData'

export function productToProductListItemModel(product: Product, partnerStores: SDStore[]): ProductListItemModel {
    const partnerStore = partnerStores.find(item => item.id === product.storeId)
    const partnerDepartment = partnerStore.departments.find(item => item.id === product.departmentId)

    return {
        id: product.id,
        storeName: partnerStore.name,
        storeAddressLine1: partnerStore.addressLine1,
        storeAddressLine2: partnerStore.addressLine2,
        storeTown: partnerStore.town,
        storeIsNew: partnerStore.isNew,
        storePostcode: partnerStore.postcode,
        departmentName: partnerDepartment.name,
        departmentAcquirer: partnerDepartment.acquirerName,
        departmentMerchantCategory: partnerDepartment.merchantCategoryCodeLabel,
        departmentCurrency: partnerDepartment.currencyName,
        departmentVisaMID: partnerDepartment.visaMID,
        departmentIsNew: partnerDepartment.isNew,
        bundles: product.bundles
    }
}

export function copyDataFromDepartmentToDepartment(copyFrom: ProductDepartment, copyTo: ProductDepartment) {
    if (isEmpty(copyFrom)) {
        return
    }

    if (isEmpty(copyTo)) {
        copyTo = createProductDepartment()
    }

    copyTo.id = copyFrom.id
    copyTo.name = copyFrom.name
    copyTo.currencyId = copyFrom.currencyId
    copyTo.currencyName = copyFrom.currencyName
    copyTo.merchantCategoryCode = copyFrom.merchantCategoryCode
    copyTo.merchantCategoryCodeLabel = copyFrom.merchantCategoryCodeLabel
    copyTo.streamlineCompanyId = copyFrom.streamlineCompanyId
    copyTo.cashBackAccepted = copyFrom.cashBackAccepted
    copyTo.cashBack = copyFrom.cashBack
    copyTo.gratuityAccepted = copyFrom.gratuityAccepted
    copyTo.gratuity = copyFrom.gratuity
    copyTo.refundAccepted = copyFrom.refundAccepted
    copyTo.refund = copyFrom.refund
    copyTo.purchaseAccepted = copyFrom.purchaseAccepted
    copyTo.purchase = copyFrom.purchase
    copyTo.visaMID = copyFrom.visaMID
    copyTo.visaDebitMID = copyFrom.visaDebitMID
    copyTo.acquirer = copyFrom?.acquirer
    copyTo.acquirerName = copyFrom.acquirerName
    copyTo.bankAccountNumber = copyFrom.bankAccountNumber
    copyTo.sortCode = copyFrom.sortCode
    copyTo.mastercardMID = copyFrom.mastercardMID
    copyTo.mastercardDebitMID = copyFrom.mastercardDebitMID
    copyTo.maestroDebitMID = copyFrom.maestroDebitMID
    copyTo.amexMerchantID = copyFrom.amexMerchantID
    copyTo.amexAcquirer = copyFrom.amexAcquirer
    copyTo.amexAcquirerName = copyFrom.amexAcquirerName
    copyTo.amexBankAccountNumber = copyFrom.amexBankAccountNumber
    copyTo.amexSortCode = copyFrom.amexSortCode
    copyTo.dinersDiscoverMerchantID = copyFrom.dinersDiscoverMerchantID
    copyTo.dinersDiscoverAcquirer = copyFrom.dinersDiscoverAcquirer
    copyTo.dinersDiscoverAcquirerName = copyFrom.dinersDiscoverAcquirerName
    copyTo.dinersDiscoverBankAccountNumber = copyFrom.dinersDiscoverBankAccountNumber
    copyTo.dinersDiscoverSortCode = copyFrom.dinersDiscoverSortCode
    copyTo.unionPayMerchantID = copyFrom.unionPayMerchantID
    copyTo.unionPayAcquirer = copyFrom.unionPayAcquirer
    copyTo.unionPayAcquirerName = copyFrom.unionPayAcquirerName
    copyTo.unionPayBankAccountNumber = copyFrom.unionPayBankAccountNumber
    copyTo.unionPaySortCode = copyFrom.unionPaySortCode
    copyTo.flexecashMerchantID = copyFrom.flexecashMerchantID
    copyTo.flexecashAcquirer = copyFrom.flexecashAcquirer
    copyTo.flexecashAcquirerName = copyFrom.flexecashAcquirerName
    copyTo.flexecashBankAccountNumber = copyFrom.flexecashBankAccountNumber
    copyTo.flexecashSortCode = copyFrom.flexecashSortCode
    copyTo.isNew = copyFrom.isNew
}

export function addStoreFormDataToExistingSDStore (data: AddStoreFormData, store: SDStore) {
    store.id = data.storeId || uuid()
    store.name = data.storeName
    store.companyRegistrationNumber = data.storeCompanyRegistrationNumber
    store.vatNumber = data.storeVATNumber
    store.telephoneNumber = data.storeTelephoneNumber
    store.email = data.storeEmail
    store.country = data.storeCountry
    store.countryName = data.storeCountryName
    store.postcode = data.storePostcode
    store.addressLine1 = data.storeAddressLine1
    store.addressLine2 = data.storeAddressLine2
    store.addressLine3 = data.storeAddressLine3
    store.town = data.storeTown
    store.county = data.storeCounty
    store.isNew = true
    store.useAxeptConnectCloud = data.useAxeptConnectCloud
}

export function sdStoreToAddStoreFormData (store: SDStore): AddStoreFormData {
    return {
        storeId: store.id,
        storeName: store.name,
        storeCompanyRegistrationNumber: store.companyRegistrationNumber,
        storeVATNumber: store.vatNumber,
        storeTelephoneNumber: store.telephoneNumber,
        storeEmail: store.email,
        storeCountry: store.country,
        storeCountryName: store.countryName,
        storePostcode: store.postcode,
        storeAddressLine1: store.addressLine1,
        storeAddressLine2: store.addressLine2,
        storeAddressLine3: store.addressLine3,
        storeTown: store.town,
        storeCounty: store.county,
        useAxeptConnectCloud: store.useAxeptConnectCloud

    }
}

export function sdDepartmentToAddDepartmentFormData (department: SDDepartment): AddDepartmentFormData {
    return {
        storeId: department.storeId,
        departmentId: department.id,
        departmentName: department.name,
        departmentCurrency: department.currencyId,
        departmentCurrencyName: department.currencyName,
        departmentMerchantCategoryCode: department.merchantCategoryCode,
        departmentMerchantCategoryCodeLabel: department.merchantCategoryCodeLabel,
        departmentType: department.departmentType,
        departmentStreamlineCompanyId: department.streamlineCompanyId,
        departmentEnableCashBack: department.cashBackAccepted,
        departmentCashBack: department.cashBack,
        departmentEnableGratuity: department.gratuityAccepted,
        departmentGratuity: department.gratuity,
        departmentEnableRefund: department.refundAccepted,
        departmentRefund: department.refund,
        departmentEnablePurchase: department.purchaseAccepted,
        departmentPurchase: department.purchase,
        departmentVisaMID: department.visaMID,
        departmentVisaDebitMID: department.visaDebitMID,
        departmentAcquirer: department?.acquirer,
        departmentAcquirerName: department.acquirerName,
        departmentBankAccountNumber: department.bankAccountNumber,
        departmentSortCode: department.sortCode,
        departmentMastercardMID: department.mastercardMID,
        departmentMastercardDebitMID: department.mastercardDebitMID,
        departmentMaestroDebitMID: department.maestroDebitMID,
        departmentAmexMerchantId: department.amexMerchantID,
        departmentAmexAcquirer: department.amexAcquirer,
        departmentAmexAcquirerName: department.amexAcquirerName,
        departmentAmexBankAccountNumber: department.amexBankAccountNumber,
        departmentAmexSortCode: department.amexSortCode,
        departmentDinersDiscoverMerchantId: department.dinersDiscoverMerchantID,
        departmentDinersDiscoverAcquirer: department.dinersDiscoverAcquirer,
        departmentDinersDiscoverAcquirerName: department.dinersDiscoverAcquirerName,
        departmentDinersDiscoverBankAccountNumber: department.dinersDiscoverBankAccountNumber,
        departmentDinersDiscoverSortCode: department.dinersDiscoverSortCode,
        departmentUnionPayMerchantId: department.unionPayMerchantID,
        departmentUnionPayAcquirer: department.unionPayAcquirer,
        departmentUnionPayAcquirerName: department.unionPayAcquirerName,
        departmentUnionPayBankAccountNumber: department.unionPayBankAccountNumber,
        departmentUnionPaySortCode: department.unionPaySortCode,
        departmentFlexecashMerchantId: department.flexecashMerchantID,
        departmentFlexecashAcquirer: department.flexecashAcquirer,
        departmentFlexecashAcquirerName: department.flexecashAcquirerName,
        departmentFlexecashBankAccountNumber: department.flexecashBankAccountNumber,
        departmentFlexecashSortCode: department.flexecashSortCode,
        departmentAcquirerAssignedTIDs: department.acquirerAssignedTIDs,
        departmentAccountVerification: department.accountVerification,
        departmentCashAdvance: department.cashAdvance,
        departmentMoto: department.moto,
        departmentPreAuth: department.preAuth
    }
}

export function addDepartmentFormDataToExistingSDDepartment (data: AddDepartmentFormData, department: SDDepartment) {
    department.storeId = data.storeId
    department.id = data.departmentId || uuid()
    department.name = data.departmentName
    department.currencyId = data.departmentCurrency
    department.currencyName = data.departmentCurrencyName
    department.merchantCategoryCode = data.departmentMerchantCategoryCode
    department.merchantCategoryCodeLabel = data.departmentMerchantCategoryCodeLabel
    department.departmentType = data.departmentType
    department.streamlineCompanyId = data.departmentStreamlineCompanyId
    department.cashBackAccepted = data.departmentEnableCashBack
    department.cashBack = data.departmentCashBack
    department.gratuityAccepted = data.departmentEnableGratuity
    department.gratuity = data.departmentGratuity
    department.refundAccepted = data.departmentEnableRefund
    department.refund = data.departmentRefund
    department.purchaseAccepted = data.departmentEnablePurchase
    department.purchase = data.departmentPurchase
    department.visaMID = data.departmentVisaMID
    department.visaDebitMID = data.departmentVisaDebitMID
    department.acquirer = data.departmentAcquirer
    department.acquirerName = data.departmentAcquirerName
    department.bankAccountNumber = data.departmentBankAccountNumber
    department.sortCode = data.departmentSortCode
    department.mastercardMID = data.departmentMastercardMID
    department.mastercardDebitMID = data.departmentMastercardDebitMID
    department.maestroDebitMID = data.departmentMaestroDebitMID
    department.amexMerchantID = data.departmentAmexMerchantId
    department.amexAcquirer = data.departmentAmexAcquirer
    department.amexAcquirerName = data.departmentAmexAcquirerName
    department.amexBankAccountNumber = data.departmentAmexBankAccountNumber
    department.amexSortCode = data.departmentAmexSortCode
    department.dinersDiscoverMerchantID = data.departmentDinersDiscoverMerchantId
    department.dinersDiscoverAcquirer = data.departmentDinersDiscoverAcquirer
    department.dinersDiscoverAcquirerName = data.departmentDinersDiscoverAcquirerName
    department.dinersDiscoverBankAccountNumber = data.departmentDinersDiscoverBankAccountNumber
    department.dinersDiscoverSortCode = data.departmentDinersDiscoverSortCode
    department.unionPayMerchantID = data.departmentUnionPayMerchantId
    department.unionPayAcquirer = data.departmentUnionPayAcquirer
    department.unionPayAcquirerName = data.departmentUnionPayAcquirerName
    department.unionPayBankAccountNumber = data.departmentUnionPayBankAccountNumber
    department.unionPaySortCode = data.departmentUnionPaySortCode
    department.flexecashMerchantID = data.departmentFlexecashMerchantId
    department.flexecashAcquirer = data.departmentFlexecashAcquirer
    department.flexecashAcquirerName = data.departmentFlexecashAcquirerName
    department.flexecashBankAccountNumber = data.departmentFlexecashBankAccountNumber
    department.flexecashSortCode = data.departmentFlexecashSortCode
    department.isNew = true
    department.acquirerAssignedTIDs = data.departmentAcquirerAssignedTIDs
    department.accountVerification = data.departmentAccountVerification
    department.cashAdvance = data.departmentCashAdvance
    department.moto = data.departmentMoto
    department.preAuth = data.departmentPreAuth
    department.preAuthorisation = data.preAuthorisation || false
}

export function addProductFormDataToExistingProduct (data: AddProductFormData, product: Product) {
    product.id = data.id

    product.storeId = data.storeId
    product.departmentId = data.departmentId

    product.bundles = data.bundles
}

export function productToAddProductFormData (product: Product): AddProductFormData {
    let addProductFormData = createAddProductFormData()
    addProductFormData = {
        ...addProductFormData,
        id: product.id,

        storeId: product.storeId,
        departmentId: product.departmentId,

        bundles: product.bundles
    }

    return addProductFormData
}
