// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._12vDr2bLjcKMWcvCDgOrSn {\n  width: 100%; }\n  ._3rGH4s80B986TDGRHaqTr6 {\n    background-color: transparent; }\n  ._1lQ0kHJnvoEKgCxS8CcXCv {\n    padding-top: 16px; }\n  .k7tAltSmV7UZJKyQzRBi1 {\n    padding-top: 16px;\n    display: flex;\n    flex-flow: row-reverse; }\n  .GrPcB3nKZEu8HX3j2W3vU {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #000000;\n    padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"DeliveryAddressForm": "_12vDr2bLjcKMWcvCDgOrSn",
	"DeliveryAddressForm_inputContainer": "_3rGH4s80B986TDGRHaqTr6",
	"DeliveryAddressForm_goBackButtonContainer": "_1lQ0kHJnvoEKgCxS8CcXCv",
	"DeliveryAddressForm_submitButtonContainer": "k7tAltSmV7UZJKyQzRBi1",
	"DeliveryAddressForm_subgroupTitle": "GrPcB3nKZEu8HX3j2W3vU"
};
module.exports = exports;
