// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._i0C2g7UOXgo1Umiewz7m, ._225k94fTHXxVl7RTen5TzX {\n  margin-right: 8px; }\n\n._3UiW0vpmgre3OXkHsTg5AG {\n  margin-top: -10px; }\n\n._3jfPG7j7gLwHTkFj7rg5Ue {\n  background: #dedede;\n  padding: 10px;\n  font-size: 24px;\n  max-width: 200px;\n  text-align: center; }\n\n._34UDi--932rjq0hWXh4bzR {\n  margin-bottom: 8px; }\n\n.FO2RAc7NhH-QHcLTbtn08 {\n  font-size: 14px; }\n\n.ucVjFNbAranCa3sSEaGAr {\n  display: flex;\n  align-items: center; }\n", ""]);
// Exports
exports.locals = {
	"backButton": "_i0C2g7UOXgo1Umiewz7m",
	"restartButton": "_225k94fTHXxVl7RTen5TzX",
	"download": "_3UiW0vpmgre3OXkHsTg5AG",
	"captcha": "_3jfPG7j7gLwHTkFj7rg5Ue",
	"listActions": "_34UDi--932rjq0hWXh4bzR",
	"button": "FO2RAc7NhH-QHcLTbtn08",
	"logsHeader": "ucVjFNbAranCa3sSEaGAr"
};
module.exports = exports;
