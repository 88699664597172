// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._8LiewrKpmAO_9CNvwn-_E {\n  margin-right: 8px; }\n\n._2Pc0Po_rXTpHsDEQJ2cVvC {\n  display: flex;\n  justify-content: end; }\n", ""]);
// Exports
exports.locals = {
	"cancel": "_8LiewrKpmAO_9CNvwn-_E",
	"upload": "_2Pc0Po_rXTpHsDEQJ2cVvC"
};
module.exports = exports;
