// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ci913oxmpHlDxQaQtFXtA {\n  position: relative;\n  top: 1px;\n  font-size: 0.8em; }\n", ""]);
// Exports
exports.locals = {
	"ExistingIcon": "_2ci913oxmpHlDxQaQtFXtA"
};
module.exports = exports;
