export const ONE_TWO_THREE_SEND_BUNDLE_CUSTOM_PRICES = [
    {
        label: '£0.00',
        value: 0
    },
    {
        label: '£15.95',
        value: 15.95
    },
    {
        label: '£16.95',
        value: 16.95
    },
    {
        label: '£17.95',
        value: 17.95
    },
    {
        label: '£18.95',
        value: 18.95
    },
    {
        label: '£19.95',
        value: 19.95
    },
    {
        label: '£20.95',
        value: 20.95
    },
    {
        label: '£21.95',
        value: 21.95
    },
    {
        label: '£22.95',
        value: 22.95
    },
    {
        label: '£23.95',
        value: 23.95
    },
    {
        label: '£24.95',
        value: 24.95
    },
    {
        label: '£25.95',
        value: 25.95
    },
    {
        label: '£26.95',
        value: 26.95
    },
    {
        label: '£27.95',
        value: 27.95
    },
    {
        label: '£28.95',
        value: 28.95
    },
    {
        label: '£29.95',
        value: 29.95
    },
    {
        label: '£30.95',
        value: 30.95
    },
    {
        label: '£31.95',
        value: 31.95
    }
]

export const ONE_TWO_THREE_SEND_PRODUCT_BUNDLES = {
    A920_24_MONTH: 'A920-24',
    A920_36_MONTH: 'A920-36',
    A920_48_MONTH: 'A920-48'
}

export const BUNDLE_ACQUIRER_REQUIREMENT_TYPE = {
    NO_TID: 0,
    DEVICE_TID: 1,
    CNP_TID: 2
}
