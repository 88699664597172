import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    orderingAs: string
    onHold: string
    onHoldDescription: string
    searchBy: string
    selectCompany: string
    companyPurposeExplanation: string
    confirm: string
    areYouSureToChangeCompany: any
    yes: string
    cancel: string
    directMerchant: string
    resellerCustomer: string
    agent: string
    partner: string
    dealerEposSupplier: string
    referrer: string
    parentCompany: string
    iso: string
    acquirer: string
}>()
