import React, { useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Col, Row, Form } from 'antd'
import { getFieldValue } from '~/code/services'
import { Input, DatePicker, Checkbox, Select, Button } from '~/code/common/components'
import { stringToDate } from './services'
import translations from './translations'
import { OrderDetailsFormProps } from './props'
import styles from './OrderDetailsForm.scss'
import { OTTSEND_DEAL_ID_PATTERN } from '~/code/models'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { ORDER_TYPE } from '~/code/POS/pages/NewOrder/components/123send/OrderDetailsForm/models/OrderType'
import { DeleteOutlined } from '@ant-design/icons/lib'

export const OrderDetailsForm = observer(({ store }: OrderDetailsFormProps) => {
    const { orderDetailsFormData: data, merchantType, setOrderDetailsFormData, orderTypes, addUpgradeRow, deleteUpgradeRow, onSerialNumberChange, onTIDChange, partnerCompanies, canGoBack, goBack } = store
    const [form] = Form.useForm()

    const [containsAcquirerRevenue, setContainsAcquirerRevenue] = useState(Boolean(data.containsAcquirerRevenue))
    const [isOrderTypeUpgrade, setOrderTypeUpgrade] = useState(data.orderType === ORDER_TYPE.upgrade)

    const onFinish = (values) => {
        setOrderDetailsFormData({
            ...values,
            requestedDeliveryAndOrActivationDate: values.requestedDeliveryAndOrActivationDate ?
                (values.requestedDeliveryAndOrActivationDate as unknown as moment.Moment).format('YYYY-MM-DD') :
                ''
        })
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    function disabledDate(date) {
        // Can not select days before today and today
        return date && date < moment().endOf('day')
    }

    const onContainsAcquirerRevenueCheck = (checked: boolean) => {
        setContainsAcquirerRevenue(checked)
    }

    const onOrderTypeChange = (orderType: number) => {
        setOrderTypeUpgrade(orderType === ORDER_TYPE.upgrade)
        store.onOrderTypeChange(orderType)
    }

    const onAddUpgradeRowClick = () => {
        addUpgradeRow()
    }

    const onDeleteUpgradeRowClick = (index) => {
        deleteUpgradeRow(index)
        form.resetFields([
            ...data.upgradeDetails.map((item, _index) => ['upgradeDetails', _index, 'serialNumber']),
            ...data.upgradeDetails.map((item, _index) => ['upgradeDetails', _index, 'tid'])
        ])
    }

    const formInitialValues = {
        poNumber: data.poNumber,
        requestedDeliveryAndOrActivationDate: stringToDate(data.requestedDeliveryAndOrActivationDate) || null,
        orderType: data.orderType,
        containsAcquirerRevenue
    }

    const generateTIDRow = (index, id, serialNumber?, tid?) => {
        return (
            <Row justify={'space-between'}>
                <Col xs={24} md={9}>
                    <Form.Item
                        key={id + 'serialNumber'}
                        label={translations().serialNumber}
                        name={['upgradeDetails', id, 'serialNumber']}
                        initialValue={serialNumber}
                        rules={
                            [
                                {
                                    required: true,
                                    message: translations().serialNumberRequired
                                }
                            ]
                        }
                    >
                        <Input placeholder={translations().serialNumber} onChange={(event) => onSerialNumberChange(index, event.target.value)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={9}>
                    <Form.Item
                        label={translations().tid}
                        key={id + 'tid'}
                        name={['upgradeDetails', id, 'tid']}
                        initialValue={tid}
                        rules={
                            [
                                {
                                    required: true,
                                    message: translations().tidRequired
                                }
                            ]
                        }
                    >
                        <Input placeholder={translations().tid} onChange={(event) => onTIDChange(index, event.target.value)} value={tid}/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={2}>
                    <div className={styles.deleteUpgradeRowButtonWrapper}>
                        <Button onClick={() => onDeleteUpgradeRowClick(index)} status={'danger'} disabled={data.upgradeDetails.length <= 1}>
                            <DeleteOutlined />
                        </Button>
                    </div>
                </Col>
            </Row>
        )
    }

    const generateUpgradeDetails = () => {
        return (
            <>
                <div className={styles.OrderDetailsForm_subgroupTitle}>
                    {translations().upgradeDetails}
                </div>
                {
                    data.upgradeDetails?.map((item, index) => {
                        return generateTIDRow(index, item.id, item.serialNumber, item.tid)
                    })
                }
                <Row justify={'space-between'}>
                    <Col xs={24} md={4}>
                        <Form.Item>
                            <Button className={null} onClick={onAddUpgradeRowClick}>
                                {translations().addUpgradeRow}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </>
        )
    }


    return (
        <div className={styles.OrderDetailsForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={formInitialValues}>
                <div className={styles.OrderDetailsForm_subgroupTitle}>
                    {translations().orderInformation}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().poNumber}
                                name={'poNumber'}
                                initialValue={getFieldInitialValue('poNumber')}
                            >
                                <Input placeholder={translations().poNumber}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().activationDate}
                                name={'requestedDeliveryAndOrActivationDate'}
                            >
                                <DatePicker placeholder={translations().activationDate} disabledDate={disabledDate} format={'YYYY-MM-DD'} size={'large'}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().dealId}
                                name={'dealId'}
                                initialValue={getFieldInitialValue('dealId')}
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: translations().dealIdRequired
                                        },
                                        {
                                            pattern: OTTSEND_DEAL_ID_PATTERN,
                                            message: translations().invalidDealId
                                        },
                                        {
                                            len: 18,
                                            message: translations().maximumLength
                                        }
                                    ]
                                }
                            >
                                <Input placeholder={translations().dealId}/>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col xs={24} md={10}>
                            <Form.Item
                                label={translations().orderType}
                                name={'orderType'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().orderTypeRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().orderType}
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                            return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        onSelect={onOrderTypeChange}
                                        selectItems={orderTypes}/>
                            </Form.Item>
                    </Col>

                    {/*<Col xs={24} md={10}>*/}
                    {/*    <Form.Item*/}
                    {/*        label={translations().uniqueOrderReference}*/}
                    {/*        name={'uniqueOrderReference'}*/}
                    {/*        initialValue={getFieldInitialValue('uniqueOrderReference')}*/}
                    {/*        rules={*/}
                    {/*            [*/}
                    {/*                {*/}
                    {/*                    required: true,*/}
                    {/*                    message: translations().uniqueOrderReferenceRequired*/}
                    {/*                }*/}
                    {/*            ]*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        <Input placeholder={translations().uniqueOrderReference}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
                {/*{ merchantType === 'existing' && <Row justify={'space-between'}>*/}
                {/*    <Col xs={24} md={10}>*/}
                {/*        <Form.Item*/}
                {/*            label={translations().orderType}*/}
                {/*            name={'orderType'}*/}
                {/*            rules={[*/}
                {/*                {*/}
                {/*                    required: true,*/}
                {/*                    message: translations().orderTypeRequired*/}
                {/*                }*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Select placeholder={translations().orderType}*/}
                {/*                    showSearch={true}*/}
                {/*                    filterOption={(input, option) => {*/}
                {/*                        return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                {/*                    }}*/}
                {/*                    onSelect={onOrderTypeChange}*/}
                {/*                    selectItems={orderTypes}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row> }*/}
                { isOrderTypeUpgrade && generateUpgradeDetails() }
                <div className={styles.OrderDetailsForm_subgroupTitle}>
                    {translations().partnerDetails}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().partnerCompany}
                            name={'partnerCompany'}
                        >
                            <Select placeholder={translations().partnerCompany}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    selectItems={partnerCompanies}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={10}>
                        <Form.Item
                            label={translations().agentName}
                            name={'partnerCompanyAgentName'}
                            initialValue={getFieldInitialValue('agentName')}
                        >
                            <Input placeholder={translations().agentName}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.OrderDetailsForm_subgroupTitle}>
                    {translations().revenueDetails}
                </div>
                <Row>
                    <Col xs={24}>
                        <Form.Item
                            name={'containsAcquirerRevenue'}
                            valuePropName={'checked'}
                            rules={[
                                {
                                    transform: checked => (checked || undefined),
                                    type: 'boolean'
                                }
                            ]}
                        >
                            <Checkbox onChange={(e) => onContainsAcquirerRevenueCheck(e.target.checked)}>
                                {translations().containsAcquirerRevenue}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
