export enum LOCATION_ODIN_PATH_NAMES {
    MERCHANT_SCHEDULE = '/odin/merchant-schedule',
    MERCHANT_SELECTION = '/odin/merchant-selection',
    DEVICE_MANAGEMENT = '/odin/device-management',
    DEVICE_MANAGEMENT_DETAILS = '/odin/device-management/details',
    APPLICATION_MANAGEMENT = '/odin/application-management',
    FIRMWARE_MANAGEMENT = '/odin/firmware-management'
}

export const NotificationTypes = [
    {
        value: 1,
        label: 'Status Update'
    },
    {
        value: 2,
        label: 'Update Package'
    },
    {
        value: 3,
        label: 'Delete Application'
    },
    {
        value: 4,
        label: 'Send Logs'
    },
    {
        value: 5,
        label: 'Display / Print Message'
    },
    {
        value: 6,
        label: 'Lock Device'
    },
    {
        value: 7,
        label: 'Restart Device'
    },
    {
        value: 8,
        label: 'Set Launcher Application'
    },
    {
        value: 9,
        label: 'Update Device Configuration'
    },
    {
        value: 10,
        label: 'Device Maintenance'
    }
]

export const APPLICATION_MAX_FILE_SIZE = 200
export const FIRMWARE_MAX_FILE_SIZE = 750

export const DEFAULT_NOTIFICATION_DURATION = 3
