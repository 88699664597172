import translations from '../translations'

export type MerchantType = 'new' | 'existing'
export type OrganisationType = 'company' | 'soleTrader'
export type ConfigCountry = 'uk' | 'roi' | 'other'
export type OrderType = 'standard' | 'hardwareOnly'

export interface Currency {
    id: number
    name: string
    decimalPlaces: number
}

export const currenciesWithDecimals: Currency[] = [
    {
        id: 36,
        name: translations().australianDollar,
        decimalPlaces: 2
    },
    {
        id: 124,
        name: translations().canadianDollar,
        decimalPlaces: 2
    },
    {
        id: 203,
        name: translations().czechKoruna,
        decimalPlaces: 2
    },
    {
        id: 208,
        name: translations().danishKrone,
        decimalPlaces: 2
    },
    {
        id: 344,
        name: translations().danishKrone,
        decimalPlaces: 2
    },
    {
        id: 348,
        name: translations().hungarianForint,
        decimalPlaces: 2
    },
    {
        id: 352,
        name: translations().icelandicKrona,
        decimalPlaces: 0
    },
    {
        id: 356,
        name: translations().indianRupee,
        decimalPlaces: 2
    },
    {
        id: 392,
        name: translations().japaneseYen,
        decimalPlaces: 0
    },
    {
        id: 512,
        name: translations().omaniRial,
        decimalPlaces: 3
    },
    {
        id: 554,
        name: translations().newZealandDollar,
        decimalPlaces: 2
    },
    {
        id: 578,
        name: translations().norwegianKrone,
        decimalPlaces: 2
    },
    {
        id: 682,
        name: translations().saudiRiyal,
        decimalPlaces: 2
    },
    {
        id: 702,
        name: translations().singaporeDollar,
        decimalPlaces: 2
    },
    {
        id: 752,
        name: translations().swedishKrona,
        decimalPlaces: 2
    },
    {
        id: 756,
        name: translations().swissFranc,
        decimalPlaces: 2
    },
    {
        id: 764,
        name: translations().thaiBaht,
        decimalPlaces: 2
    },
    {
        id: 784,
        name: translations().uaeDirham,
        decimalPlaces: 2
    },
    {
        id: 826,
        name: translations().poundSterling,
        decimalPlaces: 2
    },
    {
        id: 840,
        name: translations().usaDollar,
        decimalPlaces: 2
    },
    {
        id: 978,
        name: translations().euro,
        decimalPlaces: 2
    }
]