import { action, computed, makeObservable, observable } from 'mobx'
import { IMerchantDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/IMerchantDetailsFormStore'
import { createMerchantDetailsFormData, MerchantDetailsFormData } from '~/code/POS/pages'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { SelectItem } from '~/code/common/components'
import { AppStore } from '~/code/AppStore'
import { ConfigCountry } from '~/code/POS/pages/NewOrder/models/constants'
import { isEmpty } from 'dna-common'
import { fetch123SendAccountManagers } from '~/code/stores/NewOrderStore/services/fetchers'
import { LARGE_PAGE_SIZE } from '~/code/models'

export class MerchantDetailsForm123SendStoreStore implements IMerchantDetailsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler, merchantDetailsFormData?: MerchantDetailsFormData) {
        makeObservable(this, {
            _accountManagers: observable,
            isLoadingAccountManagers: observable,
            merchantDetailsFormData: observable,
            canGoBack: observable,
            countries: computed,
            configCountry: computed,
            isNewOrderForSoleTrader: computed,
            accountManagers: computed,
            setMerchantDetailsFormData: action,
            goBack: action,
            loadAccountManagers: action
        })

        this.merchantDetailsFormData = merchantDetailsFormData || createMerchantDetailsFormData()
    }

    _accountManagers: SelectItem[] = []
    isLoadingAccountManagers: boolean = false

    merchantDetailsFormData: MerchantDetailsFormData = null

    canGoBack: boolean = false

    static stepName: string = 'merchantDetails'

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get configCountry(): ConfigCountry {
        return AppStore.newOrderStore?.placeOrderConfigStore.country
    }

    get isNewOrderForSoleTrader(): boolean {
        return AppStore.newOrderStore?.isNewOrderForSoleTrader
    }

    get accountManagers(): SelectItem[] {
        this.loadAccountManagers()
        return this._accountManagers
    }

    setMerchantDetailsFormData = (data: MerchantDetailsFormData) => {
        this.merchantDetailsFormData = {...this.merchantDetailsFormData, ...data, isNew: true}
        this.formSubmitHandler.handleFormSubmit(data, MerchantDetailsForm123SendStoreStore.stepName)
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(MerchantDetailsForm123SendStoreStore.stepName)
    }

    loadAccountManagers = async () => {
        if (this.isLoadingAccountManagers || !isEmpty(this._accountManagers)) { return }
        this.isLoadingAccountManagers = true
        this._accountManagers = await fetch123SendAccountManagers(1, LARGE_PAGE_SIZE)
        this.isLoadingAccountManagers = false
    }

}
