import React from 'react'
import { SvgDropDownArrow } from '~/assets/icons'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'
import mainStyles from '~/assets/styles/main.scss'
import styles from './UserShortInfo.scss'

export const UserShortInfo = ({userInfo} : {userInfo: UserInfo}) => {
    return <div className={styles.UserShortInfo}>
        <div className={styles.UserShortInfo_userInfoWrapper}>
            <div className={styles.UserShortInfo_userFullName}>
                <span> {userInfo.firstName} {userInfo.surname} </span>
            </div>
            { (userInfo.userType && userInfo.userType !== 'none') &&
            <div className={styles.UserShortInfo_userType}>
                {userInfo.userType}
            </div>
            }
        </div>
        <SvgDropDownArrow className={mainStyles.icon}/>
    </div>
}
