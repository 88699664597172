// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2fa5Xb9eiaN9D5fGuOxtA5 {\n  background-color: transparent; }\n\n._2X_j2c_SU9bKo2M4H21DWY {\n  line-height: 1.7; }\n  ._2X_j2c_SU9bKo2M4H21DWY .yBxT1O-4LlDvPuGVBHPFW {\n    font-size: 0.7rem;\n    line-height: 0.8rem;\n    font-style: italic; }\n\n.ant-select-item-option-content {\n  white-space: break-spaces;\n  word-break: break-word; }\n", ""]);
// Exports
exports.locals = {
	"BundleSelect": "_2fa5Xb9eiaN9D5fGuOxtA5",
	"BundleOption": "_2X_j2c_SU9bKo2M4H21DWY",
	"subLabel": "yBxT1O-4LlDvPuGVBHPFW"
};
module.exports = exports;
