const pascalCasePattern = /^([A-Z])([a-z]+)/

export function pascalCaseToCamelCase(propname) {
    if (pascalCasePattern.test(propname)) {
        return propname.charAt(0).toLowerCase() + propname.slice(1)
    }
    else {
        return propname
    }
}

export function convertPropertyNames(obj, converterFn) {
    let r
    let value
    const t = Object.prototype.toString.apply(obj)

    if (t === '[object Object]') {
        r = {}

        // tslint:disable-next-line:forin
        for (const propname in obj) {
            value = obj[propname]
            r[converterFn(propname)] = convertPropertyNames(value, converterFn)
        }
        return r
    }
    else if (t === '[object Array]') {
        r = []
        for (let i = 0, L = obj.length; i < L; ++i ) {
            value = obj[i]
            r[i] = convertPropertyNames(value, converterFn)
        }
        return r
    }
    return obj
}
