import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { error } from 'dna-common'
import { notification } from 'antd'
import { fetchDevelopers } from '~/code/ODIN/stores/ApplicationManagementStore/services/fetchers'
import { SelectItem } from '~/code/common/components'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { LARGE_PAGE_SIZE } from '~/code/models'
import { IAppDeveloperStore } from '~/code/ODIN/pages/ApplicationManagement/IAppDeveloperStore'
import commonTranslations from '~/code/translations/translations'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class AppDeveloperStore implements IAppDeveloperStore {
    constructor () {
        makeObservable(this, {
            isLoading: observable,
            _developers: observable,
            totalPageItems: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,
            newDeveloper: observable,
            developers: computed,
            setDevelopers: action,
            loadDevelopers: action,
            setNewDeveloper: action,
            addNewDeveloper: action
        })
    }

    isLoading: boolean = false
    totalPageItems: number = 0
    totalCount: number = 0
    pageSize: number = LARGE_PAGE_SIZE
    pageNumber: number = 0
    _developers: SelectItem[] = []
    newDeveloper = {
        NewDeveloperName: '',
        NewDeveloperEmail: ''
    }

    public get developers(): SelectItem[] {
        if (!this._developers.length && !this.isLoading) {
            this.pageNumber = 0
            this.loadDevelopers()
        }

        return this._developers
    }

    public loadDevelopers = () => {
        if (this.isLoading) {
            return
        }

        this.pageNumber++

        this.isLoading = true

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber
        }

        fetchDevelopers(queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    runInAction(() => {
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this._developers = [...this._developers, ...response?.result?.map(item => ({value: item.id, label: item.name, key: item.id}))]
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    setDevelopers (developers: SelectItem[]): void {
        this._developers = developers
    }

    addNewDeveloper (newDeveloper): void {
        if (this.newDeveloper.NewDeveloperName && this.newDeveloper.NewDeveloperEmail) {
            this._developers.shift()
            this.setNewDeveloper(newDeveloper)

            return
        }

        this.setNewDeveloper(newDeveloper)
    }

    setNewDeveloper = (newDeveloper) => {
        this._developers = [
            {
                label: `${newDeveloper.NewDeveloperName} - ${newDeveloper.NewDeveloperEmail}`,
                value: `${newDeveloper.NewDeveloperName} - ${newDeveloper.NewDeveloperEmail}`,
                params: newDeveloper
            },
            ...this._developers
        ]

        this.newDeveloper = newDeveloper
    }
}
