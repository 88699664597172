import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { v4 as uuid } from 'uuid'
import { fetchApnDetails } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { EXTRA_LARGE_PAGE_SIZE } from '~/code/models'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { ApnDetail } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/ApnDetail'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class ApnDetailsStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            apnDetails: observable,
            totalPageItems: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,
            permissions: computed,
            setApnDetails: action,
            loadApnDetails: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    isLoading: boolean = false
    apnDetails: ApnDetail[] = []
    totalPageItems: number = 0
    totalCount: number = 0
    pageSize: number = EXTRA_LARGE_PAGE_SIZE
    pageNumber: number = 0

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    setApnDetails = (apnDetails: ApnDetail[]) => {
        this.apnDetails = apnDetails
    }

    public loadApnDetails = (deviceId: number, pageNumber?: number) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber

        if (this.pageNumber === 1) {
            this.apnDetails = []
        }

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber
        }

        fetchApnDetails(deviceId, queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    runInAction(() => {
                        const apnDetails = response.result.map((item) => ({...item, key: uuid()}))
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.setApnDetails(apnDetails)
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
