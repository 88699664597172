// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2zTUxXbK3aWCt0lbftPfaA {\n  font-family: Open Sans;\n  font-weight: normal;\n  width: 935px !important; }\n  ._2zTUxXbK3aWCt0lbftPfaA .ant-modal-title {\n    text-align: center;\n    font-size: 24px;\n    line-height: 30px; }\n  ._2zTUxXbK3aWCt0lbftPfaA .ant-modal-body {\n    max-height: 550px;\n    overflow-y: auto; }\n  @media (max-width: 980px) {\n    ._2zTUxXbK3aWCt0lbftPfaA {\n      top: 20px; }\n      ._2zTUxXbK3aWCt0lbftPfaA .ant-modal-body {\n        max-height: 500px; } }\n  ._3kUETvpeXmoNv-qJohiouZ {\n    width: 550px !important; }\n", ""]);
// Exports
exports.locals = {
	"modal": "_2zTUxXbK3aWCt0lbftPfaA",
	"modal__smallerWidth": "_3kUETvpeXmoNv-qJohiouZ"
};
module.exports = exports;
