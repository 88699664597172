import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Empty, Select, Spin } from 'antd'
import { SearchBarProps } from './props'
import styles from './SearchBar.scss'
import translations from './translations'
import _ from 'lodash'
import { isEmpty } from 'dna-common'

const Option = Select.Option

export const SearchBar = observer((props: SearchBarProps) => {
    const { store, children } = props
    const [isActive, setActive] = useState(false)
    const searchBarClassName = `${styles.SearchBar} ${!props.disabled && isActive ? styles.SearchBar__active : ''} ${props.disabled ? styles.SearchBar__disabled : ''}`
    const ref = useRef(null)
    const selectRef = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setActive(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    function onChange(value) {
        if (props.onSearchItemSelect) {
            props.onSearchItemSelect(value)
            return
        }
        if (!value){
            store.setItems([])
            store.selectItem(value)
            return
        }
        store.selectItem(value)
        selectRef?.current?.blur()
    }

    const onClear = () => {
        if (props.onClear) {
            props.onClear()
            return
        }
        store.setItems([])
        store.selectItem(null)
    }

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur()
            return
        }
    }

    const delayedQuery = useCallback(_.debounce(q => {store && store.search && store.search(q)}, 500), [])

    function onSearch(val) {
        if (props.onSearch) {
            props.onSearch(val)
        }
        delayedQuery(val)
    }

    const renderNotFoundContent = () => {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null}>
            { props.searchInputNoDataContent || <p className={'ant-empty-description'}>{translations().nothingFound}</p> }
        </Empty>
    }

    let items = store && store.items

    if (props.searchShouldPreserveSelectedItemWhenNoData && isEmpty(items.slice()) && store.selectedItem) {
        items = [store.selectedItem]
    }

    const _value = isEmpty(items) ? null :
        props.searchInputValue !== undefined ? props.searchInputValue :
            (store.selectedItem?.identifier || null)

    const select = children ? children : (
        <Select
            showSearch
            placeholder={props.searchInputPlaceholder || translations().typeToSearch}
            optionFilterProp={'title'}
            onSelect={onChange}
            onSearch={onSearch}
            notFoundContent={store.isSearching ? <Spin size={'small'}/> : renderNotFoundContent()}
            showArrow={props.searchShowArrow || false}
            onPopupScroll={props.searchOnPopupScroll || undefined}
            onFocus={props.searchOnFocus || undefined}
            allowClear={props.searchInputAllowClear}
            onClear={onClear}
            onBlur={onBlur}
            disabled={props.disabled}
            value={ _value }
            bordered={false}
            size={'large'}
            ref={selectRef}
        >
            { !store.isSearching && items && items.map((item, index) => {
                const description = item.description.replace('<br/>', ' ')
                const hiddenDescription = item.hiddenDescription ? item.hiddenDescription : ''
                return <Option
                    key={`${index}-${item.identifier}-${item.title}${item.description}`}
                    title={`${item.title} ${description} ${hiddenDescription}`}
                    value={`${item.identifier}`}
                >
                    <div className={styles.Search_option}>
                        <div className={styles.Search_option_title}>{item.title}</div>
                        <div className={styles.Search_option_description} dangerouslySetInnerHTML={{__html: item.description}}/>
                    </div>
                </Option>
            })}
        </Select>
    )


    return <div ref={ref} className={searchBarClassName} onClick={() => setActive(true)}>
        <div className={styles.SearchBar_iconContainer}>
            <div className={styles.SearchBar_searchIcon}/>
        </div>
        <div className={styles.SearchBar_divider}/>
        <div className={styles.SearchBar_searchContainer}>
            <div className={styles.Search}>
                {select}
            </div>
        </div>
    </div>
})
