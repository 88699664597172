export const IDLE_TIME_OUT_TIME = 60 * 1000 * 25 // 25 minutes in milliseconds
export const PAGE_SIZE = 20
export const SMALL_PAGE_SIZE = 10
export const EXTRA_SMALL_PAGE_SIZE = 5
export const LARGE_PAGE_SIZE = 50
export const EXTRA_LARGE_PAGE_SIZE = 100

export enum LOCAL_STORAGE_TOKEN_KEYS {
    DNA_API = '_dnaApiToken_',
    GRAPH_QL_MANAGEMENT_API = '_managementApiToken'
}
