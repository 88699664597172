import React from "react";
const SvgLogOut = props => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
      fill="#F5F5F5"
    />
    <mask
      id="log-out_svg__a"
      maskUnits="userSpaceOnUse"
      x={11}
      y={11}
      width={18}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H13a2 2 0 01-2-2v-4h2v4h14V13H13v4h-2v-4a2 2 0 012-2zm6.5 14l-1.41-1.41L20.67 21H11v-2h9.67l-2.58-2.59L19.5 15l5 5-5 5z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#log-out_svg__a)">
      <path fill="#00A0DC" d="M8 8h24v24H8z" />
    </g>
  </svg>
);
export default SvgLogOut;
