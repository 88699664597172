import React, { useEffect, useState } from 'react'
import page from 'page'
import { observer } from 'mobx-react'
import { Avatar, Card, Descriptions, PageHeader, Tabs, Typography } from 'antd'
import { Page } from '~/code/common/pages/Page'
import { Button } from '~/code/common/components'
import { PlusOutlined } from '@ant-design/icons'
import { AddNewApplication } from '~/code/ODIN/pages/ApplicationManagement/components/AddNewApplication/AddNewApplication'
import { VersionHistoryList } from '~/code/ODIN/pages/ApplicationManagementDetails/components/VersionHistoryList/VersionHistoryList'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import { APPLICATION_MAX_FILE_SIZE, LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import translations from './translations'
import { IApplicationManagementDetailsProps } from './props'
import styles from './ApplicationManagementDetails.scss'

const {Title, Text} = Typography
const {TabPane} = Tabs

export const ApplicationManagementDetails = observer(({store, id}: IApplicationManagementDetailsProps) => {
    const {
        loadApplicationDetailsById, isLoading, applicationDetails, addNewVersionStore, versionHistoryStore, permissions,
        fillDeviceManufacturerOnNewVersionAdd
    } = store
    const [isAddNewVersion, setIsAddNewVersion] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            loadApplicationDetailsById(id)
            versionHistoryStore.loadVersionHistoryById(id)
        }
    }, [])

    const onAddNewVersionButtonClick = () => {
        setIsAddNewVersion(true)
        fillDeviceManufacturerOnNewVersionAdd(applicationDetails.deviceManufacturerName)
    }

    return (
        <Page>
            {!isAddNewVersion && (
                <PageHeader
                    ghost={false}
                    onBack={() => page(LOCATION_ODIN_PATH_NAMES.APPLICATION_MANAGEMENT)}
                    className={styles.pageHeader}
                    title={
                        <Title className={styles.title} level={3}>
                            {`${translations().applicationManagement} - ${id}`}
                        </Title>
                    }
                    extra={[
                        <>
                            {permissions?.includes('odin.applicationManagement.createVersion') && (
                                <Button
                                    key={'addNewVersion'}
                                    onClick={onAddNewVersionButtonClick}
                                    icon={<PlusOutlined />}
                                >
                                    {translations().uploadNewVersion}
                                </Button>
                            )}
                        </>
                    ]}
                >
                    {permissions?.includes('odin.applicationManagement.lastVersionDetail') && (
                        <Card loading={isLoading}>
                            <Card.Meta
                                description={
                                    <>
                                        <Descriptions
                                            title={applicationDetails?.displayName}
                                            size="small"
                                            column={2}
                                        >
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().category}</Text>}>
                                                <Text strong>{applicationDetails?.categories?.map(category => `${category.name} `)}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().developerName}</Text>}>
                                                <Text strong>{applicationDetails?.developerName}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().deviceTypes}</Text>}>
                                                <Text strong>{applicationDetails?.deviceTypes?.map(deviceType => `${deviceType.name} `)}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().email}</Text>}>
                                                <Text strong>{applicationDetails?.developerEmail}</Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<Text type={'secondary'}>{translations().packageName}</Text>}>
                                                <Text strong>{applicationDetails?.packageName}</Text>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                }
                                avatar={<Avatar className={styles.avatar} src="link" />}
                            />
                        </Card>
                    )}
                    <br/>
                    {permissions?.includes('odin.applicationManagement.versionHistory') && (
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={translations().versionHistory} key="1">
                                <VersionHistoryList
                                    applicationId={id}
                                    packageType={PackageType.Application}
                                    maxFileSize={APPLICATION_MAX_FILE_SIZE}
                                    store={versionHistoryStore}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                </PageHeader>
            )}

            {isAddNewVersion && <AddNewApplication store={addNewVersionStore} setIsAddNewApp={setIsAddNewVersion} applicationDetails={applicationDetails}/>}
        </Page>
    )
})
