import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { ModalIdleWarningProps } from './props'
import styles from './ModalIdleWarning.scss'
import { Modal, Progress } from 'antd'
import { Button } from '~/code/common/components'
import { COUNT_DOWN_FROM } from '~/code/common/components/IdleTimer/components/ModalIdleWarning/models/constants'

export const ModalIdleWarning = observer((props: ModalIdleWarningProps) => {
    const [countDown, setCountDown] = useState(COUNT_DOWN_FROM)
    const lastTime = useRef<number>(Math.floor(Date.now() / 1000) + COUNT_DOWN_FROM)
    const savedIntervalCallback = useRef(null)
    const intervalCallback = () => {
        if (countDown <= 1) {
            props.onCancel()
        }
        const now = Math.floor(Date.now() / 1000)
        setCountDown(lastTime.current - now)
    }

    useEffect(() => {
        savedIntervalCallback.current = intervalCallback
    })

    useEffect(() => {
        const tick = () => {
            savedIntervalCallback.current()
        }

        const id = setInterval(tick, 1000)
        return () => clearInterval(id)
    }, [])

    const percentFromSeconds: number = ((100 * (countDown)) / 60)
    return <Modal
        centered
        closable={false}
        visible={props.visible}
        footer={null}
        title={null}
        width={390}
        className={styles.ModalIdleWarning}
    >
        <div className={styles.idleTimerModal}>
            <div className={styles.title} >
                {props.warningModalHeader || translations().idleTimerTitle}
            </div>
            <div className={styles.content}>
                {props.warningModalContent || translations().idleTimerWarning}
            </div>
            <div className={styles.countDown}>
                <Progress
                    type={'circle'}
                    strokeLinecap={'round'}
                    percent={percentFromSeconds}
                    format={() => `${countDown}`} />
            </div>
            <div></div>
            <div className={styles.buttonsWrapper}>
                <Button outline={'outlined'} onClick={props.onCancel}>
                    {props.warningModalCancelButtonText || translations().idleTimerButtonStop}
                </Button>
                <Button onClick={props.onOk}>
                    {props.warningModalContinueButtonText || translations().idleTimerButtonContinue}
                </Button>
            </div>
        </div>
    </Modal>
})
