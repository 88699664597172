import translations from './translations'

translations.add('en', {
    dailyTransactionLimitByValue: 'Daily Transaction Limit (by Value)',
    dailyTransactionLimitByVolume: 'Daily Transaction Limit (by Volume)',
    monthlyTransactionLimitByValue: 'Monthly Transaction Limit (by Value)',
    monthlyTransactionLimitByVolume: 'Monthly Transaction Limit (by Volume)',
    maximumSingleTransactionByValue: 'Maximum Single Transaction (by Value)',
    maximumRefundByValue: 'Maximum Refund (by Value)',
    unlimited: 'Unlimited',
    fieldRequired: 'The field is required',
    max13Chars: 'Max 13 characters',
    max2DecimalPlaces: 'Max 2 decimal places'
})
