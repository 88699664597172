import { NewOrderStore } from '~/code/stores/NewOrderStore/NewOrderStore'
import {
    AddDepartmentForm,
    AddStoreForm, createAddDepartmentFormData, createAddProductFormData, createAddStoreFormData, createDeliveryDetailsFormData, createProductListFormData, DeliveryDetailsForm,
    deliveryDetailsFormDataToSummaryBlock, IDeliveryDetailsFormStore, OrderDetailsModel,
    ProductListForm, ProductListFormData,
    productListFormDataToSummaryBlock,
    StoresAndDepartmentsForm
} from '~/code/POS/pages'
import { OrderDetailsForm, createOrderDetailsFormData } from '~/code/POS/pages/NewOrder/components/123send'
import React from 'react'
import page from 'page'
import { action, autorun, makeObservable, observable, override, reaction, computed, runInAction } from 'mobx'
import translations from '~/code/POS/pages/NewOrder/translations/translations'
import { SummaryBlockData } from '~/code/common/components'
import { error, isEmpty } from 'dna-common'
import moment from 'moment'
import {
    orderDetailsToSummaryBlock
} from '~/code/POS/pages/NewOrder/services/order-summary-utils'
import { removeCompanyNumberFromMerchantName, createValidCompanyNumber, getAllStoreDepartments, getExistingStoreIds, getExistingDepartmentIds, setStoreIdsToOrderRequest, setDepartmentIdsToOrderRequest } from '~/code/stores/NewOrderStore/services/utils'
import { EmployeeMerchant } from '~/code/POS/pages/NewOrder/models/EmployeeMerchant'
import { AppStore } from '~/code/AppStore'
import {
    createPlaceOrderRequestFromData, mapBusinessEntities, merchantDetailsFormDataToSummaryBlock,
    orderDetailsFormDataToSummaryBlock
} from '~/code/POS/pages/NewOrder/services/123send'
import { IOrderDetailsFormStore } from '~/code/POS/pages/NewOrder/components/123send/OrderDetailsForm/IOrderDetailsFormStore'
import { createOTTSendMerchantDetailsFormData, MerchantDetails123SendForm, OTTSendMerchantDetailsFormData } from '~/code/POS/pages/NewOrder/components/123send/MerchantDetailsForm'
import { StoresAndDepartmentsFormStore } from '~/code/stores/NewOrderStore/common/StoresAndDepartmentsFormStore'
import { IStoresAndDepartmentsFormStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/IStoresAndDepartmentsFormStore'
import { IProductListFormStore } from '~/code/POS/pages/NewOrder/components/common/ProductListForm/IProductListFormStore'
import { ProductListFormPartnerStore } from '~/code/stores/NewOrderStore/partner/ProductListFormPartnerStore'
import { DeliveryDetailsPartnerStore } from '~/code/stores/NewOrderStore/partner/DeliveryDetailsPartnerStore'
import { OrderDetailsForm123SendStore } from '~/code/stores/NewOrderStore/123send/OrderDetailsForm123SendStore'
import { MerchantType } from '~/code/POS/pages/NewOrder/models/constants'
import { OrderInfo } from '~/code/stores/OrderHistoryStore/models/OrderInfo'
import { ProductListForm123SendStore } from '~/code/stores/NewOrderStore/123send/ProductListForm123SendStore'
import { OWNER_GROUPS, PAGE_SIZE } from '~/code/models'
import { IMerchantDetailsForm123SendStore } from '~/code/POS/pages/NewOrder/components/123send/MerchantDetailsForm/IMerchantDetailsForm123SendStore'
import { MerchantDetailsForm123SendStoreStore } from '~/code/stores/NewOrderStore/123send/MerchantDetailsForm123SendStore'
import { AddProduct123SendForm } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/AddProduct123SendForm'
import { PlaceOrderConfiguration123SendStore } from '~/code/stores/PlaceOrderConfigurationStore/PlaceOrderConfiguration123SendStore'
import { convertPropertyNames, pascalCaseToCamelCase } from '~/code/services/case-converter'
import {
    place123SendMerchantRequestToAddDepartment, place123SendMerchantRequestToAddStore, place123SendMerchantRequestToDeliveryDetails,
    place123SendMerchantRequestToMerchantDetails, place123SendMerchantRequestToOrderDetails, place123SendMerchantRequestToProductList,
    place123SendMerchantRequestToStoresAndDepartments
} from '~/code/stores/NewOrderStore/services/mapper'
import { OTTSendPlaceOrderRequest } from '~/code/POS/pages/NewOrder/models/123send/123SendPlaceOrderRequest'
import { fetch123SendMerchants, fetchBusinessEntities, fetchPartnerMerchants } from '../services/fetchers'
import { LOCATION_POS_PATH_NAMES } from '~/code/POS/constants'
import { INewOrder123SendStore } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/components/123send/INewOrder123SendStore'

export class NewOrder123SendStore extends NewOrderStore implements INewOrder123SendStore {

    constructor () {
        super()

        makeObservable(this, {
            existing123SendMerchants: observable,
            isBusinessEntitiesLoading: observable,
            selectedExisting123SendMerchant: observable,
            isExistingMerchant: observable,
            currentSelectMerchantGroupCompanyStep: observable,
            businessEntities: observable,
            businessEntityId: observable,
            steps: override,
            generateCreateOrderSteps: override,
            generatePlaceOrderRequestAndURL: override,
            createOrderFromStringAndOrder: override,
            createOrderFromOrderInfo: override,
            onMerchantTypeChange: override,
            clearAndSetUp: override,
            orderSummaryData: override,
            internalIdentifierEncoded: override,
            selectItem: override,
            search: override,
            shouldShowForm: override,
            setShouldShowForm: override,
            placeOrder: override,
            orderSummaryCheckText: override,
            orderSummaryCheckError: override,
            is123SendFailedOrder: computed,
            generateOrderSummaryData: action,
            getMerchantInfo: action,
            handleFormSubmit: action,
            handleGoBack: action,
            setIs123SendFailedOrder: action,
            _orderSummaryData: observable,
            _is123SendFailedOrder: observable,
            _internalIdentifier: observable,
            internalIdentifier: override,
            searchIn123SSendCompanies: action,
            searchExistingMerchants: action,
            searchExistingMerchantsByInternalIdentifier: action,
            setIsExistingMerchant: action,
            getBusinessEntities: action,
            setBusinessEntityId: action
        })

        this.placeOrderConfigStore = new PlaceOrderConfiguration123SendStore(this)
        this.addDepartmentFormStore.shouldShowTIDFormItem = true
        this.steps = ['confirmMerchantType', 'creatingOrder', 'summary', 'placingOrder']

        // when the selected company changes
        reaction(() => this.selectedItem, (selectedItem) => {
            if (this.placeOrderConfigStore.merchantType === 'new' && selectedItem) {
                const company = selectedItem?.additionalInfo
                if (this.placeOrderConfigStore.country === 'uk') {
                    this.merchantDetailsStore.merchantDetailsFormData = {
                        ...this.merchantDetailsStore.merchantDetailsFormData,
                        ...this.generateInformationFormDataFromCHResponse(company),
                        receiveSalesAndMarketing: true
                    }
                } else {
                    this.merchantDetailsStore.merchantDetailsFormData = {
                        ...this.merchantDetailsStore.merchantDetailsFormData,
                        ...this.generateInformationFormDataFromCROResponse(company),
                        receiveSalesAndMarketing: true
                    }
                }
            }
        }, {fireImmediately: false})

        reaction(() => this.placeOrderConfigStore.merchantType, (merchantType) => {
            this.generateCreateOrderSteps()
            this.storesAndDepartmentsStore.canGoBack = merchantType === 'new'
            this.addProductFormStore.shouldShowBundleTypeCheckbox = (merchantType === 'existing' && (this.placeOrderConfigStore as PlaceOrderConfiguration123SendStore).orderType === 'standard')
            this.addProductFormStore.showOnlyHardwareOnlyBundles(merchantType === 'existing' && (this.placeOrderConfigStore as PlaceOrderConfiguration123SendStore).orderType === 'hardwareOnly')
        })

        reaction(() => (this.placeOrderConfigStore as PlaceOrderConfiguration123SendStore).orderType, (orderType) => {
            this.generateCreateOrderSteps()
            this.productListFormStore.canGoBack = orderType === 'standard'
            if (orderType === 'hardwareOnly') {
                this.addProductFormStore.shouldShowBundleTypeCheckbox = false
                this.addProductFormStore.showOnlyHardwareOnlyBundles(true)
                this.deliveryDetailsStore.isDeliverToRadioDisabled = true
                this.deliveryDetailsStore.deliveryDetailsFormData.deliverTo = 'deliverToMerchant'
            } else {
                this.addProductFormStore.shouldShowBundleTypeCheckbox = true
                this.addProductFormStore.showOnlyHardwareOnlyBundles(false)
                this.deliveryDetailsStore.isDeliverToRadioDisabled = false
                this.deliveryDetailsStore.deliveryDetailsFormData.deliverTo = 'deliverToStore'
            }
        })

        autorun(() => {
            const stores = this.storesAndDepartmentsStore.stores
            this.productListFormStore.stores = stores
            if (stores && stores.length > 0 && stores[0].departments && stores[0].departments.length > 0) {
                this.addProductFormStore.setSelectedStore(stores[0])
            }
        })

        this.addStoreFormStore.newOrderStore = this
        this.addDepartmentFormStore.newOrderStore = this

        this.getBusinessEntities()
    }

    private readonly merchantDetailsStore: IMerchantDetailsForm123SendStore = new MerchantDetailsForm123SendStoreStore(this, createOTTSendMerchantDetailsFormData())
    readonly storesAndDepartmentsStore: IStoresAndDepartmentsFormStore = new StoresAndDepartmentsFormStore(this, this.merchantDetailsStore)
    readonly addDepartmentFormStore = (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).addDepartmentFormStore
    private readonly addStoreFormStore = (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).addStoreFormStore
    readonly productListFormStore: IProductListFormStore = new ProductListForm123SendStore(this)
    private readonly addProductFormStore = this.productListFormStore as ProductListForm123SendStore
    private readonly orderDetailsFormStore: IOrderDetailsFormStore = new OrderDetailsForm123SendStore(this)
    private readonly deliveryDetailsStore: IDeliveryDetailsFormStore = new DeliveryDetailsPartnerStore(this)
    existing123SendMerchants: EmployeeMerchant[] = []
    selectedExisting123SendMerchant: { internalIdentifier: string, accountNumber: string }
    public isExistingMerchant = false
    public currentSelectMerchantGroupCompanyStep = 0
    public goBackToInformationReviewStep = 1
    public businessEntities = null
    public isBusinessEntitiesLoading = false
    public businessEntityId: number = null

    merchantDetailsForm = <MerchantDetails123SendForm store={this.merchantDetailsStore} setManuallyEnteredCompanyName={this.setManuallyEnteredCompanyName}/>
    storesAndDepartmentsForm = <StoresAndDepartmentsForm store={this.storesAndDepartmentsStore}>
                                    <AddStoreForm
                                        key={'add-store-form'}
                                        store={this.addStoreFormStore}
                                        configCountry={this.placeOrderConfigStore.country}
                                        onClose={(this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).onAddStoreFormClose}/>
                                    <AddDepartmentForm
                                        key={'add-department-form'}
                                        shouldShowVerifoneProperties={true}
                                        store={this.addDepartmentFormStore}
                                        onClose={(this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).onAddDepartmentFormClose}/>
                                </StoresAndDepartmentsForm>
    productListForm = <ProductListForm store={this.productListFormStore}>
                        <AddProduct123SendForm
                            store={this.productListFormStore as ProductListForm123SendStore}
                            onClose={this.addProductFormStore.onAddProductFormClose}/>
                    </ProductListForm>
    orderDetailsForm = <OrderDetailsForm store={this.orderDetailsFormStore}/>
    deliveryDetailsForm = <DeliveryDetailsForm store={this.deliveryDetailsStore}/>

    public _internalIdentifier // it's required in this store as the internal identifier is obtained when a user selects a company that is listed in the search bar result not in the select company component

    _is123SendFailedOrder: boolean = false

    get internalIdentifier(): string {
        return this._internalIdentifier
    }

    get internalIdentifierEncoded(): string {
        return encodeURIComponent(this._internalIdentifier || '')
    }

    get is123SendFailedOrder(): boolean {
        return this._is123SendFailedOrder
    }

    setIs123SendFailedOrder(value: boolean): void {
        this._is123SendFailedOrder = value
    }

    setShouldShowForm (value: boolean) {
        this._shouldShowForm = value
        this.setIs123SendFailedOrder(value)
    }

    get shouldShowForm(): boolean {
        return this._shouldShowForm || this.is123SendFailedOrder || !isEmpty(this.selectedCompanyName)
    }

    get orderSummaryCheckText() {
        return translations().agreeWithTermsDna
    }

    get orderSummaryCheckError() {
        return translations().confirmationDNARequired
    }

    placeOrder() {
        super.placeOrder()
        runInAction(() => this.businessEntityId = null)
    }

    @action
    search (value): void {
        if (value && value.length >= 3 ) {
            if (this.placeOrderConfigStore.merchantType === 'new') {
                if (this.placeOrderConfigStore.country === 'uk') {
                    super.searchInCH(value)
                } else {
                    super.searchInCRO(value)
                }
                this.searchExistingMerchants(value)
            } else {
                this.existingMerchants = []
                this.searchIn123SSendCompanies(value)
            }
        }

        if (!value || value.length === 0) {
            this.setItems([])
        }
    }

    public searchIn123SSendCompanies = (value) => {
        return fetch123SendMerchants({
            pageNumber: 1,
            pageSize: PAGE_SIZE,
            searchValue: value,
            ownerGroupId: OWNER_GROUPS['123send']
        })
        .then( response => {
            if (response && response.length > 0) {
                if (isEmpty(this.items)) {
                    this.setItems([])
                }
                this.items = response.map((merchant, index) => {
                    const companyRawName = merchant.companyName.replace(`(${merchant.accountNumber}) `, '')
                    return {identifier: merchant.internalIdentifier, title: merchant.companyName, description: `ACC: ${merchant.accountNumber}`, hiddenDescription: `${merchant.accountNumber}`, additionalInfo: { ...merchant, companyRawName}}
                })
            } else {
                this.items = []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
        .finally(() => {
            this.isSearching = false
        })
    }

    public searchExistingMerchants = (value) => {
        return fetch123SendMerchants({
            pageNumber: 1,
            pageSize: PAGE_SIZE,
            searchValue: value,
            ownerGroupId: OWNER_GROUPS['123send']
        })
        .then( response => {
            if (response && response.length > 0) {
                if (isEmpty(this.existing123SendMerchants)) {
                    this.existing123SendMerchants = []
                }

                this.existing123SendMerchants = response
            } else {
                this.existing123SendMerchants = []
            }
        })
        .catch((err: Error) => {
            error(`FAILED: ${err.message}`)
        })
        .finally(() => {
            this.isSearching = false
        })
    }

    public searchExistingMerchantsByInternalIdentifier(accountNumber, internalIdentifier) {
        return fetchPartnerMerchants({
            pageNumber: 1,
            pageSize: PAGE_SIZE,
            searchValue: accountNumber,
            internalIdentifier
        })
            .then((response) => {
                if (response && response.length > 0) {
                    this.existingMerchants = response.map(m => {
                        return {
                            id: m.merchantId,
                            name: m.merchantName
                        }
                    })
                } else {
                    this.existingMerchants = []
                }
            })
            .catch(err => {
                error(`FAILED: ${err.message}`)
            })
    }

    public getBusinessEntities() {
        runInAction(() => {
            this.isBusinessEntitiesLoading = true
        })

        return fetchBusinessEntities()
            .then((response) => {
                runInAction(() => {
                    this.businessEntities = mapBusinessEntities(response)
                })
            })
            .catch(err => {
                error(`FAILED: ${err.message}`)
            })
            .finally(() => {
                runInAction(() => {
                    this.isBusinessEntitiesLoading = false
                })
            })
    }

    generateCreateOrderSteps() {
        let steps: any[] = [
            {title: translations().merchantDetails, key: 'merchantDetails', children: this.merchantDetailsForm},
            {title: translations().storesAndDepartments, key: 'storesAndDepartments', children: this.storesAndDepartmentsForm},
            {title: translations().productList, key: 'productList', children: this.productListForm},
            {title: translations().orderDetails, key: 'orderDetails', children: this.orderDetailsForm},
            {title: translations().deliveryDetails, key: 'deliveryDetails', children: this.deliveryDetailsForm}
        ]

        if (this.placeOrderConfigStore.merchantType === 'existing') {
            steps = steps.filter(item => item.key !== 'merchantDetails')
        }

        if ((this.placeOrderConfigStore as PlaceOrderConfiguration123SendStore).orderType === 'hardwareOnly') {
            steps = steps.filter(item => item.key !== 'storesAndDepartments')
        }
        this.createOrderSteps = steps.map((item, index) => {return {...item, order: index + 1}})
    }

    async selectItem (value: string) {
        const selectedTempItem = this.items.find(item => item.identifier === value)

        if (this.placeOrderConfigStore.merchantType === 'new') {
            (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).clearAndLoadStores(this.internalIdentifierEncoded)
            await super.selectItem(value)

            this.existing123SendMerchants = this.existing123SendMerchants.map(m => {
                return {
                    ...m,
                    companyName: removeCompanyNumberFromMerchantName(m.companyName)
                }
            })
            this.selectedExisting123SendMerchant = this.existing123SendMerchants.find(m => m.companyName === selectedTempItem.title)
            this._internalIdentifier = this.selectedExisting123SendMerchant?.internalIdentifier

            if (this.selectedExisting123SendMerchant) {
                await this.searchExistingMerchantsByInternalIdentifier(this.selectedExisting123SendMerchant.accountNumber, this.selectedExisting123SendMerchant.internalIdentifier)
                this.selectedExistingMerchant = this.existingMerchants.find(m => m.name === this.selectedItem.title)
                this.isExistingMerchantModalOpen = !!this.selectedExistingMerchant
            }

            return
        }

        this._internalIdentifier = selectedTempItem.identifier
        super.selectItem(value);
        (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).isLoadingStores = true
        this.selectedItem = await this.getMerchantInfo(this._internalIdentifier, selectedTempItem.additionalInfo.accountNumber);
        (this.storesAndDepartmentsStore as StoresAndDepartmentsFormStore).clearAndLoadStores(this.internalIdentifierEncoded)
    }

    getMerchantInfo = (internalIdentifier, accountNumber) => {
        this.isLoading = true
        const data = {
            pageNumber: 1,
            pageSize: PAGE_SIZE,
            searchValue: accountNumber,
            internalIdentifier
        }

        return fetchPartnerMerchants(data).
        then( response => {
            if (response && response.length > 0) {
                const merchants = response.map(merchant => {
                    const description = `${translations().accountNumber}: ${merchant.accountNo}`
                    return {identifier: merchant.merchantId.toString(), title: merchant.merchantName, description, hiddenDescription: `${merchant.merchantId}${merchant.accountNo}`, additionalInfo: {...merchant, merchantsAtStoreLevel: this.selectedItem.additionalInfo.merchantsAtStoreLevel} } // MID: ${merchant.visaMid}
                }).filter((item, index, array) =>
                    array.findIndex(findItem => findItem.identifier === item.identifier) === index)
                const result = merchants.find(item => item.additionalInfo.accountNo === accountNumber) || merchants[0]
                return result
            }
            return null
        }).
        catch((err: Error) => {
            error(`FAILED: ${err.message}`)
            return null
        })
        .finally(() => {
            this.isLoading = false
        })
    }

    _orderSummaryData: SummaryBlockData[] = []

    get orderSummaryData() {
        this.generateOrderSummaryData()
        return this._orderSummaryData
    }

    generateOrderSummaryData = () => {
        const data: SummaryBlockData[] = []
        const initials = AppStore.userInfo?.firstName?.charAt(0)?.toUpperCase() + AppStore.userInfo?.surname?.charAt(0)?.toUpperCase()
        const companyName = (
            this.manuallyEnteredCompanyName || this.selectedCompanyName || this.merchantDetailsStore.merchantDetailsFormData.companyName
        )?.replace(/\s+/g, '')

        const _orderReference = '123Send-' + initials + `-${companyName}` + `-${moment().unix()}`
        this.orderReference = _orderReference
        data.push(orderDetailsToSummaryBlock(this.orderReference, this.selectedEmployeeCompany?.title))

        this.createOrderSteps.forEach(step => {
            switch (step.key) {
                // partner related
                case 'merchantDetails': {
                    data.push(merchantDetailsFormDataToSummaryBlock(this.merchantDetailsStore.merchantDetailsFormData as OTTSendMerchantDetailsFormData, AppStore.countriesStore?.countries, this.merchantDetailsStore.accountManagers))
                    break
                }
                case 'productList': {
                    data.push(productListFormDataToSummaryBlock(this.productListFormStore.productListFormData, this.storesAndDepartmentsStore.stores))
                    break
                }
                case 'orderDetails': {
                    data.push(orderDetailsFormDataToSummaryBlock(this.orderDetailsFormStore.orderDetailsFormData, this.orderDetailsFormStore.orderTypes, this.orderDetailsFormStore.partnerCompanies))
                    break
                }
                case 'deliveryDetails': {
                    data.push(deliveryDetailsFormDataToSummaryBlock(this.deliveryDetailsStore.deliveryDetailsFormData, AppStore.countriesStore?.countries))
                    break
                }
            }
        })
        this._orderSummaryData = data
    }

    clearAndSetUp() {
        this.shouldResetForm = true
        this.currentStep = 1
        this.currentCreateOrderStep = 0
        this.placingOrder = false
        this.items = []
        this.selectedItem = null
        this.orderReference = ''
        this.manuallyEnteredCompanyName = ''
        this.merchantDetailsStore && (this.merchantDetailsStore.merchantDetailsFormData = createOTTSendMerchantDetailsFormData())
        this.storesAndDepartmentsStore && (this.storesAndDepartmentsStore.setStores([]))
        this.addStoreFormStore && (this.addStoreFormStore.addStoreFormData = createAddStoreFormData())
        this.addDepartmentFormStore && (this.addDepartmentFormStore.addDepartmentFormData = createAddDepartmentFormData())
        this.productListFormStore && (this.productListFormStore.productListFormData = createProductListFormData())
        this.addProductFormStore && (this.addProductFormStore.addProductFormData = createAddProductFormData())
        this.addProductFormStore && (this.addProductFormStore.productType = null)
        this.orderDetailsFormStore && (this.orderDetailsFormStore.orderDetailsFormData = createOrderDetailsFormData())
        this.deliveryDetailsStore && (this.deliveryDetailsStore.deliveryDetailsFormData = createDeliveryDetailsFormData())
    }

    generatePlaceOrderRequestAndURL() {
        // merchantId is used only for existing merchants
        const merchantId = this.selectedItem?.additionalInfo?.merchantId || ''
        const companyNumber = createValidCompanyNumber(this.placeOrderConfigStore.country, this.selectedItem?.additionalInfo, this.merchantDetailsStore.merchantDetailsFormData.companyNumber)
        const request = createPlaceOrderRequestFromData(
            {...(this.merchantDetailsStore.merchantDetailsFormData as OTTSendMerchantDetailsFormData), companyNumber},
            this.storesAndDepartmentsStore.stores,
            this.productListFormStore.productListFormData,
            this.orderDetailsFormStore.orderDetailsFormData,
            this.deliveryDetailsStore.deliveryDetailsFormData,
            this.orderReference,
            merchantId,
            this.selectedCompany?.accountNo,
            this.createOrderSteps,
            this.businessEntityId,
            this.internalIdentifier
        )

        if (this.placeOrderConfigStore.merchantType === 'new') {
            delete request['internalIdentifier']
        }

        const requestURL = `/api/123send-orders/place-order`
        return {requestURL, request}
    }

    handleFormSubmit (data, stepName) {
        switch (stepName) {
            case StoresAndDepartmentsFormStore.stepName: {
                this.productListFormStore.stores = this.storesAndDepartmentsStore.stores
                if (this.productListFormStore.stores && this.productListFormStore.stores.length > 0) {
                    this.addProductFormStore.setSelectedStore(this.productListFormStore.stores[0])
                }

                break
            }
            case ProductListFormPartnerStore.stepName: {
                const productListFormData = data as ProductListFormData
                const instructions = this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions.map(item => {
                    return {storeId: item.storeId, isCustom: item.isCustom, deliveryInstructions: item.deliveryInstructions}
                })

                // update the stores list in the delivery details form
                this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions = []
                productListFormData.products.forEach(product => {
                    if (isEmpty(this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions?.find(item => item.storeId === product.storeId))) {
                        this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions.push({
                            storeId: product.storeId,
                            isCustom: false,
                            storeName: this.storesAndDepartmentsStore.stores.find(item => item.id === product.storeId).name,
                            deliveryInstructions: ''
                        })
                    }
                })

                // set instructions
                this.deliveryDetailsStore.deliveryDetailsFormData.storeDeliveryInstructions.forEach(instructionsItem => {
                    const foundInstructions = instructions.find(item => item.storeId === instructionsItem.storeId)
                    instructionsItem.deliveryInstructions = foundInstructions?.deliveryInstructions || ''
                    instructionsItem.isCustom = foundInstructions?.isCustom || false
                })
                break
            }
        }

        if (this.createOrderSteps[this.currentCreateOrderStep].key === 'deliveryDetails') {
            this.currentStep++
        } else {
            this.currentCreateOrderStep++
        }
    }

    handleGoBack () {
        if (this.steps[this.currentStep] !== 'creatingOrder') {
            this.currentStep--
        } else {
            this.currentCreateOrderStep--
        }
    }

    @action
    setMerchantType(type: MerchantType) {
        this.placeOrderConfigStore.setSelectedMerchantType(type)
    }

    // tslint:disable-next-line:no-empty
    onMerchantTypeChange(merchantType: MerchantType) {}

    async createOrderFromStringAndOrder (previousOrder: string, orderDetailsModel: OrderDetailsModel, employeeMerchant?: EmployeeMerchant) {
        const order: OTTSendPlaceOrderRequest = convertPropertyNames(JSON.parse(previousOrder), pascalCaseToCamelCase)

        const allStores = order?.merchant?.stores
        const allDepartments = getAllStoreDepartments(allStores)
        const existingStoreIds = getExistingStoreIds(allStores)
        const existingDepartments = getExistingDepartmentIds(allStores)

        const isAllStoresExist = existingStoreIds.length === allStores?.length

        const isAllDepartmentsExist = existingDepartments.length === allDepartments?.length

        const isExistingStoreWithDepartments = !isEmpty(order?.merchant?.existingMerchantId) && isAllStoresExist && isAllDepartmentsExist

        if (isExistingStoreWithDepartments) {
            this.setMerchantType('existing')
        } else {
            this.setMerchantType('new')
        }

        const orderWithStoreIds = setStoreIdsToOrderRequest(order)
        const orderWithDepartmentIds = setDepartmentIdsToOrderRequest(orderWithStoreIds)

        // clear the forms and set up
        this.clearAndSetUp()

        this.setIs123SendFailedOrder(true)

        this.items = [{
            identifier: employeeMerchant.internalIdentifier,
            title: employeeMerchant.companyName,
            description: employeeMerchant.companyName,
            hiddenDescription: String(employeeMerchant.companyTypeId),
            additionalInfo: employeeMerchant
        }]

        if (isExistingStoreWithDepartments) {
            this.selectItem(employeeMerchant?.internalIdentifier)
        } else {
            this.storesAndDepartmentsStore.stores = place123SendMerchantRequestToStoresAndDepartments(orderWithDepartmentIds)
        }

        // fill the forms with new data
        this.merchantDetailsStore.merchantDetailsFormData = place123SendMerchantRequestToMerchantDetails((orderWithDepartmentIds))
        this.addStoreFormStore.addStoreFormData = place123SendMerchantRequestToAddStore(orderWithDepartmentIds)
        this.productListFormStore.productListFormData.products = place123SendMerchantRequestToProductList(orderWithDepartmentIds)
        this.addDepartmentFormStore.addDepartmentFormData = place123SendMerchantRequestToAddDepartment(orderWithDepartmentIds)
        this.orderDetailsFormStore.orderDetailsFormData = place123SendMerchantRequestToOrderDetails(orderWithDepartmentIds)
        this.deliveryDetailsStore.deliveryDetailsFormData = place123SendMerchantRequestToDeliveryDetails(orderWithDepartmentIds)

        this.generateCreateOrderSteps()

        if (this.businessEntityId) {
            this.currentStep = 1
        }

        page(LOCATION_POS_PATH_NAMES.PLACE_ORDER)
    }

    // tslint:disable-next-line:no-empty
    createOrderFromOrderInfo (orderInfo: OrderInfo) {}

    loadStores() {
        this.storesAndDepartmentsStore.loadStores(this.internalIdentifierEncoded)
    }

    public setIsExistingMerchant(isExistingMerchant: boolean) {
        this.isExistingMerchant = isExistingMerchant
    }

    public setBusinessEntityId(id: number) {
        this.businessEntityId = id
        this.currentStep++
    }
}
