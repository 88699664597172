import React from 'react'
import translations from './translations'

translations.add('en', {
    emailInvalid: 'Email Invalid',
    enterEmail: 'Enter Email',
    enterName: 'Enter Name',
    addDeveloper: 'Add New Developer',
    nameRequired: 'Name Required',
    emailRequired: 'Email Required'
})
