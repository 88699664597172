// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._10BRncCyMFcRqS3KzQnc6e {\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  font-size: 24px;\n  cursor: pointer;\n  text-align: center; }\n  ._1TwID80OdI1Pl3C6LctHcc {\n    opacity: 0.4;\n    cursor: unset; }\n  ._10BRncCyMFcRqS3KzQnc6e:hover {\n    opacity: 0.4; }\n  ._10BRncCyMFcRqS3KzQnc6e svg path {\n    fill: #00A0DC; }\n", ""]);
// Exports
exports.locals = {
	"IconButton": "_10BRncCyMFcRqS3KzQnc6e",
	"IconButton__disabled": "_1TwID80OdI1Pl3C6LctHcc"
};
module.exports = exports;
