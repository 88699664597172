import React from 'react'
import translations from './translations'

translations.add('en', {
    noDate: '--',
    category: 'Category',
    displayName: 'Display Name',
    latestVersion: 'Latest Version',
    packageId: 'Package ID',
    packageName: 'Package Name',
    supportedDeviceTypes: 'Supported Device Types',
    uploadedDateTime: 'Uploaded Date Time',
    pleaseSelectAppDeveloper: 'Please Select Development Company',
    selectAppDeveloper: 'Select App Developer',
    searchName: 'Search by Name',
    search: 'Search',
    reset: 'Reset',
    cancel: 'Cancel'
})
