import { v4 as uuid } from 'uuid'
import { AddDepartmentFormData, AddStoreFormData, DeliverToType, DeliveryDetailsFormData, MerchantDetailsFormData, OrderDetailsFormData, Product, SDDepartment, SDStore } from '~/code/POS/pages'
import { PartnerPlaceOrderRequest } from '~/code/stores/NewOrderStore/models/PartnerPlaceOrderRequest'
import { PartnerDepartment } from '~/code/POS/pages/NewOrder/models/partner/PartnerDepartment'
import { OTTSendPlaceOrderRequest } from '~/code/POS/pages/NewOrder/models/123send/123SendPlaceOrderRequest'
import { OTTSendBundle } from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/models/OTTSendBundle'
import { OrderDetailsFormData as OTTOrderDetailsFormData } from '~/code/POS/pages/NewOrder/components/123send'

export function placePartnerMerchantRequestToMerchantDetails(order: PartnerPlaceOrderRequest): MerchantDetailsFormData {
    const { merchant } = order || {}
    const { newMerchant } = merchant || {}
    const { name: companyName, companyRegistrationNumber: companyNumber, tradingAs, vatNumber, address, contact, communicationDetails} = newMerchant || {}
    const { line1: addressLine1, line2: addressLine2, townOrCity: town, countryCode: country, postcode, county } = address || {}
    const { email: contactEmail, firstName: contactName, jobTitle: contactJobTitle, mobileNumber: contactMobilePhone,
        telephoneNumber: contactPhoneNumber, surname: contactSurname } = contact || {}
    const { receiveSalesAndMarketing } = communicationDetails || {}

    return {
        addressLine1,
        addressLine2,
        companyName,
        companyNumber,
        postcode,
        town,
        contactEmail,
        contactName,
        contactJobTitle,
        contactMobilePhone,
        contactPhoneNumber,
        contactSurname,
        country,
        county,
        isNew: Boolean(newMerchant),
        receiveSalesAndMarketing,
        tradingAs,
        vatNumber
    }
}

export function placePartnerMerchantRequestToAddStore(order: PartnerPlaceOrderRequest): AddStoreFormData {
    const {
        merchant: {
            stores: [firstStoreDetails] = []
        } = {}
    } = order
    const { newStore, existingStoreId } = {...firstStoreDetails}
    const { address, companyRegistrationNumber: storeCompanyRegistrationNumber = null, email: storeEmail = null, name: storeName = null,
        telephoneNumber: storeTelephoneNumber = null, vatNumber: storeVATNumber = null } = newStore
    const { countryCode: storeCountry = null, county: storeCounty = null, line1: storeAddressLine1 = null, line2: storeAddressLine2 = null,
        line3: storeAddressLine3 = null, postcode: storePostcode = null, townOrCity: storeTown = null } = address || {}

    return {
        storeAddressLine1,
        storeAddressLine2,
        storeAddressLine3,
        storeCompanyRegistrationNumber,
        storeTelephoneNumber,
        storePostcode,
        storeCountry,
        storeCounty,
        storeEmail,
        storeName,
        storeTown,
        storeCountryName: '',
        storeId: existingStoreId?.toString() || uuid(),
        storeVATNumber,
        useAxeptConnectCloud: newStore.useAxeptConnectCloud
    }
}

export function placePartnerMerchantRequestToAddDepartment(order: PartnerPlaceOrderRequest): AddDepartmentFormData {
    const {
        merchant: {
            stores: [firstStoreDetails] = []
        } = {}
    } = order
    const { departments: [firstDepartmentDetails] = [] } = firstStoreDetails
    const { newDepartment } = {...firstDepartmentDetails}
    const {  } = newDepartment || {}

    return {
        departmentAccountVerification: false,
        departmentAcquirer: 0,
        departmentAcquirerAssignedTIDs: [],
        departmentAcquirerName: '',
        departmentAmexAcquirer: 0,
        departmentAmexAcquirerName: '',
        departmentAmexBankAccountNumber: '',
        departmentAmexMerchantId: '',
        departmentAmexSortCode: '',
        departmentBankAccountNumber: '',
        departmentCashAdvance: false,
        departmentCashBack: 0,
        departmentCurrency: 0,
        departmentCurrencyName: '',
        departmentDinersDiscoverAcquirer: 0,
        departmentDinersDiscoverAcquirerName: '',
        departmentDinersDiscoverBankAccountNumber: '',
        departmentDinersDiscoverMerchantId: '',
        departmentDinersDiscoverSortCode: '',
        departmentEnableCashBack: false,
        departmentEnableGratuity: false,
        departmentEnablePurchase: false,
        departmentEnableRefund: false,
        departmentFlexecashAcquirer: 0,
        departmentFlexecashAcquirerName: '',
        departmentFlexecashBankAccountNumber: '',
        departmentFlexecashMerchantId: '',
        departmentFlexecashSortCode: '',
        departmentGratuity: 0,
        departmentId: '',
        departmentMaestroDebitMID: '',
        departmentMastercardDebitMID: '',
        departmentMastercardMID: '',
        departmentMerchantCategoryCode: 0,
        departmentMerchantCategoryCodeLabel: '',
        departmentMoto: false,
        departmentName: '',
        departmentPreAuth: false,
        departmentPurchase: 0,
        departmentRefund: 0,
        departmentSortCode: '',
        departmentStreamlineCompanyId: '',
        departmentType: undefined,
        departmentUnionPayAcquirer: 0,
        departmentUnionPayAcquirerName: '',
        departmentUnionPayBankAccountNumber: '',
        departmentUnionPayMerchantId: '',
        departmentUnionPaySortCode: '',
        departmentVisaDebitMID: '',
        departmentVisaMID: '',
        storeId: ''
    }
}

export function placePartnerMerchantRequestToStoresAndDepartments(order: PartnerPlaceOrderRequest): SDStore[] {
    const { merchant } = order

    const stores: SDStore[] = merchant?.stores?.map(store => {
        const { newStore, departments } = store || {}
        const { address } = newStore || {}

        return {
            id: store.id,
            name: newStore?.name,
            companyRegistrationNumber: newStore?.companyRegistrationNumber,
            telephoneNumber: newStore?.telephoneNumber,
            vatNumber: newStore?.vatNumber,
            country: address?.countryCode,
            addressLine1: address?.line1,
            addressLine2: address?.line2,
            addressLine3: address?.line3,
            postcode: address?.postcode,
            town: address?.townOrCity,
            county: address?.county,
            email: newStore?.email,
            countryName: '',
            isNew: Boolean(newStore),
            departments: placePartnerDepartmentsToSDDepartments(departments),
            isLoading: false,
            useAxeptConnectCloud: newStore.useAxeptConnectCloud
        }
    })

    return [
        ...stores
    ]
}

export function placePartnerMerchantRequestToProductList(order: PartnerPlaceOrderRequest): Product[] {
    const { merchant } = order || {}
    const { stores } = merchant || {}

    return stores.reduce((prevValue, currValue) => {
        const currProducts = currValue?.departments?.reduce((acc, department) => {
            const bundles = getProductBundles(department)

            return [{departmentId: department.id, storeId: department.storeId, bundles}, ...acc]
        }, [])

        return [...currProducts, ...prevValue]
    }, [])
}

function getProductBundles(department: PartnerDepartment): OTTSendBundle[] {
    return department.productDetails.map(detail => ({bundleCode: detail.bundleId, bundleDescription: detail.bundleId, bundleQuantity: detail.quantity, bundleType: 'standard'}))
}

function placePartnerDepartmentsToSDDepartments(departments: PartnerDepartment[]): SDDepartment[] {
    return departments?.map(department => {
        const { newDepartment } = department || {}
        const { transactionTypes, cardSchemeDetails } = newDepartment || {}
        const { visaMasterCard, flexecash, americanExpress, unionPay, dinersDiscover } = cardSchemeDetails || {}

        return {
            id: department.id,
            storeId: department.storeId,
            isNew: Boolean(newDepartment),
            name: newDepartment?.name,
            currencyId: newDepartment?.currencyId,
            currencyName: '',
            merchantCategoryCode: newDepartment?.merchantCategoryCode,
            merchantCategoryCodeLabel: '',
            streamlineCompanyId: newDepartment?.streamlineCompanyId,

            cashBackAccepted: transactionTypes?.cashbackAccepted,
            cashBack: transactionTypes?.cashbackMaxValue,
            gratuityAccepted: transactionTypes?.cashbackAccepted,
            gratuity: transactionTypes?.gratuityMaxValue,
            refundAccepted: transactionTypes?.refundAccepted,
            refund: transactionTypes?.refundMaxValue,
            purchaseAccepted: transactionTypes?.purchaseAccepted,
            purchase: transactionTypes?.purchaseMaxValue,

            visaMID: visaMasterCard?.visaMid,
            visaDebitMID: visaMasterCard.visaDebitMid,
            mastercardMID: visaMasterCard?.mastercardMid,
            mastercardDebitMID: visaMasterCard?.mastercardDebitMid,
            maestroDebitMID: visaMasterCard?.maestroDebitMid,
            acquirer: visaMasterCard?.acquirerId,
            acquirerName: '',
            bankAccountNumber: visaMasterCard?.accountNumber,
            sortCode: visaMasterCard?.sortCode,
            amexMerchantID: americanExpress?.mid,
            amexAcquirer: americanExpress?.acquirerId,
            amexAcquirerName: '',
            amexBankAccountNumber: americanExpress?.accountNumber,
            amexSortCode: americanExpress?.sortCode,
            unionPayMerchantID: unionPay?.mid,
            unionPayAcquirer: unionPay?.acquirerId,
            unionPayAcquirerName: '',
            unionPayBankAccountNumber: unionPay?.accountNumber,
            unionPaySortCode: unionPay?.sortCode,
            dinersDiscoverMerchantID: dinersDiscover?.mid,
            dinersDiscoverAcquirer: dinersDiscover?.acquirerId,
            dinersDiscoverAcquirerName: '',
            dinersDiscoverBankAccountNumber: dinersDiscover?.accountNumber,
            dinersDiscoverSortCode: dinersDiscover?.sortCode,
            flexecashMerchantID: flexecash?.mid,
            flexecashAcquirer: flexecash?.acquirerId,
            flexecashAcquirerName: '',
            flexecashBankAccountNumber: flexecash?.accountNumber,
            flexecashSortCode: flexecash?.sortCode,
            // TODO: Need to fill data from request - task: https://dnapayments.atlassian.net/browse/POS-581
            amexCpcMid: '',
            dinersMid: '',
            discoverMid: '',
            flexecashMid: '',
            jcbMid: '',
            maestroMid: '',
            mastercardOneMid: '',
            visaCpcMiMid: '',
            visaCpcVatMid: '',
            visaElectronMid: '',
            ukMaestroMid: '',
            unionPayMid: '',
            unionPayAmexMid: '',
            unionPayDinersMid: '',
            unionPayJcbMid: '',
            unionPayMaestroMid: '',
            unionPayMastercardMid: '',
            unionPayVisaMid: '',
            visaPurchasingMid: '',
            nectarMid: '',
            creationMid: '',
            aliPayMid: '',
            departmentType: 'general',
            acquirerAssignedTIDs: [],
            accountVerification: false,
            cashAdvance: false,
            moto: false,
            preAuth: false
        }
    })
}

export function placePartnerMerchantRequestToOrderDetails(order: PartnerPlaceOrderRequest): OrderDetailsFormData {
    const { brandingOrganizationId: brandingOrganisationId, poNumber, requestedDeliveryAndOrActivationDate, merchant } = order || {}
    const { activationDetails } = merchant || {}
    const { companyName, email, firstName, surname, useMainContact } = activationDetails || {}

    return {
        brandingOrganisationId,
        companyName,
        email,
        firstName,
        poNumber,
        requestedDeliveryAndOrActivationDate,
        surname,
        useMainContact
    }
}

export function placePartnerMerchantRequestToDeliveryDetails(order: PartnerPlaceOrderRequest): DeliveryDetailsFormData {
    const { merchant } = order
    const { deliveryDetails } = merchant || {}
    const { deliveryInstructions, deliverToMerchant, deliverToStore, newHardwareAddress } = deliveryDetails || {}
    const { contact, address } = newHardwareAddress || {}
    const { jobTitle, firstName, surname, telephoneNumber, mobileNumber, email } = contact || {}
    const { deliveryName, tradingAs, line1, line2, townOrCity, county, countryCode, postcode } = address || {}

    const deliverTo = getDeliveryTo({ deliverToMerchant, deliverToStore })

    return {
        addressLine1: line1,
        addressLine2: line2,
        contactMobilePhone: mobileNumber,
        contactName: firstName,
        contactPhoneNumber: telephoneNumber,
        contactSurname: surname,
        country: countryCode,
        county,
        deliverTo,
        deliveryInstructions,
        deliveryName,
        email,
        jobTitle,
        postcode,
        storeDeliveryInstructions: [],
        town: townOrCity,
        tradingAs
    }
}

function getDeliveryTo({ deliverToMerchant, deliverToStore }): DeliverToType {
    if (deliverToMerchant) {
        return 'deliverToMerchant'
    }

    if (deliverToStore) {
        return 'deliverToStore'
    }

    return 'deliverToNewAddress'
}

export function place123SendMerchantRequestToMerchantDetails(order: OTTSendPlaceOrderRequest): MerchantDetailsFormData {

    const { merchant } = order || {}
    const { newMerchant } = merchant || {}
    const { name: companyName, companyRegistrationNumber: companyNumber, tradingAs, vatNumber, address, contact, communicationDetails} = newMerchant || {}
    const { line1: addressLine1, line2: addressLine2, townOrCity: town, countryCode: country, postcode, county } = address || {}
    const { email: contactEmail, firstName: contactName, jobTitle: contactJobTitle, mobileNumber: contactMobilePhone,
        telephoneNumber: contactPhoneNumber, surname: contactSurname } = contact || {}
    const { receiveSalesAndMarketing } = communicationDetails || {}

    return {
        addressLine1,
        addressLine2,
        companyName,
        companyNumber,
        postcode,
        town,
        contactEmail,
        contactName,
        contactJobTitle,
        contactMobilePhone,
        contactPhoneNumber,
        contactSurname,
        financeContactName: newMerchant.financeContact?.firstName,
        financeContactSurname: newMerchant.financeContact?.surname,
        financeContactJobTitle: newMerchant.financeContact?.jobTitle,
        financeContactPhoneNumber: newMerchant.financeContact?.telephoneNumber,
        financeContactEmail: newMerchant.financeContact?.email,
        country,
        county,
        isNew: Boolean(newMerchant),
        receiveSalesAndMarketing,
        tradingAs,
        vatNumber
    }
}

export function place123SendMerchantRequestToAddStore(order: OTTSendPlaceOrderRequest): AddStoreFormData {
    const {
        merchant: {
            stores: [firstStoreDetails] = []
        } = {}
    } = order

    const {
        newStore,
        existingStoreId
    } = {...firstStoreDetails}

    const {
        address,
        companyRegistrationNumber: storeCompanyRegistrationNumber = null,
        email: storeEmail = null,
        name: storeName = null,
        telephoneNumber: storeTelephoneNumber = null,
        vatNumber: storeVATNumber = null
    } = newStore || {}

    const {
        countryCode: storeCountry = null,
        county: storeCounty = null,
        line1: storeAddressLine1 = null,
        line2: storeAddressLine2 = null,
        line3: storeAddressLine3 = null,
        postcode: storePostcode = null,
        townOrCity: storeTown = null
    } = address || {}

    return {
        storeAddressLine1,
        storeAddressLine2,
        storeAddressLine3,
        storeCompanyRegistrationNumber,
        storeTelephoneNumber,
        storePostcode,
        storeCountry,
        storeCounty,
        storeEmail,
        storeName,
        storeTown,
        storeCountryName: '',
        storeId: existingStoreId?.toString() || uuid(),
        storeVATNumber,
        useAxeptConnectCloud: newStore.useAxeptConnectCloud
    }
}

export function place123SendMerchantRequestToAddDepartment(order: OTTSendPlaceOrderRequest): AddDepartmentFormData {
    const {
        merchant: {
            stores: [firstStoreDetails] = []
        } = {}
    } = order

    const { departments: [firstDepartmentDetails] = [] } = firstStoreDetails

    const {
        newDepartment: {

        } = {}
    } = {...firstDepartmentDetails}

    return {
        departmentAccountVerification: false,
        departmentAcquirer: 0,
        departmentAcquirerAssignedTIDs: [],
        departmentAcquirerName: '',
        departmentAmexAcquirer: 0,
        departmentAmexAcquirerName: '',
        departmentAmexBankAccountNumber: '',
        departmentAmexMerchantId: '',
        departmentAmexSortCode: '',
        departmentBankAccountNumber: '',
        departmentCashAdvance: false,
        departmentCashBack: 0,
        departmentCurrency: 0,
        departmentCurrencyName: '',
        departmentDinersDiscoverAcquirer: 0,
        departmentDinersDiscoverAcquirerName: '',
        departmentDinersDiscoverBankAccountNumber: '',
        departmentDinersDiscoverMerchantId: '',
        departmentDinersDiscoverSortCode: '',
        departmentEnableCashBack: false,
        departmentEnableGratuity: false,
        departmentEnablePurchase: false,
        departmentEnableRefund: false,
        departmentFlexecashAcquirer: 0,
        departmentFlexecashAcquirerName: '',
        departmentFlexecashBankAccountNumber: '',
        departmentFlexecashMerchantId: '',
        departmentFlexecashSortCode: '',
        departmentGratuity: 0,
        departmentId: '',
        departmentMaestroDebitMID: '',
        departmentMastercardDebitMID: '',
        departmentMastercardMID: '',
        departmentMerchantCategoryCode: 0,
        departmentMerchantCategoryCodeLabel: '',
        departmentMoto: false,
        departmentName: '',
        departmentPreAuth: false,
        departmentPurchase: 0,
        departmentRefund: 0,
        departmentSortCode: '',
        departmentStreamlineCompanyId: '',
        departmentType: undefined,
        departmentUnionPayAcquirer: 0,
        departmentUnionPayAcquirerName: '',
        departmentUnionPayBankAccountNumber: '',
        departmentUnionPayMerchantId: '',
        departmentUnionPaySortCode: '',
        departmentVisaDebitMID: '',
        departmentVisaMID: '',
        storeId: ''

    }
}

export function place123SendMerchantRequestToStoresAndDepartments(order: OTTSendPlaceOrderRequest): SDStore[] {
    const { merchant } = order

    const stores: SDStore[] = merchant?.stores?.map(store => {
        const { newStore, departments } = store || {}
        const { address } = newStore || {}

        return {
            id: store.id,
            name: newStore?.name,
            companyRegistrationNumber: newStore?.companyRegistrationNumber,
            telephoneNumber: newStore?.telephoneNumber,
            vatNumber: newStore?.vatNumber,
            country: address?.countryCode,
            addressLine1: address?.line1,
            addressLine2: address?.line2,
            addressLine3: address?.line3,
            postcode: address?.postcode,
            town: address?.townOrCity,
            county: address?.county,
            email: newStore?.email,
            countryName: '',
            isNew: Boolean(newStore),
            departments: placePartnerDepartmentsToSDDepartments(departments),
            isLoading: false,
            useAxeptConnectCloud: newStore.useAxeptConnectCloud
        }
    })

    return [
        ...stores
    ]
}

export function place123SendMerchantRequestToProductList(order: OTTSendPlaceOrderRequest): Product[] {
    const { merchant } = order || {}
    const { stores } = merchant || {}

    return stores && stores.reduce((prevValue, currValue) => {
        const currProducts = currValue?.departments?.reduce((acc, department) => {
            const bundles = getProductBundles(department)

            return [{departmentId: department.id, storeId: department.storeId, bundles}, ...acc]
        }, [])

        return [...currProducts, ...prevValue]
    }, [])
}

export function place123SendMerchantRequestToOrderDetails(order: OTTSendPlaceOrderRequest): OTTOrderDetailsFormData {
    const { poNumber, requestedDeliveryAndOrActivationDate, orderReference, containsAcquirerRevenue, dealId, orderTypeId,
        partnerDetails, upgradeDetails
    } = order || {}

    const { partnerCompanyDetailsId, agentName } = partnerDetails || {}

    return {
        containsAcquirerRevenue,
        dealId,
        orderType: orderTypeId,
        partnerCompany: partnerCompanyDetailsId,
        partnerCompanyAgentName: agentName,
        poNumber,
        requestedDeliveryAndOrActivationDate,
        uniqueOrderReference: orderReference,
        upgradeDetails: upgradeDetails?.map(detail => ({...detail, id: detail.tid}))
    }
}

export function place123SendMerchantRequestToDeliveryDetails(order: OTTSendPlaceOrderRequest): DeliveryDetailsFormData {
    const { merchant } = order
    const { deliveryDetails } = merchant || {}
    const { deliveryInstructions, deliverToMerchant, deliverToStore, newHardwareAddress } = deliveryDetails || {}
    const { contact, address } = newHardwareAddress || {}
    const { jobTitle, firstName, surname, telephoneNumber, mobileNumber, email } = contact || {}
    const { deliveryName, tradingAs, line1, line2, townOrCity, county, countryCode, postcode } = address || {}

    const deliverTo = getDeliveryTo({ deliverToMerchant, deliverToStore })

    return {
        addressLine1: line1,
        addressLine2: line2,
        contactMobilePhone: mobileNumber,
        contactName: firstName,
        contactPhoneNumber: telephoneNumber,
        contactSurname: surname,
        country: countryCode,
        county,
        deliverTo,
        deliveryInstructions,
        deliveryName,
        email,
        jobTitle,
        postcode,
        storeDeliveryInstructions: [],
        town: townOrCity,
        tradingAs
    }
}
