import React from 'react'
import translations from './translations'

translations.add('en', {
    title: 'Log in using your OCC account',
    email: 'email',
    emailRequired: 'email is required',
    invalidEmail: 'invalid email',
    password: 'password',
    passwordRequired: 'password is required',
    forgotPassword: 'Forgot password?',
    signIn: 'Sign in',
    orderPortal: 'Order Portal',
    odin: 'ODIN'
})
