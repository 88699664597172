import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form } from 'antd'
import { validatePostalCodeOfUK } from 'dna-common'
import { getFieldValue } from '~/code/services'
import { DeliveryInstructions, Checkbox, Input, PostCode, CountrySelect } from '~/code/common/components'
import { VALID_TEXT_PATTERN, VALID_NAME_PATTERN } from '~/code/models/Patterns'
import { searchAddressesByPostcode } from '../../../services/fetchers'
import { ROI_ID, UK_ID } from '~/code/models/Constants'
import translations from './translations'
import { DeliveryAddressFormProps } from './props'
import styles from './DeliveryAddressForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'

export const DeliveryAddressForm = observer(({ store }: DeliveryAddressFormProps) => {
    const { deliveryAddressFormData: data, setDeliveryAddressFormData, setUseMerchantAddress, countries, configCountry, canGoBack, goBack } = store
    const [form] = Form.useForm()

    const onFinish = (values) => {
        setDeliveryAddressFormData(values)
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const onUseMerchantAddressChange = (value) => {
        setUseMerchantAddress(value)
        form.resetFields([
            'addressLine1', 'addressLine2', 'town', 'county', 'country', 'postcode'
        ])
        form.setFieldsValue({
            addressLine1: getFieldValue(data, 'addressLine1'),
            addressLine2: getFieldValue(data, 'addressLine2'),
            town: getFieldValue(data, 'town'),
            county: getFieldValue(data, 'county'),
            country: getFieldValue(data, 'country') || defaultCountryCode(),
            postcode: getFieldValue(data, 'postcode')
        })
    }

    const isAddressFieldRequired = () => {
        return !data.useMerchantAddress
    }

    const defaultCountryCode = () => {
        if (configCountry === 'roi') {
            return ROI_ID
        }
        return UK_ID
    }

    const isUK = () => {
        return form.getFieldValue('country') === UK_ID
    }

    const postcodeInput = (isDisabled) => {
        if (isDisabled) {
            return <Input placeholder={translations().postcode} disabled={true}/>
        }

        return <PostCode
            isUK={ isUK() }
            getPostCodeAddresses={ (postCode: string) => searchAddressesByPostcode(postCode) }
            onAddressSelect={ (a) => {
                form.setFieldsValue({
                    addressLine1: a.line_1,
                    addressLine2: a.line_2,
                    addressLine3: a.line_3,
                    town: a.town_or_city,
                    county: a.county
                })
            } }
            disabled={isDisabled}
            placeholder={translations().postcode}
        />
    }

    return (
        <div className={styles.DeliveryAddressForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <Form.Item>
                                <Checkbox onChange={(e) => onUseMerchantAddressChange(e.target.checked)} checked={data.useMerchantAddress}>
                                    {translations().useMerchantAddress}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <CountrySelect
                            form={form}
                            initialValue={getFieldInitialValue('country')}
                            fieldName={'country'}
                            userCountry={configCountry}
                            countries={countries.slice()}
                            disabled={data.useMerchantAddress}
                            required={data.useMerchantAddress}
                        />
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <Form.Item shouldUpdate noStyle>
                                {() => {
                                    return (
                                        <Form.Item
                                            label={translations().postcode}
                                            name={'postcode'}
                                            initialValue={getFieldInitialValue('postcode')}
                                            rules={
                                                [
                                                    {
                                                        required: isAddressFieldRequired(),
                                                        message: translations().postcodeRequired
                                                    },
                                                    {
                                                        validator: async (rule, value) => {
                                                            if (value && isUK() && !validatePostalCodeOfUK(value)) {
                                                                throw new Error(translations().postcodeInvalid)
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        >
                                            {postcodeInput(data.useMerchantAddress)}
                                        </Form.Item>
                                    )}
                                }
                            </Form.Item>
                        </div>
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <Form.Item
                                label={translations().addressLine1}
                                name={'addressLine1'}
                                initialValue={getFieldInitialValue('addressLine1')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine1')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: isAddressFieldRequired(),
                                        message: translations().addressLine1Required
                                    }
                                ]}
                            >
                                <Input placeholder={translations().addressLine1} disabled={data.useMerchantAddress}/>
                            </Form.Item>
                        </div>
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <Form.Item
                                label={translations().addressLine2}
                                name={'addressLine2'}
                                initialValue={getFieldInitialValue('addressLine2')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('addressLine2')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                            >
                                <Input placeholder={translations().addressLine2} disabled={data.useMerchantAddress}/>
                            </Form.Item>
                        </div>
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <Form.Item
                                label={translations().town}
                                name={'town'}
                                initialValue={getFieldInitialValue('town')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_TEXT_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('town')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: isAddressFieldRequired(),
                                        message: translations().townRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().town} disabled={data.useMerchantAddress}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <Form.Item
                                label={translations().recipientName}
                                name={'recipientName'}
                                initialValue={getFieldInitialValue('recipientName')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('recipientName')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                            >
                                <Input placeholder={translations().recipientName}/>
                            </Form.Item>
                        </div>
                        <div className={styles.DeliveryAddressForm_inputContainer}>
                            <DeliveryInstructions id={'deliveryInstructions'}
                                initialValue={getFieldInitialValue('deliveryInstructions')}
                                rows={10}
                                form={form}/>
                        </div>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack} />
            </Form>
        </div>
    )
})
