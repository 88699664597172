import React, { useState } from 'react'
import { Col, Row, Form } from 'antd'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { isEmpty } from 'dna-common'
import { Input, Select } from '~/code/common/components'
import { BANK_ACCOUNT_NUMBER_PATTERN, BANK_ACCOUNT_NUMBER_TYPING_PATTERN, SORT_CODE_PATTERN, SORT_CODE_TYPING_PATTERN } from '~/code/models/Patterns'
import { getValidMerchantIdValue, validateVisaMID } from '../../../../../../../../../../services/merchant-id-validator'
import translations from './translations'
import { AcquirerDetailsProps } from './props'
import styles from './AcquirerDetails.scss'

export const AcquirerDetails = observer((props: AcquirerDetailsProps) => {
    const { form, store, merchantIdField, bankAccountNumberField, sortCodeField, acquirerField,
        isAcquirerSelectionDisabled, departmentBankAccountNumber, departmentSortCode } = props
    const data = store.addDepartmentFormData
    const [isAcquirerDataRequired, setAcquirerDataRequired] = useState(Boolean(form.getFieldValue(merchantIdField) || (data[merchantIdField])))
    const [merchantIdHelp, setMerchantIdHelp] = useState(validateVisaMID(data[merchantIdField], data[acquirerField]))
    const [merchantIdValidateStatus, setMerchantIdValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(merchantIdHelp) ? undefined : 'warning')

    const onMerchantIdChange = (event) => {
        const value = getValidMerchantIdValue(event.target.value, form.getFieldValue(merchantIdField))
        setAcquirerDataRequired(!!value)
        if (!value) {
            form.resetFields([bankAccountNumberField, sortCodeField, acquirerField])
            form.setFieldsValue({
                [bankAccountNumberField]: '',
                [sortCodeField]: ''
            })
        } else {
            form.setFieldsValue({
                [bankAccountNumberField]: departmentBankAccountNumber,
                [sortCodeField]: departmentSortCode
            })
        }
    }

    const setMerchantIdStatus = (merchantId: string) => {
        const warning = validateVisaMID(merchantId, props.form.getFieldValue(acquirerField))
        setMerchantIdHelp(warning)
        setMerchantIdValidateStatus(isEmpty(warning) ? undefined : 'warning')
    }

    return (
        <div className={styles.AcquirerDetails}>
            <div className={styles.groupHeader}>{ props.groupTitle }</div>
            <div className={styles.groupContent}>
                <Form.Item label={translations().merchantId}
                           validateStatus={merchantIdValidateStatus}
                           help={merchantIdHelp}
                           className={classNames({[styles.lessMargin]: !isAcquirerDataRequired})}
                           name={merchantIdField}
                           getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                               const value = getValidMerchantIdValue(e.currentTarget.value, form.getFieldValue(merchantIdField))
                               setMerchantIdStatus(value)
                               return value
                           }}
                           rules={[
                               {
                                   required: false,
                                   message: translations().merchantIdRequired
                               }
                           ]}
                >
                    <Input placeholder={translations().merchantId} onChange={onMerchantIdChange}/>
                </Form.Item>
                <div className={classNames(styles.hideableContent, {[styles.hideableContent__noDisplay]: !isAcquirerDataRequired})}>
                    <Form.Item
                        label={translations().acquirer}
                        name={acquirerField}
                        rules={[
                            {
                                required: isAcquirerDataRequired,
                                message: translations().acquirerRequired
                            }
                        ]}
                    >
                        <Select placeholder={translations().acquirer}
                                   showSearch={true}
                                   filterOption={(input, option) => {
                                       return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                   }}
                                   selectItems={store.acquirers.slice()}
                                   disabled={!isAcquirerDataRequired || isAcquirerSelectionDisabled}
                                   onSelect={() => { setMerchantIdStatus(form.getFieldValue(merchantIdField)) }}
                        />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col xs={15}>
                            <Form.Item
                                label={translations().accountNumber}
                                className={styles.lessMargin}
                                name={bankAccountNumberField}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = BANK_ACCOUNT_NUMBER_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return props.form.getFieldValue(bankAccountNumberField)
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: isAcquirerDataRequired,
                                        message: translations().accountNumberRequired
                                    },
                                    {
                                        pattern: BANK_ACCOUNT_NUMBER_PATTERN,
                                        message: translations().accountNumberInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().accountNumber} disabled={!isAcquirerDataRequired}/>
                            </Form.Item>
                        </Col>
                        <Col xs={9}>
                            <Form.Item
                                label={translations().sortCode}
                                className={styles.lessMargin}
                                name={sortCodeField}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = SORT_CODE_TYPING_PATTERN
                                    const value = e.currentTarget.value.replace(/-/g, '')
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return props.form.getFieldValue(sortCodeField)
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: isAcquirerDataRequired,
                                        message: translations().sortCodeRequired
                                    },
                                    {
                                        pattern: SORT_CODE_PATTERN,
                                        message: translations().sortCodeInvalid
                                    }
                                ]}
                            >
                                <Input  type={'masked'} className={'ant-input'} mask={'99-99-99'} placeholder={translations().sortCode} disabled={!isAcquirerDataRequired}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
})
