import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Radio, Form } from 'antd'
import { RadioChangeEvent } from 'antd/es/radio'
import { validatePostalCodeOfUK } from 'dna-common'
import { getFieldValue } from '~/code/services'
import { Checkbox, DeliveryInstructions, Input, PostCode, CountrySelect } from '~/code/common/components'
import { VALID_TEXT_PATTERN, VALID_NAME_PATTERN, VALID_NAME_WITHOUT_SPACE_PATTERN, PHONE_NUMBER_TYPING_PATTERN, PHONE_NUMBER_PATTERN } from '~/code/models/Patterns'
import { searchAddressesByPostcode } from '~/code/POS/pages/NewOrder/services/fetchers'
import { ROI_ID, UK_ID } from '~/code/models/Constants'
import styles from './DeliveryDetailsForm.scss'
import { DELIVER_TO_MERCHANT, DELIVER_TO_NEW_ADDRESS, DELIVER_TO_STORE } from '~/code/POS/pages/NewOrder/components/partner/DeliveryDetailsForm/models/DeliverToType'
import translations from './translations'
import { DeliveryDetailsFormProps } from './props'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'

export const DeliveryDetailsForm = observer(({store}: DeliveryDetailsFormProps) => {
    const { deliveryDetailsFormData: data, merchantType, configCountry, countries, canGoBack, goBack } = store
    const [form] = Form.useForm()

    const onFinish = (values) => {
        store.setDeliveryDetailsFormData(values)
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const defaultCountryCode = () => {
        if (configCountry === 'roi') {
            return ROI_ID
        }
        return UK_ID
    }

    const [deliverTo, setDeliverTo] = useState(getFieldInitialValue('deliverTo', DELIVER_TO_STORE))

    const onDeliverToChange = (event: RadioChangeEvent) => {
        setDeliverTo(event.target.value)
        form.resetFields([
            'country', 'postcode', 'addressLine1', 'addressLine2', 'town', 'county',
            'contactName', 'contactSurname', 'email', 'contactPhoneNumber', 'contactMobilePhone'
        ])
        form.setFieldsValue({
            country: getFieldValue(data, 'country') || defaultCountryCode(),
            postcode: getFieldValue(data, 'postcode'),
            addressLine1: getFieldValue(data, 'addressLine1'),
            addressLine2: getFieldValue(data, 'addressLine2'),
            town: getFieldValue(data, 'town'),
            county: getFieldValue(data, 'county'),
            contactName: getFieldValue(data, 'contactName'),
            contactSurname: getFieldValue(data, 'contactSurname'),
            email: getFieldValue(data, 'email'),
            contactPhoneNumber: getFieldValue(data, 'contactPhoneNumber'),
            contactMobilePhone: getFieldValue(data, 'contactMobilePhone')
        })
    }

    const onSetStoreInstructionsChange = (set: boolean, textAreaId: string | (string | number)[], index: number) => {
        data.storeDeliveryInstructions[index].isCustom = set
        if (!set) {
            data.storeDeliveryInstructions[index].deliveryInstructions = ''
            form.resetFields([textAreaId])
        }
    }

    const isAddressFieldRequired = () => {
        return deliverTo === DELIVER_TO_NEW_ADDRESS
    }

    const renderContent = () => {
        switch (deliverTo) {
            case 'deliverToStore': {
                return <>
                    {renderBigInstructions()}
                    {renderDeliverToStoreContent()}
                </>
            }
            case 'deliverToMerchant': {
                return <>
                    { renderBigInstructions() }
                    {/*{ merchantType === 'new' && renderMainContent(true, false) }*/}
                </>
            }
            case 'deliverToNewAddress': {
                return renderMainContent(false, true)
            }
        }

        return null
    }

    const renderDeliverToStoreContent = () => {
        const rows = []
        store.deliveryDetailsFormData.storeDeliveryInstructions.forEach((item, index) => {
            rows.push(
                <Row gutter={16} key={item.storeId}>
                    <Col xs={24} sm={8}>
                        <div>
                            <Form.Item
                                name={['storeDeliveryInstructions', index, 'isCustom']}
                                initialValue={item.isCustom}
                                valuePropName={'checked'}
                            >
                                <Checkbox onChange={(event) => {onSetStoreInstructionsChange(event.target.checked, ['storeDeliveryInstructions', index, 'deliveryInstructions'], index)}}>
                                    {translations().setCustomDeliveryInstructions} {item.storeName}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['storeDeliveryInstructions', index, 'storeId']}
                                initialValue={item.storeId}
                            >
                                <Input style={{'visibility': 'hidden'}}/>
                            </Form.Item>
                            <Form.Item
                                name={['storeDeliveryInstructions', index, 'storeName']}
                                initialValue={item.storeName}
                            >
                                <Input style={{'visibility': 'hidden'}}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} sm={16}>
                        <div>
                            <DeliveryInstructions id={['storeDeliveryInstructions', index, 'deliveryInstructions']}
                                                  initialValue={item.deliveryInstructions}
                                                  autoSize={{ minRows: 4, maxRows: 4 }}
                                                  disabled={!data.storeDeliveryInstructions[index].isCustom}
                                                  form={form}/>
                        </div>
                    </Col>
                </Row>
            )
        })
        return rows
    }

    const renderBigInstructions = () => {
        return <Row justify={'space-between'}>
            <Col xs={24}>
                <div>
                    <DeliveryInstructions id={'deliveryInstructions'}
                                          initialValue={getFieldInitialValue('deliveryInstructions')}
                                          rows={10}
                                          form={form}/>
                </div>
            </Col>
        </Row>
    }

    const renderMainContent = (isDisabled: boolean, showInstructions: boolean) => {
        const postCodeInput = isDisabled ?
            <Input placeholder={translations().postcode} disabled={isDisabled}/> :
            <PostCode
                isUK={ form.getFieldValue('country') === UK_ID }
                getPostCodeAddresses={ (postCode: string) => searchAddressesByPostcode(postCode) }
                onAddressSelect={ (a) => {
                    form.setFieldsValue({
                        addressLine1: a.line_1,
                        addressLine2: a.line_2,
                        town: a.town_or_city,
                        county: a.county
                    })
                } }
                disabled={isDisabled}
                placeholder={translations().postcode}
            />

        return <>
            <div className={styles.subgroupTitle}>
                {translations().address}
            </div>
            <Row justify={'space-between'}>
                <Col xs={24} md={10}>
                    <CountrySelect
                        form={form}
                        initialValue={getFieldInitialValue('country')}
                        fieldName={'country'}
                        userCountry={configCountry}
                        countries={countries}
                        required={isAddressFieldRequired()}
                    />
                    <div>
                        <Form.Item shouldUpdate noStyle>
                            {() => {
                                return (
                                    <Form.Item
                                        label={translations().postcode}
                                        name={'postcode'}
                                        initialValue={getFieldInitialValue('postcode')}
                                        rules={[
                                            {
                                                required: isAddressFieldRequired(),
                                                message: translations().postcodeRequired
                                            },
                                            {
                                                validator: async (rule, value) => {
                                                    const isUK = form.getFieldValue('country') === UK_ID
                                                    if (value && isUK && !validatePostalCodeOfUK(value)) {
                                                        throw new Error(translations().postcodeInvalid)
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        {postCodeInput}
                                    </Form.Item>
                                )}
                            }
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().addressLine1}
                            name={'addressLine1'}
                            initialValue={getFieldInitialValue('addressLine1')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('addressLine1')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: isAddressFieldRequired(),
                                    message: translations().addressLine1Required
                                }
                            ]}
                        >
                            <Input placeholder={translations().addressLine1} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().addressLine2}
                            name={'addressLine2'}
                            initialValue={getFieldInitialValue('addressLine2')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('addressLine2')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                        >
                            <Input placeholder={translations().addressLine2} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().town}
                            name={'town'}
                            initialValue={getFieldInitialValue('town')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('town')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: isAddressFieldRequired(),
                                    message: translations().townRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().town} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().county}
                            name={'county'}
                            initialValue={getFieldInitialValue('county')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('county')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: translations().countyRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().county} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col xs={24} md={10}>
                    <div>
                        <Form.Item
                            label={translations().tradingAs}
                            name={'tradingAs'}
                            initialValue={getFieldInitialValue('tradingAs')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('tradingAs')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: !isDisabled,
                                    message: translations().tradingAsRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().tradingAs} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    { !isDisabled &&
                        <div>
                            <Form.Item
                                label={translations().deliveryName}
                                name={'deliveryName'}
                                initialValue={getFieldInitialValue('deliveryName')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('deliveryName')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: !isDisabled,
                                        message: translations().deliveryNameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().deliveryName} disabled={isDisabled}/>
                            </Form.Item>
                        </div>
                    }
                    {
                        showInstructions &&
                        <div>
                            <DeliveryInstructions id={'deliveryInstructions'}
                                                  initialValue={getFieldInitialValue('deliveryInstructions')}
                                                  rows={10}
                                                  form={form}/>
                        </div>
                    }
                </Col>
            </Row>
            <div className={styles.subgroupTitle}>
                {translations().contact}
            </div>
            <Row justify={'space-between'}>
                <Col xs={24} md={10}>
                    <div>
                        <Form.Item
                            label={translations().contactName}
                            name={'contactName'}
                            initialValue={getFieldInitialValue('contactName')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_WITHOUT_SPACE_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('contactName')
                                }
                                return value
                            }}
                            rules={[
                                {
                                    required: !isDisabled,
                                    message: translations().contactNameRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().contactName} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().contactSurname}
                            name={'contactSurname'}
                            initialValue={getFieldInitialValue('contactSurname')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_NAME_WITHOUT_SPACE_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('contactSurname')
                                }
                                return value
                            }}
                            rules={[
                                {
                                    required: !isDisabled,
                                    message: translations().contactSurnameRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().contactSurname} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().jobTitle}
                            name={'jobTitle'}
                            initialValue={getFieldInitialValue('jobTitle')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const regex = VALID_TEXT_PATTERN
                                const value = e.currentTarget.value
                                const test = regex.test(value)
                                if (!test) {
                                    return form.getFieldValue('jobTitle')
                                }
                                return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                            }}
                            rules={[
                                {
                                    required: false,
                                    message: translations().jobTitleRequired
                                }
                            ]}
                        >
                            <Input placeholder={translations().jobTitle} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col xs={24} md={10}>
                    <div>
                        <Form.Item
                            label={translations().email}
                            name={'email'}
                            initialValue={getFieldInitialValue('email')}
                            rules={[
                                {
                                    required: !isDisabled,
                                    message: translations().emailRequired
                                },
                                {
                                    type: 'email',
                                    message: translations().emailInvalid
                                }
                            ]}
                        >
                            <Input textTransform={'lowercase'} placeholder={translations().email} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().contactPhoneNumber}
                            name={'contactPhoneNumber'}
                            initialValue={getFieldInitialValue('contactPhoneNumber')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                const value = e.currentTarget.value
                                const test = phoneRegexp.test(value)
                                if (!test) {
                                    return form.getFieldValue('contactPhoneNumber')
                                }
                                return value
                            }}
                            rules={[
                                {
                                    required: !isDisabled,
                                    message: translations().contactPhoneNumberRequired
                                },
                                {
                                    pattern: PHONE_NUMBER_PATTERN,
                                    message: translations().contactPhoneNumberInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().contactPhoneNumber} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={translations().contactMobilePhone}
                            name={'contactMobilePhone'}
                            initialValue={getFieldInitialValue('contactMobilePhone')}
                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                const phoneRegexp = PHONE_NUMBER_TYPING_PATTERN
                                const value = e.currentTarget.value
                                const test = phoneRegexp.test(value)
                                if (!test) {
                                    return form.getFieldValue('contactMobilePhone')
                                }
                                return value
                            }}
                            rules={[
                                {
                                    required: !isDisabled,
                                    message: translations().contactMobilePhoneRequired
                                },
                                {
                                    pattern: PHONE_NUMBER_PATTERN,
                                    message: translations().contactMobilePhoneInvalid
                                }
                            ]}
                        >
                            <Input placeholder={translations().contactMobilePhone} disabled={isDisabled}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </>
    }

    return (
        <div className={styles.DeliveryDetailsForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            name={'deliverTo'}
                            initialValue={store.isDeliverToRadioDisabled ? 'deliverToMerchant' : deliverTo}
                        >
                            <Radio.Group onChange={onDeliverToChange} disabled={store.isDeliverToRadioDisabled}>
                                <Radio value={DELIVER_TO_STORE}>{translations().deliverToStore}</Radio>
                                <Radio value={DELIVER_TO_MERCHANT}>{translations().deliverToMerchant}</Radio>
                                <Radio value={DELIVER_TO_NEW_ADDRESS}>{translations().deliverToNewAddress}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                { renderContent() }

                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
