import React, { LegacyRef, createRef, useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Button, IconButton, Input, ListActions } from '~/code/common/components'
import { Col, Form, List, Radio, Row, Skeleton, Space, Typography } from 'antd'
import { DownloadOutlined, FileOutlined, PoweroffOutlined, CloudUploadOutlined } from '@ant-design/icons'
import translations from './translations'
import { IManagementActionsProps } from './props'
import styles from './ManagementActions.scss'

const {Title} = Typography

export const ManagementActions = observer((props: IManagementActionsProps) => {
    const { store, deviceId, deviceDetails } = props
    const [logId, setLogId] = useState<string>()
    const [captchaValue, setCaptchaValue] = useState<string>()
    const linkRef = createRef() as LegacyRef<any>
    const captcha = deviceDetails?.passCode?.split('-')[deviceDetails?.verificationDataIndex]

    const onRestartFormFinish = (values) => {
        const formattedValues = {priorityId: 1, verificationDataIndex: deviceDetails.verificationDataIndex, ...values}
        store.restartDevice(deviceId, formattedValues)
        setCaptchaValue(null)
    }

    const onLogFormFinish = (values) => {
        const formattedValues = {priorityId: 1, verificationDataIndex: deviceDetails.verificationDataIndex, ...values}
        store.createDeviceLog(deviceId, formattedValues)
        setCaptchaValue(null)
    }

    const onLogRadioChange = (e) => {
        setLogId(e.target.value)
    }

    const downloadLog = (deviceLogId: string) => {
        store.downloadDeviceLog(deviceId, deviceLogId)
    }

    const onLogButtonLoadClick = () => {
        store.loadDeviceLogs(deviceId)
        store.setIsRestartCaptchaVisible(false)
        store.setIsRetrieveLogCaptchaVisible(true)
    }

    const onRestartButtonClick = () => {
        store.restartHistoryStore.loadRestartHistory(deviceId)
        store.setIsRestartCaptchaVisible(true)
        store.setIsRetrieveLogCaptchaVisible(false)
    }

    const renderLogsRadioGroup = () => {
        return (
            <>
                { store.isLogsLoading && <Skeleton active /> }

                { store.logs.length
                    ? (
                        <>
                            <div className={styles.logsHeader}>
                                <Typography.Title level={5}>{translations().availableLogsFromLast2Weeks}</Typography.Title>
                            </div>
                            <ListActions
                                pageInfo={{
                                    from: store.pageNumber * store.pageSize - 10,
                                    to: store.pageNumber * store.pageSize > store.totalCount
                                        ? store.totalCount
                                        : store.pageNumber * store.pageSize,
                                    total: store.totalCount
                                }}
                                className={styles.listActions}
                                currentTotalAmount={store.logs.length}
                                onReloadClick={() => store.clearAndLoadDeviceLogs(deviceId)}
                                onGoNextPageClick={() => store.loadDeviceLogs(deviceId, store.pageNumber + 1)}
                                onGoPrevPageClick={() => store.loadDeviceLogs(deviceId, store.pageNumber - 1)}
                                isNextPageDisabled={store.pageNumber >= store.totalPageItems}
                                isPrevPageDisabled={store.pageNumber <= 1}
                                onGoFirstPageClick={() => store.loadDeviceLogs(deviceId, 1)}
                                onGoLastPageClick={() => store.loadDeviceLogs(deviceId, store.totalPageItems)}
                                isGoToFirstPageDisabled={store.pageNumber <= 1}
                                isGoToLastPageDisabled={store.pageNumber >= store.totalPageItems}
                                isFilterDisabled={true}
                            />
                        </>
                    )
                    : null }
                <Radio.Group onChange={onLogRadioChange} value={logId}>
                    <Space direction="vertical">
                        {
                            store.logs.map(log => (
                                <Space key={log.deviceLogId} direction="horizontal">
                                    <Radio value={log.deviceLogId}>{log.displayName}</Radio>
                                    { store.permissions?.includes('odin.deviceManagement.device.logs.download') && log.deviceLogId === logId &&
                                        <>
                                            <IconButton
                                                icon={<DownloadOutlined/>}
                                                className={styles.download}
                                                onClick={() => downloadLog(log.deviceLogId)}
                                            />
                                            <a ref={linkRef}/>
                                        </>
                                    }
                                </Space>
                            ))
                        }
                    </Space>
                </Radio.Group>
            </>
        )
    }

    const renderRestartCaptcha = () => (
        <Form
            id="captchaForm"
            name="captchaForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={(values) => onRestartFormFinish(values)}
            autoComplete="off"
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <p className={styles.captcha}>{captcha}</p>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <p>{translations().pleaseEnterCaptcha}</p>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name="verificationToken"
                        rules={[
                            {required: true, message: translations().captchaIsRequired},
                            {
                                validator: async (rule, value) => {
                                    if (value && captchaValue !== captcha) {
                                        throw new Error(translations().invalidCode)
                                    }
                                }
                            }
                        ]}
                    >
                        <Input
                            onPaste={e => {
                                e.preventDefault()
                                return false
                            }}
                            onChange={(e) => setCaptchaValue(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Button
                        className={styles.restartButton}
                        key="submit"
                        htmlType="submit"
                        form="captchaForm"
                        loading={store.isLoading}
                        disabled={captchaValue !== captcha}
                    >
                        <CloudUploadOutlined />
                        {translations().restartDevice}
                    </Button>
                </Col>
            </Row>
        </Form>
    )

    const renderLogCaptcha = () => (
        <Form
            id="captchaForm"
            name="captchaForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={(values) => onLogFormFinish(values)}
            autoComplete="off"
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <p className={styles.captcha}>{captcha}</p>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <p>{translations().pleaseEnterCaptchaForLog}</p>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        name="verificationToken"
                        rules={[
                            {required: true, message: translations().captchaIsRequired},
                            {
                                validator: async (rule, value) => {
                                    if (value && value !== captcha) {
                                        throw new Error(translations().invalidCode)
                                    }
                                }
                            }
                        ]}
                    >
                        <Input
                            onPaste={e => {
                                e.preventDefault()
                                return false
                            }}
                            onChange={(e) => setCaptchaValue(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Button
                        className={styles.restartButton}
                        key="submit"
                        htmlType="submit"
                        form="captchaForm"
                        loading={store.isCreateLogLoading}
                        disabled={captchaValue !== captcha}
                    >
                        <FileOutlined />
                        {translations().retrieveLog}
                    </Button>
                </Col>
            </Row>
        </Form>
    )

    return (
        <>

            <Button
                type={'default'}
                onClick={() => onRestartButtonClick()}
                className={styles.restartButton}
                disabled={!store.permissions?.includes('odin.deviceManagement.device.restart.request')}
            >
                <PoweroffOutlined />
                {translations().restartDevice}
            </Button>

            {store.permissions?.includes('odin.deviceManagement.device.logs.request') && (
                <Button onClick={() => onLogButtonLoadClick()}>
                    <FileOutlined />
                    {translations().retrieveLog}
                </Button>
            )}

            <br/>
            <br/>

            {store.permissions?.includes('odin.deviceManagement.device.restart.request') && store.isRestartCaptchaVisible && (
                renderRestartCaptcha()
            )}

            {store.isRetrieveLogCaptchaVisible && (
                renderLogCaptcha()
            )}

            {store.restartHistoryStore.restartHistory.length && store.isRestartCaptchaVisible ? (
                <>
                    <Title level={5}>{translations().restartHistory}</Title>
                    <ListActions
                        pageInfo={{
                            from: store.restartHistoryStore.pageNumber * store.restartHistoryStore.pageSize - 5,
                            to: store.restartHistoryStore.pageNumber * store.restartHistoryStore.pageSize > store.restartHistoryStore.totalCount
                                ? store.restartHistoryStore.totalCount
                                : store.restartHistoryStore.pageNumber * store.restartHistoryStore.pageSize,
                            total: store.restartHistoryStore.totalCount
                        }}
                        className={styles.listActions}
                        currentTotalAmount={store.restartHistoryStore.restartHistory.length}
                        onReloadClick={() => store.restartHistoryStore.loadRestartHistory(deviceId)}
                        onGoNextPageClick={() => store.restartHistoryStore.loadRestartHistory(deviceId, store.restartHistoryStore.pageNumber + 1)}
                        onGoPrevPageClick={() => store.restartHistoryStore.loadRestartHistory(deviceId, store.restartHistoryStore.pageNumber - 1)}
                        isNextPageDisabled={store.restartHistoryStore.pageNumber >= store.restartHistoryStore.totalPageItems}
                        isPrevPageDisabled={store.restartHistoryStore.pageNumber <= 1}
                    />
                    <List
                        itemLayout="horizontal"
                        loading={store.restartHistoryStore.isLoading}
                        dataSource={store.restartHistoryStore.restartHistory}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={moment(item.startupDateTime).format('DD/MM/YYYY HH:mm:ss')}
                                />
                            </List.Item>
                        )}
                    />
                </>
            ) : null}

            <br/>
            <br/>

            {store.permissions?.includes('odin.deviceManagement.device.logs.view') && store.isRetrieveLogCaptchaVisible && renderLogsRadioGroup()}
        </>
    )
})
