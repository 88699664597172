import React from 'react'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { message, notification } from 'antd'
import { error, isEmpty } from 'dna-common'
import { setLauncherApplication, uninstallAppById, installApp, fetchAvailablePackages, fetchAvailablePackageVersions } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { IApplicationFirmwareManagementStore } from '~/code/ODIN/pages/DeviceManagementDetails/IApplicationFirmwareManagementStore'
import { DetailsForPerformingInstallation } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DetailsForPerformingInstallation'
import translations from '~/code/ODIN/pages/DeviceManagementDetails/translations'
import { AvailablePackage } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AvailablePackage'
import { AppVersion } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AppVersion'
import { SMALL_PAGE_SIZE } from '~/code/models'
import { AvailablePackageFilter } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AvailablePackageFilter'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { IDeviceManagementDetailsStore } from '~/code/ODIN/pages/DeviceManagementDetails/IDeviceManagementDetailsStore'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import commonTranslations from '~/code/translations/translations'

export class ApplicationFirmwareManagementStore implements IApplicationFirmwareManagementStore {
    constructor (parentStore: IDeviceManagementDetailsStore) {
        makeObservable(this, {
            isLoading: observable,
            availablePackages: observable,
            isUpdateAppToLatestVersionModalOpen: observable,
            isUpdateFirmwareToLatestVersionModalOpen: observable,
            isAppUninstallModalOpen: observable,
            isSetAsLauncherModalOpen: observable,
            isPushNewAppsModalOpen: observable,
            pageNumber: observable,
            pageSize: observable,
            totalPageItems: observable,
            totalCount: observable,
            availablePackageName: observable,
            availablePackageVersions: observable,
            packageId: observable,
            isVersionLoading: observable,
            appVersions: computed,
            uninstallApp: action,
            installApp: action,
            updateApp: action,
            loadAvailablePackages: action,
            loadAvailablePackageVersions: action,
            setAsLauncher: action,
            setAvailablePackages: action,
            setIsUpdateAppToLatestVersionModalOpen: action,
            setIsUpdateFirmwareToLatestVersionModalOpen: action,
            setIsAppUninstallModalOpen: action,
            setIsSetAsLauncherModalOpen: action,
            setIsPushNewAppsModalOpen: action,
            setPageNumber: action,
            setPageSize: action,
            setAvailablePackageName: action,
            setPackageId: action,
            setAvailablePackageVersions: action
        })

        this.parentStore = parentStore

        reaction(() => this.packageId, () => {
            this.loadAvailablePackageVersions()
        })
    }

    private parentStore: IDeviceManagementDetailsStore
    public availablePackages: AvailablePackage[] = []
    public availablePackageVersions: AppVersion[] = []
    public isLoading: boolean = false
    public isVersionLoading: boolean = false
    public isUpdateAppToLatestVersionModalOpen: boolean = false
    public isUpdateFirmwareToLatestVersionModalOpen: boolean = false
    public isAppUninstallModalOpen: boolean = false
    public isSetAsLauncherModalOpen: boolean = false
    public isPushNewAppsModalOpen: boolean = false
    public pageNumber: number = 0
    public pageSize: number = SMALL_PAGE_SIZE
    public totalPageItems: number
    public totalCount: number
    public availablePackageName: string
    public packageId: number
    public deviceId: number

    public get appVersions (): AppVersion[] {
        return this.availablePackageVersions || []
    }

    public uninstallApp = (deviceId: number, data: DetailsForPerformingInstallation) => {
        uninstallAppById(deviceId, data)
            .then(response => {
                notification.info({
                    message: translations ().uninstallAppRequested,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .catch((err: Error) => {
                notification.error({
                    message: <><span>{err.name}</span><span>{err.message}</span></>,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
    }

    public setAsLauncher = (deviceId: number, data: DetailsForPerformingInstallation) => {
        setLauncherApplication(deviceId, data)
            .then(response => {
                message.info(translations().changeLauncherRequested)
            })
            .catch((err: Error) => {
                message.error(<><span>{err.name}</span><span>{err.message}</span></>, 3)
            })
    }

    public updateApp = (deviceId: number, data: DetailsForPerformingInstallation) => {
        installApp(deviceId, data)
            .then(_ => {
                const versionNumber = this.availablePackageVersions
                    .find(version => version.packageVersionId === data.packageVersionId)?.version

                notification.info({
                    message: translations().updateAppRequested(`(${versionNumber})`)
                })
            })
            .catch((err: Error) => {
                notification.error({
                    message: err.name,
                    description: err.message,
                    duration: 10
                })
            })
    }

    public installApp = (deviceId: number, data: DetailsForPerformingInstallation) => {
        installApp(deviceId, data)
            .then(_ => {
                const versionNumber = this.availablePackageVersions.find(version => version.packageVersionId === data.packageVersionId)?.version

                notification.info({
                    message: translations().installAppRequested(`(${versionNumber})`)
                })
            })
            .catch((err: Error) => {
                notification.error({
                    message: err.name,
                    description: err.message,
                    duration: 10
                })
            })
    }

    public loadAvailablePackages = (deviceId: number, filter: AvailablePackageFilter) => {
        this.isLoading = true
        const filters = {
            ...filter,
            name: this.availablePackageName
        }

        this.pageNumber = isEmpty(filter?.pageNumber) ? 1 : filter?.pageNumber

        fetchAvailablePackages(deviceId, filters)
            .then(response => {
                runInAction(() => {
                    const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                    response?.result?.length > 0 ? this.setAvailablePackages(response?.result) : this.setAvailablePackages([])
                    this.totalCount = pagination.totalCount
                    this.totalPageItems = pagination.totalPages
                    this.pageSize = pagination.pageSize
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    public loadAvailablePackageVersions = (packageId?: number) => {
        this.isVersionLoading = true
        this.setAvailablePackageVersions([])

        const id = packageId != null ? packageId : this.packageId

        fetchAvailablePackageVersions(this.parentStore.deviceId, id)
            .then(response => {
                runInAction(() => {
                    response?.result?.length > 0
                        ? this.setAvailablePackageVersions(response?.result)
                        : this.setAvailablePackageVersions([])
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isVersionLoading = false
                })
            })
    }

    public setAvailablePackages = (availablePackages: AvailablePackage[]) => {
        this.availablePackages = availablePackages
    }

    public setAvailablePackageVersions = (availablePackageVersions: AppVersion[]) => {
        this.availablePackageVersions = availablePackageVersions
    }

    public setIsUpdateAppToLatestVersionModalOpen = (isUpdateAppToLatestVersionModalOpen: boolean) => {
        this.isUpdateAppToLatestVersionModalOpen = isUpdateAppToLatestVersionModalOpen
    }

    public setIsUpdateFirmwareToLatestVersionModalOpen = (isUpdateFirmwareToLatestVersionModalOpen: boolean) => {
        this.isUpdateFirmwareToLatestVersionModalOpen = isUpdateFirmwareToLatestVersionModalOpen
    }

    public setIsAppUninstallModalOpen = (isAppUninstallModalOpen: boolean) => {
        this.isAppUninstallModalOpen = isAppUninstallModalOpen
    }

    public setIsSetAsLauncherModalOpen = (isSetAsLauncherModalOpen: boolean) => {
        this.isSetAsLauncherModalOpen = isSetAsLauncherModalOpen
    }

    public setIsPushNewAppsModalOpen = (isPushNewAppsModalOpen: boolean) => {
        this.isPushNewAppsModalOpen = isPushNewAppsModalOpen
    }

    public setPageNumber = (page: number) => {
        this.pageNumber = page
    }

    public setPageSize = (size: number) => {
        this.pageSize = size
    }

    public setAvailablePackageName = (availablePackageName: string) => {
        this.availablePackageName = availablePackageName
    }

    public setPackageId = (packageId: number) => {
        this.packageId = packageId
    }
}
