import React, { forwardRef } from 'react'
import { Select as AntSelect } from 'antd'
import { isEmpty } from 'dna-common'
import { AsyncSelectProps } from '~/code/common/components/AsyncSelect/props'
import { Select, SelectItem } from '../Select'

const {Option} = AntSelect

export const AsyncSelect = forwardRef<any, AsyncSelectProps<any>>((props, forwardedRef) => {
    const { loading, selectItems, loadMore, currentPage, totalPages, children, ...rest } = props

    const onScroll = (event) => {
        const target = event.target
        if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (currentPage < totalPages ) {
                loadMore()
                target.scrollTo(0, target.scrollHeight)
            }
        }
    }

    const renderOptions = (options: SelectItem[]) => {
        return isEmpty(options) ? [] :
            options.map((option) => (
                <Option
                    value={ option.value }
                    title={ option.label }
                    key={ option.value }
                >{ option.label }</Option>
            ))
    }

    const renderChildren = () => (
        children
            ? (!loading ? children : [children, <Option value={null} key="loading">Loading...</Option>])
            : children
    )

    const renderSelectItems = () => (
        !loading
            ? renderOptions(selectItems)
            : [...renderOptions(selectItems), <Option value={null} key="loading">Loading...</Option>]
    )

    return <Select
        {...rest}
        onPopupScroll={onScroll}
        loading={loading}
    >
        {renderChildren() || renderSelectItems()}
    </Select>
})
