import { IDeliveryAddressFormStore } from '~/code/POS/pages/NewOrder/components/iso/DeliveryAddressForm/IDeliveryAddressFormStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import { AppStore } from '~/code/AppStore'
import { createDeliveryAddressFormData, DeliveryAddressFormData } from '~/code/POS/pages'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { ConfigCountry } from '~/code/POS/pages/NewOrder/models/constants'

export class DeliveryAddressStore implements IDeliveryAddressFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            deliveryAddressFormData: observable,
            canGoBack: observable,
            countries: computed,
            configCountry: computed,
            setDeliveryAddressFormData: action,
            setUseMerchantAddress: action
        })
        this.deliveryAddressFormData = createDeliveryAddressFormData()
    }

    static stepName: string = 'deliveryAddress'

    deliveryAddressFormData: DeliveryAddressFormData

    canGoBack: boolean = true

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get configCountry(): ConfigCountry {
        return AppStore.newOrderStore?.placeOrderConfigStore.country
    }

    setDeliveryAddressFormData = (data: DeliveryAddressFormData) => {
        this.deliveryAddressFormData = {...this.deliveryAddressFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(data, DeliveryAddressStore.stepName)
    }

    setUseMerchantAddress = (use: boolean) => {
        this.deliveryAddressFormData.useMerchantAddress = use
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(DeliveryAddressStore.stepName)
    }

}
