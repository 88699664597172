import { getCurrencyOfCountry } from '~/code/services'
import { IStoresAndDepartmentsFormStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/IStoresAndDepartmentsFormStore'
import { IAddDepartmentFormStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/models/IAddDepartmentFormStore'
import { createAddDepartmentFormData, createAddStoreFormData, IAddStoreFormStore, SDDepartment, sdDepartmentToAddDepartmentFormData, SDStore, sdStoreToAddStoreFormData } from '~/code/POS/pages'
import { action, makeObservable, observable, reaction } from 'mobx'
import { AddStoreFormStore } from '~/code/stores/NewOrderStore/common/AddStoreFormStore'
import { AddDepartmentFormStore } from '~/code/stores/NewOrderStore/common/AddDepartmentFormStore'
import { AppStore } from '~/code/AppStore'
import { CurrenciesStore } from '~/code/stores/NewOrderStore/common/CurrenciesStore'
import { LARGE_PAGE_SIZE, PAGE_SIZE } from '~/code/models'
import { fetchPartnerMerchantDepartments, fetchPartnerMerchantStores } from '~/code/stores/NewOrderStore/services/fetchers'
import { isEmpty, log } from 'dna-common'
import { IMerchantDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/MerchantDetailsForm/IMerchantDetailsFormStore'
import { INewOrder123SendStore } from '~/code/POS/pages/NewOrder/components/iso/ProductsForm/components/123send/INewOrder123SendStore'
import { INewOrderPartnerStore } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/models/partner/INewOrderPartnerStore'

export class StoresAndDepartmentsFormStore implements IStoresAndDepartmentsFormStore {

    constructor (public parentStore: INewOrder123SendStore | INewOrderPartnerStore, public merchantDetailsFormStore: IMerchantDetailsFormStore) {
        makeObservable(this, {
            stores: observable,
            isLoadingStores: observable,
            isAddStoreFormVisible: observable,
            isAddDepartmentFormVisible: observable,
            canGoBack: observable,
            searchStoreName: observable,
            internalIdentifierEncoded: observable,
            setStores: action,
            onAddStoreFormClose: action,
            addStore: action,
            editStore: action,
            deleteStore: action,
            onAddDepartmentFormClose: action,
            addDepartment: action,
            editDepartment: action,
            deleteDepartment: action,
            loadStores: action,
            loadDepartmentsByStoreId: action,
            clearAndLoadStores: action,
            goBack: action,
            setSearchStoreName: action,
            removeDepartmentProducts: action
        })

        this.addStoreFormStore = new AddStoreFormStore(this, merchantDetailsFormStore)
        this.addDepartmentFormStore = new AddDepartmentFormStore(this)

        reaction(() => parentStore.internalIdentifierEncoded, () => {
            this.internalIdentifierEncoded = parentStore.internalIdentifierEncoded
        }, {fireImmediately: true})
    }

    static stepName: string = 'storesAndDepartments'

    public internalIdentifierEncoded: string = ''

    // ui stores
    addStoreFormStore : IAddStoreFormStore
    addDepartmentFormStore : IAddDepartmentFormStore

    // helper stores
    currenciesStore: CurrenciesStore = AppStore.currenciesStore

    stores: SDStore[]

    isLoadingStores: boolean = false

    isAddStoreFormVisible: boolean

    isAddDepartmentFormVisible: boolean

    canGoBack: boolean = true

    searchStoreName: string = ''

    setStores =  (stores: SDStore[]) => {
        this.stores = stores
    }

    onAddStoreFormClose = () => {
        this.isAddStoreFormVisible = false
    }

    addStore = () => {
        log('adding stire in store')
        if (this.addStoreFormStore.addStoreFormData.storeId) {
            this.addStoreFormStore.addStoreFormData = createAddStoreFormData()
        }
        this.isAddStoreFormVisible = true
    }

    editStore = (storeId: string) => {
        this.addStoreFormStore.addStoreFormData = sdStoreToAddStoreFormData(this.stores.find(item => item.id === storeId))
        this.isAddStoreFormVisible = true
    }

    deleteStore = (storeId: string) => {
        this.stores = this.stores.filter(item => item.id !== storeId)
    }

    onAddDepartmentFormClose = () => {
        this.isAddDepartmentFormVisible = false
    }

    addDepartment = (storeId: string) => {
        if (this.addDepartmentFormStore.addDepartmentFormData.departmentId) {
            this.addDepartmentFormStore.addDepartmentFormData = createAddDepartmentFormData()
        }
        this.addDepartmentFormStore.addDepartmentFormData.storeId = storeId

        const store = this.stores.find((s) => s.id === storeId)
        const countryCurrency = store && getCurrencyOfCountry(store.country)
        if (countryCurrency) {
            const currency = Number(countryCurrency.currency.numeric)
            const isCurrencyExist = Boolean(this.currenciesStore.currencies.find((c) => Number(c.value) === currency))
            this.addDepartmentFormStore.addDepartmentFormData.departmentCurrency = isCurrencyExist ? currency : undefined
        }

        this.isAddDepartmentFormVisible = true
    }

    editDepartment = (storeId: string, departmentId: string) => {
        const store = this.stores.find(item => item.id === storeId)
        this.addDepartmentFormStore.addDepartmentFormData = sdDepartmentToAddDepartmentFormData(store.departments.find(department => department.id === departmentId))
        this.isAddDepartmentFormVisible = true
    }

    deleteDepartment = (storeId: string, departmentId) => {
        const store = this.stores.find(item => item.id === storeId)
        this.removeDepartmentProducts(departmentId)
        store.departments = store.departments.filter(item => item.id !== departmentId)
    }

    setStoresAndDepartmentsFormData = () => {
        this.parentStore.handleFormSubmit(this.stores, StoresAndDepartmentsFormStore.stepName)
    }

    removeDepartmentProducts = (departmentId: string) => {
        const filteredProducts = this.parentStore?.productListFormStore?.productListFormData?.products?.filter(product => product.departmentId !== departmentId)
        this.parentStore.productListFormStore?.setProducts(filteredProducts)
    }

    currentPageForStores = 0
    lastResponseSizeForStores = -1

    loadStores = async (internalIdentifierEncoded?: string, pageNumber?: number) => {
        if ((this.lastResponseSizeForStores > 0 && this.lastResponseSizeForStores < PAGE_SIZE) || this.isLoadingStores || isEmpty(AppStore.newOrderStore?.selectedCompany?.merchantId)) {
            return
        }

        if (isEmpty(this.stores)) {
            this.currentPageForStores = 0
        }

        if (pageNumber) {
            this.currentPageForStores = pageNumber
        } else {
            this.currentPageForStores++
        }

        this.isLoadingStores = true

        const internalIdentifier = internalIdentifierEncoded ? `&internalIdentifier=${internalIdentifierEncoded}` : (this.internalIdentifierEncoded ? `&internalIdentifier=${this.internalIdentifierEncoded}` : '')
        fetchPartnerMerchantStores(this.currentPageForStores, PAGE_SIZE, AppStore.newOrderStore?.selectedCompany?.merchantId, internalIdentifier, this.searchStoreName)
            .then((responseStores) => {
                this.lastResponseSizeForStores = responseStores.length || 0
                if (pageNumber) {
                    this.stores = [...responseStores]
                } else {
                    this.stores = [...this.stores, ...responseStores]
                }
                this.isLoadingStores = false

                // load departments
                responseStores.forEach(item => {
                    this.loadDepartmentsByStoreId(item.merchantStoreId, internalIdentifierEncoded)
                })
            })
    }

    loadDepartmentsByStoreId = async (storeId: string, internalIdentifierEncoded?: string) => {
        const store: SDStore = this.stores.find(item => item.id === storeId)
        const departments: SDDepartment[] = store.departments
        const currentPageForDeps = 1

        const internalIdentifier = internalIdentifierEncoded ? `&internalIdentifier=${internalIdentifierEncoded}` : (this.internalIdentifierEncoded ? `&internalIdentifier=${this.internalIdentifierEncoded}` : '')
        const responseDepartments = await fetchPartnerMerchantDepartments(currentPageForDeps, LARGE_PAGE_SIZE, storeId, internalIdentifier)
        departments.push(...responseDepartments)
        store.isLoading = false
    }

    clearAndLoadStores = (internalIdentifier?: string, pageNumber?: number) => {
        this.stores = []
        this.currentPageForStores = 0
        this.lastResponseSizeForStores = -1
        this.isLoadingStores = false
        this.loadStores(internalIdentifier, pageNumber)
    }

    goBack = () => {
        this.parentStore.handleGoBack(StoresAndDepartmentsFormStore.stepName)
    }

    setSearchStoreName = (name) => {
        this.searchStoreName = name
    }

}
