import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { checkHTTPStatus } from '~/code/services/http-response-handler'
import { post } from 'back-connector'
import { error } from 'dna-common'
import { setContext } from '@apollo/client/link/context'
import { LOCAL_STORAGE_TOKEN_KEYS } from '~/code/models'

export function createApolloClient () {
    const httpLink = createHttpLink({
        uri: '/management-api/'
    })

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEYS.GRAPH_QL_MANAGEMENT_API)
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            }
        }
    })

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    })

    return client
}

export async function authorizeApolloClient () {
    try {
        const response = await checkHTTPStatus(post<{token}>('/management-api/api/users/authenticate',
            {
                username: _MANAGEMENT_API_USERNAME_,
                password: _MANAGEMENT_API_PASSWORD_
            }
        ))
        const accessToken = response.token
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEYS.GRAPH_QL_MANAGEMENT_API, accessToken)
        return true
    } catch (e) {
        error(e.message)
        return false
    }
}

export async function graphQlQuery(query, client) {
    try {
        return await client.query(query)
    } catch (error) {
        const { graphQLErrors } = error
        if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].extensions && graphQLErrors[0].extensions.code === 'AUTH_NOT_AUTHENTICATED') {
            const isAuthorised = await authorizeApolloClient()
            if (isAuthorised) {
                return graphQlQuery(query, client)
            }
        }
        throw error
    }
}
