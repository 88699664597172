import { IOrderDetailsFormStore } from '~/code/POS/pages/NewOrder/components/partner/OrderDetailsForm/IOrderDetailsFormStore'
import { SelectItem } from '~/code/common/components'
import { createOrderDetailsFormData, OrderDetailsFormData } from '~/code/POS/pages'
import { action, computed, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { AppStore } from '~/code/AppStore'
import { BrandingOrganisationsStore } from '~/code/stores/NewOrderStore/common/BrandingOrganisationsStore'
import { MerchantType } from '~/code/POS/pages/NewOrder/models/constants'

export class OrderDetailsFormPartnerStore implements IOrderDetailsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            useMainContact: observable,
            orderDetailsFormData: observable,
            canGoBack: observable,
            countries: computed,
            brandingOrganisations: computed,
            merchantType: computed,
            setOrderDetailsFormData: action,
            setUseMainContact: action
        })

        this.orderDetailsFormData = createOrderDetailsFormData()
    }

    static stepName: string = 'orderDetails'

    useMainContact: boolean = false

    orderDetailsFormData: OrderDetailsFormData

    canGoBack: boolean = true

    // helper stores
    private readonly brandingOrganisationsStore = new BrandingOrganisationsStore()

    get countries(): SelectItem[] {
        return AppStore.countriesStore.countries
    }

    get brandingOrganisations (): SelectItem[] {
        return this.brandingOrganisationsStore.brandingOrganisations
    }

    get merchantType(): MerchantType {
        return AppStore.newOrderStore?.placeOrderConfigStore.merchantType
    }

    setOrderDetailsFormData =  (data: OrderDetailsFormData) => {
        this.orderDetailsFormData = {...this.orderDetailsFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(data)
    }

    setUseMainContact = (use: boolean) => {
        this.useMainContact = use
        this.orderDetailsFormData = { ...this.orderDetailsFormData, useMainContact: use }
    }

    goBack = () => {
        this.formSubmitHandler.handleGoBack(OrderDetailsFormPartnerStore.stepName)
    }
}
