import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Layout, Typography } from 'antd'
import classNames from 'classnames'
import { IDLE_TIME_OUT_TIME } from '~/code/models'
import { SideMenu } from '~/code/common/layouts/MainLayout/components'
import { IdleTimer } from '~/code/common/components'
import { Header } from './components'
import { AppStoreContext } from '~/code/AppStore'
import { MainLayoutProps } from './props'
import styles from './MainLayout.scss'

const { Header: AntHeader, Footer, Sider, Content } = Layout

export const MainLayout = observer((props: MainLayoutProps) =>  {
    const siderClassName = classNames(styles.MainLayout_sider, `${props.store.isSideMenuVisible ? styles.MainLayout_sider__visible : styles.MainLayout_sider__hidden}`)
    const siderWrapperClassName = classNames(styles.siderWrapper, `${props.store.isSideMenuVisible ? styles.siderWrapper__visible : styles.siderWrapper__hidden}`)
    const {store, sideMenuStore} = props
    const headerStore = useContext(AppStoreContext)?.headerStore
    const version = store.version ? ` v.${store.version}` : ''

    const onCancelSession = () => {
        store.logOut()
    }

    const renderFooter = () => <div>
        <Typography.Text type="secondary">{version}</Typography.Text>
    </div>

    return <Layout className={styles.MainLayout}>
        <IdleTimer onCancelSession={onCancelSession} timeOut={IDLE_TIME_OUT_TIME}/>
        <AntHeader className={styles.MainLayout_headerWrapper}>
            <div className={styles.MainLayout_header}>
                <Header store={headerStore}/>
            </div>
        </AntHeader>
        <Layout className={styles.MainLayout_body}>
            <div className={siderWrapperClassName}>
                <Sider className={siderClassName}>
                    <SideMenu store={sideMenuStore}/>
                </Sider>
            </div>
            <Layout className={styles.MainLayout_contentWrapper}>
                <Content className={styles.MainLayout_content}>
                    {props.children}
                </Content>
                <Footer className={styles.MainLayout_footer} children={renderFooter()}/>
            </Layout>
        </Layout>
    </Layout>
})

