import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { message, Modal, Upload } from 'antd'
import { get as getFromStore } from 'store-connector'
import { Button } from '~/code/common/components'
import { beforeFileUpload } from '~/code/services'
import { InboxOutlined } from '@ant-design/icons'
import { log } from 'dna-common'
import translations from './translations'
import { FileUploadModalProps } from './props'

const {Dragger} = Upload

export const FileUploadModal = observer((props: FileUploadModalProps) => {
    const {store, permission, onOk, megabytes} = props
    const [isAPKUploading, setIsAPKUploading] = useState(false)

    const handleCancel = () => {
        store.setIsAppUploadModalOpen(false)
    }

    const handleOk = () => {
        onOk()
        store.setIsAppUploadModalOpen(false)
    }

    return (
        <Modal
            visible={store.isAppUploadModalOpen}
            title={translations().uploadAppPackage}
            maskClosable={false}
            onCancel={handleCancel}
            footer={[
                <Button disabled={isAPKUploading} key="back" style={{marginRight: 8}} onClick={handleCancel}>
                    {translations().cancel}
                </Button>,
                <Button disabled={isAPKUploading} key="ok" onClick={handleOk}>
                    {translations().ok}
                </Button>
            ]}
        >
            <Dragger
                name={'file'}
                disabled={store.permissions?.includes(permission)}
                multiple={false}
                beforeUpload={(file) => beforeFileUpload(file, translations().fileSizeRequirements(megabytes), megabytes)}
                onChange={(info) => {
                    const { status } = info.file
                    setIsAPKUploading(true)
                    if (status !== 'uploading') {
                        log(info.file, info.fileList)
                    }
                    if (status === 'done') {
                        setIsAPKUploading(false)
                        message.success(`${info.file.name} file uploaded successfully.`)
                    } else if (status === 'error') {
                        setIsAPKUploading(false)
                        message.error(`${info.file.name} file upload failed.`)
                    }
                }}
                action={`${_ODIN_CDN_URL_}/api/Packages/${store.packageVersionId}/UploadPackage`}
                onDrop={(e) => {
                    log('Dropped files', e.dataTransfer.files)
                }}
                progress={{
                    strokeColor: {
                        '0%': '#108ee9',
                        '100%': '#87d068'
                    },
                    strokeWidth: 3,
                    format: percent => `${parseFloat(percent.toFixed(2))}%`
                }}
                headers={{
                    'Authorization': `Bearer ${getFromStore('accessToken')}`
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{translations().dragAndDropOrClickToUpload(megabytes)}</p>
            </Dragger>
        </Modal>
    )
})
