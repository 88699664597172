import { Product } from './Product'

export interface ProductListFormData {
    products: Product[]
}

export function createProductListFormData() {
    return {
        products: []
    }
}
