import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    companyNameFull: string
    companyNameFullRequired: string
    companyNameShort: string
    companyNameShortRequired: string
    descriptor: string
    descriptorRequired: string
    descriptorTooltip: string
    companyNumber: string
    companyNumberRequired: string
    companyNumberInvalid: string
    companyNumberTooltip: string
    mcc: string
    mccRequired: string
    currency: string
    currencyRequired: string
    acquisitionChannel: string
    acquisitionChannelRequired: string
    acquirer: string
    acquirerRequired: string

    addressLine1: string
    addressLine1Required: string
    addressLine2: string
    addressLine2Required: string
    addressLine3: string
    addressLine3Required: string
    town: string
    townRequired: string
    county: string
    countyRequired: string
    country: string
    countryRequired: string
    postcode: string
    postcodeRequired: string
    postcodeInvalid: string

    contactName: string
    contactNameRequired: string
    contactSurname: string
    contactSurnameRequired: string

    email: string
    emailInvalid: string
    emailRequired: string
    website: string
    websiteRequired: string
    credentials: string
    credentialsGuidanceText: string
    password: string

    nextStep: string
    back: string
    initiatorInfo: string
    initiatorEmail: string
    invalidUrl: string
}>()

