import React from 'react'
import { OrderListItem } from './components'
import { OrderListViewProps } from './props'

export const OrdersListView = (props: OrderListViewProps) => {
    return  <div>
        {
            props.orders.map((item, index) => {
                return <OrderListItem key={`${item.orderReference}-${index}`}
                                      onClick={props.onOrderItemClick}
                                      orderListItem={item}/>
            })
        }
    </div>
}
