import { IPlaceOrderConfigurationStore } from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration/IPlaceOrderConfigurationStore'
import { ConfigCountry, MerchantType, OrganisationType } from '~/code/POS/pages/NewOrder/models/constants'
import { action, computed, makeObservable, observable } from 'mobx'
import { IPlaceOrderConfigurationParentStore, ORGANISATION_TYPES } from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration/models'
import { AppStore } from '~/code/AppStore'

export class PlaceOrderConfigurationStore implements IPlaceOrderConfigurationStore {

    constructor (parentStore) {
        makeObservable(this, {
            _country: observable,
            merchantType: observable,
            organisationType: observable,
            shouldShowOrganisationTypeSelection: observable,
            shouldShowCountrySelection: observable,
            country: computed,
            onMerchantTypeChange: action,
            onOrganisationTypeChange: action,
            onCountryChange: action,
            setSelectedMerchantType: action,
            setSelectedOrganisationType: action,
            setSelectedCountryLocal: action,
            setShowOrganisationTypeSelection: action,
            setShowCountrySelection: action,
            clearAndSetup: action
        })

        this.parentStore = parentStore
        this.clearAndSetup()
    }

    parentStore: IPlaceOrderConfigurationParentStore

    _country: ConfigCountry

    merchantType: MerchantType

    organisationType: OrganisationType

    shouldShowOrganisationTypeSelection: boolean

    shouldShowCountrySelection: boolean

    get country (): ConfigCountry {
        return this._country || AppStore?.userInfo.timezone as ConfigCountry || 'uk'
    }

    onMerchantTypeChange = (merchantType: MerchantType) => {
        if (merchantType === this.merchantType) return
        if (merchantType === 'new'){
            this.setSelectedOrganisationType(ORGANISATION_TYPES[0].value as OrganisationType)
            this.setSelectedCountryLocal(null)
        }
        this.setSelectedMerchantType(merchantType)
    }

    onOrganisationTypeChange = (organisationType) => {
        if (organisationType === this.organisationType) return
        this.setSelectedOrganisationType(organisationType)
        this.setSelectedCountryLocal(null)
    }

    onCountryChange = (country) => {
        if (country === this.country) return
        this.setSelectedCountryLocal(country)
    }

    setSelectedMerchantType = (merchantType: MerchantType) => {
        if (merchantType !== this.merchantType) {
            this.merchantType = merchantType
            this.parentStore.clearAndSetUp()
            this.parentStore.onMerchantTypeChange(merchantType)

            switch (merchantType) {
                case 'new': {
                    this.setShowOrganisationTypeSelection(true)
                    this.setShowCountrySelection(true)
                    break
                }
                default: {
                    this.setShowOrganisationTypeSelection(false)
                    this.setShowCountrySelection(false)
                }
            }
        }
    }

    setSelectedOrganisationType = (organisationType: OrganisationType) => {
        if (organisationType !== this.organisationType) {
            this.organisationType = organisationType
            this.parentStore.clearAndSetUp()
            this.parentStore.onOrganisationTypeChange(organisationType)

            switch (organisationType) {
                case 'company': {
                    this.setShowCountrySelection(true)
                    break
                }
                default: {
                    this.setShowCountrySelection(false)
                }
            }
        }
    }

    setSelectedCountryLocal = (country: ConfigCountry) => {
        if (country !== this._country) {
            this._country = country
            this.parentStore.clearAndSetUp()
        }
    }

    setShowOrganisationTypeSelection = (value: boolean) => {
        this.shouldShowOrganisationTypeSelection = value
    }

    setShowCountrySelection = (value: boolean) => {
        this.shouldShowCountrySelection = value
    }

    clearAndSetup(){
        this._country = null
        this.merchantType = 'new'
        this.organisationType = 'company'
        this.shouldShowCountrySelection = true
        this.shouldShowOrganisationTypeSelection = true
    }

}
