import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Form, Row, Spin } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { EmptyView, Button, ListActions, Input } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { runInAction } from 'mobx'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { useForm } from 'antd/es/form/Form'
import { useOnClickOutside } from '~/code/hooks/useOnClickOutside'
import { PAGE_SIZE } from '~/code/models'
import { StoreCard } from './components'
import { SDDepartment } from './models'
import translations from './translations'
import { StoresAndDepartmentsFormProps } from './props'
import styles from './StoresAndDepartmentsForm.scss'

export const StoresAndDepartmentsForm = observer(({store, children}: StoresAndDepartmentsFormProps) => {
    const { canGoBack, goBack } = store
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>()
    const popupRef = useRef(null)

    useOnClickOutside(popupRef, () => setIsFilterVisible(false))

    const [form] = useForm()

    const onFinish = (values) => {
        store.setStoresAndDepartmentsFormData()
    }

    const onAddStoreClick = () => {
        store.addStore()
    }

    const onEditStoreClick = (storeId: string) => {
        store.editStore(storeId)
    }

    const onDeleteStoreClick = (storeId: string) => {
        store.deleteStore(storeId)
    }

    const onAddDepartmentClick = (storeId: string) => {
        store.addDepartment(storeId)
    }

    const onEditDepartmentClick = (storeId: string, departmentId: string) => {
        store.editDepartment(storeId, departmentId)
    }

    const onDeleteDepartmentClick = (storeId: string, departmentId: string) => {
        store.deleteDepartment(storeId, departmentId)
    }

    const isNextButtonDisabled = () => {
        return isEmpty(store.stores) ||
            store.stores?.some(item => isEmpty(item.departments))
    }

    const handleSearch = () => {
        store.clearAndLoadStores()
        setIsFilterVisible(false)
    }

    const handleReset = () => {
        runInAction(() => {
            store.searchStoreName = ''
            store.clearAndLoadStores()
            setIsFilterVisible(false)
        })
    }

    const renderContent = () => {
        const content = []
        content.push(<ListActions
            currentTotalAmount={store.stores?.length}
            isFilterDisabled={false}
            isFilterVisible={isFilterVisible}
            onFilterClick={() => setIsFilterVisible(true)}
            onGoNextPageClick={() => store.loadStores(store.internalIdentifierEncoded, store.currentPageForStores + 1)}
            onGoPrevPageClick={() => store.loadStores(store.internalIdentifierEncoded, store.currentPageForStores - 1)}
            isNextPageDisabled={store.lastResponseSizeForStores >= 0 && store.lastResponseSizeForStores < PAGE_SIZE}
            isPrevPageDisabled={store.currentPageForStores === 1}
            className={styles.listActions}
            filterContent={() => {
                return <div ref={popupRef} style={{ padding: 8 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Input
                                placeholder={translations().storeName}
                                onChange={(e) => store.setSearchStoreName(e.target.value)}
                            />
                        </Col>
                        <Col span={24}>
                            <Button className={styles.button} onClick={() => handleSearch()} icon={<SearchOutlined />}>
                                {translations().search}
                            </Button>
                            <Button className={styles.button} onClick={() => handleReset()}>
                                {translations().reset}
                            </Button>
                            <Button onClick={() => setIsFilterVisible(false)}>
                                {translations().cancel}
                            </Button>
                        </Col>
                    </Row>
                </div>
            }}
        />)
        if (isEmpty(store.stores.slice())) {
            content.push(<div className={styles.wrapperWithBottomPadding} key={'EmptyViewWrapper'}>
                <EmptyView noData={translations().noStoreAdded} key={'EmptyView'}/>
            </div>)
        } else {
            content.push(<React.Fragment key={'PartnerStores'}>
                {
                    store.stores?.map((item) => {
                        return <div className={styles.wrapperWithBottomPadding} key={item.id}>
                            <StoreCard key={item.id}
                                    onDeleteClick={() => onDeleteStoreClick(item.id)}
                                    onEditClick={() => onEditStoreClick(item.id)}
                                    onDepartmentEditClick={ (department: SDDepartment) => onEditDepartmentClick(item.id, department.id) }
                                    onDepartmentDeleteClick={ (department: SDDepartment) => onDeleteDepartmentClick(item.id, department.id) }
                                    onDepartmentAddClick={ () => onAddDepartmentClick(item.id) }
                                    storeData={ item }
                                    departments={item.departments}/>
                        </div>
                    })
                }
            </React.Fragment>)
        }

        content.push(<div className={styles.addStoreButtonWrapper} key={'AddStoreButtonWrapper'}>
            <Button onClick={onAddStoreClick} key={'AddStoreButton'}>
                <PlusOutlined/> {translations().addStore}
            </Button>
        </div>)

        return content
    }

    return (
        <div className={styles.StoresAndDepartmentsForm}>
            <Spin spinning={store.isLoadingStores}>
                { renderContent() }
            </Spin>
            { children }
            <Form form={form} onFinish={onFinish}>
                <FormNavigation canGoBack={canGoBack} goBack={goBack} isNextStepButtonDisabled={isNextButtonDisabled()}/>
            </Form>
        </div>
    )
})
