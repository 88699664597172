// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3OV0OWwnag4aNsI9DPocR5 {\n  display: flex;\n  flex-direction: row;\n  cursor: pointer;\n  margin-right: 16px;\n  align-items: center; }\n\n._1ol1cWDLqlDPj_iIFBmqs3 {\n  width: 135px;\n  height: 36px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  cursor: pointer; }\n  ._1ol1cWDLqlDPj_iIFBmqs3 img {\n    max-width: 135px;\n    max-height: 36px; }\n\n._3j1Q1jEHxeGOT0uD_hnxeh {\n  z-index: 100;\n  position: absolute;\n  left: 0;\n  top: 64px;\n  background-color: rgba(0, 0, 0, 0.3);\n  width: 100%;\n  height: calc(100vh - 64px); }\n\n._232incSbg9GjzAJLGbg1qp {\n  color: #00a0dc; }\n  ._232incSbg9GjzAJLGbg1qp .ant-tooltip-inner {\n    color: #00a0dc; }\n\n.xzYXlraMKaBtishKyENk9 {\n  background: #FFFFFF;\n  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);\n  border-radius: 2px;\n  min-height: 100px;\n  min-width: 100px; }\n", ""]);
// Exports
exports.locals = {
	"OwnerGroupSelect": "_3OV0OWwnag4aNsI9DPocR5",
	"logoWrapper": "_1ol1cWDLqlDPj_iIFBmqs3",
	"contentOverlay": "_3j1Q1jEHxeGOT0uD_hnxeh",
	"tooltip": "_232incSbg9GjzAJLGbg1qp",
	"OwnerGroupsContent": "xzYXlraMKaBtishKyENk9"
};
module.exports = exports;
