import translations from '~/code/POS/pages/NewOrder/components/common/PlaceOrderConfiguration/translations'

export const MERCHANT_TYPES = [
    {
        label: translations().new,
        value: 'new'
    },
    {
        label: translations().existing,
        value: 'existing'
    }
]

export const ORGANISATION_TYPES = [
    {
        label: translations().company,
        value: 'company'
    },
    {
        label: translations().soleTrader,
        value: 'soleTrader'
    }
]

export const COUNTRIES = [
    {
        label: translations().uk,
        value: 'uk'
    },
    {
        label: translations().roi,
        value: 'roi'
    },
    {
        label: translations().other,
        value: 'other'
    }
]

export const ORDER_TYPES = [
    {
        label: translations().standard,
        value: 'standard'
    },
    {
        label: translations().hardwareOnly,
        value: 'hardwareOnly'
    }
]
