// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1R5wv7xHKtmegvl8mmM5b2 {\n  padding-top: 20px; }\n", ""]);
// Exports
exports.locals = {
	"TransactionLimitsForm": "_1R5wv7xHKtmegvl8mmM5b2"
};
module.exports = exports;
