import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { error } from 'dna-common'
import { notification } from 'antd'
import { IApplicationManagementDetailsStore } from '~/code/ODIN/pages/ApplicationManagementDetails/IApplicationManagementDetailsStore'
import { fetchApplicationDetailsById } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/services/fetchers'
import { IAppStore } from '~/code/IAppStore'
import { ApplicationDetails } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/models/ApplicationDetails'
import { AddNewApplicationStore } from '~/code/ODIN/stores/ApplicationManagementStore/AddNewApplicationStore'
import { IAddNewApplicationStore } from '~/code/ODIN/pages/ApplicationManagement/components/AddNewApplication/IAddNewApplicationStore'
import { ApplicationVersionHistoryStore } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/ApplicationVersionHistoryStore'
import { IApplicationVersionHistoryStore } from '~/code/ODIN/pages/ApplicationManagementDetails/IApplicationVersionHistoryStore'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import translations from '~/code/translations'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class ApplicationManagementDetailsStore implements IApplicationManagementDetailsStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            applicationDetails: observable,
            permissions: computed,
            loadApplicationDetailsById: action,
            fillDeviceManufacturerOnNewVersionAdd: action
        })

        this.parentStore = parentStore
        this.addNewVersionStore = new AddNewApplicationStore(PackageType.Application)
        this.versionHistoryStore = new ApplicationVersionHistoryStore(this)
    }

    parentStore: IAppStore
    addNewVersionStore: IAddNewApplicationStore
    versionHistoryStore: IApplicationVersionHistoryStore
    isLoading: boolean = false
    applicationDetails: ApplicationDetails = null

    public get permissions(): string[] {
        return this.parentStore?.userInfo?.permissions
    }

    public fillDeviceManufacturerOnNewVersionAdd = (deviceManufacturerName: string) => {
        this.addNewVersionStore.deviceTypesStore.manufacturer = deviceManufacturerName
    }

    public loadApplicationDetailsById = (applicationId: number) => {

        if (this.isLoading) {
            return
        }

        this.isLoading = true

        fetchApplicationDetailsById(applicationId)
            .then(response => {
                runInAction(() => {
                    this.applicationDetails = response.result
                })
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: translations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
