// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".X2oNpRsKwL8TmwCiRBE5D .ant-input {\n  height: 50px;\n  background: #FFFFFF;\n  border: 1px solid #BABABA;\n  border-radius: 4px;\n  color: #000;\n  font-family: \"Open Sans\"; }\n  .X2oNpRsKwL8TmwCiRBE5D .ant-input::-webkit-input-placeholder {\n    text-transform: initial; }\n  .X2oNpRsKwL8TmwCiRBE5D .ant-input:-moz-placeholder {\n    text-transform: initial; }\n  .X2oNpRsKwL8TmwCiRBE5D .ant-input:-ms-input-placeholder {\n    text-transform: initial; }\n\n._1UO_41U_93V-aPvWZ1Gmuj .ant-input {\n  background: #EDEDED; }\n\n.X2oNpRsKwL8TmwCiRBE5D._3-x5pKgmT77zDmPZ9iBEN .ant-input {\n  text-transform: lowercase; }\n\n.X2oNpRsKwL8TmwCiRBE5D.coQIT68Xr5ffTgOSor7Dp .ant-input {\n  text-transform: uppercase; }\n", ""]);
// Exports
exports.locals = {
	"Input": "X2oNpRsKwL8TmwCiRBE5D",
	"Input__disabled": "_1UO_41U_93V-aPvWZ1Gmuj",
	"lowercase": "_3-x5pKgmT77zDmPZ9iBEN",
	"uppercase": "coQIT68Xr5ffTgOSor7Dp"
};
module.exports = exports;
