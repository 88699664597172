import { action, makeObservable, observable } from 'mobx'
import { SMALL_PAGE_SIZE } from '~/code/models'
import { fetchMerchants } from './services/fetchers'
import { RangePickerStore } from '~/code/stores/RangePickerStore/RangePickerStore'
import { FiltersSelectStore } from '~/code/stores/FiltersSelectStore/FiltersSelectStore'
import { error, isEmpty } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { LabeledValue } from 'antd/lib/select'
import translations from './translations'
import { IMerchantSelectionStore } from '~/code/ODIN/pages/MerchantSelection/IMerchantSelectionStore'
import { MerchantSearchItem } from '~/code/ODIN/stores/MerchantSelectionStore/models/MerchantSearchItem'
import { MerchantSearchFilter } from '~/code/ODIN/stores/MerchantSelectionStore/models/MerchantSearchFilter'
import { persist } from 'mobx-persist'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { fetchDevices } from '~/code/ODIN/stores/DeviceManagementStore/services/fetchers'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel, LOCATION_ODIN_PATH_NAMES } from '~/code/ODIN/models'
import page from 'page'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class MerchantSelectionStore implements IMerchantSelectionStore {

    public rangePickerStore: RangePickerStore
    public filtersSelectMerchantStore: FiltersSelectStore

    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            merchants: observable,
            selectedMerchant: observable,
            pageNumber: observable,
            pageSize: observable,
            totalPageItems: observable,
            totalCount: observable,
            loadMerchants: action,
            setSelectedMerchant: action
        })

        this.rangePickerStore = new RangePickerStore(this.loadMerchants)
        this.filtersSelectMerchantStore = new FiltersSelectStore(this.loadMerchants, this.options, this.onDeselectFilter)
        this.parentStore = parentStore
    }

    public pageNumber = 1
    public pageSize = SMALL_PAGE_SIZE
    public totalPageItems = 1
    public totalCount = 0
    public isLoading: boolean = false
    public merchants: MerchantSearchItem[] = []
    private parentStore: IAppStore

    @persist('object', MerchantSearchItem)
    public selectedMerchant: MerchantSearchItem = null

    public options: LabeledValue[] = [
        {
            key: 'serialNumber',
            value: 'serialNumber',
            label: translations().serialNumberIsEqualsTo
        },
        {
            key: 'name',
            value: 'name',
            label: translations().merchantMameContains
        },
        {
            key: 'merchantNumber',
            value: 'merchantNumber',
            label: translations().merchantIdIsEqualsTo
        },
        {
            key: 'accountNumber',
            value: 'accountNumber',
            label: translations().accountNumberContains
        }
    ]

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    public loadMerchants = (pageNumber?: number) => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true
        this.pageNumber = isEmpty(pageNumber) ? 1 : pageNumber
        if (this.pageNumber === 1) {
            this.merchants = []
        }

        const queryParams: MerchantSearchFilter = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            serialNumber: this.filtersSelectMerchantStore.serialNumber,
            name: this.filtersSelectMerchantStore.name,
            merchantNumber: this.filtersSelectMerchantStore.merchantNumber,
            accountNumber: this.filtersSelectMerchantStore.accountNumber
        }

        fetchMerchants(queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                    this.merchants = response.result
                    this.totalCount = pagination.totalCount
                    this.totalPageItems = pagination.totalPages
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                this.isLoading = false
            })
    }

    loadMerchantDevices = (serialNumber) => {
        const queryParam = {
            serialNumber
        }

        fetchDevices(this.selectedMerchant?.merchantId, queryParam)
            .then(res => {
                if (res?.result[0]?.deviceId) {
                    page(`${LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT_DETAILS}/${res?.result[0].deviceId}`)
                } else {
                    page(LOCATION_ODIN_PATH_NAMES.DEVICE_MANAGEMENT)
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
    }

    setSelectedMerchant(merchant: MerchantSearchItem) {
        this.selectedMerchant = merchant
    }

    onDeselectFilter = (val: LabeledValue, context) => {
        context.query = context[val.value]
        context[val.value] = undefined

        context.loadOrders(1)
    }

    clear = () => {
        this.pageNumber = 1
        this.pageSize = SMALL_PAGE_SIZE
        this.totalPageItems = 1
        this.totalCount = 0
        this.isLoading = false
        this.merchants = []
        this.filtersSelectMerchantStore = new FiltersSelectStore(this.loadMerchants, this.options, this.onDeselectFilter)
    }
}
