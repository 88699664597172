import React from "react";
const SvgDropDownArrow = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <mask
      id="drop-down-arrow_svg__a"
      maskUnits="userSpaceOnUse"
      x={7}
      y={9}
      width={10}
      height={6}
    >
      <path d="M7 9.5l5 5 5-5H7z" fill="#fff" />
    </mask>
    <g mask="url(#drop-down-arrow_svg__a)">
      <path fill="#fff" fillOpacity={0.54} d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default SvgDropDownArrow;
