import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    penniesDonationActiveText: string
    penniesDonationInactiveText: string
    donationPromptText: string
    donationSelectedText: string
    status: string
    charity: string
    charityNumber: string
    donationMode: string
    adjustmentBanding: string
    ceilingAmount: string
    ceilingAmountInvalid: string
    floorLimit: string
    floorLimitInvalid: string
    adjustmentValue: string
    adjustmentValueInvalid: string
    charityImage: string
    uploadNewImage: string
    isPenniesUsed: string
}>()

