import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    newPackageDetails: string
    displayName: string
    packageNameRequired: string
    packageDeveloper: string
    packageDeveloperRequired: string
    packageVersionNumber: string
    packageVersionNumberRequired: string
    packageVersionNumberInvalid: string
    packageVersionRegex: string
    packageVersionRegexRequired: string
    cancel: string
    uploadPackage: string
    pleaseSelectDeveloper: string
    packageModels: string
    packageModelsRequired: string
    documentationUrl: string
    packageName: string
    appNameRequired: string
    max500Length: string
    invalidUrl: string
    whichDeviceManufacturerSignedAPK: string
    deviceManufacturer: string
    deviceManufacturerRequired: string
    addNewDeveloper: string
    packageCreatedSuccessfully: string
}>()
