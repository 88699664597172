import React from 'react'
import translations from './translations'

translations.add('en', {
    store: 'Store',
    department: 'Department',
    bundles: 'Bundles',
    edit: 'Edit',
    delete: 'Delete',
    areYouSureToDelete: 'Are you sure you want to delete?',
    yesDelete: 'Yes, delete it',
    noDontDelete: 'No, keep it'
})
