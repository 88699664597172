import React from "react";
const SvgCart = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <mask
      id="cart_svg__a"
      maskUnits="userSpaceOnUse"
      x={3}
      y={1}
      width={18}
      height={22}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 6.5h2c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-12c0-1.1.9-2 2-2h2c0-2.76 2.24-5 5-5s5 2.24 5 5zm-2 0c0-1.66-1.34-3-3-3s-3 1.34-3 3h6zm-10 14v-12h14v12H5zm4-11c0 1.66 1.34 3 3 3s3-1.34 3-3h2c0 2.76-2.24 5-5 5s-5-2.24-5-5h2z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#cart_svg__a)">
      <path fill="#00A0DC" d="M0 0h24v24H0z" />
    </g>
  </svg>
);
export default SvgCart;
