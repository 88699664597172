import { action, makeObservable, observable, override, runInAction } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { IFirmwareManagementDetailsStore } from '~/code/ODIN/pages/FirmwareManagementDetails/IFirmwareManagementDetailsStore'
import { ApplicationManagementDetailsStore } from '~/code/ODIN/stores/ApplicationManagementDetailsStore/ApplicationManagementDetailsStore'
import { fetchPatchRegex } from '~/code/ODIN/stores/FirmwareManagementDetailsStore/services/fetchers'
import { notification } from 'antd'
import React from 'react'
import { DEFAULT_NOTIFICATION_DURATION } from '~/code/ODIN/models'

export class FirmwareManagementDetailsStore extends ApplicationManagementDetailsStore implements IFirmwareManagementDetailsStore {
    constructor (parentStore: IAppStore) {
        super(parentStore)

        makeObservable(this, {
            isLoading: override,
            isPatchRegexLoading: observable,
            isCreateNewRegexVersionModalOpen: observable,
            setIsCreateNewRegexVersionModalOpen: action,
            fillDeviceManufacturerOnNewVersionAdd: override,
            applicationDetails: override,
            loadApplicationDetailsById: override
        })
    }

    isCreateNewRegexVersionModalOpen: boolean = false
    isPatchRegexLoading: boolean = false

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    public setIsCreateNewRegexVersionModalOpen = (isCreateNewRegexVersionModalOpen: boolean) => {
        this.isCreateNewRegexVersionModalOpen = isCreateNewRegexVersionModalOpen
    }

    public patchRegex = (id: number, regex: string) => {
        if (this.isPatchRegexLoading) {
            return
        }

        this.isPatchRegexLoading = true

        fetchPatchRegex(id, regex)
            .then((_) => {
                runInAction(() => {
                    this.isCreateNewRegexVersionModalOpen = false
                })
            })
            .catch((err: Error) => {
                const errorObject = JSON.parse(err.message)

                const errors = errorObject?.error?.errors?.map(item => <div key={item.errorMessage}>{`${item.propertyName} - ${item.errorMessage}`}</div>)
                notification.error({
                    message: <>{errors}</>,
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isPatchRegexLoading = false
                })
            })
    }
}
