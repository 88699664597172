import React from 'react'
import translations from './translations'
import { EmptyViewProps } from './props'
import styles from './EmptyView.scss'

export const EmptyView = (props: EmptyViewProps) => {
    return <div className={styles.EmptyView}>
        <div className={styles.message}>
            { props.noData || translations().noData }
        </div>
    </div>
}
