import React, { forwardRef, Ref } from 'react'
import { Checkbox as AntCheckbox } from 'antd'
import { CheckboxProps } from 'antd/es/checkbox'
import styles from './Checkbox.scss'


export const Checkbox = forwardRef((props: CheckboxProps = {}, forwardedRef: Ref<HTMLInputElement>) => {
    return <div className={ styles.Checkbox } >
        <AntCheckbox ref={ forwardedRef } { ...props }>
            { props.children }
        </AntCheckbox>
    </div>
})
