import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Row, Spin, Tooltip } from 'antd'
import { ArrowLeftOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Page } from '~/code/common/pages/Page'
import { SvgConfiguration, SvgRedo, SvgReport } from '~/assets/icons'
import { DispatchReport, ConfigurationReport } from './components'
import configTranslations from './components/ConfigurationReport/translations'
import { CopyText, Steps, Button, Modal, ButtonWithConfirmation } from '~/code/common/components'
import { ExistingMerchantIcon } from '~/code/POS/pages'
import dispatchTranslations from './components/DispatchReport/translations'
import { formatPriceWithCurrency } from '~/code/services/ui-helpers'
import { Bundle } from '~/code/POS/pages/OrderHistory/models'
import { UserInfo } from '~/code/common/layouts/MainLayout/models/UserInfo'
import translations from './translations'
import { OrderDetailsProps } from './props'
import mainStyles from '~/assets/styles/main.scss'
import styles from './OrderDetails.scss'

export const OrderDetails = observer((props: OrderDetailsProps) => {
    const { store } = props
    const {
        companyName,
        orderReference,
        deliveryName,
        deliveryTown,
        deliveryPostcode,
        bundles,
        placedBy,
        status,
        statusLabel,
        statusHistory,
        merchantDetails,
        merchantAddress,
        mid,
        acquirerName,
        lastModifiedDateTime,
        createdDateTime,
        isExistingMerchant,
        canBeCancelled,
        isCancelBtnDisabled
    } = store.order

    let statusClassName = ''
    let statusValueClassName = ''
    switch (status) {
        case 'failed': {
            statusClassName = styles.status_failed
            statusValueClassName = styles.statusValue_failed
            break
        }
        case 'success': {
            statusClassName = styles.status_success
            statusValueClassName = styles.statusValue_success
            break
        }
        case 'reversed': {
            statusClassName = styles.status_reversed
            statusValueClassName = styles.statusValue_reversed
            break
        }
        case 'cancelled': {
            statusClassName = styles.status_cancelled
            statusValueClassName = styles.statusValue_cancelled
            break
        }
        default: {
            statusClassName = ''
            statusValueClassName = ''
        }
    }

    const onBackClick = () => {
        window.history.back()
    }

    const onReorderClick = () => {
        store.reorder()
    }

    const onStatusClick = () => {
        if (status === 'failed') {
            store.handleErrorInfoShow()
        }
    }

    const handleErrorInfoClose = store.handleErrorInfoClose

    const handleConfReportShow = () => {
        store.handleConfReportShow()
    }

    const handleConfReportClose = () => {
        store.handleConfReportClose()
    }

    const handleDispatchReportShow = () => {
        store.handleDispatchReportShow()
    }

    const handleDispatchReportClose = () => {
        store.handleDispatchReportClose()
    }

    const handleNoCompanySelectedClose = () => {
        store.handleNoCompanySelectedClose()
    }

    return (
        <Spin size={'large'} spinning={store.isLoading} tip={translations().loading} className={`${mainStyles.spin__large} ${mainStyles.spin}`}>
            <Page>
                <div className={styles.OrderDetails}>
                    <div className={styles.header} key={'header'}>{translations().orderDetails}</div>
                    <div className={styles.content}>
                        { companyName && <div className={styles.subheader}>{companyName} { isExistingMerchant && <ExistingMerchantIcon /> }</div> }
                        <div className={styles.block}>
                            <div className={styles.statusBlock}>
                                <Steps steps={statusHistory}/>
                            </div>
                            <Row gutter={16}>
                                <Col xs={24} sm={placedBy ? 6 : 12}>
                                    <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                        <div className={styles.propLabel}>{translations().orderRef}</div>
                                        <div className={styles.propValue}><CopyText text={ orderReference } /></div>
                                    </div>
                                </Col>
                                {
                                    placedBy && (
                                        <Col xs={24} sm={6}>
                                            <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                                <div className={styles.propLabel}>{translations().orderPlacedBy }</div>

                                                <div className={styles.propValue}>{ placedBy }</div>
                                            </div>
                                        </Col>
                                    )
                                }
                                <Col xs={24} sm={12}>
                                    <div className={`${styles.prop} ${styles.status} ${statusClassName}`}>
                                        <div className={styles.propLabel}>{translations().status}</div>
                                        <div className={`${styles.propValue} ${styles.statusValue} ${statusValueClassName}`} onClick={onStatusClick}>
                                            {statusLabel}
                                            { status === 'failed' && <Tooltip title={translations().errorInfo.tooltip}>
                                                <span className={styles.statusIcon}><InfoCircleOutlined /></span>
                                            </Tooltip> }
                                        </div>
                                        
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {
                                    createdDateTime && (
                                        <Col xs={24} sm={6}>
                                            <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                                <div className={styles.propLabel}>{translations().orderPlacedOn }</div>

                                                <div className={styles.propValue}>{ createdDateTime }</div>
                                            </div>
                                        </Col>
                                    )
                                }
                                <Col xs={24} sm={ createdDateTime ? 6 : 12 }>
                                    <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                        <div className={styles.propLabel}>{translations().lastUpdateTime}</div>
                                        <div className={styles.propValue}>{lastModifiedDateTime}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className={styles.prop}>
                                        <div className={styles.propLabel}>{translations().deliverTo}</div>
                                        <div className={styles.propValue}>{deliveryName}</div>
                                        <div className={styles.propValue}>{deliveryTown}</div>
                                        <div className={styles.propValue}>{deliveryPostcode}</div>
                                    </div>
                                </Col>
                            </Row>
                            { bundles.map((bundle, index) => {
                                return <Row gutter={16} key={`${bundle.bundle}-${index}`}>
                                    <Col xs={24} sm={12}>
                                        <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                            <div className={styles.propLabel}>{translations().bundle}</div>
                                            <div className={styles.propValue}>{bundle.bundle}</div>
                                            <div className={styles.propValue}>{bundle.bundleDescription}</div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={6}>
                                        <div className={styles.prop}>
                                            <div className={styles.propLabel}>{translations().quantity}</div>
                                            <div className={styles.propValue}>{bundle.bundleQuantity}</div>
                                        </div>
                                    </Col>
                                    { renderBundleCustomPrice(bundle) }
                                </Row>
                            }) }
                        </div>
                        <div className={styles.block}>
                            <div className={styles.blockTitle}>{translations().merchantInformation}</div>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                        <div className={styles.propLabel}>{translations().merchantDetails}</div>
                                        <div className={styles.propValue}>{merchantDetails}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className={styles.prop}>
                                        <div className={styles.propLabel}>{translations().merchantAddress}</div>
                                        <div className={styles.propValue}>{merchantAddress}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={12}>
                                    { mid &&
                                        <div className={`${styles.prop} ${styles.prop_noPaddingLeft}`}>
                                            <div className={styles.propLabel}>{translations().mid}</div>
                                            <div className={styles.propValue}>{mid}</div>
                                        </div>
                                    }
                                </Col>
                                <Col xs={24} sm={12}>
                                    { (acquirerName && acquirerName !== 'Unknown') &&
                                        <div className={styles.prop}>
                                            <div className={styles.propLabel}>{translations().acquirer}</div>
                                            <div className={styles.propValue}>{acquirerName}</div>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div className={styles.reportButtonsWrapper}>
                                { store.order.status === 'success' && <Button outline={'outlined'} key="viewConfiguration" onClick={handleConfReportShow}>
                                    <div className={styles.buttonContent}>
                                        <div className={styles.buttonIcon}>
                                            <SvgConfiguration/>
                                        </div>
                                        {translations().viewConfiguration}
                                    </div>
                                </Button> }
                                <Button outline={'outlined'} key="viewDispatchReport" onClick={handleDispatchReportShow}>
                                    <div className={styles.buttonContent}>
                                        <div className={styles.buttonIcon}>
                                            <SvgReport/>
                                        </div>
                                        {translations().viewDispatchReport}
                                    </div>
                                </Button>
                            </div>
                            <Row gutter={[16, 16]}>
                                <Col xs={12}>
                                    <div className={styles.leftAlign}>
                                        <Button type={'primary'} key="back" onClick={onBackClick}>
                                            <div className={styles.buttonContent}>
                                                <div className={styles.buttonIcon}>
                                                    <ArrowLeftOutlined/>
                                                </div>
                                                { translations().back }
                                            </div>
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className={styles.rightAlign}>
                                        { renderCancelButton(canBeCancelled, isCancelBtnDisabled, store.cancelOrder, store.isCancelOrderLoading) }
                                        { renderTryAgainButton(store.userInfo, status, store.isOrderingPrevented, onReorderClick) }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <Modal
                    key={'noCompanySelectedModal'}
                    visible={store.isNoCompanySelectedVisible}
                    title={null}
                    onOk={handleNoCompanySelectedClose}
                    onCancel={handleNoCompanySelectedClose}
                    footer={null}
                    type={'danger'}
                >
                    <div className={styles.errorInformationModalTitle}>
                        <CloseCircleOutlined /> <span>{translations().noCompanySelected}</span>
                    </div>
                    <div>
                        { store.noCompanySelectedDescription }
                    </div>
                </Modal>

                <Modal
                    key={'configReportModal'}
                    visible={store.isConfReportVisible}
                    title={configTranslations().configReport}
                    onOk={handleConfReportClose}
                    onCancel={handleConfReportClose}
                    footer={[
                        <Button key={'ok'} type={'primary'} onClick={handleConfReportClose}>
                            { translations().close }
                        </Button>
                    ]}
                    type={'info'}
                >
                    <div>
                        <ConfigurationReport report={store.configurationReport} />
                    </div>
                </Modal>

                <Modal
                    key={'dispatchReportModal'}
                    visible={store.isDispatchReportVisible}
                    title={dispatchTranslations().dispatchReport}
                    onOk={handleDispatchReportClose}
                    onCancel={handleDispatchReportClose}
                    footer={[
                        <Button key={'ok'} type={'primary'} onClick={handleDispatchReportClose}>
                            { translations().close }
                        </Button>
                    ]}
                    type={'info'}
                >
                    <div>
                        <DispatchReport report={store.dispatchReport} />
                    </div>
                </Modal>

                <Modal
                    key={'errorInformationModal'}
                    visible={store.isErrorInfoVisible}
                    title={null}
                    onOk={handleErrorInfoClose}
                    onCancel={handleErrorInfoClose}
                    footer={null}
                    type={'danger'}
                >
                    <div className={styles.errorInformationModalTitle}>
                        <CloseCircleOutlined /> <span>{translations().errorInfo.title}</span>
                    </div>
                    <div>
                        {store.errorInfo && store.errorInfo.map((err) => <Card key={err.errorCode} className={styles.errorInformationModalItem}>
                            <p>{err.errorText}</p>
                            <span className={styles.errorInformationModalLabel}>{translations().errorInfo.fields}:</span> <ul>{err.properties.map((p, i) => <li key={i}>{p}</li>)}</ul>
                        </Card>)}
                    </div>
                </Modal>
            </Page>
        </Spin>
    )
})

function renderCancelButton (showCancelOrder: boolean, disabled: boolean, cancelOrder: () => void, loading: boolean) {
    if (!showCancelOrder) {
        return null
    }

    return <div className={styles.button}>
        <ButtonWithConfirmation
            statusType={'danger'}
            confirmButtonTitle={translations().continueWithCancellation}
            cancelButtonTitle={translations().close}
            popConfirmTitle={translations().areYouSureCancelOrder}
            onConfirm={cancelOrder}
            loading={loading}
            disabled={disabled}
        >
            {!disabled ? translations().cancel : translations().cancelling}
        </ButtonWithConfirmation>
    </div>
}

function renderTryAgainButton (userInfo: UserInfo, status, disabled: boolean, tryAgainCallback) {

    return <div className={styles.button}>
        {
            status === 'failed' && <Button type={'primary'} key="reorder" onClick={tryAgainCallback} disabled={disabled}>
                <div className={styles.buttonContent}>
                    <div className={styles.buttonIcon}>
                        <SvgRedo/>
                    </div>
                    {status === 'failed' ? translations().tryAgain : translations().reorder}
                </div>
            </Button>
        }
    </div>
}

function renderBundleCustomPrice (bundle: Bundle) {
    if (bundle.priceOverride !== undefined && bundle.priceOverride !== null) {
        return <Col xs={24} sm={6}>
            <div className={styles.prop}>
                <div className={styles.propLabel}>{translations().customPrice}</div>
                <div className={styles.propValue}>{formatPriceWithCurrency(bundle.priceOverride)}</div>
            </div>
        </Col>
    }
    return null
}
