import { SelectItem, SummaryBlockData } from '~/code/common/components'
import { MerchantDetailsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/MerchantDetailsForm/models'
import { StoreAndDepartmentSelectionData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/StoreAndDepartmentSelectionForm/models'
import { TerminalSettingsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm/models'
import { TransactionLimitsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm/models'
import { PenniesFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/PenniesForm/models'
import { CheckoutSettingsFormData } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/models'
import translations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/translations'
import storeAndDepartmentSelectionFormTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/StoreAndDepartmentSelectionForm/translations'
import merchantDetailsFormTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/MerchantDetailsForm/translations'
import terminalSettingsFormTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TerminalSettingsForm/translations'
import transactionLimitsFormTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm/translations'
import penniesFormTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/PenniesForm/translations'
import checkoutSettingsFormTranslations from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/CheckoutSettingsForm/translations'
import { isEmpty } from 'dna-common'

function nonEmpty(value: any) {
    return isEmpty(value) ? ' ' : value
}

export function storeAndDepartmentSelectionFormDataToSummaryBlock (data: StoreAndDepartmentSelectionData, stores: SelectItem[]): SummaryBlockData {
    const store = stores ? stores.find(item => item.value as number === data.storeId) : null
    const department: SelectItem = store.params.departments.find(item => item.value === data.departmentId)
    return {
        header: translations().storeAndDepartmentSelection,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: storeAndDepartmentSelectionFormTranslations().store,
                        value: store?.label,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: storeAndDepartmentSelectionFormTranslations().department,
                        value: department?.label,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

/// field type to change
export function merchantDetailsFormDataToSummaryBlock (data: MerchantDetailsFormData, countries: SelectItem[], mccList: SelectItem[]): SummaryBlockData {
    const country = countries ? countries.find(item => item.value as number === data.country) : null
    const mcc = mccList ? mccList.find(item => item.value as number === data.mcc) : null

    return {
        header: translations().merchantDetails,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().companyNameFull,
                        value: data?.companyNameFull,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().country,
                        value: country?.label,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().companyNameShort,
                        value: data?.companyNameShort,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().postcode,
                        value: data?.postcode,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().descriptor,
                        value: data?.descriptor,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().addressLine1,
                        value: data?.addressLine1,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().website,
                        value: data?.website,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().addressLine2,
                        value: data?.addressLine2,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().mcc,
                        value: mcc.label,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().town,
                        value: data?.town,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().currency,
                        value: data?.currency?.join(','),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().county,
                        value: nonEmpty(data?.county),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().acquisitionChannel,
                        value: data?.acquisitionChannel,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: merchantDetailsFormTranslations().initiatorInfo
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().initiatorEmail,
                        value: data?.initiatorEmail,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: merchantDetailsFormTranslations().credentials
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: merchantDetailsFormTranslations().email,
                        value: data?.credentials?.email,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'password',
                        label: merchantDetailsFormTranslations().password,
                        value: nonEmpty(data?.credentials?.password),
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function terminalSettingsFormDataToSummaryBlock (data: TerminalSettingsFormData, countries: SelectItem[], acquirers: SelectItem[]): SummaryBlockData {
    const acquirer = acquirers ? acquirers.find(item => item.value as number === data?.optomanyPlatform?.acquirerId) : null
    const country = countries ? countries.find(item => item.value as number === data?.optomanyPlatform?.countryId) : null

    return {
        header: translations().terminalSettings,
        rows: [
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: terminalSettingsFormTranslations().generalSettings
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().defaultTransactionType,
                        value: data?.defaultTransactionType,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().visaMid,
                        value: data?.optomanyPlatform?.visaMid,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().acquirer,
                        value: acquirer?.label,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().mastercardMid,
                        value: data?.optomanyPlatform?.mastercardMid,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().paymentChannelId,
                        value: data?.optomanyPlatform?.paymentChannelId,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().amexMid,
                        value: data?.optomanyPlatform?.amexMid,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().cardAcceptorNumber,
                        value: data?.optomanyPlatform?.cardAcceptorNumber,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().unionPayMid,
                        value: data?.optomanyPlatform?.unionPayMid,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().merchantName,
                        value: data?.optomanyPlatform?.merchantName,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().avsHouseMatrix,
                        value: data?.optomanyPlatform?.avsHouseMatrix,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().merchantUrl,
                        value: data?.optomanyPlatform?.merchantUrl,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().avsPostcodeMatrix,
                        value: data?.optomanyPlatform?.avsPostCodeMatrix,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().country,
                        value: country?.label,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().cscMatrix,
                        value: data?.optomanyPlatform?.cscMatrix,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().enableAvsForNonUKCustomers,
                        value: String(data?.optomanyPlatform?.enableAvsForNonUKCustomers),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().paMatrix,
                        value: data?.optomanyPlatform?.paMatrix,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: terminalSettingsFormTranslations().terminalDeviceInfo
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().attributes,
                        value: data?.optomanyPlatform?.terminalDeviceInfo?.attributes,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().attributesUsed,
                        value: data?.optomanyPlatform?.terminalDeviceInfo?.attributesUsed,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().capabilities,
                        value: data?.optomanyPlatform?.terminalDeviceInfo?.capabilities,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().poiCapabilities,
                        value: data?.optomanyPlatform?.terminalDeviceInfo?.poiCapabilities,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().terminalType,
                        value: data?.optomanyPlatform?.terminalDeviceInfo?.terminalType,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: terminalSettingsFormTranslations().capabilitiesUsed,
                        value: data?.optomanyPlatform?.terminalDeviceInfo?.capabilitiesUsed,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function transactionLimitsFormDataToSummaryBlock (data: TransactionLimitsFormData): SummaryBlockData {
    return {
        header: translations().transactionLimits,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: transactionLimitsFormTranslations().dailyTransactionLimitByValue,
                        value: data?.dailyValue === 0 ? transactionLimitsFormTranslations().unlimited : data?.dailyValue,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: transactionLimitsFormTranslations().monthlyTransactionLimitByVolume,
                        value: data?.monthlyVolume === 0 ? transactionLimitsFormTranslations().unlimited : data?.monthlyVolume,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: transactionLimitsFormTranslations().dailyTransactionLimitByVolume,
                        value: data?.dailyVolume === 0 ? transactionLimitsFormTranslations().unlimited : data?.dailyVolume,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: transactionLimitsFormTranslations().maximumSingleTransactionByValue,
                        value: data?.individualTransactionValue === 0 ? transactionLimitsFormTranslations().unlimited : data?.individualTransactionValue,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: transactionLimitsFormTranslations().monthlyTransactionLimitByValue,
                        value: data?.monthlyValue === 0 ? transactionLimitsFormTranslations().unlimited : data?.monthlyValue,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: transactionLimitsFormTranslations().maximumRefundByValue,
                        value: data?.maxRefundValue === 0 ? transactionLimitsFormTranslations().unlimited : data?.maxRefundValue,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}


export function penniesFormDataToSummaryBlock (data: PenniesFormData): SummaryBlockData {
    const { adjustmentBands = [] } = data
    const [adjustmentBand = {
        bandCeilValue: undefined,
        adjustmentValue: undefined,
        bandFloorValue: undefined
    }] = adjustmentBands
    return {
        header: translations().pennies,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: penniesFormTranslations().penniesDonationActiveText,
                        value: data?.penniesDonationActiveText,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: penniesFormTranslations().penniesDonationInactiveText,
                        value: data?.penniesDonationInactiveText,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: penniesFormTranslations().donationMode,
                        value: data?.donationMode,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: penniesFormTranslations().adjustmentBanding
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: penniesFormTranslations().ceilingAmount,
                        value: adjustmentBand.bandCeilValue,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: penniesFormTranslations().adjustmentValue,
                        value: adjustmentBand.adjustmentValue,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: penniesFormTranslations().floorLimit,
                        value: adjustmentBand.bandFloorValue,
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

export function checkoutSettingsFormDataToSummaryBlock (data: CheckoutSettingsFormData): SummaryBlockData {
    if (data.usePartnerSettings) {
        return {
            header: translations().checkoutSettings,
            rows: [
                {
                    fields: [
                        {
                            type: 'subHeader',
                            value: checkoutSettingsFormTranslations().checkoutCustomisations
                        }
                    ]
                },
                {
                    fields: [
                        {
                            type: 'field',
                            label: checkoutSettingsFormTranslations().usePartnerDefinedSettings,
                            value: String(data?.usePartnerSettings),
                            span: {xs: 12, sm: 12}
                        }
                    ]
                }
            ]
        }
    }

    return {
        header: translations().checkoutSettings,
        rows: [
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().integrationTypes,
                        value: data?.integrationTypes?.join(','),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: checkoutSettingsFormTranslations().mandatoryFields
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountFirstName,
                        value: String(data?.mandatoryFields?.accountFirstName),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountCountry,
                        value: String(data?.mandatoryFields?.accountCountry),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountLastName,
                        value: String(data?.mandatoryFields?.accountLastName),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountCity,
                        value: String(data?.mandatoryFields?.accountCity),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountEmail,
                        value: String(data?.mandatoryFields?.accountEmail),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountPostalCode,
                        value: String(data?.mandatoryFields?.accountPostalCode),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().accountStreet1,
                        value: String(data?.mandatoryFields?.accountStreet1),
                        span: {offset: 12, xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: checkoutSettingsFormTranslations().colors
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().primaryColor,
                        value: data?.customStyle?.primaryColor,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().actionButtonBackgroundColor,
                        value: data?.customStyle?.actionButtonBackground,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerStartColor,
                        value: data?.customStyle?.headerStartColor,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().actionButtonBackgroundColor,
                        value: data?.customStyle?.actionButtonBackground,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerFinishColor,
                        value: data?.customStyle?.headerFinishColor,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().closeButtonColor,
                        value: data?.customStyle?.closeButtonColor,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoTextColor,
                        value: data?.customStyle?.headerLogoTextColor,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: checkoutSettingsFormTranslations().images
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().secondaryLogoImagePosition,
                        value: data?.customStyle?.headerSecondaryLogoPosition,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'subHeader',
                        value: checkoutSettingsFormTranslations().otherSettings
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoText,
                        value: data?.customStyle?.headerLogoText,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoMobileWidth,
                        value: data?.customStyle?.headerLogoMobileWidth,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoTextSecondary,
                        value: data?.customStyle?.headerLogoTextSecondary,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoMobileHeight,
                        value: data?.customStyle?.headerLogoMobileHeight,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoTextMarginLeft,
                        value: data?.customStyle?.headerLogoTextMarginLeft,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoDesktopWidth,
                        value: data?.customStyle?.headerLogoDesktopWidth,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().closeButtonOpacity,
                        value: data?.customStyle?.closeButtonOpacity,
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoDesktopHeight,
                        value: data?.customStyle?.headerLogoDesktopHeight,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().actionButtonBorderRadius,
                        value: data?.customStyle?.actionButtonBorderRadius,
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().showPoweredByBlock,
                        value: String(data?.customStyle?.showPoweredByBlock),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().headerLogoDesktopHeight,
                        value: String(data?.customStyle?.showOrderDescriptionBlock),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().showHeaderBottomLine,
                        value: String(data?.customStyle?.showHeaderBottomLine),
                        span: {xs: 12, sm: 12}
                    },
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().showPaymentSecuredByBlock,
                        value: String(data?.customStyle?.showPaymentSecuredByBlock),
                        span: {xs: 12, sm: 12}
                    }
                ]
            },
            {
                fields: [
                    {
                        type: 'field',
                        label: checkoutSettingsFormTranslations().showOrderNumberBlock,
                        value: String(data?.customStyle?.showOrderNumberBlock),
                        span: {xs: 12, sm: 12}
                    }
                ]
            }
        ]
    }
}

/// toWATCH

export function createRequestSummary(
    storeAndDepartmentSelectionFormData: StoreAndDepartmentSelectionData,
    merchantDetailsFormData: MerchantDetailsFormData,
    terminalSettingsFormData: TerminalSettingsFormData,
    transactionLimitsFormData: TransactionLimitsFormData,
    penniesFormData: PenniesFormData,
    checkoutSettingsFormData: CheckoutSettingsFormData,
    stores: SelectItem[],
    countries: SelectItem[],
    mccList: SelectItem[],
    acquirers: SelectItem[]
): SummaryBlockData[] {
    const requestSummary: SummaryBlockData[] = []
    requestSummary.push(storeAndDepartmentSelectionFormDataToSummaryBlock(storeAndDepartmentSelectionFormData, stores))
    requestSummary.push(merchantDetailsFormDataToSummaryBlock(merchantDetailsFormData, countries, mccList))
    requestSummary.push(terminalSettingsFormDataToSummaryBlock(terminalSettingsFormData, countries, acquirers))
    requestSummary.push(transactionLimitsFormDataToSummaryBlock(transactionLimitsFormData))
    penniesFormData.isPenniesUsed && requestSummary.push(penniesFormDataToSummaryBlock(penniesFormData))
    requestSummary.push(checkoutSettingsFormDataToSummaryBlock(checkoutSettingsFormData))

    return requestSummary
}
