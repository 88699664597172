// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2AZ-4nSL8huXJhNLG5eYLl :hover {\n  cursor: pointer; }\n\n._2AZ-4nSL8huXJhNLG5eYLl .ant-select, ._2AZ-4nSL8huXJhNLG5eYLl .ant-select-multiple {\n  font-family: Open Sans;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 19px;\n  color: #000000; }\n\n._2AZ-4nSL8huXJhNLG5eYLl .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {\n  min-height: 50px;\n  border: 1px solid #BABABA;\n  border-radius: 4px; }\n  ._2AZ-4nSL8huXJhNLG5eYLl .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {\n    min-height: 48px; }\n  ._2AZ-4nSL8huXJhNLG5eYLl .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder, ._2AZ-4nSL8huXJhNLG5eYLl .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {\n    line-height: 48px; }\n\n._2AZ-4nSL8huXJhNLG5eYLl .ant-select-multiple .ant-select-selector {\n  min-height: 50px;\n  border: 1px solid #BABABA;\n  border-radius: 4px; }\n\n.ant-select-item {\n  line-height: 40px; }\n", ""]);
// Exports
exports.locals = {
	"Select": "_2AZ-4nSL8huXJhNLG5eYLl"
};
module.exports = exports;
