import React from 'react'
import translations from './translations'

translations.add('en', {
    accountNumber: 'Bank account number',
    accountNumberRequired: 'Bank account number is required',
    accountNumberInvalid: 'Bank account number must be 8 digits',
    sortCode: 'Sort code',
    sortCodeRequired: 'Sort code is required',
    sortCodeInvalid: 'Sort code must be 6 digits',
    acquirer: 'Acquirer',
    acquirerRequired: 'Acquirer is required',
    merchantId: 'Merchant ID',
    merchantIdRequired: 'Merchant ID is required'
})
