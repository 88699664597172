import { DepartmentType } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/AddDepartmentForm/models/DepartmentType'

export interface ProductDepartment {
    id?: string
    isNew?: boolean
    name: string
    currencyId: number
    currencyName: string
    merchantCategoryCode: number
    merchantCategoryCodeLabel: string
    departmentType: DepartmentType
    streamlineCompanyId: string
    cashBackAccepted: boolean
    cashBack: number
    gratuityAccepted: boolean
    gratuity: number
    refundAccepted: boolean
    refund: number
    purchaseAccepted: boolean
    purchase: number
    visaMID: string
    visaDebitMID: string
    mastercardMID: string
    mastercardDebitMID: string
    maestroDebitMID: string
    acquirer: number
    acquirerName: string
    bankAccountNumber: string
    sortCode: string
    amexMerchantID: string
    amexAcquirer: number
    amexAcquirerName: string
    amexBankAccountNumber: string
    amexSortCode: string
    unionPayMerchantID: string
    unionPayAcquirer: number
    unionPayAcquirerName: string
    unionPayBankAccountNumber: string
    unionPaySortCode: string
    dinersDiscoverMerchantID: string
    dinersDiscoverAcquirer: number
    dinersDiscoverAcquirerName: string
    dinersDiscoverBankAccountNumber: string
    dinersDiscoverSortCode: string
    flexecashMerchantID: string
    flexecashAcquirer: number
    flexecashAcquirerName: string
    flexecashBankAccountNumber: string
    flexecashSortCode: string
}

export function createProductDepartment(): ProductDepartment {
    return {
        id: '',
        isNew: true,
        name: '',
        currencyId: 0,
        currencyName: '',
        merchantCategoryCode: 0,
        merchantCategoryCodeLabel: '',
        departmentType: 'general',
        streamlineCompanyId: '',
        cashBackAccepted: false,
        cashBack: 0,
        gratuityAccepted: false,
        gratuity: 0,
        refundAccepted: false,
        refund: 0,
        purchaseAccepted: false,
        purchase: 0,
        visaMID: '',
        visaDebitMID: '',
        mastercardMID: '',
        mastercardDebitMID: '',
        maestroDebitMID: '',
        acquirer: 0,
        acquirerName: '',
        bankAccountNumber: '',
        sortCode: '',
        amexMerchantID: '',
        amexAcquirer: 0,
        amexAcquirerName: '',
        amexBankAccountNumber: '',
        amexSortCode: '',
        unionPayMerchantID: '',
        unionPayAcquirer: 0,
        unionPayAcquirerName: '',
        unionPayBankAccountNumber: '',
        unionPaySortCode: '',
        dinersDiscoverMerchantID: '',
        dinersDiscoverAcquirer: 0,
        dinersDiscoverAcquirerName: '',
        dinersDiscoverBankAccountNumber: '',
        dinersDiscoverSortCode: '',
        flexecashMerchantID: '',
        flexecashAcquirer: 0,
        flexecashAcquirerName: '',
        flexecashBankAccountNumber: '',
        flexecashSortCode: ''
    }
}
