import React from 'react'
import { observer } from 'mobx-react'
import { IconButtonProps } from './props'
import styles from './IconButton.scss'

export const IconButton = observer((props: IconButtonProps) => {

    const { className, onClick, disabled } = props
    const onButtonClick = () => {
        if (!disabled) {
            onClick()
        }
    }

    return (
        <div className={`${styles.IconButton} ${disabled ? styles.IconButton__disabled : ''} ${className ? className : ''}`}
            onClick={onButtonClick}>
            {props.icon}
        </div>
    )
})
