import { TranslationBuilder } from '~/code/common/components/Translation'

export interface TranslationStructure {
    addProduct: string
    addBundle: string

    store: string
    storeName: string
    storeNameRequired: string
    telephoneNumber: string
    telephoneNumberRequired: string
    telephoneNumberInvalid: string
    companyNumber: string
    companyNumberRequired: string
    companyNumberUKInvalid: string
    companyNumberROIInvalid: string
    companyNumberUKTooltip: string
    companyNumberROITooltip: string
    email: string
    emailRequired: string
    emailInvalid: string
    vatNumber: string
    vatNumberRequired: string

    address: string
    country: string
    countryRequired: string
    town: string
    townRequired: string
    postcode: string
    postcodeRequired: string
    postcodeInvalid: string
    county: string
    countyRequired: string
    addressLine1: string
    addressLine1Required: string
    addressLine2: string
    addressLine2Required: string
    addressLine3: string
    axeptConnectCloud: string
    yes: string
    no: string

    department: string,
    departmentId: string,
    departmentName: string
    departmentType: string
    general: string
    eCommerce: string
    departmentNameRequired: string
    departmentRequired: string
    streamlineCompanyId: string
    streamlineCompanyIdRequired: string
    streamlineCompanyIdInvalid: string
    streamlineCompanyIdTooltip: string
    merchantCategoryCode: string
    merchantCategoryCodeRequired: string
    merchantCategoryCodeTooltip: string

    transactionTypes: string
    cashBack: string
    enableCashBack: string
    cashBackRequired: string
    cashBackInvalid: string
    cashBackMaxExceeded: string
    gratuity: string
    enableGratuity: string
    gratuityRequired: string
    refund: string
    enableRefund: string
    refundRequired: string
    purchase: string
    enablePurchase: string
    purchaseRequired: string

    cardSchemeDetails: string
    noCardSchemeSpecified: string
    visaMID: string
    visaMIDRequired: string
    visaDebitMID: string
    visaDebitMIDRequired: string
    accountNumber: string
    accountNumberRequired: string
    accountNumberInvalid: string
    sortCode: string
    sortCodeRequired: string
    sortCodeInvalid: string
    mastercardMID: string
    mastercardMIDRequired: string
    mastercardDebitMID: string
    mastercardDebitMIDRequired: string
    maestroDebitMID: string
    maestroDebitMIDRequired: string

    acquirer: string
    acquirerRequired: string
    acquirerTooltip: string
    acquirerAssignedTID: string

    preferredSubmission: string
    preferredSubmissionRequired: string
    preferredSubmissionTooltip: string
    brandingOrganisation: string
    brandingOrganisationTooltip: string
    accountName: string
    accountNameRequired: string
    accountNameTooltip: string
    currency: string
    currencyRequired: string
    currencyTooltip: string

    merchantId: string
    merchantIdRequired: string
    amexMerchantIdTooltip: string

    americanExpress: string
    dinersDiscover: string
    unionPay: string
    flexecash: string

    selectStoreAndDepartment: string
    selectBundles: string
    proceedToBundlesSelection: string
    reselectStoreAndDepartment: string
    complete: string

    cancel: string
    addToOrder: string
    save: string

    invalidWorldPayMIDLength: string
    invalidBarclaycardSterlingMIDLength: string
    invalidGlobalPayMIDLength: string
    invalidAMEXMIDLength: string
    invalidElavonMIDLength: string
    invalidBarclaycardMCMIDLength: string
    invalidOptomanyMIDLength: string
    invalidLloydsCardNetMIDLength: string
    invalidFDMSMIDLength: string
    invalidAIBMIDLength: string
    invalidSwedbankMIDLength: string
    invalidBorgunMIDLength: string

    bundle: string
    bundles: string
    bundleRequired: string
    bundleTooltip: string
    bundleDescription: string
    noDescription: string
    quantity: string
    quantityRequired: string
    selectedBundles: string

    noBundleAdded: JSX.Element | string
}

export default TranslationBuilder.create<TranslationStructure>()

