export interface TerminalSettingsFormData {
    defaultTransactionType: 'SALE' | 'AUTH',
    optomanyPlatform: {
        acquirerId: number
        terminalId?: string
        paymentChannelId: number
        cardAcceptorNumber: string
        merchantDepartmentId?: number
        merchantName: string
        merchantUrl: string
        merchantStoreId?: number
        visaMid: string
        mastercardMid: string
        amexMid: string
        unionPayMid: string
        avsHouseMatrix: number
        avsPostCodeMatrix: number
        cscMatrix: number
        paMatrix: number
        enableAvsForNonUKCustomers: boolean
        countryId: number
        terminalDeviceInfo: {
            attributes: string
            capabilities: string
            terminalType: string
            attributesUsed: string
            poiCapabilities: string
            capabilitiesUsed: string
        }
    }
}

export function createFakeTerminalSettingsFormData(): TerminalSettingsFormData {
    return {
        defaultTransactionType: undefined,
        optomanyPlatform: {
            acquirerId: null,
            paymentChannelId: null,
            cardAcceptorNumber: '',
            merchantName: '',
            merchantUrl: '',
            visaMid: '',
            mastercardMid: '',
            amexMid: '',
            unionPayMid: '',
            avsHouseMatrix: null,
            avsPostCodeMatrix: null,
            cscMatrix: null,
            paMatrix: null,
            enableAvsForNonUKCustomers: true,
            countryId: null,
            terminalDeviceInfo: {
                attributes: '020000',
                capabilities: '000000',
                terminalType: 'C298',
                attributesUsed: '020000',
                poiCapabilities: '400C07880000',
                capabilitiesUsed: '000000'
            }
        }
    }
}
