import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Col, Row, Spin, Form } from 'antd'
import { getFieldValue } from '~/code/services'
import { Input, Select, Checkbox, LabelWithTooltip, DatePicker } from '~/code/common/components'
import { stringToDate } from './services'
import translations from './translations'
import { OrderDetailsFormProps } from './props'
import styles from './OrderDetailsForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { isEmpty } from 'dna-common'

export const OrderDetailsForm = observer(({ store,  }: OrderDetailsFormProps) => {
    const { orderDetailsFormData: data, brandingOrganisations, setOrderDetailsFormData, setUseMainContact, merchantType, canGoBack, goBack } = store
    const [form] = Form.useForm()

    const onFinish = (values) => {
        setOrderDetailsFormData({
            ...values,
            requestedDeliveryAndOrActivationDate: values.requestedDeliveryAndOrActivationDate ?
                (values.requestedDeliveryAndOrActivationDate as unknown as moment.Moment).format('YYYY-MM-DD') :
                ''
        })
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const onUseMerchantAddressChange = (value) => {
        setUseMainContact(value)
        form.resetFields([
            'companyName', 'email', 'firstName', 'surname'
        ])
        form.setFieldsValue({
            companyName: getFieldValue(data, 'companyName'),
            email: getFieldValue(data, 'email'),
            firstName: getFieldValue(data, 'firstName'),
            surname: getFieldValue(data, 'surname')
        })
    }

    const isContactFieldRequired = () => {
        return !data.useMainContact
    }

    function disabledDate(date) {
        // Can not select days before today and today
        return date && date < moment().endOf('day')
    }

    const validateForWhiteSpace = (value) => {
        return value.includes(' ')
    }

    return (
        <div className={styles.OrderDetailsForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <div className={styles.OrderDetailsForm_subgroupTitle}>
                    {translations().orderInformation}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().poNumber}
                                name={'poNumber'}
                                initialValue={getFieldInitialValue('poNumber')}
                            >
                                <Input placeholder={translations().poNumber}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().brandingOrganisation} tooltipText={translations().brandingOrganisationTooltip} tooltipPosition={'right'} />}
                                name={'brandingOrganisationId'}
                                initialValue={(isEmpty(brandingOrganisations) ? undefined : getFieldInitialValue('brandingOrganisationId'))}
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                <Select placeholder={translations().brandingOrganisation}
                                        allowClear
                                        disabled={isEmpty(brandingOrganisations?.slice())}
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                           return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        notFoundContent={<Spin size={'small'}/>}
                                        selectItems={brandingOrganisations.slice()}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().activationDate}
                                name={'requestedDeliveryAndOrActivationDate'}
                                initialValue={stringToDate(getFieldInitialValue('requestedDeliveryAndOrActivationDate')) || null}
                            >
                                <DatePicker placeholder={translations().activationDate} disabledDate={disabledDate} format={'YYYY-MM-DD'} size={'large'}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className={styles.OrderDetailsForm_subgroupTitle}>
                    {translations().contactsForActivation} <div className={styles.tooltipIconWrapper}><span className={styles.tooltipIcon}><LabelWithTooltip label={''} tooltipText={translations().contactsForActivationTooltip}/></span></div>
                </div>
                <Row gutter={16}>
                    <Col xs={24}>
                        <div>
                            <Form.Item
                                name={'useMainContact'}
                                initialValue={data.useMainContact}
                                valuePropName={'checked'}
                            >
                                <Checkbox onChange={(e) => onUseMerchantAddressChange(e.target.checked)}>
                                    {translations().useMainContact}
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                { isContactFieldRequired() && <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().companyName}
                                name={'companyName'}
                                initialValue={getFieldInitialValue('companyName')}
                                rules={[
                                    {
                                        required: isContactFieldRequired(),
                                        message: translations().companyNameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().companyName} disabled={!isContactFieldRequired()}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().email}
                                name={'email'}
                                initialValue={getFieldInitialValue('email')}
                                rules={[
                                    {
                                        required: isContactFieldRequired(),
                                        message: translations().emailRequired
                                    },
                                    {
                                        type: 'email',
                                        message: translations().emailInvalid
                                    }
                                ]}
                            >
                                <Input textTransform={'lowercase'} placeholder={translations().email} disabled={!isContactFieldRequired()}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col xs={24} md={10}>
                        <div>
                            <Form.Item
                                label={translations().firstName}
                                name={'firstName'}
                                initialValue={getFieldInitialValue('firstName')}
                                rules={[
                                    {
                                        required: isContactFieldRequired(),
                                        message: translations().firstNameRequired
                                    },
                                    {
                                        validator: async (rule, value) => {
                                            if (validateForWhiteSpace(value)) {
                                                throw new Error(translations().noWhiteSpace)
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input placeholder={translations().firstName} disabled={!isContactFieldRequired()}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().surname}
                                name={'surname'}
                                initialValue={getFieldInitialValue('surname')}
                                rules={[
                                    {
                                        required: isContactFieldRequired(),
                                        message: translations().surnameRequired
                                    },
                                    {
                                        validator: async (rule, value) => {
                                            if (validateForWhiteSpace(value)) {
                                                throw new Error(translations().noWhiteSpace)
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input placeholder={translations().surname} disabled={!isContactFieldRequired()}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row> }
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
