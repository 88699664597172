import React from 'react'
import { Col, Form } from 'antd'
import { Input } from '~/code/common/components'
import translations from '~/code/POS/pages/NewOrder/components/123send/AddProductForm/translations/translations'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { countInArray } from '~/code/POS/pages/NewOrder/services/utils'

interface AcquirerAssignedTidListProps {
    totalTIDsList: string[]
    acquirerAssignedTids: AcquirerAssignedTid[]
    acquirerAssignedTIDValidateStatus: ValidateStatus[]
    handleAcquirerAssignedTidChange: (e, index) => void
    setIsTIDDuplicateExist: (isTIDDuplicateExist: boolean) => void
    onTIDBlur: (index) => void
    sizes?: {
        xs: number
        sm: number
    }
    required?: boolean
    isPartnerFlow?: boolean
}

export const AcquirerAssignedTidList = ((props: AcquirerAssignedTidListProps) => {
    const {
        acquirerAssignedTids, acquirerAssignedTIDValidateStatus, totalTIDsList,
        handleAcquirerAssignedTidChange, onTIDBlur, sizes = { xs: 24, sm: 12 },
        setIsTIDDuplicateExist, required = false, isPartnerFlow = false
    } = props

    return (
        <>
            {
                acquirerAssignedTids.map((acquirerTid, index) => (
                    <Col key={`acquirerAssignedTids-${acquirerTid.uid}`} xs={sizes.xs} sm={sizes.sm}>
                        <Form.Item
                            label={isPartnerFlow ? translations().acquirerIssuedTid : translations().acquirerAssignedTid}
                            validateTrigger={['onChange', 'onBlur']}
                            validateStatus={acquirerAssignedTIDValidateStatus[index]}
                            name={`acquirerAssignedTids-${acquirerTid.uid}`}
                            rules={[
                                {
                                    required,
                                    message: translations().acquirerAssignedTidRequired
                                },
                                {
                                    validator: async (rule, value) => {
                                        if (value && value.length !== 8) {
                                            throw new Error(translations().invalidDNAPaymentsTIDLength)
                                        }
                                    }
                                },
                                {
                                    validator: async (rule, value) => {
                                        if (value && countInArray<string>(totalTIDsList, value) > 1) {
                                            setIsTIDDuplicateExist(true)
                                            throw new Error(translations().invalidDNAPaymentsTIDDuplicate)
                                        }

                                        setIsTIDDuplicateExist(false)
                                    }
                                }
                            ]}
                        >
                            <Input
                                onChange={e => handleAcquirerAssignedTidChange(e, index)}
                                placeholder={translations().acquirerAssignedTid}
                                onBlur={() => onTIDBlur(index)}
                                value={acquirerTid.tid}
                                name="tid"
                            />
                        </Form.Item>
                    </Col>
                ))
            }
        </>
    )
})
