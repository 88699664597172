// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3A5KJdG5Jqf_DIHrm14IiZ .NWoyfVHxPXgkY374l7Sfz {\n  padding-top: 16px; }\n\n._3A5KJdG5Jqf_DIHrm14IiZ ._3SOBn6nH1NuJfEMnZAc8u0 {\n  padding-top: 16px;\n  display: flex;\n  flex-flow: row-reverse; }\n", ""]);
// Exports
exports.locals = {
	"FormNavigation": "_3A5KJdG5Jqf_DIHrm14IiZ",
	"goBackButtonContainer": "NWoyfVHxPXgkY374l7Sfz",
	"submitButtonContainer": "_3SOBn6nH1NuJfEMnZAc8u0"
};
module.exports = exports;
