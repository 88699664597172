import { patchWithAuth } from '~/code/services/authorised-requests'
import { checkODINHTTPStatus } from '~/code/services/http-response-handler'

export const fetchPatchRegex = (id: number, regex: string) =>
    checkODINHTTPStatus(patchWithAuth(`/odin-api/api/Packages/${id}`, [
        {
            'op': 'replace',
            'path': '/VersionNumberRegex',
            'value': regex
        }
    ]))
