// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._4gPS8_HkDzP6G-Rn5PFo8 {\n  margin: 0px 5px 0px  0px; }\n", ""]);
// Exports
exports.locals = {
	"space": "_4gPS8_HkDzP6G-Rn5PFo8"
};
module.exports = exports;
