// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LKiicIKSqb95r70Wp2lwj {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 30px 0px; }\n  .LKiicIKSqb95r70Wp2lwj ._26cmw8G1RYOwFL0tAI8UlC {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 32px;\n    line-height: 48px;\n    color: #000000; }\n  .LKiicIKSqb95r70Wp2lwj ._3oUYG5-0W4lPASUua17FFX {\n    width: 48px;\n    height: 48px;\n    font-size: 48px;\n    line-height: 48px;\n    margin-top: 24px;\n    margin-bottom: 24px; }\n    .LKiicIKSqb95r70Wp2lwj ._2hnSMSTiYqU_eowlsvDwRX {\n      color: #00A0DC; }\n    .LKiicIKSqb95r70Wp2lwj ._3zVsDLUaYJcbCzJ0hkHE2M {\n      color: #00C81A; }\n    .LKiicIKSqb95r70Wp2lwj ._2fF7yVT1CGxC6xk_hq-SyJ {\n      color: #F53777; }\n  .LKiicIKSqb95r70Wp2lwj .anSNPaLIjq3Yz9MUdlF3H {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 36px;\n    color: #8C8C8C;\n    text-align: center; }\n  .LKiicIKSqb95r70Wp2lwj ._3sb1csYS4YNjyJWunEOxvf {\n    width: 100%;\n    padding-top: 50px;\n    text-align: center; }\n", ""]);
// Exports
exports.locals = {
	"LoadingView": "LKiicIKSqb95r70Wp2lwj",
	"title": "_26cmw8G1RYOwFL0tAI8UlC",
	"icon": "_3oUYG5-0W4lPASUua17FFX",
	"icon_loading": "_2hnSMSTiYqU_eowlsvDwRX",
	"icon_success": "_3zVsDLUaYJcbCzJ0hkHE2M",
	"icon_failure": "_2fF7yVT1CGxC6xk_hq-SyJ",
	"message": "anSNPaLIjq3Yz9MUdlF3H",
	"buttonWrapper": "_3sb1csYS4YNjyJWunEOxvf"
};
module.exports = exports;
