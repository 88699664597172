import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    newOrder: string
    orderHistory: string
    userGuide: string
    faq: string
    contactUs: string
    selectCompany: string
    employeeFunctions: string
    createCheckoutV3Account: string
    deviceManagement: string
    applicationManagement: string
    firmwareManagement: string
    merchantSchedule: string
    noMerchantSelected: string
}>()

