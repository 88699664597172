import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { ProgressProps } from './props'
import styles from './Progress.scss'

export const Progress = observer((props: ProgressProps) => {
    const { store } = props
    let iconClass = ''
    switch (store.status) {
        case 'success': iconClass = styles.icon_success; break
        case 'failure': iconClass = styles.icon_failure; break
        default: iconClass = styles.icon_loading
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.Progress}>
            <div className={styles.title}>
                {store.title}
            </div>
            <div className={`${styles.icon} ${iconClass}`}>
                {store.icon}
            </div>
            <div className={styles.message}>
                { store.messages.map((message, i) => {
                    return <div key={i}>{ message }</div>
                }) }
            </div>
            {
                store.status !== 'loading' &&
                    <div className={styles.buttonWrapper}>
                        {store.actionsContent}
                    </div>
            }
        </div>
    )
})
