// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iW7DNZwNwVy6bQrsYLMpw {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 14px;\n  line-height: 21px;\n  color: #00A0DC; }\n  .iW7DNZwNwVy6bQrsYLMpw:hover {\n    cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"PlainButton": "iW7DNZwNwVy6bQrsYLMpw"
};
module.exports = exports;
