import React from "react";
const SvgCompany = props => (
  <svg width="1em" height="1em" viewBox="0 0 512 509" {...props}>
    <g fill="#00A0DC" fillRule="nonzero">
      <path d="M293.333 509h-256C16.747 509 0 492.253 0 471.667V38.195C0 27.187 4.821 16.819 13.205 9.693 21.59 2.568 32.64-.483 43.456 1.352l234.432 35.52c18.325 3.05 31.445 18.539 31.445 36.864V493c0 8.832-7.168 16-16 16zM45.332 44a4.842 4.842 0 00-3.226 1.187c-.658.593-1.773 1.862-1.773 3.929v415.768c0 2.824 2.23 5.116 4.979 5.116h224.021V83.205c0-2.516-1.752-4.645-4.161-5.054L46.347 44.102A6.087 6.087 0 0045.332 44z" />
      <path d="M474.667 509H293.333c-8.832 0-16-7.168-16-16V205c0-4.821 2.176-9.387 5.91-12.416 3.733-3.05 8.64-4.224 13.354-3.243l185.963 38.955c17.408 3.861 29.44 18.837 29.44 36.48v206.89C512 492.254 495.253 509 474.667 509zm-163.334-39h153.063c2.725 0 4.937-2.235 4.937-4.989V271.494c0-2.354-1.58-4.35-3.85-4.869L311.332 234v236zM203 501h-96c-8.832 0-16-7.168-16-16v-90.667C91 373.747 107.747 357 128.333 357h53.334C202.253 357 219 373.747 219 394.333V485c0 8.832-7.168 16-16 16zm-80-32h64v-74.667a5.335 5.335 0 00-5.333-5.333h-53.334a5.335 5.335 0 00-5.333 5.333V469zM117 133H85c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM223.667 133h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM117 197H85c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM223.667 197h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM117 261H85c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM223.667 261h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM117 325H85c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM223.667 325h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16z" />
      <path d="M407 316h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM407 380h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16zM407 444h-32c-8.832 0-16-7.168-16-16s7.168-16 16-16h32c8.832 0 16 7.168 16 16s-7.168 16-16 16z" />
    </g>
  </svg>
);
export default SvgCompany;
