import { action, computed, makeObservable, observable } from 'mobx'
import { SelectItem } from '~/code/common/components'
import { isEmpty } from 'dna-common'
import { fetchPreferredSubmissions } from '~/code/stores/NewOrderStore/services/fetchers'

export class PreferredSubmissionsStore {

    constructor () {
        makeObservable(this, {
            _items: observable,
            _isLoading: observable,
            preferredSubmissions: computed,
            load: action
        })
    }

    _items: SelectItem[] = []
    _isLoading: boolean = false

    get preferredSubmissions() {
        this.load()
        return this._items
    }

    async load() {
        if (this._isLoading || !isEmpty(this._items)) { return }
        this._isLoading = true
        this._items = await fetchPreferredSubmissions()
        this._isLoading = false
    }

}
