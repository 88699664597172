import translations from './translations'

translations.add('en', {
    penniesDonationActiveText: 'Pennies donation active text',
    penniesDonationInactiveText: 'Pennies donation inactive text',
    donationPromptText: 'Donation Prompt Text',
    donationSelectedText: 'Donation selected text',
    status: 'Status',
    charity: 'Charity',
    charityNumber: 'Charity Number',
    donationMode: 'Donation Mode',
    adjustmentBanding: 'Adjustment Banding',
    ceilingAmount: 'Ceiling Amount',
    ceilingAmountInvalid: 'Ceiling Amount is invalid',
    floorLimit: 'Floor Limit',
    floorLimitInvalid: 'Floor Limit is invalid',
    adjustmentValue: 'Adjustment value',
    adjustmentValueInvalid: 'Adjustment Value is invalid',
    charityImage: 'Charity Image',
    uploadNewImage: 'Upload New Image',
    isPenniesUsed: 'I want to use Pennies'
})
