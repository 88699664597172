import { v4 as uuid } from 'uuid'
import { ONE_TWO_THREE_SEND_PRODUCT_BUNDLES } from '~/code/POS/pages/NewOrder/services/constants'
import { AcquirerAssignedTid } from '~/code/stores/NewOrderStore/models/AcquirerAssignedTid'
import { ACQUIRER_IDS, validateTID } from '~/code/services'
import { isEmpty } from 'dna-common'
import { ValidateStatus } from 'antd/lib/form/FormItem'

const _A920_24_MONTH_POUND_STERLING = 28.95
const _A920_36_MONTH_POUND_STERLING = 19.95
const _A920_48_MONTH_POUND_STERLING = 18.95

export const getOTTCustomBundlePriceValue = (bundle) => {
    if (!bundle?.bundleCode) {
        return null
    }

    if (bundle.bundleCode.includes(ONE_TWO_THREE_SEND_PRODUCT_BUNDLES.A920_24_MONTH)) {
        return _A920_24_MONTH_POUND_STERLING
    }

    if (bundle.bundleCode.includes(ONE_TWO_THREE_SEND_PRODUCT_BUNDLES.A920_36_MONTH)) {
        return _A920_36_MONTH_POUND_STERLING
    }

    if (bundle.bundleCode.includes(ONE_TWO_THREE_SEND_PRODUCT_BUNDLES.A920_48_MONTH)) {
        return _A920_48_MONTH_POUND_STERLING
    }

    return ''
}

export function acquirerTidHelpers (
    {
        acquirerAssignedTids,
        acquirerAssignedTIDValidateStatus,
        setAcquirerAssignedTids,
        setAcquirerAssignedTIDValidateStatus,
        isOTTFlow = false,
        selectedDepartment
    }) {
    const handleAcquirerAssignedTidChange = (e, index) => {
        const {name, value} = e.target
        const newAcquirerAssignedTids = [...acquirerAssignedTids]
        newAcquirerAssignedTids[index][name] = value

        setAcquirerAssignedTids(newAcquirerAssignedTids)

        onTIDBlur(index)
    }

    const initiateAcquirerAssignedTids = (value: number) => {
        setAcquirerAssignedTids([])
        const tempAcquirerAssignedTids = []

        for (let i = 0; i < value; i++) {
            tempAcquirerAssignedTids.push({uid: uuid(), tid: ''})
        }

        setAcquirerAssignedTids(tempAcquirerAssignedTids)
    }

    const onTIDBlur = index => {
        const acquirerId = isOTTFlow ? selectedDepartment?.acquirer || ACQUIRER_IDS.DNA_PAYMENTS : ACQUIRER_IDS.DNA_PAYMENTS
        const _tid = acquirerAssignedTids[index]['tid']
        const warning = validateTID(_tid, acquirerId)
        const newAcquirerAssignedTIDValidateStatus = [...acquirerAssignedTIDValidateStatus]

        newAcquirerAssignedTIDValidateStatus[index] = isEmpty(warning) ? undefined : 'warning'

        setAcquirerAssignedTIDValidateStatus(newAcquirerAssignedTIDValidateStatus)
    }

    return {handleAcquirerAssignedTidChange, initiateAcquirerAssignedTids, onTIDBlur}
}

export function countInArray<T>(array: T[], value: T) {
    return array.filter(item => item === value).length
}
