import React from "react";
const SvgRedo = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 16" {...props}>
    <defs>
      <path
        d="M10.092 2C7.055 2 4.587 4.69 4.587 8H7.34l-3.67 4L0 8h2.752c0-4.42 3.285-8 7.34-8 1.44 0 2.78.46 3.908 1.24L12.66 2.7a5.037 5.037 0 00-2.568-.7zm2.569 6l3.67-4L20 8h-2.752c0 4.42-3.285 8-7.34 8-1.44 0-2.78-.46-3.908-1.24l1.34-1.46c.76.45 1.642.7 2.568.7 3.037 0 5.505-2.69 5.505-6H12.66z"
        id="redo_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="redo_svg__b" fill="#fff">
        <use xlinkHref="#redo_svg__a" />
      </mask>
      <path
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#redo_svg__b)"
        d="M-2-4h24v24H-2z"
      />
    </g>
  </svg>
);
export default SvgRedo;
