import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    newBundle: string
    newStoreAndDepartment: string
    newDepartment: string
    orderType: string
    orderTypeRequired: string
    pointInformation: string
    storeAndDepartmentInfo: string
    store: string
    storeRequired: string
    storeId: string
    storeAddressLine1: string
    storePostcode: string
    departmentId: string
    visaMid: string
    mastercardMid: string
    amexMid: string
    dinersMid: string
    unionPayMid: string
    aliPayMid: string
    flexecashMid: string
    departmentType: string
    department: string
    departmentRequired: string
    nextStep: string
}>()
