import React from 'react'
import { ModalProps } from './props'
import { Modal as AntModal } from 'antd'
import styles from './Modal.scss'
import classNames from 'classnames'

export const Modal = (props: ModalProps) => {
    const { children, visible, title, onOk, onCancel, footer, type, smallerWidth } = props

    return <AntModal
        centered
        visible={visible}
        title={title}
        onOk={onOk}
        onCancel={onCancel}
        footer={footer}
        className={classNames({[styles.modal]: type === 'info', [styles.modal__smallerWidth]: smallerWidth })}
    >
        {children}
    </AntModal>
}