import React from 'react'
import translations from './translations'

translations.add('en', {
    category: 'Category',
    details: 'Details',
    supportedDeviceTypes: 'Supported Device Types',
    uploadedDateTime: 'Uploaded Date Time',
    appSignedBy: 'App Signed By',
    needToFileUpload: 'Please upload version APK file',
    applicationCreatedSuccessfully: 'Application Created Successfully'
})
