import translations from './translations'

translations.add('en', {
    orderStatusErrorCodes: {
        '1': 'Mandatory Field Missing',
        '2': 'Company Details & Account Number Do Not Match',
        '3': 'Duplicate Store Names or IDs in Order(s)',
        '4': 'Invalid Format',
        '5': 'Issue Processing Order - Please Contact Optomany',
        '6': 'Issue Processing Order - Please Contact Optomany',
        '7': 'Issue Processing Order - Please Contact Optomany',
        '8': 'Issue Processing Order - Please Contact Optomany',
        '9': 'Issue Processing Order - Please Contact Optomany',
        '10': 'Issue Processing Order - Please Contact Optomany',
        '11': 'Issue Processing Order - Please Contact Optomany',
        '12': 'Contact Optomany',
        '13': 'Bundle Selection Invalid - Should the order have been processed as an "existing merchant" order?',
        '14': 'Invalid Order Configuration',
        '15': 'Ordering Currently Disabled',
        '16': 'Invalid Order Configuration',
        '17': 'Invalid Order Configuration - Bundle Type Mismatch',
        '18': 'Issue Processing Order - Please Contact Optomany',
        '19': 'Order Rejected Due to an Attempt to Create a Duplicate Merchant.  Order needs to be processed as an "existing merchant" order',
        '20': 'Duplicate Company Registration Number',
        '21': 'Issue Processing Order - Please Contact Optomany',
        '22': 'Issue Processing Order - Please Contact Optomany',
        '23': 'Issue Processing Order - Please Contact Optomany',
        '24': 'Issue Processing Order - Please Contact Optomany',
        '25': 'Merchant Store and/or Department Name Not Unique',
        '26': 'Issue Processing Order - Please Contact Optomany',
        '27': 'Bundle Selected Incompatible with Chosen Acquirer, please contact Optomany',
        '28': 'Issue Processing Order - Please Contact Optomany',
        '29': 'Issue Processing Order - Please Contact Optomany',
        '30': 'Invalid Acquirer for Scheme, please contact Optomany',
        '31': 'Invalid TID(s) Supplied, please re-try or contact Optomany for assistance',
        '32': 'Invalid Acquirer For Bundle'
    },
    fetchError: 'Could not fetch order status',
    cancelOrderSuccessMsg: 'Cancelled Order Successfully',
    cancelOrderErrMsg: 'Order Cannot be Cancelled - Please Contact Optomany',
    cancelling: 'Cancelling',
    notYetDispatched: 'Not Yet Dispatched'
})
