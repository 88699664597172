import { computed, makeObservable } from 'mobx'
import { IAppStore } from '~/code/IAppStore'
import { AllApplicationsStore } from '~/code/ODIN/stores/ApplicationManagementStore/AllApplicationsStore'
import { IAllApplicationsStore } from '~/code/ODIN/components/AllApplications/IAllApplicationsStore'
import { PackageType } from '~/code/ODIN/stores/ApplicationManagementStore/models/PackageType'
import { AddNewApplicationStore } from '~/code/ODIN/stores/ApplicationManagementStore/AddNewApplicationStore'
import { IAddNewApplicationStore } from '~/code/ODIN/pages/ApplicationManagement/components/AddNewApplication/IAddNewApplicationStore'
import { IFirmwareManagementStore } from '~/code/ODIN/pages/FirmwareManagement/IFirmwareManagementStore'

export class FirmwareManagementStore implements IFirmwareManagementStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            permissions: computed
        })

        this.parentStore = parentStore
        this.allAppsStore = new AllApplicationsStore(parentStore, PackageType.Firmware)
        this.addNewFirmwareStore = new AddNewApplicationStore(PackageType.Firmware)
    }

    public get permissions(): string[] {
        return this.parentStore.userInfo?.permissions
    }

    parentStore: IAppStore
    allAppsStore: IAllApplicationsStore
    addNewFirmwareStore: IAddNewApplicationStore
}
