import { action, makeObservable, observable, runInAction } from 'mobx'
import { error } from 'dna-common'
import { IAppStore } from '~/code/IAppStore'
import { fetchNotificationPriorities } from '~/code/ODIN/stores/DeviceManagementDetailsStore/services/fetchers'
import { NotificationPriority } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/NotificationPriority'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'
import { notification } from 'antd'
import commonTranslations from '~/code/translations/translations'

export class NotificationPriorityStore {
    constructor (parentStore: IAppStore) {
        makeObservable(this, {
            isLoading: observable,
            notificationPriorities: observable,
            setNotificationPriorities: action,
            loadNotificationPriorities: action
        })

        this.parentStore = parentStore
    }

    parentStore: IAppStore
    isLoading: boolean = false
    notificationPriorities: NotificationPriority[] = []

    setNotificationPriorities = (notificationPriorities: NotificationPriority[]) => {
        this.notificationPriorities = notificationPriorities
    }

    public loadNotificationPriorities = () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        fetchNotificationPriorities()
            .then(response => {
                this.setNotificationPriorities(response.result.map((item) => ({...item, key: item.priorityId})))
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }
}
