import React from 'react'
import translations from './translations'

translations.add('en', {
    cancel: 'Cancel',
    initiated: 'Initiated',
    appName: 'App Name',
    requestedBy: 'Requested By',
    notSent: 'Not Sent',
    sent: 'Sent',
    delivered: 'Delivered',
    errored: 'Errored',
    all: 'All',
    deliveryStatusName: 'Status',
    errorDetails: 'Errors',
    notificationType: 'Notification Type'
})
