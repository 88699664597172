import React from 'react'
import translations from './translations'

translations.add('en', {
    bundle: 'Bundle',
    description: 'Description',
    quantity: 'Quantity',
    customPrice: 'Custom price',
    acquirerAssignedTids: 'Acquirer Assigned TIDs',
    edit: 'Edit',
    delete: 'Delete'
})
