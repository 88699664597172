import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Spin, Form } from 'antd'
import { ACQUIRER_IDS, getFieldValue } from '~/code/services'
import { digitsToSortCode } from '~/code/POS/pages'
import { Input, Select, Checkbox, LabelWithTooltip } from '~/code/common/components'
import { BANK_ACCOUNT_NUMBER_PATTERN, BANK_ACCOUNT_NUMBER_TYPING_PATTERN, MERCHANT_ID_TYPING_PATTERN, SORT_CODE_PATTERN, SORT_CODE_TYPING_PATTERN, STREAMLINE_COMPANY_ID_PATTERN, STREAMLINE_COMPANY_ID_TYPING_PATTERN, TWO_DECIMAL_POINTS_NUMBER_PATTERN, VALID_NAME_PATTERN } from '~/code/models/Patterns'
import { validateMerchantId } from '~/code/services'
import { WORLDPAY_ID } from './models'
import translations from './translations'
import { ProcessingDetailsFormProps } from './props'
import styles from './ProcessingDetailsForm.scss'
import { UK_CURRENCY_NUMBER_CODE } from '~/code/models'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { isEmpty } from 'dna-common'

export const ProcessingDetailsForm = observer(({ store}: ProcessingDetailsFormProps) => {
    const {
        processingDetailsFormData: data,
        setProcessingDetailsFormData,
        clearAmexData,
        acquirers,
        currencies,
        preferredSubmissions,
        brandingOrganisations,
        merchantCategoryCodes,
        clearStreamlineCompanyId,
        canGoBack,
        goBack
    } = store

    const [form] = Form.useForm()

    const [isCashBackChecked, setCashBackChecked] = useState(data.isCashBackChecked)
    const [isGratuityChecked, setGratuityChecked] = useState(data.isGratuityChecked)
    const [isAmexDataRequired, setAmexDataRequired] = useState(!!(data.amexMerchantId))
    const [merchantIdHelp, setMerchantIdHelp] = useState(validateMerchantId(data.merchantId, data.acquirer))
    const [merchantIdValidateStatus, setMerchantIdValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(merchantIdHelp) ? undefined : 'warning')

    const [amexMerchantIdHelp, setAmexMerchantIdHelp] = useState(validateMerchantId(data.amexMerchantId, data.amexAcquirer))
    const [amexMerchantIdValidateStatus, setAmexMerchantIdValidateStatus] = useState<'' | 'success' | 'warning' | 'error' | 'validating'>(isEmpty(merchantIdHelp) ? undefined : 'warning')

    const setMerchantIdStatus = (merchantId: string) => {
        const warning = validateMerchantId(merchantId, form.getFieldValue('acquirer'))
        setMerchantIdHelp(warning)
        setMerchantIdValidateStatus(isEmpty(warning) ? undefined : 'warning')
    }

    const setAmexMerchantIdStatus = (merchantId: string) => {
        const warning = validateMerchantId(merchantId, form.getFieldValue('amexAcquirer'))
        setAmexMerchantIdHelp(warning)
        setAmexMerchantIdValidateStatus(isEmpty(warning) ? undefined : 'warning')
    }

    const onFinish = (values) => {
        if (values.sortCode.indexOf('-') >= 0 ) {
            values.sortCode = values.sortCode.replace(/-/g, '')
        }
        if (values.amexSortCode?.indexOf('-') >= 0 ) {
            values.amexSortCode = values.amexSortCode.replace(/-/g, '')
        }
        if (isEmpty(values.amexMerchantId)) {
            values.amexAcquirer = undefined
        }
        setProcessingDetailsFormData(values)
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const fieldChecked = (fieldName: string) => {
        form.resetFields([fieldName])
        form.setFieldsValue({[fieldName]: ''})
    }

    const onCashBackCheck = (checked: boolean) => {
        fieldChecked('cashBack')
        setCashBackChecked(checked)
    }

    const onGratuityCheck = (checked: boolean) => {
        fieldChecked('gratuity')
        setGratuityChecked(checked)
    }

    const onAmexMerchantIdChange = (event) => {
        const value = getValidAmexMerchantIdValue(event.target.value)
        setAmexDataRequired(!!value)
        if (!value) {
            clearAmexData()
            form.resetFields(['amexBankAccountNumber', 'amexSortCode', 'amexAcquirer'])
        } else {
            form.setFieldsValue({
                amexBankAccountNumber: form.getFieldValue('bankAccountNumber'),
                amexSortCode: form.getFieldValue('sortCode')
            })
        }
    }

    const getValidAmexMerchantIdValue = (value) => {
        const test = MERCHANT_ID_TYPING_PATTERN.test(value)
        if (!test) {
            return form.getFieldValue('amexMerchantId')
        }

        return value
    }

    let amexDefaultValue = !isEmpty(acquirers.slice()) ? 4 : null

    // set currency's initial value when the list of currencies is fetched
    useEffect(() => {
        if (!isEmpty(currencies.slice())) {
            const currency = getFieldInitialValue('currency')
            const isCurrencyExist = Boolean(currency && currencies.find((c) => Number(c.value) === Number(currency)))
            const currencyInitialValue = isCurrencyExist ? currency : UK_CURRENCY_NUMBER_CODE

            form.setFieldsValue({
                currency: currencyInitialValue
            })
        }
    }, [currencies, getFieldInitialValue('currency')])

    // set preferred submission's initial value when the list of preferred submissions is fetched
    useEffect(() => {
        let submissionDefaultValue = getFieldInitialValue('preferredSubmission')
        submissionDefaultValue = isEmpty(preferredSubmissions) ? null :
            isEmpty(submissionDefaultValue) ? preferredSubmissions[0].value :
                Number(submissionDefaultValue)

        form.setFieldsValue({
            preferredSubmission: submissionDefaultValue
        })
    }, [preferredSubmissions])

    // set branding organisation's initial value when the list of branding organisations is fetched
    useEffect(() => {
        const brandingOrganisationIdInitialValue = (isEmpty(brandingOrganisations) ? null :
            getFieldInitialValue('brandingOrganisationId', brandingOrganisations.slice()[0].value))
        form.setFieldsValue({
            brandingOrganisationId: brandingOrganisationIdInitialValue
        })
    }, [brandingOrganisations])

    // set merchant category code's initial value when the list of merchant category codes is fetched
    useEffect(() => {
        const merchantCategoryCodeInitialValue = isEmpty(merchantCategoryCodes) ? null : getFieldInitialValue('merchantCategoryCode')
        form.setFieldsValue({
            merchantCategoryCode: merchantCategoryCodeInitialValue
        })
    }, [merchantCategoryCodes])

    // set acquirer's initial value when the list of acquirers is fetched
    useEffect(() => {
        let acquirerInitialValue = getFieldInitialValue('acquirer')
        acquirerInitialValue = isEmpty(acquirers) || isEmpty(acquirerInitialValue) ? null : Number(acquirerInitialValue)
        amexDefaultValue = !isEmpty(acquirers.slice()) ? 4 : null
        form.setFieldsValue({
            acquirer: acquirerInitialValue,
            amexAcquirer: getFieldInitialValue('amexAcquirer', amexDefaultValue)
        })
    }, [acquirers])

    const onAcquirerChange = () => {
        clearStreamlineCompanyId()
        form.resetFields(['streamlineCompanyId'])
    }

    const onAcquirerSelect = () => {
        setMerchantIdStatus(form.getFieldValue('merchantId'))
    }

    return (
        <div className={styles.ProcessingDetailsForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row justify={'space-between'}>
                    <Col xs={24} sm={10}>
                        <div>
                            <Form.Item
                                label={ <LabelWithTooltip label={translations().acquirer} tooltipText={translations().acquirerTooltip} tooltipPosition={'right'} />}
                                name={'acquirer'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().acquirerRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().acquirer}
                                           showSearch={true}
                                           filterOption={(input, option) => {
                                                    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                           }
                                           notFoundContent={<Spin size={'small'}/>}
                                           // onFocus={() => { loadAcquirers() }}
                                           onChange={onAcquirerChange}
                                           onSelect={onAcquirerSelect}
                                           selectItems={acquirers.slice()}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={translations().merchantId}
                                validateStatus={merchantIdValidateStatus}
                                help={merchantIdHelp}
                                name={'merchantId'}
                                initialValue={getFieldInitialValue('merchantId')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const phoneRegexp = MERCHANT_ID_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = phoneRegexp.test(value)
                                    if (!test) {
                                        return form.getFieldValue('merchantId')
                                    }
                                    setMerchantIdStatus(value)
                                    return value
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().merchantIdRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().merchantId}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().merchantCategoryCode} tooltipText={translations().merchantCategoryCodeTooltip} tooltipPosition={'right'} />}
                                name={'merchantCategoryCode'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().merchantCategoryCodeRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().merchantCategoryCode}
                                           showSearch={true}
                                           filterOption={(input, option) => {
                                                    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                           }
                                           notFoundContent={<Spin size={'small'}/>}
                                           // onFocus={() => { loadMerchantCategoryCodes() }}
                                           selectItems={merchantCategoryCodes.slice()}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().currency} tooltipText={translations().currencyTooltip} tooltipPosition={'right'} />}
                                name={'currency'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().currencyRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().currency}
                                           showSearch={true}
                                           filterOption={(input, option) => {
                                                    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                           }
                                           notFoundContent={<Spin size={'small'}/>}
                                           // onFocus={() => { loadCurrencies() }}
                                           selectItems={currencies.slice()}/>
                            </Form.Item>
                        </div>
                        <Row gutter={16}>
                            <Col xs={14}>
                                <div>
                                    <Form.Item
                                        label={translations().accountNumber}
                                        name={'bankAccountNumber'}
                                        initialValue={getFieldInitialValue('bankAccountNumber')}
                                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                            const phoneRegexp = BANK_ACCOUNT_NUMBER_TYPING_PATTERN
                                            const value = e.currentTarget.value
                                            const test = phoneRegexp.test(value)
                                            if (!test) {
                                                return form.getFieldValue('bankAccountNumber')
                                            }
                                            return value
                                        }}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations().accountNumberRequired
                                            },
                                            {
                                                pattern: BANK_ACCOUNT_NUMBER_PATTERN,
                                                message: translations().accountNumberInvalid
                                            }
                                        ]}
                                    >
                                        <Input placeholder={translations().accountNumber}/>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={10}>
                                <div>
                                    <Form.Item
                                        label={translations().sortCode}
                                        name={'sortCode'}
                                        initialValue={digitsToSortCode(getFieldInitialValue('sortCode'))}
                                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                            const phoneRegexp = SORT_CODE_TYPING_PATTERN
                                            const value = e.currentTarget.value.replace(/-/g, '')
                                            const test = phoneRegexp.test(value)
                                            if (!test) {
                                                return form.getFieldValue('sortCode')
                                            }
                                            return value
                                        }}
                                        rules={[
                                            {
                                                required: true,
                                                message: translations().sortCodeRequired
                                            },
                                            {
                                                pattern: SORT_CODE_PATTERN,
                                                message: translations().sortCodeInvalid
                                            }
                                        ]}
                                    >
                                        <Input type={'masked'} className={'ant-input'} mask={'99-99-99'} placeholder={translations().sortCode}/>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.checkBoxContainer}>
                                    <Form.Item
                                        name={'isCashBackChecked'}
                                        initialValue={isCashBackChecked}
                                        valuePropName={'checked'}
                                        rules={[
                                            {
                                                transform: checked => (checked || undefined),
                                                type: 'boolean'
                                            }
                                        ]}
                                    >
                                        <Checkbox onChange={(e) => onCashBackCheck(e.target.checked)}>
                                            {translations().enableCashBack}
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div>
                                    <Form.Item
                                        label={translations().cashBack}
                                        name={'cashBack'}
                                        initialValue={getFieldInitialValue('cashBack')}
                                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                            const value = e.currentTarget.value
                                            let tempValue = value.replace(/ /g, '')
                                            if (value.includes('.')) {
                                                const length = tempValue.length < tempValue.indexOf('.') + 3 ? tempValue.length : tempValue.indexOf('.') + 3
                                                tempValue = tempValue.substr(0, length)
                                            }

                                            const convertedValue = Number(tempValue)
                                            if (isNaN(convertedValue)) {
                                                return form.getFieldValue('cashBack')
                                            }
                                            if ((!isNaN(convertedValue) && (tempValue.endsWith('.') || tempValue.endsWith('.0') || tempValue.endsWith('.00')) )) {
                                                return tempValue
                                            }
                                            return convertedValue
                                        }}
                                        rules={[
                                            {
                                                required: isCashBackChecked,
                                                message: translations().cashBackRequired
                                            },
                                            {
                                                pattern: TWO_DECIMAL_POINTS_NUMBER_PATTERN,
                                                message: translations().cashBackInvalid
                                            },
                                            {
                                                validator: (async (rule, value) => {
                                                    if (value && !isNaN(value) && value > 100) {
                                                        throw new Error(translations().cashBackMaxExceeded)
                                                    }
                                                })
                                            }
                                        ]}
                                    >
                                        <Input placeholder={translations().cashBack} disabled={!isCashBackChecked}/>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className={styles.checkBoxContainer}>
                                    <Form.Item
                                        name={'isGratuityChecked'}
                                        initialValue={isGratuityChecked}
                                        valuePropName={'checked'}
                                        rules={[
                                            {
                                                transform: checked => (checked || undefined),
                                                type: 'boolean'
                                            }
                                        ]}
                                    >
                                        <Checkbox onChange={(e) => onGratuityCheck(e.target.checked)}>
                                            {translations().enableGratuity}
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div>
                                    <Form.Item
                                        label={translations().gratuity}
                                        name={'gratuity'}
                                        initialValue={getFieldInitialValue('gratuity')}
                                        rules={[
                                            {
                                                required: isGratuityChecked,
                                                message: translations().gratuityRequired
                                            }
                                        ]}
                                    >
                                        <Input placeholder={translations().gratuity} disabled={!isGratuityChecked}/>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                            {/*<Form.Item noStyle={true} shouldUpdate={true}>*/}
                            {/*    {() => {*/}
                            {/*        if (form.getFieldValue('acquirer') === ACQUIRER_IDS.DNA_PAYMENTS ) {*/}
                            {/*            return (*/}
                            {/*                <Row>*/}
                            {/*                    <Col xs={12}>*/}
                            {/*                        <Form.Item name={'preAuthorisation'} valuePropName={'checked'}*/}
                            {/*                        >*/}
                            {/*                            <Checkbox>{translations().preathorization}</Checkbox>*/}
                            {/*                        </Form.Item>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            )*/}
                            {/*        } else return <></>*/}
                            {/*    }}*/}
                            {/*</Form.Item>*/}

                    </Col>
                    <Col xs={24} sm={10}>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().streamlineCompanyId} tooltipText={translations().streamlineCompanyIdTooltip} tooltipPosition={'right'} />}
                                name={'streamlineCompanyId'}
                                initialValue={getFieldInitialValue('streamlineCompanyId')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const typingRegexp = STREAMLINE_COMPANY_ID_TYPING_PATTERN
                                    const value = e.currentTarget.value
                                    const test = typingRegexp.test(value)
                                    if (!test) {
                                        return form.getFieldValue('streamlineCompanyId')
                                    }
                                    return value
                                }}
                                rules={[
                                    {
                                        required: form.getFieldValue('acquirer') === WORLDPAY_ID,
                                        message: translations().streamlineCompanyIdRequired
                                    },
                                    {
                                        pattern: STREAMLINE_COMPANY_ID_PATTERN,
                                        message: translations().streamlineCompanyIdInvalid
                                    }
                                ]}
                            >
                                <Input placeholder={translations().streamlineCompanyId} disabled={form.getFieldValue('acquirer') !== WORLDPAY_ID}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().preferredSubmission} tooltipText={translations().preferredSubmissionTooltip} tooltipPosition={'right'} />}
                                name={'preferredSubmission'}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().preferredSubmissionRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().preferredSubmission}
                                           showSearch={true}
                                           filterOption={(input, option) => {
                                                    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                           }
                                           notFoundContent={<Spin size={'small'}/>}
                                           // onFocus={() => { loadPreferredSubmissions() }}
                                           selectItems={preferredSubmissions.slice()}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().accountName} tooltipText={translations().accountNameTooltip} tooltipPosition={'right'} />}
                                name={'accountName'}
                                initialValue={getFieldInitialValue('accountName')}
                                getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                    const regex = VALID_NAME_PATTERN
                                    const value = e.currentTarget.value
                                    const test = regex.test(value)
                                    if (!test) {
                                        return form.getFieldValue('accountName')
                                    }
                                    return value.replace(/\s{2,}/gi, ' ').replace(/^\s+/, '')
                                }}
                                rules={[
                                    {
                                        required: false,
                                        message: translations().accountNameRequired
                                    }
                                ]}
                            >
                                <Input placeholder={translations().accountName}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label={<LabelWithTooltip label={translations().brandingOrganisation} tooltipText={translations().brandingOrganisationTooltip} tooltipPosition={'right'} />}
                                name={'brandingOrganisationId'}
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                <Select placeholder={translations().brandingOrganisation}
                                        allowClear
                                        disabled={isEmpty(brandingOrganisations?.slice())}
                                        showSearch={true}
                                        filterOption={(input, option) => {
                                           return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        notFoundContent={<Spin size={'small'}/>}
                                        // onFocus={() => { loadBrandingOrganisations() }}
                                        selectItems={brandingOrganisations.slice()}/>
                            </Form.Item>
                        </div>
                        <div className={styles.amexContainer}>
                            <div className={styles.amexTitle}>
                                {translations().americanExpress}
                            </div>
                            <div>
                                <Form.Item
                                    label={<LabelWithTooltip label={translations().merchantId} tooltipText={translations().amexMerchantIdTooltip} tooltipPosition={'right'} />}
                                    validateStatus={amexMerchantIdValidateStatus}
                                    help={amexMerchantIdHelp}
                                    name={'amexMerchantId'}
                                    initialValue={getFieldInitialValue('amexMerchantId')}
                                    getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = getValidAmexMerchantIdValue(e.currentTarget.value)
                                        setAmexMerchantIdStatus(value)
                                        return value
                                    }}
                                    rules={[
                                        {
                                            required: false,
                                            message: translations().merchantIdRequired
                                        }
                                    ]}
                                >
                                    <Input placeholder={translations().merchantId} onChange={onAmexMerchantIdChange}/>
                                </Form.Item>
                            </div>
                            <div>
                                {
                                    isAmexDataRequired ?
                                        <Form.Item
                                            label={translations().acquirer}
                                            name={'amexAcquirer'}
                                            initialValue={getFieldInitialValue('amexAcquirer', amexDefaultValue)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: translations().acquirerRequired
                                                }
                                            ]}
                                        >
                                            <Select placeholder={translations().acquirer}
                                                       showSearch={true}
                                                       filterOption={(input, option) => {
                                                           return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                       }}
                                                       selectItems={acquirers.slice()}
                                                       disabled={true}/>
                                        </Form.Item>
                                        :
                                        <Form.Item
                                            label={translations().acquirer}
                                            name={'amexAcquirer'}
                                            initialValue={null}
                                        >
                                            <Select placeholder={translations().acquirer}
                                                       showSearch={true}
                                                       filterOption={(input, option) => {
                                                           return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                       }
                                                       }
                                                       selectItems={acquirers.slice()}
                                                       disabled={true}/>
                                        </Form.Item>
                                }
                            </div>
                            <Row gutter={16}>
                                <Col xs={14}>
                                    <div>
                                        <Form.Item
                                            label={translations().accountNumber} className={styles.lessMargin}
                                            name={'amexBankAccountNumber'}
                                            initialValue={getFieldInitialValue('amexBankAccountNumber')}
                                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                                const phoneRegexp = BANK_ACCOUNT_NUMBER_TYPING_PATTERN
                                                const value = e.currentTarget.value
                                                const test = phoneRegexp.test(value)
                                                if (!test) {
                                                    return form.getFieldValue('amexBankAccountNumber')
                                                }
                                                return value
                                            }}
                                            rules={[
                                                {
                                                    required: isAmexDataRequired,
                                                    message: translations().accountNumberRequired
                                                },
                                                {
                                                    pattern: BANK_ACCOUNT_NUMBER_PATTERN,
                                                    message: translations().accountNumberInvalid
                                                }
                                            ]}
                                        >
                                            <Input placeholder={translations().accountNumber} disabled={!isAmexDataRequired}/>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={10}>
                                    <div>
                                        <Form.Item
                                            label={translations().sortCode} className={styles.lessMargin}
                                            name={'amexSortCode'}
                                            initialValue={getFieldInitialValue('amexSortCode')}
                                            getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                                                const phoneRegexp = SORT_CODE_TYPING_PATTERN
                                                const value = e.currentTarget.value.replace(/-/g, '')
                                                const test = phoneRegexp.test(value)
                                                if (!test) {
                                                    return form.getFieldValue('amexSortCode')
                                                }
                                                return value
                                            }}
                                            rules={[
                                                {
                                                    required: isAmexDataRequired,
                                                    message: translations().sortCodeRequired
                                                },
                                                {
                                                    pattern: SORT_CODE_PATTERN,
                                                    message: translations().sortCodeInvalid
                                                }
                                            ]}
                                        >
                                            <Input  type={'masked'} className={'ant-input'} mask={'99-99-99'} placeholder={translations().sortCode} disabled={!isAmexDataRequired}/>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
