import React from "react";
const SvgPrevPage = props => (
  <svg
    id="prev-page_svg__\u0421\u043B\u043E\u0439_1"
    x={0}
    y={0}
    viewBox="0 0 1024 1024"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".prev-page_svg__st0{fill:#babac4}"}</style>
    <g id="prev-page_svg__Artboard">
      <g id="prev-page_svg__Group-3" transform="matrix(-1 0 0 1 884 -55)">
        <g id="prev-page_svg__Group-2">
          <path
            id="prev-page_svg__Rectangle-3"
            className="prev-page_svg__st0"
            d="M504.3 527.7c24.3 24 24.3 63 0 87L327.7 789.5c-24.3 24-63.7 24-88 0-24.3-24-24.3-63 0-87l176.7-174.7c24.2-24.1 63.6-24.1 87.9-.1z"
          />
          <path
            id="prev-page_svg__Rectangle-3-Copy"
            className="prev-page_svg__st0"
            d="M504.3 606.3c24.3-24 24.3-63 0-87L327.7 344.5c-24.3-24-63.7-24-88 0-24.3 24-24.3 63 0 87l176.7 174.7c24.2 24.1 63.6 24.1 87.9.1z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPrevPage;
