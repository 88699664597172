import React from 'react'
import translations from './translations'

translations.add('en', {
    placeOrder: 'New Order',
    merchantName: 'Merchant name',
    visaMID: 'Visa MID',
    accountNumber: 'Account number',
    newOrderForSoleTrader: 'Ordering for a new sole trader',
    newOrderForSoleTraderTooltip: 'Select this option if the order is for a business that is not registered with Companies House.',
    company: 'Company',
    soleTrader: 'Sole Trader',
    information: 'Information',
    orderType: 'Order Type',
    deliveryAddress: 'Delivery Address',
    processingDetails: 'Processing Details',
    placingOrder: 'Your order is being placed...',
    products: 'Products',
    merchantDetails: 'Merchant Details',
    storesAndDepartments: 'Stores & Departments',
    productList: 'Products',
    deliveryDetails: 'Delivery Details',
    orderDetails: 'Order Details',
    pleaseWait: 'Please wait',
    success: 'Success',
    orderHasBeenPlaced: 'Your order has been placed',
    trackOrderInOrderHistory: 'You can track the progress of your order in the "Order History" section',
    sorry: 'Sorry',
    unableToPlaceOrder: 'Unfortunately, we are unable to process your order at this time',
    error: 'Error',
    orderReference: 'Order reference',
    pleaseContactUs: 'Please contact us using the details provided and one of the team will be happy to assist',
    orderingAs: 'ordering as',
    companyName: 'Company name',
    noCompanySelected: 'Please select a company to order on behalf of',
    existingMerchant: 'Existing merchant',
    newMerchant: 'New merchant',
    existingMerchantSearchText: 'Search by merchant name or Merchant ID (MID)',
    existingPartnerMerchantSearchText: 'Search by merchant name, merchant ID or account number',
    newROIMerchantSearchText: 'Search by merchant name',
    newUKMerchantSearchText: 'Search by merchant name or number',
    cancelOrder: 'Cancel Order',
    cancelOrderConfirmText: <p>
        Are you sure you want to cancel the current order?
        <br/>
        All entered data will be lost
    </p>,
    confirmCancelOrder: 'Yes, cancel order',
    cancelCancelOrder: 'No, keep order',
    orderingPrevented: <>
        <p>We are sorry but you cannot process new orders at this time.</p>
        <p>All other functionality such as order tracking remains available. Please contact your account manager for more information.</p>
        <p>Error Code: B01</p>
    </>,
    enterManually: 'Add Manually',
    dataNotFoundInCH: 'Company not found in Companies House UK. You can add your company manually',
    dataNotFoundInROI: 'Company not found in Companies Registration Office Ireland. You can add your company manually',
    dataNotFoundInExisting: 'There is no such existing merchant. Please, add a new merchant',
    areYouSureToEnterManually: <><p>Are you sure that you want to add your company manually?<br/>All entered data will be lost</p></>,
    yesAddManually: 'Yes, I want to add manually',
    noDontAddManually: 'No, keep the data',
    error19Text: <>
        <p>Optomany has rejected the order as it appears to contain duplicate information which matches one (or both) of the below criteria.</p>
        <ol style={{textAlign: 'left'}}>
            <li>Company Registration Number already exists and/or</li>
            <li>The combination of head office post code and phonetically matched merchant name already exists.</li>
        </ol>
        <p>Please re-process this order as an “existing merchant” order and search for the merchant record already present on the system.</p>
        <p>If you believe the merchant does not already exist on the system and the rejection is incorrect please contact Optomany either using the ‘Live Chat’ function or using the contact information provided.</p>
    </>,
    error20Text: <>The same company registration number has been found at least twice within the same order request.</>,
    noMerchantSelectedDescription: <>No merchant selected<br/>Please use the form above to select a merchant</>,
    merchantMatch: 'Merchant match',
    continueAsNewMerchant: 'Continue as a New Merchant',
    orderForThisMerchant: 'Order for this Merchant',
    matchFound: 'A match has been found on your existing merchants',
    companyNumber: 'Company Number',

    nextStep: 'Next',
    back: 'Back',
    australianDollar: 'Australian Dollar',
    canadianDollar: 'Canadian Dollar',
    czechKoruna: 'Czech Koruna',
    danishKrone: 'Danish Krone',
    honkKongDollar: 'Hong Kong Dollar',
    hungarianForint: 'Hungarian Forint',
    icelandicKrona: 'Icelandic Krona',
    indianRupee: 'Indian Rupee',
    japaneseYen: 'Japanese YEN',
    omaniRial: 'Omani rial',
    newZealandDollar: 'New Zealand Dollar',
    norwegianKrone: 'Norwegian Krone',
    saudiRiyal: 'Saudi Riyal',
    singaporeDollar: 'Singapore Dollar',
    swedishKrona: 'Swedish Krona',
    swissFranc: 'Swiss Franc',
    thaiBaht: 'Thai Baht',
    uaeDirham: 'UAE Dirham',
    poundSterling: 'Pound Sterling',
    usaDollar: 'United States Dollar',
    euro: 'EURO',
    newRelationship: 'New Relationship',
    newRelationshipDetails: 'Merchant who is yet to have any relationship with a group company.',
    existingRelationship: 'Existing Relationship',
    existingRelationshipDetails: 'Merchant who already has an existing relationship with a group company.',
    selectCompanyMerchantType: (company) => `Select ${company} Company`,
    selectMerchantType: 'selectMerchantType',
    confirmTheMerchantType: 'Please Confirm the Merchant Type',
    agreeWithTermsDna: 'The order has been double checked and is now ready to be processed',
    confirmationDNARequired: 'Please confirm the above before continuing'
})
