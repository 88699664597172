import React from 'react'
import { Modal } from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import translations from './translations'
import { ZeroPriceWarningModalProps } from './props'


export const ZeroPriceWarningModal = ({open, onCancel, onOk}: ZeroPriceWarningModalProps) => {
    return (
        <Modal
            title={<><WarningTwoTone twoToneColor={'#faad14'}/>{translations().warning}</>}
            visible={open}
            onCancel={onCancel}
            onOk={onOk}
        >
            <div>
                <p>{translations().warningText1}</p>
                <p>{translations().warningText2}</p>
                <p>{translations().warningText3}</p>
            </div>
        </Modal>
    )
}
