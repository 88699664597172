import React, { useEffect, useState } from 'react'
import { isEmpty } from 'dna-common'
import { observer } from 'mobx-react'
import { Col, Divider, Drawer, Empty, Row, Spin, Steps, Form } from 'antd'
import { ACQUIRER_IDS, getFieldValue } from '~/code/services'
import { Button, Input, LabelWithTooltip } from '~/code/common/components'
import { ONLY_DIGITS_PATTERN, STANDARD_MID_LENGTH } from '~/code/models'
import { BundleSelect } from '~/code/POS/pages/NewOrder/components/common/BundleSelect'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { acquirerTidHelpers } from '~/code/POS/pages/NewOrder/services/utils'
import { AcquirerAssignedTidList } from '~/code/POS/pages/NewOrder/components/common/AcquirerAssignedTidList'
import { BUNDLE_ACQUIRER_REQUIREMENT_TYPE } from '~/code/POS/pages/NewOrder/services/constants'
import { BundleListItem, DepartmentDetails, StoreDetails } from './components'
import translations from './translations'
import { AddProductFormProps } from './props'
import styles from './AddProductForm.scss'

const { Step } = Steps

export const AddProductForm = observer(({ store, onClose  }: AddProductFormProps) => {
    const data = store.addProductFormData
    const { isFormVisible, stores, acquirerAssignedTids, setAcquirerAssignedTids, totalTIDsList, setTotalTIDsList, isTIDDuplicateExist, setIsTIDDuplicateExist } = store

    const [form] = Form.useForm()
    const [bundleCodeHasData, setBundleCodeHasValue] = useState(false)
    const [bundleQuantityHasData, setBundleQuantityHasData] = useState(false)
    const [bundleAcquirerTIDsHasData, setBundleAcquirerTIDsHasData] = useState<boolean>(false)
    const [bundleAdded, setBundleAdded] = useState(false)
    const [_currentStep, setCurrentStep] = useState(store.currentStep || 0)

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value ? value : defaultValue ? defaultValue : ''
    }

    const [selectedStore, setSelectedStore] = useState(isEmpty(stores) ? null : stores.find( item => item.id === getFieldInitialValue('storeId', stores[0].id)))
    const [selectedDepartment, setSelectedDepartment] = useState(isEmpty(selectedStore?.departments) ? null : selectedStore?.departments.find(item => item.id === getFieldInitialValue('departmentId', selectedStore?.departments[0].id)))

    const selectedBundleItem = null
    const [selectedBundle, setBundle] = useState({bundleCode: selectedBundleItem?.params?.bundleCode,
        description: selectedBundleItem?.params?.description || translations().noDescription, acquirerRequiredTids: selectedBundleItem?.params?.acquirerAssignedTids || []})
    const bundleCodeInitialValue = isEmpty(store.bundles) ? null : getFieldInitialValue('bundleCode')
    const bundleDescription = isEmpty(selectedBundle?.bundleCode) ?
        (isEmpty(selectedBundleItem) ? translations().noDescription : selectedBundleItem?.params?.description) : selectedBundle.description

    const [acquirerAssignedTIDValidateStatus, setAcquirerAssignedTIDValidateStatus] = useState<ValidateStatus[]>([])

    useEffect(() => {
        setCurrentStep(store.currentStep)
    }, [store.currentStep])

    useEffect(() => {
        cleanState()
    }, [isFormVisible])

    useEffect(() => {
        acquirerAssignedTids.forEach(tid => {
            if (tid.tid || selectedBundle?.acquirerRequiredTids === 0) {
                setBundleAcquirerTIDsHasData(true)
            } else {
                setBundleAcquirerTIDsHasData(false)
            }
        })
    }, [acquirerAssignedTids, selectedBundle])

    useEffect(() => {
        setTotalTIDsList(data.bundles)
    }, [acquirerAssignedTids])

    const { handleAcquirerAssignedTidChange, initiateAcquirerAssignedTids, onTIDBlur } = acquirerTidHelpers({
        acquirerAssignedTids, acquirerAssignedTIDValidateStatus, setAcquirerAssignedTIDValidateStatus, setAcquirerAssignedTids, selectedDepartment
    })

    const onBundleChange = (value) => {
        const bundle = store.bundles.find(item => item.value === value)
        setBundle(bundle.params)
        setBundleCodeHasValue(!!value)
    }

    const onBundleQuantityChange = (event) => {
        setBundleQuantityHasData(!!event.target.value)
    }

    const onFinish = (values) => {
        store.setAddProductFormData({
            ...values,
            storeId: selectedStore?.id,
            departmentId: selectedDepartment?.id,
            bundles: data.bundles
        })
        form.resetFields()
        setCurrentStep(0)
        const _selectedStore = stores[0]
        setSelectedStore(_selectedStore)
        setSelectedDepartment(_selectedStore.departments[0])
        onDrawerClose()
    }

    const onDrawerClose = () => {
        // in order to clean up the form for the next configuration without reinitializing the form
        if (!isEmpty(form.getFieldValue('id'))) {
            form.resetFields()
        }
        onClose && onClose()
    }

    const onBundleDeleteClick = (bundleCode) => {
        data.bundles = data.bundles.filter(bundle => bundle.bundleCode !== bundleCode)
    }

    const onAddBundleClick = () => {
        const foundBundle =  data.bundles.find(item => item.bundleCode === selectedBundle.bundleCode)
        if (foundBundle) {
            foundBundle.bundleQuantity = Number(foundBundle.bundleQuantity) + Number(form.getFieldValue('bundleQuantity'))
            foundBundle.acquirerAssignedTids = _shouldShowTIDFormItem() ? acquirerAssignedTids.reverse() : []
            data.bundles = data.bundles.filter(item => item.bundleCode !== selectedBundle.bundleCode)
            data.bundles.unshift(foundBundle)
        } else {
            data.bundles.unshift({
                bundleCode: selectedBundle.bundleCode,
                bundleDescription: selectedBundle.description,
                bundleQuantity: form.getFieldValue('bundleQuantity'),
                acquirerAssignedTids: _shouldShowTIDFormItem() ? acquirerAssignedTids.reverse() : []
            })
        }

        setBundleAdded(true)
        setTimeout(() => { setBundleAdded(false) }, 1000)
        setBundle(null)
        form.setFieldsValue({
            bundleCode: null,
            bundleDescription: null,
            bundleQuantity: null
        })

        setBundleCodeHasValue(false)
        setBundleQuantityHasData(false)
        cleanState()
    }

    const onStoreChange = (value) => {
        const _store = stores?.find(item => item.id === value)
        setSelectedStore(_store)
        setSelectedDepartment(_store.departments[0])
        form.setFieldsValue({'departmentId' : _store.departments[0]?.id})
    }

    const onDepartmentChange = (value) => {
        setSelectedDepartment(selectedStore?.departments?.find(item => item.id === value))
    }

    const _shouldShowTIDFormItem = () => {
        const isDNAPaymentsAcquirer = +selectedDepartment?.acquirer === ACQUIRER_IDS.DNA_PAYMENTS
        const isDNAPaymentsEcomAcquirer = +selectedDepartment?.acquirer === ACQUIRER_IDS.DNA_PAYMENTS_ECOM
        const isDnaMID = selectedDepartment?.visaMID?.length !== STANDARD_MID_LENGTH
        const isTidRequired = selectedBundle?.acquirerRequiredTids === BUNDLE_ACQUIRER_REQUIREMENT_TYPE.DEVICE_TID
            || selectedBundle?.acquirerRequiredTids === BUNDLE_ACQUIRER_REQUIREMENT_TYPE.CNP_TID

        return ((isDNAPaymentsAcquirer && isDnaMID) || selectedDepartment?.acquiresWithDna || isDNAPaymentsEcomAcquirer) && isTidRequired
    }

    const cleanState = () => {
        setBundle(null)
        form.setFieldsValue({
            bundleCode: null,
            bundleDescription: null,
            bundleQuantity: null,
            acquirerAssignedTids: null
        })

        setBundleCodeHasValue(false)
        setBundleQuantityHasData(false)
        setAcquirerAssignedTIDValidateStatus([])
        initiateAcquirerAssignedTids(0)
    }

    const storesAndDepartmentsView = () => {
        return <>
            {/* Store Details */}
            <StoreDetails storeIdInitialValue={selectedStore?.id} stores={stores} storeIdFieldName={'storeId'} onStoreChange={onStoreChange}/>

            {/* Department Details */}
            <DepartmentDetails departments={selectedStore?.departments} departmentIdInitialValue={selectedDepartment?.id} departmentIdFieldName={'departmentId'} onDepartmentChange={onDepartmentChange}/>
        </>
    }

    const bundlesView = () => {
        return <>
            {/* Bundles */}
            <div className={styles.AddProductForm_subgroupTitle}>
                {translations().bundles}
            </div>
            <Row gutter={24}>
                <Col xs={24} sm={7}>
                    <Form.Item
                        label={<LabelWithTooltip label={translations().bundle} tooltipText={translations().bundleTooltip} tooltipPosition={'right'} />}
                        name={'bundleCode'}
                        initialValue={bundleCodeInitialValue}
                        rules={[
                            {
                                required: false,
                                message: translations().bundleRequired
                            }
                        ]}
                    >
                        <BundleSelect placeholder={translations().bundle}
                                         showSearch={true}
                                         filterOption={(input, option) => {
                                             return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                         }}
                                         notFoundContent={<Spin size={'small'}/>}
                                         onFocus={() => { store.loadBundles() }}
                                         selectItems={store.bundles.slice()}
                                         onChange={onBundleChange}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                    <Form.Item
                        label={translations().bundleDescription}
                        name={'bundleDescription'}
                    >
                        <div className={styles.AddProductForm_label}>{bundleDescription}</div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={4}>
                    <Form.Item
                        label={translations().quantity}
                        name={'bundleQuantity'}
                        initialValue={getFieldInitialValue('bundleQuantity')}
                        getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                            const onlyNumberRegexp = ONLY_DIGITS_PATTERN
                            const value = e.currentTarget.value
                            _shouldShowTIDFormItem() && initiateAcquirerAssignedTids(+value)
                            if (!onlyNumberRegexp.test(value) && !isEmpty(value)) {
                                return form.getFieldValue('bundleQuantity')
                            }
                            return value
                        }}
                        rules={[
                            {
                                required: false,
                                message: translations().quantityRequired
                            }
                        ]}
                    >
                        <Input placeholder={translations().quantity} onChange={onBundleQuantityChange}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={5}>
                    <div className={styles.AddProductForm_buttonsContainer}>
                        <div className={styles.AddProductForm_addToProductButtonContainer}>
                            <Button
                                className={null}
                                onClick={onAddBundleClick}
                                disabled={!bundleCodeHasData || !bundleQuantityHasData || (_shouldShowTIDFormItem() && !bundleAcquirerTIDsHasData) || isTIDDuplicateExist}
                            >
                                {translations().addBundle}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            {
                _shouldShowTIDFormItem() && <AcquirerAssignedTidList
                    totalTIDsList={totalTIDsList}
                    acquirerAssignedTids={acquirerAssignedTids}
                    acquirerAssignedTIDValidateStatus={acquirerAssignedTIDValidateStatus}
                    handleAcquirerAssignedTidChange={handleAcquirerAssignedTidChange}
                    setIsTIDDuplicateExist={setIsTIDDuplicateExist}
                    onTIDBlur={onTIDBlur}
                    required={true}
                    isPartnerFlow={true}
                />
            }
            <Divider className={styles.divider}>{translations().selectedBundles}</Divider>
            <>
                { data.bundles?.map((bundle, index) =>
                    <BundleListItem key={bundle.bundleCode} isHighlighted={index === 0 && bundleAdded} bundleListItem={bundle} onDeleteClick={ () => onBundleDeleteClick(bundle.bundleCode)}/>) }
                {
                    isEmpty(data.bundles) &&
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={translations().noBundleAdded} key={'EmptyView'}/>
                }
            </>
        </>
    }

    const steps = [
        {
            title: translations().selectStoreAndDepartment,
            content: storesAndDepartmentsView()
        },
        {
            title: translations().selectBundles,
            content: bundlesView()
        }
    ]

    const next = () => {
        setCurrentStep(_currentStep + 1)
    }

    const prev = () => {
        setCurrentStep(_currentStep - 1)
    }

    return <div>
        <Drawer
            title={translations().addProduct}
            width={'50%'}
            onClose={onDrawerClose}
            visible={isFormVisible}
        >
            <div className={styles.AddProductForm}>
                <Form layout={'vertical'} form={form} onFinish={onFinish}>
                    <Form.Item style={{ display: 'none' }} name={'id'} initialValue={getFieldInitialValue('id')}>
                        <Input />
                    </Form.Item>
                    <Steps size={'small'} current={_currentStep}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className={styles.stepsContent}>{steps[_currentStep].content}</div>
                    <div className={styles.stepsActions}>
                        {_currentStep < steps.length - 1 && (
                            <Button onClick={next}>
                                {translations().proceedToBundlesSelection}
                            </Button>
                        )}
                        {_currentStep === steps.length - 1 && (
                            <Button htmlType={'submit'} disabled={isEmpty(data.bundles)}>
                                {translations().complete}
                            </Button>
                        )}
                        {_currentStep > 0 && (
                            <Button outline={'outlined'} onClick={prev}>
                                {translations().reselectStoreAndDepartment}
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        </Drawer>
    </div>
})
