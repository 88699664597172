import { TranslationBuilder } from '~/code/stores/TranslationStore/TranslationStore'

export default TranslationBuilder.create<{
    applicationManagement: string
    latestApplicationInfo: string
    uploadNewVersion: string
    category: string
    installationPackage: string
    email: string
    appSignedBy: string
    versionHistory: string
    developerName: string
    packageName: string
    deviceTypes: string
}>()
