import translations from './translations'

translations.add('en', {
    orderDetails: 'Order details',
    companyName: 'Company name',
    orderRef: 'Order ref',
    deliverTo: 'Deliver to',
    bundle: 'Bundle',
    status: 'Status',
    lastUpdateTime: 'Last update time',
    quantity: 'Quantity',
    customPrice: 'Custom price',
    merchantInformation: 'Merchant information',
    merchantDetails: 'Merchant details',
    merchantAddress: 'Merchant address',
    mid: 'MID',
    acquirer: 'Acquirer',
    viewConfiguration: 'View configuration report',
    viewDispatchReport: 'View dispatch report',
    back: 'Back',
    tryAgain: 'Try again',
    reorder: 'Reorder',
    loading: 'Loading...',
    configurationReport: 'Configuration report',
    noConfigurationReport: 'Configuration report is not available',
    noDispatchReport: 'Dispatch report is not available',
    close: 'Close',
    orderSubmissionFailed: 'Order Submission Failed',
    orderSubmitted: 'Order Submitted',
    validationCompleted: 'Validation Completed',
    validationFailed: 'Validation Failed',
    cancellationRequested: 'Cancellation Requested',
    cancelled: 'Cancelled',
    dispatching: 'Dispatching',
    completed: 'Completed',
    orderFailed: 'Order Failed',
    reversed: 'Reversed',
    cannotRetryDueToLackOfInfo: 'Cannot retry placing the order, as there is not enough data',
    orderPlacedOn: 'Order placed on',
    orderPlacedBy: 'Order placed by',
    noCompanySelected: 'No Company Selected',
    selectCompanyToViewTryAgain: 'Please select a company to try again',
    selectCompanyToViewErrorDetails: 'Please select a company to view error details',
    selectCompanyToViewDispatchReport: 'Please select a company to view the dispatch report',
    selectCompanyToViewConfigurationReport: 'Please select a company to view the configuration report',
    errorInfo: {
        title: 'Error Information',
        fields: 'Errored fields',
        tooltip: 'Please click to view error details'
    },
    areYouSureCancelOrder: 'Are you sure you want to cancel this order?',
    continueWithCancellation: 'Continue with cancellation',
    cancel: 'Cancel',
    cancelling: 'Cancelling'
})
