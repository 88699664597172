import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form } from 'antd'
import { Select, GroupTitle } from '~/code/common/components'
import { Prop } from '~/code/POS/pages/NewOrder/components/partner/StoresAndDepartments/components/StoreCard/components/Prop/Prop'
import translations from '../../translations/translations'
import { DepartmentDetailsProps } from './props'
import styles from './DepartmentDetails.scss'

export const DepartmentDetails = observer(({departments = [], departmentIdInitialValue, departmentIdFieldName, onDepartmentChange}: DepartmentDetailsProps) => {

    const [selectedDepartment, setSelectedDepartment] = useState(null) // initialSelectedDepartment()
    useEffect(() => {
        setSelectedDepartment(departments.find(item => item.id === departmentIdInitialValue))
    }, [departmentIdInitialValue])

    const onDepartmentSelect = (departmentId) => {
        const _selectedDepartment = departments?.find(item => item.id === departmentId)
        setSelectedDepartment(_selectedDepartment)
        onDepartmentChange(departmentId)
    }

    const getDepartments = () => {
        return departments?.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        }) || []
    }

    return <div className={styles.DepartmentDetails}>
        <Row>
            <Col xs={24}>
                <GroupTitle content={translations().department}/>
            </Col>
        </Row>
        <Row gutter={24}>
            <Col xs={24} sm={12}>
                <Form.Item
                    label={translations().departmentName}
                    name={departmentIdFieldName}
                    initialValue={departmentIdInitialValue}
                    rules={[
                        {
                            required: true,
                            message: translations().departmentRequired
                        }
                    ]}
                >
                    <Select placeholder={translations().department}
                            value={selectedDepartment?.id}
                            onSelect={onDepartmentSelect}
                            selectItems={getDepartments()} />  {/*{departmentsOptions}/>*/}
                </Form.Item>
            </Col>
        </Row>
        { selectedDepartment &&
        <Row gutter={16}>
            {
                !selectedDepartment?.isNew &&
                <Col sm={6}>
                    <Prop label={translations().departmentId}
                          value={ selectedDepartment?.id || ''}/>
                </Col>
            }
            <Col sm={6}>
                <Prop label={translations().visaMID}
                      value={selectedDepartment?.visaMID || ''}/>
            </Col>
            <Col sm={6}>
                <Prop label={translations().mastercardMID}
                      value={selectedDepartment?.mastercardMID || ''}/>
            </Col>
            <Col sm={6}>
                <Prop label={translations().departmentType}
                      value={translations()[selectedDepartment?.departmentType] || ''}/>
            </Col>
        </Row>
        }
    </div>
})
