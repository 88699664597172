// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1TSK62G_RU-kGYJfCnDmzx {\n  padding-bottom: 40px; }\n", ""]);
// Exports
exports.locals = {
	"DepartmentDetails": "_1TSK62G_RU-kGYJfCnDmzx"
};
module.exports = exports;
