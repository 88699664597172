import React from "react";
const SvgNextPage = props => (
  <svg
    id="next-page_svg__\u0421\u043B\u043E\u0439_1"
    x={0}
    y={0}
    viewBox="0 0 1024 1024"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".next-page_svg__st0{fill:#babac4}"}</style>
    <g id="next-page_svg__Artboard">
      <g id="next-page_svg__Group-3" transform="translate(140 -56)">
        <g id="next-page_svg__Group-2">
          <path
            id="next-page_svg__Rectangle-3"
            className="next-page_svg__st0"
            d="M504.4 527.7c24.3 24 24.3 63 0 87L327.7 789.5c-24.3 24-63.7 24-88 0-24.3-24-24.3-63 0-87l176.7-174.7c24.2-24.1 63.7-24.1 88-.1z"
          />
          <path
            id="next-page_svg__Rectangle-3-Copy"
            className="next-page_svg__st0"
            d="M504.4 606.3c24.3-24 24.3-63 0-87L327.7 344.5c-24.3-24-63.7-24-88 0-24.3 24-24.3 63 0 87l176.7 174.7c24.2 24.1 63.7 24.1 88 .1z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgNextPage;
