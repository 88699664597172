import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    addStore: string
    editStore: string
    store: string
    storeName: string
    tradingAsExtraText: string
    storeNameRequired: string
    storeNameAlreadyInUse: string
    telephoneNumber: string
    telephoneNumberRequired: string
    telephoneNumberInvalid: string
    companyNumber: string
    companyNumberRequired: string
    companyNumberUKInvalid: string
    companyNumberROIInvalid: string
    companyNumberUKTooltip: string
    companyNumberROITooltip: string
    email: string
    emailRequired: string
    emailInvalid: string
    vatNumber: string
    vatNumberRequired: string
    address: string
    country: string
    countryRequired: string
    town: string
    townRequired: string
    townInvalid: string
    postcode: string
    postcodeRequired: string
    postcodeInvalid: string
    county: string
    countyRequired: string
    addressLine1: string
    addressLine1Required: string
    addressLine2: string
    addressLine2Required: string
    addressLine3: string

    save: string
    useMerchantDetails: string
    add: string
    cancel: string
    axeptDescription: string
    integrationOptions: string
    axeptLabel: string
}>()

