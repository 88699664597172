import { getWithAuth, patchWithAuth, postWithAuth } from '~/code/services/authorised-requests'
import { checkODINHTTPStatus } from '~/code/services/http-response-handler'
import { DeviceDetails } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DeviceDetails'
import { ForceReSyncResponse } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/ForceReSyncResponse'
import { CommunicationsMethodHistory } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/CommunicationsMethodHistory'
import { BatteryHistory } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/BatteryHistory'
import { Package } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/Package'
import { DetailsForPerformingInstallation } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/DetailsForPerformingInstallation'
import { NotificationPriority } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/NotificationPriority'
import { AvailablePackage } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AvailablePackage'
import { AvailablePackageFilter } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AvailablePackageFilter'
import { RestartDevice } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/RestartDevice'
import { PushTask } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/PushTask'
import { SystemInfo } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/SystemInfo'
import { MidTid } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/MidTid'
import { CommunicationDetail } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/CommunicationDetail'
import { RestartHistory } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/RestartHistory'
import { ApnDetail } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/ApnDetail'
import { AppVersion } from '~/code/ODIN/stores/DeviceManagementDetailsStore/models/AppVersion'

export const fetchDeviceDetailsById = (deviceId: number) =>
    checkODINHTTPStatus(getWithAuth<DeviceDetails>(`/odin-api/api/Devices/${deviceId}`))

export const forceReSyncDeviceById = (deviceId: number) =>
    checkODINHTTPStatus(postWithAuth<ForceReSyncResponse>(`/odin-api/api/Devices/${deviceId}/ForceResync`))

export const fetchCommunicationsMethodHistory = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<CommunicationsMethodHistory[]>(`/odin-api/api/Devices/${deviceId}/CommsMethodHistory`, filters))

export const fetchBatteryHistory = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<BatteryHistory[]>(`/odin-api/api/Devices/${deviceId}/BatteryHistory`, filters))

export const fetchPackages = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<Package[]>(`/odin-api/api/Devices/${deviceId}/Packages`, filters))

export const fetchAvailablePackages = (deviceId: number, filters: AvailablePackageFilter) =>
    checkODINHTTPStatus(getWithAuth<AvailablePackage[]>(`/odin-api/api/Devices/${deviceId}/Packages/AvailablePackages`, filters))

export const fetchAvailablePackageVersions = (deviceId: number, packageId: number) =>
    checkODINHTTPStatus(getWithAuth<AppVersion[]>(`/odin-api/api/Devices/${deviceId}/Packages/${packageId}AvailablePackageVersions`))

export const fetchNotificationPriorities = () =>
    checkODINHTTPStatus(getWithAuth<NotificationPriority[]>(`/odin-api/api/NotificationPriority`))

export const uninstallAppById = (deviceId: number, data: DetailsForPerformingInstallation) =>
    checkODINHTTPStatus(postWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Packages/Uninstall`, data))

export const setLauncherApplication = (deviceId: number, data: DetailsForPerformingInstallation) =>
    checkODINHTTPStatus(postWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Packages/Launcher`, data))

export const installApp = (deviceId: number, data: DetailsForPerformingInstallation) =>
    checkODINHTTPStatus(postWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Packages/Install`, data))

export const restartDevice = (deviceId: number, data: RestartDevice) =>
    checkODINHTTPStatus(postWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Restart`, data))

export const fetchCreateNewDeviceLog = (deviceId: number, data: RestartDevice) =>
    checkODINHTTPStatus(postWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Logs`, data))

export const fetchAudit = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Audit`, filters))

export const fetchDeviceLogs = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<any>(`/odin-api/api/Devices/${deviceId}/Logs`, filters))

export const fetchPushTasks = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<PushTask[]>(`/odin-api/api/Devices/${deviceId}/Notifications`, filters))

export const fetchSystemInfo = (deviceId: number) =>
    checkODINHTTPStatus(getWithAuth<SystemInfo>(`/odin-api/api/Devices/${deviceId}/SystemInformation`))

export const fetchMidAndTid = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<MidTid[]>(`/odin-api/api/Devices/${deviceId}/MidAndTids`, filters))

export const fetchCommunicationDetails = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<CommunicationDetail[]>(`/odin-api/api/Devices/${deviceId}/CommunicationDetails`, filters))

export const fetchApnDetails = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<ApnDetail[]>(`/odin-api/api/Devices/${deviceId}/ApnDetails`, filters))

export const fetchRestartHistory = (deviceId: number, filters) =>
    checkODINHTTPStatus(getWithAuth<RestartHistory[]>(`/odin-api/api/Devices/${deviceId}/StartupDetails`, filters))

export const fetchPatchFriendlyName = (id: number, name: string) =>
    checkODINHTTPStatus(patchWithAuth(`/odin-api/api/Devices/${id}`, [
        {
            'op': 'replace',
            'path': '/DeviceName',
            'value': name
        }
    ]))
