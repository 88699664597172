import { isEmpty } from 'dna-common'
import { DispatchReportResponse } from '~/code/stores/OrderDetailsStore/models/DispatchReportResponse'
import { DispatchReportModel } from '~/code/POS/pages/OrderHistory/pages/OrderDetails/components/DispatchReport/models/DispatchReportModel'
import moment from 'moment'
import translations from '../translations'

export function dispatchReportResponseToConfigurationReportModel (response: DispatchReportResponse[]): DispatchReportModel[] {
    if (isEmpty(response) ) {
        return  null
    }

    return response.map(item => {
        const dispatchReport: DispatchReportModel = {
            ...item,
            dispatchedDateTime: item.dispatchedDateTime ?
                moment(new Date(item.dispatchedDateTime)).format('HH:mm:ss, DD.MM.YYYY') :
                translations().notYetDispatched
        }

        return dispatchReport
    })
}
