import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    checkoutCustomisations: string
    usePartnerDefinedSettings: string

    integrationTypes: string
    integrationTypesRequired: string

    mandatoryFields: string
    accountCity: string
    accountEmail: string
    accountCountry: string
    accountStreet1: string
    accountLastName: string
    accountFirstName: string
    accountPostalCode: string

    colors: string
    images: string
    otherSettings: string

    // colors
    primaryColor: string
    primaryColorRequired: string
    headerStartColor: string
    headerStartColorRequired: string
    headerFinishColor: string
    headerFinishColorRequired: string
    actionButtonBackgroundColor: string
    actionButtonBackgroundColorRequired: string
    actionButtonColor: string
    actionButtonColorRequired: string
    closeButtonColor: string
    closeButtonColorRequired: string
    headerLogoTextColor: string
    headerLogoTextColorRequired: string

    // images
    headerLogoImageURL: string
    headerLogoImageURLRequired: string
    secondaryLogoImageURL: string
    secondaryLogoImageURLRequired: string
    secondaryLogoImagePosition: string
    secondaryLogoImagePositionRequired: string

    // other settings
    headerLogoText: string
    headerLogoTextRequired: string
    headerLogoTextSecondary: string
    headerLogoTextSecondaryRequired: string
    headerLogoTextMarginLeft: string
    headerLogoTextMarginLeftRequired: string
    closeButtonOpacity: string
    closeButtonOpacityRequired: string
    headerLogoMobileWidth: string
    headerLogoMobileWidthRequired: string
    headerLogoMobileHeight: string
    headerLogoMobileHeightRequired: string
    headerLogoDesktopWidth: string
    headerLogoDesktopWidthRequired: string
    headerLogoDesktopHeight: string
    headerLogoDesktopHeightRequired: string
    actionButtonBorderRadius: string
    actionButtonBorderRadiusRequired: string
    showPoweredByBlock: string
    showHeaderBottomLine: string
    showOrderNumberBlock: string
    showOrderDescriptionBlock: string
    showPaymentSecuredByBlock: string
}>()

