import React from 'react'
import translations from './translations'

translations.add('en', {
    addStore: 'Add Store',
    editStore: 'Edit Store',
    store: 'Merchant Store',
    storeName: 'Name',
    tradingAsExtraText: 'PLEASE NOTE: If ordering a terminal solution this will appear as the merchant name on all receipts.',
    storeNameRequired: 'Name is required',
    storeNameAlreadyInUse: 'This store name is already in use',
    telephoneNumber: 'Telephone number',
    telephoneNumberRequired: 'Telephone number is required',
    telephoneNumberInvalid: 'Invalid telephone number',
    companyNumber: 'Company number',
    companyNumberRequired: 'Company number is required',
    companyNumberUKInvalid: 'Company number must consist of 8 digits or 2 letters followed by 6 digits or 2 letters followed by 5 digits followed by 1 letter',
    companyNumberROIInvalid: 'Company number must consist of 5 or 6 digits',
    companyNumberUKTooltip: 'Company registration number (CRN) as registered with Companies House. If you do not have a company number, please select the “sole trader” option.',
    companyNumberROITooltip: 'Company or business number as registered with Companies Registration Office (CRO). If you do not have a company or business number, please select the “sole trader” option.',
    vatNumber: 'VAT number',
    vatNumberRequired: 'VAT number is required',
    email: 'Email',
    emailRequired: 'Email is required',
    emailInvalid: 'Invalid email',

    address: 'Address',
    country: 'Country',
    countryRequired: 'Country is required',
    town: 'Town/City',
    townRequired: 'Town/City is required',
    townInvalid: 'Town/City is invalid',
    postcode: 'Postcode',
    postcodeRequired: 'Postcode is required',
    postcodeInvalid: 'Invalid postcode',
    county: 'County',
    countyRequired: 'County is required',
    addressLine1: 'Address line 1',
    addressLine1Required: 'Address line 1 is required',
    addressLine2: 'Address line 2',
    addressLine2Required: 'Address line 2 is required',
    addressLine3: 'Address line 3',

    save: 'Save',
    useMerchantDetails: 'Use Merchant Details',
    add: 'Add',
    cancel: 'Cancel',

    axeptDescription: 'Selecting the above option will enable all axept PRO devices activated under this Merchant Store for use with axept Connect Cloud integration option',
    axeptLabel: 'Enable axept Connect Cloud',
    integrationOptions: 'Integration Options'
})
