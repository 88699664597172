import { TranslationBuilder } from '~/code/common/components/Translation'
import { TranslationStructure } from '../../../common/ProductListForm/components/AddProductForm/translations/translations'

interface Structure extends TranslationStructure {
    showOnlyHardwareOnlyBundles: string
    customPrice: string
    otherDepartmentsContainVerifoneProduct: JSX.Element
    whenMixedOrderHardwareOnlyDeliveredToMerchant: string
    acquirerAssignedTid: string
    acquirerIssuedTid: string
    acquirerIssuedTidRequired: string
    acquirerAssignedTidRequired: string
    invalidDNAPaymentsTIDLength: string
    invalidDNAPaymentsTIDDuplicate: string
}

export default TranslationBuilder.create<Structure>()

