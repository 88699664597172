import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    title: string
    email: string
    emailRequired: string
    invalidEmail: string
    password: string
    passwordRequired: string
    forgotPassword: string
    signIn: string
    orderPortal: string
    odin: string
}>()

