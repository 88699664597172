import React from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'dna-common'
import { Button, EmptyView } from '~/code/common/components'
import { ProductListItem } from './components'
import { productToProductListItemModel } from './services'
import translations from './translations'
import { ProductListFormProps } from './props'
import styles from './ProductListForm.scss'
import { PlusOutlined } from '@ant-design/icons/lib'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'

export const ProductListForm = observer(({store, children}: ProductListFormProps) => {
    const {stores, productListFormData, canGoBack, goBack} = store

    const [form] = useForm()

    const onFinish = () => {
        store.setProductListFormData(store.productListFormData)
    }

    const onAddProductButtonClick = () => {
        store.addProduct()
    }

    const deleteProduct = (productId) => {
        store.deleteProduct(productId)
    }

    const editProduct = (productId) => {
        store.editProduct(productId)
    }

    const renderContent = () => {
        const content = []
        if (isEmpty(store.productListFormData?.products)) {
            content.push(<EmptyView noData={translations().noProductAdded} addProductClick={onAddProductButtonClick} key={'EmptyView'}/>)
        } else {
            content.push(<React.Fragment key={'ProductsList'}>
                {
                    store.productListFormData?.products.map((item, index) => {
                        const partnerStore = stores.find(storeItem => storeItem.id === item.storeId)
                        const partnerDepartment = partnerStore.departments.find(departmentItem => departmentItem.id === item.departmentId)

                        if (partnerDepartment) {
                            return <ProductListItem
                                key={`${item.id}-${index}`}
                                onDeleteClick={() => deleteProduct(item.id)}
                                onEditClick={() => editProduct(item.id)}
                                productListItem={productToProductListItemModel(item, stores)}
                            />
                        }

                        return null
                    })
                }
            </React.Fragment>)
        }
        content.push(<div className={styles.addProductButtonWrapper} key={'AddProductButtonWrapper'}>
            <Button onClick={onAddProductButtonClick} key={'AddProductButton'}>
                <PlusOutlined/> {translations().addProduct}
            </Button>
        </div>)

        return content
    }

    return (
        <div className={styles.ProductListForm}>
            {renderContent()}
            {children}
            <Form form={form} onFinish={onFinish}>
                <FormNavigation canGoBack={canGoBack} goBack={goBack} isNextStepButtonDisabled={isEmpty(productListFormData?.products)}/>
            </Form>
        </div>
    )
})
