import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { error } from 'dna-common'
import { notification } from 'antd'
import { fetchDeviceManufacturer } from '~/code/ODIN/stores/ApplicationManagementStore/services/fetchers'
import { IDeviceManufacturerStore } from '~/code/ODIN/pages/ApplicationManagement/IDeviceManufacturerStore'
import { SelectItem } from '~/code/common/components'
import { ResponsePagination } from '~/code/ODIN/models/ResponsePagination'
import { LARGE_PAGE_SIZE } from '~/code/models'
import commonTranslations from '~/code/translations/translations'
import { DEFAULT_NOTIFICATION_DURATION, ErrorModel } from '~/code/ODIN/models'

export class DeviceManufacturerStore implements IDeviceManufacturerStore {
    constructor () {
        makeObservable(this, {
            isLoading: observable,
            deviceManufacturer: observable,
            totalPageItems: observable,
            totalCount: observable,
            pageSize: observable,
            pageNumber: observable,
            selectDeviceManufacturer: computed,
            setDeviceManufacturer: action,
            loadDeviceManufacturer: action
        })
    }

    isLoading: boolean = false
    totalPageItems: number = 0
    totalCount: number = 0
    pageSize: number = LARGE_PAGE_SIZE
    pageNumber: number = 0
    deviceManufacturer: SelectItem[] = []

    public get selectDeviceManufacturer(): SelectItem[] {
        if (!this.deviceManufacturer.length) {
            this.loadDeviceManufacturer()
        }

        return this.deviceManufacturer
    }

    public loadDeviceManufacturer = () => {
        if (this.isLoading) {
            return
        }

        this.pageNumber++

        this.isLoading = true

        const queryParams = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber
        }

        fetchDeviceManufacturer(queryParams)
            .then(response => {
                if (response?.result?.length > 0) {
                    runInAction(() => {
                        const pagination = JSON.parse(response.headers.get('x-pagination')) as ResponsePagination
                        this.deviceManufacturer = [...this.deviceManufacturer, ...response?.result?.map(item => ({value: item.deviceManufacturerId, label: item.name, key: item.deviceManufacturerId}))]
                        this.totalCount = pagination.totalCount
                        this.totalPageItems = pagination.totalPages
                    })
                }
            })
            .catch((err: ErrorModel) => {
                notification.error({
                    message: commonTranslations().errors.unableToRetrieveRequestedDate(err.traceId),
                    duration: DEFAULT_NOTIFICATION_DURATION
                })
                error(`FAILED: ${err.message}`)
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false
                })
            })
    }

    setDeviceManufacturer (deviceManufacturer: SelectItem[]): void {
        this.deviceManufacturer = deviceManufacturer
    }
}
