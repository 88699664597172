import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Col, Form, Row, Spin } from 'antd'
import { getFieldValue } from '~/code/services'
import { Select } from '~/code/common/components'
import { generateDepartmentDetails, generateStoreDetails } from './services'
import translations from './translations'
import { OrderTypeFormProps } from './props'
import styles from './OrderTypeForm.scss'
import { FormNavigation } from '~/code/POS/pages/NewOrder/components/common/FormNavigation/FormNavigation'
import { isEmpty } from 'dna-common'

export const OrderTypeForm = observer(({ store }: OrderTypeFormProps) => {
    const {
        orderTypeFormData: data,
        setOrderTypeFormData,
        selectedOrderType,
        setOrderType,
        stores,
        departments,
        selectedStoreId,
        selectedStore,
        onStoreChange,
        selectedDepartment,
        selectedDepartmentId,
        onDepartmentChange,
        orderTypes,
        loadStores,
        isLoadingStores,
        loadDepartments,
        isLoadingDepartments,
        canGoBack,
        goBack
    } = store
    const [form] = Form.useForm()

    const onFinish = (values) => {
        setOrderTypeFormData(values)
    }

    const onOrderTypeChange = (orderType) => {
        setOrderType(orderType)
        form.resetFields(['storeId', 'departmentId'])
    }

    const handleStoreChange = (merchantStoreId) => {
        onStoreChange(merchantStoreId)
    }

    const handleDepartmentChange = (merchantDepartmentId) => {
        onDepartmentChange(merchantDepartmentId)
    }

    const getFieldInitialValue = (fieldName, defaultValue?: any) => {
        const value =  getFieldValue(data, fieldName)
        return value || defaultValue || ''
    }

    const handleScroll = (e, callback) => {
        const isEndOfList = e.target.scrollTop + e.target.clientHeight

        if (isEndOfList === e.target.scrollHeight ) {
            callback()
        }
    }

    const isStoreSelectionDisabled = selectedOrderType === 'newStoreAndDepartment'
    const isDepartmentSelectionDisabled = (selectedOrderType === 'newDepartment' || selectedOrderType === 'newStoreAndDepartment') ||
        isEmpty(stores)

    useEffect(() => {
        const storeInitialValue = isStoreSelectionDisabled ? null : (getFieldInitialValue('storeId') || selectedStoreId)
        form.setFieldsValue({
            storeId: storeInitialValue
        })
    }, [stores, selectedStoreId, selectedOrderType])

    useEffect(() => {
        const departmentInitialValue = isDepartmentSelectionDisabled ? null : (getFieldInitialValue('departmentId') || selectedDepartmentId)
        form.setFieldsValue({
            departmentId: departmentInitialValue
        })
    }, [departments, selectedDepartmentId, selectedOrderType])

    return (
        <div className={styles.OrderTypeForm}>
            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div className={styles.OrderTypeForm_inputContainer}>
                            <Form.Item
                                label={translations().orderType}
                                name={'orderType'}
                                initialValue={getFieldInitialValue('orderType')}
                                rules={[
                                    {
                                        required: true,
                                        message: translations().orderTypeRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().orderType}
                                        onChange={onOrderTypeChange}
                                        selectItems={orderTypes.slice()}
                                        currentValue={selectedOrderType}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <div className={styles.OrderTypeForm_subgroupTitle}>
                    {translations().pointInformation}
                </div>
                <Row justify={'space-between'}>
                    <Col xs={24} md={10}>
                        <div className={styles.OrderTypeForm_inputContainer}>
                            <Form.Item
                                label={translations().store}
                                name={'storeId'}
                                rules={[
                                    {
                                        required: selectedOrderType !== 'newStoreAndDepartment',
                                        message: translations().storeRequired
                                    }
                                ]}
                            >
                                <Select placeholder={translations().store}
                                           onSelect={handleStoreChange}
                                           onFocus={loadStores}
                                           onPopupScroll={(e) => {handleScroll(e, loadStores)}}
                                           notFoundContent={isLoadingStores ? <Spin size={'small'}/> : undefined}
                                           disabled={isStoreSelectionDisabled}
                                           selectItems={stores.slice()}/>
                            </Form.Item>
                        </div>
                        { !isStoreSelectionDisabled && selectedStore && generateStoreDetails(selectedStore)}
                    </Col>
                    <Col xs={24} md={10}>
                        <div className={styles.OrderTypeForm_inputContainer}>
                            {
                                departments?.length > 0 ?
                                <Form.Item
                                    label={translations().department}
                                    name={'departmentId'}
                                    rules={[
                                        {
                                            required: selectedOrderType === 'newBundle',
                                            message: translations().departmentRequired
                                        }
                                    ]}
                                >
                                    <Select placeholder={translations().department}
                                               onFocus={loadDepartments}
                                               onSelect={handleDepartmentChange}
                                               onPopupScroll={(e) => {handleScroll(e, loadDepartments)}}
                                               notFoundContent={isLoadingDepartments ? <Spin size={'small'}/> : undefined}
                                               disabled={isDepartmentSelectionDisabled}
                                               selectItems={departments.slice()}/>
                                </Form.Item> :
                                <Form.Item
                                    label={translations().department}
                                    name={'departmentIdFake'}
                                    rules={[
                                        {
                                            required: selectedOrderType === 'newBundle',
                                            message: translations().departmentRequired
                                        }
                                    ]}
                                >
                                    <Select placeholder={''}
                                           onFocus={loadDepartments}
                                           onPopupScroll={(e) => {handleScroll(e, loadDepartments)}}
                                           notFoundContent={isLoadingDepartments ? <Spin size={'small'}/> : undefined}
                                           disabled={isDepartmentSelectionDisabled}
                                           selectItems={[]}/>
                                </Form.Item>
                            }
                        </div>
                        { !isDepartmentSelectionDisabled && selectedDepartment && generateDepartmentDetails(selectedDepartment) }
                    </Col>
                </Row>
                <FormNavigation canGoBack={canGoBack} goBack={goBack}/>
            </Form>
        </div>
    )
})
