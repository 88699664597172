import React from 'react'
import { Form, Modal } from 'antd'
import { Button } from '~/code/common/components'
import styles from '~/code/ODIN/pages/DeviceManagementDetails/DeviceManagementDetails.scss'
import { PrioritySelect } from '~/code/ODIN/pages/DeviceManagementDetails/components/PrioritySelect/PrioritySelect'
import translations from './translations'
import { AppUpdateModalProps } from './props'
import { observer } from 'mobx-react'

export const AppUpdateModal = observer((
    {
        isDeviceRegistered, form, serialNumber, title, onClose, onOk, isVisible,
        notificationPriorities, setPriorityId, renderAvailablePackagesSelect
    }: AppUpdateModalProps
) => {
    const onUpdateToLatestAppModalCancel = () => {
        form.resetFields()
        onClose()
    }

    return (
        <Modal
            title={`${serialNumber} - ${title}`}
            visible={isVisible}
            onOk={onUpdateToLatestAppModalCancel}
            onCancel={onUpdateToLatestAppModalCancel}
            footer={[
                <Button
                    key="back"
                    className={styles.backButton}
                    onClick={onUpdateToLatestAppModalCancel}
                >
                    {translations().cancel}
                </Button>,
                <Button
                    key="submit"
                    htmlType="submit"
                    form="updateLatestApp"
                    disabled={!isDeviceRegistered}
                >
                    {translations().pushUpdate}
                </Button>
            ]}
        >
            <Form
                form={form}
                id="updateLatestApp"
                name="updateLatestApp"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={(values) => {
                    onOk(values, 'update')
                    onClose()
                }}
                autoComplete="off"
            >
                <Form.Item
                    label={translations().installPriority}
                    name="priorityId"
                    rules={[
                        {
                            required: true,
                            message: translations().pleaseSelectPriority
                        }
                    ]}
                >
                    <PrioritySelect
                        dataSource={notificationPriorities}
                        onChange={setPriorityId}
                    />
                </Form.Item>
                <Form.Item
                    label={translations().appVersion}
                    name="packageVersionId"
                    rules={[
                        {
                            required: true,
                            message: translations().pleaseTypePackageVersionId
                        }
                    ]}
                >
                    {renderAvailablePackagesSelect()}
                </Form.Item>
            </Form>
        </Modal>
    )
})
