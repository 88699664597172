import { TranslationBuilder } from '~/code/common/components/Translation'

export default TranslationBuilder.create<{
    cn: string
    checkoutV3Onboarding: string
    merchantOnboardingRequirements: string
    searchMerchantPlaceholder: string
    noMerchantFound: string
    noMerchantSelectedDescription: JSX.Element

    cancelOnboarding: string
    cancelOnboardingConfirmText: JSX.Element
    confirmCancelOnboarding: string
    cancelCancelOnboarding: string

    storeAndDepartmentSelection: string
    merchantDetails: string
    terminalSettings: string
    pennies: string
    checkoutSettings: string
    transactionLimits: string

    onboardingDetails: string
    requestSummary: string
    progress: string
}>()

