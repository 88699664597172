import { ContentType, get, patch, post, put } from 'back-connector'
import { get as getFromStore } from 'store-connector'
import { getDnaApiTokenByScope } from '~/code/stores/CheckoutV3Onboarding/services/utils'
import { error } from 'dna-common'

export const getWithAuth = <T>(url: string, data?: ContentType) => {
    const headers = []
    const accessToken = getFromStore('accessToken')
    headers.push(['Authorization', `Bearer ${accessToken}`])
    return get<T>(url, data, headers)
}

export const putWithAuth = <T>(url: string, data?: ContentType, headers?: any[][]) => {
    const totalHeaders = []
    const accessToken = getFromStore('accessToken')
    totalHeaders.push(['Authorization', `Bearer ${accessToken}`])

    if (headers.length) {
        totalHeaders.push(...headers)
    }

    return put<T>(url, data, totalHeaders)
}

export const postWithAuth = <T>(url: string, data?: ContentType) => {
    const headers = []
    const accessToken = getFromStore('accessToken')
    headers.push(['Authorization', `Bearer ${accessToken}`])
    return post<T>(url, data, headers)
}

export const patchWithAuth = <T>(url: string, data?: ContentType) => {
    const headers = []
    const accessToken = getFromStore('accessToken')
    headers.push(['Authorization', `Bearer ${accessToken}`])
    return patch<T>(url, data, headers)
}

export const getWithDnaAuth = async <T> (url: string, scope: string, data?: ContentType) => {
    let token
    try {
        token = await getDnaApiTokenByScope('checkout_onboarding')
    } catch (e) {
        error('Could not authorize in DNA api', e.message)
    }
    const headers = []
    headers.push(['Authorization', `Bearer ${token}`])
    return get<T>(url, data, headers)
}

export const postWithDnaAuth = async <T> (url: string, scope: string, data?: ContentType) => {
    let token
    try {
        token = await getDnaApiTokenByScope('checkout_onboarding')
    } catch (e) {
        error('Could not authorize in DNA api', e.message)
    }
    const headers = []
    headers.push(['Authorization', `Bearer ${token}`])
    return post<T>(url, data, headers)
}
