import { action, makeObservable, observable } from 'mobx'
import { IFormSubmitHandler } from '~/code/stores/NewOrderStore/models/IFormSubmitHandler'
import { ITransactionLimitsFormStore } from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm/models'
import {
    createTransactionLimitsFormData,
    TransactionLimitsFormData
} from '~/code/POS/pages/EmployeeFunctions/CheckoutV3Onboarding/components/TransactionLimitsForm/models/TransactionLimitsFormData'
import { OnboardingDetailsStepKey } from '~/code/stores/CheckoutV3Onboarding/models'

export class TransactionLimitsFormStore implements ITransactionLimitsFormStore {

    constructor (public formSubmitHandler: IFormSubmitHandler) {
        makeObservable(this, {
            transactionLimitsFormData: observable,
            goBack: action
        })

        this.transactionLimitsFormData = createTransactionLimitsFormData()
    }

    static stepName: OnboardingDetailsStepKey = 'transactionLimits'

    transactionLimitsFormData: TransactionLimitsFormData

    setTransactionLimitsFormData = (data: TransactionLimitsFormData) => {
        this.transactionLimitsFormData = {...this.transactionLimitsFormData, ...data}
        this.formSubmitHandler.handleFormSubmit(this.transactionLimitsFormData, TransactionLimitsFormStore.stepName)
    }

    // navigation related props
    canGoBack: boolean = true

    goBack = () => {
        this.formSubmitHandler.handleGoBack(TransactionLimitsFormStore.stepName)
    }
}
