export interface InformationFormData {
    companyName: string
    tradingAs: string
    companyNumber: string
    vatNumber: string
    addressLine1: string
    addressLine2: string
    addressLine3: string
    town: string
    county: string
    country: number
    postcode: string
    contactName: string
    contactSurname: string
    jobTitle: string
    email: string
    contactPhoneNumber: string
    contactMobilePhone: string
}

export function createInformationFormData(): InformationFormData {
    return {
        companyName: '',
        tradingAs: '',
        companyNumber: '',
        vatNumber: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        country: 0,
        postcode: '',
        contactName: '',
        contactSurname: '',
        jobTitle: '',
        email: '',
        contactPhoneNumber: '',
        contactMobilePhone: ''
    }
}
