// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._9mWqRD_JTS9bW9lAiFW6d {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 36px;\n  color: #00a0dc;\n  padding-bottom: 13px; }\n", ""]);
// Exports
exports.locals = {
	"Header": "_9mWqRD_JTS9bW9lAiFW6d"
};
module.exports = exports;
